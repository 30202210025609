import {
  GFCheckbox,
  GFDataTable,
  GFLayoutWrapper,
  GFModal,
  GFSwitch,
  GFTypography,
} from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { getBusinessTypeLabel } from "@Containers/ApprovalOrderCancellation/ApprovalOrderCancellation.config";
import CreateOrUpdateApprovalStatusForm from "@Containers/ApprovalOrderCancellation/ApprovalStatus/CreateOrUpdateApprovalStatusForm";
import useQueryPagination from "@Hooks/useQueryPagination";
import { ApprovalStatusModel } from "@Models/approval-status.model";
import { ApprovalStatusService } from "@Services/approval-status.service";
import { getErrorResponse } from "@Utils";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalStatusPayload } from "src/schemas/approval-status.schema";
import { ModalState, ModalType } from "src/types";

const columnSettings = (
  onUpdateStatus: (data: ApprovalStatusPayload, id?: number) => void,
  t: any
): GFDataTableColumn<ApprovalStatusModel>[] => [
  {
    title: "approvalOrderCancellation.code",
    dataIndex: "code"
  },
  {
    title: "approvalOrderCancellation.status",
    dataIndex: "statusName",
    width: 300
  },
  {
    title: "approvalOrderCancellation.lock",
    dataIndex: "isLocked",
    render(cellData, record, rowIndex, onChange) {
      return <GFCheckbox checked={cellData} readOnly disabled size="small" />;
    },
  },
  {
    title: "approvalOrderCancellation.businessType",
    dataIndex: "businessType",
    width: 300,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{t(getBusinessTypeLabel(cellData))}</GFTypography>;
    },
  },
  {
    title: "approvalOrderCancellation.active",
    dataIndex: "isActive",
    width: 250,
    render: (cellData, record, rowIndex, onChange) => {
      return (
        <GFSwitch
          value={cellData}
          onChange={(checked) => onUpdateStatus({ ...record, isActive: checked }, record.id)}
          disabled={record.isLocked}
          size="small"
        />
      );
    },
  },
  {
    title: "approvalOrderCancellation.description",
    dataIndex: "description",
    width: 300,
    autoWidth: true,
  },
];

export default function ApprovalStatus() {
  const {
    data,
    loading,
    hasMore,
    onRemoveSuccess,
    onUpdateSuccess,
    onCreateSuccess,
    onLoadMore,
    onSearch,
    searchValue,
  } = useQueryPagination<ApprovalStatusModel>(ApprovalStatusService.getAll);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("translation");

  const [modal, setModal] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });

  const isOpenCreateOrUpdateModal =
    modal.open && (modal.modalType === ModalType.Create || modal.modalType === ModalType.Update);
  const isOpenDeleteModal = modal.open && modal.modalType === ModalType.Delete;

  const handleOpenCreateModal = () => {
    setModal({
      open: true,
      selectedId: null,
      modalType: ModalType.Create,
    });
  };

  const handleOpenUpdateModal = (id: number, record: any) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Update,
      initUpdateData: record,
    });
  };

  const handleOpenDeleteModal = (id: number) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Delete,
    });
  };

  const handleCloseModal = () => {
    setModal({
      open: false,
      selectedId: null,
      initUpdateData: null,
      modalType: ModalType.Create,
    });
  };

  const handleCreateApprovalStatus = async (data: ApprovalStatusModel) => {
    try {
      setModal((prev) => ({ ...prev, loading: true }));

      const response = await ApprovalStatusService.create(data);
      enqueueSnackbar(t("approvalOrderCancellation.createStatusSuccess"), {
        variant: "success",
      });
      onCreateSuccess(response.data);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.createStatusFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
      setModal((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateApprovalStatus = async (data: ApprovalStatusPayload, id?: number) => {
    try {
      const updateId = id || modal.selectedId;
      if (!updateId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      const response = await ApprovalStatusService.update(updateId as number, data);

      enqueueSnackbar(t("approvalOrderCancellation.updateStatusSuccess"), {
        variant: "success",
      });

      onUpdateSuccess(updateId, response.data);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.updateStatusFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
      setModal((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleDeleteApprovalStatus = async () => {
    try {
      if (!modal.selectedId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      await ApprovalStatusService.delete(modal.selectedId as number);

      enqueueSnackbar(t("approvalOrderCancellation.deleteStatusSuccess"), {
        variant: "success",
      });

      onRemoveSuccess(modal.selectedId);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.deleteStatusFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleConfirm = (data: ApprovalStatusModel, type: ModalType) => {
    if (type === ModalType.Create) {
      handleCreateApprovalStatus(data);
    } else {
      handleUpdateApprovalStatus(data);
    }
  };

  const columns = useMemo(() => columnSettings(handleUpdateApprovalStatus, t), [data.data, t]);

  return (
    <GFLayoutWrapper>
      {isOpenCreateOrUpdateModal && (
        <CreateOrUpdateApprovalStatusForm
          open={isOpenCreateOrUpdateModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          modalType={modal.modalType}
          initialValues={modal.initUpdateData}
          loading={modal.loading}
        />
      )}

      {isOpenDeleteModal && (
        <GFModal
          title={t("approvalOrderCancellation.deleteStatus")}
          open={isOpenDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDeleteApprovalStatus}
          loading={modal.loading}>
          <GFTypography>{t("approvalOrderCancellation.deleteStatusConfirmation")}</GFTypography>
        </GFModal>
      )}

      <GFDataTable
        columns={columns}
        dataSource={data.data}
        hasMore={hasMore}
        onLoadMore={onLoadMore}
        showDateRange={false}
        showExcelButton={false}
        showCreateButton={true}
        onCreate={handleOpenCreateModal}
        showActions={{
          showActionDeleteButton(record) {
            return !record.isLocked;
          },
          showActionEditButton(record) {
            return !record.isLocked;
          },
          onActionEdit(id, record) {
            handleOpenUpdateModal(id, record);
          },

          onActionDelete(id, record) {
            handleOpenDeleteModal(id);
          },
        }}
        searchValue={searchValue}
        onSearch={onSearch}
        loading={loading}
        height="calc(100vh - 300px)"
      />
    </GFLayoutWrapper>
  );
}
