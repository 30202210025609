import GFLabel from "@Components/ui/GFLabel";
import { TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}


const StyledTextField = styled(TextField)(({ theme, size }) => ({
  "& .MuiInputBase-root": {
    padding: "0",

    "& .MuiInputBase-input": {
      minHeight: "4.8rem",
      textTransform: "none",
      borderRadius: 4,
      color: "var(--text-foreground)",
      fontSize: "1.6rem",
      lineHeight: 1,
      fontWeight: 700,
      padding: "1.6rem",

      // --- SIZES ---
      ...(size === "small" && {
        fontSize: "1.4rem",
        minHeight: "3.6rem",
        paddingInline: "0.8rem",

        "& .MuiSvgIcon-root": {
          fontSize: "1.6rem",
        },
      }),
      ...(size === "large" && {
        fontSize: "1.8rem",
        minHeight: "5.6rem",
        paddingInline: "2rem",
        "& .MuiSvgIcon-root": {
          fontSize: "2.4rem",
        },
      }),
    },

    "&.Mui-disabled": {
      backgroundColor: "var(--disabled-color)",
    },

    "&.Mui-focused fieldset": {
      borderColor: "#0a6836",
    },
    "&.Mui-error fieldset": {
      borderColor: "#f44336",
    },
  },
}));

type GFTextFieldProps = TextFieldProps & {};

export default function GFTextArea({ label, error, required, ...props }: GFTextFieldProps) {
  return (
    <div>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <StyledTextField
        {...props}
        multiline
        error={error ? true : false}
        helperText={error ? error : ""}
      />
    </div>
  );
}
