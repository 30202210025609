import IconCD from "@Assets/images/mock/ic-cambodia.png";
import IconEN from "@Assets/images/mock/ic-english.png";
import IconMA from "@Assets/images/mock/ic-myanmar.png";
import IconVN from "@Assets/images/mock/ic-vietnam.png";
import { LeadStatus, PlanStatus, Reason, ReportPermission } from "@Models";
import { APP_ROUTES } from "./module.constant";
// import IconCN from "@Assets/images/mock/ic-china.png";
import { LANGUAGES } from "./other.constant";

export const dept_headers = [
  { label: "Số hoá đơn" },
  { label: "Ngày  hoá đơn " },
  { label: "Công nợ" },
  { label: "Chưa đến hạn" },
  { label: "Tới hạn" },
  { label: "Quá hạn" },
  { label: "1 - 7 ngày" },
  { label: "8 - 14 ngày" },
  { label: "15 - 21 ngày" },
  { label: "22 - 28 ngày" },
  { label: "29 - 60 ngày" },
  { label: "61 - 90 ngày" },
  { label: "91 - 120 ngày" },
  { label: "121 - 180 ngày" },
  { label: "181 - 365 ngày" },
  { label: "366 - 730 ngày" },
  { label: "731 - 1095 ngày" },
  { label: "Trên 1095 ngày" },
];

export const amtRange = [
  { label: "amtRange1" },
  { label: "amtRange2" },
  { label: "amtRange3" },
  { label: "amtRange4" },
  { label: "amtRange5" },
  { label: "amtRange6" },
  { label: "amtRange7" },
  { label: "amtRange8" },
  { label: "amtRange9" },
  { label: "amtRange10" },
  { label: "amtRange11" },
  { label: "amtRange12" },
];

export const AccessHistory = [
  {
    id: "1",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "2",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "Điện thoại",
  },
  {
    id: "3",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "iPad",
  },
  {
    id: "4",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "5",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "6",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "7",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "8",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "9",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
  {
    id: "10",
    time: "25/10/2020 lúc 21:00",
    ip: "109.233.17.75",
    status: "Thành công",
    unit: "PC",
  },
];

export const NavHeaderOrder = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "CART",
    value: "draft",
  },
  {
    id: "3",
    label: "CREATED",
    value: "created",
  },
  {
    id: "4",
    label: "PENDING",
    value: "verified",
  },
  {
    id: "5",
    label: "RECORDED",
    value: "confirmed",
  },
  {
    id: "6",
    label: "DELIVERING",
    value: "processing",
  },
  {
    id: "7",
    label: "DELIVERED",
    value: "completed",
  },
  {
    id: "8",
    label: "CANCELLED",
    value: "cancelled",
  },
];

export const NavHeaderOrderFarm = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "CART",
    value: "draft",
  },
  {
    id: "3",
    label: "CUSTOMER_CONFIRM_ORDER",
    value: "created",
    // Khách hàng xác nhận đặt hàng
  },
  {
    id: "4",
    label: "COMPANY_CONFIRM_ORDER",
    value: "confirmed",
    // Xác nhận từ công ty
  },
  {
    id: "5",
    label: "WAIT_FOR_SHIPPING",
    value: "shipping",
    // Chờ vận chuyển
  },
  {
    id: "6",
    label: "IN_SHIPPING",
    value: "verified",
    // Đang giao
  },
  {
    id: "7",
    label: "PARTIALLY_DELIVERED",
    value: "halfCompleted",
    // Giao 1 phần
  },
  {
    id: "8",
    label: "DELIVERED",
    value: "completed",
    // Đã giao
  },
  {
    id: "9",
    label: "CANCELLED",
    value: "cancelled",
    // Đã huỷ
  },
];

export const NavHeaderOrderLab = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "DRAFT",
    value: "draft",
  },
  {
    id: "3",
    label: "SEND_REQUEST",
    value: "created",
  },
  {
    id: "4",
    label: "CONFIRMED",
    value: "confirmed",
  },
  {
    id: "5",
    label: "TRANSFER_SAMPLE",
    value: "processing",
  },
  {
    id: "6",
    label: "SAMPLE_SCHEDULED",
    value: "scheduled",
  },
  {
    id: "7",
    label: "SAMPLE_RECEIVED",
    value: "received",
  },
  {
    id: "8",
    label: "LAB_COMPLETED",
    value: "completed",
  },
  {
    id: "9",
    label: "CANCELLED",
    value: "cancelled",
  },
  {
    id: "10",
    label: "REJECTED",
    value: "rejected",
  },
];

export const UpdateHistory = [
  {
    id: "1",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
  {
    id: "2",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
  {
    id: "3",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
  {
    id: "4",
    time: "25/10/2020 lúc 21:00",
    name: "Brooklyn",
    update: "Thay đổi Tên khách hàng",
  },
];

export const NavHeaderAccount = [
  {
    id: "0",
    label: "ALL",
    value: undefined,
    title: "all",
  },
  {
    id: "1",
    label: "ACTIVE",
    value: true,
    title: "activated",
  },
  {
    id: "2",
    label: "DISABLE",
    value: false,
    title: "locked",
  },
  {
    id: "3",
    label: "CANCELLED",
    value: "cancelled",
    title: "cancelled",
  },
];

export const NavHeaderSubAccount = [
  {
    id: "0",
    label: "ALL",
    value: "ALL",
    title: undefined,
  },
  {
    id: "1",
    label: "OPENING",
    value: "OPENING",
    title: "OPENING",
  },
  {
    id: "2",
    label: "PENDING",
    value: "PENDING",
    title: "PENDING",
  },
  {
    id: "3",
    label: "ACTIVATED",
    value: "ACTIVATED",
    title: "ACTIVATED",
  },
  {
    id: "4",
    label: "LOCKED",
    value: "LOCKED",
    title: "LOCKED",
  },
  {
    id: "5",
    label: "CANCELLED",
    value: "CANCELLED",
    title: "CANCELLED",
  },
];

export const NavHeaderLead = [
  {
    id: "0",
    label: "Tất cả",
    value: undefined,
    title: "total",
  },
  {
    id: "1",
    label: "Đã duyệt",
    value: LeadStatus.APPROVED,
    title: LeadStatus.APPROVED,
  },
  // {
  //   id: "2",
  //   label: "Từ chối",
  //   value: LeadStatus.DENIED,
  //   title: LeadStatus.DENIED,
  // },
  {
    id: "2",
    label: "Chưa duyệt",
    value: LeadStatus.PENDING,
    title: LeadStatus.PENDING,
  },
];

export const NavHeaderNews = [
  {
    id: "0",
    label: "ALL",
    value: "all",
  },
  {
    id: "1",
    label: "PUBLISHED",
    value: "active",
  },
  {
    id: "2",
    label: "DRAFTS",
    value: "isDraft",
  },
  {
    id: "3",
    label: "HIDDEN",
    value: "delete",
  },
];

export const FilterList: { id: string; title: string }[] = [
  {
    id: "1",
    title: "Tất cả",
  },
  {
    id: "2",
    title: "Yêu cầu mới",
  },
  {
    id: "3",
    title: "Đã duyệt",
  },
  {
    id: "4",
    title: "Từ chối",
  },
];

export const ConditionQuantity = [
  { value: ">", title: "Lớn hơn" },
  { value: ">=", title: "Lớn hơn hoặc bằng" },
  { value: "<", title: "Nhỏ hơn" },
  { value: "<=", title: "Nhỏ hơn hoặc bằng" },
  // { value: "==", title: "Bằng" },
  // { value: "!=", title: "Không bằng" },
  // { value: "<&&<", title: "Ở giữa" },
  // { value: ">||>", title: "Không nằm trong khoảng" },
];

export enum OrderStatusERP {
  CREATED_ERP = "createdERP",
  NOT_CREATE_ERP = "notCreatedERP",
  UPDATED_ERP = "updatedERP",
  NOT_UPDATED_ERP = "notUpdatedERP",
}

export enum OrderStatusLab {
  DRAFT = "draft",
  CREATED = "created",
  CONFIRMED = "confirmed",
  PROCESSING = "processing",
  SCHEDULED = "scheduled",
  RECEIVED = "received",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  REJECTED = "rejected",
}

export const StatusOrder = [
  {
    id: "1",
    label: "ALL",
    value: "all",
  },
  {
    id: "2",
    label: "ERP_CREATED",
    value: "createdERP",
  },
  {
    id: "3",
    label: "ERP_NOT_CREATED",
    value: "notCreatedERP",
  },
  {
    id: "4",
    label: "ERP_UPDATED",
    value: "updatedERP",
  },
  {
    id: "5",
    label: "ERP_NOT_UPDATED",
    value: "notUpdatedERP",
  },
  {
    id: "6",
    label: "Phản hồi",
    value: "isFeedBack",
  },
];

export const StatusOrderFarm = [
  {
    id: "1",
    label: "ALL",
    value: "ALL",
  },
  {
    id: "2",
    label: "ORDER_HAS_BEEN_ALLOCATED", //ĐH đã được phân bổ
    value: "ORDER_HAS_BEEN_ALLOCATED",
  },
  {
    id: "3",
    label: "ORDER_CONFIRMED_NOT_YET_SYNCHRONIZED_ERP", //ĐH đã xác nhận trại -- chưa đồng bộ ERP
    value: "ORDER_CONFIRMED_NOT_YET_SYNCHRONIZED_ERP",
  },
  {
    id: "4",
    label: "ORDER_CONFIRMED_AWAITING_CREDIT_APPROVAL", //ĐH đã xác nhận trại (chờ duyệt nợ)
    value: "ORDER_CONFIRMED_AWAITING_CREDIT_APPROVAL",
  },
  {
    id: "5",
    label: "FIRST_DEPOSIT_HAS_BEEN_MADE",
    value: "FIRST_DEPOSIT_HAS_BEEN_MADE", //Đã cọc lần 1
  },
  {
    id: "6",
    label: "FIRST_DEBT_APPROVAL_HAS_BEEN_GRANTED",
    value: "FIRST_DEBT_APPROVAL_HAS_BEEN_GRANTED", //Được duyệt nợ lần 1
  },
  {
    id: "7",
    label: "VEHICLE_HAS_NOT_BEEN_SCHEDULED",
    value: "VEHICLE_HAS_NOT_BEEN_SCHEDULED", //Chưa sắp xe
  },
  {
    id: "8",
    label: "VEHICLE_HAS_BEEN_SCHEDULEDNOT_YET_SYNCHRONIZED_ERP",
    value: "VEHICLE_HAS_BEEN_SCHEDULEDNOT_YET_SYNCHRONIZED_ERP", //Đã sắp xe - chưa đồng bộ ERP
  },
  {
    id: "9",
    label: "VEHICLE_HAS_BEEN_SCHEDULED",
    value: "VEHICLE_HAS_BEEN_SCHEDULED", //Đã sắp xe
  },
  {
    id: "10",
    label: "SECOND_DEPOSIT_HAS_BEEN_MADE",
    value: "SECOND_DEPOSIT_HAS_BEEN_MADE", //Đã cọc lần 2
  },
  {
    id: "11",
    label: "SECOND_DEBT_APPROVAL_HAS_BEEN_GRANTED",
    value: "SECOND_DEBT_APPROVAL_HAS_BEEN_GRANTED", //Được duyệt nợ lần 2
  },
  {
    id: "12",
    label: "AWAITING_SHIPMENT",
    value: "AWAITING_SHIPMENT", //Chờ xuất kho
  },
];

export const ReasonStatus = [
  {
    id: "1",
    label: "NOT_RESPONSE",
    value: Reason.NOT_RESPONSE,
  },
  {
    id: "2",
    label: "CHANGE_OF_MIND",
    value: Reason.CHANGE_OF_MIND,
  },
  {
    id: "3",
    label: "DUPLICATE_ORDER",
    value: Reason.DUPLICATED,
  },
  {
    id: "4",
    label: "OTHER",
    value: Reason.OTHER,
  },
];

export const ReasonCloseOrder = [
  {
    id: "1",
    label: "INSUFFICIENT_SUPPLY_SOURCE",
    value: Reason.NOT_ENOUGH,
  },
  {
    id: "2",
    label: "CUSTOMER_REQUESTS_CANCEL_ORDER",
    value: Reason.CUSTOMER_SUGGEST,
  },
  {
    id: "3",
    label: "DIFFERENT",
    value: Reason.OTHER,
  },
];

export const ReasonPlan = [
  {
    id: "1",
    label: "Không nhận được phản hồi từ nhân viên",
    value: Reason.NOT_RESPONSE,
  },
  {
    id: "2",
    label: "Thay đổi yêu cầu",
    value: "2",
  },
  {
    id: "3",
    label: "Yêu cầu đã tồn tại",
    value: "3",
  },
  {
    id: "4",
    label: "Khác",
    value: Reason.OTHER,
  },
];

export const SubTab = [
  {
    label: "Tổng quan",
    path: APP_ROUTES.DASHBOARD_OVERVIEW,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_OVERVIEW,
  },
  {
    label: "Sản lượng",
    path: APP_ROUTES.REPORT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_SALES_VOLUMES,
  },
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
  {
    label: "Chiết khấu",
    path: APP_ROUTES.DISCOUNT,
    source: "feed",
    permission: ReportPermission.MANAGE_REPORT_DISCOUNT,
  },
  /*farm */
  {
    label: "Doanh thu",
    path: APP_ROUTES.REPORT_REVENUE,
    source: "farm",
    permission: ReportPermission.MANAGE_REPORT_REVENUE,
  },
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "farm",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
  /*vet */
  {
    label: "Công nợ",
    path: APP_ROUTES.DEBT,
    source: "vet",
    permission: ReportPermission.MANAGE_REPORT_DEBT,
  },
];

export const SubTabFarm = [
  {
    label: "Heo giống",
    path: APP_ROUTES.ORDER_FARM,
    source: "feed",
    permission: "",
    id: 19,
  },
  {
    label: "Heo thịt",
    path: APP_ROUTES.ORDER_FARM_MARKET_PIGS,
    source: "feed",
    permission: "",
    id: 18,
  },
  {
    label: "Heo loại",
    path: APP_ROUTES.ORDER_FARM_CULLED_PIGS,
    source: "feed",
    permission: "",
  },
  {
    label: "Heo nội bộ",
    path: APP_ROUTES.ORDER_FARM_INTERNAL_PIGS,
    source: "feed",
    permission: "",
  },
  {
    label: "Tinh heo",
    path: APP_ROUTES.ORDER_FARM_BOAR_SEMEN,
    source: "feed",
    permission: "",
  },
];

// export const SubTabFarm = [
//   {
//     label: "Heo giống",
//     path: APP_ROUTES.DASHBOARD_OVERVIEW,
//     source: "farm",
//     permission: "",
//   },
//   {
//     label: "Heo thịt",
//     path: APP_ROUTES.REPORT,
//     source: "farm",
//     permission: "",
//   },
//   {
//     label: "Heo loại",
//     path: APP_ROUTES.DEBT,
//     source: "farm",
//     permission: "",
//   },
//   { label: "Heo nội bộ", path: APP_ROUTES.DISCOUNT, source: "feed", permission: "" },
// ];

export const Month = [
  {
    month: "01",
  },
  {
    month: "02",
  },
  {
    month: "03",
  },
  {
    month: "04",
  },
  {
    month: "05",
  },
  {
    month: "06",
  },
  {
    month: "07",
  },
  {
    month: "08",
  },
  {
    month: "09",
  },
  {
    month: "10",
  },
  {
    month: "11",
  },
  {
    month: "12",
  },
];

export const PriceType = [
  { value: "bs", title: "Cơ bản" },
  { value: "fixed", title: "Cố định" },
  { value: "increment", title: "Lũy tiến" },
];

export const DebtHeader = [
  { label: "Tổ chức kinh doanh" },
  { label: "Ngành hàng" },
  { label: "Khách hàng" },
  { label: "Mã khách hàng" },
  { label: "Hạn Mức Tín Dụng" },
  { label: "Tổng Công Nợ" },
  { label: "Tổng Nợ Quá Hạn" },
  { label: "1 - 7 ngày" },
  { label: "8 - 14 ngày" },
  { label: "15 - 21 ngày" },
  { label: "22 - 28 ngày" },
  { label: "29 - 60 ngày" },
  { label: "61 - 90 ngày" },
  { label: "91 - 120 ngày" },
  { label: "121 - 180 ngày" },
  { label: "181 - 365 ngày" },
  { label: "366 - 730 ngày" },
  { label: "731 - 1095 ngày" },
  { label: "Trên 1095 ngày" },
  { label: "Giám đốc kinh doanh" },
  { label: "Trợ lý kinh doanh" },
  { label: "Giám sát kinh doanh" },
  { label: "Nhân viên kinh doanh" },
];

export const sumRange = [
  { label: "sumRange1" },
  { label: "sumRange2" },
  { label: "sumRange3" },
  { label: "sumRange4" },
  { label: "sumRange5" },
  { label: "sumRange6" },
  { label: "sumRange7" },
  { label: "sumRange8" },
  { label: "sumRange9" },
  { label: "sumRange10" },
  { label: "sumRange11" },
  { label: "sumRange12" },
];

export const sumAmt = [
  { label: "sumAmt1" },
  { label: "sumAmt2" },
  { label: "sumAmt3" },
  { label: "sumAmt4" },
  { label: "sumAmt5" },
  { label: "sumAmt6" },
  { label: "sumAmt7" },
  { label: "sumAmt8" },
  { label: "sumAmt9" },
  { label: "sumAmt10" },
  { label: "sumAmt11" },
  { label: "sumAmt12" },
];

export const DataAccessFeed = [
  {
    id: 0,
    value: "sales-org",
    label: "Tổ chức kinh doanh",
  },
  {
    id: 1,
    value: "divisions",
    label: "Ngành hàng",
  },
  {
    id: 2,
    value: "sales-man",
    label: "Giám đốc kinh doanh",
  },
];

export const DataAccessFarm = [
  {
    id: 0,
    value: "sales-org",
    label: "Tổ chức kinh doanh",
  },
  {
    id: 1,
    value: "site",
    label: "Trại",
  },
  {
    id: 2,
    value: "sales-man",
    label: "Giám đốc kinh doanh",
  },
  {
    id: 3,
    value: "products",
    label: "Sản phẩm",
  },
];

export const TypeSms = [
  {
    value: "all",
    label: "Tất cả",
  },
  {
    value: "otp",
    label: "OTP",
  },
  {
    value: "resetPassword",
    label: "Cấp lại mật khẩu",
  },
  {
    value: "signup",
    label: "Đăng ký tài khoản",
  },
];

export const StatusSms = [
  {
    value: "all",
    label: "Tất cả",
  },
  {
    value: "succeed",
    label: "Thành công",
  },
  {
    value: "failed",
    label: "Thất bại",
  },
];

export const AccountTypeSms = [
  {
    value: "customer",
    label: "Khách hàng",
  },
  {
    value: "employee",
    label: "Nhân viên",
  },
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "role",
    label: "Role",
  },
];

export const SourceType = [
  {
    value: "all",
    label: "Tất cả",
  },
  {
    value: "farm",
    label: "Trang trại và con giống",
  },
  {
    value: "feed",
    label: "Thức ăn chăn nuôi",
  },
];

export const Actors = [
  {
    value: "customers",
    label: "Khách hàng",
  },
  {
    value: "employees",
    label: "Nhân viên",
  },
  {
    value: "roles",
    label: "Role",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export const ActorForNews = [
  {
    value: "customer",
    label: "CUSTOMER",
  },
  {
    value: "employee",
    label: "EMPLOYEE",
  },
  {
    value: "role",
    label: "ROLE",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

export const TypeNoti = [
  {
    value: "all",
    label: "Tất cả",
  },
  {
    value: "order",
    label: "Đơn hàng",
  },
  {
    value: "export",
    label: "Xuất dữ liệu",
  },
  {
    value: "notify",
    label: "Thông báo tự động",
  },
];

export const StatusNoti = [
  {
    value: "all",
    label: "Tất cả",
  },
  {
    value: "seen",
    label: "Đã xem",
  },
  {
    value: "notSeen",
    label: "Chưa xem",
  },
];

export const ReportRevenueHeader = [
  { label: "Báo cáo tổng" },
  { label: "Báo cáo chi tiết" },
  { label: "Sản lượng bán" },
  { label: "Tổng trọng lượng" },
  { label: "TLBQ" },
  { label: "ĐGBQ" },
  { label: "DT trước vận chuyển" },
  { label: "DT vận chuyển" },
  { label: "Tổng doanh thu (DT)" },
];

export const ReportDebtFarmHeader = [
  { label: "BU" },
  { label: "Khách hàng" },
  { label: "Current" },
  { label: "1 to 7" },
  { label: "8 to 14" },
  { label: "15 to 21" },
  { label: "Over 21" },
  { label: "Tổng cộng" },
  { label: "Bảo lãnh" },
  { label: "Ngày cập nhật cuối cùng" },
];

export const DataLanguageHeader = [
  { id: 0, label: "Tiếng Việt", icon: IconVN, code: LANGUAGES.vi },
  { id: 1, label: "English", icon: IconEN, code: LANGUAGES.en },
  { id: 2, label: "Cambodia", icon: IconCD, code: LANGUAGES.km },
  { id: 3, label: "Myanmar", icon: IconMA, code: LANGUAGES.my },
  // { id: 4, label: "Trung Quốc", icon: IconCN, code: LANGUAGES.vi },
];

export const StatusPlan = [
  {
    id: "1",
    label: "ALL",
    value: "ALL",
  },
  {
    id: "2",
    label: "Đang soạn",
    value: PlanStatus.OPENING,
  },
  {
    id: "3",
    label: "Đã tạo",
    value: PlanStatus.CREATED,
  },
  {
    id: "4",
    label: "Đáp ứng",
    value: PlanStatus.ADAPTED,
  },
  {
    id: "5",
    label: "Cần điều chỉnh",
    value: PlanStatus.CHANGING,
  },
  {
    id: "6",
    label: "Đã xác nhận",
    value: PlanStatus.CONFIRMED,
  },
  {
    id: "7",
    label: "Đang xử lý",
    value: PlanStatus.PROCESSING,
  },
  {
    id: "8",
    label: "Từ chối",
    value: PlanStatus.REJECTED,
  },
  {
    id: "9",
    label: "Đã đóng",
    value: PlanStatus.CLOSED,
  },
  {
    id: "10",
    label: "Không đáp ứng",
    value: PlanStatus.NOT_ADAPTED,
  },
];
