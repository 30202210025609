import { z } from "zod";

export const checkCustomerDuplicateSchema = z.object({
  idCard: z
    .string({
      message: "Nhập CCCD/CMND",
    })
    .min(1, {
      message: "CCCD/CMND không được để trống",
    }),
  taxId: z
    .string({
        message: "Nhập mã số thuế",
    })
    .min(1, {
        message: "Mã số thuế không được để trống",
    }),
  phoneNumber: z
    .string({
      message: "Nhập Số điện thoại",
    })
    .min(10, {
      message: "Số điện thoại phải có ít nhất 10 chữ số",
    })
    .max(11, {
      message: "Số điện thoại không được vượt quá 11 chữ số",
    })
    .regex(/^\d+$/, {
      message: "Số điện thoại chỉ được chứa số",
    }),
  email: z
    .string({
      message: "Nhập Email",
    })
    .min(1, {
      message: "Email không được để trống",
    }),
});

export type CheckCustomerDuplicatePayload = z.infer<typeof checkCustomerDuplicateSchema>;
