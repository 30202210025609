import { Box } from "@mui/material";
import { isFunction } from "lodash";
import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

type FormItemProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  children: React.ReactElement;
};

export default function GFFormItem<T extends FieldValues>({
  control,
  name,
  children,
}: FormItemProps<T>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const handleChange = (value: unknown, optionData: any, callback?: (value: unknown, optionData?: any) => void) => {
    field.onChange(value);

    if (isFunction(callback)) {
      callback(value, optionData);
    }
  };

  return (
    <Box sx={{ mb: 2}}>
      {React.cloneElement(children, {
        value: field.value,
        onChange: (value: unknown, optionData?: any) => handleChange(value, optionData, children.props.onChange),
        error: error?.message,
      })}
    </Box>
  );
}
