import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const GFLayoutWrapper = styled(Box)<{ pageScroll?: boolean }>(({ pageScroll }) => ({
  padding: "4rem",
  display: "flex",
  flexDirection: "column",
}));

export default GFLayoutWrapper;
