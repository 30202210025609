import ImgEmpty from "@Assets/images/img_empty.png";
import Loading from "@Components/Loading";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { NewsDetailOutputModel } from "@Models";
import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./NewsDetail.scss";
import { BASE_LINK_IMAGE, TEXT_HOLDER } from "@Constants";

type P_Props = {
  data: NewsDetailOutputModel | null;
  closeForm: () => void;
};

const NewsPreview: React.FC<P_Props> = ({ closeForm, data }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);

  return (
    <div className="news-detail">
      <SimpleModalHeader title={t("PREVIEW")} onClose={closeForm} />
      {!!data ? (
        <div className="content">
          <Grid container>
            <Grid item xs={8} className="content-info">
              <div className="body">
                <div className="content-title">{data.title}</div>
                <div className="content-body" style={{ paddingBottom: "2rem" }}>
                  <p>{data.body}</p>
                  <a
                    style={{
                      color: "#0A6836",
                      textDecoration: "underline",
                    }}
                    href={data.url || TEXT_HOLDER}
                    target="_blank"
                    rel="noopener noreferrer">
                    {data.url || TEXT_HOLDER}
                  </a>
                </div>
                <div className="content-image">
                  {!!data.image ? (
                    <img src={BASE_LINK_IMAGE + data.image} alt="news" />
                  ) : (
                    <img src={ImgEmpty} alt="news" />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Loading style={{ margin: "2rem 0", height: "80vh" }} />
      )}
    </div>
  );
};

export default NewsPreview;
