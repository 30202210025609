/* eslint-disable react-hooks/exhaustive-deps */
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import Loading from "@Components/Loading";
import { Month } from "@Constants";
import { useAppDispatch } from "@Core/store/hook";
import { loadProfile } from "@Core/store/profile";
import { Action, DepositOutputModel, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { IWareHouse } from "@Models/warehouse.model";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ContentCopy,
  Search as SearchIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { WarehouseService } from "@Services/warehouse.service";
import { YearList } from "@Utils";
import _, { get } from "lodash";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CTable from "../components";
import { Align, Font, IColumn } from "../components/const";
import { SettingsIcon } from "@Components/Icons/SettingsIcon";
import "./index.scss";
import BreedingTransfer from "@Containers/Warehouse/breeding/breeding-transfer";
import { GFButton } from "@Components/ui";
function reducer(state: PaginateFetchReducer<DepositOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

type ColumnView = {
  key: string;
  label: string;
  show: boolean;
};

const BreedingPigs: React.FC = () => {
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const { t } = useTranslation(["translation", "product"]);
  const mounted = useRef(false);
  // const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  // eslint-disable-next-line
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [time, setTime] = useState({
    month: month,
    year: year,
  });
  const [columnViews, setColumnViews] = useState<ColumnView[]>([
    { key: "forecast", label: `Forecast tháng ${month}`, show: true },
    { key: "reservedTotal", label: "Tổng số lượng đã giữ", show: true },
    { key: "reserved", label: "Đã giữ", show: true },
    { key: "shippedTotal", label: "Tổng số lượng đã giao", show: true },
    { key: "shippedNum", label: "Đã giao", show: true },
    { key: "availableTotal", label: "Tổng số lượng khả dụng", show: true },
    { key: "availableNum", label: "Khả dụng", show: true },
    { key: "transferredTotal", label: "Tổng số lượng điều chuyển", show: true },
    { key: "transferredNum", label: "Điều chuyển", show: true },
    { key: "stock", label: "Tổng số lượng khả dụng sau điều chuyển", show: true },
  ]);
  const [settingAnchorEl, setSettingAnchorEl] = useState<HTMLButtonElement | null>(null);
  const settingOpen = Boolean(settingAnchorEl);

  const handleSettingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleCloseTransferModal = () => {
    setOpenTransferModal(false);
  };

  const handleToggleView = (key: string) => {
    setSettingAnchorEl(null);

    setTimeout(() => {
      const newColumnViews = columnViews.map((columnView) => {
        if (columnView.key === key) {
          return { ...columnView, show: !columnView.show };
        }
        return columnView;
      });

      setColumnViews(newColumnViews);
    }, 0);
  };

  // const handleData = (data: any, page: number) => {
  //   const duplicatedArray = Array.from({ length: page }, () => data).flat();
  //   console.log(duplicatedArray);
  //   return duplicatedArray;
  // }

  const dispatchProfile = useAppDispatch();
  // const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
    totalPages: 0,
    limit: 50,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
    columns: [],
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await WarehouseService.getAll(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const handlePagination = () => {
    fetchData({
      month: month,
      year: year,
      // weekNumber: 2,
      page: state.page + 1,
      limit: 1,
      searchString: searchValue,
    });
  };

  useEffect(() => {
    // if (refresh) {
    fetchData(
      {
        month: month,
        year: year,
        // weekNumber: 2,
        page: 1,
        limit: 50,
        searchString: searchValue,
      },
      true
    );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, time]);

  const hasMore = state.data.length < state.total && state.page < state.totalPages;
  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  // useEffect(() => {
  //   setRefresh(true);
  //   if (location.state?.startTime !== undefined) {
  //     setRange(moment(location.state?.startTime), moment(location.state?.endTime));
  //   } else {
  //     setRange(
  //       moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
  //       moment().set({ hours: 23, minutes: 59, seconds: 59 })
  //     );
  //   }
  // }, [location, profile]);

  const columns: IColumn[] = [
    {
      key: "bu",
      index: "bu",
      label: "BU",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "bu.name", "")} placement="top-end">
            <div>{get(record, "bu.name", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "employee",
      index: "employee",
      label: "Vùng (Sales Manager)",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "employee.fullName", "")} placement="top-end">
            <div>{get(record, "employee.fullName", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "itemAllocation",
      index: "itemAllocation",
      label: "Mã nhóm SP phân bổ",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "itemAllocation.code", "")} placement="top-end">
            <div>{get(record, "itemAllocation.code", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "itemAllocation",
      index: "itemAllocation",
      label: "Tên nhóm SP phân bổ",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "itemAllocation.name", "")} placement="top-end">
            <div
              style={{
                width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}>
              {get(record, "itemAllocation.name", "")}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: "Tổng số lượng tồn kho",
      index: "inventory",
      label: "Tổng số lượng tồn kho",
      sticky: true,
      width: 150,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks.0.inventory", "")} placement="top-end">
            <div>{get(record, "monthlyStocks.0.inventory", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "forecast",
      index: "forecast",
      label: `Forecast tháng ${month}`,
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.0.inventory", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.0.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.1.inventory", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.1.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.2.inventory", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.2.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.3.inventory", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.3.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.4.inventory", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.4.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "reservedTotal",
      index: "reservedTotal",
      label: "Tổng số lượng đã giữ",
      width: 140,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks.0.reservedTotal", "")} placement="top-end">
            <div>{get(record, "monthlyStocks.0.reservedTotal", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "reserved",
      index: "reserved",
      label: "Đã giữ",
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.0.reservedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.0.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.1.reservedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.1.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.2.reservedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.2.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.3.reservedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.3.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.4.reservedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.4.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "shippedTotal",
      index: "shippedTotal",
      label: "Tổng số lượng đã giao",
      width: 150,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks.0.shippedTotal", "")} placement="top-end">
            <div>{get(record, "monthlyStocks.0.shippedTotal", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "shippedNum",
      index: "shippedNum",
      label: "Đã giao",
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.0.shippedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.0.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.1.shippedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.1.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.2.shippedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.2.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.3.shippedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.3.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks.4.shippedNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks.4.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "availableTotal",
      index: "availableTotal",
      label: "Tổng số lượng khả dụng",
      width: 140,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks.0.availableTotal", "")} placement="top-end">
            <div>{get(record, "monthlyStocks.0.availableTotal", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "availableNum",
      index: "availableNum",
      label: "Khả dụng",
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks.0.weeklyStocks[0].availableNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks.0.weeklyStocks[0].availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[1].availableNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[1].availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[2].availableNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[2].availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[3].availableNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[3].availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[4].availableNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[4].availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "transferredTotal",
      index: "transferredTotal",
      label: "Tổng số lượng điều chuyển",
      width: 150,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks[0].transferredTotal", "")} placement="top-end">
            <div>{get(record, "monthlyStocks[0].transferredTotal", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "transferredNum",
      index: "transferredNum",
      label: "Điều chuyển",
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[0].transferredNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[0].transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[1].transferredNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[1].transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[2].transferredNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[2].transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[3].transferredNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[3].transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[4].transferredNum", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[4].transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "stock",
      index: "stock",
      label: "Tổng số lượng khả dụng sau điều chuyển",
      width: 190,
      align: Align.right,
      font: Font.bold,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "monthlyStocks[0].stock", "")} placement="top-end">
            <div>{get(record, "monthlyStocks[0].stock", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "stock",
      index: "stock",
      label: "Khả dụng sau điều chuyển",
      children: [
        {
          key: "W1",
          index: "W1",
          label: t("product:WEEK_1"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[0].stock", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[0].stock", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W2",
          index: "W2",
          label: t("product:WEEK_2"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[1].stock", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[1].stock", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W3",
          index: "W3",
          label: t("product:WEEK_3"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[2].stock", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[2].stock", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W4",
          index: "W4",
          label: t("product:WEEK_4"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[3].stock", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[3].stock", "")}</div>
              </Tooltip>
            );
          },
        },
        {
          key: "W5",
          index: "W5",
          label: t("product:WEEK_5"),
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip
                title={get(record, "monthlyStocks[0].weeklyStocks[4].stock", "")}
                placement="top-end">
                <div>{get(record, "monthlyStocks[0].weeklyStocks[4].stock", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
  ];

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => {
      const columnView = columnViews.find((c) => c.key === col.key);
      return columnView ? columnView.show : true;
    });
  }, [columnViews, columns]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTransferSuccess = async () => {
    await fetchData(
      {
        month: month,
        year: year,
        page: 1,
        limit: 50,
        searchString: searchValue,
      },
      true
    );
  };

  return (
    <>
      {openTransferModal && (
        <BreedingTransfer
          open={openTransferModal}
          onClose={handleCloseTransferModal}
          onTransferSuccess={handleTransferSuccess}
        />
      )}

      <div className="warehouse-content breeding">
        <div className="action">
          <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
            <GFButton
              variant="contained"
              onClick={handleOpenTransferModal}>
              Điều chuyển
            </GFButton>

            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              style={{ marginRight: 0 }}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>

            <Button
              id="basic-button"
              className="breeding__grear-icon"
              aria-controls={settingOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={settingOpen ? "true" : undefined}
              onClick={handleSettingClick}>
              <SettingsIcon color="#333" size={[24, 24]} viewBox={[32, 32]} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={settingAnchorEl}
              open={settingOpen}
              onClose={() => setSettingAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}>
              {columnViews.map((columnView, idx) => (
                <MenuItem
                  key={columnView.key}
                  onClick={() => handleToggleView(columnView.key)}
                  className={`breeding__menu-item ${columnView.show ? "show" : "hide"}`}>
                  <ListItemIcon>
                    {columnView.show ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff className="breeding__menu-item__hide-icon" />
                    )}
                  </ListItemIcon>
                  <p className="breeding__menu-item__text">{columnView.label}</p>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
        <div className="list" style={{ flex: 1 }}>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : state.data && state.data.length > 0 ? (
            <CTable
              columns={filteredColumns}
              data={state?.data ?? []}
              hasMore={hasMore}
              handlePagination={handlePagination}
            />
          ) : (
            <EmptyList
              img={Ord}
              title={t("order:NO_ORDER_PLACED")}
              text={t("order:PLACE_ORDER_NOW")}
            />
          )}
          {/* {state.data && state.data.length > 0 ? (
              <CTable columns={columns} data={state?.data ?? []} />
            ) : (
              <EmptyList
                img={Ord}
                title={t("order:NO_ORDER_PLACED")}
                text={t("order:PLACE_ORDER_NOW")}
              />
            )} */}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime({
                  month: month,
                  year: year,
                });
                handleClose();
                // setDetailLetter(null);
                // dispatch({
                //   type: Action.RESET,
                //   payload: result.data,
                // });
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default React.memo(BreedingPigs);
