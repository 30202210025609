import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const GFProductItemWrapper = styled(Box)<{
  isActive?: boolean;
  hover?: boolean;
  size?: "small" | "medium";
}>(({ isActive, hover, size }) => ({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  gap: "1.6rem",
  borderRadius: "8px",
  ...(hover && {
    padding: "1.6rem 3.6rem",
    cursor: "pointer",
    borderBottom: "1px solid var(--border-color)",
    "&:hover": {
      backgroundColor: "var(--hover-green)",
    },
  }),
  ...(isActive && {
    backgroundColor: "var(--hover-green)",
  }),
}));

export const GFProductItemWrapperV2 = styled(Box)<{
  isActive?: boolean;
  hover?: boolean;
  size?: "small" | "medium";
}>(({ isActive, hover, size }) => ({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  gap: "1.6rem",
  borderRadius: "8px",
  marginBottom: '12px',
  border: "1px solid #cacaca",
  background: '#fff',
  transition: 'all .4s',
  ...(hover && {
    padding: "1.6rem 3.6rem",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "var(--hover-green)",
    },
  }),
  ...(isActive && {
    backgroundColor: "var(--hover-green)",
    borderColor: 'var(--primary-green)',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
  }),
}));

export const GFProductItemAvatar = styled("img")<{ size?: "small" | "medium" }>(({ size }) => ({
  width: "6.4rem",
  height: "6.4rem",
  ...(size === "small" && {
    width: "4.8rem",
    height: "4.8rem",
  }),
  objectFit: "cover",
  borderRadius: "50%",
  border: "1px solid var(--border-color)",
  flexShrink: 0,
}));
