import { APPROVE_BUSINESS_TYPE, APPROVE_BY } from "@Constants";
import { z } from "zod";

export const approvalProcessSchema = z.object({
  approvalStatusFormula: z
    .string({
      message: "Nhập công thức trạng thái",
    })
    .min(1, {
      message: "Công thức trạng thái không được để trống",
    }),
  approvalFormula: z
    .string({
      message: "Nhập công thức cấp duyệt",
    })
    .min(1, {
      message: "Công thức cấp duyệt không được để trống",
    }),
  businessType: z.nativeEnum(APPROVE_BUSINESS_TYPE, {
    message: "Nghiệp vụ không hợp lệ",
  }),
  approvalBy: z.nativeEnum(APPROVE_BY, {
    message: "Loại duyệt không hợp lệ",
  }),
  description: z.string().optional(),
});

export type ApprovalProcessPayload = z.infer<typeof approvalProcessSchema>;
