import { IconBase, IconComponent } from "@Core/Icons";

export const CampCoordinatorIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M12.7161 18.0789V19.5C14.1274 19.3593 15.4122 18.7965 16.4514 17.9452L15.4544 16.9392C14.661 17.5598 13.7148 17.9536 12.7161 18.0789ZM6.40421 12.5C6.40421 9.65075 8.53159 7.28693 11.2839 6.92111V5.5C7.75226 5.87286 5 8.86985 5 12.5C5 16.1301 7.75226 19.1271 11.2839 19.5V18.0789C8.53159 17.7131 6.40421 15.3492 6.40421 12.5ZM17.5817 11.7965H19C18.8596 10.3824 18.2979 9.09497 17.4483 8.05377L16.4443 9.0598C17.0481 9.83367 17.4554 10.7693 17.5817 11.7965ZM16.4514 7.05477C15.3859 6.17992 14.0867 5.63914 12.7161 5.5V6.92111C13.7412 7.04774 14.675 7.45578 15.4544 8.0608L16.4514 7.05477ZM16.4443 15.9472L17.4483 16.9462C18.3226 15.8793 18.8625 14.5771 19 13.2035H17.5817C17.4567 14.2042 17.0637 15.1523 16.4443 15.9472Z"
        fill={props.color}
      />
      <path
        d="M15 12.37C15 10.627 13.575 9.5 12 9.5C10.425 9.5 9 10.627 9 12.37C9 13.532 9.9975 14.911 12 16.5C14.0025 14.911 15 13.532 15 12.37ZM12 13C11.7932 12.9917 11.5978 12.9091 11.4546 12.7695C11.3114 12.63 11.2315 12.4442 11.2315 12.251C11.2315 12.0578 11.3114 11.872 11.4546 11.7325C11.5978 11.5929 11.7932 11.5103 12 11.502C12.2068 11.5103 12.4022 11.5929 12.5454 11.7325C12.6886 11.872 12.7685 12.0578 12.7685 12.251C12.7685 12.4442 12.6886 12.63 12.5454 12.7695C12.4022 12.9091 12.2068 12.9917 12 13Z"
        fill={props.color}
      />
    </IconBase>
  );
};
