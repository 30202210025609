import GFTextField from "@Components/ui/GFTextField";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

type GFSearchInputProps = {
  value?: string;
  onSearch?: (value: string) => void;
  placeholder?: string;
};

export default function GFSearchInput({
  value = "",
  placeholder = "",
  onSearch,
}: GFSearchInputProps) {
  const [searchValue, setSearchValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchQuery: string) => {
      if (onSearch) {
        onSearch(searchQuery);
      }
    }, 400),
    [onSearch]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    debounceSearch(newValue);
  };

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <GFTextField
      placeholder={placeholder}
      variant="outlined"
      inputProps={{ className: "gf-search-input" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="large" className="icon" />
          </InputAdornment>
        ),
      }}
      value={searchValue}
      onChange={handleSearchChange}
      sx={{ maxWidth: "50rem" }}
    />
  );
}
