import GFProductItemV2 from "@Components/ui/GFProductItem/GFProductItemV2";
import GFProductSearch from "@Components/ui/GFProductSearch";
import { ProductOutputModel } from "@Models";
import { Box } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { useProductsInfinite } from "src/app/queries/product.query";
import { CreateOrderPayload } from "src/schemas/order.schema";

type OrderBoarSemenProductSearchProps = {
  sourceType: string;
  itemGroupId?: string;
  customerId: string;
  divisionId?: string;
  control: Control<CreateOrderPayload>;
};

function OrderBoarSemenProductSearch({
  sourceType,
  customerId,
  divisionId,
  itemGroupId,
  control,
}: OrderBoarSemenProductSearchProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const { append } = useFieldArray({
    control,
    name: "orderDetails",
  });

  const fields = useWatch<CreateOrderPayload>({
    control,
    name: "orderDetails",
  }) as any[];
  const { products, hasNextPage, fetchNextPage, isFetching, isLoading, total } =
    useProductsInfinite({
      limit: 10,
      source: sourceType,
      searchValue,
      isInternal: false,
      customerId,
      divisionId,
      itemGroupId,
    });

  const handleClickItem = (item: ProductOutputModel) => {
    if (_.some(fields, { item: { id: item.id } })) {
      return;
    }
    append({
      item: item,
      quantity: 1,
      condition: item.condition,
      itemId: item.item?.id,
      divisionId,
      discount: 0,
      note: "",
      saleUnit: item.item?.saleUnit,
      dimensionToShow: 0,
      baseUoM: item.baseUoM,
      dimension: "0",
      unitPrice: item.value,
    } as any);
  };

  return (
    <Box
      height={"calc(100vh - 6.4rem)"}
      sx={{
        backgroundColor: "var(--table-bg)",
      }}>
      <GFProductSearch
        data={products}
        loading={isLoading}
        fetchingNextPage={isFetching}
        hasMore={hasNextPage}
        onGetNextPage={fetchNextPage}
        searchValue={searchValue}
        onClickItem={handleClickItem}
        onSearchChange={(value) => {
          setSearchValue(value);
        }}
        renderItem={(item) => (
          <GFProductItemV2
            baseUoM={item.baseUoM}
            material={item.material}
            name={_.get(item, "item.name", "")}
            source={_.get(item, "item.source", "")}
            value={item.value}
            avatarUrl={_.get(item, "item.images", "") as any}
            isActive={_.some(fields, { item: { id: item.id } })}
            shơwPrice={false}
          />
        )}
      />
    </Box>
  );
}

export default OrderBoarSemenProductSearch;
