import { REQUEST_URL } from "@Constants";
import { RequestUtil } from "@Utils";

export const SalesOrgService = {
  getAllSalesOrg: (query: { sources?: any }) => {
    const url = REQUEST_URL.GET_SALES_ORG;

    return RequestUtil.get({ url, query });
  },

  getSalesOrgForRecall: (query: { divisionId: number; customerId: number }) => {
    const url = REQUEST_URL.GET_SALES_ORG_FOR_RECALL;

    return RequestUtil.get({ url, query });
  },
};
