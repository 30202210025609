/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import RangePicker from "@Components/RangePicker";
import { APP_ROUTES, NavHeaderNews, TEXT_HOLDER, TIME } from "@Constants";
import useDateRange from "@Hooks/useDateRange";
import { Action, DivisionModal, FetchAction, NewsOutputModel } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { FiberManualRecord as DotIcon, Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { NewService, ProductService } from "@Services";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";

function reducer(state: PaginateFetchReducer<NewsOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const News: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const location = useLocation<any>();
  const history = useHistory();
  const mounted = useRef(false);
  const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>("");
  const [dataDivision, setDataDivision] = useState<DivisionModal[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    onFetchApiDivision();
  }, []);

  useEffect(() => {
    setRefresh(true);
    if (!!location?.state?.selectTab) {
      setSelectTab(location.state?.selectTab);
    } else {
      setSelectTab("all");
    }
  }, [location]);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await NewService.getAllNews(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    if (refresh && !!range.startTime.toISOString() && !!range.endTime.toISOString()) {
      fetchData(
        {
          page: 1,
          limit: 50,
          searchString: searchValue,
          from: range.startTime.toISOString(),
          to: range.endTime.toISOString(),
          ...(selectTab !== "all" && { isDraft: !!(selectTab === "isDraft") }),
          ...(selectTab !== "all" &&
            selectTab !== "isDraft" && {
              isActive: !!(selectTab === "active"),
            }),
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectTab, range.startTime.toISOString(), range.endTime.toISOString()]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const onFetchApiDivision: () => Promise<void> = async () => {
    try {
      const response = await ProductService.getDivisionBySource({ getAll: true });
      if (mounted.current) {
        setDataDivision(() => response.data.data);
      }
    } catch (error) {}
  };

  const renderNameDivision = (divisionArr: number[]) => {
    return !!dataDivision.length ? dataDivision.filter((i) => divisionArr.includes(i.id)) : [];
  };

  return (
    <div className="news">
      <div className="news-content">
        <div className="action">
          <div className="news__nav-header">
            {NavHeaderNews.map((o, idx: number) => {
              return (
                <div
                  key={o.id + idx}
                  className={o.value === selectTab ? "tab tab-active" : "tab tab-hover"}
                  onClick={() => setSelectTab(o.value)}>
                  {t(`${o.label}`)} {o.value && <span>{state?.count?.[o.value]}</span>}
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              style={{ marginRight: "1rem", height: "5.2rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <RangePicker
              startDate={
                location.state?.startTime !== undefined
                  ? moment(location.state?.startTime)
                  : range.startTime
              }
              endDate={
                location.state?.endTime !== undefined
                  ? moment(location.state?.endTime)
                  : range.endTime
              }
              onChangeDate={(val) => {
                if (val && val.length) {
                  setRange(
                    moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                    moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="list">
          <div className="list-header">
            <div className="name">{t("ACCESS_ARRAY")}</div>
            <div className="code">{t("customer:INDUSTRY")}</div>
            <div className="obj">{t("order:OBJECTIVE")}</div>
            <div className="title_news">{t("TITLE")}</div>
            <div className="createBy">{t("CREATOR")}</div>
            <div className="date">{t("PUBLISH_DATE")}</div>
            <div className="status">{t("STATUS")}</div>
          </div>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        page: state.page + 1,
                        limit: 50,
                        searchString: searchValue,
                        from: range.startTime.toISOString(),
                        to: range.endTime.toISOString(),
                        ...(selectTab !== "all" && { isDraft: !!(selectTab === "isDraft") }),
                        ...(selectTab !== "all" &&
                          selectTab !== "isDraft" && {
                            isActive: !!(selectTab === "active"),
                          }),
                      })
                    }>
                    {state.data.map((item: NewsOutputModel) => {
                      const divisionName = renderNameDivision(item.divisionIds);
                      return (
                        <div
                          className="item"
                          key={item.id}
                          onClick={() => {
                            history.push({
                              pathname: APP_ROUTES.NEWS_DETAIL.replace(
                                ":newsId",
                                item.id.toString()
                              ),
                              state: {
                                startTime: range.startTime.toISOString(),
                                endTime: range.endTime.toISOString(),
                                selectTab: selectTab,
                              },
                            });
                          }}>
                          <div className="name ellipsis" style={{ paddingRight: "1rem" }}>
                            <Tooltip
                              title={item?.sources?.map((i) => t(i.toUpperCase())).join(", ")}
                              placement="top-start">
                              <span>
                                {item?.sources?.map((i) => t(i.toUpperCase())).join(", ")}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="code ellipsis" style={{ paddingRight: "1rem" }}>
                            <Tooltip
                              title={
                                !!divisionName.length
                                  ? divisionName.map((i) => i.description).join(", ")
                                  : TEXT_HOLDER
                              }
                              placement="top-start">
                              <span>
                                {!!divisionName.length
                                  ? divisionName.map((i) => i.description).join(", ")
                                  : TEXT_HOLDER}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="obj ellipsis" style={{ paddingRight: "1rem" }}>
                            <Tooltip
                              title={item?.actors?.map((i) => t(i.toUpperCase())).join(", ")}
                              placement="top-start">
                              <span>{item?.actors?.map((i) => t(i.toUpperCase())).join(", ")}</span>
                            </Tooltip>
                          </div>
                          <div className="title_news ellipsis" style={{ paddingRight: "1rem" }}>
                            <Tooltip title={item.title || TEXT_HOLDER} placement="top-start">
                              <span>{item.title || TEXT_HOLDER}</span>
                            </Tooltip>
                          </div>
                          <div className="createBy">{item.createdBy?.nickname || TEXT_HOLDER}</div>
                          <div className="date">
                            {moment(item.postedDate).format(TIME.DAY_MONTH_YEAR) || TEXT_HOLDER}
                          </div>
                          <div
                            className="status"
                            style={{
                              color: item.isDraft
                                ? "#5A5959"
                                : item.isActive
                                ? "#0A6836"
                                : "#B8292F",
                            }}>
                            <DotIcon style={{ fontSize: "1rem" }} />{" "}
                            {item.isDraft
                              ? t("DRAFTS")
                              : item.isActive
                              ? t("PUBLISHED")
                              : t("HIDDEN")}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.NEWS_CREATE);
        }}
        btns={[]}
      />
    </div>
  );
};

export default News;
