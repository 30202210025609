import GFIconButton from "@Components/ui/GFIconButton";
import GFTextField from "@Components/ui/GFTextField";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { size } from "lodash";
import GFTypography from "@Components/ui/GFTypography";

const GFQuantityButtonWrapper = styled(Box)({
  display: "flex",
  gap: "1.6rem",
  alignItems: "center",
  flexWrap: "nowrap",
});

type GFQuantityButtonProps = {
  value?: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
  error?: any;
  min?: number;
  max?: number;
  inputWidth?: number;
};

export default function GFQuantityButton({
  value,
  defaultValue,
  error,
  inputWidth = 70,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  onChange,
}: GFQuantityButtonProps) {
  const [currentValue, setCurrentValue] = useState<number>(defaultValue || 0);

  const handleIncrease = () => {
    const newValue = currentValue + 1;

    setCurrentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleDecrease = () => {
    const newValue = currentValue - 1;

    setCurrentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = Number(event.target.value);

    setCurrentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <Box>
      <GFQuantityButtonWrapper>
        <GFIconButton
          onClick={handleDecrease}
          size="small"
          variant="contained"
          disabled={currentValue <= min}>
          <RemoveIcon />
        </GFIconButton>
        <Box width={inputWidth}>
          <GFTextField value={currentValue} onChange={handleChange} size="small" type="number" />
        </Box>
        <GFIconButton
          onClick={handleIncrease}
          size="small"
          variant="contained"
          disabled={currentValue >= max}>
          <AddIcon />
        </GFIconButton>
      </GFQuantityButtonWrapper>
      {error && (
        <GFTypography color="error" variant="body2" fontWeight={700} sx={{ mt: 1 }}>
          {error}
        </GFTypography>
      )}
    </Box>
  );
}
