import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import React, { useRef } from "react";
import './index.scss';

type DatePickerProps = {
  locale: any;
  error?: any;
  value: string;
  onChange: (value: any) => void;
}

export default function DatePicker({
  locale,
  error,
  value = '',
  onChange
}: DatePickerProps) {

  const [open, setOpen] = React.useState(false);

  const handleOpenPicker = () => {
    setOpen(true);
  }

  const handleClosePicker = () => {
    setOpen(false);
  }


  return (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        open={open}
        onClick={handleOpenPicker}
        onClose={handleClosePicker}
        okLabel="SELECT"
        cancelLabel="CANCEL"
        fullWidth
        disableToolbar
        disablePast
        inputVariant="outlined"
        format="dd-MM-yyyy"
        value={value}
        onChange={onChange}
        InputProps={{ readOnly: true}}
        className="date-picker"
        inputProps={{ className: "date-picker__input" }}
        InputAdornmentProps={{ position: "start", className: "date-picker__icon" }}
        helperText={error ? error.message : ""}
        error={!!error}
        placeholder="DD-MM-YYYY"
    
      />
    </MuiPickersUtilsProvider>
  );
}
