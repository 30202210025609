import { GFTypography } from "@Components/ui";
import GFProductItem from "@Components/ui/GFProductItem";
import OrderDetailExpanded from "@Containers/OrderCombination/OrderDetailExpanded";
import { OrderDetailItemWrapper } from "@Containers/OrderCombination/styles";
import { OrderOutputModel } from "@Models";
import { Box, Grid } from "@mui/material";
import { formatCurrency, StatusUIUtil } from "@Utils";
import _, { isEmpty } from "lodash";

type OrderLineItemProps = {
  order: OrderOutputModel["orders"]["0"];
  isChild?: boolean;
};

export default function OrderLineItem({ order, isChild }: OrderLineItemProps) {
  return (
    <div className="gf-ol-item">
      <OrderDetailItemWrapper isChild={isChild}>
        <div className="expand-icon"></div>
        <div className="gf-order-detail-item__main">
          <Grid container spacing={2} className="gf-ol-item__content">
            <Grid item xs={6} className="gf-ol-item__cell">
              <GFProductItem
                avatarUrl=""
                baseUoM=""
                name={_.get(order, "orderlines[0].itemName", "")}
                material={_.get(order, "orderlines[0].item.code", "")}
                source={"farm"}
                shơwPrice={false}
                hover={false}
                showImg={!isChild}
                size="small"
              />
            </Grid>
            <Grid item xs={2} className="gf-ol-item__cell right">
              <GFTypography>{+_.get(order, "orderlines[0].orderQuantity", 0)}</GFTypography>
            </Grid>
            {/* <Grid item xs={2} className="gf-ol-item__cell right">
              <GFTypography>
                {formatCurrency(_.get(order, "orderlines[0].price", ""))}/
                <GFTypography type="primary" sx={{ display: "inline" }}>
                  {_.get(order, "orderlines[0].baseUoM", "")}
                </GFTypography>
              </GFTypography>
            </Grid>
            <Grid item xs={2} className="gf-ol-item__cell right">
              <GFTypography type="primary">
                {formatCurrency(_.get(order, "orderlines[0].subTotal", ""))}
              </GFTypography>
            </Grid> */}
            <Grid item xs={4} className="gf-ol-item__cell right">
              {StatusUIUtil.renderCircleStatusNew(null, order.status, order.subStatus)}
            </Grid>
          </Grid>
        </div>
      </OrderDetailItemWrapper>
      {isChild && !isEmpty(order.orderlines) && (
        <Grid item xs={12}>
          <OrderDetailExpanded order={order} />
        </Grid>
      )}
    </div>
  );
}
