/* eslint-disable react/jsx-no-useless-fragment */
import ChipCustom from "@Components/ChipCustom";
import { OrderStatusERP, OrderStatusLab } from "@Constants";
import {
  FARM_ORDER_SUB_STATUS,
  indexOfStatus,
  indexOfSubStatus,
  LeadStatus,
  OrderStatus,
  OrderStatusFarm,
  OrderStatusFarmDisplay,
  PlanDetailStatus,
  PlanStatus,
  RegistrationArray,
  SourceType,
} from "@Models";
import { ArrowRight as ArrowRightIcon, Circle as CircleIcon } from "@mui/icons-material";
import i18n from "src/i18n";

export const StatusUIUtil = {
  renderCircleStatusNew,
  renderCircleStatus,
  renderBgStatus,
  renderStatusLead,
  renderSourceLead,
  renderStatusPlan,
  renderBgStatusPlan,
  renderBgStatusPlanDetail,
  renderCircleStatusPlanDetail,
  renderBgStatusFarmNew,
};

// Này dùng trong chi tiết của đơn hàng
// Đối với 1 số trạng thái sẽ có trạng thái con
function renderBgStatusFarmNew(value: any, source?: string, subStatus?: string) {
  return (
    <div className="circle-status item-more-info">
      {(() => {
        switch (value) {
          case OrderStatusFarm.CREATED:
            return (
              <span style={{ background: "#41AD49" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.VERIFIED:
            return (
              <span style={{ background: "#41AD49" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.CONFIRMED:
            return (
              <span
                style={{
                  background: "#41AD49",
                  color: "white",
                  display: "inline-block",
                  textAlign: "center",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}>
                <div style={{ fontSize: 9 }}>
                  {i18n.t(
                    OrderStatusFarmDisplay[
                      value.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                    ]
                  )}
                </div>
                <div style={{ fontSize: 7 }}>
                  {(() => {
                    switch (subStatus) {
                      case FARM_ORDER_SUB_STATUS.ALLOCATED:
                        return <div>ĐH đã được phân bổ</div>;
                      case FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP:
                        return <div>ĐH đã xác nhận trại - chưa đồng bộ ERP</div>;
                      case FARM_ORDER_SUB_STATUS.CONFIRMED_AWAITING_DEBT_APPROVAL:
                        return <div>ĐH đã xác nhận trại (chờ duyệt nợ)</div>;
                      case FARM_ORDER_SUB_STATUS.FIRST_DEPOSIT_MADE:
                        return <div>Đã cọc lần 1</div>;
                      case FARM_ORDER_SUB_STATUS.FIRST_DEBT_APPROVAL_GRANTED:
                        return <div>Được duyệt nợ lần 1</div>;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </span>
            );
          case OrderStatusFarm.CANCELLED:
            return (
              <span style={{ background: "#b8292f" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.HALF_COMPLETED:
            return (
              <span style={{ background: "#0a6836" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.COMPLETED:
            return (
              <span style={{ background: "#0a6836" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.DRAFT:
            return (
              <span style={{ background: "#0a6836" }}>
                {i18n.t(
                  OrderStatusFarmDisplay[value.toUpperCase() as keyof typeof OrderStatusFarmDisplay]
                )}
              </span>
            );
          case OrderStatusFarm.SHIPPING:
            return (
              <span
                style={{
                  background: "#41AD49",
                  color: "white",
                  display: "inline-block", // Đảm bảo chỉ chiếm không gian bằng nội dung
                  textAlign: "center", // Căn giữa văn bản
                  whiteSpace: "nowrap", // Đảm bảo nội dung không ngắt dòng
                  fontWeight: 700,
                }}>
                <div style={{ fontSize: 9 }}>
                  {i18n.t(
                    OrderStatusFarmDisplay[
                      value.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                    ]
                  )}
                </div>
                <div style={{ fontSize: 7 }}>
                  {(() => {
                    switch (subStatus) {
                      case FARM_ORDER_SUB_STATUS.VEHICLE_NOT_ASSIGNED:
                        return <div>Chưa sắp xe</div>;
                      case FARM_ORDER_SUB_STATUS.VEHICLE_ASSIGNED_NOT_SYNCED_WITH_ERP:
                        return <div>Đã sắp xe - chưa đồng bộ ERP</div>;
                      case FARM_ORDER_SUB_STATUS.VEHICLE_ASSIGNED:
                        return <div>Đã sắp xe</div>;
                      case FARM_ORDER_SUB_STATUS.SECOND_DEPOSIT_MADE:
                        return <div>Đã cọc lần 2</div>;
                      case FARM_ORDER_SUB_STATUS.SECOND_DEBT_APPROVAL_GRANTED:
                        return <div>Được duyệt nợ lần 2</div>;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </span>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

// Này đang dùng cho orderListing theo code cũ của FE
function renderCircleStatusNew(selectTab: any, selected: any, subStatus?: any) {
  return (
    <div className="circle-status item-more-info">
      {(() => {
        switch (selected) {
          case OrderStatusFarm.CREATED:
            return (
              <span className="item-more-info" style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.VERIFIED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.CONFIRMED:
            return (
              <div
                style={{
                  whiteSpace: "nowrap", // Đảm bảo nội dung không ngắt dòng
                  fontWeight: 700,
                }}>
                <span style={{ color: "#41AD49" }}>
                  <CircleIcon />
                  &ensp;{" "}
                  {i18n.t(
                    OrderStatusFarmDisplay[
                      selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                    ]
                  )}
                </span>
                <div style={{ fontSize: 11 }}>
                  {(() => {
                    switch (subStatus) {
                      case FARM_ORDER_SUB_STATUS.ALLOCATED:
                        return <div>ĐH đã được phân bổ</div>;
                      case FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP:
                        return <div>ĐH đã xác nhận trại - chưa đồng bộ ERP</div>;
                      case FARM_ORDER_SUB_STATUS.CONFIRMED_AWAITING_DEBT_APPROVAL:
                        return <div>ĐH đã xác nhận trại (chờ duyệt nợ)</div>;
                      case FARM_ORDER_SUB_STATUS.FIRST_DEPOSIT_MADE:
                        return <div>Đã cọc lần 1</div>;
                      case FARM_ORDER_SUB_STATUS.FIRST_DEBT_APPROVAL_GRANTED:
                        return <div>Được duyệt nợ lần 1</div>;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </div>
            );
          case OrderStatusFarm.CANCELLED:
            return (
              <span style={{ color: "#b8292f" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.HALF_COMPLETED:
            return (
              <span style={{ color: "#0a6836" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.COMPLETED:
            return (
              <span style={{ color: "#0a6836" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.DRAFT:
            return (
              <span style={{ color: "#0a6836" }}>
                <CircleIcon />
                &ensp;{" "}
                {i18n.t(
                  OrderStatusFarmDisplay[
                    selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                  ]
                )}
              </span>
            );
          case OrderStatusFarm.SHIPPING:
            return (
              <div
                style={{
                  whiteSpace: "nowrap", // Đảm bảo nội dung không ngắt dòng
                  fontWeight: 700,
                }}>
                <span style={{ color: "#41AD49" }}>
                  <CircleIcon />
                  &ensp;{" "}
                  {i18n.t(
                    OrderStatusFarmDisplay[
                      selected.toUpperCase() as keyof typeof OrderStatusFarmDisplay
                    ]
                  )}
                </span>
                <div style={{ fontSize: 11 }}>
                  {(() => {
                    switch (subStatus) {
                      case FARM_ORDER_SUB_STATUS.VEHICLE_NOT_ASSIGNED:
                        return <div>Chưa sắp xe</div>;
                      case FARM_ORDER_SUB_STATUS.VEHICLE_ASSIGNED_NOT_SYNCED_WITH_ERP:
                        return <div>Đã sắp xe - chưa đồng bộ ERP</div>;
                      case FARM_ORDER_SUB_STATUS.VEHICLE_ASSIGNED:
                        return <div>Đã sắp xe</div>;
                      case FARM_ORDER_SUB_STATUS.SECOND_DEPOSIT_MADE:
                        return <div>Đã cọc lần 2</div>;
                      case FARM_ORDER_SUB_STATUS.SECOND_DEBT_APPROVAL_GRANTED:
                        return <div>Được duyệt nợ lần 2</div>;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
              </div>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

function renderCircleStatus(selectTab: any, selected: any, source?: any, subStatus?: any) {
  return (
    <div className="circle-status">
      {(() => {
        switch (selectTab === "all" ? selected : selectTab) {
          case OrderStatus.DRAFT:
            return (
              <span style={{ color: "#004125" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_DRAFT") : i18n.t("CART")}
              </span>
            );
          case OrderStatus.CREATED:
            return source === "feed" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <span style={{ color: "#41AD49" }}>
                  <CircleIcon />
                  &ensp; {i18n.t("CREATED")}
                </span>
                {(() => {
                  switch (subStatus) {
                    case OrderStatusERP.NOT_CREATE_ERP:
                      return (
                        <span
                          style={{
                            color: "#08C4E3",
                            fontSize: "1rem",
                            paddingLeft: "3rem",
                          }}>
                          {i18n.t("ERP_NOT_CREATED")}
                        </span>
                      );
                    case OrderStatusERP.NOT_UPDATED_ERP:
                      return (
                        <span
                          style={{
                            color: "#F6A609",
                            fontSize: "1rem",
                            paddingLeft: "3rem",
                          }}>
                          {i18n.t("ERP_NOT_UPDATED")}
                        </span>
                      );
                    default:
                      return;
                  }
                })()}
              </div>
            ) : source === SourceType.LAB ? (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {i18n.t("LAB_CREATED")}
              </span>
            ) : (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {i18n.t("CREATED")}
              </span>
            );
          case OrderStatus.CONFIRMED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp;{" "}
                {source === "feed"
                  ? i18n.t("RECORDED")
                  : source === SourceType.LAB
                  ? i18n.t("LAB_CONFIRMED")
                  : i18n.t("DISPATCHED")}
              </span>
            );
          case OrderStatus.PROCESSING:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_PROCESSING") : i18n.t("DELIVERING")}
              </span>
            );
          case OrderStatusLab.SCHEDULED:
            return (
              source === SourceType.LAB && (
                <span style={{ color: "#0A6836" }}>
                  <CircleIcon />
                  &ensp; {i18n.t("LAB_SCHEDULED")}
                </span>
              )
            );
          case OrderStatusLab.RECEIVED:
            return (
              source === SourceType.LAB && (
                <span style={{ color: "#0A6836" }}>
                  <CircleIcon />
                  &ensp; {i18n.t("LAB_RECEIVED")}
                </span>
              )
            );
          case OrderStatus.HALF_COMPLETED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {i18n.t("PARTIALLY_DELIVERED")}
              </span>
            );
          case OrderStatus.COMPLETED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; {source === SourceType.LAB ? i18n.t("LAB_COMPLETED") : i18n.t("DELIVERED")}
              </span>
            );
          case OrderStatus.CANCELLED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; {i18n.t("CANCELLED")}
              </span>
            );
          case OrderStatusLab.REJECTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; {i18n.t("REJECTED")}
              </span>
            );
          case OrderStatus.VERIFIED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; {source === "feed" ? i18n.t("PENDING") : i18n.t("PROCESSING")}
              </span>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

function renderBgStatus(value: any, source?: string) {
  switch (value) {
    case OrderStatus.DRAFT:
      return (
        <span style={{ background: "#004125" }}>
          {source === SourceType.LAB ? i18n.t("LAB_DRAFT") : i18n.t("CART")}
        </span>
      );
    case OrderStatus.CREATED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.LAB ? i18n.t("LAB_CREATED") : i18n.t("CREATED")}
        </span>
      );
    case OrderStatus.CONFIRMED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.FARM
            ? i18n.t("DISPATCHED")
            : source === SourceType.LAB
            ? i18n.t("LAB_CONFIRMED")
            : i18n.t("RECORDED")}
        </span>
      );
    case OrderStatus.PROCESSING:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.LAB ? i18n.t("LAB_PROCESSING") : i18n.t("DELIVERING")}
        </span>
      );
    case OrderStatusLab.SCHEDULED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("LAB_SCHEDULED")}</span>;
    case OrderStatusLab.RECEIVED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("LAB_RECEIVED")}</span>;
    case OrderStatus.HALF_COMPLETED:
      return <span style={{ background: "#0A6836" }}>{i18n.t("PARTIALLY_DELIVERED")}</span>;
    case OrderStatus.COMPLETED:
      return (
        <span style={{ background: "#0A6836 " }}>
          {source === SourceType.LAB ? i18n.t("LAB_COMPLETED") : i18n.t("DELIVERED")}
        </span>
      );
    case OrderStatus.CANCELLED:
      return <span style={{ background: "#B8292F" }}>{i18n.t("CANCELLED")}</span>;
    case OrderStatusLab.REJECTED:
      return <span style={{ background: "#B8292F" }}>{i18n.t("REJECTED")}</span>;
    case OrderStatus.VERIFIED:
      return (
        <span style={{ background: "#41AD49" }}>
          {source === SourceType.FARM ? i18n.t("PROCESSING") : i18n.t("PENDING")}
        </span>
      );
    default:
      return;
  }
}

function renderStatusLead(value: string | null) {
  switch (value) {
    case LeadStatus.APPROVED:
      return (
        <span className="status" style={{ color: "#1AB759" }}>
          &#9679;&ensp;{i18n.t("APPROVED")}
        </span>
      );
    case LeadStatus.DENIED:
      return (
        <span className="status" style={{ color: "#E93C3C" }}>
          &#9679;&ensp;{i18n.t("REJECT")}
        </span>
      );
    case LeadStatus.PENDING:
      return (
        <span className="status" style={{ color: "#E89806" }}>
          &#9679;&ensp;{i18n.t("NOT_APPROVED")}
        </span>
      );
    default:
      return;
  }
}

function renderSourceLead(value: string) {
  switch (value) {
    case RegistrationArray.FEED:
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "6.6rem",
            // height: "4rem",
            backgroundColor: "#CEE1D7",
            color: "#0A6836",
            borderRadius: "2rem",
          }}>
          {i18n.t("FEED")}
        </span>
      );
    case RegistrationArray.FARM:
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "6.6rem",
            // height: "4rem",
            backgroundColor: "#ECF7ED",
            color: "#41AD49",
            borderRadius: "2rem",
          }}>
          {i18n.t("FARM")}
        </span>
      );
    case RegistrationArray.CCU:
      return (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "6.6rem",
            // height: "4rem",
            backgroundColor: "#3B865E",
            color: "#FFFFFF",
            borderRadius: "2rem",
          }}>
          CCU
        </span>
      );
    default:
      return;
  }
}

function renderStatusPlan(status: string) {
  return (
    <div className="circle-status">
      {(() => {
        switch (status) {
          case PlanStatus.OPENING:
            return (
              <span style={{ color: "#004125" }}>
                <CircleIcon />
                &ensp; Đang soạn
              </span>
            );
          case PlanStatus.CREATED:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; Đã tạo
              </span>
            );
          case PlanStatus.ADAPTED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đáp ứng
              </span>
            );
          case PlanStatus.CHANGING:
            return (
              <span style={{ color: "#41AD49" }}>
                <CircleIcon />
                &ensp; Cần điều chỉnh
              </span>
            );
          case PlanStatus.CONFIRMED:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đã xác nhận
              </span>
            );
          case PlanStatus.PROCESSING:
            return (
              <span style={{ color: "#0A6836" }}>
                <CircleIcon />
                &ensp; Đang xử lý
              </span>
            );
          case PlanStatus.REJECTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Từ chối
              </span>
            );
          case PlanStatus.CLOSED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Đã đóng
              </span>
            );
          case PlanStatus.NOT_ADAPTED:
            return (
              <span style={{ color: "#B8292F" }}>
                <CircleIcon />
                &ensp; Không đáp ứng
              </span>
            );
          default:
            return;
        }
      })()}
    </div>
  );
}

function renderBgStatusPlan(value: any) {
  switch (value) {
    case PlanStatus.OPENING:
      return <ChipCustom label="Đang soạn" background="#004125" uppercase />;
    case PlanStatus.CREATED:
      return <ChipCustom label="Đã tạo" background="#41AD49" uppercase />;
    case PlanStatus.CHANGING:
      return <ChipCustom label="Cần điều chỉnh" background="#41AD49" uppercase />;
    case PlanStatus.CONFIRMED:
      return <ChipCustom label="Đã xác nhận" background="#0A6836" uppercase />;
    case PlanStatus.PROCESSING:
      return <ChipCustom label="Đang xử lý" background="#0A6836" uppercase />;
    case PlanStatus.REJECTED:
      return <ChipCustom label="Từ chối" background="#B8292F" uppercase />;
    case PlanStatus.CLOSED:
      return <ChipCustom label="Đã đóng" background="#B8292F" uppercase />;
    case PlanStatus.ADAPTED:
      return <ChipCustom label="Đáp ứng" background="#0A6836" uppercase />;
    case PlanStatus.NOT_ADAPTED:
      return <ChipCustom label="Không đáp ứng" background="#B8292F" uppercase />;
    default:
      return;
  }
}

function renderBgStatusPlanDetail(value: any, label?: boolean) {
  switch (value) {
    case PlanDetailStatus.CHANGING:
      return (
        <ChipCustom
          label={label ? "Yêu cầu chỉnh sửa" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#41AD49"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.APPROVED:
      return (
        <ChipCustom
          label={label ? "Đã xác nhận" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#0A6836"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.REJECTED:
      return (
        <ChipCustom
          label={label ? "Đã từ chối" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#B8292F"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.PENDING:
      return (
        <ChipCustom
          label={label ? "Chờ duyệt" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#0A6836"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.ADAPTED:
      return (
        <ChipCustom
          label={label ? "Đáp ứng" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#0A6836"
          width={label ? "22rem" : undefined}
        />
      );
    case PlanDetailStatus.NOT_ADAPTED:
      return (
        <ChipCustom
          label={label ? "Không đáp ứng" : ""}
          avatar={label ? undefined : <ArrowRightIcon style={{ color: "#fff" }} />}
          background="#B8292F"
          width={label ? "22rem" : undefined}
        />
      );
    default:
      return;
  }
}

function renderCircleStatusPlanDetail(value: any) {
  switch (value) {
    case PlanDetailStatus.CHANGING:
      return (
        <span>
          <CircleIcon style={{ color: "#41AD49" }} />
          &ensp; Yêu cầu chỉnh sửa
        </span>
      );
    case PlanDetailStatus.APPROVED:
      return (
        <span>
          <CircleIcon style={{ color: "#0A6836" }} />
          &ensp; Xác nhận
        </span>
      );
    case PlanDetailStatus.REJECTED:
      return (
        <span>
          <CircleIcon style={{ color: "#B8292F" }} />
          &ensp; Từ chối
        </span>
      );
    default:
      return;
  }
}

export const checkStatusGreater = (
  status: string,
  type: "subStatus" | "status",
  statusToCompare: string
) => {
  if (type === "status") {
    return (
      indexOfStatus.findIndex((item: any) => item === status) >
      indexOfStatus.findIndex((item: any) => item === statusToCompare)
    );
  } else {
    return (
      indexOfSubStatus.findIndex((item: any) => item === status) >
      indexOfSubStatus.findIndex((item: any) => item === statusToCompare)
    );
  }
};
