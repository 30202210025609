import { z } from "zod";

export const sapCodeSchema = z.object({
    sapCode: z.string({
        message: "Vui lòng nhập SAP code.",
      })
      .min(1, {
        message: "Vui lòng nhập SAP code."
      })
      .max(500, {
        message: "SAP code không được vượt quá 500 ký tự.",
      })
})

export type SapCodePayload = z.infer<typeof sapCodeSchema>;