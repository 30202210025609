import Logo from "@Assets/images/logo.png";
import { ItemPermission, OrderStatus } from "@Models";
import {
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  // ShareOutlined as ShareOutlinedIcon,
  ZoomInRounded as ZoomInRoundedIcon,
  ZoomOutRounded as ZoomOutRoundedIcon,
} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { currencyFormat, PermissionUtil } from "@Utils";
import html2canvas from "html2canvas";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import { MessengerIcon } from "@Components/Icons/MessengerIcon";
// import { ViberIcon } from "@Components/Icons/ViberIcon";
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { useTranslation } from "react-i18next";
import "./index.scss";

type P_Props = {
  billData: any;
};

const Bill: React.FC<P_Props> = ({ billData }) => {
  const { t } = useTranslation(["translation", "order"]);
  // const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [zoom, setZoom] = useState<number>(1);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const zoomIn = () => {
    if (zoom < 2.5) setZoom(zoom * 1.2);
  };

  const zoomOut = () => {
    if (zoom > 1) setZoom(zoom / 1.2);
  };

  const printDocument = () => {
    const printArea = document.getElementById("divToPrint");

    html2canvas(printArea as any, {
      scale: 2,
      useCORS: true,
      // foreignObjectRendering:true,
      // windowWidth: 330,
      windowHeight: 1920,
    }).then((canvas) => {
      // const dataURL = canvas.toDataURL();
      // const pdf = new jsPDF();

      // pdf.addImage(dataURL, "JPEG", 20, 20, 180, 160);

      // pdf.save("saved.pdf");
      canvas.style.display = "none";
      var image = canvas.toDataURL("png");
      var opt = document.createElement("a");

      opt.setAttribute("download", `#${billData.code}_GREENFEED.png`);
      opt.setAttribute("href", image);
      opt.click();
    });
  };

  return (
    <div className="bill">
      <div className="bill-container" id={"divToPrint"} style={{ zoom: zoom }}>
        <div className="bill-page" style={{ width: "330px", backgroundColor: "#fff" }}>
          <img src={Logo} alt="logo" width="62px" />
          <div className="title">{t("order:ORDER_DETAIL")}</div>
          <div className="bill-page-content">
            <Grid container>
              <Grid item xs={12} className="title">
                {t("order:DELIVERY_INFORMATION")}
                <hr style={{ borderTop: "0.1rem dashed #000000", margin: "0.8rem 0" }} />
              </Grid>
              <Grid item xs={8}>
                {t("order:ORDER_ID")}: #{billData.code}
                <br />
                {t("order:ORDER_PLACER")}: {billData.createdBy?.fullName}
                <br />
                {t("CUSTOMER")}: {billData?.orders?.[0]?.customer?.fullName}&ensp;-&ensp;
                {billData?.orders?.[0]?.customer?.code}
                <br />
                {billData?.orders?.[0]?.customer?.phoneNumber}
              </Grid>
              <Grid item xs={4}>
                {t("order:ORDER_TIME")}: {moment(billData.createdAt).format("DD/MM/YYYY - HH:mm")}
              </Grid>
            </Grid>
            <hr style={{ borderTop: "0.1rem dashed #000000", margin: "0.8rem 0" }} />
            {billData?.orders.map((o: any, index: number) => {
              return (
                <>
                  <Grid container className="division" key={index}>
                    <Grid item xs={12}>
                      <div className="title">
                        {o.division?.description} ({o.orderlines?.length} {t("PRODUCT")})
                      </div>
                      <div>
                        {t("order:REFERENCE")}: {o?.orderGFId}
                        <br />
                        {t("order:DELIVERY_DATE")}: {o?.requiredDate}
                        <br />
                        {t("VEHICLE_NUMBER")}: {o?.vehicleNumber || TEXT_HOLDER}
                        <br />
                        {t("order:RECEIVING_ADDRESS")}: {o?.address || TEXT_HOLDER}
                        <br />
                        {t("NOTES")}: {o?.note || TEXT_HOLDER}
                      </div>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      borderTop: "0.1rem dashed #000000",
                      borderBottom: "0.1rem dashed #000000",
                      margin: "0.8rem 0",
                      height: "0.5rem",
                    }}
                  />
                  <Grid container className="header">
                    <Grid item xs={4}>
                      {t("order:ORDER_NAME")}
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="flex-end">
                      {t("QUANTITY")}
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="flex-end">
                      {t("UNIT_PRICE")}
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="flex-end">
                      {t("TOTAL_PRICE")}
                    </Grid>
                  </Grid>
                  {o.orderlines.map((pro: any, idx: number) => {
                    return (
                      <Grid container className="item" key={idx}>
                        <Grid item xs={4}>
                          {idx + 1}. {pro.item?.name}
                          <div className="weight">
                            {t("order:WEIGHT")}: {currencyFormat(pro?.dimension)}&ensp;
                            {pro?.baseUoM}
                          </div>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="flex-end">
                          {(() => {
                            if (
                              o.status === OrderStatus.CONFIRMED ||
                              o.status === OrderStatus.PROCESSING
                            ) {
                              return pro.expectQuantity
                                ? currencyFormat(pro.expectQuantity)
                                : TEXT_HOLDER;
                            } else if (o.status === OrderStatus.COMPLETED) {
                              return pro.actualQuantity
                                ? currencyFormat(pro.actualQuantity)
                                : TEXT_HOLDER;
                            } else
                              return pro.orderQuantity
                                ? currencyFormat(pro.orderQuantity)
                                : TEXT_HOLDER;
                          })()}
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="flex-end">
                          {checkPermissionViewPrice ? currencyFormat(pro?.price) : TEXT_ASTERISK}
                          &ensp;đ/
                          {pro?.saleUnit}
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="flex-end">
                          {checkPermissionViewPrice ? currencyFormat(pro.subTotal) : TEXT_ASTERISK}
                          &ensp;đ
                        </Grid>
                      </Grid>
                    );
                  })}
                  <hr
                    style={{
                      borderTop: "0.1rem dashed #000000",
                      borderBottom: "0.1rem dashed #000000",
                      margin: "0.8rem 0",
                      height: "0.5rem",
                    }}
                  />
                </>
              );
            })}

            <Grid container spacing={1}>
              <Grid item xs={6} className="label">
                {t("TOTAL_WEIGHT")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {currencyFormat(
                  billData?.orders?.reduce((sum: any, item: any) => {
                    const a = item.orderlines?.reduce((sum: any, line: any) => {
                      sum += +line.dimension;
                      return sum;
                    }, 0);
                    return (sum += a);
                  }, 0)
                )}
                &ensp;kg
              </Grid>
              <Grid item xs={6} className="label">
                {t("order:ESTIMATED")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice ? currencyFormat(+billData?.totalAmount) : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
              <Grid item xs={6} className="label">
                {t("order:COMMERCIAL_DISCOUNT")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice ? currencyFormat(+billData?.preDiscount) : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
              <Grid item xs={6} className="label">
                {t("order:WITHHOLDING_TAX")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice
                  ? currencyFormat(+billData?.commerceDiscount)
                  : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
              <Grid item xs={6} className="label">
                {t("WITHHOLDING_PIP")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice ? currencyFormat(+billData?.personalVat) : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
              <Grid item xs={6} className="label">
                {t("VAT")}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice ? currencyFormat(+billData?.totalVat) : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
              <Grid item xs={4} className="label">
                {t("order:TOTAL_NUMBER")}
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end">
                {currencyFormat(
                  billData?.orders?.reduce((sum: any, item: any) => {
                    const a = item.orderlines?.reduce((sum: any, line: any) => {
                      sum += +line.orderQuantity;
                      return sum;
                    }, 0);
                    return (sum += a);
                  }, 0)
                )}
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {checkPermissionViewPrice ? currencyFormat(+billData?.totalAmount) : TEXT_ASTERISK}
                &ensp;đ
              </Grid>
            </Grid>
            <hr style={{ borderTop: "0.1rem dashed #000000", margin: "0.8rem 0" }} />
            <div className="label">{t("order:THANK_YOU")}</div>
            <div
              className="label"
              style={{ textAlign: "center", paddingTop: "6.4rem" }}
              dangerouslySetInnerHTML={{ __html: t("order:CONTACT_OUR_SALE_SUPPORT") }}
            />
          </div>
        </div>
      </div>
      <div className="bill-icon">
        {/* <button
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAnchorFilterEl(event.currentTarget)
          }>
          <ShareOutlinedIcon style={{ fontSize: "4rem", color: "#FFFFFF" }} />
        </button> */}
        <button
          onClick={() => {
            setZoom(1);
            setTimeout(() => {
              printDocument();
            });
          }}>
          <FileDownloadOutlinedIcon style={{ fontSize: "4rem", color: "#FFFFFF" }} />
        </button>
        <button
          onClick={zoomIn}
          disabled={zoom < 2.5 ? false : true}
          className={zoom < 2.5 ? "" : "btn-click"}>
          <ZoomInRoundedIcon
            style={{ fontSize: "4.5rem", color: zoom < 2.5 ? "#FFFFFF" : "#5a5959" }}
          />
        </button>
        <button
          onClick={zoomOut}
          disabled={zoom > 1 ? false : true}
          className={zoom > 1 ? "" : "btn-click"}>
          <ZoomOutRoundedIcon
            style={{ fontSize: "4.5rem", color: zoom > 1 ? "#FFFFFF" : "#5a5959" }}
          />
        </button>
      </div>

      {/* <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <div className="share">
          <button>
            <MessengerIcon color="#000000" size={[24, 24]} viewBox={[24, 24]} />
            &emsp;Messenger
          </button>
          <button>
            <img src={ZaloIcon} alt="icon-zalo" />
            &emsp;Zalo
          </button>
          <button>
            <ViberIcon color="#000000" size={[24, 24]} viewBox={[24, 24]} />
            &emsp;Viber
          </button>
        </div>
      </Popover> */}
    </div>
  );
};

export default Bill;
