import ModalFooter from "@Components/ModalFooter";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  AccountCustomerPermission,
  Action,
  CustomerModel,
  FetchAction,
  RoleModal,
  RoleType,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, PermissionService } from "@Services";
import { FormUtil, PermissionUtil } from "@Utils";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./AccountModal.scss";

type P_Props = {
  closeForm: () => void;
};

function reducer(state: PaginateFetchReducer<RoleModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const AccountModal: React.FC<P_Props> = ({ closeForm }) => {
  const { t } = useTranslation(["translation", "order"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [requireOTP, setRequireOTP] = useState<boolean>(false);
  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<CustomerModel | null>(null);
  const [selectedCustomerFarm, setSelectedCustomerFarm] = useState<CustomerModel | null>(null);
  const [selectedCustomerVet, setSelectedCustomerVet] = useState<CustomerModel | null>(null);
  const [selectedCustomerLab, setSelectedCustomerLab] = useState<CustomerModel | null>(null);
  const [selectedCustomerCcu, setSelectedCustomerCcu] = useState<CustomerModel | null>(null);
  const [roleId, setRoleId] = useState<any>("");
  const [customer, setCustomer] = useState<
    { item: CustomerModel; userId: string; source: string }[]
  >([]);

  const [role, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const fetchData = async (query: any) => {
    let cancel = false;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await PermissionService.getAllRole(query);
      if (!cancel)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (!cancel)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData({
      page: 1,
      limit: 200,
      roleType: RoleType.CUSTOMER,
    });
  }, []);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  const [isActiveSource, setIsActiveSource] = useState<{
    feed: boolean;
    farm: boolean;
    vet: boolean;
    lab: boolean;
    ccu: boolean;
  }>({
    feed: false,
    farm: false,
    vet: false,
    lab: false,
    ccu: false,
  });

  React.useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const onUpdateCustomer = (
    variantId: number,
    userId: string,
    source: string,
    addedCustomer?: CustomerModel
  ) => {
    const idx = customer.findIndex((item) => item.item.id === variantId);
    let data = [...customer];
    if (idx !== -1) {
      // update

      data[idx] = {
        ...data[idx],
        userId: userId,
        source: source,
      };
    } else {
      // thêm mới
      if (!addedCustomer) {
        return;
      }

      data.push({ item: addedCustomer, userId, source });
    }

    setCustomer(data);
  };

  const checkPermissionCusAccountCreate = PermissionUtil.check(
    profile?.role?.permissions,
    AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_ADD,
    true
  );

  const submitForm = async (values: any) => {
    if (customer.length === 0) {
      enqueueSnackbar("Chưa chọn Khách hàng!", {
        variant: "error",
      });
      return;
    }

    if (!checkPermissionCusAccountCreate) {
      enqueueSnackbar(t("ACCESS_DENIED"), {
        variant: "error",
        preventDuplicate: true,
      });
      setTimeout(() => window.location.reload(), 200);
      return;
    }

    const validCustomer = customer.filter((item) => item.source);
    const users = validCustomer.map((item) => {
      switch (item.item.source) {
        case SourceType.FEED:
          return {
            userId: selectedCustomerFeed?.id,
            source: selectedCustomerFeed?.source,
            isActive: selectedCustomerFeed === null ? undefined : isActiveSource.feed,
          };
        case SourceType.FARM:
          return {
            userId: selectedCustomerFarm?.id,
            source: selectedCustomerFarm?.source,
            isActive: selectedCustomerFarm === null ? undefined : isActiveSource.farm,
          };
        case SourceType.VET:
          return {
            userId: selectedCustomerVet?.id,
            source: selectedCustomerVet?.source,
            isActive: selectedCustomerVet === null ? undefined : isActiveSource.vet,
          };
        case SourceType.LAB:
          return {
            userId: selectedCustomerLab?.id,
            source: selectedCustomerLab?.source,
            isActive: selectedCustomerLab === null ? undefined : isActiveSource.lab,
          };
        default:
          return {
            userId: selectedCustomerCcu?.id,
            source: selectedCustomerCcu?.source,
            isActive: selectedCustomerCcu === null ? undefined : isActiveSource.ccu,
          };
      }
    });

    const data: Parameters<typeof CustomerService.createSignUpAccount>[0] = {
      ...values,
      roleId: roleId,
      isActive: isActive,
      requireOTP: requireOTP,
      users: users,
      isEmployee: false,
    } as any;

    CustomerService.createSignUpAccount(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Tạo tài khoản thành công!", {
            variant: "success",
          });
          history.push(
            APP_ROUTES.ACCOUNT_DETAIL.replace(":accountId", res.data?.accountId.toString())
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.message?.includes("email")) {
            enqueueSnackbar("Email đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber")) {
            enqueueSnackbar("Số điện thoại đã tồn tại!", {
              variant: "error",
            });
          } else if (
            error.response.data.message === `Key (code)=(${values.code}) already exists.`
          ) {
            enqueueSnackbar("Mã đăng nhập đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber must be a string")) {
            enqueueSnackbar("Vui lòng điền SĐT", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Tạo tài khoản thất bại!", {
            variant: "error",
          });
        }
      });
  };

  const dataFarmMap = {
    nickname: selectedCustomerFarm?.fullName,
    phoneNumber: selectedCustomerFarm?.phoneNumber,
    code: selectedCustomerFarm?.code,
    email: selectedCustomerFarm?.email,
  };

  return (
    <div className="account-modal">
      <SimpleModalHeader title={"Cấp tài khoản khách hàng"} onClose={closeForm} />
      <div className="content">
        <Form initialValues={dataFarmMap} onSubmit={async (values) => submitForm(values)}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="account">
                <div className="form-item">
                  <div className="form-label">{t("OPERATIONAL_STATUS")}</div>
                  <div>
                    <DefaultSwitch checked={isActive} onChange={() => setIsActive(!isActive)} />
                    {isActive === true ? (
                      <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>Hoạt động</span>
                    ) : (
                      <span style={{ fontSize: "1.6rem", color: "#838283" }}>Vô hiệu hóa</span>
                    )}
                  </div>
                </div>
                <div className="title">{t("ACCOUNT_INFO")}</div>
                <div>
                  <div className="header">
                    <div className="source">{t("ACCESS_SCOPE")}</div>
                    <div className="cus">{t("CUSTOMER")}</div>
                    <div className="access">{t("ACCESS_GRANT")}</div>
                  </div>
                  <div className="table">
                    <div className="col source">
                      <div>{t("FEED")}</div>
                      <div>{t("FARM")}</div>
                      <div>{t("VET")}</div>
                      <div>{t("LAB")}</div>
                      <div>{t("CCU")}</div>
                    </div>
                    <div className="col cus">
                      {!selectedCustomerFeed ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FEED}
                          onChange={(value) => {
                            setSelectedCustomerFeed(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          icon={<SearchIcon />}
                          className={"item-textfield"}
                          placeholder="Tìm kiếm khách hàng"
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: CustomerService.getCustomer,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedCustomerFeed.fullName || TEXT_HOLDER} -{" "}
                            {selectedCustomerFeed.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedCustomerFeed.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedCustomerFeed.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedCustomerFeed(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedCustomerFarm ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FARM}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          onChange={(value) => {
                            setSelectedCustomerFarm(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          placeholder="Tìm kiếm khách hàng"
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: CustomerService.getCustomer,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedCustomerFarm.fullName || TEXT_HOLDER} -{" "}
                            {selectedCustomerFarm.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedCustomerFarm.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedCustomerFarm.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedCustomerFarm(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedCustomerVet ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.VET}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          onChange={(value) => {
                            setSelectedCustomerVet(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          placeholder="Tìm kiếm khách hàng"
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: CustomerService.getCustomer,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedCustomerVet.fullName || TEXT_HOLDER} -{" "}
                            {selectedCustomerVet.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedCustomerVet.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedCustomerVet.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedCustomerVet(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedCustomerLab ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.LAB}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          onChange={(value) => {
                            setSelectedCustomerLab(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          placeholder="Tìm kiếm khách hàng"
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: CustomerService.getCustomer,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedCustomerLab.fullName || TEXT_HOLDER} -{" "}
                            {selectedCustomerLab.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedCustomerLab.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedCustomerLab.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedCustomerLab(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedCustomerCcu ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.CCU}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          onChange={(value) => {
                            setSelectedCustomerCcu(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          placeholder="Tìm kiếm khách hàng"
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: CustomerService.getCustomer,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedCustomerCcu.fullName || TEXT_HOLDER} -{" "}
                            {selectedCustomerCcu.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedCustomerCcu.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedCustomerCcu.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedCustomerCcu(null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col access">
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.feed}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedCustomerFeed === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: !isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.farm}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedCustomerFarm === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: !isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.vet}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedCustomerCcu === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: !isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.lab}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedCustomerCcu === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: !isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.ccu}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedCustomerCcu === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: !isActiveSource.ccu,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="title">{t("LOGIN_NAME")}</div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "100%", marginRight: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">{t("DISPLAY_NAME")}</div>
                      <Field
                        name="nickname"
                        validate={FormUtil.Rule.required("Nhập tên đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập tên đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_PHONE")}</div>
                      <Field
                        name="phoneNumber"
                        validate={
                          requireOTP === true
                            ? FormUtil.Rule.required(
                                t("INPUT_BY_NAME", { NAME: t("LOGIN_PHONE").toLowerCase() })
                              )
                            : undefined
                        }>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_PHONE").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Vai trò</div>
                      <Field
                        name="roleId"
                        validate={FormUtil.Rule.required("Bạn chưa chọn vai trò")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl style={{ width: "100%" }}>
                              <Select
                                {...input}
                                {...rest}
                                // value={salesOrg}
                                onChange={(e, value) => {
                                  input.onChange(e.target.value);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                style={{ fontFamily: "SVN-Gotham" }}
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">
                                          Chọn vai trò cho người dùng
                                        </span>
                                      )
                                }
                                displayEmpty
                                className="form-select-field"
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {role.data.data?.map((s: RoleModal) => {
                                  return (
                                    <MenuItem
                                      key={s.id}
                                      value={s.position}
                                      onClick={() => setRoleId(s.id)}>
                                      {s.position}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>

                    <div className="form-item">
                      <div className="otp">
                        <Checkbox
                          className="app-check-box"
                          checked={requireOTP}
                          onChange={() => setRequireOTP(!requireOTP)}
                        />
                        <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">Mã đăng nhập</div>
                      <Field name="code" validate={FormUtil.Rule.required("Nhập mã đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập mã đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_EMAIL")}</div>
                      <Field
                        name="email"
                        // validate={FormUtil.Rule.required("Nhập Email đăng nhập")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_EMAIL").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <ModalFooter
        formId="account"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={"Cấp tài khoản và mật khẩu"}
      />
    </div>
  );
};

export default AccountModal;
