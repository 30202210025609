/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { Action, AuthCustomerProfileOutputModel, EmployeePermission, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { EmployeeService, ExportService } from "@Services";
import { PermissionUtil, checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import { Search as SearchIcon, FiberManualRecord as DotIcon } from "@mui/icons-material";
import { Dialog, InputAdornment, TextField, Tooltip } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import CreateEmployee from "./Modal/CreateEmployee";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<any>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const EmployeeListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const checkExportRef = useRef<any>(null);
  const [openCreateEmployee, setOpenCreateEmployee] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await EmployeeService.getAllEmployee(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        source: sourceType,
        searchString: searchValue,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, searchValue, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 1000);

  const checkPermissionEmployee = PermissionUtil.check(
    profile?.role?.permissions,
    EmployeePermission.MANAGE_EMPLOYEE_ACCOUNT_ADD,
    true
  );

  const checkPermissionEmployeeDetail = PermissionUtil.check(
    profile?.role?.permissions,
    EmployeePermission.MANAGE_EMPLOYEE_VIEW_DETAILS,
    true
  );

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "employeeAccount",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, state.loading, success?.isExport === !isExport]);

  const exportEmployee = async () => {
    ExportService.getExportEmployee({
      source: sourceType,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [EmployeePermission.MANAGE_EMPLOYEE_ACCOUNT_EXPORT],
    true
  );

  return (
    <div className="employee-listing">
      <div className="employee-listing-content">
        <div className="header">
          <div className="title">
            {t("ALL")} ({state.totalRecords})
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            {!isExport && checkPermissionExportExcel && (
              <button
                className="btn-excel"
                onClick={() => {
                  exportEmployee();
                  setNotiExport(true);
                  setSuccess(undefined);
                }}>
                <img src={Excel} alt="icon-excel" />
                {t("EXPORT_DATA")}
              </button>
            )}
          </div>
        </div>
        {state.loading || (state.fetching && searchValue) ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          <>
            {state.data ? (
              state.data.length > 0 ? (
                <div className="list">
                  <div className="list-header">
                    <div className="id">Mã nhân viên</div>
                    <div className="name">Tên nhân viên</div>
                    <div className="phone">Điện thoại</div>
                    <div className="email">Email</div>
                    <div className="status">Tài khoản</div>
                    <div className="policy">{t("POLICY")}</div>
                  </div>
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        source: sourceType,
                        searchString: searchValue,
                      })
                    }>
                    {state.data.map((cus: AuthCustomerProfileOutputModel, index: number) => {
                      return (
                        <div
                          key={index}
                          className="item"
                          onClick={() => {
                            checkPermissionEmployeeDetail
                              ? history.push(
                                  APP_ROUTES.EMPLOYEE_DETAIL.replace(":employeeId", cus.id)
                                )
                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                  preventDuplicate: true,
                                  variant: "error",
                                });
                          }}>
                          <div className="id">{cus.code || TEXT_HOLDER}</div>
                          <div className="name ellipsis">
                            <Tooltip title={cus.fullName} placement="top-start">
                              <span>
                                <img
                                  src={checkLinkImage(cus.account?.avatar) || renderImageProNull()}
                                  alt="Avata"
                                  onError={renderErrorImageItem()}
                                />
                                {cus.fullName}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="phone">{cus.phoneNumber || TEXT_HOLDER}</div>
                          <div className="email ellipsis">
                            <Tooltip title={cus.email || TEXT_HOLDER} placement="top-start">
                              <span>{cus.email || TEXT_HOLDER}</span>
                            </Tooltip>
                          </div>
                          <div className={!!cus.isActive ? "status active" : "status "}>
                            {/* {!!cus.isActive ? "Đã cấp" : "Chưa cấp"} */}
                            {cus?.account === null ? (
                              <span style={{ color: "#838283" }}>
                                &#9679;&ensp;{t("customer:NO_ACCOUNT")}
                              </span>
                            ) : (
                              <>
                                {cus?.account?.cancelled ? (
                                  <span style={{ color: "#B8292F" }}>&#9679;&ensp;Đã hủy</span>
                                ) : cus?.account?.isActive === false ? (
                                  <span style={{ color: "#E93C3C" }}>
                                    &#9679;&ensp;{t("DISABLE")}
                                  </span>
                                ) : (
                                  <span style={{ color: "#1AB759" }}>
                                    &#9679;&ensp;Đang kích hoạt
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            className={`policy ${
                              cus.account?.policyAccepted ? "accepted" : "notAccepted"
                            }`}>
                            <DotIcon style={{ fontSize: "1rem" }} />{" "}
                            {cus.account?.policyAccepted ? t("CONFIRM") : t("NOT_CONFIRM")}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>
      {checkPermissionEmployee === true && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            setOpenCreateEmployee(true);
          }}
          btns={[]}
        />
      )}
      <Dialog fullScreen open={openCreateEmployee}>
        <CreateEmployee closeForm={() => setOpenCreateEmployee(false)} />
      </Dialog>

      <NotiExportExcel
        notiExport={notiExport}
        title="Nhân viên"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default EmployeeListing;
