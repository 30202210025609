import { ItemSection } from "@Components/Common";
import {
  GFAutoComplete,
  GFButton,
  GFDatePicker,
  GFFormItem,
  GFModal,
  GFSwitch,
  GFTextArea,
  GFTextField,
  GFTypography,
} from "@Components/ui";
import { APPROVE_BUSINESS_TYPE, APPROVE_BY } from "@Constants";
import {
  approvalByOptions,
  businessTypeOptions,
} from "@Containers/ApprovalOrderCancellation/ApprovalOrderCancellation.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { ICustomer, IDistrict, IProvince, MasterData } from "@Models/customer-manage.model";
import { Box, CircularProgress, Grid } from "@mui/material";
import { isFunction } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApprovalProcessPayload, approvalProcessSchema } from "src/schemas/approval-process.schema";
import { CustomerManagePayload, customerManageSchema } from "src/schemas/customer-manage.schema";
import { ModalDefaultProps, ModalType, SelectOption } from "src/types";
import _UniqBy from "lodash/uniqBy";
import _Filter from "lodash/filter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  CreateCustomerShippingAddressPayload,
  createCustomerShippingAddressSchema,
} from "src/schemas/customer-shipping-address.schema";
import GFSelectVirtualized from "@Components/ui/GFSelectVirtualized";

const defaultValues: CreateCustomerShippingAddressPayload = {
  shippingAddress: "",
  customerCode: "",
  district: "",
  province: "",
};

type Props = ModalDefaultProps & {
  initialValues?: CreateCustomerShippingAddressPayload;
  modalType?: ModalType;
  loading?: boolean;
  masterData: any;
  customers: ICustomer[]
};

export default function CreateOrUpdateCustomerShippingAddress({
  initialValues = defaultValues,
  open,
  onClose,
  onConfirm,
  modalType,
  loading,
  masterData,
  customers
}: Props) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm<CreateCustomerShippingAddressPayload>({
    resolver: zodResolver(createCustomerShippingAddressSchema),
    defaultValues: modalType === ModalType.Create ? defaultValues : initialValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const provinces: IProvince[] = masterData.region?.map((item: any) => {
    return {
      value: item.code,
      label: item.description,
      code: item.code,
    };
  });

  const districts: IDistrict[] = masterData.structureGroup?.map((item: any) => {
    return {
      value: item.code,
      label: item.description,
      provinceCode: item.code.slice(0, 2),
    };
  });

  const onSubmit = (data: CreateCustomerShippingAddressPayload) => {
    if (isFunction(onConfirm)) {
      onConfirm(data, modalType, isDraft);
    }
  };

  const selectedProvince = watch("province");
  const matchedDistricts = useMemo(() => {
    return _UniqBy(
      _Filter(districts, (x) => x.provinceCode === selectedProvince),
      "value"
    );
  }, [selectedProvince]);

  const actions = (
    <>
      <GFButton onClick={onClose} variant="outlined" minWidth={15}>
        {t("CANCEL")}
      </GFButton>
      <GFButton
        form="create-shipping-address"
        type="submit"
        onClick={() => setIsDraft(true)}
        variant="contained"
        disabled={!isValid || !isDirty || loading}
        minWidth={15}>
        {loading && <CircularProgress size={18} />}
        {t("customerManage.btnSave")}
      </GFButton>
      <GFButton
        onClick={() => setIsDraft(false)}
        form="create-shipping-address"
        variant="contained"
        type="submit"
        disabled={!isValid || !isDirty || loading}
        minWidth={15}>
        {loading && <CircularProgress size={18} />}
        {t("customerManage.btnSendRequest")}
      </GFButton>
    </>
  );
  return (
    <GFModal
      title={
        modalType === ModalType.Create
          ? t("customerManage.createCustomerShippingAddress")
          : t("customerManage.updateCustomerShippingAddress")
      }
      open={open}
      onClose={onClose}
      width="90rem"
      actions={actions}>
      <form id="create-shipping-address" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GFFormItem control={control} name="customerCode">
              <GFSelectVirtualized
                options={customers}
                label={t("customerManage.customer")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.customer")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={12}>
            <GFFormItem control={control} name="shippingAddress">
              <GFTextField
                fullWidth
                label={t("customerManage.shippingAddress")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.shippingAddress")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={6}>
            <GFFormItem control={control} name="province">
              <GFAutoComplete
                label={t("customerManage.province")}
                options={provinces}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.province")}`}
              />
            </GFFormItem>
          </Grid>
          <Grid item xs={6}>
            <GFFormItem control={control} name="district">
              <GFAutoComplete
                label={t("customerManage.district")}
                options={matchedDistricts}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.district")}`}
              />
            </GFFormItem>
          </Grid>
        </Grid>
      </form>
    </GFModal>
  );
}
