/* eslint-disable react/jsx-no-useless-fragment */
import employeeImage from "@Assets/images/avt.png";
import Img from "@Assets/images/history.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { EmployeePermission, LogType, SourceType } from "@Models";
import { AuthService, CustomerService, EmployeeService } from "@Services";
import { PermissionUtil, checkLinkImage } from "@Utils";
import {
  CheckRounded as CheckRoundedIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CreateEmployee from "../Modal/CreateEmployee";
import UpdateEmployee from "../Modal/UpdateEmployee";
import "./EmployeeDetail.scss";
import { useTranslation } from "react-i18next";

const EmployeeDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [employeeDetail, setEmployeeDetail] = useState<any>(null);
  const mounted = useRef(false);
  const { employeeId }: { employeeId: string } = useParams();
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [noti, setNoti] = useState<boolean>(false);
  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });
  const [isActive, setIsActive] = useState<boolean>(false);
  const [reCallAPI, setRecallAPI] = useState<boolean>(false);
  const [openCreateEmployee, setOpenCreateEmployee] = useState<boolean>(false);
  const [openUpdateEmployee, setOpenUpdateEmployee] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatch(loadProfile());
  }, [dispatch]);

  const getEmployeeDetail: () => Promise<void> = async () => {
    try {
      const response = await EmployeeService.getEmployeeById(employeeId);
      if (mounted.current) {
        setEmployeeDetail(() => response.data);
        setRecallAPI(false);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getEmployeeDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCallAPI === true]);

  const employeeImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = employeeImage;
    event.currentTarget.className = "error";
  };

  const grantPassword = () => {
    AuthService.grantNewPassword(employeeDetail.account?.id)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Cấp mật khẩu thành công", {
            variant: "success",
          });
          window.location.reload();
        } else {
          // do something
        }
      })
      .catch((err) => {
        enqueueSnackbar("Cấp mật khẩu thất bại", {
          variant: "error",
        });
      });
  };

  const changeStatus = () => {
    CustomerService.changeStatus({
      accountId: employeeDetail.account?.id,
      status: isActive,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (notiStatus.status === true) {
            enqueueSnackbar("Kích hoạt tài khoản thành công", {
              variant: "success",
            });
            // window.location.reload();
            setRecallAPI(true);
          } else {
            enqueueSnackbar("Vô hiệu tài khoản thành công", {
              variant: "success",
            });
            // window.location.reload();
            setRecallAPI(true);
          }
        } else {
          // do something
        }
      })
      .catch((err) => {
        if (isActive === false) {
          enqueueSnackbar("Kích hoạt tài khoản thất bại", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Vô hiệu tài khoản thất bại", {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (
      employeeDetail &&
      employeeDetail.account !== null &&
      employeeDetail.account?.isActive === false
    ) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    // eslint-disable-next-line
  }, [employeeDetail]);

  const checkPermissionEmployee = PermissionUtil.check(
    profile?.role?.permissions,
    EmployeePermission.MANAGE_EMPLOYEE_ACCOUNT_ADD,
    true
  );
  const checkPermissionRecoverAccount = profile?.role?.position.includes("Admin");

  const checkPermissionEmployeeEdit = PermissionUtil.check(
    profile?.role?.permissions,
    EmployeePermission.MANAGE_EMPLOYEE_ACCOUNT_EDIT,
    true
  );
  const checkPermissionEmployeeResetPW = PermissionUtil.check(
    profile?.role?.permissions,
    EmployeePermission.MANAGE_EMPLOYEE_ACCOUNT_RESET_PASSWORD,
    true
  );

  const recoverAccount = async () => {
    try {
      const res = await CustomerService.recoverAccount({ id: +employeeDetail?.account?.id });
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Khôi phục tài khoản thành công", {
          variant: "success",
          preventDuplicate: true,
        });
        setTimeout(() => getEmployeeDetail(), 200);
      }
    } catch (error) {
      enqueueSnackbar("Khôi phục tài khoản thất bại", {
        variant: "error",
        preventDuplicate: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Dialog fullScreen open={closeForm}>
        <div className="employee-detail">
          <SimpleModalHeader
            title={"Chi tiết tài khoản nhân viên"}
            onClose={() => {
              setCloseForm(false);
              history.push(APP_ROUTES.EMPLOYEE + "-" + employeeDetail?.source);
            }}
          />
          <div className="content">
            {!!employeeDetail ? (
              <Grid container>
                <Grid item xs={3} className="left">
                  <div className="image">
                    <img
                      src={checkLinkImage(employeeDetail.account?.avatar) || employeeImage}
                      alt="img"
                      onError={employeeImageOnErrorHandler}
                    />
                  </div>
                  <div className="title">{employeeDetail?.fullName || TEXT_HOLDER}</div>
                  <div style={{ fontWeight: 500, fontSize: "2rem", paddingBottom: "1rem" }}>
                    {employeeDetail?.code || TEXT_HOLDER}
                  </div>
                  <div className="status">
                    {employeeDetail?.account === null ? (
                      <span style={{ color: "#838283" }}>
                        &#9679;&ensp;{t("customer:NO_ACCOUNT")}
                      </span>
                    ) : (
                      <>
                        {employeeDetail.account?.isActive === false ? (
                          <span style={{ color: "#E93C3C" }}>&#9679;&ensp;{t("DISABLE")}</span>
                        ) : (
                          <span style={{ color: "#41AD49" }}>&#9679;&ensp;{t("ACTIVATE")}</span>
                        )}
                      </>
                    )}
                  </div>
                  {employeeDetail.account?.cancelled ? (
                    <div
                      className="btn-confirm-style"
                      onClick={() => {
                        if (checkPermissionRecoverAccount) {
                          recoverAccount();
                        } else {
                          enqueueSnackbar(t("ACCESS_DENIED"), {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        }
                      }}>
                      Khôi phục tài khoản
                    </div>
                  ) : employeeDetail.account !== null ? (
                    <>
                      {checkPermissionEmployeeEdit && (
                        <div
                          className="btn-confirm-style"
                          onClick={() => {
                            setOpenUpdateEmployee(true);
                          }}>
                          Chỉnh sửa
                        </div>
                      )}
                      {checkPermissionEmployeeResetPW && (
                        <div className="btn-setpw" onClick={() => setNoti(true)}>
                          <div className="text">Cấp lại mật khẩu</div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {checkPermissionEmployee && (
                        <div
                          className="btn-confirm-style"
                          onClick={() => setOpenCreateEmployee(true)}>
                          Cấp tài khoản
                        </div>
                      )}
                      <div
                        className="btn-setpw"
                        onClick={() => {
                          enqueueSnackbar("Tính năng đang được cập nhật", {
                            variant: "warning",
                          });
                        }}>
                        <div className="text">Liên kết tài khoản</div>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item xs={8} className="center">
                  <div className="box">
                    <div className="box-header">
                      <div className="title">Thông tin tài khoản</div>
                      {employeeDetail.account && (
                        <div>
                          <DefaultSwitch
                            checked={isActive}
                            disabled={
                              employeeDetail.account?.cancelled
                                ? employeeDetail.account?.cancelled
                                : employeeDetail && employeeDetail.account === null
                            }
                            onChange={() => {
                              setIsActive(!isActive);
                              setNotiStatus({
                                visible: true,
                                status: isActive === false ? true : false,
                              });
                            }}
                          />
                          {employeeDetail.account?.cancelled ? (
                            <span style={{ color: "#B8292F" }}>&#9679;&ensp;Đã hủy</span>
                          ) : employeeDetail.account.isActive === false ? (
                            <span style={{ color: "#E93C3C" }}>&#9679;&ensp;{t("DISABLE")}</span>
                          ) : (
                            <span style={{ color: "#1AB759" }}>&#9679;&ensp;Đang kích hoạt</span>
                          )}
                        </div>
                      )}
                    </div>
                    <Grid container>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">{t("DISPLAY_NAME")}</div>
                          {employeeDetail.account?.nickname || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("PHONE_NUMBER")}</div>
                          {employeeDetail.account?.phoneNumber || TEXT_HOLDER}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">Email</div>
                          {employeeDetail.account?.email || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("OTP_VERIFICATION")}</div>
                          {employeeDetail.account?.requireOTP === true ? "Có" : "Không"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">Mã đăng nhập</div>
                          {employeeDetail?.account?.code || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("POLICY")}</div>
                          {employeeDetail?.account?.policyAccepted
                            ? t("CONFIRMED")
                            : t("NOT_CONFIRM")}
                        </div>
                      </Grid>
                    </Grid>
                    {employeeDetail?.account?.employees?.length > 0 && (
                      <div>
                        <div className="header">
                          <div className="source">Mảng truy cập</div>
                          <div className="employ">Nhân viên</div>
                          <div className="access">Cho phép truy cập</div>
                        </div>
                        <div className="table">
                          {employeeDetail?.account?.employees.map((employ: any, idx: number) => {
                            return (
                              <div className="table-content" key={idx}>
                                <div className="col source">
                                  <div>
                                    {(() => {
                                      if (employ.source === SourceType.FEED) {
                                        return t("FEED");
                                      } else if (employ.source === SourceType.FARM) {
                                        return t("FARM");
                                      } else if (employ.source === SourceType.VET) {
                                        return t("VET");
                                      } else if (employ.source === SourceType.LAB) {
                                        return t("LAB");
                                      } else {
                                        return t("CCU");
                                      }
                                    })()}
                                  </div>
                                </div>
                                <div className="col employ">
                                  <div className="employ-info">
                                    {/* <div className="cus-info__img">
                                      <img
                                        src={BASE_LINK_IMAGE + cus.avatar || accountImage}
                                        alt="avatar"
                                        onError={accountImageOnErrorHandler}
                                      />
                                    </div> */}
                                    <div>
                                      <div className="employ-info__name">
                                        {employ.fullName || TEXT_HOLDER} -{" "}
                                        {employ.code || TEXT_HOLDER}
                                      </div>
                                      <div className="employ-info__phone">
                                        {employ.phoneNumber || TEXT_HOLDER} -{" "}
                                        {employ.email || TEXT_HOLDER}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col access">
                                  <div
                                    className="ic-green"
                                    style={{ backgroundColor: employ.isActive ? "" : "#efefef" }}>
                                    <CheckRoundedIcon
                                      style={{
                                        fontSize: "1.7rem",
                                        color: "#fff",
                                        background: employ.isActive ? "" : "#efefef",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "4rem" }}>
                    <div className="box">
                      <div className="title"> {t("customer:ACCESS_HISTORY")}</div>
                      <Divider style={{ margin: "1.6rem 0" }} />
                      {employeeDetail.account !== null ? (
                        <>
                          <div className="label">
                            <Grid container>
                              <Grid item xs={3}>
                                {t("TIME")}
                              </Grid>
                              <Grid item xs={3}>
                                IP
                              </Grid>
                              <Grid item xs={3}>
                                {t("STATUS")}
                              </Grid>
                              <Grid item xs={3}>
                                {t("customer:DEVICE")}
                              </Grid>
                            </Grid>
                          </div>
                          {employeeDetail.account?.accessLogs.map((u: any, idx: number) => {
                            return (
                              <div style={{ maxHeight: "54rem", overflow: "auto" }} key={idx}>
                                <div className="item">
                                  <Grid container>
                                    <Grid item xs={3}>
                                      {moment(u.createdAt).format("DD/MM/YYYY")} lúc{" "}
                                      {(new Date(u.createdAt).toString() as any).substr(16, 5)}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.ip || TEXT_HOLDER}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.status
                                        ? u.type === LogType.Login
                                          ? "Đăng nhập thành công"
                                          : "Đăng ký thành công"
                                        : "Thất bại"}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.deviceType || TEXT_HOLDER}
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <EmptyList img={Img} title={t("customer:NO_ACCESS_HISTORY")} />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Loading style={{ margin: "2rem 0", height: "70vh" }} />
            )}
          </div>
        </div>
      </Dialog>

      <Notification
        visible={noti}
        title={"Cấp lại mật khẩu"}
        subTitle={"Bạn có chắc muốn cấp lại mật khẩu cho tài khoản này?"}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setNoti(false)}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={grantPassword}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setNoti(false);
        }}
      />

      {notiStatus && (
        <Notification
          visible={notiStatus.visible}
          title={notiStatus.status ? "Kích hoạt tài khoản" : "Vô hiệu hóa tài khoản"}
          subTitle={
            notiStatus.status ? (
              <span>
                Tài khoản đang vô hiệu hóa
                <br />
                Bạn có chắc muốn kích hoạt tài khoản này ?
              </span>
            ) : (
              <span>
                Tài khoản đang hoạt động
                <br />
                Bạn có chắc muốn vô hiệu hóa tài khoản này ?
              </span>
            )
          }
          icon={
            <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                  notiStatus.status ? setIsActive(false) : setIsActive(true);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  changeStatus();
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            notiStatus.status
              ? setNotiStatus({ visible: false, status: true })
              : setNotiStatus({ visible: false, status: false });
            notiStatus.status ? setIsActive(false) : setIsActive(true);
          }}
        />
      )}

      <Dialog fullScreen open={openCreateEmployee}>
        <CreateEmployee
          closeForm={() => setOpenCreateEmployee(false)}
          dataSource={employeeDetail}
        />
      </Dialog>

      <Dialog fullScreen open={openUpdateEmployee}>
        <UpdateEmployee
          closeForm={() => setOpenUpdateEmployee(false)}
          infoEmployee={employeeDetail}
        />
      </Dialog>
    </>
  );
};

export default EmployeeDetail;
