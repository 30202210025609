import { IconBase, IconComponent } from "@Core/Icons";

export const AccountAuthorIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.57405 15.0394L3.57659 15.0363ZM3.57405 15.0394L3.57523 15.0379L3.57659 15.0363M2.79894 14.4115C2.02075 13.7875 2.02134 13.7867 2.02134 13.7867L2.02343 13.7841L2.02692 13.7798L2.03635 13.7683C2.04373 13.7594 2.05327 13.748 2.06498 13.7343C2.0884 13.7068 2.12052 13.6701 2.16131 13.6253C2.24285 13.5358 2.35934 13.4142 2.51054 13.2712C2.81245 12.9856 3.25588 12.6121 3.83883 12.2404C5.00751 11.4952 6.74345 10.7539 9.00901 10.7539C11.2746 10.7539 13.0105 11.4952 14.1792 12.2404C14.7621 12.6121 15.2056 12.9856 15.5075 13.2712C15.6587 13.4142 15.7752 13.5358 15.8567 13.6253C15.8975 13.6701 15.9296 13.7068 15.953 13.7343C15.9648 13.748 15.9743 13.7594 15.9817 13.7683L15.9911 13.7798L15.9946 13.7841L15.996 13.7859C15.996 13.7859 15.9973 13.7875 15.2191 14.4115L15.9973 13.7875C16.3419 14.2173 16.2729 14.8451 15.8431 15.1897C15.4146 15.5333 14.7893 15.4658 14.444 15.0394M14.444 15.0394L14.4352 15.029C14.4254 15.0175 14.4077 14.9971 14.3822 14.9691C14.3312 14.9131 14.2491 14.827 14.1367 14.7206C13.9112 14.5074 13.5665 14.2158 13.1066 13.9225C12.1894 13.3377 10.8203 12.7489 9.00901 12.7489C7.1977 12.7489 5.8286 13.3377 4.91147 13.9225C4.45151 14.2158 4.10684 14.5074 3.88137 14.7206C3.76888 14.827 3.68687 14.9131 3.63582 14.9691C3.61032 14.9971 3.59263 15.0175 3.58281 15.029L3.57405 15.0394C3.22875 15.4658 2.6034 15.5333 2.17489 15.1897C1.74511 14.8451 1.67669 14.2165 2.02134 13.7867L2.79894 14.4115M14.444 15.0394L14.4428 15.0379L14.4419 15.0369"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99777 5.15908C8.17969 5.15908 7.5165 5.82227 7.5165 6.64035C7.5165 7.45843 8.17969 8.12162 8.99777 8.12162C9.81585 8.12162 10.479 7.45843 10.479 6.64035C10.479 5.82227 9.81585 5.15908 8.99777 5.15908ZM5.52148 6.64035C5.52148 4.72045 7.07787 3.16406 8.99777 3.16406C10.9177 3.16406 12.4741 4.72045 12.4741 6.64035C12.4741 8.56025 10.9177 10.1166 8.99777 10.1166C7.07787 10.1166 5.52148 8.56025 5.52148 6.64035Z"
        fill={props.color}
      />
    </IconBase>
  );
};
