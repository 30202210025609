import { EventIcon } from "@Components/Icons/EventIcon";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, REGEX } from "@Constants";
import { useAppSelector } from "@Core/store/hook";
import { LoadMe } from "@Core/store/profile";
import { DATA_DEPOSIT_METHOD, DepositInputModel, E_DEPOSIT_TYPE, itemGroupTypeFarm } from "@Models";
import { DepositService } from "@Services";
import { FormUIUtil, FormUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { vi } from "date-fns/locale";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./DepositModal.scss";

const DepositCreate: React.FC = () => {
  console.log("call E_DEPOSIT_TYPE");

  const { t } = useTranslation(["translation", "order", "product"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitRef = useRef<any | null>(null);
  const mounted = useRef(false);
  const [depositType, setDepositType] = useState<string>("");
  const [rate1, setRate1] = useState<any>(0);
  const [rate2, setRate2] = useState<number>(0);
  const [rate3, setRate3] = useState<number>(0);
  const [dataItemGroup, setDataItemGroup] = useState<itemGroupTypeFarm[]>([]);
  const [typeGroup, setTypeGroup] = useState<number[]>([]);

  const [valid, setValid] = useState<boolean>(false);
  const { data: profile } = useAppSelector(LoadMe);

  const dataCheck = [...(typeGroup || [])];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!profile) {
      setDataItemGroup(profile.itemGroupTypeFarm);
    }

    //eslint-disable-next-line
  }, [profile]);

  const submitForm = async (values: any) => {
    const { appliedDate } = values;
    const rateSum = Number(rate1 + rate2 + rate3) !== 100;
    if (rateSum) {
      enqueueSnackbar(t("INVALID_DEPOSIT_RATE"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!typeGroup.length) {
      setValid(true);
      return;
    }
    const data: DepositInputModel = {
      ...values,
      rate1: rate1,
      rate2: rate2,
      rate3: rate3,
      appliedDate: !!appliedDate ? appliedDate : moment(new Date()).toISOString(),
      itemGroupTypeIds: typeGroup,
    };

    DepositService.createDeposit(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("CREATE_DEPOSIT_SUCCESSFUL"), {
            variant: "success",
          });
          history.push(APP_ROUTES.DEPOSIT);
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("CREATE_DEPOSIT_FAILED"), {
          variant: "error",
        });
        setValid(true);
      });
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="deposit-modal">
        <SimpleModalHeader
          title={t("DEPOSIT_METHOD_CREATE")}
          onClose={() => {
            history.push(APP_ROUTES.DEPOSIT);
          }}
        />
        <div className="content">
          <Form
            mutators={{
              setMin: (args, state, utils) => {
                utils.changeValue(state, "rate1", () => {
                  if (args[0] === E_DEPOSIT_TYPE.DEPOSIT_1) {
                    setRate1(100);
                    return 100;
                  } else {
                    setRate1(0);
                    return "00";
                  }
                });
                utils.changeValue(state, "rate2", () => {
                  if (args[0] === E_DEPOSIT_TYPE.DEPOSIT_1) {
                    setRate2(0);
                    return "00";
                  }
                });
                utils.changeValue(state, "rate3", () => {
                  if (args[0] === E_DEPOSIT_TYPE.DEPOSIT_1) {
                    setRate3(0);
                    return "00";
                  }
                });
              },
            }}
            onSubmit={async (values) => submitForm(values)}
            initialValues={{ rate1: 100 }}>
            {({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} id="deposit" style={{ width: "50%" }}>
                  <div className="body">
                    <Grid container spacing={3} alignItems={"center"}>
                      <Grid item xs={6}>
                        {FormUIUtil.renderFormItem(
                          t("CODE"),
                          <Field
                            name="code"
                            validate={FormUtil.Rule.required("Nhập mã phương thức cọc")}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={"Nhập"}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  autoComplete="off"
                                />
                              );
                            }}
                          </Field>,
                          true
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {FormUIUtil.renderFormItem(
                          t("DEPOSIT_METHOD_NAME"),
                          <Field
                            name="name"
                            validate={FormUtil.Rule.required("Nhập tên phương thức cọc")}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  placeholder={"Nhập"}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  autoComplete="off"
                                />
                              );
                            }}
                          </Field>,
                          true
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <div className="form-item">
                          <div className="form-label asterisk">{t("DEPOSIT_TYPE")}</div>
                          <Field
                            name="type"
                            validate={
                              !depositType
                                ? FormUtil.Rule.required("Bạn chưa chọn hình thức cọc")
                                : undefined
                            }>
                            {({ input, meta, ...rest }) => {
                              return (
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    {...input}
                                    {...rest}
                                    value={depositType}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    variant="outlined"
                                    renderValue={() => {
                                      return depositType ? (
                                        t(depositType?.toUpperCase())
                                      ) : (
                                        <span className="form__placeholder">
                                          {t("order:SELECT")}
                                        </span>
                                      );
                                    }}
                                    error={meta.error && meta.touched}
                                    onChange={(e) => {
                                      input.onChange(e.target.value);
                                      setDepositType(e.target.value);
                                      form.mutators.setMin(e.target.value);
                                    }}
                                    displayEmpty
                                    className="form-select-field"
                                    MenuProps={{
                                      className: "select-menu-list",
                                      style: { maxHeight: "40rem" },
                                    }}>
                                    {DATA_DEPOSIT_METHOD.map((value, index: number) => {
                                      return (
                                        <MenuItem key={index} value={value.id}>
                                          {t("DEPOSIT_BY_NUMBER", { NUMBER: index + 1 })}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                  <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="form-item">
                          <div className="form-label asterisk">{t("DEPOSIT_RATE")}</div>
                          <div style={{ display: "flex", width: "100%" }}>
                            <Field
                              name="rate1"
                              validate={FormUtil.composeValidators([
                                FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                  errorMessage: t("INVALID_NAME", {
                                    NAME: t("DEPOSIT_RATE"),
                                  }),
                                }),
                              ])}>
                              {({ input, meta, ...rest }) => {
                                return (
                                  <TextField
                                    {...input}
                                    {...rest}
                                    onChange={(e) => {
                                      input.onChange(e.target.value);
                                      setRate1(Number(e.target.value));
                                    }}
                                    placeholder={"00"}
                                    color="success"
                                    className="form-text-field form-sel-field"
                                    style={{ paddingRight: "1rem" }}
                                    inputProps={{
                                      className: "input",
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">%</InputAdornment>
                                      ),
                                    }}
                                    autoComplete="off"
                                    type="number"
                                    helperText={meta.touched ? meta.error : ""}
                                    error={
                                      (valid && !input.value) ||
                                      (Boolean(meta.error) && meta.touched)
                                    }
                                    disabled={!depositType || Number(rate2) + Number(rate3) >= 100}
                                  />
                                );
                              }}
                            </Field>

                            {(depositType === E_DEPOSIT_TYPE.DEPOSIT_2 ||
                              depositType === E_DEPOSIT_TYPE.DEPOSIT_3) && (
                              <Field
                                name="rate2"
                                validate={FormUtil.composeValidators([
                                  FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                    errorMessage: t("INVALID_NAME", {
                                      NAME: t("DEPOSIT_RATE"),
                                    }),
                                  }),
                                ])}>
                                {({ input, meta, ...rest }) => {
                                  return (
                                    <TextField
                                      {...input}
                                      {...rest}
                                      onChange={(e) => {
                                        input.onChange(e.target.value);
                                        setRate2(Number(e.target.value));
                                      }}
                                      placeholder={"00"}
                                      color="success"
                                      className="form-text-field form-sel-field"
                                      style={{ paddingRight: "1rem" }}
                                      inputProps={{
                                        className: "input",
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      autoComplete="off"
                                      type="number"
                                      helperText={meta.touched ? meta.error : ""}
                                      error={
                                        (valid && !input.value) ||
                                        (Boolean(meta.error) && meta.touched)
                                      }
                                      disabled={Number(rate1) + Number(rate3) >= 100}
                                    />
                                  );
                                }}
                              </Field>
                            )}

                            {depositType === E_DEPOSIT_TYPE.DEPOSIT_3 && (
                              <Field
                                name="rate3"
                                validate={FormUtil.composeValidators([
                                  FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                    errorMessage: t("INVALID_NAME", {
                                      NAME: t("DEPOSIT_RATE"),
                                    }),
                                  }),
                                ])}>
                                {({ input, meta, ...rest }) => {
                                  return (
                                    <TextField
                                      {...input}
                                      {...rest}
                                      onChange={(e) => {
                                        input.onChange(e.target.value);
                                        setRate3(Number(e.target.value));
                                      }}
                                      placeholder={"00"}
                                      color="success"
                                      className="form-text-field form-sel-field"
                                      inputProps={{
                                        className: "input",
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">%</InputAdornment>
                                        ),
                                      }}
                                      autoComplete="off"
                                      type="number"
                                      helperText={meta.touched ? meta.error : ""}
                                      error={
                                        (valid && !input.value) ||
                                        (Boolean(meta.error) && meta.touched)
                                      }
                                      disabled={Number(rate1) + Number(rate2) >= 100}
                                    />
                                  );
                                }}
                              </Field>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        {FormUIUtil.renderFormItem(
                          t("EFFECTIVE_DATE"),
                          <Field name="appliedDate">
                            {({ input, meta, ...rest }) => {
                              return (
                                <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    okLabel={t("order:SELECT")}
                                    cancelLabel={t("CANCEL")}
                                    fullWidth
                                    disableFuture
                                    disableToolbar
                                    InputProps={{ readOnly: true }}
                                    inputVariant="outlined"
                                    format="dd-MM-yyyy"
                                    className="form-text-field date"
                                    value={input.value || moment().format("YYYY-MM-DD")}
                                    onChange={(value) => {
                                      if (value) {
                                        input.onChange(value);
                                      }
                                    }}
                                    inputProps={{ className: "input" }}
                                    keyboardIcon={
                                      <EventIcon
                                        color="#41AD49"
                                        size={[22, 22]}
                                        viewBox={[22, 22]}
                                      />
                                    }
                                    helperText={meta.touched ? meta.error : ""}
                                    error={meta.error && meta.touched}
                                  />
                                </MuiPickersUtilsProvider>
                              );
                            }}
                          </Field>,
                          true
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <div className="form-item select-multiple">
                          <div className="form-label">{t("product:PRODUCT_GROUP")}</div>
                          <FormControl fullWidth error={valid && !typeGroup.length}>
                            <Select
                              value={typeGroup}
                              multiple
                              fullWidth
                              variant="outlined"
                              className="form-selected"
                              renderValue={() => {
                                const name =
                                  (!!dataItemGroup?.length &&
                                    dataItemGroup
                                      .find((i) => i.id === typeGroup[0])
                                      ?.name.toLowerCase()) ||
                                  "";
                                return !!typeGroup.length ? (
                                  <span className="input-multi">
                                    <span
                                      className="input-multi-item"
                                      style={{ textTransform: "capitalize" }}>
                                      {name}
                                    </span>
                                    {typeGroup.length > 1 && (
                                      <span className="input-multi-item">
                                        + {typeGroup.length - 1} ...
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      color: "#9e9e9e",
                                      display: "flex",
                                      alignItems: "center",
                                    }}>
                                    <span className="form-selected-placeholder">
                                      {t("SELECT_BY_NAME", {
                                        NAME: t("product:PRODUCT_GROUP").toLowerCase(),
                                      })}
                                    </span>
                                  </span>
                                );
                              }}
                              displayEmpty
                              MenuProps={{
                                className: "select-menu-list",
                              }}>
                              {dataItemGroup.map((item, index: number) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={dataItemGroup.find((i) => i.id === item.id)?.id}
                                    className="checked-item">
                                    <ListItemText
                                      primary={t(
                                        dataItemGroup
                                          .find((i) => i.id === item.id)
                                          ?.name.toLowerCase() ?? ""
                                      )}
                                    />
                                    <Checkbox
                                      checked={typeGroup.some((some: any) => some === item.id)}
                                      onChange={(_, value) => {
                                        if (value === true) {
                                          dataCheck.push(item.id);
                                          setTypeGroup(dataCheck);
                                        } else {
                                          setTypeGroup(
                                            dataCheck.filter((value) => {
                                              return value !== item.id;
                                            })
                                          );
                                        }
                                      }}
                                      color="success"
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="footer">
                    <div className="show">
                      <div
                        className="btn-cancel-style btn__text-cancel"
                        onClick={() => {
                          history.push(APP_ROUTES.DEPOSIT);
                        }}>
                        {t("CANCEL")}
                      </div>
                      <button
                        // disabled={!orderDetail?.length && !selectedVars?.length}
                        onClick={() => {}}
                        type="submit"
                        ref={submitRef}
                        className="btn-confirm-style"
                        style={{ marginLeft: "4rem" }}>
                        {t("CREATE")}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default DepositCreate;
