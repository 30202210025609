/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  Action,
  AuthCustomerProfileOutputModel,
  CustomerOutputModel,
  CustomerPermission,
  FetchAction,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, ExportService } from "@Services";
import { PermissionUtil, checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<CustomerOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const CustomerListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "customer"]);
  const checkExportRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<boolean | undefined>(undefined);
  const [notiExport, setNotiExport] = useState<boolean>(false);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllCustomer(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        source: sourceType,
        strSearch: searchValue,
        hasCusDiv: selectTab,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, searchValue, selectTab, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "customer",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
  }, [sourceType, selectTab, state.loading, success?.isExport === !isExport]);

  const exportCus = async () => {
    ExportService.exportCus({ source: sourceType })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionCusViewDetail = PermissionUtil.check(
    profile?.role?.permissions,
    [
      CustomerPermission.MANAGE_CUS_VIEW_INFORMATION_DETAILS,
      CustomerPermission.MANAGE_CUS_ORDER_LIST,
      CustomerPermission.MANAGE_CUS_DEBT,
    ],
    false
  );

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [CustomerPermission.MANAGE_CUS_EXPORT],
    true
  );

  return (
    <div className="customer-listing">
      <div className="customer-listing-content">
        <div className="header">
          {profile?.role?.position.includes("Admin") ? (
            <div className="customer-listing-content__nav-header">
              <div
                className={selectTab === undefined ? "tab tab-active btn--shiny" : "tab tab-hover"}
                onClick={() => {
                  setSelectTab(undefined);
                }}>
                {t("CUSTOMER")} <span>{state.count?.total}</span>
              </div>
              <div
                className={selectTab === true ? "tab tab-active btn--shiny" : "tab tab-hover"}
                onClick={() => setSelectTab(true)}>
                {t("customer:BUSINESS_REGION")} <span>{state.count?.cusDiv}</span>
              </div>
            </div>
          ) : (
            <div className="title">
              {t("customer:BUSINESS_REGION")} ({state.totalRecords})
            </div>
          )}

          <div className="search">
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            {!isExport && checkPermissionExportExcel && (
              <button
                className="btn-excel"
                onClick={() => {
                  exportCus();
                  setNotiExport(true);
                  setSuccess(undefined);
                }}>
                <img src={Excel} alt="icon-excel" />
                {t("EXPORT_DATA")}
              </button>
            )}
          </div>
        </div>
        <div className="list">
          <div className="list-header">
            <div className="id">{t("customer:CUSTOMER_CODE")}</div>
            <div className="name">{t("CUSTOMER")}</div>
            {/* {/ <div className="company">Tên Công ty</div> /} */}
            <div className="phone">{t("PHONE")}</div>
            <div className="email">{t("EMAIL")}</div>
            <div className="status">{t("ACCOUNT")}</div>
          </div>

          {state.loading || (state.fetching && (sourceType || searchValue)) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={1}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        source: sourceType,
                        strSearch: searchValue,
                        hasCusDiv: selectTab,
                      })
                    }>
                    {state.data.map((cus: AuthCustomerProfileOutputModel) => {
                      return (
                        <div
                          key={cus.code}
                          className="item"
                          onClick={() => {
                            if (checkPermissionCusViewDetail) {
                              if (cus.source === SourceType.FEED) {
                                history.push({
                                  pathname: APP_ROUTES.CUSTOMER_DETAIL_FEED.replace(
                                    ":customerId",
                                    cus.id
                                  ),
                                  state: {
                                    fullName: cus.fullName,
                                    phoneNumber: cus.phoneNumber,
                                  },
                                });
                              } else if (cus.source === SourceType.VET) {
                                history.push({
                                  pathname: APP_ROUTES.CUSTOMER_DETAIL_VET.replace(
                                    ":customerId",
                                    cus.id
                                  ),
                                  state: {
                                    fullName: cus.fullName,
                                    phoneNumber: cus.phoneNumber,
                                  },
                                });
                              } else if (cus.source === SourceType.LAB) {
                                history.push({
                                  pathname: APP_ROUTES.CUSTOMER_DETAIL_LAB.replace(
                                    ":customerId",
                                    cus.id
                                  ),
                                  state: {
                                    fullName: cus.fullName,
                                    phoneNumber: cus.phoneNumber,
                                  },
                                });
                              } else {
                                history.push({
                                  pathname: APP_ROUTES.CUSTOMER_DETAIL_FARM.replace(
                                    ":customerId",
                                    cus.id
                                  ),
                                  state: {
                                    fullName: cus.fullName,
                                    phoneNumber: cus.phoneNumber,
                                  },
                                });
                              }
                            } else {
                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                preventDuplicate: true,
                                variant: "error",
                              });
                            }
                          }}>
                          <div className="id">{cus.code || TEXT_HOLDER}</div>
                          <div className="name">
                            <img
                              src={checkLinkImage(cus.account?.avatar) || renderImageProNull()}
                              alt="Avata"
                              onError={renderErrorImageItem()}
                            />
                            {cus.fullName}
                          </div>
                          {/* {/ <div className="company">{cus.company || TEXT_HOLDER}</div> /} */}
                          <div className="phone">{cus.phoneNumber || TEXT_HOLDER}</div>
                          <div className="email">{cus.email || TEXT_HOLDER}</div>
                          <div className="status">
                            {cus?.account === null ? (
                              <span style={{ color: "#838283" }}>
                                &#9679;&ensp;{t("customer:NO_ACCOUNT")}
                              </span>
                            ) : (
                              <>
                                {cus?.isActive === false ? (
                                  <span style={{ color: "#E93C3C" }}>
                                    &#9679;&ensp;{t("DISABLE")}
                                  </span>
                                ) : (
                                  <span style={{ color: "#41AD49" }}>
                                    &#9679;&ensp;{t("ACTIVATE")}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>

      <NotiExportExcel
        notiExport={notiExport}
        title={t("customer:BUSINESS_REGION")}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default CustomerListing;
