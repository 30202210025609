import { IconBase, IconComponent } from "@Core/Icons";

export const CreateShippingAddressIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M2.8 4H18.2L17.2083 2.83333H3.79167L2.8 4ZM8.16667 11.875L10.5 10.7083L12.8333 11.875V6.33333H8.16667V11.875ZM2.33333 21.5C1.69167 21.5 1.14236 21.2715 0.685417 20.8146C0.228472 20.3576 0 19.8083 0 19.1667V4.6125C0 4.34028 0.04375 4.07778 0.13125 3.825C0.21875 3.57222 0.35 3.33889 0.525 3.125L1.98333 1.34583C2.19722 1.07361 2.46458 0.864583 2.78542 0.71875C3.10625 0.572917 3.44167 0.5 3.79167 0.5H17.2083C17.5583 0.5 17.8937 0.572917 18.2146 0.71875C18.5354 0.864583 18.8028 1.07361 19.0167 1.34583L20.475 3.125C20.65 3.33889 20.7812 3.57222 20.8687 3.825C20.9562 4.07778 21 4.34028 21 4.6125V8.78333C20.6111 8.68611 20.2174 8.65694 19.8188 8.69583C19.4201 8.73472 19.0361 8.83194 18.6667 8.9875V6.33333H15.1667V12.1667L12.8333 14.5L10.5 13.3333L5.83333 15.6667V6.33333H2.33333V19.1667H10.5V21.5H2.33333ZM12.8333 21.5V17.7958L17.85 12.7792L19.1042 14.0333L14.5833 18.525V19.75H15.8083L20.3292 15.2583L21.5542 16.4833L16.5375 21.5H12.8333ZM21.5542 16.4833L17.85 12.7792L19.3083 11.3208C19.5222 11.1069 19.7944 11 20.125 11C20.4556 11 20.7278 11.1069 20.9417 11.3208L23.0125 13.3917C23.2264 13.6056 23.3333 13.8778 23.3333 14.2083C23.3333 14.5389 23.2264 14.8111 23.0125 15.025L21.5542 16.4833Z" 
        fill={props.color}
      />
    </IconBase>
  );
};
