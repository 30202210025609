import { GFTypography } from "@Components/ui";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OrderItemDetailWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 200px 200px 300px",
  paddingInline: "4.8rem",
  marginBottom: "1.6rem",
});

export const OrderItemDetailTitle = styled(GFTypography)({
  fontWeight: 600,
  color: "var(--text-secondary)",
});

export const OrderItemDetailValue = styled(GFTypography)(({ color }) => ({
  color: "var(--text-foreground)",
  fontWeight: 600,
  ...(color && ({ color } as any)),
}));
