import { IconBase, IconComponent } from "@Core/Icons";

export const CustomerManageIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path d="M21.75 0.5C22.9688 0.5 24 1.53125 24 2.75V19.25C24 20.5156 22.9688 21.5 21.75 21.5H2.25C0.984375 21.5 0 20.5156 0 19.25V2.75C0 1.53125 0.984375 0.5 2.25 0.5H21.75ZM21.4688 19.25C21.6094 19.25 21.75 19.1562 21.75 18.9688V3.03125C21.75 2.89062 21.6094 2.75 21.4688 2.75H2.53125C2.34375 2.75 2.25 2.89062 2.25 3.03125V18.9688C2.25 19.1562 2.34375 19.25 2.53125 19.25H21.4688ZM19.5 14.9375V16.0625C19.5 16.3906 19.2188 16.625 18.9375 16.625H9.5625C9.23438 16.625 9 16.3906 9 16.0625V14.9375C9 14.6562 9.23438 14.375 9.5625 14.375H18.9375C19.2188 14.375 19.5 14.6562 19.5 14.9375ZM19.5 10.4375V11.5625C19.5 11.8906 19.2188 12.125 18.9375 12.125H9.5625C9.23438 12.125 9 11.8906 9 11.5625V10.4375C9 10.1562 9.23438 9.875 9.5625 9.875H18.9375C19.2188 9.875 19.5 10.1562 19.5 10.4375ZM19.5 5.9375V7.0625C19.5 7.39062 19.2188 7.625 18.9375 7.625H9.5625C9.23438 7.625 9 7.39062 9 7.0625V5.9375C9 5.65625 9.23438 5.375 9.5625 5.375H18.9375C19.2188 5.375 19.5 5.65625 19.5 5.9375ZM7.6875 6.5C7.6875 7.4375 6.89062 8.1875 6 8.1875C5.0625 8.1875 4.3125 7.4375 4.3125 6.5C4.3125 5.60938 5.0625 4.8125 6 4.8125C6.89062 4.8125 7.6875 5.60938 7.6875 6.5ZM7.6875 11C7.6875 11.9375 6.89062 12.6875 6 12.6875C5.0625 12.6875 4.3125 11.9375 4.3125 11C4.3125 10.1094 5.0625 9.3125 6 9.3125C6.89062 9.3125 7.6875 10.1094 7.6875 11ZM7.6875 15.5C7.6875 16.4375 6.89062 17.1875 6 17.1875C5.0625 17.1875 4.3125 16.4375 4.3125 15.5C4.3125 14.6094 5.0625 13.8125 6 13.8125C6.89062 13.8125 7.6875 14.6094 7.6875 15.5Z"
      fill={props.color}
      />
    </IconBase>
  );
};
