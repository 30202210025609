import { REQUEST_URL } from "@Constants";
import { PaginatedQueryDto } from "@Models";
import { RequestUtil } from "@Utils";
import { ApprovalStatusPayload } from "src/schemas/approval-status.schema";

export const ApprovalStatusService = {
  getAll: (query: PaginatedQueryDto) => {
    return RequestUtil.get({ url: REQUEST_URL.APPROVAL_STATUS, query: query });
  },

  create: (data: ApprovalStatusPayload) => {
    return RequestUtil.post({ url: REQUEST_URL.APPROVAL_STATUS, data: data });
  },

  update: (id: number, data: Omit<ApprovalStatusPayload, "code">) => {
    return RequestUtil.put({ url: `${REQUEST_URL.APPROVAL_STATUS}/${id}`, data: data });
  },

  delete: (id: number) => {
    return RequestUtil.delete({ url: `${REQUEST_URL.APPROVAL_STATUS}/${id}` });
  },
};
