import { ItemSection } from "@Components/Common";
import { GFFormItem, GFSwitch, GFTextField, GFTypography } from "@Components/ui";

import GFPageDialog from "@Components/ui/GFPageDialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { VehicleService } from "@Services/vehicle.service";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomerManagePayload } from "src/schemas/customer-manage.schema";
import { VehiclePayload, vehicleSchema } from "src/schemas/vehicle.schema";
import { ModalDefaultProps, ModalType } from "src/types";

const defaultValues: VehiclePayload = {
  vehicleType: "",
  vehicleInfo: "",
  name: "",
  idCard: "",
  phoneNumber: "",
  vehicleLoadCapacity: "",
  vehicleLoadCapacityRegistered: "",
  vehicleCategory: "",
};

type Props = ModalDefaultProps & {
  initialValues?: CustomerManagePayload;
  modalType?: ModalType;
  id?: number;
};

const CreateOrUpdateVehicle = ({ initialValues, id, onClose, modalType }: Props) => {
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { isValid, isDirty },
  } = useForm<VehiclePayload>({
    resolver: zodResolver(vehicleSchema),
    defaultValues: modalType === ModalType.Create ? defaultValues : initialValues,
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data: VehiclePayload) => {
    await trigger();

    setLoading(true);
    try {
      if (modalType === ModalType.Create) {
        const res = await VehicleService.createVehicle(data);
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar("Tạo mới loại xe thành công", {
            variant: "success",
          });
          window.location.reload();
        }
      } else {
        if (!id) {
          return;
        }

        const valueUpdate = {
          vehicleType: data.vehicleType,
          vehicleInfo: data.vehicleInfo,
          isActive: data.isActive,
          vehicleLoadCapacity: data.vehicleLoadCapacity,
          vehicleLoadCapacityRegistered: data.vehicleLoadCapacityRegistered,
          vehicleCategory: data.vehicleCategory,
          drivers: [
            {
              name: data.name,
              idCard: data.idCard,
              phoneNumber: data.phoneNumber,
            },
          ],
        };
        const res = await VehicleService.updateVehicle(id?.toString(), valueUpdate);
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar("Cập nhật xe thành công", {
            variant: "success",
          });
          window.location.reload();
        }
      }
    } catch {
      enqueueSnackbar(
        modalType === ModalType.Create ? "Tạo mới xe thất bại" : "Cập nhật xe thất bại",
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchVehicleDetail = async (id: number) => {
      try {
        setLoading(true);
        const res = await VehicleService.getDetailVehicle(id.toString());
        if (res) {
          console.log(res.data);
          reset({
            vehicleType: res?.data?.vehicleType,
            vehicleInfo: res?.data?.vehicleInfo,
            name: res?.data?.drivers[0]?.name,
            idCard: res?.data?.drivers[0]?.idCard,
            phoneNumber: res?.data?.drivers[0]?.phoneNumber,
            isActive: res?.data?.isActive,
            vehicleLoadCapacity: res?.data?.vehicleLoadCapacity,
            vehicleLoadCapacityRegistered: res?.data?.vehicleLoadCapacityRegistered,
            vehicleCategory: res?.data?.vehicleCategory,
          });
        }
      } catch {
      } finally {
        setLoading(false);
      }
    };
    if (modalType === ModalType.Update && id) {
      fetchVehicleDetail(id);
    }
  }, [id, modalType, reset]);

  return (
    <GFPageDialog
      title={modalType === ModalType.Create ? "Tạo mới loại xe" : "Cập nhật loại xe"}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="u-flex u-items-center u-flex--between">
          <GFTypography mb={2}>Thông tin xe</GFTypography>
          {modalType === ModalType.Update && (
            <GFFormItem control={control} name="isActive">
              <GFSwitch label="Trạng thái" size="small" />
            </GFFormItem>
          )}
        </div>
        <ItemSection>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <GFFormItem control={control} name="vehicleType">
                <GFTextField fullWidth label="Loại xe" placeholder="Nhập loại xe" required />
              </GFFormItem>
            </Grid>
            <Grid item xs={6}>
              <GFFormItem control={control} name="vehicleInfo">
                <GFTextField fullWidth label="Biển số xe" placeholder="Nhập biển số xe" required />
              </GFFormItem>
            </Grid>

            <Grid item xs={6}>
              <GFFormItem control={control} name="vehicleLoadCapacity">
                <GFTextField fullWidth label="Tải trọng (kg)" placeholder="Nhập tải trọng" />
              </GFFormItem>
            </Grid>
            <Grid item xs={6}>
              <GFFormItem control={control} name="vehicleLoadCapacityRegistered">
                <GFTextField
                  fullWidth
                  label="Tải trọng đăng kiểm (kg)"
                  placeholder="Nhập tải trọng đăng kiểm"
                />
              </GFFormItem>
            </Grid>
            <Grid item xs={12}>
              <GFFormItem control={control} name="vehicleCategory">
                <GFTextField fullWidth label="Nhóm xe" placeholder="Nhập nhóm xe" />
              </GFFormItem>
            </Grid>
          </Grid>
        </ItemSection>

        <GFTypography mb={2}>Thông tài xế</GFTypography>
        <ItemSection>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <GFFormItem control={control} name="name">
                <GFTextField fullWidth label="Họ và tên tài xế" placeholder="Nhập tên xài xế" />
              </GFFormItem>
            </Grid>
            <Grid item xs={4}>
              <GFFormItem control={control} name="idCard">
                <GFTextField fullWidth label="CCCD/CMND" placeholder="Nhập CCCD/CMND" required />
              </GFFormItem>
            </Grid>
            <Grid item xs={4}>
              <GFFormItem control={control} name="phoneNumber">
                <GFTextField
                  fullWidth
                  label="Số điện thoại"
                  placeholder="Nhập số điện thoại"
                  required
                />
              </GFFormItem>
            </Grid>
          </Grid>
        </ItemSection>
      </form>
    </GFPageDialog>
  );
};

export default CreateOrUpdateVehicle;
