import { z } from "zod";

export const createCustomerShippingAddressSchema = z.object({
  customerCode: z
    .string({
      message: "Nhập khách hàng",
    })
    .min(1, {
      message: "Khách hàng không được để trống",
    }),
  shippingAddress: z
    .string({
        message: "Nhập Địa chỉ giao hàng",
    })
    .min(1, {
        message: "Địa chỉ giao hàng không được để trống",
    }),
  province: z
    .string({
        message: "Nhập Tỉnh/Thành",
    })
    .min(1, {
        message: "Tỉnh/Thành không được để trống",
    }),
  district: z
    .string({
        message: "Nhập Quận/Huyện",
    })
    .min(1, {
        message: "Quận/Huyện không được để trống",
    }),
});

export type CreateCustomerShippingAddressPayload = z.infer<typeof createCustomerShippingAddressSchema>;
