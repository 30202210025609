import GFIconButton from "@Components/ui/GFIconButton";
import GFTypography from "@Components/ui/GFTypography";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const BoxStyled = styled(Box)({
  display: "inline-flex",
  borderRadius: "9999px",
  border: "1px solid var(--primary-green)",
  background: "var(--hover-green)",
  padding: "0.4rem 1.2rem",
  alignItems: "center",
  columnGap: "0.4rem",
  "&:not(:last-child)": {
    marginRight: "1.2rem",
  },
  "& .MuiSvgIcon-root": {
    fill: "var(--primary-green)",
  },
});

type SelectedChipProps = {
  title: string;
  type?: "primary" | "secondary";
  onDelete?: () => void;
};

function SelectedChip({ title, onDelete, type = "primary" }: SelectedChipProps) {
  return (
    <BoxStyled>
      <GFTypography variant="caption" type={type} fontWeight={600}>
        {title}
      </GFTypography>
      {onDelete && (
        <GFIconButton onClick={onDelete} size="small">
          <Close />
        </GFIconButton>
      )}
    </BoxStyled>
  );
}

export default SelectedChip;
