import { AccessTime } from "@mui/icons-material";
import { Grid, MenuItem, OutlinedInput, Popover, Select, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import { TimePickerValue } from "src/types";
import "./index.scss";

const hoursOptions = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const minutesOptions = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

type DatePickerProps = {
  value: TimePickerValue;
  onChange: (value: TimePickerValue) => void;
};

export default function TimePicker({ value, onChange }: DatePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeValue = (e: SelectChangeEvent) => {
    onChange({ ...value, [e.target.name]: e.target.value });
  };

  return (
    <>
      <button className="time-picker" type="button" onClick={handleClick}>
        <AccessTime className="time-picker__icon" />
        <span className="time-picker__text">
          {value.hour}:{value.minute}
        </span>
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Grid container spacing={1} style={{ paddingBottom: 17 }} className="time-picker__popup">
          <Grid item xs={6}>
            <Select
              value={value.hour}
              onChange={handleChangeValue}
              name="hour"
              input={<OutlinedInput className="time-picker__select__input" />}
              className="time-picker__select"
              MenuProps={{
                className: "time-picker__select__menu",
              }}
              fullWidth>
              {hoursOptions.map((hour) => (
                <MenuItem key={hour} value={hour} className="time-picker__select__item">
                  {hour}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <Select
              value={value.minute}
              onChange={handleChangeValue}
              name="minute"
              input={<OutlinedInput className="time-picker__select__input" />}
              className="time-picker__select"
              MenuProps={{
                className: "time-picker__select__menu",
              }}
              fullWidth>
              {minutesOptions.map((minute) => (
                <MenuItem key={minute} value={minute} className="time-picker__select__item">
                  {minute}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
