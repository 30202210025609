import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const PermissionService = {
  getAllPermissionAvailable: () => {
    const url = REQUEST_URL.GET_ALL_PERMISSION;

    return RequestUtil.get({ url });
  },

  createNewRole: (data: {
    position: string;
    description: string;
    permissions: number[];
    roleType: string;
    dataAccessPermissions: {
      source: string;
      divisionIds?: number[];
      salesOrgIds: number[];
      siteIds?: number[];
      itemIds?: number[];
      salesManIds: number[];
    }[];
  }) => {
    const url = REQUEST_URL.CREATE_ROLE;

    return RequestUtil.post({ url, data });
  },

  updateRole: (data: {
    id: number;
    position: string;
    description: string;
    permissions: number[];
    dataAccessPermissions: {
      source: string;
      divisionIds?: number[];
      salesOrgIds: number[];
      siteIds?: number[];
      itemIds?: number[];
      salesManIds: number[];
    }[];
  }) => {
    const url = REQUEST_URL.CREATE_ROLE;

    return RequestUtil.put({ url, data });
  },

  getAllRole: (
    query: PaginationQueryModel & {
      from?: string;
      to?: string;
      searchString?: string;
    }
  ) => {
    const url = REQUEST_URL.GET_ROLE;

    return RequestUtil.get({ url, query: query });
  },

  getRoleById(id: number) {
    const url = `${REQUEST_URL.GET_ROLE}/${id}`;
    return RequestUtil.get({ url });
  },
};
