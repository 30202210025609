import { CustomerService } from "@Services";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  checkQueryPaginationNextPage,
  getQueryPaginationData,
  getQueryPaginationTotal,
} from "@Utils";
import { GetAllBoarSemenCustomerPayload } from "src/dtos/customer.dto";

export const useCustomersInfinite = (params: Omit<GetAllBoarSemenCustomerPayload, "page">) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["customer-boar-semen", params],
    queryFn: async ({ pageParam }) => {
      const query = {
        ...params,
        page: pageParam || 1,
      };
      return CustomerService.getAllBoarSemenCustomer(query);
    },
    getNextPageParam: (lastPage) => {
      return checkQueryPaginationNextPage(lastPage);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  return {
    customers: getQueryPaginationData(data),
    hasCustomerNextPage: hasNextPage,
    customerLoading: isLoading,
    customerFetching: isFetching,
    fetchCustomerNextPage: fetchNextPage,
    totalCustomer: getQueryPaginationTotal(data),
  };
};
