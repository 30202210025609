import SelectInputV2 from "@Components/SelectInputV2";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, REGEX, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  AccountCustomerPermission,
  AccountModel,
  CampsAccount,
  CustomerAccount,
  RoleModal,
  SourceType,
  SubAccountInputModel,
} from "@Models";
import { CustomerService, PermissionService } from "@Services";
import { FormUtil, PermissionUtil, renderErrorImageItem, renderImageProNull } from "@Utils";
import {
  PersonAdd as AddCustomerIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import { Checkbox, Dialog, FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./SubAccountModal.scss";

const SubAccountModal: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitRef = useRef<any | null>(null);
  const mounted = useRef(false);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);
  const [requireOTP, setRequireOTP] = useState<boolean>(false);
  const [visibleAddAccount, setVisibleAddAccount] = useState<boolean>(false);
  const [dataAccount, setDataAccount] = useState<AccountModel | null>(null);
  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<number | null>(null);
  const [selectedCustomerFarm, setSelectedCustomerFarm] = useState<number | null>(null);
  const [selectedCustomerVet, setSelectedCustomerVet] = useState<number | null>(null);
  const [selectedCustomerLab, setSelectedCustomerLab] = useState<number | null>(null);
  const [selectedCustomerCcu, setSelectedCustomerCcu] = useState<number | null>(null);
  const [roleId, setRoleId] = useState<number | null>(null);
  const [dataCamps, setDataCamps] = useState<AccountModel | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [requestCamps, setRequestCamp] = useState<
    { campId: number | null; source: string; active?: boolean }[]
  >([]);

  const checkPermissionCusAccountCreate = PermissionUtil.check(
    profile?.role?.permissions,
    AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_ADD,
    true
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const onFetchApiAccountDetail: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response = await CustomerService.getAccountById(id);
      if (mounted.current) {
        setDataCamps(() => response.data);
        setLoading(false);
      }
    } catch (error) {}
  };

  const onUpdateRequestCamp = (campId: number, source: string, dataAdded?: CampsAccount) => {
    const idx = requestCamps.findIndex((item) => item.source === source);
    let data = [...requestCamps];

    if (idx !== -1) {
      if (!dataAdded) {
        data = data.filter((i) => Number(i.campId) !== campId);
      } else {
        data[idx] = {
          ...data[idx],
          campId: campId,
          source: source,
        };
      }
    } else {
      if (!dataAdded) {
        return;
      }
      data.push({ campId, source });
    }

    setRequestCamp(data);
  };

  const handleUpdateCamp = (id: number, source: string, status: "add" | "delete") => {
    const result = dataFilterCamps(source)?.find((i) => i.id === id);
    if (status === "add") {
      onUpdateRequestCamp(id, source, result);
    } else if (status === "delete") {
      onUpdateRequestCamp(id, source);
    }
  };

  const handleUpdateSource = (value: boolean, source: string) => {
    const result = {
      campId: null,
      source: source,
      active: value,
    };
    if (!!requestCamps.length) {
      const idx = requestCamps.findIndex((item) => item.source === source);
      let data = [...requestCamps];
      if (idx !== -1) {
        data[idx] = {
          ...data[idx],
          source: source,
          active: value,
        };
        setRequestCamp(data);
      } else {
        setRequestCamp([...requestCamps, { ...result }]);
      }
    } else {
      setRequestCamp([...requestCamps, { ...result }]);
    }
  };

  const submitForm = async (values: any) => {
    const { requestFullName, requestPhoneNumber, requestEmail, permissionNote } = values;
    setLoading(true);
    if (!dataAccount) {
      enqueueSnackbar(t("NOT_SELECTED_CUSTOMER"), {
        variant: "error",
      });
      return;
    }
    if (!requestFullName) {
      enqueueSnackbar(t("NAME_NOT_ENTERED"), {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    if (!requestEmail) {
      enqueueSnackbar(t("EMAIL_NOT_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      setLoading(false);
      return;
    }
    if (!requestPhoneNumber) {
      enqueueSnackbar(t("PHONE_NOT_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      setLoading(false);
      return;
    }

    if (!checkPermissionCusAccountCreate) {
      enqueueSnackbar(t("ACCESS_DENIED"), {
        variant: "error",
        preventDuplicate: true,
      });
      setTimeout(() => window.location.reload(), 200);
      setLoading(false);
      return;
    }

    if (requestCamps.findIndex((i) => !i.active && !!i.campId) > -1) {
      enqueueSnackbar("Vui lòng cấp quyền truy cập cho tài khoản được chọn", {
        variant: "error",
        preventDuplicate: true,
      });
      setLoading(false);
      return;
    }

    const validCamps = requestCamps.filter((item) => item.active);
    const dataCamp = validCamps.map((val) => {
      switch (val.source) {
        case SourceType.FEED:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.FARM:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.VET:
          return {
            campId: val.campId,
            source: val.source,
          };
        case SourceType.LAB:
          return {
            campId: val.campId,
            source: val.source,
          };
        default:
          return {
            campId: val.campId,
            source: val.source,
          };
      }
    });

    const data: SubAccountInputModel = {
      customerAccountId: +dataAccount?.id,
      isDraft: ordDraft,
      requestEmail,
      requestPhoneNumber,
      requestFullName,
      requestSaleOfficeIds: deliveryAddress,
      requestRoleId: Number(roleId),
      requestCamps: dataCamp,
      code: "",
      permissionNote,
    };

    CustomerService.createSubAccount(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(ordDraft ? "Lưu tài khoản thành công" : t("CREATE_ACCOUNT_SUCCESSFUL"), {
            variant: "success",
          });
          history.push(
            APP_ROUTES.SUB_ACCOUNT_DETAIL.replace(":accountId", res.data?.subAccount?.id.toString())
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        enqueueSnackbar(ordDraft ? "Lưu tài khoản thất bại" : t("CREATE_ACCOUNT_FAILED"), {
          variant: "error",
        });
        setLoading(false);
        setValid(true);
      });
  };

  const dataFilterCamps = (source: string) => {
    const result: CampsAccount[] = !!dataCamps?.customers.length
      ? dataCamps?.customers?.find((i: CustomerAccount) => i.source === source)?.camps || []
      : [];
    return result;
  };

  const dataSaleOffice =
    dataCamps?.customers
      .map((cus) => {
        const mergedSalesOffices = cus.customerDivisions.flatMap(
          (division) => division.salesOffices
        );
        return {
          salesOffices: mergedSalesOffices,
        };
      })
      .filter((item) => item !== null)
      .flatMap((item) => item.salesOffices) || [];

  return (
    <Dialog fullScreen open={true}>
      <div className="subAccount-modal">
        <SimpleModalHeader
          title={t("PROVIDE_CUSTOMER_ACCOUNT")}
          onClose={() => {
            history.push(APP_ROUTES.SUB_ACCOUNT);
          }}
        />
        <div className="content">
          <Form onSubmit={async (values) => submitForm(values)}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="account" style={{ width: "50%" }}>
                  <div className="form-item select-act">
                    <SelectInputV2<AccountModel>
                      value={dataAccount || undefined}
                      onChange={(value) => {
                        if (!!value) {
                          setDataAccount(value);
                          onFetchApiAccountDetail(value?.id);
                          setSelectedCustomerFeed(null);
                          setSelectedCustomerFarm(null);
                          setSelectedCustomerVet(null);
                          setSelectedCustomerLab(null);
                          setSelectedCustomerCcu(null);
                          setDataCamps(null);
                        }
                      }}
                      onClick={() => {
                        setVisibleAddAccount(true);
                      }}
                      className={!visibleAddAccount ? "customer-textfield" : "active"}
                      placeholder={t("SELECT_BY_NAME", { NAME: t("CUSTOMER") })}
                      getOptionLabel={(opt) => (opt?.nickname ? opt?.nickname : "")}
                      renderOption={(p) => (
                        <>
                          <img
                            src={renderImageProNull()}
                            alt=""
                            style={{
                              width: "4.8rem",
                              height: "4.8rem",
                              borderRadius: "50%",
                              marginRight: "1rem",
                            }}
                            onError={renderErrorImageItem()}
                          />
                          <div>
                            <span style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                              {p.nickname} - {p.code}
                            </span>
                            <br />
                            <span style={{ fontSize: "1.4rem", opacity: "0.4" }}>
                              {p.phoneNumber}
                            </span>
                          </div>
                        </>
                      )}
                      actionConfig={{
                        action: CustomerService.getAllAccount,
                        optionalQuery: {
                          isEmployee: false,
                          isActive: true,
                        },
                      }}
                      dropdownBoxMaxHeight="45rem"
                      icon={<AddCustomerIcon />}
                    />
                  </div>

                  {/* info */}
                  <div className="title">{t("ACCOUNT_INFO")}</div>
                  <div>
                    <div className="header">
                      <div className="source">{t("ACCESS_SCOPE")}</div>
                      {/* <div className="cus">{t("ACCOUNT")}</div> */}
                      <div className="access">{t("ACCESS_GRANT")}</div>
                    </div>
                    <div className="table">
                      <div className="col">
                        <div className="col-source">{t("FEED")}</div>
                        {/* <div className="col-cus">
                          {!selectedCustomerFeed ? (
                            <FormControl fullWidth>
                              <Select
                                value={selectedCustomerFeed || undefined}
                                onChange={(e) => {
                                  setSelectedCustomerFeed(Number(e.target.value));
                                  handleUpdateCamp(Number(e.target.value), SourceType.FEED, "add");
                                }}
                                fullWidth
                                variant="outlined"
                                className="form-selected"
                                renderValue={() => {
                                  return !!selectedCustomerFeed ? (
                                    dataFilterCamps(SourceType.FEED)?.find(
                                      (i) => i.id === selectedCustomerFeed
                                    )?.name
                                  ) : (
                                    <span
                                      style={{
                                        color: "#9e9e9e",
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <SearchIcon
                                        style={{
                                          width: "2.2rem",
                                          height: "2.2rem",
                                          color: "#272B2F",
                                        }}
                                      />
                                      <span className="form-selected-placeholder">
                                        {t("SELECT_FARM_ACCOUNT")}
                                      </span>
                                    </span>
                                  );
                                }}
                                displayEmpty
                                disabled={!dataAccount}
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {dataFilterCamps(SourceType.FEED)?.map((tar, index: number) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {tar.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="item-selected">
                              <div className="item-selected-body">
                                <div className="name">
                                  {
                                    dataFilterCamps(SourceType.FEED)?.find(
                                      (i) => i.id === selectedCustomerFeed
                                    )?.name
                                  }
                                </div>
                                <div className="phone">
                                  {
                                    dataFilterCamps(SourceType.FEED)?.find(
                                      (i) => i.id === selectedCustomerFeed
                                    )?.phoneNumber
                                  }
                                </div>

                                <ClearIcon
                                  className="clear-icon"
                                  onClick={() => {
                                    setSelectedCustomerFeed(null);
                                    handleUpdateCamp(
                                      Number(selectedCustomerFeed),
                                      SourceType.FEED,
                                      "delete"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="col-access">
                          <Checkbox
                            className="app-check-box"
                            checked={
                              requestCamps.find((i) => i.source === SourceType.FEED)?.active ??
                              false
                            }
                            disabled={!dataAccount}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  fontSize: "2.2rem",
                                  color: !selectedCustomerFeed ? "#efefef" : "",
                                }}
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                            onChange={(e) => {
                              handleUpdateSource(e.target.checked, SourceType.FEED);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-source">{t("FARM")}</div>
                        {/* <div className="col-cus">
                          {!selectedCustomerFarm ? (
                            <FormControl fullWidth>
                              <Select
                                value={selectedCustomerFarm || undefined}
                                onChange={(e) => {
                                  setSelectedCustomerFarm(Number(e.target.value));
                                  handleUpdateCamp(Number(e.target.value), SourceType.FARM, "add");
                                }}
                                fullWidth
                                variant="outlined"
                                className="form-selected"
                                renderValue={() => {
                                  return !!selectedCustomerFarm ? (
                                    dataFilterCamps(SourceType.FARM)?.find(
                                      (i) => i.id === selectedCustomerFarm
                                    )?.name
                                  ) : (
                                    <span
                                      style={{
                                        color: "#9e9e9e",
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <SearchIcon
                                        style={{
                                          width: "2.2rem",
                                          height: "2.2rem",
                                          color: "#272B2F",
                                        }}
                                      />
                                      <span className="form-selected-placeholder">
                                        {t("SELECT_FARM_ACCOUNT")}
                                      </span>
                                    </span>
                                  );
                                }}
                                displayEmpty
                                disabled={!dataAccount}
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {dataFilterCamps(SourceType.FARM)?.map((tar, index: number) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {tar.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="item-selected">
                              <div className="item-selected-body">
                                <div className="name">
                                  {
                                    dataFilterCamps(SourceType.FARM)?.find(
                                      (i) => i.id === selectedCustomerFarm
                                    )?.name
                                  }
                                </div>
                                <div className="phone">
                                  {
                                    dataFilterCamps(SourceType.FARM)?.find(
                                      (i) => i.id === selectedCustomerFarm
                                    )?.phoneNumber
                                  }
                                </div>

                                <ClearIcon
                                  className="clear-icon"
                                  onClick={() => {
                                    setSelectedCustomerFarm(null);
                                    handleUpdateCamp(
                                      Number(selectedCustomerFarm),
                                      SourceType.FARM,
                                      "delete"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="col-access">
                          <Checkbox
                            className="app-check-box"
                            checked={
                              requestCamps.find((i) => i.source === SourceType.FARM)?.active ??
                              false
                            }
                            disabled={!dataAccount}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  fontSize: "2.2rem",
                                  color: !selectedCustomerFarm ? "#efefef" : "",
                                }}
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                            onChange={(e) => {
                              handleUpdateSource(e.target.checked, SourceType.FARM);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-source">{t("VET")}</div>
                        {/* <div className="col-cus">
                          {!selectedCustomerVet ? (
                            <FormControl fullWidth>
                              <Select
                                value={selectedCustomerVet || undefined}
                                onChange={(e) => {
                                  setSelectedCustomerVet(Number(e.target.value));
                                  handleUpdateCamp(Number(e.target.value), SourceType.VET, "add");
                                }}
                                fullWidth
                                variant="outlined"
                                className="form-selected"
                                renderValue={() => {
                                  return !!selectedCustomerVet ? (
                                    dataFilterCamps(SourceType.VET)?.find(
                                      (i) => i.id === selectedCustomerVet
                                    )?.name
                                  ) : (
                                    <span
                                      style={{
                                        color: "#9e9e9e",
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <SearchIcon
                                        style={{
                                          width: "2.2rem",
                                          height: "2.2rem",
                                          color: "#272B2F",
                                        }}
                                      />
                                      <span className="form-selected-placeholder">
                                        {t("SELECT_FARM_ACCOUNT")}
                                      </span>
                                    </span>
                                  );
                                }}
                                displayEmpty
                                disabled={!dataAccount}
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {dataFilterCamps(SourceType.VET)?.map((tar, index: number) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {tar.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="item-selected">
                              <div className="item-selected-body">
                                <div className="name">
                                  {
                                    dataFilterCamps(SourceType.VET)?.find(
                                      (i) => i.id === selectedCustomerVet
                                    )?.name
                                  }
                                </div>
                                <div className="phone">
                                  {
                                    dataFilterCamps(SourceType.VET)?.find(
                                      (i) => i.id === selectedCustomerVet
                                    )?.phoneNumber
                                  }
                                </div>

                                <ClearIcon
                                  className="clear-icon"
                                  onClick={() => {
                                    setSelectedCustomerVet(null);
                                    handleUpdateCamp(
                                      Number(selectedCustomerVet),
                                      SourceType.VET,
                                      "delete"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="col-access">
                          <Checkbox
                            className="app-check-box"
                            checked={
                              requestCamps.find((i) => i.source === SourceType.VET)?.active ?? false
                            }
                            disabled={!dataAccount}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  fontSize: "2.2rem",
                                  color: !selectedCustomerVet ? "#efefef" : "",
                                }}
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                            onChange={(e) => {
                              handleUpdateSource(e.target.checked, SourceType.VET);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-source">{t("LAB")}</div>
                        {/* <div className="col-cus">
                          {!selectedCustomerLab ? (
                            <FormControl fullWidth>
                              <Select
                                value={selectedCustomerLab || undefined}
                                onChange={(e) => {
                                  setSelectedCustomerLab(Number(e.target.value));
                                  handleUpdateCamp(Number(e.target.value), SourceType.LAB, "add");
                                }}
                                fullWidth
                                variant="outlined"
                                className="form-selected"
                                renderValue={() => {
                                  return !!selectedCustomerLab ? (
                                    dataFilterCamps(SourceType.LAB)?.find(
                                      (i) => i.id === selectedCustomerLab
                                    )?.name
                                  ) : (
                                    <span
                                      style={{
                                        color: "#9e9e9e",
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <SearchIcon
                                        style={{
                                          width: "2.2rem",
                                          height: "2.2rem",
                                          color: "#272B2F",
                                        }}
                                      />
                                      <span className="form-selected-placeholder">
                                        {t("SELECT_FARM_ACCOUNT")}
                                      </span>
                                    </span>
                                  );
                                }}
                                displayEmpty
                                disabled={!dataAccount}
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {dataFilterCamps(SourceType.LAB)?.map((tar, index: number) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {tar.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="item-selected">
                              <div className="item-selected-body">
                                <div className="name">
                                  {
                                    dataFilterCamps(SourceType.LAB)?.find(
                                      (i) => i.id === selectedCustomerLab
                                    )?.name
                                  }
                                </div>
                                <div className="phone">
                                  {
                                    dataFilterCamps(SourceType.LAB)?.find(
                                      (i) => i.id === selectedCustomerLab
                                    )?.phoneNumber
                                  }
                                </div>

                                <ClearIcon
                                  className="clear-icon"
                                  onClick={() => {
                                    setSelectedCustomerLab(null);
                                    handleUpdateCamp(
                                      Number(selectedCustomerLab),
                                      SourceType.LAB,
                                      "delete"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="col-access">
                          <Checkbox
                            className="app-check-box"
                            checked={
                              requestCamps.find((i) => i.source === SourceType.LAB)?.active ?? false
                            }
                            disabled={!dataAccount}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  fontSize: "2.2rem",
                                  color: !selectedCustomerLab ? "#efefef" : "",
                                }}
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                            onChange={(e) => {
                              handleUpdateSource(e.target.checked, SourceType.LAB);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col-source">{t("CCU")}</div>
                        {/* <div className="col-cus">
                          {!selectedCustomerCcu ? (
                            <FormControl fullWidth>
                              <Select
                                value={selectedCustomerCcu || undefined}
                                onChange={(e) => {
                                  setSelectedCustomerCcu(Number(e.target.value));
                                  handleUpdateCamp(Number(e.target.value), SourceType.CCU, "add");
                                }}
                                fullWidth
                                variant="outlined"
                                className="form-selected"
                                renderValue={() => {
                                  return !!selectedCustomerCcu ? (
                                    dataFilterCamps(SourceType.CCU)?.find(
                                      (i) => i.id === selectedCustomerCcu
                                    )?.name
                                  ) : (
                                    <span
                                      style={{
                                        color: "#9e9e9e",
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <SearchIcon
                                        style={{
                                          width: "2.2rem",
                                          height: "2.2rem",
                                          color: "#272B2F",
                                        }}
                                      />
                                      <span className="form-selected-placeholder">
                                        {t("SELECT_FARM_ACCOUNT")}
                                      </span>
                                    </span>
                                  );
                                }}
                                displayEmpty
                                disabled={!dataAccount}
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {dataFilterCamps(SourceType.CCU)?.map((tar, index: number) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={tar.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {tar.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          ) : (
                            <div className="item-selected">
                              <div className="item-selected-body">
                                <div className="name">
                                  {
                                    dataFilterCamps(SourceType.CCU)?.find(
                                      (i) => i.id === selectedCustomerCcu
                                    )?.name
                                  }
                                </div>
                                <div className="phone">
                                  {
                                    dataFilterCamps(SourceType.CCU)?.find(
                                      (i) => i.id === selectedCustomerCcu
                                    )?.phoneNumber
                                  }
                                </div>

                                <ClearIcon
                                  className="clear-icon"
                                  onClick={() => {
                                    setSelectedCustomerCcu(null);
                                    handleUpdateCamp(
                                      Number(selectedCustomerCcu),
                                      SourceType.CCU,
                                      "delete"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div> */}
                        <div className="col-access">
                          <Checkbox
                            className="app-check-box"
                            checked={
                              requestCamps.find((i) => i.source === SourceType.CCU)?.active ?? false
                            }
                            disabled={!dataAccount}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{
                                  fontSize: "2.2rem",
                                  color: !selectedCustomerCcu ? "#efefef" : "",
                                }}
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                            onChange={(e) => {
                              handleUpdateSource(e.target.checked, SourceType.CCU);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* name */}
                  <div className="title">{t("LOGIN_NAME")}</div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", marginRight: "3.5rem" }}>
                      <div className="form-item">
                        <div className="form-label">{t("DISPLAY_NAME")}</div>
                        <Field
                          name="requestFullName"
                          validate={FormUtil.Rule.required(
                            t("INPUT_BY_NAME", { NAME: t("DISPLAY_NAME").toLowerCase() })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("DISPLAY_NAME").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item">
                        <div className="form-label">{t("LOGIN_EMAIL")}</div>
                        <Field
                          name="requestEmail"
                          validate={FormUtil.Rule.required(
                            t("INPUT_BY_NAME", {
                              NAME: t("LOGIN_EMAIL").toLowerCase(),
                            })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("LOGIN_EMAIL").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>

                    <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                      <div className="form-item">
                        <div className="form-label">{t("LOGIN_PHONE")}</div>
                        <Field
                          name="requestPhoneNumber"
                          validate={
                            requireOTP
                              ? FormUtil.composeValidators([
                                  FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                    errorMessage: t("INVALID_NAME", {
                                      NAME: t("LOGIN_PHONE").toLowerCase(),
                                    }),
                                  }),
                                ])
                              : undefined
                          }>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("LOGIN_PHONE").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={(valid && !input.value) || (meta.error && meta.touched)}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item select-multiple">
                        <div className="form-label">{t("order:DELIVERY_ADDRESS")}</div>
                        <FormControl fullWidth>
                          <Select
                            value={deliveryAddress}
                            multiple
                            onChange={(e) => {
                              const value: any = e.target.value;
                              setDeliveryAddress(value);
                            }}
                            fullWidth
                            variant="outlined"
                            className="form-selected"
                            renderValue={() => {
                              const resultData = deliveryAddress
                                .map((item) => {
                                  return dataSaleOffice.filter((i) => i.id === item);
                                })
                                .flatMap((i) => i);

                              return !!deliveryAddress.length ? (
                                <span className="input-multi">
                                  {resultData.map((val) => {
                                    return (
                                      <span key={val.id} className="input-multi-item">
                                        {val.description}
                                      </span>
                                    );
                                  })}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#9e9e9e",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <span className="form-selected-placeholder">
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("order:DELIVERY_ADDRESS").toLowerCase(),
                                    })}
                                  </span>
                                </span>
                              );
                            }}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {dataSaleOffice?.map((tar, index: number) => {
                              const active = deliveryAddress.findIndex((i) => i === tar.id) > -1;

                              return (
                                <MenuItem
                                  key={index}
                                  value={tar.id}
                                  style={{
                                    borderBottom: "0.1rem solid #EFEFEF",
                                    background: active ? "#d4d4d4 !important" : "#fff",
                                  }}>
                                  {tar.description}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  {false && (
                    <div className="form-item">
                      <div className="form-label">{t("ROLE")}</div>
                      <SelectInputV2<RoleModal>
                        onChange={(value) => {
                          !!value && setRoleId(value?.id);
                        }}
                        icon={<SearchIcon />}
                        className={"item-textfield"}
                        placeholder={t("SELECT_BY_NAME", {
                          NAME: t("ROLE"),
                        })}
                        getOptionLabel={(opt) => opt.position}
                        renderOption={(opt) => (
                          <div
                            style={{
                              display: "flex",
                              cursor: "pointer",
                              margin: "1rem 0",
                              width: "100%",
                              // borderBottom: "1px solid #d6d5d6",
                            }}>
                            <div>
                              <div style={{ fontSize: "1.8rem" }}>
                                {opt.position || TEXT_HOLDER}
                              </div>
                              <div
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#adacac",
                                  // padding: "0.4rem 0 2rem",
                                }}>
                                {opt.description || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        )}
                        actionConfig={{
                          action: PermissionService.getAllRole,
                        }}
                      />
                    </div>
                  )}

                  <div className="form-item">
                    <div className="form-label">{t("NOTES")}</div>
                    <Field
                      name="permissionNote"
                      validate={FormUtil.Rule.required(
                        t("INPUT_BY_NAME", {
                          NAME: t("NOTES").toLowerCase(),
                        })
                      )}>
                      {({ input, meta, ...rest }) => {
                        return (
                          <TextField
                            {...input}
                            {...rest}
                            placeholder={t("INPUT_BY_NAME", {
                              NAME: t("NOTES").toLowerCase(),
                            })}
                            color="success"
                            className="form-textarea-field"
                            inputProps={{ className: "input" }}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            multiline
                            rows={3}
                            fullWidth
                          />
                        );
                      }}
                    </Field>
                  </div>

                  <div className="form-item">
                    <div className="otp">
                      <Checkbox
                        className="app-check-box"
                        checked={requireOTP}
                        onChange={() => setRequireOTP(!requireOTP)}
                      />
                      <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                    </div>
                  </div>

                  <div className="footer">
                    <div className="show">
                      <div
                        className="btn-cancel-style btn__text-cancel"
                        onClick={() => {
                          history.push(APP_ROUTES.SUB_ACCOUNT);
                        }}>
                        {t("CANCEL")}
                      </div>
                      <button
                        disabled={loading}
                        onClick={() => {
                          setOrdDraft(true);
                        }}
                        type="submit"
                        ref={submitRef}
                        className="btn-confirm-style btn-border btn--transparent"
                        style={{ marginLeft: "4rem" }}>
                        {t("SAVE_DRAFT")}
                      </button>
                      <button
                        disabled={loading}
                        onClick={() => {}}
                        type="submit"
                        ref={submitRef}
                        className="btn-confirm-style"
                        style={{ marginLeft: "4rem" }}>
                        {t("LAB_CREATED")}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default SubAccountModal;
