import { styled } from "@mui/material/styles";
import React from "react";

type GFLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode;
  required?: boolean;
};

const StyledLabel = styled("label")({
  fontSize: "1.4rem",
  lineHeight: "1.8rem",
  fontWeight: 600,
  color: "#333",
  marginBottom: 8,
  display: "block",
  "& .required": {
    color: "var(--destructive-color)",
    marginLeft: 4,
    fontSize: '1.4rem',
  },
});

export default function GFLabel({ children, required, ...rest }: GFLabelProps) {
  return (
    <StyledLabel {...rest}>
      {children}
      {required && <span className="required">*</span>}
    </StyledLabel>
  );
}
