import { checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import React from "react";
import "./index.scss";

type P_Props = {
  name: string;
  id?: string;
  avatar?: string;
};

const ViewUser: React.FC<P_Props> = (props) => {
  const { name, id, avatar } = props;

  return (
    <div className="view-user">
      <img
        src={avatar ? checkLinkImage(avatar) : renderImageProNull()}
        alt="Avatar"
        onError={renderErrorImageItem()}
      />

      <div>
        <div className="view-user__name">{name}</div>
        {id && <div className="view-user__id">{id}</div>}
      </div>
    </div>
  );
};

export default ViewUser;
