import { IconBase, IconComponent } from "@Core/Icons";

export const DetachIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M12.25 7.5H13.75V12H0.25V7.5H1.75V10.5H12.25V7.5ZM0.25 0V4.5H1.75V1.5H12.25V4.5H13.75V0H0.25ZM6.25 5.25V6.75H4V8.25L1.75 6L4 3.75V5.25H6.25ZM10 5.25V3.75L12.25 6L10 8.25V6.75H7.75V5.25H10Z"
        fill={props.color}
      />
    </IconBase>
  );
};
