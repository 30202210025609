import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { AccountTypeSms, Month, StatusSms, TypeSms } from "@Constants";
import { SmsModel } from "@Models";
import { Grid, MenuItem, OutlinedInput, Popover, Select } from "@mui/material";
import { ExportService, SmsService } from "@Services";
import { dateFormat, FormUIUtil, YearList } from "@Utils";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import "./index.scss";

const Sms: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const checkExportRef = useRef<any>(null);
  const submitRef = useRef<any | null>(null);
  const [type, setType] = useState<string>("all");
  const [status, setStatus] = useState<string>("all");
  const [accountType, setAccountType] = useState<string>("");
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [success, setSuccess] = useState<any>();
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [listSms, setListSms] = useState<SmsModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "sms",
      });
      setSuccess(() => response.data);
      if (response.data.isExport === false) {
        clearInterval(checkExportRef.current);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (values: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      const res = await ExportService.exportSms({
        type: type,
        period: time,
        status: status,
        accountType: accountType,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        if (res.data.isExport === true) {
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        }
        if (submitRef.current) {
          submitRef.current.disabled = false;
        }
      }
    } catch (error: any) {
      if (submitRef.current) {
        submitRef.current.disabled = false;
      }
    }
  };

  useEffect(() => {
    const fetchData: () => Promise<void> = async () => {
      setLoading(true);
      try {
        const result = await SmsService.getAllSms({
          type: type,
          period: time,
          status: status,
          accountType: accountType === "" ? undefined : accountType,
        });
        if (result && (result.status === 200 || result.status === 201)) {
          setListSms(result.data?.data);
          setLoading(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, time, status, accountType]);

  return (
    <div className="sms">
      <div className="title">Báo cáo SMS</div>
      <Form onSubmit={async (values) => submitForm(values)}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} id="export-sms" className="sms-form">
              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item xs={3}>
                  {FormUIUtil.renderFormItem(
                    "Loại SMS",
                    <Select
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value as string);
                      }}
                      fullWidth
                      input={<OutlinedInput className="form-text-field" color="success" />}
                      variant="outlined"
                      renderValue={
                        type !== ""
                          ? undefined
                          : () => <span className="form__placeholder">- Chọn Loại SMS</span>
                      }
                      displayEmpty
                      className="form-select-field"
                      MenuProps={{
                        className: "select-menu-list",
                      }}>
                      {TypeSms.map((type, index: number) => {
                        return (
                          <MenuItem key={index} value={type.value}>
                            {type.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <div className="form-item">
                    <div className="form-label">Kỳ</div>
                    <button onClick={handleClick} className="btn-white">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}>
                        <div style={{ fontSize: "1.6rem" }}>
                          {month}/{year}
                        </div>
                        <EventIcon color="#5A5959" size={[16, 16]} viewBox={[20, 20]} />
                      </div>
                    </button>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  {FormUIUtil.renderFormItem(
                    t("STATUS"),
                    <Select
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value as string);
                      }}
                      fullWidth
                      input={<OutlinedInput className="form-text-field" color="success" />}
                      variant="outlined"
                      renderValue={
                        status !== ""
                          ? undefined
                          : () => <span className="form__placeholder">- Chọn Trạng thái</span>
                      }
                      displayEmpty
                      className="form-select-field"
                      MenuProps={{
                        className: "select-menu-list",
                      }}>
                      {StatusSms.map((status, index: number) => {
                        return (
                          <MenuItem key={index} value={status.value}>
                            {status.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {FormUIUtil.renderFormItem(
                    "Đối tượng",
                    <Select
                      value={accountType}
                      onChange={(e) => {
                        setAccountType(e.target.value as string);
                      }}
                      fullWidth
                      input={<OutlinedInput className="form-text-field" color="success" />}
                      variant="outlined"
                      renderValue={
                        accountType !== ""
                          ? undefined
                          : () => <span className="form__placeholder">- Chọn Đối tượng</span>
                      }
                      displayEmpty
                      className="form-select-field"
                      MenuProps={{
                        className: "select-menu-list",
                      }}>
                      {AccountTypeSms.map((accountType, index: number) => {
                        return (
                          <MenuItem key={index} value={accountType.value}>
                            {accountType.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Grid>
                <Grid item xs={12} container justifyContent={"flex-end"}>
                  <button
                    className="btn-confirm-style"
                    type="submit"
                    ref={submitRef}
                    onClick={() => {
                      setNotiExport(true);
                      setSuccess(undefined);
                    }}>
                    Xuất excel
                  </button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>

      {loading ? (
        <Loading style={{ height: "50vh" }} />
      ) : (
        listSms &&
        listSms?.length > 0 && (
          <div className="sms__list">
            <div className="sms__list__col sms__list__head">
              <div className="sms__list__col__1">Loại SMS</div>
              <div className="sms__list__col__2">Nội dung</div>
              <div className="sms__list__col__3">Ngày gửi</div>
              <div className="sms__list__col__4">Mã tài khoản nhận</div>
              <div className="sms__list__col__5">Tên tài khoản nhận</div>
              <div className="sms__list__col__6">Số điện thoại</div>
              <div className="sms__list__col__7">Loại tài khoản</div>
              <div className="sms__list__col__8">Trạng thái</div>
            </div>
            {listSms?.map((item, key: number) => {
              return (
                <div key={key} className="sms__list__col sms__list__item">
                  <div className="sms__list__col__1">
                    {TypeSms?.find((f) => f.value === item.type)?.label}
                  </div>
                  <div className="sms__list__col__2">{item.content}</div>
                  <div className="sms__list__col__3">{dateFormat(item.createdAt)}</div>
                  <div className="sms__list__col__4">{item.accountCode}</div>
                  <div className="sms__list__col__5">{item.accountName}</div>
                  <div className="sms__list__col__6">{item.phoneNumber}</div>
                  <div className="sms__list__col__7">{item.accountType}</div>
                  <div className="sms__list__col__8">
                    {item.succeed ? "Thành công" : "Thất bại"}
                  </div>
                </div>
              );
            })}
          </div>
        )
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <NotiExportExcel
        notiExport={notiExport}
        title="Báo cáo SMS"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default Sms;
