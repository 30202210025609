import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const NotificationService = {
  getNotificationByCustomer: (
    query: PaginationQueryModel = {
      page: 1,
      limit: 10,
    }
  ) => {
    const url = REQUEST_URL.NOTIFICATION;

    return RequestUtil.get({ url, query: query });
  },

  UpdateNotificationById: async (notiId: number) => {
    const url = `${REQUEST_URL.UPDATE_NOTIFICATION}/${notiId}`;

    const payload = { url };

    return RequestUtil.put(payload);
    // const result = await fetch(`http://103.160.85.189:8686/notification/204`, {
    //   method: "put",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //   },
    // });
    // const data = await result.json();
  },

  UpdateAllNotificationById: async () => {
    const url = REQUEST_URL.UPDATE_NOTIFICATION;

    const payload = { url };

    return RequestUtil.put(payload);
  },

  NotificationGetUsers: (query: {
    sources: string;
    searchString?: string;
    salesOrgIds: any;
    actors: any;
    getAll?: boolean;
  }) => {
    const url = REQUEST_URL.NOTI_GET_USERS;

    return RequestUtil.get({ url, query: query });
  },

  SendNotification: (data: {
    accountIds: string[];
    actors: any;
    title: string;
    body: string;
    getAll: any;
    salesOrgIds: any;
    sources: any;
  }) => {
    const url = REQUEST_URL.SEND_NOTIFICATION;

    return RequestUtil.post({ url, data });
  },
};
