import {
  GFButton,
  GFCustomerAvatar,
  GFModal,
  GFTypography,
} from "@Components/ui";
import { APPROVAL_PERSON, CUSTOMER_MANAGE_REQUEST_TYPE, CUSTOMER_MANAGE_STATUS, TEXT_HOLDER } from "@Constants";
import { ApproveOrRejectModel, ShippingAddressDetailModel } from "@Models/customer-manage.model";
import {  Grid } from "@mui/material";
import { ModalDefaultProps } from "src/types";
import { toCustomerManageStatus } from "../CustomerManage.config";
import GFLabelValue from "@Components/ui/GFLabelValue";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useGetMe from "@Hooks/useGetMe";
import { useSnackbar } from "notistack";
import { useState } from "react";
import RejectReasonModal from "../RejectModal";
import SAPCodeConfirmModal from "../ConfirmModal";

type Props = ModalDefaultProps & {
  data?: ShippingAddressDetailModel;
  onChangeRequest?: (id: number) => void;
  onApproveOrReject: (id: number, type: APPROVAL_PERSON, data: ApproveOrRejectModel) => void;
};

export default function CustomerShippingAddressDetail({
  data,
  open,
  onClose,
  onConfirm,
  onChangeRequest,
  onApproveOrReject,
}: Props) {

  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();
  const { permissions } = useGetMe();
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false)
  const [approval, setApproval] = useState<string>("")
  const [isSAPModal, setIsSAPModal] = useState<boolean>(false);

  const handleChanageRequest = () => {
    if (!data) return
    onChangeRequest && onChangeRequest(data.id)
  }

  const handleGDKDApprove = () => {
    if (!data) return
    onApproveOrReject && onApproveOrReject(data.id, APPROVAL_PERSON.GDKD, { isAccepted: true })
  }

  const handleGDKDReject = (reason: string) => {
    if (!data) return
    onApproveOrReject && onApproveOrReject(data.id, APPROVAL_PERSON.GDKD, { isAccepted: false, reason })
  }

  const handleKTBHReject = (reason: string) => {
    if (!data) return
    onApproveOrReject && onApproveOrReject(data.id, APPROVAL_PERSON.KTBH, { isAccepted: false, reason })
  }

  const handleKTBHConfirm = () => {
    if (!data) return
    onApproveOrReject && onApproveOrReject(data.id, APPROVAL_PERSON.KTBH, { isAccepted: true })
  }

  const handleGDKDClickReject = () => {
    setApproval(APPROVAL_PERSON.GDKD)
    setIsOpenRejectModal(true)
  }

  const handleKTBHClickReject = () => {
    setApproval(APPROVAL_PERSON.KTBH)
    setIsOpenRejectModal(true)
  }

  const handleCloseRejectModal = () => {
    setIsOpenRejectModal(false)
  }

  const handleConfirmRejectModal = (reason: string, approval: string) => {
    if (approval === APPROVAL_PERSON.GDKD) handleGDKDReject(reason);
    if (approval === APPROVAL_PERSON.KTBH) handleKTBHReject(reason);
    setIsOpenRejectModal(false)
  }

  const handleKTBHClickConfirm = () => {
    setIsSAPModal(true)
  }

  const handleCloseSAPModal = () => {
    setIsSAPModal(false)
  }

  const handleConfirmSAPCode = (sapCode: string) => {
    if (!data) return
    onApproveOrReject && onApproveOrReject(data.id, APPROVAL_PERSON.KTBH, { isAccepted: true, sapCode: sapCode })
    setIsSAPModal(false)
  }

  const cancelButtons = (
    <>
      <GFButton onClick={onClose} variant="outlined" minWidth={15}>
        {t('CANCEL')}
      </GFButton>
    </>
  )

  const actionDrafts = (
    <>
      <GFButton onClick={onClose} variant="outlined" minWidth={15}>
        {t('CANCEL')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        onClick={handleChanageRequest}
        minWidth={15}>
        {t('customerManage.btnSendRequest')}
      </GFButton>
    </>
  )

  const actionSubmits = (
    <>
      <GFButton className="btn-reject" 
        variant="outlined" 
        minWidth={15}
        onClick={handleGDKDClickReject}
        >
        {t('customerManage.btnReject')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        onClick={handleGDKDApprove}
        minWidth={15}>
        {t('customerManage.btnApprove')}
      </GFButton>
    </>
  )

  const actionApproves = (
    <>
      <GFButton className="btn-reject" 
        variant="outlined" minWidth={15}
        onClick={handleKTBHClickReject}
        >
        {t('customerManage.btnReject')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        minWidth={15}
        onClick={handleKTBHClickConfirm}
        >
        {t('customerManage.confirm')}
      </GFButton>
    </>
  )

  let finalActions;
  switch (data?.status) {
    case CUSTOMER_MANAGE_STATUS.DRAFT:
      finalActions = actionDrafts;
      break;

    case CUSTOMER_MANAGE_STATUS.SUBMIT:
      if (permissions.hasPermissionExportExcel) {
        finalActions = actionSubmits;
      }
      else {
        finalActions = cancelButtons
      }
      break;

    case CUSTOMER_MANAGE_STATUS.APPROVED:
      if (permissions.hasPermissionExportExcel) {
        finalActions = actionApproves;
      }
      else {
        finalActions = cancelButtons
      }
      break;

    default:
      finalActions = cancelButtons;
      break;
  }

  return (
    <>
      {isOpenRejectModal && (
        <RejectReasonModal
          open={isOpenRejectModal}
          onClose={handleCloseRejectModal}
          onConfirmReject={handleConfirmRejectModal}
          approval={approval}
        />
      )}

      {isSAPModal && (
        <SAPCodeConfirmModal
          open={isSAPModal}
          onClose={handleCloseSAPModal}
          onConfirmSAPCode={handleConfirmSAPCode}
          requestType={CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS}
        />
      )}

      <GFModal
        title={t("customerManage.detailCustomerShippingAddress")}
        open={open}
        onClose={onClose}
        width="90rem"
        actions={finalActions}
        >
        <form >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="u-flex u-flex--between u-items-center u-px-12 u-bg-white u-py-4 u-border-b">
                <GFCustomerAvatar
                  code={data?.customer.code || ''}
                  fullName={data?.customer.fullName || ''}
                  phoneNumber={data?.customer.phoneNumber}
                  fullWidth={false}
                />
                <div className="customer-status">
                  <GFTypography className="!u-mb-1">Trạng thái</GFTypography>
                  <GFTypography
                    variant="body2"
                    className={`u-rounded-full u-py-2 u-px-6 u-status-${data?.status.toLocaleLowerCase()} u-text-white`}>
                    {t(toCustomerManageStatus(data?.status) || '')}
                  </GFTypography>
                </div>
              </div>
            </Grid>
            
            <Grid item xs={12} marginBottom={1} marginLeft={'48px'}>
              <GFLabelValue label="Địa chỉ giao hàng" value={_.get(data, "address[0].shippingAddress") || TEXT_HOLDER} />
            </Grid>
            <Grid item xs={4} marginLeft={'48px'}>
              <GFLabelValue label="Tỉnh/Thành" value={_.get(data, "address[0].province.description") || TEXT_HOLDER} />
            </Grid>
            <Grid item xs={4} marginLeft={'48px'}>
              <GFLabelValue label="Quận/Huyện" value={_.get(data, "address[0].district.description") || TEXT_HOLDER} />
            </Grid>
            <Grid item xs={12}>
              <div className="u-px-12 u-bg-white u-py-4 u-border-b"></div>
            </Grid>
          </Grid>
        </form>
      </GFModal>
    </>
  );
}
