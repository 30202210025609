import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const EmployeeService = {
  getEmployee: (
    query: PaginationQueryModel & {
      source: string;
      strSearch?: string;
      forCreateAccount?: boolean;
    }
  ) => {
    const url = REQUEST_URL.ALL_EMPLOYEE;

    return RequestUtil.get({ url, query });
  },

  getAllEmployee: (data: { searchString?: string }) => {
    const url = REQUEST_URL.ALL_EMPLOYEE;
    return RequestUtil.get({ url, query: data });
  },

  getEmployeeById(employeeId: string) {
    const url = `${REQUEST_URL.ALL_EMPLOYEE}/${employeeId}`;

    return RequestUtil.get({ url });
  },

  createEmployeeAccount(data: {
    email: string;
    phoneNumber: string;
    fullName: string;
    gender?: boolean;
    code: string;
    source: string;
    position?: string;
    isRequiredOTP?: boolean;
    isActive?: boolean;
    dateOfBirth?: string;
  }) {
    const url = REQUEST_URL.SIGNUP_ACCOUNT;

    return RequestUtil.post({ url, data });
  },

  getSalesMan(query: { source: string; salesOrgIds: any[]; divisionIds: any[] }) {
    const url = REQUEST_URL.SALES_MAN;

    return RequestUtil.get({ url, query });
  },
};
