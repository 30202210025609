import GFLabel from "@Components/ui/GFLabel";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { styled } from "@mui/material/styles";
import { isFunction } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeSize } from "src/types";

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)(({ size }: { size?: ThemeSize }) => ({
  "& .MuiInputBase-root": {
    padding: 0,

    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-green)",
    },
    "&.Mui-error fieldset": {
      borderColor: "var(--destructive-color)",
    },
  },
  "& .MuiInputBase-input": {
    height: "4.8rem",
    textTransform: "none",
    borderRadius: 4,
    color: "var(--text-foreground)",
    fontSize: "1.6rem",
    padding: "0",
    lineHeight: 1,
    fontWeight: 700,
    paddingLeft: "1.6rem",

    // --- SIZES ---
    ...(size === "small" && {
      fontSize: "1.4rem",
      height: "3.6rem",
      paddingLeft: "0.8rem",

      "& .MuiSvgIcon-root": {
        fontSize: "1.6rem",
      },
    }),
    ...(size === "large" && {
      fontSize: "1.8rem",
      height: "5.6rem",
      paddingLeft: "2rem",
      "& .MuiSvgIcon-root": {
        fontSize: "2.4rem",
      },
    }),
  },
  "& .MuiInputAdornment-root": {
    marginTop: 0,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
  ...(size === "small" && {
    "& .MuiSvgIcon-root": {
      fontSize: "1.6rem",
    },
  }),
  ...(size === "large" && {
    "& .MuiSvgIcon-root": {
      fontSize: "2.4rem",
    },
  }),
}));

type GFDatePickerProps = {
  locale?: any;
  error?: any;
  value?: string;
  formatPattern?: string;
  label?: string;
  size?: ThemeSize;
  required?: boolean;
  disablePast?: boolean
  onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void;
};

export default function GFDatePicker({
  locale,
  error,
  value = '',
  label,
  size,
  required,
  disablePast,
  onChange,
}: GFDatePickerProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("translation");

  const handleOpenPicker = () => {
    setOpen(true);
  };

  const handleClosePicker = () => {
    setOpen(false);
  };

  const handleChange = (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (isFunction(onChange)) {
      onChange(date, value);
    }
  };

  return (
    <div>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
        <StyledKeyboardDatePicker
          open={open}
          onClick={handleOpenPicker}
          onClose={handleClosePicker}
          okLabel={t("SELECT")}
          cancelLabel={t("CANCEL")}
          fullWidth
          disableToolbar
          disablePast={disablePast}
          inputVariant="outlined"
          format={"dd-MM-yyyy"}
          value={value}
          onChange={handleChange}
          InputProps={{ readOnly: true }}
          helperText={error ? error : ""}
          error={!!error}
          size={size as any}
          placeholder="DD-MM-YYYY"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
