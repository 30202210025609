/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-useless-fragment */
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { ItemPermission } from "@Models";
import { IOutCome, OrderFarmOutcomeDetail } from "@Models/outCome.model";
import { OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { Divider } from "@material-ui/core";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Accordion, AccordionSummary, Dialog, Grid } from "@mui/material";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import SimpleModalHeader from "../SimpleModalHeader";
import "./index.scss";

export type P_Props = {
  closeForm: () => void;
  id: number;
  orderGroup: any;
  orderDO: any;
};

const DO: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation", "order"]);
  // const [customerValue, setCustomerValue] = useState<any>(undefined);

  const mounted = useRef(false);
  const [order, setOrder] = useState<IOutCome>();

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);
  const [open, setOpen] = useState<string | false>(false);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getInfoDeliveryNote(props.id);
      if (mounted.current) {
        setOrder(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const theme = createTheme({
  //   components: {
  //     MuiTooltip: {
  //       styleOverrides: {
  //         tooltip: {
  //           fontSize: "1.3rem",
  //           lineHeight: "1.9rem",
  //           color: " #FFFFFF",
  //           backgroundColor: "#312F30",
  //           fontFamily: "SVN-Gotham",
  //           fontWeight: 400,
  //           boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
  //           borderRadius: "0.4rem",
  //         },
  //       },
  //     },
  //   },
  // });

  // const gridSize: any = 12 / (order?.orders?.length + 2);

  // const totalDO = (title: string) => {
  //   return order?.deliveryOrders.reduce((sum: any, item: any) => {
  //     return sum + +item[`${title}`];
  //   }, 0);
  // };
  // const totalDO = (title: string) => {
  //   return "0";
  // };

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const handleChangeOpen = (open: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setOpen(isExpanded ? open : false);
  };

  // const getDimensionOfOrder = (dimensionToShow: string, listDimension: any) => {
  //   if (isArray(listDimension)) {
  //     const listDimensionToShow = listDimension.filter((item, index) => {
  //       if (item.includes(String(dimensionToShow))) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //     if (listDimensionToShow.length > 0) {
  //       let dimensionList = [...listDimensionToShow[0]];
  //       if (dimensionList[1] === "null") {
  //         return "trên " + dimensionList[0];
  //       } else if (dimensionList[0] === "null") {
  //         return "dưới " + dimensionList[1];
  //       } else {
  //         return dimensionList[0] + " - " + dimensionList[1];
  //       }
  //     }
  //   } else {
  //     return `${listDimension}`;
  //   }
  // };

  return (
    <Dialog fullScreen open={true}>
      <div className="do-listing">
        <SimpleModalHeader
          title={` ${t("order:DELIVERY_NOTE")}`}
          onClose={() => {
            props.closeForm();
          }}
        />
        <div className="order-farm-detail">
          <div className="order-farm-detail-container">
            {!!order ? (
              <div className="content">
                <div>
                  <div className={"list list-farm"}>
                    <div className="order-farm-detail-header">
                      <div className="name">Sản phẩm</div>
                      <div className="quan">{t("QUANTITY")}</div>
                      <div className="quan">SL thực xuất</div>
                      <div className="weight">{t("TOTAL_WEIGHT")}</div>
                      <div className="weight">TL thực xuất</div>
                      <div className="price">{t("UNIT_PRICE")}</div>
                      <div className="unit">Phí giống</div>
                      <div className="unit">Phần vượt</div>
                      <div className="total">{t("TOTAL_PRICE")}</div>
                      {/* Hiển thị trạng thái của order */}
                      <div className="chip">
                        {StatusUIUtil.renderBgStatusFarmNew(order.status, "farm", order?.subStatus)}
                      </div>
                    </div>

                    {order?.orderFarmOutcome?.orderFarmOutcomeDetails?.map(
                      (o: OrderFarmOutcomeDetail, idx: number) => {
                        return (
                          <>
                            <div key={idx}>
                              <div
                                style={{
                                  background: "#f7f7f7",
                                  textAlign: "center",
                                  padding: "5px 0px",
                                }}
                                className="item">
                                <div className="name">
                                  <img
                                    src={renderImageProNull(order?.orderGroup?.orderType)}
                                    style={{
                                      marginRight: 0,
                                    }}
                                    // alt={o.item.shortName}
                                    onError={renderErrorImageItem(order?.orderGroup?.orderType)}
                                  />
                                  <div>
                                    {o.itemCate === "KM" && (
                                      <span
                                        style={{
                                          padding: 4,
                                          color: "green",
                                          fontWeight: 8,
                                          border: "1px solid green",
                                          borderRadius: 8,
                                          marginRight: 1,
                                        }}>
                                        Quà tặng
                                      </span>
                                    )}
                                    {o.item.name}
                                    {/* &ensp;&#8226;&ensp;
                                      {Array.isArray(o?.item.dimension)
                                        ? o.item.dimension
                                            .filter((f: any) => {
                                              return +f[0] === +o.dimensionToShow;
                                            })
                                            .map((d: any) => {
                                              return (
                                                <span>{d[1] === null ? "Trên " + d[0] : d[1]}</span>
                                              );
                                            })
                                        : o.item.dimension}
                                      &ensp;{o.baseUoM} */}
                                    <div className="code">{o.item.code}</div>
                                  </div>
                                </div>
                                <div
                                  className="quan"
                                  style={{
                                    textAlign: "center",
                                  }}>
                                  {o.totalQty ? +o.totalQty : TEXT_HOLDER}
                                </div>
                                <div
                                  className="quan"
                                  style={{
                                    textAlign: "center",
                                  }}>
                                  {o.totalQty ? +o.totalQty : TEXT_HOLDER}
                                </div>
                                <div className="weight">
                                  {currencyFormat(+o.totalNetWeight)}&ensp;kg
                                </div>
                                <div className="weight">
                                  {currencyFormat(+o.altLineQty)}&ensp;kg
                                </div>

                                <div className="price">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(props.orderDO.orderlines[0].price)
                                    : TEXT_ASTERISK}
                                  &ensp;đ/
                                  <span style={{ color: "#41ad49" }}>{o.baseUoM}</span>
                                </div>
                                <div className="unit">
                                  {currencyFormat(parseFloat(o.breedingPrice))}đ/
                                  <span style={{ color: "#41ad49" }}>{o.baseUoM}</span>
                                </div>
                                <div className="unit">
                                  {currencyFormat(parseFloat(o.overPrice))}đ/
                                  <span style={{ color: "#41ad49" }}>{o.baseUoM}</span>
                                </div>
                                <div className="total" style={{ color: "#41ad49" }}>
                                  {checkPermissionViewPrice
                                    ? currencyFormat(parseFloat(order.orderAmount))
                                    : TEXT_ASTERISK}
                                  &ensp;đ
                                </div>
                                <div className="chip"></div>
                                {/* Hiển thị menu item */}
                                {/* Menu Item này sẽ tổng cộng có 5 button:
                                      + Tách đơn hàng
                                      + Điều phối trại
                                      + Huỷ đơn hàng
                                      + Điều phối xe
                                      + Ghép xe
                                      + 
                                  */}
                              </div>
                              {/* Thông tin điều phối xe */}
                              {open !== order.id.toString() && (
                                <>
                                  <div
                                    className="ship"
                                    style={{ background: "#f7f7f7", marginBottom: 0 }}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">{t("order:DELIVERY_DATE")}</div>
                                          {order.requiredDate}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Ghi chú</div>
                                          <div>{order.note ?? TEXT_HOLDER}</div>
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">{"Địa chỉ"}</div>
                                          {order.address || TEXT_HOLDER}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Loại xe</div>
                                          {order.orderShippingVehicles[0].vehicleType}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Biển số xe</div>
                                          {order.orderShippingVehicles[0].vehicleInfo}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Tên tài xế</div>
                                          {order.orderShippingVehicles[0].nameOfDriver}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Số điện thoại</div>
                                          {order.orderShippingVehicles[0].phoneNumberOfDriver}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Nhân viên áp tải</div>
                                          {order.orderShippingVehicles[0].escortStaff}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Mã chuyến xe</div>
                                          {order.orderShippingVehicles[0].shippingCode}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Trang trại và con giống</div>
                                          {order.orderFarmOutcome.site.name}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div>
                                          <div className="label">Chi phí vận chuyển</div>
                                          {currencyFormat(
                                            get(order, "orderShippingFeeDetail.value", 0)
                                          )}
                                          đ
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </>
                              )}
                            </div>
                            <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                              <hr style={{ border: "1px dashed grey" }} />
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className="footer"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div className="footer-invoice">
                      <Grid container className="summary" style={{ width: "30vw" }}>
                        <Grid item>
                          <div className="lb">Tổng cộng tiền thanh toán</div>
                          <div className="lb">Số dư</div>
                          <div className="lb">Số tiền bên mua phải trả thêm</div>
                          <div className="lb">Số tiền bên bán phải hoàn lại</div>
                          <div className="text-bold">Số hóa đơn</div>
                          <div className="lb">Link hóa đơn</div>
                        </Grid>
                        <Grid item className="right">
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.totalPay))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.balance))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.addPay))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.addRefund))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>

                          <div className="text-bold">
                            {checkPermissionViewPrice
                              ? get(order, "orderFarmOutcome.invoice", "")
                              : TEXT_ASTERISK}
                          </div>
                          {/* Link hóa đơn */}
                          <div className="text-bold">
                            {get(order, "orderFarmOutcome.invoiceUrl", "") ? (
                              <a
                                href={`${get(order, "orderFarmOutcome.invoiceUrl", "")}`}
                                target="_blank"
                                style={{
                                  textDecoration: "underline",
                                  color: "#0A6836",
                                }}>
                                Xem hóa đơn
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="total-gr">
                      <Grid container className="summary" style={{ width: "30vw" }}>
                        <Grid item>
                          <div className="lb">{t("order:DISCOUNT")}</div>
                          <div className="lb">{t("PROMOTION")}</div>
                          <div className="lb">{t("WITHHOLDING_PIP")}</div>
                          <div className="lb">{t("VAT")}</div>
                          <div className="text-bold">{t("TOTAL")}</div>
                        </Grid>
                        <Grid item className="right">
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.comDiscountOrder))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(
                                  order.orderFarmOutcome.orderFarmOutcomeDetails.reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + Number(currentValue.proValue),
                                    0
                                  )
                                )
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(
                                  order.orderFarmOutcome.orderFarmOutcomeDetails.reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + Number(currentValue.pitAmount),
                                    0
                                  )
                                )
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                          <div className="lb">
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.taxAmount))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>

                          <div className="text-bold" style={{ color: "#0A6836" }}>
                            {checkPermissionViewPrice
                              ? currencyFormat(parseInt(order.orderFarmOutcome.totalPay))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Accordion className="update-history" disabled>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                      className="title">
                      {t("UPDATE_HISTORY")}
                    </AccordionSummary>
                    <Divider style={{ marginBottom: "1.2rem" }} />
                  </Accordion>
                </div>
              </div>
            ) : (
              <Loading style={{ margin: "2rem 0", height: "80vh" }} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DO;
