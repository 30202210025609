import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Box)<{ variant?: string }>(({ variant }) => ({
  minHeight: "8rem",
  borderTop: "1px solid var(--border-color)",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "1.6rem",
  paddingInline: "4.8rem",
  ...(variant === "default" && {
    background: "var(--primary-white)",
  }),
}));
