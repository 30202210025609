/* eslint-disable react-hooks/exhaustive-deps */
import IconReload from "@Assets/images/icons/ic_reload.png";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ReasonPlan, TEXT_HOLDER } from "@Constants";
import TabCustom from "@Containers/Plan/TabCustom";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  ItemsPlanOutputModel,
  OrderPlanPermission,
  PlanDetailStatus,
  PlanOutputModel,
  PlanStatus,
  Reason,
} from "@Models";
import {
  Clear as ClearIcon,
  DisabledByDefaultOutlined as DisabledByDefaultOutlinedIcon,
  GridView as GridViewIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  VerticalAlignBottomRounded as VerticalAlignBottomRoundedIcon,
} from "@mui/icons-material";
import {
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ExportService } from "@Services";
import { PlanService } from "@Services/plan.service";
import {
  checkLinkImage,
  dateFormat,
  getAllDaysOfWeek,
  getWeek,
  PermissionUtil,
  renderErrorImageItem,
  renderImageProNull,
  StatusUIUtil,
} from "@Utils";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./index.scss";

const PlanDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "product"]);
  const { planId }: { planId: string } = useParams();
  const mounted = useRef(false);
  const history = useHistory();
  const location = useLocation<any>();
  const { enqueueSnackbar } = useSnackbar();
  const [submitClose, setSubmitClose] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [closePlan, setclosePlan] = useState<string>(Reason.NOT_RESPONSE);
  const [detailPlan, setDetailPlan] = useState<PlanOutputModel | null>(null);
  const [visibleStatus, setVisibleStatus] = useState<boolean>(false);
  const [visibleNoti, setVisibleNoti] = useState<PlanStatus | null>(null);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const weekList =
    (detailPlan &&
      getAllDaysOfWeek(
        new Date(detailPlan?.start).getFullYear(),
        getWeek(new Date(detailPlan?.start))
      )) ||
    [];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData: () => Promise<void> = async () => {
      try {
        const result = await PlanService.getPlanById(planId);
        if (mounted.current) {
          setDetailPlan(result.data);
        }
      } catch (err: any) {
        if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleStatus]);

  const listPros = detailPlan?.details?.map((d: any) => d?.items)?.flat() || [];
  const total = listPros?.reduce((sum: any, item: ItemsPlanOutputModel) => {
    return sum + +item?.quantity;
  }, 0);

  const changeStatus = (status: PlanStatus, noti: string, endReason?: string) => {
    if (status === PlanStatus.CLOSED && !endReason) {
      return enqueueSnackbar("Vui lòng nhập lý do", {
        variant: "error",
      });
    }
    PlanService.updatePlanStatus(planId, {
      status: status,
      reason: !!reason ? reason : endReason ?? " ",
      endReason: endReason,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(noti + " thành công", {
            variant: "success",
          });
          setVisibleStatus(!visibleStatus);
          setSubmitClose(false);
          setReason("");
          setVisibleNoti(null);
        } else {
          enqueueSnackbar(noti + " thất bại", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(noti + " thất bại", {
          variant: "error",
        });
      });
  };

  const valMenuSel = [
    {
      label: "Cần điều chỉnh",
      handleAction: () =>
        checkPermissionPlanChangeStatus
          ? setVisibleNoti(PlanStatus.CHANGING) // changeStatus(PlanStatus.CHANGING, "Cần điều chỉnh")
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.CREATED],
      status: PlanStatus.CHANGING,
    },
    {
      label: "Chỉnh sửa",
      handleAction: () =>
        checkPermissionPlanUpdate
          ? history.push(APP_ROUTES.UPDATE_PLAN.replace(":planId", planId))
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.CREATED],
      status: "EDIT",
    },
    {
      label: "Xác nhận",
      handleAction: () =>
        checkPermissionPlanChangeStatus
          ? changeStatus(PlanStatus.CONFIRMED, "Xác nhận")
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.CREATED],
      status: PlanStatus.CONFIRMED,
    },
    {
      label: "Đáp ứng",
      handleAction: () =>
        checkPermissionPlanUpdateAdapt
          ? changeStatus(PlanStatus.ADAPTED, "Xác nhận")
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.ADAPTED],
      status: PlanStatus.ADAPTED,
    },
    {
      label: "Không đáp ứng",
      handleAction: () =>
        checkPermissionPlanUpdateAdapt
          ? changeStatus(PlanStatus.NOT_ADAPTED, "Xác nhận")
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.NOT_ADAPTED],
      status: PlanStatus.NOT_ADAPTED,
    },
    {
      label: "Từ chối",
      handleAction: () =>
        checkPermissionPlanChangeStatus
          ? setVisibleNoti(PlanStatus.REJECTED) // changeStatus(PlanStatus.REJECTED, "Từ chối")
          : enqueueSnackbar(t("ACCESS_DENIED"), {
              preventDuplicate: true,
              variant: "error",
            }),
      statusCheck: [PlanStatus.CREATED],
      status: PlanStatus.REJECTED,
    },
  ];

  const checkStusRecreatePlan = [
    PlanStatus.CONFIRMED,
    PlanStatus.REJECTED,
    PlanStatus.CLOSED,
  ]?.includes(detailPlan?.status as PlanStatus);

  const renderItem = (label: string, value?: any) => {
    return (
      <div className="render-item">
        <div className="render-item__label">{label}</div>
        <div>{value || TEXT_HOLDER}</div>
      </div>
    );
  };

  const checkExportRef = useRef<any>(null);
  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [notiExport, setNotiExport] = useState<boolean>(false);

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "order_plan",
        // source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success?.isExport === !isExport]);

  const exportPlan = async () => {
    ExportService.exportPlanById(planId)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  //permission
  const checkPermissionPlanExport = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_EXPORT,
    true
  );
  const checkPermissionPlanCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_ADD,
    true
  );
  const checkPermissionPlanChangeStatus = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_UPDATE_STATUS,
    true
  );
  const checkPermissionPlanUpdate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_UPDATE,
    true
  );
  const checkPermissionPlanUpdateAdapt = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPlanPermission.MANAGE_ORDERPLAN_ADAPT,
    true
  );

  const checkAdapt =
    !profile?.role.fixed &&
    checkPermissionPlanUpdateAdapt &&
    detailPlan?.status === PlanStatus.ADAPTED;

  return (
    <Dialog fullScreen open={true}>
      <div className="plan-detail">
        <SimpleModalHeader
          title={"Chi tiết kế hoạch đặt hàng - Tuần"}
          onClose={() => {
            history.push({
              pathname: APP_ROUTES.PLAN,
              state: {
                startTime: location.state?.startTime,
                endTime: location.state?.endTime,
                status: location.state?.status,
              },
            });
          }}
        />
        <div className="plan-detail__container">
          {!!detailPlan ? (
            <div className="content">
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <div className="content__view-user">
                    <img
                      src={checkLinkImage("avatar") || renderImageProNull()}
                      alt="Avatar"
                      onError={renderErrorImageItem()}
                    />
                    <div>
                      <div className="content__view-user__name">
                        {detailPlan.customer.fullName} - {detailPlan.customer.code}
                      </div>
                      <div className="content__view-user__phone">
                        {detailPlan.customer.phoneNumber}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  {renderItem("Tên trại", detailPlan.camp.name)}
                </Grid>
                <Grid item xs={4} container gap={2} justifyContent={"flex-end"}>
                  <Tooltip title={<span style={{ fontSize: 12 }}>{t("EXPORT_DATA")}</span>} arrow>
                    <button
                      className="btn-confirm-style"
                      style={{ background: "#41AD49", width: 48 }}
                      onClick={() => {
                        if (checkPermissionPlanExport) {
                          exportPlan();
                          setNotiExport(true);
                        } else {
                          enqueueSnackbar(t("ACCESS_DENIED"), {
                            preventDuplicate: true,
                            variant: "error",
                          });
                        }
                      }}>
                      <VerticalAlignBottomRoundedIcon style={{ fontSize: 22 }} />
                    </button>
                  </Tooltip>
                  {detailPlan?.status === PlanStatus.CHANGING ||
                  detailPlan?.status === PlanStatus.NOT_ADAPTED ? (
                    <button className="btn-confirm-style" onClick={() => setSubmitClose(true)}>
                      <DisabledByDefaultOutlinedIcon
                        style={{ fontSize: 20, marginRight: 10, marginTop: 2 }}
                      />
                      Đóng kế hoạch
                    </button>
                  ) : checkStusRecreatePlan ? (
                    <button
                      className="btn-confirm-style"
                      onClick={() => {
                        if (checkPermissionPlanCreate) {
                          history.push(APP_ROUTES.RECREATE_PLAN.replace(":planId", planId));
                        } else {
                          enqueueSnackbar(t("ACCESS_DENIED"), {
                            preventDuplicate: true,
                            variant: "error",
                          });
                        }
                      }}>
                      <img src={IconReload} alt="icon-reload" />
                      Tạo lại kế hoạch
                    </button>
                  ) : (
                    !checkAdapt && (
                      <Select
                        variant="outlined"
                        className="btn-green-style btn--shiny"
                        renderValue={() => <span>{t("order:OPTIONS")}</span>}
                        displayEmpty
                        startAdornment={
                          <InputAdornment position="start">
                            <GridViewIcon />
                          </InputAdornment>
                        }
                        inputProps={{ readOnly: false }}
                        MenuProps={{
                          className: "select-menu-list",
                          anchorOrigin: {
                            vertical: 60,
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}>
                        {valMenuSel
                          ?.filter((f) => {
                            const statusCheck = f.statusCheck?.includes(
                              detailPlan.status as PlanStatus
                            );
                            if (profile?.role.fixed && detailPlan?.status === PlanStatus.ADAPTED) {
                              return (
                                f.status === PlanStatus.CONFIRMED ||
                                f.status === PlanStatus.REJECTED
                              );
                            }
                            if (
                              !profile?.role.fixed &&
                              checkPermissionPlanUpdateAdapt &&
                              detailPlan?.status === PlanStatus.CREATED
                            ) {
                              const status =
                                f.status === PlanStatus.CHANGING ||
                                f.status === PlanStatus.ADAPTED ||
                                f.status === PlanStatus.NOT_ADAPTED;
                              return status;
                            }
                            return statusCheck;
                          })

                          .map((item, key: number) => {
                            return (
                              <MenuItem key={key} onClick={item.handleAction}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                  {renderItem("ID", detailPlan.code)}
                </Grid>
                <Grid item xs={3}>
                  {renderItem("Tên kế hoạch", detailPlan.name)}
                </Grid>
                <Grid item xs={3}>
                  {renderItem("Địa chỉ giao hàng", detailPlan?.salesOffice?.description)}
                </Grid>
                <Grid item xs={3}>
                  {renderItem("Trạng thái", StatusUIUtil.renderBgStatusPlan(detailPlan.status))}
                </Grid>
                <Grid item xs={3}>
                  {renderItem(
                    "Thời gian bắt đầu",
                    dateFormat(detailPlan.start, false, "DD/MM/YYYY")
                  )}
                </Grid>
                <Grid item xs={3}>
                  {renderItem(
                    "Thời gian kết thúc",
                    dateFormat(detailPlan.end, false, "DD/MM/YYYY")
                  )}
                </Grid>
                <Grid item xs={6}>
                  {renderItem("Mô tả", detailPlan.description)}
                </Grid>
              </Grid>
              <div className="plan-detail__title">BẢNG KẾ HOẠCH</div>
              <TabCustom
                weekList={weekList}
                dataDetails={detailPlan.details}
                checkWeekUpdate={false}
                customerIdDetail={detailPlan.customer.id}
                onVisible={() => setVisibleStatus(!visibleStatus)}
                statusAll={detailPlan.status}
              />
              <div className="plan-detail__group">
                <div>Tổng sản lượng đặt theo tuần</div>
                <div className="plan-detail__group__total">{total}&#160;kg</div>
              </div>
            </div>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      <Dialog maxWidth="md" open={submitClose}>
        <div className="dialog-cancel">
          <div className="title">
            <div>Lý do đóng kế hoạch</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setSubmitClose(false);
                setReason("");
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={closePlan}
            onChange={(e) => {
              setclosePlan(e.target.value);
            }}>
            {ReasonPlan.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={r.label}
                  className="text"
                />
              );
            })}
            {closePlan === Reason.OTHER && (
              <>
                <div className="label">{t("REASON")}</div>
                <TextField
                  fullWidth
                  placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
                  className="form-textarea-field"
                  multiline
                  rows={3}
                  variant="outlined"
                  color="success"
                  inputProps={{ className: "input" }}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
              </>
            )}
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel"
              onClick={() => {
                setSubmitClose(false);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() =>
                changeStatus(
                  PlanStatus.CLOSED,
                  "Đóng kế hoạch",
                  closePlan === Reason.OTHER
                    ? reason
                    : ReasonPlan?.find((f) => f.value === closePlan)?.label
                )
              }>
              Xác nhận
            </button>
          </div>
        </div>
      </Dialog>

      <NotiExportExcel
        notiExport={notiExport}
        title={"Kế hoạch đặt hàng"}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />

      {!!visibleNoti && (
        <Notification
          visible={!!visibleNoti}
          title={t(visibleNoti === PlanStatus.CHANGING ? "Lý do cần điều chỉnh" : "Lý do từ chối")}
          subTitle={""}
          icon={
            <div className={"notification-icon-box yellow"}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          onTextChange={(text) => setReason(text.target.value)}
          isCoorinate
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                onClick={() => {
                  setVisibleNoti(null);
                }}>
                {t("NO")}
              </button>
              <input
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "10rem",
                }}
                form="site-form"
                aria-label="submit supplier form"
                value={t("CONFIRM")}
                onClick={() => {
                  !reason
                    ? enqueueSnackbar(t("order:PLEASE_ENTER_REASON"), {
                        preventDuplicate: true,
                        variant: "error",
                      })
                    : changeStatus(visibleNoti, t(visibleNoti));
                }}
              />
            </>
          }
          onClose={() => setVisibleNoti(null)}
        />
      )}
    </Dialog>
  );
};

export default PlanDetail;
