import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const PriceService = {
  getPriceList: (
    query: PaginationQueryModel & { searchValue?: string; source: string; salesOrgId: number }
  ) => {
    const url = REQUEST_URL.LIST_PRICE;
    return RequestUtil.get({ url, query: query });
  },

  getPriceFarmList: (
    query: PaginationQueryModel & { searchValue?: string; source: string; salesOfficeId: number }
  ) => {
    const url = REQUEST_URL.LIST_PRICE_FARM;
    return RequestUtil.get({ url, query: query });
  },

  // updatePrice: (data: any) => {
  //   const url = REQUEST_URL.UPDATE_PRICE;

  //   return RequestUtil.put({ url, data });
  // },

  importPrice: (formData: FormData) => {
    const url = REQUEST_URL.IMPORT_PRICE;

    return RequestUtil.post({ url, data: formData });
  },
};
