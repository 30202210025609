/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import { AccountAuthorSmallIcon } from "@Components/Icons/AccountAuthorSmall";
import { KeySmallIcon } from "@Components/Icons/KeySmall";
import Loading from "@Components/Loading";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { Action, FetchAction, RoleModal, RoleType } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { PermissionService } from "@Services";
import { Search as SearchIcon } from "@mui/icons-material";
import { Dialog, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import AuthorizeModal from "./Modal/AuthorizeModal";
import "./index.scss";
import { useTranslation } from "react-i18next";

export const DEFAULT_COLOR = "#6C778D";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [28, 28];

type P_Props = {
  roleType: RoleType;
};

function reducer(state: PaginateFetchReducer<RoleModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const RoleListing: React.FC<P_Props> = ({ roleType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState<boolean>(false);
  const mounted = useRef(false);

  const [role, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (role.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await PermissionService.getAllRole(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        searchString: searchValue,
        roleType: roleType,
      },
      true
    );
  }, [searchValue]);

  const hasMore = role.data.length < role.totalRecords && role.page < role.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  return (
    <div className="authorize-listing">
      <div className="authorize-listing-content">
        <div className="header">
          <div className="title">
            {t("ROLE")} ({role.totalRecords})
          </div>
          <TextField
            placeholder={t("ENTER_SEARCH")}
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
        {role.loading || (role.fetching && searchValue) ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {role.data ? (
              role.data.length > 0 ? (
                <div className="list">
                  <div className="list-header">
                    <div className="role">{t("ROLE")}</div>
                    <div className="user">{t("USER")}</div>
                    <div className="module">{t("MODULE")}</div>
                    <div className="permission">{t("PERMISSION")}</div>
                    <div className="des">{t("DESCRIPTION")}</div>
                  </div>

                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData(
                        {
                          limit: 50,
                          page: role.page + 1,
                          searchString: searchValue,
                        },
                        true
                      )
                    }>
                    {role.data?.map((role: RoleModal) => {
                      return (
                        <div
                          key={role.id}
                          className="item"
                          onClick={() => {
                            if (roleType === RoleType.EMPLOYEE) {
                              history.push(
                                APP_ROUTES.AUTHORIZE_ROLE_DETAIL.replace(
                                  ":roleId",
                                  role.id.toString()
                                )
                              );
                            } else {
                              history.push(
                                APP_ROUTES.ACCOUNT_ROLE_DETAIL.replace(
                                  ":roleId",
                                  role.id.toString()
                                )
                              );
                            }
                          }}>
                          <div className="role">{role.position}</div>
                          <div className="user">
                            <AccountAuthorSmallIcon
                              color={ACTIVE_COLOR}
                              size={DEFAULT_SIZE}
                              viewBox={[24, 20]}
                            />
                            {role.accounts}
                          </div>
                          <div className="module">
                            <KeySmallIcon
                              color={ACTIVE_COLOR}
                              size={DEFAULT_SIZE}
                              viewBox={[24, 2]}
                            />
                            {role.modules || TEXT_HOLDER}
                          </div>
                          <div className="permission">{role.permissions}</div>
                          <div className="des">{role.description}</div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>
      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          setOpenCreateOrderModal(true);
        }}
        btns={[]}
      />
      <Dialog fullScreen open={openCreateOrderModal}>
        <AuthorizeModal
          closeForm={() => {
            setOpenCreateOrderModal(false);
          }}
          roleType={roleType}
        />
      </Dialog>
    </div>
  );
};

export default RoleListing;
