import Avatar from "@Assets/images/avt.png";
import { DEFAULT_IMG_ALT } from "@Constants";
import { ACTIVE_COLOR, DEFAULT_SIZE } from "@Core/Layout/routes";
import { UploadedImageModel } from "@Models";
import { loadCallback } from "@Utils";
import {
  DeleteOutline as DeleteOutlineIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import { Dialog } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { ImageUploadIcon } from "../Icons/ImageUploadIcon";
import { SelectedImageIcon } from "../Icons/SelectedImageIcon";
import { SelectedStarIcon } from "../Icons/SelectedStarIcon";
import { UnselectedStarIcon } from "../Icons/UnselectedStarIcon";
import SimpleModalHeader from "../SimpleModalHeader";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

type ImagePreview = {
  visible: boolean;
  initData: { id?: string; src?: string; alt: string; file: any };
};

type P_Props = {
  className?: string;
  max: number;
  title?: string;
  style?: React.CSSProperties;
  fileList: { id: string; src: string; alt?: string; originFileObj?: any }[];
  onChange: (data: UploadedImageModel) => void;
  onDelete: (fileId: string) => void;
  config?: {
    uploadModal?: boolean;
  };
  selectConfig?: {
    selectedIds: string[];
    onSelect: (fileId: string, fileInList: any) => void;
  };
  limitSize?: boolean;
};

const ImageUpload: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();

  const classes = clsx({
    "image-upload": true,
    [props.className || ""]: Boolean(props.className),
  });
  const [imagePreview, setImagePreview] = useState<ImagePreview>({
    visible: false,
    initData: {
      file: undefined,
      alt: "",
    },
  });

  const changeImagePreviewState = <T extends keyof ImagePreview>(
    key: T,
    value: ImagePreview[T]
  ) => {
    setImagePreview({
      ...imagePreview,
      [key]: value,
    });
  };

  const closeImagePreviewModal = () => {
    setImagePreview({ visible: false, initData: { alt: "", file: undefined } });
  };

  const handleUpload = (event: any) => {
    const file: File = event.target.files[0];
    if (!!props.limitSize && file.size > 1 * 1024 * 1024) {
      enqueueSnackbar(t("UPLOAD_ERROR_SIZE", { SIZE: 1 }), {
        variant: "error",
      });
      return;
    }
    const reader = new FileReader();
    reader.onloadend = function (e) {
      props.onChange({ file, fileStr: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleModalUpload = (event: any) => {
    const file: File = event.target.files[0];
    if (!!props.limitSize && file.size > 1 * 1024 * 1024) {
      enqueueSnackbar(t("UPLOAD_ERROR_SIZE", { SIZE: 1 }), {
        variant: "error",
      });
      return;
    }
    const reader = new FileReader();
    reader.onloadend = function (e) {
      changeImagePreviewState("initData", {
        ...imagePreview.initData,
        file,
        src: reader.result as string,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    props.onChange({ file, fileStr: reader.result });
  };

  const handelModalSave = () => {
    props.onChange({
      id: imagePreview.initData.id,
      file: imagePreview.initData.file,
      fileStr: imagePreview.initData.src,
      alt: imagePreview.initData.alt,
    });
    setImagePreview({ visible: false, initData: { alt: "", file: undefined } });
  };

  const setDefaultImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Avatar;
    event.currentTarget.className = "error";
  };

  return (
    <>
      <div className={classes} style={props.style}>
        {props.fileList.length < props.max && !Boolean(props.config?.uploadModal) && (
          <div className="image-upload__input">
            <label>
              <ImageUploadIcon size={[32, 32]} color="#6658F3" viewBox={[32, 32]} />
            </label>
            <input accept="image/*" type="file" onChange={handleUpload} />
            {props.title === "Ảnh đại diện" && (
              <button className="ic-up-avt">
                <SelectedImageIcon
                  color={ACTIVE_COLOR}
                  size={DEFAULT_SIZE}
                  viewBox={DEFAULT_SIZE}
                />
              </button>
            )}
            {props.title === "Hình ảnh CMND/CCCD" && (
              <label style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontFamily: "SVN-Gotham",
                    fontSize: "1.4rem",
                    marginTop: "4rem",
                    fontWeight: 400,
                  }}>
                  Kéo thả hoặc lựa chọn ảnh
                  <br />
                  Kích thước tối đa dưới 2MB
                </div>
              </label>
            )}
          </div>
        )}

        {props.fileList.length < props.max && Boolean(props.config?.uploadModal) && (
          <button
            className="image-upload__upload-btn"
            onClick={(e) => {
              e.preventDefault();
              changeImagePreviewState("visible", true);
            }}>
            <ImageUploadIcon size={[32, 32]} color="#838283" viewBox={[32, 32]} />
            {props.title === "Ảnh đại diện" && (
              <button className="ic-up-avt">
                <SelectedImageIcon
                  color={ACTIVE_COLOR}
                  size={DEFAULT_SIZE}
                  viewBox={DEFAULT_SIZE}
                />
              </button>
            )}
            {props.title === "Hình ảnh CMND/CCCD" && (
              <div
                style={{
                  fontFamily: "SVN-Gotham",
                  fontSize: "1.4rem",
                  marginTop: "4rem",
                  fontWeight: 400,
                }}>
                Kéo thả hoặc lựa chọn ảnh
                <br />
                Kích thước tối đa dưới 2MB
              </div>
            )}
          </button>
        )}

        {props.fileList.map((file) => {
          let isSelected: boolean = false;

          if (props.selectConfig?.selectedIds.length) {
            isSelected = props.selectConfig.selectedIds.includes(file.id);
          }

          return (
            <div key={file.id} className="image-upload__img">
              <img alt={DEFAULT_IMG_ALT} src={file.src} onError={setDefaultImage} />
              <div className="image-upload__img__actions">
                {Boolean(props.config?.uploadModal) && (
                  <button
                    className="ic-click"
                    style={{
                      marginRight: "1rem",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setImagePreview({
                        visible: true,
                        initData: {
                          ...file,
                          alt: file.alt || "",
                          file: undefined,
                        },
                      });
                    }}>
                    <VisibilityOutlinedIcon style={{ fontSize: "2rem", color: "#fff" }} />
                  </button>
                )}
                <button
                  className="ic-click"
                  style={{
                    marginLeft: "1rem",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    props.onDelete(file.id);
                  }}>
                  <DeleteOutlineIcon style={{ fontSize: "2rem", color: "#fff" }} />
                </button>
              </div>

              {props.selectConfig && (
                <div>
                  {isSelected ? (
                    <button
                      className="image-upload__img__selected"
                      onClick={(e) => {
                        e.preventDefault();
                        loadCallback(props.selectConfig?.onSelect, file.id, file);
                      }}>
                      <SelectedStarIcon size={[19, 19]} color="#ffbc1f" viewBox={[19, 19]} />
                    </button>
                  ) : (
                    <button
                      className="image-upload__img__unselected"
                      onClick={(e) => {
                        e.preventDefault();
                        loadCallback(props.selectConfig?.onSelect, file.id, file);
                      }}>
                      <UnselectedStarIcon size={[19, 19]} color="#bdc6d7" viewBox={[19, 19]} />
                    </button>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Dialog open={imagePreview.visible} maxWidth="sm" fullWidth>
        <div className="image-upload__preview">
          <SimpleModalHeader
            title="Tải ảnh"
            onClose={() => {
              closeImagePreviewModal();
            }}
          />
          <div className="image-upload__preview__body">
            <div className="image-upload__preview__body__upload">
              {imagePreview.initData.src ? (
                <div className="image-upload__preview__body__upload__img">
                  <img alt={DEFAULT_IMG_ALT} src={imagePreview.initData.src} />
                  <div className="image-upload__preview__body__upload__img__actions">
                    <button
                      className="ic-click"
                      onClick={() => {
                        changeImagePreviewState("initData", {
                          ...imagePreview.initData,
                          src: undefined,
                          file: undefined,
                        });
                      }}>
                      <DeleteOutlineIcon style={{ fontSize: "2rem", color: "#fff" }} />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="image-upload__preview__body__input">
                  <label>
                    <ImageUploadIcon size={[32, 32]} color="#838283" viewBox={[32, 32]} />
                  </label>
                  <input accept="image/*" type="file" onChange={handleModalUpload} />
                </div>
              )}
            </div>
          </div>
          {props.title === "Ảnh đại diện" && (
            <>
              {!imagePreview.initData.file && !imagePreview.initData.src && (
                <div className="des">Thêm ảnh đại diện bằng cách kéo thả hoặc tải ảnh lên</div>
              )}
              {imagePreview.initData.src && !imagePreview.initData.file && (
                <div className="des">Thay đổi ảnh đại diện bằng cách kéo thả hoặc tải ảnh lên</div>
              )}
              {imagePreview.initData.file && (
                <div className="des-suc">
                  Tải lên thành công
                  <br />
                  <span>hoặc thay đổi ảnh khác</span>
                </div>
              )}
            </>
          )}
          <div
            className={`${
              props.title === "Ảnh đại diện" && "btn-group"
            } image-upload__preview__actions`}>
            {props.title === "Ảnh đại diện" && (
              <button
                className="btn-remove-style"
                onClick={() => {
                  closeImagePreviewModal();
                }}>
                {t("CANCEL")}
              </button>
            )}
            <button
              onClick={() => {
                if (imagePreview.initData.src || imagePreview.initData.file) {
                  handelModalSave();
                }
              }}
              className={
                imagePreview.initData.src || imagePreview.initData.file
                  ? `btn-confirm-style`
                  : `hide btn-confirm-style`
              }>
              Lưu
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ImageUpload;
