import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@mui/material";
import { AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { loadCallback } from "@Utils";
import Loading from "../Loading";
import "./index.scss";
import { SourceType } from "@Models";

type TAction = (...K: any[]) => Promise<void | AxiosResponse<any>>;

type Props<T, P extends TAction = any> = {
  getSource: string;
  value?: T | null;
  getOptionLabel: (opt: T) => string;
  renderOption?: (opt: T) => React.ReactNode;
  actionConfig: {
    action: P;
    getAll?: boolean;
    noAutoLoadOnFirst?: boolean;
    pageSize?: number;
    optionalQuery?: { [key: string]: any };
    handleBuildActionParams?: (query: {
      page: number;
      limit: number;
      searchValue?: string;
      source?: string;
    }) => Parameters<P>;
  };
  onChange?: (opt: T | null) => void;
  placeholder?: string;
  dropdownBoxMaxHeight?: React.CSSProperties["maxHeight"];
  icon?: any;
  className?: string;
  onClick?: () => void;
};

type FectchedListState<Item> = {
  data: Item[];
  page: number;
  totalRecords: number;
  loading: boolean;
  limit: number;
  lastPageRequest: number;
};

const EMPTY_LIST_STATE: FectchedListState<any> = {
  data: [],
  page: 1,
  totalRecords: 1,
  loading: false,
  limit: 12,
  lastPageRequest: 0,
};

function SelectInput<T, P extends TAction = any>(props: Props<T, P>): JSX.Element {
  const [source, setSource] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [listState, setListState] = useState<FectchedListState<T>>(EMPTY_LIST_STATE);
  const [forCreateAccount, setForCreateAccount] = useState<boolean>(false);
  const buildActionParams = (query: any) => {
    return [query];
  };

  const handleLoadData = (loadMore: boolean = false) => {
    if (listState.loading) return;
    if (listState.data.length >= listState.totalRecords && loadMore) return;

    const pageSize: number = props.actionConfig.pageSize || 12;
    const currentPage = Math.ceil(listState.data.length / pageSize);
    let page = loadMore ? currentPage + 1 : 1;

    if (page !== 1) {
      if (listState.lastPageRequest === page) {
        return;
      } else if (page - listState.lastPageRequest > 1) {
        page = listState.lastPageRequest ? listState.lastPageRequest + 1 : 2;
      }
    }

    const data = loadMore ? listState.data : [];
    setListState({ ...listState, loading: true, data, page, limit: pageSize });
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  useEffect(() => {
    setSource(props.getSource);
    setForCreateAccount(true);
    // eslint-disable-next-line
  }, [source, forCreateAccount]);

  useEffect(() => {
    if (listState.loading) {
      const loadMore = listState.page !== 1;
      const tempQuery = {
        page: listState.page,
        limit: listState.limit,
        source: source,
        strSearch: searchValue,
        searchString: searchValue,
        forCreateAccount: true,
        isActive: true,
        ...(source !== SourceType.LAB && { hasCusDiv: true }),
      };

      const handleBuildActionParams =
        props.actionConfig.handleBuildActionParams || buildActionParams;
      props.actionConfig
        .action(...handleBuildActionParams!(tempQuery))
        .then((res: any) => {
          if (res && (res.status === 200 || res.stats === 201)) {
            let data = res.data.data;

            if (loadMore) {
              data = [...listState.data, ...data];
            }

            let totalRecords: number = res.data?.totalRecords || 1;
            if (!data.length) {
              totalRecords = 1;
            }

            setListState({
              ...listState,
              loading: false,
              data: data,
              limit: data,
              totalRecords,
              lastPageRequest: listState.page,
            });
          } else {
            setListState(EMPTY_LIST_STATE);
          }
        })
        .catch((err) => {
          setListState(EMPTY_LIST_STATE);
        });
    }
    // eslint-disable-next-line
  }, [listState.loading]);

  useEffect(() => {
    handleLoadData(false);
    // eslint-disable-next-line
  }, [source, searchValue, forCreateAccount]);

  useEffect(() => {
    if (!props.actionConfig.noAutoLoadOnFirst) {
      handleLoadData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Autocomplete<T>
      value={props.value}
      onChange={(e, value) => {
        loadCallback(props.onChange, value);
      }}
      noOptionsText={
        !listState.loading && listState.data.length === 0 ? "Không có dữ liệu" : <Loading />
      }
      fullWidth
      options={listState.data}
      getOptionLabel={(opt) => props.getOptionLabel(opt)}
      renderOption={props.renderOption}
      filterOptions={(options, state) => options}
      renderInput={(params) => (
        <div className={props.className}>
          {props.icon}
          <TextField
            {...params}
            onClick={() => {
              loadCallback(props.onClick);
            }}
            placeholder={props.placeholder}
            variant="outlined"
            className="textfield__root"
          />
        </div>
      )}
      onInputChange={(e: any) => {
        const value = e.target?.value;

        onSearch(value);
      }}
      ListboxProps={{
        onScroll: (e: any) => {
          const ulElement = e?.target;

          if (
            !props.actionConfig.getAll &&
            ulElement &&
            ulElement.scrollTop >= ulElement.scrollHeight - ulElement.offsetHeight
          ) {
            handleLoadData(true);
          }
        },
        style: {
          maxHeight: props.dropdownBoxMaxHeight || "200px",
          fontFamily: "SVN-Gotham",
        },
      }}
      className="form-autocomplete-select-field"
    />
  );
}

export default SelectInput;
