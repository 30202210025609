import { IconBase, IconComponent } from "@Core/Icons";

export const PositionAuthorIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3856 0.104564C7.00741 -0.0191228 7.65194 0.0443576 8.23768 0.286978C8.82341 0.529598 9.32405 0.940461 9.67628 1.46761C10.0285 1.99476 10.2165 2.61452 10.2165 3.24852C10.2165 4.09868 9.87879 4.91402 9.27763 5.51518C8.67647 6.11633 7.86113 6.45406 7.01097 6.45406C6.37697 6.45406 5.75721 6.26606 5.23006 5.91383C4.70291 5.5616 4.29205 5.06096 4.04943 4.47522C3.80681 3.88949 3.74333 3.24496 3.86702 2.62315C3.9907 2.00133 4.296 1.43016 4.7443 0.981853C5.19261 0.533549 5.76378 0.228251 6.3856 0.104564ZM7.01097 2.00702C6.75952 2.00702 6.51372 2.08159 6.30465 2.22128C6.09558 2.36098 5.93263 2.55954 5.8364 2.79184C5.74018 3.02415 5.715 3.27977 5.76406 3.52639C5.81311 3.773 5.9342 3.99953 6.112 4.17733C6.2898 4.35513 6.51633 4.47622 6.76294 4.52527C7.00956 4.57433 7.26518 4.54915 7.49749 4.45293C7.72979 4.3567 7.92835 4.19375 8.06805 3.98468C8.20774 3.77561 8.28231 3.52981 8.28231 3.27836C8.28231 3.11141 8.24942 2.94609 8.18553 2.79184C8.12164 2.6376 8.02799 2.49744 7.90994 2.37939C7.79188 2.26134 7.65173 2.16769 7.49749 2.1038C7.34324 2.03991 7.17792 2.00702 7.01097 2.00702ZM14.4174 9.86916H12.495V11.0094H13.8539V13.3173H9.71134V11.0094H11.2976C11.3172 11.1231 11.3706 11.2293 11.4526 11.3129C11.5579 11.4203 11.7017 11.4816 11.8527 11.4816C12.0037 11.4816 12.1475 11.4203 12.2528 11.3129C12.358 11.2056 12.4162 11.0611 12.4162 10.9115V9.61798C12.4162 9.4683 12.358 9.32381 12.2528 9.21655C12.1475 9.10911 12.0037 9.04786 11.8527 9.04786C11.7017 9.04786 11.5579 9.10911 11.4526 9.21655C11.3474 9.32381 11.2892 9.4683 11.2892 9.61798V9.86916H9.14784C8.99683 9.86916 8.853 9.93042 8.74771 10.0378C8.64258 10.1451 8.58435 10.2896 8.58435 10.4393V13.8874C8.58435 14.0371 8.64258 14.1816 8.74771 14.2889C8.853 14.3963 8.99683 14.4575 9.14784 14.4575H14.4174C14.5684 14.4575 14.7123 14.3963 14.8176 14.2889C14.9227 14.1816 14.9809 14.0371 14.9809 13.8874V10.4393C14.9809 10.2896 14.9227 10.1451 14.8176 10.0378C14.7123 9.93042 14.5684 9.86916 14.4174 9.86916Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82154 11.1439C1.82364 11.1395 1.82935 11.128 1.83913 11.1103C1.8602 11.072 1.90001 11.005 1.96316 10.9184C2.08905 10.7458 2.30889 10.4934 2.66197 10.2327C3.35683 9.71958 4.6371 9.12355 6.88636 9.12355C8.23026 9.12355 9.22506 9.33663 9.95237 9.61331C10.443 9.79995 10.992 9.55353 11.1786 9.06291C11.3653 8.57229 11.1189 8.02327 10.6282 7.83663C9.66075 7.46858 8.4337 7.22266 6.88636 7.22266C4.27921 7.22266 2.59165 7.92163 1.53279 8.70352C1.009 9.0903 0.655496 9.48543 0.427258 9.79842C0.313354 9.95462 0.230899 10.09 0.173981 10.1934C0.145513 10.2451 0.123394 10.2888 0.106885 10.3233C0.098629 10.3405 0.0917701 10.3554 0.0862167 10.3679L0.0788514 10.3846L0.0758797 10.3916L0.0745677 10.3947L0.0739547 10.3961C0.0739547 10.3961 0.145421 10.2465 0.0733701 10.3975C-0.128536 10.8821 0.10058 11.4385 0.585113 11.6404C1.06455 11.8402 1.61443 11.618 1.82154 11.1439Z"
        fill={props.color}
      />
    </IconBase>
  );
};
