import { IconComponent } from "@Core/Icons";

export const DocumentSearchIcon: React.FC<IconComponent> = (props) => {
  return (
    <svg width="176" height="172" viewBox="0 0 176 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2609 90.8649L10.8502 90.3125L10.3044 87.7672C10.2817 87.6711 10.2135 87.623 10.1225 87.623C10.0315 87.623 9.96329 87.6951 9.94055 87.7672L9.39476 90.3365L6.98408 90.9368C6.89311 90.9609 6.84766 91.033 6.84766 91.129C6.84766 91.2251 6.91587 91.2971 7.00684 91.3211L9.41747 91.8733L9.96331 94.4188C9.98605 94.5148 10.0543 94.5629 10.1453 94.5629C10.2362 94.5629 10.3044 94.4908 10.3272 94.4188L10.873 91.8494L13.2836 91.2491C13.3746 91.2251 13.4201 91.153 13.4201 91.0569C13.4201 90.9609 13.3519 90.8649 13.2609 90.8649Z" fill="#6CA486"/>
      <path d="M74.3208 2.37736L72.5698 1.96909L72.1603 0.120067C72.1376 0.0480268 72.0922 0 72.024 0C71.9557 0 71.9102 0.0480268 71.8875 0.120067L71.4781 1.99312L69.727 2.42538C69.6588 2.44939 69.6133 2.49738 69.6133 2.56942C69.6133 2.64146 69.6588 2.68951 69.727 2.71353L71.4781 3.12174L71.8875 4.97076C71.9102 5.0428 71.9557 5.09083 72.024 5.09083C72.0922 5.09083 72.1376 5.0428 72.1603 4.97076L72.5698 3.09776L74.3208 2.66551C74.3891 2.6415 74.4345 2.59345 74.4345 2.52141C74.4345 2.44937 74.3891 2.37736 74.3208 2.37736Z" fill="#6CA486"/>
      <path d="M121.606 55.0856L124.016 54.5332L124.562 51.9879C124.585 51.8918 124.653 51.8438 124.744 51.8438C124.835 51.8438 124.903 51.9158 124.926 51.9879L125.472 54.5572L127.882 55.1576C127.973 55.1816 128.019 55.2537 128.019 55.3497C128.019 55.4458 127.951 55.5178 127.882 55.5418L125.472 56.0941L124.926 58.6395C124.903 58.7355 124.835 58.7836 124.744 58.7836C124.653 58.7836 124.585 58.7115 124.562 58.6395L124.016 56.0701L121.606 55.4698C121.515 55.4458 121.469 55.3737 121.469 55.2776C121.447 55.1816 121.515 55.0856 121.606 55.0856Z" fill="#6CA486"/>
      <path d="M131.744 33.5677L132.995 33.2794L133.268 31.9587C133.268 31.9107 133.313 31.8867 133.359 31.8867C133.404 31.8867 133.45 31.9107 133.45 31.9587L133.745 33.2794L134.996 33.5917C135.042 33.5917 135.064 33.6397 135.064 33.6877C135.064 33.7358 135.042 33.7838 134.996 33.7838L133.745 34.072L133.472 35.3927C133.472 35.4407 133.427 35.4647 133.381 35.4647C133.336 35.4647 133.29 35.4407 133.29 35.3927L132.995 34.072L131.744 33.7597C131.698 33.7597 131.676 33.7117 131.676 33.6637C131.676 33.6157 131.698 33.5917 131.744 33.5677Z" fill="#6CA486"/>
      <path d="M51.6914 163.194V84.4781C51.6914 80.0356 55.1027 76.4336 59.31 76.4336H82.5069C85.5089 76.4336 88.2152 78.2827 89.4433 81.1643L91.9223 86.9035C93.1503 89.7851 95.8794 91.634 98.8586 91.634H135.36C139.567 91.634 142.978 95.236 142.978 99.6785V163.194C142.978 167.636 139.567 171.238 135.36 171.238H59.2874C55.1256 171.238 51.6914 167.636 51.6914 163.194Z" fill="#F7F7F7"/>
      <path d="M135.404 171.96H59.3321C54.7382 171.96 51.0312 168.022 51.0312 163.196V84.4797C51.0312 79.629 54.761 75.7148 59.3321 75.7148H82.5291C85.7812 75.7148 88.7605 77.7319 90.1022 80.8537L92.5811 86.5929C93.6955 89.2103 96.197 90.8914 98.9034 90.8914H135.404C139.998 90.8914 143.705 94.8296 143.705 99.6562V163.172C143.705 168.022 139.998 171.96 135.404 171.96ZM59.3321 77.1316C55.5114 77.1316 52.3958 80.4214 52.3958 84.4557V163.172C52.3958 167.206 55.5114 170.496 59.3321 170.496H135.404C139.225 170.496 142.341 167.206 142.341 163.172V99.6562C142.341 95.622 139.225 92.3322 135.404 92.3322H98.9034C95.6512 92.3322 92.6721 90.3149 91.3303 87.1932L88.8514 81.454C87.7371 78.8366 85.2354 77.1556 82.5291 77.1556H59.3321V77.1316Z" fill="#6CA486"/>
      <path d="M131.473 171.239C131.473 171.239 122.535 170.783 120.352 158.8L112.529 112.046C111.892 108.204 108.708 105.395 105.024 105.395H35.8424C31.0666 105.395 27.4506 109.981 28.3375 114.952L37.2297 164.66C37.912 168.454 41.0504 171.215 44.7346 171.215H131.473V171.239Z" fill="#F5FBF6"/>
      <path d="M131.473 171.959H44.7117C40.7091 171.959 37.275 168.957 36.5473 164.803L27.6551 115.095C27.2003 112.526 27.837 109.884 29.429 107.867C31.0209 105.85 33.3406 104.697 35.8195 104.697H105.001C109.049 104.697 112.483 107.747 113.188 111.949L121.011 158.703C123.058 169.966 131.405 170.542 131.496 170.542L131.473 171.959ZM35.8423 106.138C33.7727 106.138 31.8169 107.099 30.4979 108.803C29.1788 110.508 28.633 112.694 29.0196 114.855L37.9118 164.563C38.5259 168.021 41.3914 170.542 44.7345 170.542H127.038C126.833 170.422 126.606 170.302 126.379 170.158C123.968 168.621 120.852 165.427 119.692 158.968L111.869 112.213C111.278 108.707 108.412 106.162 105.024 106.162H35.8423V106.138Z" fill="#6CA486"/>
      <path d="M80.6831 32.2266L58.4414 41.6639L75.6572 48.7958C75.68 48.7958 91.1445 44.1852 80.6831 32.2266Z" fill="#6CA486"/>
      <path d="M0.90625 24.6358C0.90625 24.6358 0.906208 99.8939 78.0701 47.1845C78.0701 47.1845 59.5354 50.8585 51.4847 6.67383L0.90625 24.6358Z" fill="white"/>
      <path d="M32.2662 66.6602C26.8764 66.6602 22.1006 65.4595 17.916 63.0582C10.6613 58.8799 5.29417 50.9075 2.40592 39.9814C0.245421 31.7688 0.222656 24.9249 0.222656 24.6368V24.1085L52.0065 5.73828L52.1428 6.55471C56.2591 29.1513 63.15 38.8287 68.2215 42.959C73.5659 47.3055 77.9096 46.513 77.9551 46.513L81.3664 45.8406L78.4555 47.8337C60.0571 60.3447 44.5925 66.6602 32.2662 66.6602ZM1.58718 25.1411C1.63267 26.894 1.92829 32.8734 3.72492 39.6212C5.68074 46.9693 9.81981 56.7667 18.5755 61.7855C31.5158 69.2297 50.7556 64.5471 75.7264 47.9058C73.7251 47.6657 70.7231 46.8012 67.38 44.0637C64.0369 41.3262 61.0123 37.2678 58.4197 31.9608C55.304 25.5733 52.7796 17.3847 50.9375 7.61128L1.58718 25.1411Z" fill="#6CA486"/>
      <path d="M25.3085 60.0807L24.9219 58.712C25.0811 58.664 40.955 53.5731 55.3508 46.7773L55.9193 48.0981C41.4326 54.9179 25.4677 60.0327 25.3085 60.0807Z" fill="#6CA486"/>
      <path d="M20.7219 53.8607L20.3125 52.4919C20.449 52.4439 33.9804 48.0495 50.332 40.7734L50.8551 42.0942C34.4808 49.3942 20.8583 53.8127 20.7219 53.8607Z" fill="#6CA486"/>
      <path d="M15.7141 46.9436L15.3047 45.5748C15.4411 45.5267 28.3359 41.3484 45.347 34.3125L45.8473 35.6573C28.7908 42.7172 15.8505 46.9196 15.7141 46.9436Z" fill="#6CA486"/>
      <path d="M12.2108 39.3601L11.8242 37.9672C11.9607 37.9192 25.1511 33.813 31.3597 30.8594L31.9282 32.1801C25.6286 35.1818 12.3473 39.3121 12.2108 39.3601Z" fill="#6CA486"/>
      <path d="M147.942 110.224L142.594 113.783L153.289 131.701L158.638 128.142L147.942 110.224Z" fill="#6CA486"/>
      <path d="M147.355 115.546C158.459 108.156 161.788 92.6608 154.789 80.9361C147.791 69.2113 133.116 65.697 122.012 73.0867C110.908 80.4763 107.58 95.9716 114.578 107.696C121.576 119.421 136.251 122.935 147.355 115.546Z" fill="#F5FBF6"/>
      <path d="M134.729 120.14C132.91 120.14 131.091 119.924 129.271 119.491C122.903 117.979 117.491 113.92 113.989 108.085C110.486 102.25 109.395 95.334 110.827 88.6103C112.283 81.8865 116.104 76.1713 121.63 72.4732C133.024 64.885 148.147 68.511 155.334 80.5417C158.813 86.377 159.928 93.2929 158.495 100.017C157.062 106.74 153.219 112.455 147.692 116.154C143.758 118.771 139.278 120.14 134.729 120.14ZM134.638 69.9518C130.431 69.9518 126.178 71.1525 122.38 73.6979C117.172 77.1799 113.534 82.5829 112.169 88.9224C110.805 95.2619 111.873 101.794 115.148 107.317C118.423 112.84 123.563 116.658 129.567 118.099C135.571 119.539 141.757 118.411 146.987 114.953C152.195 111.471 155.834 106.068 157.199 99.7284C158.563 93.3889 157.494 86.8572 154.219 81.3341C149.807 73.986 142.302 69.9518 134.638 69.9518Z" fill="#6CA486"/>
      <path d="M144.347 110.512C152.816 104.876 155.354 93.0581 150.016 84.1159C144.679 75.1737 133.487 72.4933 125.018 78.1292C116.549 83.7651 114.011 95.5831 119.348 104.525C124.686 113.468 135.878 116.148 144.347 110.512Z" fill="white"/>
      <path d="M134.709 114.166C128.455 114.166 122.338 110.877 118.767 104.897C116.084 100.407 115.219 95.0998 116.334 89.9129C117.448 84.726 120.405 80.3315 124.635 77.498C128.887 74.6644 133.913 73.7519 138.826 74.9286C143.738 76.1053 147.9 79.227 150.583 83.6935C156.11 92.9627 153.472 105.233 144.693 111.069C141.6 113.182 138.121 114.166 134.709 114.166ZM134.641 75.9131C131.389 75.9131 128.205 76.8737 125.385 78.7467C121.451 81.3642 118.699 85.4464 117.675 90.2491C116.652 95.0518 117.448 99.9745 119.927 104.129C125.044 112.726 135.846 115.295 143.988 109.892C152.13 104.489 154.563 93.0827 149.446 84.4859C146.967 80.3316 143.101 77.426 138.553 76.3454C137.234 76.0572 135.937 75.9131 134.641 75.9131Z" fill="#6CA486"/>
      <path d="M151.754 134.356L164.648 155.968C166.309 158.753 169.811 159.594 172.472 157.841C175.11 156.088 175.906 152.39 174.246 149.58L161.351 127.968C159.691 125.183 156.188 124.342 153.528 126.095C150.867 127.872 150.071 131.546 151.754 134.356Z" fill="#F5FBF6"/>
      <path d="M169.449 159.476C167.334 159.476 165.264 158.371 164.059 156.354L151.164 134.742C150.255 133.229 149.959 131.428 150.346 129.675C150.709 127.922 151.71 126.433 153.143 125.497C154.576 124.536 156.281 124.224 157.941 124.632C159.602 125.016 161.012 126.073 161.899 127.586L174.793 149.198C176.658 152.32 175.771 156.474 172.815 158.443C171.791 159.139 170.609 159.476 169.449 159.476ZM156.531 125.905C155.599 125.905 154.689 126.193 153.893 126.721C152.756 127.466 151.983 128.642 151.687 130.011C151.392 131.38 151.619 132.797 152.324 133.973L165.219 155.585C166.674 158.035 169.767 158.779 172.087 157.242C174.407 155.706 175.112 152.44 173.656 149.99L160.761 128.378C160.056 127.178 158.942 126.361 157.646 126.049C157.282 125.953 156.895 125.905 156.531 125.905Z" fill="#6CA486"/>
      </svg>

  );
};

