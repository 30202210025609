import { GFCheckbox, GFDataTable, GFLayoutWrapper, GFModal, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import {
  getApprovalByLabel,
  getBusinessTypeLabel,
} from "@Containers/ApprovalOrderCancellation/ApprovalOrderCancellation.config";
import CreateOrUpdateApprovalProcessForm from "@Containers/ApprovalOrderCancellation/ApprovalProcess/CreateOrUpdateApprovalProcessForm";
import useQueryPagination from "@Hooks/useQueryPagination";
import { ApprovalProcessModel } from "@Models/approval-process.model";
import { ApprovalProcessService } from "@Services/approval-process.service";
import { getErrorResponse } from "@Utils";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApprovalProcessPayload } from "src/schemas/approval-process.schema";
import { ModalState, ModalType } from "src/types";

const columnSettings = (t: any): GFDataTableColumn<ApprovalProcessModel>[] => [
  {
    title: "approvalOrderCancellation.businessType",
    dataIndex: "businessType",
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{t(getBusinessTypeLabel(cellData))}</GFTypography>;
    },
  },
  {
    title: "approvalOrderCancellation.approvalBy",
    dataIndex: "approvalBy",
    width: 300,
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{t(getApprovalByLabel(cellData))}</GFTypography>;
    },
  },
  {
    title: "approvalOrderCancellation.description",
    dataIndex: "description",
    autoWidth: true,
  },
];

export default function ApprovalProcess() {
  const {
    data,
    loading,
    hasMore,
    onRemoveSuccess,
    onUpdateSuccess,
    onCreateSuccess,
    onLoadMore,
    onSearch,
    searchValue,
  } = useQueryPagination<ApprovalProcessModel>(ApprovalProcessService.getAll);
  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });

  const isOpenCreateOrUpdateModal =
    modal.open && (modal.modalType === ModalType.Create || modal.modalType === ModalType.Update);
  const isOpenDeleteModal = modal.open && modal.modalType === ModalType.Delete;

  const handleOpenCreateModal = () => {
    setModal({
      open: true,
      selectedId: null,
      modalType: ModalType.Create,
    });
  };

  const handleOpenUpdateModal = (id: number, record: any) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Update,
      initUpdateData: record,
    });
  };

  const handleOpenDeleteModal = (id: number) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Delete,
    });
  };

  const handleCloseModal = () => {
    setModal({
      open: false,
      selectedId: null,
      initUpdateData: null,
      modalType: ModalType.Create,
    });
  };

  const handleCreateApprovalProcess = async (data: ApprovalProcessModel) => {
    try {
      setModal((prev) => ({ ...prev, loading: true }));

      const response = await ApprovalProcessService.create(data);
      enqueueSnackbar(t("approvalOrderCancellation.createProcessSuccess"), {
        variant: "success",
      });
      onCreateSuccess(response.data);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.createProcessFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateApprovalProcess = async (data: ApprovalProcessPayload, id?: number) => {
    try {
      const updateId = id || modal.selectedId;
      if (!updateId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      const response = await ApprovalProcessService.update(updateId as number, data);

      enqueueSnackbar(t("approvalOrderCancellation.updateProcessSuccess"), {
        variant: "success",
      });

      onUpdateSuccess(updateId, response.data);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.updateProcessFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDeleteApprovalProcess = async () => {
    try {
      if (!modal.selectedId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      await ApprovalProcessService.delete(modal.selectedId as number);

      enqueueSnackbar(t("approvalOrderCancellation.deleteProcessSuccess"), {
        variant: "success",
      });

      onRemoveSuccess(modal.selectedId);
      handleCloseModal();
    } catch (error) {
      enqueueSnackbar(
        `${t("approvalOrderCancellation.deleteProcessFailed")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleConfirm = (data: ApprovalProcessModel, type: ModalType) => {
    if (type === ModalType.Create) {
      handleCreateApprovalProcess(data);
    } else {
      handleUpdateApprovalProcess(data);
    }
  };

  const columns = useMemo(() => columnSettings(t), [t]);

  return (
    <GFLayoutWrapper>
      {isOpenCreateOrUpdateModal && (
        <CreateOrUpdateApprovalProcessForm
          open={isOpenCreateOrUpdateModal}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
          modalType={modal.modalType}
          initialValues={modal.initUpdateData}
          loading={modal.loading}
        />
      )}

      {isOpenDeleteModal && (
        <GFModal
          title={t("approvalOrderCancellation.deleteApprovalProcess")}
          open={isOpenDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDeleteApprovalProcess}
          loading={modal.loading}>
          <GFTypography>{t("approvalOrderCancellation.deleteProcessConfirmation")}</GFTypography>
        </GFModal>
      )}

      <GFDataTable
        columns={columns}
        dataSource={data.data}
        hasMore={hasMore}
        onLoadMore={onLoadMore}
        showDateRange={false}
        showExcelButton={false}
        showCreateButton={true}
        onCreate={handleOpenCreateModal}
        showActions={{
          showActionDeleteButton(record) {
            return !record.isLocked;
          },
          showActionEditButton(record) {
            return !record.isLocked;
          },
          onActionEdit(id, record) {
            handleOpenUpdateModal(id, record);
          },

          onActionDelete(id, record) {
            handleOpenDeleteModal(id);
          },
        }}
        searchValue={searchValue}
        onSearch={onSearch}
        loading={loading}
        height="calc(100vh - 300px)"
      />
    </GFLayoutWrapper>
  );
}
