import { GetContractQuery } from "@Models/contract.model";
import { ContractService } from "@Services/contract.service";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  checkQueryPaginationNextPage,
  getQueryPaginationData,
  getQueryPaginationTotal,
} from "@Utils";

export const useContractDetail = (contractId: number): {
  data: any,
  isLoading: boolean,
} => {
  const { data, isLoading } = useQuery({
    queryKey: ["contract-detail", contractId],
    queryFn: () => {
      if (!contractId) {
        return;
      }
      return ContractService.getById(contractId);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0
  });

  return {
    data: data?.data,
    isLoading,
  };
};


export const useContractInfinite = (params: Omit<GetContractQuery, "page">) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ["contract-list", params],
    queryFn: async ({ pageParam }) => {
      const query = {
        ...params,
        page: pageParam || 1,
      };
      return ContractService.getAll(query);
    },
    getNextPageParam: (lastPage) => {
      return checkQueryPaginationNextPage(lastPage);
    },
    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    retry: false,
    cacheTime: 0
  });
  
  return {
    data: getQueryPaginationData(data),
    hasNextPage: hasNextPage,
    isLoading: isLoading,
    isFetching: isFetching,
    fetchNextPage: fetchNextPage,
    refetch: refetch,
    total: getQueryPaginationTotal(data)
  };
};
