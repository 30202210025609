import { GFButtonCounter } from "@Components/ui";
import { styled } from "@mui/material/styles";
import { GFDataTableFilterType } from "src/types";

export const FilterWrapperStyled = styled("div")({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "16px",
  marginBottom: "3.2rem",
});

type GFDataTableFilterProps = {
  filters?: GFDataTableFilterType[];
  activeFilter?: string | number;
  onFilter?: (filterValue: string | number) => void;
};

export default function GFDataTableFilter({
  filters = [],
  activeFilter,
  onFilter,
}: GFDataTableFilterProps) {
  const handleFilter = (filterValue: string | number) => {
    if (onFilter) {
      onFilter(filterValue);
    }
  };

  return (
    <FilterWrapperStyled>
      {filters.map((filter) => (
        <GFButtonCounter
          key={filter.value}
          counter={filter.counter}
          isActive={filter.value === activeFilter}
          textColor={filter.color}
          onClick={() => handleFilter(filter.value)}>
          {filter.label}
        </GFButtonCounter>
      ))}
    </FilterWrapperStyled>
  );
}
