import { GFCheckbox, GFCustomerAvatar, GFDataTable, GFLayoutWrapper, GFModal, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { ApproveOrRejectModel, CreateCustomerShippingAddressModel, CustomerManageGridModel, CustomerRequestModel, ICustomer, MasterData, ShippingAddressDetailState } from "@Models/customer-manage.model";
import { CustomerManageService } from "@Services/customer-manage.service";
import { getErrorResponse, toClientDate } from "@Utils";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalState, ModalType, GFDataTableFilterType } from "src/types";
import _ from "lodash";
// import CreateOrUpdateCustomerManage from "./CreateOrUpdateCustomer";
import { useHistory } from "react-router-dom";
import { useCustomerManageInfinite } from "src/app/queries/customer-manage.query";
import GFFloatingButton from "@Components/ui/GFFloatingButton";
import { ContractGridModel } from "@Models/contract.model";
import FloatingBtn from "@Components/FloatingBtn";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { getContractTypeLabel } from "../contract.config";
import { useContractInfinite } from "src/app/queries/contract.query";
import { ContractService } from "@Services/contract.service";

const columnSettings = (t: any): GFDataTableColumn<ContractGridModel>[] => [
    {
      title: "Số hợp đồng",
      dataIndex: "contractNum",
      autoWidth: true,
    },
    {
      title: "Khách hàng",
      dataIndex: "customer",
      width: 300,
      autoWidth: true,
      render: (cellData) => {
        return <GFCustomerAvatar fullName={cellData?.fullName} code={cellData?.code} />
      },
    },
    {
      title: "Bên bán",
      dataIndex: "seller",
      autoWidth: true,
      width: 300,
    },
    {
      title: "Nhóm sản phẩm",
      dataIndex: "itemGroupType",
      autoWidth: true,
    },
    {
      title: "Loại hợp đồng",
      dataIndex: "type",
      autoWidth: true,
      width: 250,
      render: (cellData) => {
        return <GFTypography fontWeight={600}>{t(`${getContractTypeLabel(cellData)}`)}</GFTypography>;
      },
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "effectiveDate",
      autoWidth: true,
      render: (cellData) => {
        return <GFTypography fontWeight={600}>{toClientDate(cellData)}</GFTypography>;
      },
    },
    {
      title: "Số ngày trễ",
      dataIndex: "delayDays",
      autoWidth: true,
    },
    {
      title: "Tệp đính kèm",
      dataIndex: 'contractFile',
      autoWidth: true,
      render: (cellData) => {
        return <GFTypography fontWeight={600}>{cellData}</GFTypography>;
      },
    },
  ];
  

const Contract = () => {

  const [searchValue, setSearchValue] = useState<string>("")
  
  const {
    data,
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
    total,
    refetch
   } = useContractInfinite({
    limit: 10,
    strSearch: searchValue
  })

  const onSearch = (value: string) => {
    setSearchValue(value)
  }

  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });

  const history = useHistory();
  const isOpenDeleteModal = modal.open && modal.modalType === ModalType.Delete;
  const columns = useMemo(() => columnSettings(t), [t]);


  const handleRowClick = (code: string | number | null, record: any) => {
    history.push(APP_ROUTES.CONTRACT_DETAIL.replace(":contractId", record.id))
  }

  const handleCloseModal = () => {
    setModal({
      open: false,
      selectedId: null,
      initUpdateData: null,
      modalType: ModalType.Delete,
    });
  };

  const handleDeleteContract = async () => {
    try {
      if (!modal.selectedId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      await ContractService.delete(modal.selectedId as number);

      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });

      handleCloseModal();
      refetch()
    } catch (error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleOpenUpdateModal = (id: number, record: any) => {
    history.push(APP_ROUTES.CONTRACT_UPDATE.replace(":contractId", id.toString()))
  };

  const handleOpenDeleteModal = (id: number) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Delete,
    });
  };

  const handleCreateContract = () => {
    history.push(APP_ROUTES.CREATE_CONTRACT)
  };

  return (
    <GFLayoutWrapper>

      {isOpenDeleteModal && (
        <GFModal
          title={t("confirmInformationTitle")}
          open={isOpenDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDeleteContract}
          loading={modal.loading}>
          <GFTypography>{t("customerManage.deleteCustomerManageConfirmation")}</GFTypography>
        </GFModal>
      )}

      <GFDataTable
        columns={columns}
        dataSource={data}
        hasMore={hasNextPage}
        onLoadMore={fetchNextPage}
        showDateRange={true}
        showExcelButton={true}
        showCreateButton={false}
        showPdfButton={true}
        showSearchSection={true}
        onRowClick={handleRowClick}
        showActions={{
          showActionDeleteButton(record) {
            return true
          },
          showActionEditButton(record) {
            return true
          },
          onActionEdit(id, record) {
            handleOpenUpdateModal(id, record);
          },
          onActionDelete(id, record) {
            handleOpenDeleteModal(id);
          },
        }}
        searchValue={searchValue}
        searchPlaceholder={t('contract.searchPlaceholder')}
        onSearch={onSearch}
        loading={isLoading}
        height="calc(100vh - 300px)"
      />
      <FloatingBtn
          visible={false}
          onClickPrimary={handleCreateContract}
          btns={[]}
        />
    </GFLayoutWrapper>
  );
}

export default Contract