import Img1 from "@Assets/images/profile/icon1.png";
import Img2 from "@Assets/images/profile/icon2.png";
import Img3 from "@Assets/images/profile/icon3.png";
import Img4 from "@Assets/images/profile/icon4.png";
import ImageUpload from "@Components/ImageUpload";
import { TEXT_HOLDER } from "@Constants";
import {
  Action,
  CustomerOutputModel,
  FetchAction,
  FetchReducer,
  FormUploadImageModel,
  UploadedImageModel,
} from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import React, { useEffect, useReducer, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ChangePassword from "./ChangePassword";
import NotiProfile from "./NotiProfile";
import SendNotification from "./SendNotification";
import Sms from "./Sms";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  closeForm: () => void;
  id: string;
};

export const DEFAULT_COLOR = "#6C778D";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [28, 30];

export const LEFT_NAV_PROFILE = [
  {
    id: "0",
    title: "Đổi mật khẩu",
    icon: Img1,
  },
  {
    id: "1",
    title: "Báo cáo Thông báo",
    icon: Img2,
  },
  { id: "2", title: "Báo cáo SMS", icon: Img3 },
  { id: "3", title: "Gửi thông báo", icon: Img4 },
];

function reducer(state: FetchReducer<CustomerOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        fetching: false,
        data: action.payload,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const Profile: React.FC<P_Props> = () => {
  const { t } = useTranslation(["translation", "order"]);
  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    fetching: false,
    failMessage: "",
  });

  const [selectTab, setSelectTab] = useState<number>(0);
  const [selectPage, setSelectPage] = useState<string>("");

  const [selectCpn, setSelectCpn] = useState<string>("0");

  const uploadAvatar = async () => {
    try {
      const formData = new FormData();
      formData.append("image", avatars[0].file);
      await CustomerService.uploadImage("avatar", formData);
      window.location.reload();
    } catch (error) {}
  };

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: Action.FETCH, payload: {} });
      try {
        const result = await CustomerService.getAuthProfile();

        if (!cancel) {
          dispatch({
            type: Action.SUCCESS,
            payload: result.data,
          });
          if (result.data.employee) {
            setSelectPage(result.data.employee[0].source);
          }
        }
      } catch (err) {
        if (!cancel)
          dispatch({
            type: Action.FAILED,
            payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
          });
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    setAvatars(
      state?.data.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(state?.data?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [state.data]);

  return (
    <div className="profile">
      <div className="right">
        {state.data.employee?.length > 0 ? (
          <div style={{ display: "flex", height: "100vh" }}>
            <div style={{ borderRight: "0.1rem solid #d6d5d6", width: "35%" }}>
              <div className="sub-header">
                {state.data.employee && (
                  <>
                    {state.data.employee.map((o: any, idx: number) => {
                      return (
                        <div
                          className={idx === selectTab ? "tab-dark-green-style tab" : "tab"}
                          onClick={() => {
                            setSelectTab(idx);
                            setSelectPage(o.source);
                          }}>
                          {(() => {
                            switch (o.source) {
                              case "feed":
                                return <div>{t("FEED")}</div>;
                              case "ccu":
                                return <div>{t("SUPPLY_CHAIN")}</div>;
                              case "farm":
                                return <div>{t("FARM")}</div>;
                              default:
                                return;
                            }
                          })()}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
              {state.data.employee && (
                <>
                  {state.data.employee
                    .filter((f: any) => f.source === selectPage)
                    .map((user: any, idx: number) => {
                      return (
                        <div key={idx}>
                          <div className="right-content">
                            <div className="title">Thông tin cá nhân</div>
                            <div className="avatar">
                              <ImageUpload
                                title="Ảnh đại diện"
                                max={1}
                                fileList={avatars}
                                onChange={(data: UploadedImageModel) => {
                                  setAvatars([
                                    {
                                      id: uuidv4(),
                                      src: data.fileStr,
                                      alt: data.alt || "",
                                      file: data.file,
                                    },
                                  ]);
                                  uploadAvatar();
                                }}
                                onDelete={() => {
                                  setAvatars([]);
                                }}
                                config={{ uploadModal: true }}
                              />
                              {/* <div className="ic-up-avt">
                              <SelectedImageIcon
                                color={ACTIVE_COLOR}
                                size={DEFAULT_SIZE}
                                viewBox={DEFAULT_SIZE}
                              />
                            </div> */}
                            </div>
                            <div className="name">
                              {user.fullName ? user.fullName : TEXT_HOLDER}
                            </div>
                          </div>
                          <div className="info">
                            <div>
                              Mã nhân viên
                              <div className="text"> {user.code ? user.code : TEXT_HOLDER}</div>
                            </div>
                            <div>
                              {t("PHONE_NUMBER")}
                              <div className="text">
                                {user.phoneNumber ? user.phoneNumber : TEXT_HOLDER}
                              </div>
                            </div>
                            <div>
                              Email
                              <div className="text"> {user.email ? user.email : TEXT_HOLDER}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
            <div style={{ margin: "0 auto" }}>
              <ChangePassword />
            </div>
          </div>
        ) : (
          <>
            <div className="right-content right-content-admin">
              <div style={{ display: "flex" }}>
                <div className="avatar">
                  <ImageUpload
                    title="Ảnh đại diện"
                    max={1}
                    fileList={avatars}
                    onChange={(data: UploadedImageModel) => {
                      setAvatars([
                        {
                          id: uuidv4(),
                          src: data.fileStr,
                          alt: data.alt || "",
                          file: data.file,
                        },
                      ]);
                      uploadAvatar();
                    }}
                    onDelete={() => {
                      setAvatars([]);
                    }}
                    config={{ uploadModal: true }}
                  />
                </div>
                <div className="name">
                  {state.data?.nickname || TEXT_HOLDER}
                  <div style={{ fontWeight: "normal", color: "#525560", marginTop: "0.2rem" }}>
                    {state.data?.role?.position || TEXT_HOLDER}
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="info-label">Mã nhân viên</div>
                <div className="text"> {state.data.code || TEXT_HOLDER}</div>
              </div>
              <div className="info">
                <div className="info-label">{t("PHONE_NUMBER")}</div>
                <div className="text">{state.data.phoneNumber || TEXT_HOLDER}</div>
              </div>
              <div className="info">
                <div className="info-label">Email</div>
                <div className="text"> {state.data.email || TEXT_HOLDER}</div>
              </div>
            </div>
            <div className="content-profile">
              <div className="content-profile-left">
                {LEFT_NAV_PROFILE.map((l) => {
                  const act = selectCpn === l.id;
                  return (
                    <div
                      key={l.id}
                      className={act ? "item item-act" : "item"}
                      onClick={() => {
                        setSelectCpn(l.id);
                      }}>
                      <img src={l.icon} alt={l.title} />
                      {l.title}
                    </div>
                  );
                })}
              </div>
              <div className="content-profile-right">
                {(() => {
                  switch (selectCpn) {
                    case "0":
                      return <ChangePassword />;
                    case "1":
                      return <NotiProfile />;
                    case "2":
                      return <Sms />;
                    case "3":
                      return <SendNotification />;
                    default:
                      return;
                  }
                })()}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
