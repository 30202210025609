import { APP_ROUTES, CUSTOMER_MANAGE_REQUEST_TYPE } from "@Constants";
import {
  AccountCustomerPermission,
  AccountType,
  CustomerPermission,
  EmployeePermission,
  ItemPermission,
  OrderPermission,
  OrderPlanPermission,
  PermissionPermission,
  ReportPermission,
  RoleType,
  SourceType,
  SubAccountPermission,
} from "@Models";
import React from "react";
import { RouteProps } from "react-router-dom";

import ComingSoon from "@Components/ComingSoon";
import { AccountIcon } from "@Components/Icons/Account";
import { AccountAuthorIcon } from "@Components/Icons/AccountAuthor";
import { DashboardIcon } from "@Components/Icons/DashboardIcon";
import { EmployeeIcon } from "@Components/Icons/Employee";
import { FarmdIcon } from "@Components/Icons/FarmIcon";
import { FeedIcon } from "@Components/Icons/FeedIcon";
import { KeyIcon } from "@Components/Icons/Key";
import { OrderListIcon } from "@Components/Icons/OrderListIcon";
import { PositionAuthorIcon } from "@Components/Icons/Position_author";
import { PriceIcon } from "@Components/Icons/PriceIcon";
import { SettingsIcon } from "@Components/Icons/SettingsIcon";
// import { TransportIcon } from "@Components/Icons/TransportIcon";
import NotFound from "@Components/404";
import { HomeIcon } from "@Components/Icons/HomeIcon";
import { LeadIcon } from "@Components/Icons/LeadIcon";
import { LetterIcon } from "@Components/Icons/LetterIcon";
import { UserIcon } from "@Components/Icons/UserIcon";

import Account from "@Containers/Account";
import AccountDetail from "@Containers/Authorize/Account/Detail/AccountDetail";
import Authorize from "@Containers/Authorize/Role";
import Customer from "@Containers/Customer";
import CustomerDetail from "@Containers/Customer/Detail/CustomerDetail";
import Dashboard from "@Containers/Dashboard";
import DebtReport from "@Containers/Dashboard/Debt";
import Report from "@Containers/Dashboard/Report";
import Employee from "@Containers/Employee";
import EmployeeDetail from "@Containers/Employee/Detail/EmployeeDetail";
import OrderListing from "@Containers/Order";
import OrderDraftFarm from "@Containers/Order/Farm/OrderDraft/OrderDraftFarm";
import OrderDraft from "@Containers/Order/Feed/OrderDraft/OrderDraft";
// import SiteOrder from "@Containers/Order/Farm/SiteOrder/SiteOrder";
import AccessDenied from "@Components/AccessDenied";
import { AccountSubIcon } from "@Components/Icons/AccountSub";
import { CustomerManageIcon } from "@Components/Icons/CustomerMangeIcon";
import { DepositIcon } from "@Components/Icons/Deposit";
import { LabIcon } from "@Components/Icons/LabIcon";
import { NewsIcon } from "@Components/Icons/News";
import { PlanIcon } from "@Components/Icons/PlanIcon";
import { VetIcon } from "@Components/Icons/VetIcon";
import { WarehouseIcon } from "@Components/Icons/Warehouse";
import CustomerAccountDetail from "@Containers/Account/Detail/CustomerAccountDetail";
import { ApprovalProcess, ApprovalStatus } from "@Containers/ApprovalOrderCancellation";
import SaleAccount from "@Containers/Authorize/Account/SaleAccount";
import AuthorizeDetail from "@Containers/Authorize/Role/Detail/AuthorizeDetail";
import { Contract, ContractAddendum } from "@Containers/ContractManage";
import CreateOrUpdateContract from "@Containers/ContractManage/Contract/CreateOrUpdateContract";
import Lead from "@Containers/Customer/Lead";
import LeadDetail from "@Containers/Customer/Lead/Detail";
import CustomerManage from "@Containers/CustomerManage";
import CreateOrUpdateCustomerManage from "@Containers/CustomerManage/CreateOrUpdateCustomerPage";
import CustomerManageDetail from "@Containers/CustomerManage/CustomerManageDetail";
import DebtFarm from "@Containers/Dashboard/DebtFarm";
import Discount from "@Containers/Dashboard/Discount";
import ReportRevenue from "@Containers/Dashboard/ReportRevenue";
import DepositVehicle from "@Containers/Deposit";
import DepositCreate from "@Containers/Deposit/Modal/DepositCreate";
import DepositUpdate from "@Containers/Deposit/Modal/DepositUpdate";
import DiscountLetter from "@Containers/DiscountLetter";
import Home from "@Containers/Home";
import ListPriceShipping from "@Containers/ListPriceShipping";
import News from "@Containers/News";
import NewsDetail from "@Containers/News/Detail/NewsDetail";
import NewsModal from "@Containers/News/Modal/NewsModal";
import UpdateNews from "@Containers/News/Modal/UpdateNews";
import OrderBoarSemen from "@Containers/Order/components/BoarSemen/OrderBoarSemen";
import OrderFarmModal from "@Containers/Order/Farm/OrderFarmModal";
import OrderFarmModalType from "@Containers/Order/Farm/OrderFarmModalType";
import TruckLoadModal from "@Containers/Order/Farm/TruckLoad";
import OrderFeedModal from "@Containers/Order/Feed/OrderFeedModal";
import UpdateOrderFeed from "@Containers/Order/Feed/Update/UpdateOrderFeed";
import OrderLabModal from "@Containers/Order/Lab/OrderLabModal";
import UpdateOrderLab from "@Containers/Order/Lab/Update/UpdateOrderLab";
import UpdateOrderLabSample from "@Containers/Order/Lab/Update/UpdateOrderLabSample";
import OrderDetail from "@Containers/Order/OrderDetail/OrderDetail";
import OrderDetailFarm from "@Containers/Order/OrderDetail/OrderDetailFarm";
import OrderDetailFarmPork from "@Containers/Order/OrderDetail/OrderDetailFarmPork";
import OrderDetailFarmType from "@Containers/Order/OrderDetail/OrderDetailFarmType";
import OrderDetailLab from "@Containers/Order/OrderDetail/OrderDetailLab";
import OrderDetailVet from "@Containers/Order/OrderDetail/OrderDetailVet";
import OrderDraftVet from "@Containers/Order/Vet/OrderDraft/OrderDraft";
import OrderVetModal from "@Containers/Order/Vet/OrderVetModal";
import UpdateOrderVet from "@Containers/Order/Vet/Update/UpdateOrderVet";
import OrderListingInternal from "@Containers/OrderInternal";
import OrderDetailFarmTypeInternal from "@Containers/OrderInternal/OrderDetail/OrderDetailFarmType";
import Plan from "@Containers/Plan";
import CreatePlan from "@Containers/Plan/CreatePlan";
import PlanDetail from "@Containers/Plan/Detail";
import DraftPlan from "@Containers/Plan/DraftPlan";
import UpdatePlan from "@Containers/Plan/UpdatePlan";
import PriceListing from "@Containers/Price/index";
import Product from "@Containers/Product";
import ProductDetail from "@Containers/Product/Detail/ProductDetail";
import ProductModal from "@Containers/Product/Modal/ProductModal";
import Profile from "@Containers/Profile";
import SubAccount from "@Containers/SubAccount";
import SubAccountDetail from "@Containers/SubAccount/Detail/SubAccountDetail";
import SubAccountModal from "@Containers/SubAccount/Modal/SubAccountModal";
import UpdateSubAccount from "@Containers/SubAccount/Modal/UpdateSubAccount";
import Warehouse from "@Containers/Warehouse";
import { LocalShippingOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { FarmType } from "src/types";

export const DEFAULT_COLOR = "#6C778D";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [28, 28];

export type ModuleItem = {
  title: string;
  label: string;
  defaultIcon: React.ReactElement;
  activeIcon: React.ReactElement;
  path: string;
  subRoutes?: Array<ModuleItem>;
  permission?: any;
  enabled: boolean;
  source?: string;
  withoutSuffix?: boolean;
};

const moduleList: ModuleItem[] = [
  {
    title: "HOME",
    label: "HOME",
    defaultIcon: <HomeIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <HomeIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.HOME,
    enabled: true,
  },
  {
    title: "REPORT",
    label: "REPORT",
    defaultIcon: <DashboardIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <DashboardIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.DASHBOARD,
    permission: Object.values(ReportPermission),
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Báo cáo",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Báo cáo",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Báo cáo",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Báo cáo",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DASHBOARD,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "ORDER",
    label: "ORDER",
    defaultIcon: <OrderListIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <OrderListIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.ORDER,
    permission: OrderPermission.MANAGE_ORDER_VIEWLIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Đơn hàng",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Đơn hàng",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "farm",
      },
      {
        title: "Heo nội bộ",
        label: "Đơn hàng",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.ORDER_INTERNAL,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Đơn hàng",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Đơn hàng",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.ORDER,
        enabled: true,
        source: "lab",
      },
      // {
      //   title: "Chuỗi cung ứng",
      //   label: "Đơn hàng",
      //   defaultIcon: <TransportIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
      //   activeIcon: <TransportIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
      //   path: APP_ROUTES.ORDER,
      //   enabled: true,
      //   source: "ccu",
      // },
    ],
  },
  {
    title: "Chi phí vận chuyển",
    label: "Bảng giá vận chuyển",
    defaultIcon: (
      <Box
        sx={{
          "& svg": {
            fill: DEFAULT_COLOR,
            width: 24,
            height: 24,
          },
        }}>
        <LocalShippingOutlined />
      </Box>
    ),
    activeIcon: (
      <Box
        sx={{
          "& svg": {
            fill: ACTIVE_COLOR,
            width: 24,
            height: 24,
          },
        }}>
        <LocalShippingOutlined />
      </Box>
    ),
    path: APP_ROUTES.LIST_PRICE_SHIPPING,
    enabled: true,
    withoutSuffix: true,
  },
  {
    title: "Kế hoạch đặt hàng",
    label: "Kế hoạch đặt hàng",
    defaultIcon: <PlanIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <PlanIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    permission: OrderPlanPermission.MANAGE_ORDERPLAN_VIEWLIST,
    path: APP_ROUTES.PLAN,
    enabled: true,
  },
  {
    title: "TRANSFER_LETTER",
    label: "TRANSFER_LETTER",
    defaultIcon: <LetterIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <LetterIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.DISCOUNT_LETTER,
    permission: Object.values(ReportPermission),
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Thư CN/CK",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.DISCOUNT_LETTER,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Thư CN/CK",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DISCOUNT_LETTER,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Thư CN/CK",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.DISCOUNT_LETTER,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Thư CN/CK",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.DISCOUNT_LETTER,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "CUSTOMER",
    label: "CUSTOMER",
    defaultIcon: <UserIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <UserIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.CUSTOMER,
    permission: CustomerPermission.MANAGE_CUS_VIEW_LIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Khách hàng",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.CUSTOMER,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Khách hàng",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.CUSTOMER,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Khách hàng",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.CUSTOMER,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Khách hàng",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.CUSTOMER,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "CUSTOMER_MANAGE",
    label: "CUSTOMER_MANAGE",
    defaultIcon: (
      <CustomerManageIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
    ),
    activeIcon: (
      <CustomerManageIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
    ),
    path: APP_ROUTES.CUSTOMER_MANAGE,
    enabled: true,
  },
  {
    title: "PRODUCT",
    label: "PRODUCT",
    defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[21, 22]} />,
    activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[21, 22]} />,
    path: APP_ROUTES.PRODUCT,
    permission: ItemPermission.MANAGE_ITEM_VIEWLIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Sản phẩm",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Sản phẩm",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Sản phẩm",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Sản phẩm",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRODUCT,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "PRICE_LIST",
    label: "PRICE_LIST",
    defaultIcon: <PriceIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <PriceIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.PRICE,
    permission: ItemPermission.MANAGE_PRICE_TABLE_VIEWLIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Bảng giá",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.PRICE,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Bảng giá",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRICE,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Bảng giá",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.PRICE,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Bảng giá",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.PRICE,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "NEWS",
    label: "NEWS",
    defaultIcon: <NewsIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <NewsIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.NEWS,
    enabled: true,
  },
  {
    title: "EMPLOYEE",
    label: "EMPLOYEE",
    defaultIcon: <EmployeeIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <EmployeeIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.EMPLOYEE,
    permission: EmployeePermission.MANAGE_EMPLOYEE_VIEW_LIST,
    enabled: true,
    subRoutes: [
      {
        title: "FEED",
        label: "Nhân viên",
        defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
        path: APP_ROUTES.EMPLOYEE,
        enabled: true,
        source: "feed",
      },
      {
        title: "FARM",
        label: "Nhân viên",
        defaultIcon: <FarmdIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <FarmdIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.EMPLOYEE,
        enabled: true,
        source: "farm",
      },
      {
        title: "VET",
        label: "Nhân viên",
        defaultIcon: <VetIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        activeIcon: <VetIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[20, 20]} />,
        path: APP_ROUTES.EMPLOYEE,
        enabled: true,
        source: "vet",
      },
      {
        title: "LAB",
        label: "Nhân viên",
        defaultIcon: <LabIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        activeIcon: <LabIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 24]} />,
        path: APP_ROUTES.EMPLOYEE,
        enabled: true,
        source: "lab",
      },
    ],
  },
  {
    title: "POTENTIAL_CUSTOMER",
    label: "POTENTIAL_CUSTOMER",
    defaultIcon: <LeadIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <LeadIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.LEAD,
    enabled: true,
  },
  {
    title: "CUSTOMER_ACCOUNT",
    label: "CUSTOMER_ACCOUNT",
    defaultIcon: <AccountIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <AccountIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.ACCOUNT,
    permission: AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_VIEW_LIST,
    enabled: true,
    subRoutes: [
      {
        title: "ACCOUNT",
        label: "CUSTOMER_ACCOUNT",
        defaultIcon: (
          <AccountAuthorIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        activeIcon: (
          <AccountAuthorIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        path: APP_ROUTES.ACCOUNT,
        enabled: true,
        source: "account",
      },
      {
        title: "ROLE",
        label: "CUSTOMER_ACCOUNT",
        defaultIcon: (
          <PositionAuthorIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        activeIcon: (
          <PositionAuthorIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        path: APP_ROUTES.ACCOUNT,
        enabled: true,
        source: "role",
      },
    ],
  },
  {
    title: "SUB_ACCOUNT",
    label: "SUB_ACCOUNT",
    defaultIcon: (
      <AccountSubIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
    ),
    activeIcon: <AccountSubIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.SUB_ACCOUNT,
    permission: Object.values(SubAccountPermission),
    enabled: true,
  },
  {
    title: "PERMISSION",
    label: "PERMISSION",
    defaultIcon: <KeyIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <KeyIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.AUTHORIZATION,
    enabled: true,
    permission: PermissionPermission.MANAGE_PERMISION_VIEW_LIST,
    subRoutes: [
      {
        title: "Tài khoản",
        label: "Phân quyền",
        defaultIcon: (
          <AccountAuthorIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        activeIcon: (
          <AccountAuthorIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        path: APP_ROUTES.AUTHORIZATION,
        enabled: true,
        source: "account",
      },
      {
        title: "Vai trò",
        label: "Phân quyền",
        defaultIcon: (
          <PositionAuthorIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        activeIcon: (
          <PositionAuthorIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[24, 14]} />
        ),
        path: APP_ROUTES.AUTHORIZATION,
        enabled: true,
        source: "role",
      },
    ],
  },
  {
    title: "DEPOSIT_METHOD_LIST",
    label: "DEPOSIT",
    defaultIcon: <DepositIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <DepositIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.DEPOSIT,
    enabled: true,
  },
  {
    title: "Danh sách kế hoạch tồn kho",
    label: "INVENTORY_WAREHOUSE",
    defaultIcon: <WarehouseIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <WarehouseIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.WAREHOUSE,
    enabled: true,
  },
  // {
  //   title: "APPROVAL_PROCESS",
  //   label: "APPROVAL_PROCESS",
  //   defaultIcon: <PlanIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   activeIcon: <PlanIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   path: APP_ROUTES.APPROVAL_ORDER_CANCELLATION,
  //   enabled: true,
  //   withoutSuffix: true,
  //   subRoutes: [
  //     {
  //       title: "APPROVAL_PROCESS",
  //       label: "APPROVAL_PROCESS",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.APPROVAL_ORDER_CANCELLATION,
  //       enabled: true,
  //       withoutSuffix: true,
  //     },
  //     {
  //       title: "APPROVAL_STATUS",
  //       label: "APPROVAL_STATUS",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.APPROVAL_STATUS,
  //       enabled: true,
  //       withoutSuffix: true,
  //     },
  //   ],
  // },
  // {
  //   title: "CONTRACT_MANAGE",
  //   label: "CONTRACT_MANAGE",
  //   defaultIcon: <PlanIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   activeIcon: <PlanIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
  //   path: APP_ROUTES.CONTRACT_MANAGE,
  //   enabled: true,
  //   withoutSuffix: true,
  //   subRoutes: [
  //     {
  //       title: "CONTRACT",
  //       label: "CONTRACT",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.CONTRACT,
  //       enabled: true,
  //       withoutSuffix: true,
  //     },
  //     {
  //       title: "CONTRACT_ADDENDUM",
  //       label: "CONTRACT_ADDENDUM",
  //       defaultIcon: <FeedIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       activeIcon: <FeedIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={[22, 24]} />,
  //       path: APP_ROUTES.CONTRACT_ADDENDUM,
  //       enabled: true,
  //       withoutSuffix: true,
  //     },
  //   ],
  // },
  {
    title: "SETTING",
    label: "SETTING",
    defaultIcon: <SettingsIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <SettingsIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.SETTING,
    enabled: true,
  },
];

export const appRoutes: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.HOME,
    component: Home,
  },

  /** REPORT */
  {
    exact: true,
    path: APP_ROUTES.REPORT + "-feed",
    component: Report,
  },
  {
    exact: true,
    path: APP_ROUTES.REPORT_REVENUE + "-farm",
    component: ReportRevenue,
  },

  {
    exact: true,
    path: APP_ROUTES.DASHBOARD_OVERVIEW + "-feed",
    component: Dashboard,
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT_REPORT + "-feed",
    component: () => {
      return <DebtReport sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT_REPORT + "-farm",
    component: DebtFarm,
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT + "-feed",
    component: Discount,
  },
  {
    exact: true,
    path: APP_ROUTES.DEBT_REPORT + "-vet",
    component: () => {
      return <DebtReport sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DASHBOARD + "-lab",
    component: ComingSoon,
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT + "-farm",
    // component: Report,
    component: ComingSoon,
  },
  {
    exact: true,
    path: APP_ROUTES.COMMING_SOON + "-farm",
    component: ComingSoon,
  },
  {
    exact: true,
    path: APP_ROUTES.COMMING_SOON + "-feed",
    component: ComingSoon,
  },

  /** ORDER */
  {
    exact: true,
    path: APP_ROUTES.ORDER_FEED,
    component: () => {
      return <OrderListing sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_VET,
    component: () => {
      return <OrderListing sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_LAB,
    component: () => {
      return <OrderListing sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_FEED,
    component: OrderDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_FARM,
    render: (props) => {
      const itemGroupTypeId = new URLSearchParams(props.location.search).get("itemGroupTypeId");

      if (itemGroupTypeId === "8") {
        return <OrderDetailFarm />;
      } else if (itemGroupTypeId === "18") {
        return <OrderDetailFarmPork />;
      } else if (itemGroupTypeId === "20") {
        return <OrderDetailFarmType />;
      } else {
        return <OrderDetailFarm />;
      }
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_VET,
    component: OrderDetailVet,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_DETAIL_LAB,
    component: OrderDetailLab,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_DRAFT,
    component: OrderDraft,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_DRAFT_VET,
    component: OrderDraftVet,
  },
  {
    exact: true,
    // path: APP_ROUTES.UPDATE_ORDER_DRAFT_FARM,
    path: APP_ROUTES.UPDATE_ORDER_FARM,
    component: OrderDraftFarm,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_FEED,
    component: UpdateOrderFeed,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_VET,
    component: UpdateOrderVet,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_LAB,
    component: UpdateOrderLab,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_ORDER_LAB_SAMPLE,
    component: UpdateOrderLabSample,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FEED,
    component: OrderFeedModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FEED_ID,
    component: OrderFeedModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FARM,
    render: (props) => {
      const itemGroupTypeId = new URLSearchParams(props.location.search).get("itemGroupType");
      if (itemGroupTypeId === "20") {
        return <OrderFarmModalType />;
      } else {
        return <OrderFarmModal />;
      }
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_BOAR_SEMEN_ORDER,
    render: (props) => {
      const type = new URLSearchParams(props.location.search).get("type") as FarmType;

      if (!type || ![FarmType.External, FarmType.Internal].includes(type)) return null;

      return <OrderBoarSemen type={type} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_FARM_ID,
    component: OrderFarmModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_VET,
    component: OrderVetModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_LAB,
    component: OrderLabModal,
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM_TRUCK_LOAD,
    component: TruckLoadModal,
  },
  /** PLAN */
  {
    exact: true,
    path: APP_ROUTES.PLAN,
    component: Plan,
  },
  {
    exact: false,
    path: APP_ROUTES.PLAN_DETAIL,
    component: PlanDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_PLAN,
    component: CreatePlan,
  },
  {
    exact: true,
    path: APP_ROUTES.DRAFT_PLAN,
    component: DraftPlan,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_PLAN,
    component: UpdatePlan,
  },
  {
    exact: true,
    path: APP_ROUTES.RECREATE_PLAN,
    component: DraftPlan,
  },

  /** LETTER   */
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-feed",
    component: () => {
      return <DiscountLetter sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-farm",
    component: ComingSoon,
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-vet",
    component: ComingSoon,
  },
  {
    exact: true,
    path: APP_ROUTES.DISCOUNT_LETTER + "-lab",
    component: ComingSoon,
  },

  /** CUSTOMER */
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_FEED,
    component: () => {
      return <Customer sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_FARM,
    component: () => {
      return <Customer sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_VET,
    component: () => {
      return <Customer sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_LAB,
    component: () => {
      return <Customer sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_DETAIL_FEED,
    component: () => {
      return <CustomerDetail sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_DETAIL_FARM,
    component: () => {
      return <CustomerDetail sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_DETAIL_VET,
    component: () => {
      return <CustomerDetail sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_DETAIL_LAB,
    component: () => {
      return <CustomerDetail sourceType={SourceType.LAB} />;
    },
  },

  /** CUSTOMER MANAGE */
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_MANAGE,
    component: CustomerManage,
  },
  {
    exact: true,
    path: APP_ROUTES.CUSTOMER_MANAGE_DETAIL,
    component: CustomerManageDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_CUSTOMER_MANAGE,
    component: () => {
      return (
        <CreateOrUpdateCustomerManage type={CUSTOMER_MANAGE_REQUEST_TYPE.CREATE} isUpdate={false} />
      );
    },
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_CUSTOMER_MANAGE,
    component: () => {
      return (
        <CreateOrUpdateCustomerManage type={CUSTOMER_MANAGE_REQUEST_TYPE.CREATE} isUpdate={true} />
      );
    },
  },
  {
    exact: true,
    path: APP_ROUTES.MODIFY_CUSTOMER_MANAGE,
    component: () => {
      return (
        <CreateOrUpdateCustomerManage type={CUSTOMER_MANAGE_REQUEST_TYPE.UPDATE} isUpdate={false} />
      );
    },
  },
  {
    exact: true,
    path: APP_ROUTES.MODIFY_CUSTOMER_MANAGE_EDIT,
    component: () => {
      return (
        <CreateOrUpdateCustomerManage type={CUSTOMER_MANAGE_REQUEST_TYPE.UPDATE} isUpdate={true} />
      );
    },
  },
  /** PRODUCT */
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_FEED,
    component: () => {
      return <Product sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_FARM,
    component: () => {
      return <Product sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_VET,
    component: () => {
      return <Product sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRODUCT_LAB,
    component: () => {
      return <Product sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_PRODUCT,
    component: ProductModal,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_PRODUCT,
    component: ProductModal,
  },
  {
    exact: false,
    path: APP_ROUTES.PRODUCT_DETAIL,
    component: ProductDetail,
  },

  /** PRICE */
  {
    exact: true,
    path: APP_ROUTES.PRICE + "-feed",
    component: () => {
      return <PriceListing sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRICE + "-farm",
    component: () => {
      return <PriceListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRICE + "-vet",
    component: () => {
      return <PriceListing sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.PRICE + "-lab",
    component: () => {
      return <PriceListing sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.LIST_PRICE_SHIPPING,
    component: () => {
      return <ListPriceShipping />;
    },
  },

  //NEWS
  {
    exact: true,
    path: APP_ROUTES.NEWS,
    component: News,
  },
  {
    exact: true,
    path: APP_ROUTES.NEWS_CREATE,
    component: NewsModal,
  },
  {
    exact: true,
    path: APP_ROUTES.NEWS_UPDATE,
    component: UpdateNews,
  },
  {
    exact: false,
    path: APP_ROUTES.NEWS_DETAIL,
    component: NewsDetail,
  },

  /** LEAD */
  {
    exact: true,
    path: APP_ROUTES.LEAD,
    component: Lead,
  },
  {
    exact: false,
    path: APP_ROUTES.LEAD_DETAIL,
    component: LeadDetail,
  },

  /** EMPLOYEE */
  {
    exact: true,
    path: APP_ROUTES.EMPLOYEE_FEED,
    component: () => {
      return <Employee sourceType={SourceType.FEED} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.EMPLOYEE_FARM,
    component: () => {
      return <Employee sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.EMPLOYEE_VET,
    component: () => {
      return <Employee sourceType={SourceType.VET} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.EMPLOYEE_LAB,
    component: () => {
      return <Employee sourceType={SourceType.LAB} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.EMPLOYEE_DETAIL,
    component: EmployeeDetail,
  },

  /** ACCOUNT */
  {
    exact: true,
    path: APP_ROUTES.ACCOUNT + "-account",
    component: Account,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCOUNT + "-role",
    component: () => {
      return <Authorize roleType={RoleType.CUSTOMER} />;
    },
  },
  {
    exact: false,
    path: APP_ROUTES.ACCOUNT_ROLE_DETAIL,
    component: () => {
      return <AuthorizeDetail roleType={RoleType.CUSTOMER} />;
    },
  },
  {
    exact: false,
    path: APP_ROUTES.ACCOUNT_DETAIL,
    component: CustomerAccountDetail,
  },

  {
    exact: true,
    path: APP_ROUTES.SUB_ACCOUNT,
    component: () => {
      return <SubAccount accountType={AccountType.SUB_ACCOUNT} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_SUB_ACCOUNT,
    component: SubAccountModal,
  },
  {
    exact: true,
    path: APP_ROUTES.UPDATE_SUB_ACCOUNT,
    component: UpdateSubAccount,
  },
  {
    exact: false,
    path: APP_ROUTES.SUB_ACCOUNT_DETAIL,
    component: SubAccountDetail,
  },

  /** AUTHORIZATION */
  {
    exact: true,
    path: APP_ROUTES.AUTHORIZATION + "-role",
    component: () => {
      return <Authorize roleType={RoleType.EMPLOYEE} />;
    },
  },
  // {
  //   exact: true,
  //   path: APP_ROUTES.AUTHORIZATION,
  //   component: Authorize,
  // },
  {
    exact: true,
    path: APP_ROUTES.AUTHORIZATION + "-account",
    component: SaleAccount,
  },
  {
    exact: false,
    path: APP_ROUTES.AUTHORIZE_ACCOUNT_DETAIL,
    component: AccountDetail,
  },
  {
    exact: false,
    path: APP_ROUTES.AUTHORIZE_ROLE_DETAIL,
    component: () => {
      return <AuthorizeDetail roleType={RoleType.EMPLOYEE} />;
    },
  },

  // DEPOSIT
  {
    exact: true,
    path: APP_ROUTES.DEPOSIT,
    component: DepositVehicle,
  },
  {
    exact: true,
    path: APP_ROUTES.DEPOSIT_CREATE,
    component: DepositCreate,
  },
  {
    exact: true,
    path: APP_ROUTES.DEPOSIT_UPDATE,
    component: DepositUpdate,
  },

  // WAREHOUSE
  {
    exact: true,
    path: APP_ROUTES.WAREHOUSE,
    component: Warehouse,
  },

  /** SETTING */
  {
    exact: true,
    path: APP_ROUTES.SETTING,
    component: Profile,
  },
  {
    exact: true,
    path: APP_ROUTES.APPROVAL_ORDER_CANCELLATION,
    component: ApprovalProcess,
  },
  {
    exact: true,
    path: APP_ROUTES.APPROVAL_STATUS,
    component: ApprovalStatus,
  },
  {
    exact: true,
    path: APP_ROUTES.NOT_FOUND,
    component: NotFound,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCESS_DENIED,
    component: AccessDenied,
  },
  // HĐ/PLHĐ
  {
    exact: true,
    path: APP_ROUTES.CONTRACT_MANAGE,
    component: Contract,
  },
  {
    exact: true,
    path: APP_ROUTES.CONTRACT,
    component: Contract,
  },
  {
    exact: true,
    path: APP_ROUTES.CREATE_CONTRACT,
    component: CreateOrUpdateContract,
  },
  {
    exact: true,
    path: APP_ROUTES.CONTRACT_UPDATE,
    component: () => {
      return <CreateOrUpdateContract isUpdate={true} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.CONTRACT_ADDENDUM,
    component: ContractAddendum,
  },

  //Đơn hàng
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM_BOAR_SEMEN,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: "/order-internal-farm",
    component: () => {
      return <OrderListingInternal sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM_MARKET_PIGS,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM_CULLED_PIGS,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_FARM_INTERNAL_PIGS,
    component: () => {
      return <OrderListing sourceType={SourceType.FARM} />;
    },
  },
  {
    exact: true,
    path: APP_ROUTES.ORDER_INTERNAL_DETAIL,
    render: (props) => {
      return <OrderDetailFarmTypeInternal />;
    },
  },
];

export default moduleList;
