import { IconBase, IconComponent } from "@Core/Icons";

export const CloseOrderIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M9.66659 4.2124C9.66051 4.15881 9.64878 4.10601 9.63159 4.0549V4.0024C9.60354 3.94242 9.56613 3.88728 9.52075 3.83906L6.02075 0.339062C5.97253 0.293689 5.9174 0.256276 5.85742 0.228229C5.84001 0.225756 5.82233 0.225756 5.80492 0.228229C5.74566 0.194245 5.68022 0.17243 5.61242 0.164062H2.08325C1.61912 0.164063 1.174 0.348437 0.845815 0.676626C0.517626 1.00481 0.333252 1.44993 0.333252 1.91406V10.0807C0.333252 10.5449 0.517626 10.99 0.845815 11.3182C1.174 11.6464 1.61912 11.8307 2.08325 11.8307H7.91659C8.38071 11.8307 8.82583 11.6464 9.15402 11.3182C9.48221 10.99 9.66659 10.5449 9.66659 10.0807V4.2474C9.66659 4.2474 9.66659 4.2474 9.66659 4.2124ZM6.16659 2.15323L7.67742 3.66406H6.74992C6.59521 3.66406 6.44684 3.6026 6.33744 3.49321C6.22804 3.38381 6.16659 3.23544 6.16659 3.08073V2.15323ZM8.49992 10.0807C8.49992 10.2354 8.43846 10.3838 8.32907 10.4932C8.21967 10.6026 8.0713 10.6641 7.91659 10.6641H2.08325C1.92854 10.6641 1.78017 10.6026 1.67077 10.4932C1.56138 10.3838 1.49992 10.2354 1.49992 10.0807V1.91406C1.49992 1.75935 1.56138 1.61098 1.67077 1.50158C1.78017 1.39219 1.92854 1.33073 2.08325 1.33073H4.99992V3.08073C4.99992 3.54486 5.18429 3.98998 5.51248 4.31817C5.84067 4.64635 6.28579 4.83073 6.74992 4.83073H8.49992V10.0807ZM6.33575 6.16656L4.41659 8.09156L3.66409 7.33323C3.55424 7.22338 3.40526 7.16168 3.24992 7.16168C3.09458 7.16168 2.9456 7.22338 2.83575 7.33323C2.72591 7.44307 2.6642 7.59205 2.6642 7.7474C2.6642 7.90274 2.72591 8.05172 2.83575 8.16156L4.00242 9.32823C4.05665 9.3829 4.12116 9.4263 4.19225 9.45592C4.26333 9.48553 4.33958 9.50078 4.41659 9.50078C4.49359 9.50078 4.56984 9.48553 4.64092 9.45592C4.71201 9.4263 4.77652 9.3829 4.83075 9.32823L7.16409 6.9949C7.27393 6.88505 7.33564 6.73607 7.33564 6.58073C7.33564 6.42539 7.27393 6.27641 7.16409 6.16656C7.05424 6.05672 6.90526 5.99501 6.74992 5.99501C6.59458 5.99501 6.4456 6.05672 6.33575 6.16656Z"
        fill={props.color}
      />
    </IconBase>
  );
};
