import { IconBase, IconComponent } from "@Core/Icons";

export const PriceIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M4.78471 10.4707L11.9849 22.9419L23.5286 16.2771L16.3284 3.80597L8.84449 2.29793C8.49887 2.22841 8.14837 2.28377 7.85242 2.45464C7.55647 2.6255 7.33328 2.90136 7.22068 3.23544L4.78471 10.4707ZM9.03897 0.75975L16.8773 2.33952C16.9883 2.36171 17.0948 2.40896 17.189 2.47788C17.2832 2.54679 17.3628 2.63563 17.422 2.73798L25.2101 16.2274C25.3159 16.4106 25.3498 16.6254 25.3043 16.8244C25.2589 17.0234 25.1378 17.1903 24.9677 17.2885L12.1413 24.6938C11.9712 24.792 11.7661 24.8134 11.571 24.7533C11.3759 24.6932 11.2069 24.5565 11.1011 24.3732L3.31301 10.8838C3.25389 10.7816 3.21659 10.6684 3.20379 10.5525C3.19099 10.4366 3.20303 10.3208 3.23902 10.2136L5.79005 2.63551C6.01514 1.96694 6.46167 1.41486 7.05386 1.07296C7.64604 0.731062 8.34742 0.620399 9.03897 0.75975Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.027 2.71597C6.027 2.71597 6.027 2.71597 6.027 2.71597L3.47604 10.2938C3.47602 10.2939 3.47606 10.2938 3.47604 10.2938C3.45199 10.3655 3.44336 10.4448 3.4523 10.5257C3.46124 10.6068 3.48745 10.6867 3.52945 10.7594L11.3177 24.2489C11.3932 24.3797 11.5123 24.4743 11.6447 24.5151C11.7762 24.5556 11.9091 24.5399 12.0163 24.478L24.8427 17.0727C24.9499 17.0108 25.03 16.9036 25.0606 16.7694C25.0915 16.6343 25.0692 16.4839 24.9936 16.3531L17.2056 2.86386C17.1636 2.79114 17.1073 2.7285 17.0414 2.68034C16.9756 2.6322 16.9026 2.6002 16.8283 2.58536L8.9896 1.00551C8.9896 1.00551 8.9896 1.00551 8.9896 1.00551C8.35565 0.877768 7.71654 0.97973 7.17887 1.29015C6.6412 1.60058 6.23335 2.10308 6.027 2.71597ZM9.08837 0.515362C8.33922 0.364405 7.57557 0.483768 6.92887 0.857141C6.28217 1.23052 5.79697 1.83217 5.55313 2.55643L3.0021 10.1345C2.9542 10.2772 2.93866 10.4298 2.95532 10.5806C2.97196 10.7314 3.02033 10.8777 3.09652 11.0095C3.09649 11.0095 3.09654 11.0095 3.09652 11.0095L10.8846 24.4989C11.0207 24.7346 11.2397 24.9135 11.4974 24.9929C11.756 25.0726 12.0334 25.0455 12.2663 24.911L25.0927 17.5057C25.3256 17.3712 25.4878 17.1445 25.5481 16.8807C25.6081 16.6178 25.5627 16.3388 25.4267 16.1031L17.6385 2.61366C17.5622 2.4817 17.4592 2.36645 17.3366 2.27678C17.214 2.1871 17.0741 2.12458 16.9263 2.09505L9.08837 0.515362ZM6.98379 3.15628C7.11514 2.76655 7.377 2.44114 7.72744 2.23882C8.07788 2.03649 8.49062 1.97242 8.89381 2.05353L16.4885 3.58388L23.8702 16.3693L11.8934 23.2841L4.51177 10.4987L6.98379 3.15628ZM7.4576 3.31598C7.45759 3.316 7.45761 3.31595 7.4576 3.31598L5.05768 10.4442L12.0764 22.6011L23.1871 16.1863L16.1684 4.02943L8.79521 2.54371C8.79518 2.5437 8.79523 2.54371 8.79521 2.54371C8.50718 2.48579 8.21888 2.53243 7.97744 2.67183C7.736 2.81122 7.55145 3.03758 7.4576 3.31598Z"
        fill={props.color}
      />
      <path
        d="M11.8801 9.75711C12.177 9.58569 12.3936 9.30336 12.4823 8.97222C12.5711 8.64108 12.5246 8.28825 12.3532 7.99136C12.1818 7.69447 11.8995 7.47783 11.5683 7.3891C11.2372 7.30037 10.8843 7.34682 10.5875 7.51823C10.2906 7.68964 10.0739 7.97197 9.98519 8.30311C9.89646 8.63426 9.94291 8.98708 10.1143 9.28398C10.2857 9.58087 10.5681 9.79751 10.8992 9.88624C11.2304 9.97497 11.5832 9.92852 11.8801 9.75711ZM12.5264 10.8765C11.9326 11.2194 11.2269 11.3123 10.5647 11.1348C9.90237 10.9574 9.33771 10.5241 8.99489 9.93028C8.65206 9.3365 8.55916 8.63084 8.73662 7.96856C8.91408 7.30628 9.34736 6.74161 9.94115 6.39879C10.5349 6.05597 11.2406 5.96307 11.9029 6.14052C12.5652 6.31798 13.1298 6.75126 13.4726 7.34505C13.8155 7.93884 13.9084 8.64449 13.7309 9.30678C13.5534 9.96906 13.1202 10.5337 12.5264 10.8765Z"
        fill={props.color}
      />
      <defs>
        <clipPath id="clip0_9862_46866">
          <rect width="26" height="26" fill={props.color} />
        </clipPath>
      </defs>
    </IconBase>
  );
};
