export enum Align {
  left = "left",
  right = "right",
  center = "center",
}

export enum Font {
  normal = "normal",
  bold = "bold",
  italic = "italic",
}

export interface IColumn {
  key: string;
  index: string;
  label: string;
  render?: (record: any) => JSX.Element;
  children?: IColumn[];
  width?: number | string;
  sticky?: boolean;

  align?: Align;
  font?: Font;
}
