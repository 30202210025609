import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '@Core/store/hook';
import { LoadMe } from '@Core/store/profile';
import { DepositService } from '@Services';
import { EventIcon } from '@Components/Icons/EventIcon';
import SimpleModalHeader from '@Components/SimpleModalHeader';
import { APP_ROUTES, REGEX } from '@Constants';
import {
    DATA_DEPOSIT_METHOD,
    DepositInputModel,
    DepositOutputModel,
    E_DEPOSIT_TYPE,
    itemGroupTypeFarm,
} from '@Models';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    Backdrop,
    Checkbox,
    Dialog,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material';
import { vi } from 'date-fns/locale';
import moment from 'moment';
import "./modalDelivery.scss";
import { checkLinkImage, formatAmount, renderErrorImageItem, renderImageProNull } from '@Utils';
import { VehicleService } from '@Services/vehicle.service';
import { get } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import Loading from '@Components/Loading';
type FeeShiping = {
    // depositId: string;
    openDeliveryFee: any;
    setOpenDeliveryFee: any;

};

const ModalDeliveryFee: React.FC<FeeShiping> = ({ openDeliveryFee, setOpenDeliveryFee }) => {
    const { t } = useTranslation(['translation', 'order', 'product']);
    const params: any = useParams();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search);
    const [moreOneCustomer, setMoreOneCustomer] = useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const [dataDepositDetail, setDataDepositDetail] = useState<DepositOutputModel | null>(null);
    const [depositType, setDepositType] = useState<string>('');
    const [rate1, setRate1] = useState<number>(0);
    const [rate2, setRate2] = useState<number>(0);
    const [rate3, setRate3] = useState<number>(0);
    const [dataItemGroup, setDataItemGroup] = useState<itemGroupTypeFarm[]>([]);
    const [typeGroup, setTypeGroup] = useState<number[]>([]);
    const { data: profile } = useAppSelector(LoadMe);

    const { control, handleSubmit, setValue, formState: { errors }, watch, getValues, reset } = useForm<any>({
        defaultValues: {
            details: []
        }
    });

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'details',
    });

    useEffect(() => {
        if (profile) {
            setDataItemGroup(profile.itemGroupTypeFarm);
        }
    }, [profile]);

    const shippingFee = watch("shippingFee");
    const isolationFee = watch("isolationFee");
    const details = watch("details");

    useEffect(() => {
        let shippingFeeValue = 0;
        let isolationFeeValue = 0;
        if (shippingFee && typeof shippingFee === "string" && shippingFee.includes(",")) {
            shippingFeeValue = Number(shippingFee?.replaceAll(",", ""));
        }

        if (isolationFee && typeof isolationFee === "string" && isolationFee.includes(",")) {
            isolationFeeValue = Number(isolationFee.replaceAll(",", ""));
        }

        setValue("totalFee", formatAmount(shippingFeeValue + isolationFeeValue));
    }, [shippingFee, isolationFee])

    const checkFormatValue = (value: any) => {
        if (value && typeof value === "string" && value.includes(",")) {
            return Number(value?.replaceAll(",", ""));
        }
        else {
            return Number(value);
        }
    }

    const onSubmit = async (values: any, event: any) => {

        let totalCustomerFee = 0
        const customer = values.details.map((
            item: any
        ) => {
            let value = checkFormatValue(item.shippingCost);
            totalCustomerFee += value;
        });
        const totalFee = checkFormatValue(values.totalFee);
        if (totalFee !== totalCustomerFee) {
            enqueueSnackbar("Tổng chi phí các khách hàng phải bằng tổng chi phí", {
                variant: "error",
            });
        }
        else {
            const shippingVehicle = get(openDeliveryFee, "order.shippingVehicles", []);
            const detailOrders: any[] = [];
            let customerList: any[] = [];
            shippingVehicle.map((item: any) => {
                let object = values.details.find((customer: any) => customer.customerId === item.customerId);
                detailOrders.push({
                    orderId: item.orderId,
                    shippingCost: checkFormatValue(object.shippingCost),
                })
            })
            const isSubmitTrue = event.nativeEvent.submitter.value === "true";
            const dataUpdate = {
                ...values,
                details: detailOrders,
                currency: checkFormatValue(values.currency).toString(),
                distance: Number(values.distance ?? 0) ?? "0",
                numberOfShipping: Number(values.numberOfShipping ?? 0) ?? "0",
                shippingFee: checkFormatValue(values.shippingFee),
                isolationPrice: checkFormatValue(values.isolationPrice),
                isolationFee: checkFormatValue(values.isolationFee),
                totalFee: checkFormatValue(values.totalFee),
                isSyncERP: isSubmitTrue,
                orderGroupId: Number(params?.orderId) ?? 0,
                isCombineSAPOrder: searchQuery.get("itemGroupTypeId") === "18" ? true : undefined,
            }
            try {
                setLoading(true);
                const res = await VehicleService.updateShippingFee(dataUpdate);
                if (res.status === 200 || res.status === 201) {
                    enqueueSnackbar("Thêm chi phí thành công", {
                        variant: "success",
                    });
                    window.location.reload();
                }
            }
            catch {
                enqueueSnackbar("Thêm chi phí thất bại", {
                    variant: "error",
                });
            }
            finally {
                setLoading(false);
            }
        }
    };

    const validationRules = {
        shippingCode: {
            required: 'Mã vận chuyển không được bỏ trống'
        },
        currency: {
            required: 'Đơn giá không được bỏ trống'
        },
        distance: {
            validate: (value: any, formValues: any) => {
                if (!value && !formValues.numberOfShipping) {
                    return 'Vui lòng nhập số km hoặc số chuyến';
                }
                return true;
            }
        },
        numberOfShipping: {
            validate: (value: any, formValues: any) => {
                if (!value && !formValues.distance) {
                    return 'Vui lòng nhập số km hoặc số chuyến';
                }
                return true;
            }
        },
        shippingFee: {
            required: 'Phí giao hàng không được bỏ trống'
        }
    };

    const handleData = () => {
        const shippingDataValue = openDeliveryFee.order.orders[0].orderShippingFeeDetail?.orderShippingFee;
        if (!shippingDataValue) return
        reset({
            shippingCode: shippingDataValue.shippingCode,
            currency: formatAmount(shippingDataValue.currency),
            distance: shippingDataValue.distance,
            numberOfShipping: shippingDataValue.numberOfShipping,
            shippingFee: formatAmount(shippingDataValue.shippingFee),
            isolationCode: shippingDataValue.isolationCode,
            isolationPrice: formatAmount(shippingDataValue.isolationPrice),
            isolationNumberOfdayIn: shippingDataValue.isolationNumberOfdayIn,
            isolationFee: formatAmount(shippingDataValue.isolationPrice ?? 0),
            totalFee: formatAmount(shippingDataValue.totalFee),
            note: shippingDataValue.note,
        });
    }

    const handleDataDelivery = () => {
        const shippingVehicle = get(openDeliveryFee, "order.shippingVehicles", []);
        if (shippingVehicle) {
            let customerList: any[] = [];
            shippingVehicle.map((item: any) => {
                const checkExists = customerList.filter((ele) => ele.customerId === item.customerId);
                if (checkExists.length > 0) {
                }
                else {
                    customerList.push(item);

                }
            })
            replace(customerList); // Sử dụng replace thay vì setValue
            console.log(customerList);
            setMoreOneCustomer(customerList);
        }
    }



    useEffect(() => {
        if (openDeliveryFee.open && openDeliveryFee.isEdit) {
            handleData();
            handleDataDelivery();
        }
        else {
            handleDataDelivery();
        }
    }, [openDeliveryFee])

    console.log(fields);

    return (
        <Dialog
            maxWidth="xl"
            fullWidth={true}
            open={openDeliveryFee.open}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <form onSubmit={handleSubmit(onSubmit)} id="deposit">
                <div className="shipping-fee-modal">
                    <SimpleModalHeader
                        title={openDeliveryFee.isEdit ? "Chỉnh sửa chi phí vận chuyển" : "Thêm chi phí vận chuyển"}
                        onClose={() => setOpenDeliveryFee({
                            open: false,
                            order: null,
                            isEdit: false,
                        })}
                    />
                    <div className="content">
                        <Grid container className="truck-item" spacing={2}>
                            <Grid item xs={12}>
                                <div className="header-shipping">
                                    Thông tin chung
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Mã vận chuyển <span>*</span></div>
                                <div className="form-item">
                                    <Controller
                                        name={`shippingCode`}
                                        control={control}
                                        rules={validationRules.shippingCode}
                                        // defaultValue={item.vehicleType}
                                        render={({ field, fieldState: { error } }) => {
                                            return (
                                                <div style={{
                                                    width: "100%"
                                                }}>
                                                    <Select
                                                        {...field}
                                                        fullWidth
                                                        input={
                                                            <OutlinedInput className="form-text-field" color="success" />
                                                        }
                                                        renderValue={(selected) => {
                                                            if (!selected) {
                                                                return <div style={{
                                                                    color: "#757575",
                                                                    opacity: 0.7,
                                                                    fontWeight: 200,
                                                                    fontSize: 10
                                                                }}>Chọn mã vận chuyển</div>
                                                            }
                                                            const findObject = [...openDeliveryFee.order.priceShippings].find((item, index) => {
                                                                return item.material === selected;
                                                            })
                                                            if (findObject) {
                                                                return `${get(findObject, "material", "")} - ${get(findObject, "name", "")}`
                                                            }
                                                            return selected;
                                                        }}
                                                        placeholder='Chọn mã vận chuyển'
                                                        displayEmpty
                                                        className="form-select-field"
                                                        sx={{
                                                            width: "100%",
                                                        }}
                                                        MenuProps={{
                                                            className: "select-menu-list",
                                                            style: { maxHeight: "40rem" },
                                                        }}
                                                        // helperText={error?.message ?? ""}
                                                        error={!!error}
                                                        onChange={(e) => {
                                                            field.onChange(e.target.value); // Cập nhật giá trị cho shippingCode
                                                            const currentValues = getValues();
                                                            reset(({
                                                                ...currentValues,
                                                                currency: "",
                                                                distance: "",
                                                                numberOfShipping: "",
                                                                shippingFee: "",
                                                            }))
                                                            const objectChoosen = [...openDeliveryFee.order.priceShippings].find((item: any) => item.material === e.target.value);
                                                            if (objectChoosen) {
                                                                setValue("currency", formatAmount(Number(objectChoosen.value ?? 0)))
                                                            }
                                                        }}
                                                    >
                                                        {[...openDeliveryFee.order.priceShippings].map((value: any, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={value.material}>
                                                                    {`${value.material} - ${get(value, "name", "")}`}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Đơn giá<span>*</span></div>
                                <div className="form-item">
                                    <Controller
                                        name={`currency`}
                                        control={control}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                className="form-text-field"
                                                placeholder='VND'
                                                InputProps={{
                                                    readOnly: true,  // Set the TextField to read-only
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Số KM <span>*</span></div>
                                <div className="form-item">
                                    <Controller
                                        name={`distance`}
                                        control={control}
                                        // defaultValue={item.vehicleType}

                                        rules={validationRules.distance}
                                        render={({ field, fieldState: { error } }) => {
                                            return <TextField
                                                {...field}
                                                placeholder='Nhập số KM'
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                className="form-text-field"
                                                helperText={error?.message ?? ""}
                                                error={!!error}
                                                disabled={watch("numberOfShipping") ? true : false}
                                                onChange={(e) => {
                                                    const regex = /^\d*\.?\d{0,2}$/;
                                                    let value = e.target.value;
                                                    if (regex.test(value) || value === '') {
                                                        if (e.target.value) {
                                                            const currencyValue = getValues("currency");
                                                            const currency = typeof currencyValue === 'string' && currencyValue.includes(",") ? Number(getValues("currency")?.replaceAll(",", "")) : Number(getValues("currency"))
                                                            const distance = Number(value);
                                                            setValue("shippingFee", formatAmount(Number(currency * distance)));
                                                        }
                                                        else {
                                                            setValue("shippingFee", 0);
                                                        }
                                                        // setValue("distance", parseFloat(e.target.value).toFixed(2));

                                                        setValue("distance", value)

                                                    }
                                                }
                                                }
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Số chuyến <span>*</span></div>
                                <div className="form-item">
                                    <Controller
                                        name={`numberOfShipping`}
                                        control={control}
                                        disabled={watch("distance") ? true : false}
                                        rules={validationRules.numberOfShipping}
                                        // defaultValue={item.vehicleType}
                                        render={({ field, fieldState: { error } }) => {
                                            return <TextField
                                                {...field}
                                                placeholder='Nhập số chuyến'
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                disabled={watch("distance") ? true : false}
                                                className="form-text-field"
                                                variant="outlined"
                                                helperText={error?.message ?? ""}
                                                error={!!error}
                                                onChange={(e) => {
                                                    const regex = /^[0-9]+$/;
                                                    let value = e.target.value;
                                                    value = value.replace(/[^0-9]/g, '');
                                                    if (regex.test(value) || value === '') {
                                                        if (e.target.value) {
                                                            const currencyValue = getValues("currency");
                                                            const currency = typeof currencyValue === 'string' && currencyValue.includes(",") ? Number(getValues("currency")?.replaceAll(",", "")) : Number(getValues("currency"))
                                                            const numberOfShipping = Number(e.target.value);
                                                            setValue("shippingFee", formatAmount(Number(currency * numberOfShipping)));
                                                        }
                                                        else {
                                                            setValue("shippingFee", 0);

                                                        }
                                                        setValue("numberOfShipping", Number(value));
                                                    }
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Phí giao hàng <span>*</span></div>
                                <div className="form-item">
                                    <Controller
                                        name={`shippingFee`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                placeholder='Chọn mã vận chuyển'
                                                InputProps={{
                                                    readOnly: true,  // Set the TextField to read-only
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Mã cách ly</div>
                                <div className="form-item">
                                    <Controller
                                        name={`isolationCode`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <Select
                                                {...field}
                                                // value={depositType}
                                                fullWidth
                                                input={
                                                    <OutlinedInput className="form-text-field" color="success" />
                                                }
                                                value={field.value || ""}
                                                placeholder='Chọn mã cách ly'
                                                displayEmpty
                                                className="form-select-field"
                                                MenuProps={{
                                                    className: "select-menu-list",
                                                    style: { maxHeight: "40rem" },
                                                }}
                                                endAdornment={
                                                    field.value && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => {
                                                                field.onChange("");
                                                                reset({
                                                                    ...getValues(),
                                                                    isolationCode: "",
                                                                    isolationFee: "",
                                                                    isolationNumberOfdayIn: "",
                                                                    isolationPrice: "",
                                                                });
                                                            }}
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    )
                                                }
                                                renderValue={(selected) => {
                                                    if (!selected) {
                                                        return <div style={{
                                                            color: "#757575",
                                                            opacity: 0.7,
                                                            fontWeight: 200,
                                                            fontSize: 10
                                                        }}>Chọn mã cách ly</div>
                                                    }
                                                    return selected;
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value); // Cập nhật giá trị cho shippingCode
                                                    const currentValues = getValues();
                                                    reset(({
                                                        ...currentValues,
                                                        isolationFee: "",
                                                        isolationNumberOfdayIn: "",
                                                        isolationPrice: "",
                                                        // currency: "",
                                                        // distance: "",
                                                        // numberOfShipping: "",
                                                        // shippingFee: "",
                                                    }))
                                                    const objectChoosen = [...openDeliveryFee.order.priceIsloations, { conditionType: 'ZHVD', material: 'LFNS85', value: '100000' }].find((item: any) => item.material === e.target.value);
                                                    if (objectChoosen) {
                                                        setValue("isolationPrice", formatAmount(Number(objectChoosen.value) ?? 0))
                                                    }
                                                }}
                                            >
                                                {[...openDeliveryFee.order.priceIsloations, { conditionType: 'ZHVD', material: 'LFNS85', value: '100000' }].map((value: any, index: number) => {
                                                    return (
                                                        <MenuItem key={index} value={value.material}>
                                                            {value.material}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Đơn giá cách ly</div>
                                <div className="form-item">
                                    <Controller
                                        name={`isolationPrice`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                disabled={true}
                                                placeholder='Chọn mã cách ly'
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Số ngày cách ly </div>
                                <div className="form-item">
                                    <Controller
                                        name={`isolationNumberOfdayIn`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                className="form-text-field"
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                placeholder='Nhập số ngày'
                                                onChange={(e) => {
                                                    const regex = /^[0-9]+$/;
                                                    let value = e.target.value;
                                                    value = value.replace(/[^0-9]/g, '');
                                                    if (regex.test(value) || value === '') {
                                                        if (e.target.value) {
                                                            const currencyValue = getValues("isolationPrice");
                                                            const currency = typeof currencyValue === 'string' && currencyValue.includes(",") ? Number(getValues("isolationPrice")?.replaceAll(",", "")) : Number(getValues("isolationPrice"))
                                                            const dayOfShipping = Number(value);

                                                            setValue("isolationFee", formatAmount(Number(currency * dayOfShipping)));
                                                        }
                                                        else {
                                                            setValue("isolationFee", 0);

                                                        }
                                                        setValue("isolationNumberOfdayIn", Number(value));
                                                    }
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Chi phí cách ly </div>
                                <div className="form-item">
                                    <Controller
                                        name={`isolationFee`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                className="form-text-field"
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                placeholder='Nhập chi phí'
                                                InputProps={{
                                                    readOnly: true,  // Set the TextField to read-only
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label">Tổng chi phí </div>
                                <div className="form-item">
                                    <Controller
                                        name={`totalFee`}
                                        control={control}
                                        // defaultValue={item.vehicleType}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                className="form-text-field"
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                placeholder='Nhập tổng chi phí'
                                                InputProps={{
                                                    readOnly: true,  // Set the TextField to read-only
                                                }}
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="label">Ghi chú</div>
                                <div className="form-item">
                                    <Controller
                                        name={`note`}
                                        control={control}
                                        render={({ field }) => {
                                            return <TextField
                                                {...field}
                                                style={{ width: "100%", fontSize: "1.6rem" }}
                                                variant="outlined"
                                                className="form-text-field"
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                placeholder='Nhập'
                                            />
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {details && details.length > 0 &&
                            <Grid container className="truck-item header-shipping-customer" spacing={2}
                                style={{ marginTop: "2rem" }}
                            >
                                <Grid item xs={12}>
                                    <div className="header-shipping">
                                        Thông tin khách hàng
                                    </div>
                                </Grid>
                                {fields.map((item: any, index: number) => {
                                    console.log("Rendering field:", item); // Thêm log này
                                    return (
                                        <Grid item xs={12} className="left" key={item.customerId}>
                                            <div className="content-customer">
                                                <div className="customer-info">
                                                    <img
                                                        src={
                                                            checkLinkImage(
                                                                item.customerAvartar
                                                            ) || renderImageProNull()
                                                        }
                                                        alt="Avata"
                                                        onError={renderErrorImageItem()}
                                                    />
                                                    <div>
                                                        <div className="name">
                                                            {item.customerFullName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label">Chi phí vận chuyển </div>
                                                    <div className="form-item">
                                                        <Controller
                                                            name={`details.${index}.shippingCost`}
                                                            control={control}
                                                            // defaultValue={item.vehicleType}
                                                            render={({ field }) => {
                                                                return <TextField
                                                                    {...field}
                                                                    className="form-text-field"
                                                                    style={{ width: "400px", fontSize: "1.6rem" }}
                                                                    variant="outlined"
                                                                    placeholder='Nhập tổng chi phí'
                                                                    onChange={(e) => {
                                                                        let total = 0;
                                                                        let value = e.target.value;
                                                                        if (value && typeof value === "string" && value.includes(',')) {
                                                                            total = Number(value?.replaceAll(",", ""))
                                                                        }
                                                                        else {
                                                                            total = Number(value);
                                                                        }
                                                                        setValue(`details.${index}.shippingCost`, formatAmount(Number(total)))
                                                                    }}
                                                                />
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        }
                    </div>

                </div>
                <div className="footer">
                    <div className="show">
                        <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => setOpenDeliveryFee({
                                open: false,
                                order: null,
                                isEdit: false
                            })}
                        >
                            {t('CANCEL')}
                        </div>
                        <button
                            type="submit"
                            className="btn-confirm-erp"
                            value={"true"}
                        >
                            Xác nhận và đồng bộ ERP
                        </button>
                        <button
                            type="submit"
                            className="btn-confirm-style"
                            style={{ marginLeft: '4rem' }}
                            value={"false"}
                        >
                            Xác nhận
                        </button>
                    </div>
                </div>
            </form>
            {
                loading && (
                    <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
                        <Loading />
                    </Backdrop>
                )
            }
        </Dialog >
    );
};
// Do react.memo chỉ shallow comparison nên sẽ bị chậm khi re-render
const areEqual = (prevProps: any, nextProps: any) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default React.memo(ModalDeliveryFee, areEqual);