import { REGEX } from "@Constants";
import clsx from "clsx";
import React from "react";

type P_Props = {
  className?: string;
  //disableMinusBtn?: boolean;
  // disablePlusBtn?: boolean;
  dimension: number | string;
  onChangeQuantity: (value: number) => void;
  style?: React.CSSProperties;
  isDisable?: boolean;
};

const Value: React.FC<P_Props> = (props) => {
  const [value, setValue] = React.useState<string>(props.dimension.toString());
  const classes = clsx({
    "quantity-btn-group": true,
    [props?.className || ""]: Boolean(props?.className),
  });

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = REGEX.NUMBER_ONLY;
    if (reg.test(e.target.value) === true) {
      setValue((oldValue) => (oldValue !== e.target.value ? e.target.value : oldValue));
    }
    //setValue(e.target.value);
  };

  React.useEffect(() => {
    props.onChangeQuantity(parseFloat(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    setValue(props.dimension.toString());
  }, [props.dimension]);

  const preventCharacter = (e: any) => {
    const { ctrlKey, key } = e;
    if (/[0-9]|Arrow|Backspace|Delete/.test(key) || (ctrlKey && /^a|c|v|x$/.test(key))) {
      return true;
    }
    e.preventDefault();
    return true;
  };

  return (
    <div className={classes} style={props.style}>
      <input
        className="quantity-btn-group__value"
        disabled={props.isDisable}
        value={value}
        onBlur={(e) => {
          if (!e.target.value || value === "0") setValue("1");
          else if (/[^0-9]/g.test(e.target.value)) setValue(e.target.value.replace(/[^0-9]/g, ""));
        }}
        onKeyDown={preventCharacter}
        // onChange={(e) => {
        //   const reg = REGEX.NUMBER_ONLY;
        //   if (!isNaN(value as any) && reg.test(e.target.value)) {
        //     props.onChangeQuantity(Number(e.target.value));
        //   }
        // }}
        onChange={handleChangeValue}
        style={{ background: "#F7F7F7" }}
      />
    </div>
  );
};

export default Value;
