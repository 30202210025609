import {
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./index.scss";

type Props = {
  data: any;
  count: any;
  onChange: (val: any) => void;
  toUpperCase?: boolean;
};

const CountStatus: React.FC<Props> = (props) => {
  const { data, count, onChange, toUpperCase } = props;
  const { t } = useTranslation(["translation", "order"]);
  const [selectTab, setSelectTab] = useState<string>(toUpperCase ? "ALL" : "all");

  const onChangeTab = (val: any) => {
    setSelectTab(val);
    onChange(val);
  };

  return (
    <div className="count-status">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={15}
        freeMode={false}
        navigation={{
          nextEl: "#tabEventNext",
          prevEl: "#tabEventPrev",
        }}>
        {data.map((o: any) => {
          return (
            <SwiperSlide key={o.id}>
              <div
                className={
                  o.value === selectTab
                    ? "tab tab-active btn--shiny"
                    : `tab ${o?.value?.toLocaleLowerCase()} tab-hover`
                }
                onClick={() => {
                  onChangeTab(o.value);
                }}>
                {t(o.label)}
                <span>{count?.[`${o.value}`]}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <button id="tabEventPrev" className="swiper-button-prev">
        <ArrowLeftSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
      </button>
      <button id="tabEventNext" className="swiper-button-next">
        <ArrowRightSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
      </button>
    </div>
  );
};

export default React.memo(CountStatus);
