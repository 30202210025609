import { TIME } from "@Constants";
import { currencyFormat } from "@Utils/other.util";
import moment from "moment";
import { Locale } from "src/types";

export const formatAmount = (value = 0) => {
  if (!+value) return 0;

  const number = `${value}`;
  const x = number.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

export const toClientDate = (date: string | Date, format = TIME.DAY_MONTH_YEAR) => {
  return moment(date).format(TIME.DAY_MONTH_YEAR);
};

export const toClientDateTime = (date: string | Date, format = TIME.DAY_MONTH_YEAR) => {
  return moment(date).format(`${TIME.DAY_MONTH_YEAR} HH:mm:ss`);
};

export const toServerDate = (date: string | Date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const formatCurrency = (value: number | string, locale: Locale = "vi") => {

  const currency = locale === "vi" ? " đ" : " $";
  if (!value) return `${currencyFormat(0)}${currency}`;

  return `${currencyFormat(Number(value))}${currency}`;
};
