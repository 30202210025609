import { IconBase, IconComponent } from "@Core/Icons";

export const SingleCupIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M17.25 14H18.75V18.5H5.25V14H6.75V17H17.25V14ZM5.25 6.5V11H6.75V8H17.25V11H18.75V6.5H5.25ZM11.25 11.75V13.25H9V14.75L6.75 12.5L9 10.25V11.75H11.25ZM15 11.75V10.25L17.25 12.5L15 14.75V13.25H12.75V11.75H15Z"
        fill="#5A5959"
      />
    </IconBase>
  );
};
