import { Autocomplete } from "@material-ui/lab";
import { InputAdornment, TextField } from "@mui/material";
import { AxiosResponse } from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { loadCallback } from "@Utils";
import { MapIcon } from "../Icons/MapIcon";
import Loading from "../Loading";
import { GFLabel } from "@Components/ui";
import { SourceType } from "@Models";

type TAction = (...K: any[]) => Promise<void | AxiosResponse<any>>;

type Props<T, P extends TAction = any> = {
  customerId: number;
  value?: T | null;
  error?: any;
  variant?: any;
  label?: string;
  required?: boolean;
  getOptionLabel: (opt: T) => string;
  renderOption?: (opt: T) => React.ReactNode;
  filterOption?: (opt: T) => string;
  actionConfig: {
    action: P;
    getAll?: boolean;
    noAutoLoadOnFirst?: boolean;
    pageSize?: number;
    handleBuildActionParams?: (query: {
      page: number;
      limit: number;
      searchValue?: string;
      source?: string;
    }) => Parameters<P>;
  };
  onChange?: (opt: T | null) => void;
  placeholder?: string;
  dropdownBoxMaxHeight?: React.CSSProperties["maxHeight"];
  className?: string;
  onClick?: () => void;
  handleSaveAddress?: (value: string) => void;
  newAddress?: string;
  disabled?: boolean;
  sourceType?: SourceType;
};

type FectchedListState<Item> = {
  data: Item[];
  page: number;
  totalRecords: number;
  loading: boolean;
  limit: number;
  lastPageRequest: number;
};

const EMPTY_LIST_STATE: FectchedListState<any> = {
  data: [],
  page: 1,
  totalRecords: 1,
  loading: false,
  limit: 12,
  lastPageRequest: 0,
};

function SelectInput<T, P extends TAction = any>(props: Props<T, P>): JSX.Element {
  const [cusId, setCusId] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listState, setListState] = useState<FectchedListState<T>>(EMPTY_LIST_STATE);
  const [val, setVal] = useState<string>("");

  const buildActionParams = (query: any) => {
    return [query];
  };

  const handleLoadData = (loadMore: boolean = false) => {

    if (!cusId) {
      setListState(EMPTY_LIST_STATE);
      return;
    }
    
    if (listState.loading) return;
    if (listState.data.length >= listState.totalRecords && loadMore) return;

    const pageSize: number = props.actionConfig.pageSize || 12;
    const currentPage = Math.ceil(listState.data.length / pageSize);
    let page = loadMore ? currentPage + 1 : 1;

    if (page !== 1) {
      if (listState.lastPageRequest === page) {
        return;
      } else if (page - listState.lastPageRequest > 1) {
        page = listState.lastPageRequest ? listState.lastPageRequest + 1 : 2;
      }
    }

    const data = loadMore ? listState.data : [];
    setListState({ ...listState, loading: true, data, page, limit: pageSize });
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  useEffect(() => {
    setCusId(props.customerId);

    // eslint-disable-next-line
  }, [props.customerId]);

  useEffect(() => {
    if (listState.loading) {
      const loadMore = listState.page !== 1;
      const tempQuery = {
        page: listState.page,
        limit: listState.limit,
        customerId: cusId,
        searchValue: searchValue,
        source: props.sourceType ?? "farm",
      };

      const handleBuildActionParams =
        props.actionConfig.handleBuildActionParams || buildActionParams;
      props.actionConfig
        .action(...handleBuildActionParams!(tempQuery))
        .then((res: any) => {
          if (res && (res.status === 200 || res.stats === 201)) {
            let data = res.data.data;

            if (loadMore) {
              data = [...listState.data, ...data];
            }

            let totalRecords: number = res.data?.totalRecords || 1;
            if (!data.length) {
              totalRecords = 1;
            }

            setListState({
              ...listState,
              loading: false,
              data: data,
              limit: data,
              totalRecords,
              lastPageRequest: listState.page,
            });
          } else {
            setListState(EMPTY_LIST_STATE);
          }
        })
        .catch((err) => {
          setListState(EMPTY_LIST_STATE);
        });
    }
    // eslint-disable-next-line
  }, [listState.loading]);

  useEffect(() => {

    handleLoadData(false);
    // eslint-disable-next-line
  }, [cusId, searchValue, val]);

  useEffect(() => {
    if (!props.actionConfig.noAutoLoadOnFirst) {
      handleLoadData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {props.label && <GFLabel required={props.required}>{props.label}</GFLabel>}
      <Autocomplete<T>
        disabled={props.disabled}
        value={props.value}
        onChange={(_, value: any) => {
          if (value?.inputValue) {
            setTimeout(() => {
              (props.handleSaveAddress as Function)(value?.inputValue);
              val !== "" && setSearchValue(val);
            });
          }
          loadCallback(props.onChange, value);
        }}
        noOptionsText={
          !listState.loading && listState.data.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                fontFamily: "SVN-Gotham",
                fontSize: "1.6rem",
              }}>
              <div>Chưa có địa chỉ giao hàng </div>
              <div>Nhập để tạo mới</div>
            </div>
          ) : (
            <Loading />
          )
        }
        fullWidth
        options={listState.data}
        getOptionLabel={(opt) => props.getOptionLabel(opt)}
        renderOption={props.renderOption}
        filterOptions={(options, state) => {
          const filtered: any[] = options;
          if (state.inputValue !== "") {
            filtered.push({
              inputValue: state.inputValue,
              title: `+ Thêm mới địa chỉ "${state.inputValue}"`,
            });
          }
          setVal(state.inputValue);
          return filtered.reverse() || "";
        }}
        forcePopupIcon={false}
        renderInput={(params) => (
          <div className={props.className}>
            <TextField
              {...params}
              onClick={() => {
                loadCallback(props.onClick);
              }}
              placeholder={props.placeholder}
              variant={props.variant ? props.variant : "standard"}
              className="textfield__root"
              error={!!props.error}
              helperText={props.error}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <MapIcon color="#41AD49" size={[22, 22]} viewBox={[10, 20]} />
                  </InputAdornment>
                ),
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                },
              }}
            />
          </div>
        )}
        onInputChange={(e: any, value: any) => {
          // const value = e.target?.value;
          onSearch(value);
        }}
        ListboxProps={{
          onScroll: (e: any) => {
            const ulElement = e?.target;

            if (
              !props.actionConfig.getAll &&
              ulElement &&
              ulElement.scrollTop >= ulElement.scrollHeight - ulElement.offsetHeight
            ) {
              handleLoadData(true);
            }
          },
          style: {
            maxHeight: props.dropdownBoxMaxHeight || "200px",
          },
        }}
        className="form-autocomplete-select-field"
      />
    </>
  );
}

export default SelectInput;
