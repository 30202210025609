import ImageUpload from "@Components/ImageUpload";
import Loading from "@Components/Loading";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
  APP_ROUTES,
  BASE_LINK_IMAGE,
  ConditionQuantity,
  TEXT_HOLDER,
  UpdateHistory,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { FormUploadImageModel, ItemPermission, SourceType, UploadedImageModel } from "@Models";
import { ProductService } from "@Services";
import { PermissionUtil, checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  Grid,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./ProductDetail.scss";
import { useTranslation } from "react-i18next";

const ProductDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "product"]);
  const history = useHistory();
  const [product, setProduct] = useState<any>(null);
  const mounted = useRef(false);
  const { productId }: { productId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  const [recallAPI, setRecallAPI] = useState<boolean>(true);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const getProductById: () => Promise<void> = async () => {
    try {
      const response = await ProductService.getProductById(productId);
      if (mounted.current) {
        setProduct(() => response.data.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getProductById();
    setRecallAPI(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recallAPI === true]);

  const checkSource = product?.source === SourceType.FEED || product?.source === SourceType.VET;

  useEffect(() => {
    setAvatars(
      product?.image
        ? [
            {
              id: "",
              src: `${checkLinkImage(product?.image?.picture)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [product]);

  const uploadAvatar = async () => {
    try {
      if (avatars[0].file) {
        const formData = new FormData();
        formData.append("image", avatars[0].file);
        await ProductService.uploadImage(productId.toString(), formData);
        setRecallAPI(true);
        enqueueSnackbar(t("product:IMAGE_UPDATED_SUCCESSFULLY"), {
          variant: "success",
        });
      }
    } catch (error) {
      // enqueueSnackbar("Cập nhật hình ảnh thất bại!", {
      //   variant: "error",
      // });
      console.log(error);
    }
  };

  // const checkPermissionAddAvt = PermissionUtil.check(
  //   profile?.role?.permissions,
  //   ItemPermission.MANAGE_ITEM_ADD_AVT,
  //   true
  // );

  const checkPermissionImportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_UPDATE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="product-detail">
        <SimpleModalHeader
          title={t("product:PRODUCT_DETAIL")}
          onClose={() => {
            product?.source && history.push(APP_ROUTES.PRODUCT + "-" + product.source);
          }}
        />
        <div className="content">
          {!!product ? (
            <Grid container>
              <Grid item xs={3} className="left">
                <div className="image">
                  {/* {!checkPermissionAddAvt ? ( */}
                  {!checkSource ? (
                    <img
                      src={
                        BASE_LINK_IMAGE + product.image?.picture ||
                        renderImageProNull(product.source)
                      }
                      alt="img"
                      onError={renderErrorImageItem(product.source)}
                    />
                  ) : (
                    <ImageUpload
                      className="product-form-avatar"
                      fileList={avatars}
                      max={1}
                      onChange={(data: UploadedImageModel) => {
                        setAvatars([
                          {
                            id: uuidv4(),
                            src: data.fileStr,
                            alt: data.alt || "",
                            file: data.file,
                          },
                        ]);
                        uploadAvatar();
                      }}
                      onDelete={() => {
                        setAvatars([]);
                      }}
                      config={{ uploadModal: true }}
                    />
                  )}
                </div>
                <div className="title">{product.name}</div>
                {!checkSource && checkPermissionImportExcel && (
                  <div
                    className="btn-confirm-style"
                    onClick={() =>
                      history.push(APP_ROUTES.UPDATE_PRODUCT.replace(":productId", productId))
                    }>
                    {t("UPDATE")}
                  </div>
                )}
              </Grid>
              <Grid item xs={8} className="center">
                <div className="box">
                  <div className="title">{t("GENERAL_INFORMATION")}</div>
                  <Grid container>
                    <Grid item xs={6}>
                      <div>
                        <div className="label">{t("PRODUCT_CODE")}</div>
                        {product.code}
                      </div>
                      {checkSource && (
                        <>
                          <div>
                            <div className="label">{t("product:PRODUCT_CATEGORY_CODE")}</div>
                            {product.division.code}
                          </div>
                          <div>
                            <div className="label">{t("product:GROUP_CODE")}</div>
                            {product.materialGroup}
                          </div>
                        </>
                      )}
                      <div>
                        <div className="label">{t("product:SALES_UNIT")}</div>
                        {product.saleUnit}
                      </div>
                      <div>
                        <div className="label">
                          {checkSource ? t("product:CONVERSION_QUANTITY") : t("WEIGHT_RANGE")}
                        </div>
                        {!checkSource && Array.isArray(product?.dimension)
                          ? product.dimension.map((d: any, idx: number) => {
                              return (
                                <span
                                  style={{ display: "flex", flexDirection: "column" }}
                                  key={idx}>
                                  &#9679;&ensp;
                                  {(() => {
                                    if (d[1] === "null") {
                                      return "trên " + d[0];
                                    } else if (d[0] === "null") {
                                      return "dưới " + d[1];
                                    } else {
                                      return d[0] + " - " + d[1];
                                    }
                                  })()}
                                  &#160;kg
                                </span>
                              );
                            })
                          : product.dimension + " kg" || TEXT_HOLDER}
                      </div>
                      <div>
                        <div className="label">{t("product:NET_WEIGHT")}</div>
                        {product.netWeight}
                      </div>
                      <div>
                        <div className="label">{t("product:GROSS_WEIGHT")}</div>
                        {product.grossWeight}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        <div className="label">{t("product:PRODUCT_NAME")}</div>
                        {product.name}
                      </div>
                      {checkSource && (
                        <>
                          <div>
                            <div className="label">{t("product:PRODUCT_CATEGORY_NAME")}</div>
                            {product.division.description}
                          </div>
                          <div>
                            <div className="label">{t("product:GROUP_NAME")}</div>
                            {product.materialGroupName}
                          </div>
                        </>
                      )}
                      <div>
                        <div className="label">{t("product:BASIC_UNIT_MEASUREMENT")}</div>
                        {product.baseUoM}
                      </div>
                      <div>
                        <div className="label">{t("product:SOURCE")}</div>
                        {product.source}
                      </div>
                      {!checkSource && product.conditionQuantity && (
                        <div>
                          <div className="label">{t("product:ORDER_CONDITION")}</div>
                          {
                            ConditionQuantity.find(
                              (f: any) => product.conditionQuantity?.replace(/\d/g, "") === f.value
                            )?.title
                          }
                          &nbsp;
                          {product.conditionQuantity?.match(/[0-9]+/g)?.[1]
                            ? product.conditionQuantity?.match(/[0-9]+/g)?.[0] +
                              " - " +
                              product.conditionQuantity?.match(/[0-9]+/g)?.[1]
                            : product.conditionQuantity?.match(/[0-9]+/g)?.[0]}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  {/* {checkSource && (
                    <>
                      <div className="label">Hình ảnh </div>
                      <div className="image-list">
                        {product.images?.length > 0 ? (
                          <div>
                            {product.images.map((a: any) => (
                              <img src={BASE_LINK_IMAGE + a.picture} alt="img1" />
                            ))}
                          </div>
                        ) : (
                          <img src={ProductImageFarm} alt="img1" />
                        )}
                      </div>
                    </>
                  )} */}
                </div>
                {product.alterItems?.length > 0 && (
                  <div className="box legal">
                    <div className="title">{t("product:PRODUCT_CODE_ERP")}</div>
                    <Grid container className="label">
                      <Grid item xs={2}>
                        {t("CODE")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("NAME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("FARMS")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("DATABASE")}
                      </Grid>
                    </Grid>
                    {product.alterItems.map((alter: any, index: number) => {
                      return (
                        <Grid container key={index} className="legal-item">
                          <Grid item xs={2}>
                            {alter.code || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={4} style={{ paddingRight: "1rem" }}>
                            {alter.name || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={3}>
                            {alter.site ? alter.site?.code + " - " + alter.site?.name : TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={3}>
                            {alter.site?.legal?.description || TEXT_HOLDER}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </div>
                )}
                <Accordion className="update-history" disabled>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.6rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={4}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("customer:EXECUTOR")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("UPDATE")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={4}>
                            {u.time}
                          </Grid>
                          <Grid item xs={4}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </Grid>
              {/* <Grid item xs={3} style={{ paddingLeft: "2rem" }} className="right">
                <div className="box">
                  <div className="title">Nhà máy </div>
                  {Factory.map((f) => {
                    return <div className="name">{f.name}</div>;
                  })}
                </div>
              </Grid> */}
            </Grid>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "70vh" }} />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default ProductDetail;
