import {
  GFAutoComplete,
  GFFormItem,
  GFModal,
  GFSwitch,
  GFTextArea,
  GFTextField,
} from "@Components/ui";
import { APPROVE_BUSINESS_TYPE } from "@Constants";
import { businessTypeOptions } from "@Containers/ApprovalOrderCancellation/ApprovalOrderCancellation.config";
import { zodResolver } from "@hookform/resolvers/zod";
import { isFunction } from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApprovalStatusPayload, approvalStatusSchema } from "src/schemas/approval-status.schema";
import { ModalDefaultProps, ModalType, SelectOption } from "src/types";

const defaultValues: ApprovalStatusPayload = {
  code: "",
  statusName: "",
  businessType: APPROVE_BUSINESS_TYPE.ORDER_CANCELLATION,
  description: "",
  isActive: true,
};

type Props = ModalDefaultProps & {
  initialValues?: ApprovalStatusPayload;
  modalType?: ModalType;
  loading?: boolean;
};

export default function CreateOrUpdateApprovalStatusForm({
  initialValues = defaultValues,
  open,
  onClose,
  onConfirm,
  modalType,
  loading,
}: Props) {
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm<ApprovalStatusPayload>({
    resolver: zodResolver(approvalStatusSchema),
    defaultValues: modalType === ModalType.Create ? defaultValues : initialValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");

  const onSubmit = (data: ApprovalStatusPayload) => {
    if (isFunction(onConfirm)) {
      onConfirm(data, modalType);
    }
  };

  return (
    <GFModal
      title={modalType === ModalType.Create ? t("approvalOrderCancellation.createApprovalStatus") : t("approvalOrderCancellation.updateApprovalStatus")}
      open={open}
      onClose={onClose}
      width="80rem"
      disabledConfirm={!isValid || !isDirty || loading}
      onConfirm={handleSubmit(onSubmit)}
      loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GFFormItem control={control} name="code">
          <GFTextField label={t("approvalOrderCancellation.code")} required fullWidth disabled={modalType === ModalType.Update} />
        </GFFormItem>
        <GFFormItem control={control} name="statusName">
          <GFTextField label={t("approvalOrderCancellation.status")} required fullWidth />
        </GFFormItem>
        <GFFormItem control={control} name="businessType">
          <GFAutoComplete translation options={businessTypeOptions} label={t("approvalOrderCancellation.businessType")} required />
        </GFFormItem>
        <GFFormItem control={control} name="isActive">
          <GFSwitch label={t("approvalOrderCancellation.active")} size="small" />
        </GFFormItem>
        <GFFormItem control={control} name="description">
          <GFTextArea fullWidth label={t("approvalOrderCancellation.description")} />
        </GFFormItem>
      </form>
    </GFModal>
  );
}
