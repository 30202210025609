import { REQUEST_URL } from "@Constants";
import { RequestUtil } from "@Utils";

export const SalesOfficeService = {
  getAllSalesOfficeFarm: (query: { sources?: any }) => {
    const url = REQUEST_URL.GET_SALES_OFFICE_FARM;

    return RequestUtil.get({ url, query });
  },
};
