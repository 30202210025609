import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { GFDataTableConfigs } from "@Components/ui/GFDataTable/GFDataTable.config";
import GFTooltip from "@Components/ui/GFTooltip";
import GFTypography from "@Components/ui/GFTypography";

type GFDataTableCellProps = {
  cellData: any;
  dataIndex: any;
  record: any;
  rowIndex: number;
  column: GFDataTableColumn;
  onCLick?: (dataIndex: string, record: any) => void;
  onChange?: (record: any, dataIndex: string, newValue: any) => void;
};

export default function GFDataTableCell({
  cellData,
  record,
  dataIndex,
  rowIndex,
  column,
  onCLick,
  onChange,
}: GFDataTableCellProps) {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onCLick && onCLick(dataIndex, record);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        ...(column.autoWidth
          ? { flex: 1 }
          : { width: column.width ?? GFDataTableConfigs.DefaultWidth }),
        minWidth: column.width ?? GFDataTableConfigs.DefaultWidth,
      }}
      className={`gf-datatable__cell ${column.color ?? ""} ${column.align ?? "left"}`}>
      {column.render ? (
        column.render(cellData, record, rowIndex, onChange)
      ) : column.showTooltip ? (
        <GFTooltip title={cellData}>
          <GFTypography fontWeight={600}>{cellData}</GFTypography>
        </GFTooltip>
      ) : (
        <GFTypography fontWeight={600}>{cellData}</GFTypography>
      )}
    </div>
  );
}
