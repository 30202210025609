import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OrderBoarSemenHeaderWrapper = styled(Box)({
  paddingInline: "4.8rem",
  height: "100px",
  borderBottom: "1px solid var(--border-color)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .gf-customer-avatar": {
    width: "auto",
  },
});

export const OrderBoarSemenHeaderSelectedWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "1.6rem",
});
