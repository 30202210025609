import { WarehouseTransferForm } from "src/schemas/warehouse-transfer.schema";

export enum PigTypeCode {
  ALL = "all",
  HEO2 = "HEO2",
  Breeding = "EC00001",
  Fork = "EC00002",
  Culled = "EC00003",
  BoarSemen = "EC00004",
}

export enum Condition {
  ZPR0 = "ZPR0",
  ZPR1 = "ZPR1",
  ZIPV = "ZIPV",
  ZIPG = "ZIPG",
}

export type Filter = {
  label: string;
  code?: string;
  value?: string | number;
};

export type TimePickerValue = {
  hour: string;
  minute: string;
};

export type SelectOption = {
  label: string;
  value: string | number;
};

export type UpdateWarehouseTransferPayload = WarehouseTransferForm & {
  itemGroupTypeId: number;
};

export type ThemeSize = "default" | "small" | "large";

export type GFDataTableFilterType = {
  label: string;
  value: string | number;
  counter?: number;
  color?: string;
};

export type GFDataTableAction = {
  showActionEditButton?: (record: any) => boolean;
  showActionDeleteButton?: (record: any) => boolean;
  onActionEdit?: (id: number, record: any) => void;
  onActionDelete?: (id: number, record: any) => void;
};

export enum ModalType {
  Create = "create",
  Update = "update",
  Delete = "delete",
}

export type ModalDefaultProps = {
  open?: boolean;
  width?: string;
  height?: string;
  onClose?: () => void;
  onConfirm?: (data: any, type?: any, isDraft?: boolean) => void;
};

export type ModalState = {
  open: boolean;
  modalType: any;
  selectedId?: number | string | null;
  initUpdateData?: any;
  loading?: boolean;
};

export type StatusColor = {
  key: string | number,
  color: string
}

export enum DivisionCode {
  BoarSemen = 30,
}

export type Locale = "vi" | "en";

export enum FarmType {
  Internal = "Internal",
  External = "External",
}


export enum OrderDetailAction {
  CampCoordination,
  OrderCombination,
}
