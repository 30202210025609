import { GFButton, GFIconButton, GFTypography } from "@Components/ui";
import OrderDetailExpanded from "@Containers/OrderCombination/OrderDetailExpanded";
import OrderLineItem from "@Containers/OrderCombination/OrderLineItem";
import { HeaderCell, OrderDetailItemWrapper } from "@Containers/OrderCombination/styles";
import { OrderOutputModel } from "@Models";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
type OrderDetailItemProps = {
  order: OrderOutputModel["orders"]["0"];
};

export default function OrderDetailItem({ order }: OrderDetailItemProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion className="gf-order-detail-item" onChange={toggleExpand}>
      <AccordionSummary>
        <OrderDetailItemWrapper>
          <div className="expand-icon">
            <GFButton size="small" variant="contained">
              <GFIconButton variant="contained" size="small">
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </GFIconButton>
              Chi tiết
            </GFButton>
          </div>
          <div className="gf-order-detail-item__main">
            <Grid container spacing={2} className="gf-order-detail-item__header">
              <HeaderCell item xs={6}>
                <GFTypography variant="body2" type="secondary">
                  Sản phẩm
                </GFTypography>
              </HeaderCell>
              <HeaderCell item xs={2} className="right">
                <GFTypography variant="body2" type="secondary">
                  Số lượng
                </GFTypography>
              </HeaderCell>
              {/* <HeaderCell item xs={2} className="right">
                <GFTypography variant="body2" type="secondary">
                  Đơn giá
                </GFTypography>
              </HeaderCell>
              <HeaderCell item xs={2} className="right">
                <GFTypography variant="body2" type="secondary">
                  Thành tiền
                </GFTypography>
              </HeaderCell> */}
              <HeaderCell item xs={4} className="right">
                <GFTypography variant="body2" type="secondary">
                  Trạng thái
                </GFTypography>
              </HeaderCell>
            </Grid>
          </div>
        </OrderDetailItemWrapper>
        <OrderLineItem order={order} />
      </AccordionSummary>
      <AccordionDetails>
        {isEmpty(order.childOrders) && !isEmpty(order.orderlines) && (
          <OrderDetailExpanded order={order} />
        )}

        {!isEmpty(order.childOrders) &&
          order.childOrders.map((childOrder: any) => <OrderLineItem order={childOrder} isChild />)}
      </AccordionDetails>
    </Accordion>
  );
}
