import { GFAutoComplete, GFButton, GFTextArea, GFTextField } from "@Components/ui";
import GFDatePicker from "@Components/ui/GFDatePicker";
import GFFormItem from "@Components/ui/GFFormItem";
import { useAppSelector } from "@Core/store/hook";
import { LoadMe } from "@Core/store/profile";
import { zodResolver } from "@hookform/resolvers/zod";
import { IWareHouseTransferData } from "@Models/warehouse.model";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Grid, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { WarehouseService } from "@Services/warehouse.service";
import { vi } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  warehouseTransferSchema,
  WarehouseTransferForm,
} from "src/schemas/warehouse-transfer.schema";
import { PigTypeCode } from "src/types";
import "./index.scss";
import _Map from "lodash/map";
import _UniqBy from "lodash/uniqBy";
import _Filter from "lodash/filter";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type BreedingTransferProps = {
  open: boolean;
  onClose: () => void;
  onTransferSuccess?: () => void;
};

const opts = [
  {
    label: "test",
    value: 123,
  },
];

export default function BreedingTransfer({
  open,
  onClose,
  onTransferSuccess,
}: BreedingTransferProps) {
  const { data: profile } = useAppSelector(LoadMe);
  const breedingId = profile?.itemGroupTypeFarm?.find((x) => x.code === PigTypeCode.Breeding)?.id;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("translation");
  const [transferData, setTransferData] = useState<IWareHouseTransferData>({
    bu: [],
    employees: [],
    itemAllocations: [],
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm<WarehouseTransferForm>({
    resolver: zodResolver(warehouseTransferSchema),
    mode: "onBlur",
  });

  const selectSourceBu = watch("sourceBuId");
  const selectTargetBu = watch("targetBuId");

  const dynamicOptions = useMemo(() => {
    return {
      fromSaleManager: _UniqBy(
        _Filter(transferData.employees, (x) => x.buId === selectSourceBu),
        "id"
      ),
      fromProductGroup: _UniqBy(
        _Filter(transferData.itemAllocations, (x) => x.buId === selectSourceBu),
        "id"
      ),
      toSaleManager: _UniqBy(
        _Filter(transferData.employees, (x) => x.buId === selectTargetBu),
        "id"
      ),
      toProductGroup: _UniqBy(
        _Filter(transferData.itemAllocations, (x) => x.buId === selectTargetBu),
        "id"
      ),
    };
  }, [selectSourceBu, selectTargetBu]);

  const onSubmit = async (data: WarehouseTransferForm) => {
    try {
      if (!breedingId || loading) return;
      setLoading(true);
      await WarehouseService.updateWarehouseTransfer({
        ...data,
        itemGroupTypeId: breedingId!,
      });
      onClose();

      if (onTransferSuccess) {
        onTransferSuccess();
      }

      enqueueSnackbar("Điều chuyển thành công", {
        variant: "success",
      });
    } catch (error) {
      console.log('error', error)
      enqueueSnackbar(`Điều chuyển thất bại: ${t((error as any)?.response?.data?.message)}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (isDirty) {
      const shouldLeave = window.confirm(
        "Bạn chưa lưu những thay đổi. Bạn có chắc chắn muốn đóng không?"
      );

      if (shouldLeave) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const fetchBuData = async (pigId: number) => {
    try {
      const response: { data: IWareHouseTransferData } =
        await WarehouseService.getWarehouseTransfer(pigId);

      setTransferData({
        bu: _Map(response.data?.bu, (item) => ({ ...item, label: item.name, value: item.id })),
        employees: _Map(response.data?.employees, (item) => ({
          ...item,
          label: item.fullName,
          value: item.id,
        })),
        itemAllocations: _Map(response.data?.itemAllocations, (item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      });
    } catch (error) {
      console.log("🚀 ~ fetchBuData ~ error:", error);
    }
  };

  const handleSourceBuChange = () => {
    setValue("sourceEmployeeId", null as any);
    setValue("sourceItemAllocationId", null as any);
  };

  const handleTargetBuChange = () => {
    setValue("targetEmployeeId", null as any);
    setValue("targetItemAllocationId", null as any);
    getTargetItemAllocationId();
  };

  const getTargetItemAllocationId = () => {
    if (
      !dynamicOptions.toProductGroup ||
      !getValues("sourceItemAllocationId") ||
      !getValues("targetBuId") ||
      !getValues("targetEmployeeId")
    ) {
      setValue("targetItemAllocationId", null as any);
      return;
    }

    const targetItemAllocation = dynamicOptions.toProductGroup.find(
      (f) => f.id === getValues("sourceItemAllocationId")
    )?.id;

    setValue("targetItemAllocationId", targetItemAllocation as any);
  };

  useEffect(() => {
    if (!breedingId) return;

    fetchBuData(breedingId);
  }, [breedingId]);

  return (
    <Modal open={open} className="transfer-modal">
      <form onSubmit={handleSubmit(onSubmit)} className="transfer-modal__content">
        <div className="transfer-modal__header">
          <h3>Điều chuyển heo giống</h3>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </div>
        <div className="transfer-modal__body">
          <h3 className="transfer-modal__subtitle">Thông tin chuyển</h3>
          <div className="transfer-modal__section transfer-item">
            <div className="transfer-item__from transfer-modal__card">
              <h3 className="transfer-modal__subtitle">Nơi chuyển</h3>
              <GFFormItem control={control} name="sourceBuId">
                <GFAutoComplete
                  label="BU"
                  options={transferData.bu}
                  required
                  onChange={handleSourceBuChange}
                />
              </GFFormItem>
              <GFFormItem control={control} name="sourceEmployeeId">
                <GFAutoComplete
                  label="Vùng (Sale Managers)"
                  options={dynamicOptions.fromSaleManager}
                  required
                  onChange={getTargetItemAllocationId}
                />
              </GFFormItem>
              <GFFormItem control={control} name="sourceItemAllocationId">
                <GFAutoComplete
                  label="Nhóm sản phẩm"
                  options={dynamicOptions.fromProductGroup}
                  required
                  onChange={getTargetItemAllocationId}
                />
              </GFFormItem>
            </div>
            <div className="transfer-item__arrow">
              <ArrowRightAltIcon />
            </div>
            <div className="transfer-item__to transfer-modal__card">
              <h3 className="transfer-modal__subtitle">Nơi nhận</h3>
              <GFFormItem control={control} name="targetBuId">
                <GFAutoComplete
                  label="BU"
                  options={transferData.bu}
                  required
                  onChange={handleTargetBuChange}
                />
              </GFFormItem>
              <GFFormItem control={control} name="targetEmployeeId">
                <GFAutoComplete
                  label="Vùng (Sale Managers)"
                  options={dynamicOptions.toSaleManager}
                  required
                  onChange={getTargetItemAllocationId}
                />
              </GFFormItem>
              <GFFormItem control={control} name="targetItemAllocationId">
                <GFAutoComplete
                  label="Nhóm sản phẩm"
                  options={dynamicOptions.toProductGroup}
                  disabled
                  required
                />
              </GFFormItem>
            </div>
          </div>
          <div className="transfer-modal__info">
            <h3 className="transfer-modal__subtitle">Thông tin chi tiết</h3>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <GFFormItem control={control} name="transferDate">
                  <GFDatePicker disablePast={true} label="Ngày chuyển đi" locale={vi} required />
                </GFFormItem>
              </Grid>
              <Grid item xs={4}>
                <GFFormItem control={control} name="receivedDate">
                  <GFDatePicker disablePast={true} label="Ngày chuyển đến" locale={vi} required />
                </GFFormItem>
              </Grid>
              <Grid item xs={4}>
                <GFFormItem control={control} name="transferNum">
                  <GFTextField label="Số lượng chuyển" type="number" fullWidth required />
                </GFFormItem>
              </Grid>
              <Grid item xs={12}>
                <GFFormItem control={control} name="note">
                  <GFTextArea label="Ghi chú" fullWidth rows={2} />
                </GFFormItem>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="transfer-modal__footer">
          <GFButton onClick={handleClose} size="small" variant="outlined">
            Huỷ
          </GFButton>
          <GFButton variant="contained" type="submit" size="small">
            {loading && <CircularProgress size={18} />}
            Xác nhận
          </GFButton>
        </div>
      </form>
    </Modal>
  );
}
