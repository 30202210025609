import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { Month, ReportRevenueHeader, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { Action, FetchAction, ItemPermission, ReportPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { ExportService, ReportService } from "@Services";
import { PermissionUtil, YearList, currencyFormat } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Close as CloseIcon,
  KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<any>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const ReportRevenue: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const checkExportRef = useRef<any>(null);
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number>(0);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [buFarmFilter, setBuFarmFilter] = useState<number[]>([]);
  const [areaFilter, setAreaFilter] = useState<number[]>([]);
  const [siteFilter, setSiteFilter] = useState<number[]>([]);
  const [submitFilter, setSubmitFilter] = useState<{
    area: number[];
    bu: number[];
    site: number[];
  }>({
    area: [],
    bu: [],
    site: [],
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filter, setFilter] = useState<HTMLButtonElement | null>(null);

  const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilter(null);
  };

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : !expanded);
  };

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await ReportService.getReportAllRevenue(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        source: "farm",
        period: time,
        searchString: searchValue,
        areaIds: submitFilter.area?.length > 0 ? submitFilter.area : undefined,
        buIds: submitFilter.bu?.length > 0 ? submitFilter.bu : undefined,
        siteIds: submitFilter.site?.length > 0 ? submitFilter.site : undefined,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, time, submitFilter]);

  const hasMore = state.data?.length < state.totalRecords && state.page < state.totalPages;

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [notiExport, setNotiExport] = useState<boolean>(false);

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "report_revenue",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, time, state.loading, success?.isExport === !isExport]);

  const exportDebt = async () => {
    ExportService.getExportReportRevenue({
      period: time,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [ReportPermission.MANAGE_REPORT_REVENUE_EXPORT],
    true
  );

  return (
    <div className="report-revenue">
      <div className="report-revenue-content">
        <div className="action">
          <TextField
            placeholder="Nhập mã, tên khách hàng..."
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          <button onClick={handleClick} className="btn-white">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
              &emsp;Tháng {month}/{year}&ensp;
            </div>
            <ArrowDropDownIcon />
          </button>
          <button className="btn-white" onClick={handleClickFilter}>
            <TuneRoundedIcon fontSize="large" style={{ marginRight: "1rem" }} />
            Chọn lọc
          </button>
          {!isExport && checkPermissionExportExcel && (
            <button
              className="btn-excel"
              onClick={() => {
                exportDebt();
                setNotiExport(true);
                setSuccess(undefined);
              }}>
              <img src={Excel} alt="icon-excel" />
              {t("EXPORT_DATA")}
            </button>
          )}
        </div>

        <div className="list">
          <div className="list-group">
            <div className="list-header">
              {ReportRevenueHeader.map((h: any, idx: number) => {
                return (
                  <div key={idx} className="label">
                    {h.label}
                  </div>
                );
              })}
            </div>
            {state.loading || (state.fetching && (searchValue || submitFilter)) ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : state.data ? (
              state.data?.length > 0 ? (
                <>
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        page: state.page + 1,
                        limit: 50,
                        source: "farm",
                        period: time,
                        searchString: searchValue,
                        areaIds: submitFilter.area?.length > 0 ? submitFilter.area : undefined,
                        buIds: submitFilter.bu?.length > 0 ? submitFilter.bu : undefined,
                        siteIds: submitFilter.site?.length > 0 ? submitFilter.site : undefined,
                      })
                    }>
                    {state.data.map((r: any, idx: number) => {
                      return (
                        <Accordion
                          key={idx}
                          defaultExpanded={true}
                          // expanded={expanded === r.id}
                          onChange={handleChange(r.id)}
                          style={{ fontFamily: "SVN-Gotham", marginTop: "0.8rem" }}>
                          <AccordionSummary
                            style={{ fontFamily: "SVN-Gotham", padding: 0 }}
                            expandIcon={
                              <KeyboardArrowDownRoundedIcon
                                fontSize="large"
                                sx={{ color: "#41AD49" }}
                              />
                            }>
                            <div className="item">
                              <div className="label name">{r.name || TEXT_HOLDER}</div>
                              <div className="label"></div>
                              <div className="label">
                                {currencyFormat(+r.qtySO) || TEXT_HOLDER}&nbsp;con
                              </div>
                              <div className="label">
                                {currencyFormat(+r.qty) || TEXT_HOLDER}&nbsp;kg
                              </div>
                              <div className="label">
                                {r.qty && r.qtySO ? currencyFormat(+r.qty / +r.qtySO) : TEXT_HOLDER}
                                &nbsp;kg
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? r.preRevenue && r.qty
                                    ? currencyFormat(+r.preRevenue / +r.qty)
                                    : TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &nbsp;đ
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? currencyFormat(+r.preRevenue) || TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &nbsp;đ
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? currencyFormat(+r.curyTransportFee) || TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &nbsp;đ
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? currencyFormat(+r.tranAmt) || TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &nbsp;đ
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails style={{ fontFamily: "SVN-Gotham" }}>
                            {r.revenueTypes?.map((re: any, idx: number) => {
                              return (
                                <div className="item reList" key={idx}>
                                  <div className="label"></div>
                                  <div className="label">{re.name || TEXT_HOLDER}</div>
                                  <div className="label">
                                    {currencyFormat(+re.qtySO) || TEXT_HOLDER}&nbsp;con
                                  </div>
                                  <div className="label">
                                    {currencyFormat(+re.qty) || TEXT_HOLDER}&nbsp;kg
                                  </div>
                                  <div className="label">
                                    {re.qty && re.qtySO
                                      ? currencyFormat(+re.qty / +re.qtySO)
                                      : TEXT_HOLDER}
                                    &nbsp;kg
                                  </div>
                                  <div className="label">
                                    {checkPermissionViewPrice
                                      ? re.preRevenue && re.qty
                                        ? currencyFormat(+re.preRevenue / +re.qty)
                                        : TEXT_HOLDER
                                      : TEXT_ASTERISK}
                                    &nbsp;đ
                                  </div>
                                  <div className="label">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(+re.preRevenue) || TEXT_HOLDER
                                      : TEXT_ASTERISK}
                                    &nbsp;đ
                                  </div>
                                  <div className="label">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(+re.curyTransportFee) || TEXT_HOLDER
                                      : TEXT_ASTERISK}
                                    &nbsp;đ
                                  </div>
                                  <div className="label">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(+re.tranAmt) || TEXT_HOLDER
                                      : TEXT_ASTERISK}
                                    &nbsp;đ
                                  </div>
                                </div>
                              );
                            })}
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </InfiniteScroll>
                  <div className="item item-total">
                    <div className="label">Tổng doanh thu</div>
                    <div className="label"></div>
                    <div className="label">
                      {currencyFormat(+state.total.qtySO) || TEXT_HOLDER}&nbsp;con
                    </div>
                    <div className="label">
                      {currencyFormat(+state.total.qty) || TEXT_HOLDER}&nbsp;kg
                    </div>
                    <div className="label">
                      {state.total.qty && state.total.qtySO
                        ? currencyFormat(+state.total.qty / +state.total.qtySO)
                        : TEXT_HOLDER}
                      &nbsp;kg
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice
                        ? +state.total.preRevenue && state.total.qty
                          ? currencyFormat(+state.total.preRevenue / +state.total.qty)
                          : TEXT_HOLDER
                        : TEXT_ASTERISK}
                      &nbsp;đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice
                        ? currencyFormat(+state.total.preRevenue) || TEXT_HOLDER
                        : TEXT_ASTERISK}
                      &nbsp;đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice
                        ? currencyFormat(+state.total.curyTransportFee) || TEXT_HOLDER
                        : TEXT_ASTERISK}
                      &nbsp;đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice
                        ? currencyFormat(+state.total.tranAmt) || TEXT_HOLDER
                        : TEXT_ASTERISK}
                      &nbsp;đ
                    </div>
                  </div>
                </>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </div>
        </div>
      </div>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <Popover
        open={Boolean(filter)}
        anchorEl={filter}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-filter-report-revenue">
          <div>
            <div className="title">
              Lọc
              <CloseIcon
                className="close-icon"
                onClick={() => {
                  handleCloseFilter();
                  setAreaFilter(submitFilter.area);
                  setBuFarmFilter(submitFilter.bu);
                  setSiteFilter(submitFilter.site);
                }}
              />
            </div>
            <div className="label">Miền</div>
            <FormControlLabel
              label={t("ALL")}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: 18,
                  fontWeight:
                    areaFilter?.length > 0 && areaFilter?.length === state?.areas?.length
                      ? 700
                      : 400,
                },
              }}
              control={
                <Checkbox
                  checked={areaFilter?.length === state?.areas?.length}
                  onChange={() => {
                    areaFilter?.length === state?.areas?.length
                      ? setAreaFilter([])
                      : setAreaFilter(
                          state?.areas?.map((item: any) => {
                            return item.id;
                          })
                        );
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                      color: "#41AD49",
                    },
                  }}
                />
              }
            />
            {state?.areas?.map((a: any, idx: number) => {
              return (
                <FormControlLabel
                  key={idx}
                  label={a.name || TEXT_HOLDER}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 18,
                      fontWeight: areaFilter?.includes(a.id) ? 700 : 400,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={areaFilter?.includes(a.id)}
                      value={a.id}
                      onChange={() => {
                        areaFilter?.includes(a.id)
                          ? setAreaFilter(
                              areaFilter
                                .filter((e) => e !== a.id)
                                .map((item: any) => {
                                  return item;
                                })
                            )
                          : setAreaFilter([...areaFilter, a.id]);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          color: "#41AD49",
                        },
                      }}
                    />
                  }
                />
              );
            })}
            <div className="label">BU</div>
            <FormControlLabel
              label={t("ALL")}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: 18,
                  fontWeight:
                    buFarmFilter?.length > 0 && buFarmFilter?.length === state?.bu?.length
                      ? 700
                      : 400,
                },
              }}
              control={
                <Checkbox
                  checked={buFarmFilter?.length === state?.bu?.length}
                  onChange={() => {
                    buFarmFilter?.length === state?.bu?.length
                      ? setBuFarmFilter([])
                      : setBuFarmFilter(
                          state?.bu?.map((item: any) => {
                            return item.id;
                          })
                        );
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                      color: "#41AD49",
                    },
                  }}
                />
              }
            />
            {state?.bu?.map((bu: any, idx: number) => {
              return (
                <FormControlLabel
                  key={idx}
                  label={bu.name || TEXT_HOLDER}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 18,
                      fontWeight: buFarmFilter.includes(bu.id) ? 700 : 400,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={buFarmFilter.includes(bu.id)}
                      value={bu.id}
                      onChange={() => {
                        buFarmFilter.includes(bu.id)
                          ? setBuFarmFilter(
                              buFarmFilter
                                .filter((e) => e !== bu.id)
                                .map((item: any) => {
                                  return item;
                                })
                            )
                          : setBuFarmFilter([...buFarmFilter, bu.id]);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          color: "#41AD49",
                        },
                      }}
                    />
                  }
                />
              );
            })}
            <div className="label">TRẠI</div>
            <FormControlLabel
              label={t("ALL")}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: 18,
                  fontWeight:
                    siteFilter?.length > 0 && siteFilter?.length === state?.sites?.length
                      ? 700
                      : 400,
                },
              }}
              control={
                <Checkbox
                  checked={siteFilter?.length === state?.sites?.length}
                  onChange={() => {
                    siteFilter?.length === state?.sites?.length
                      ? setSiteFilter([])
                      : setSiteFilter(
                          state?.sites?.map((item: any) => {
                            return item.id;
                          })
                        );
                  }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                      color: "#41AD49",
                    },
                  }}
                />
              }
            />
            {state?.sites?.map((s: any, idx: number) => {
              return (
                <FormControlLabel
                  key={idx}
                  label={s.name || TEXT_HOLDER}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 18,
                      fontWeight: siteFilter.includes(s.id) ? 700 : 400,
                    },
                  }}
                  control={
                    <Checkbox
                      checked={siteFilter.includes(s.id)}
                      value={s.id}
                      onChange={() => {
                        siteFilter.includes(s.id)
                          ? setSiteFilter(
                              siteFilter
                                .filter((e) => e !== s.id)
                                .map((item: any) => {
                                  return item;
                                })
                            )
                          : setSiteFilter([...siteFilter, s.id]);
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                          color: "#41AD49",
                        },
                      }}
                    />
                  }
                />
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5rem" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                handleCloseFilter();
                setSubmitFilter({ area: [], bu: [], site: [] });
                setAreaFilter([]);
                setBuFarmFilter([]);
                setSiteFilter([]);
              }}>
              Xóa lọc
            </button>
            <button
              className="btn-confirm-style"
              style={{ width: "17rem" }}
              onClick={() => {
                handleCloseFilter();
                setSubmitFilter({ area: areaFilter, bu: buFarmFilter, site: siteFilter });
              }}>
              Áp dụng
            </button>
          </div>
        </div>
      </Popover>

      <NotiExportExcel
        notiExport={notiExport}
        title="Báo cáo doanh số"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default ReportRevenue;
