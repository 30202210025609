import { GFBackdrop } from "@Components/ui/GFCommon";
import {
  GFSearchWithInfiniteScrollInput,
  GFSearchWithInfiniteScrollItem,
  GFSearchWithInfiniteScrollLoading,
  GFSearchWithInfiniteScrollNoData,
  GFSearchWithInfiniteScrollPopover,
} from "@Components/ui/GFSearchWithInfiniteScroll/styles";
import GFTextField from "@Components/ui/GFTextField";
import GFTypography from "@Components/ui/GFTypography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, CircularProgress } from "@mui/material";
import _, { isFunction, isNil } from "lodash";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

type GFSearchWithInfiniteScrollProps<T = any> = {
  data?: T[];
  loading?: boolean;
  fetchingNextPage?: boolean;
  hasMore?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  selectedKey?: string;
  width?: string;
  popoverHeight?: string;
  renderItem: (item: T) => React.ReactNode;
  onGetNextPage?: () => void;
  onClickItem?: (item: T) => void;
  onSearchChange?: (value: string) => void;
};

export default function GFSearchWithInfiniteScroll<T = any>({
  data = [],
  loading = false,
  fetchingNextPage = false,
  hasMore = false,
  searchPlaceholder = "Tìm kiếm",
  searchValue,
  selectedKey = "id",
  width = "100%",
  popoverHeight = "25rem",
  renderItem,
  onGetNextPage,
  onClickItem,
  onSearchChange,
}: GFSearchWithInfiniteScrollProps<T>) {
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const searchDebounced = useCallback(
    _.debounce((value: string) => {
      if (isFunction(onSearchChange)) {
        onSearchChange(value);
      }
    }, 500),
    [onSearchChange]
  );

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);

    searchDebounced.cancel();

    searchDebounced(e.target.value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickOutside = (e: any) => {
    handleCloseModal();
  };

  const handleGetNextPage = () => {
    if (loading || fetchingNextPage) return;

    if (isFunction(onGetNextPage)) {
      onGetNextPage();
    }
  };

  const handleItemClick = (item: T) => {
    if (isFunction(onClickItem)) {
      onClickItem(item);
    }
    if (isFunction(onSearchChange)) {
      onSearchChange("");
    }
    setSearch("");
    handleCloseModal();
  };

  useEffect(() => {
    if (!isNil(searchValue) && searchValue !== search) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  return (
    <>
      {openModal && <GFBackdrop onClick={handleClickOutside} />}
      <GFSearchWithInfiniteScrollInput width={width}>
        <GFTextField
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <PersonAddIcon />,
          }}
          placeholder={searchPlaceholder}
          fullWidth
          onFocus={handleOpenModal}
          className="gf-search-with-infinite-scroll__input"
          onClick={handleOpenModal}
        />

        {openModal && (
          <GFSearchWithInfiniteScrollPopover maxHeight={popoverHeight}>
            <InfiniteScroll
              loadMore={handleGetNextPage}
              hasMore={hasMore}
              loader={
                <GFSearchWithInfiniteScrollLoading key={0}>
                  <CircularProgress size={18} />
                </GFSearchWithInfiniteScrollLoading>
              }
              useWindow={false}
              initialLoad={false}>
              {data.length > 0 ? (
                data.map((item, idx) => (
                  <GFSearchWithInfiniteScrollItem
                    key={(item as any)?.id ?? idx}
                    onClick={() => handleItemClick(item)}>
                    {renderItem(item)}
                  </GFSearchWithInfiniteScrollItem>
                ))
              ) : loading ? (
                <GFSearchWithInfiniteScrollLoading key={0}>
                  <CircularProgress size={18} />
                </GFSearchWithInfiniteScrollLoading>
              ) : (
                <GFSearchWithInfiniteScrollNoData fontWeight={600} color="var(--primary-green)">
                  Không có dữ liệu
                </GFSearchWithInfiniteScrollNoData>
              )}
            </InfiniteScroll>
          </GFSearchWithInfiniteScrollPopover>
        )}
      </GFSearchWithInfiniteScrollInput>
    </>
  );
}
