import { IVehicleInfo } from "@Models/veihicle.model";
import React from "react";

interface VehicleInfo {
    data: IVehicleInfo
}

export default function VehicleInfo({ data }: VehicleInfo) {
    console.log(data);
    return (
        <>
            <div className="ship" style={{ background: "#f7f7f7" }}>
                <div className="detail"></div>
                <div className="name">
                    <div className="label">Loại xe</div>
                    {data.vehicleType}
                </div>
                <div className="price">
                    <div className="label">Biển số xe</div>
                    {data.vehicleInfo}
                </div>
                <div className="unit">
                    <div className="label">Tên tài xế</div>
                    {data.nameOfDriver}
                </div>

            </div>
            <div className="ship" style={{ background: "#f7f7f7" }}>
                <div className="detail"></div>
                <div className="name">
                    <div className="label">Số điện thoại</div>
                    {data.phoneNumberOfDriver}
                </div>
                <div className="price">
                    <div className="label">Nhân viên áp tải</div>
                    {data.escortStaff}
                </div>
                <div className="unit">
                    <div className="label">Mã chuyến xe</div>
                    {data.shippingCode}
                </div>
            </div>
        </>

    )
}