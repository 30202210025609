/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  PersonAddAlt1 as AddCustomerIcon,
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  Clear as ClearIcon,
  ContentCopy,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
//import { OpenInNewIcon } from "@Components/Icons/OpenInNewIcon";
import urlSound from "@Assets/audio/gf-noti.mp3";
import NewOrder from "@Assets/images/new-order.png";
import ProNull from "@Assets/images/product/nopro.png";
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import NotAccess from "@Components/NotAccess";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, REGEX, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  Action,
  CustomerGroupOutputModel,
  CustomerOutputModel,
  FetchAction,
  ItemGroupOutputModel,
  ItemOutputModel,
  ItemPermission,
  OrderDetailOutputModel,
  OrderInputModel,
  OrderStatus,
  ProductOutputModel,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import {
  CustomerService,
  ObjectiveService,
  OrderService,
  ProductService,
  TargetService,
} from "@Services";
import {
  FormUtil,
  PermissionUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./OrderLabModal.scss";
import { FarmdIcon } from "@Components/Icons/FarmIcon";
import { DEFAULT_COLOR } from "@Containers/Profile";
import { useSnackbar } from "notistack";

SwiperCore.use([Navigation]);

type OrderProduct = OrderDetailOutputModel & ProductOutputModel;

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const OrderLabModal: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const history = useHistory();
  const submitRef = useRef<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleValue] = useState<boolean>(false);
  const [visibleHeader, setVisibleHeader] = useState<boolean>(false);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState<boolean>(false);
  const [dataCustomer, setDataCustomer] = useState<CustomerOutputModel | null>(null);
  const [campID, setCampID] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [selectedVars, setSelectedVars] = useState<{ item: OrderProduct; quantity: number }[]>([]);
  //luu tam cho mang selectVars
  const [tempSelectedVars, setTempSelectedVars] = useState<
    { item: OrderProduct; quantity: number }[]
  >([]);
  const [permission, setPermission] = useState<boolean>(false);
  const mounted = useRef(false);
  //index mang selectVars khi add tac nhan
  const [dialogID, setDialogID] = useState<string | number | null>(null);
  //target
  const [dataTarget, setDataTarget] = useState<CustomerGroupOutputModel[]>([]);
  const [targetId, setTargetId] = useState<number>(-1);
  //objective
  const [dataObjective, setDataObjective] = useState<CustomerGroupOutputModel[]>([]);
  const [objectiveId, setObjectiveId] = useState<number>(-1);

  //request type
  const [dataRequestType, setDataRequestType] = useState<CustomerGroupOutputModel[]>([]); //xai tam model
  const [requestTypeId, setRequestTypeId] = useState<number | null>(null);

  /*Dat lai don hang*/
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();

  const [itemById, setItemById] = useState<any>([]);
  const [orderDetail, setOrderDetail] = useState<any>([]);
  const [ordDetailValue, setOrdDetailValue] = useState<any>([]);
  const [valid, setValid] = useState<boolean>(false);

  const [itemGroupType, setItemGroupType] = useState<ItemGroupOutputModel | null>(null);
  const [dataOrd, setDataOrd] = useState<any>({});
  const [dataPathogen, setDataPathogen] = useState<ItemOutputModel[]>([]);
  const [dataSampleTypeGroup, setDataSampleTypeGroup] = useState<ItemGroupOutputModel[]>([]);

  const [states, dispatchs] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(+orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    orderId && orderById();
  }, []);

  const listItem = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      return {
        itemId: d.item.id,
      };
    });
  });

  const loadListItemById = async () => {
    const response = await ProductService.getItemById({
      source: SourceType.LAB,
      itemIds: `${[].concat(...listItem).map((a: any) => {
        return a.itemId;
      })}`,
      customerId: order?.orders[0]?.customer?.id,
    });
    setItemById(response.data);
  };

  useEffect(() => {
    orderId && order && orderDetail && listItem && loadListItemById();
  }, [orderDetail]);

  const divisionIds = Array.from(
    new Set(
      selectedVars
        .map((i) => {
          return i.item.item?.division?.id;
        })
        .concat(
          orderDetail.map((d: any) => {
            return d.divisionId;
          })
        )
    )
  );

  const onFetchApiTarget: () => Promise<void> = async () => {
    try {
      const response = await TargetService.getAllTarget();
      if (mounted.current) {
        setDataTarget(() => response.data);
      }
    } catch (error) {}
  };

  const onFetchApiObjective: () => Promise<void> = async () => {
    try {
      const response = await ObjectiveService.getAllObjective();
      if (mounted.current) {
        setDataObjective(() => response.data);
      }
    } catch (error) {}
  };

  const onFetchApiRequestType: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getRequestType();
      if (mounted.current) {
        setDataRequestType(() => response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (dataCustomer?.id || order?.orders[0].customer?.id) {
      onFetchApiTarget();
      onFetchApiObjective();
      onFetchApiRequestType();
    }
  }, [dataCustomer?.id, order?.orders[0].customer?.id, selectedVars, order, !!divisionIds.length]);

  const ordDetail = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      const checkItem = itemById.some((f: any) => f.item.id === d.item.id);
      const checkTus = o.status === OrderStatus.PROCESSING ? +d.expectQuantity : +d.actualQuantity;
      const ordQuantity = checkTus !== 0 ? checkTus : +d.orderQuantity !== 0 ? +d.orderQuantity : 1;
      return {
        itemId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item?.id
          : d.item?.id,
        divisionId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.division?.id
          : o.division?.id,
        discount: 0,
        quantity: ordQuantity,
        unitPrice: checkItem
          ? +itemById.find((f: any) => f.item.id === d.item.id)?.value
          : +d.price,
        attachValue: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.additionValue
          : d.additionValue,
        saleUnit: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.baseUoM
          : d.saleUnit,
        baseUoM: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.baseUoM
          : d.baseUoM,
        dimension: checkItem
          ? +itemById.find((f: any) => f.item.id === d.item.id)?.item.dimension * ordQuantity
          : +d.dimension * ordQuantity,
        subTotal: checkItem
          ? ordQuantity * +itemById.find((f: any) => f.item.id === d.item.id)?.value
          : ordQuantity * d.price,
        available: checkItem ? true : false,
      };
    });
  });

  useEffect(() => {
    order && setOrderDetail([].concat(...ordDetail));
    setOrdDetailValue(
      order?.orders.map((o: any) => {
        return o.orderlines.map((d: any) => {
          return d;
        });
      })
    );
  }, [order]);

  useEffect(() => {
    order && itemById && setOrderDetail([].concat(...ordDetail));
  }, [itemById.length > 0]);

  const onUpdateProdVarQuantityDetail = (
    variantId: number,
    quantity: number,
    dimension?: number
  ) => {
    const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
    let data = [].concat(...orderDetail) as any;

    // update
    if (quantity === -1) {
      data.splice(idx, 1);
      ordDetailValue.splice(idx, 1);
      setOrdDetailValue(ordDetailValue);
    } else {
      data[idx] = {
        ...data[idx],
        quantity: quantity,
        dimension: dimension,
      };
    }
    // data = data.filter((item: any) => item.quantity >= 1);
    setOrderDetail(data);
  };

  const totalDetail = orderDetail
    .filter((item: any) => item.available === true)
    .reduce((sum: number, item: any) => {
      sum += +item.unitPrice * item.quantity;
      return sum;
    }, 0);

  /*------------------------------------- */

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchs({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.getProductByAdmin(query);
      if (isReset) {
        dispatchs({
          type: Action.RESET,
          payload: result.data,
        });
        if (!itemGroupType) {
          setItemGroupType(result.data?.itemGroups[0]);
        }
      } else if (mounted.current) {
        dispatchs({
          type: Action.SUCCESS,
          payload: result.data,
        });
        if (!itemGroupType) {
          setItemGroupType(result.data?.itemGroups[0]);
        }
      }
    } catch (err) {
      if (mounted) {
        dispatchs({
          type: Action.FAILED,
          payload: null,
        });
        setItemGroupType(null);
      }
    }
  };

  useEffect(() => {
    if ((dataCustomer !== undefined || ordDetail) && targetId > -1 && objectiveId > -1) {
      fetchData(
        {
          page: 1,
          limit: 20,
          source: SourceType.LAB,
          searchValue: searchValue,
          customerId: ordDetail ? order?.orders[0]?.customer?.id : dataCustomer?.id,
          itemGroupId: itemGroupType ? itemGroupType?.id : undefined,
          targetId: targetId,
          objectiveId: objectiveId,
        },
        true
      );
    }
  }, [
    searchValue,
    dataCustomer,
    itemGroupType,
    order,
    order?.orders[0]?.customer?.id,
    targetId,
    objectiveId,
  ]);

  useEffect(() => {
    if (!!states.itemGroups?.length) {
      const itemGroup: ItemGroupOutputModel[] = states.itemGroups;
      setDataSampleTypeGroup(itemGroup);
    }
  }, [states.itemGroups]);

  const hasMore = states.data?.length < states.totalRecords && states.page < states.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const onUpdateProdVarQuantity = (
    variantId: number,
    quantity: number,
    addedProduct: OrderProduct,
    addNew: boolean = false,
    index: number
  ) => {
    if (!dialogID) {
      const actOrd = [].concat(...orderDetail).find((item: any) => item.itemId === variantId);

      let data = [...tempSelectedVars];
      // thêm mới
      if (!addedProduct) {
        return;
      }
      if (actOrd) {
        return;
      }
      const checkDataItem =
        !!data.length && data.findIndex((i) => i.item?.id === addedProduct?.id) > -1;
      if (checkDataItem) {
        data = data.filter((i) => i.item?.id !== addedProduct?.id);
        setTempSelectedVars(data);
        return;
      } else {
        const addPathogen = {
          ...addedProduct,
          itemPathogen: [addedProduct.item],
          itemGroupType: itemGroupType,
          sample: undefined,
        };
        data.push({
          item: addPathogen,
          quantity,
        });
      }
      data = data.filter((item) => item.quantity >= 1);
      setTempSelectedVars(data);
    } else if (addedProduct) {
      const checkDataPathogen =
        !!dataPathogen.length && dataPathogen.findIndex((i) => i.id === addedProduct.item?.id) > -1;
      if (checkDataPathogen) {
        const newItem = dataPathogen.filter((i) => i.id !== addedProduct.item.id);
        setDataPathogen(newItem);
      } else {
        const newItem = dataPathogen.concat([{ ...addedProduct?.item, value: addedProduct.value }]);
        setDataPathogen(newItem);
      }
    }
  };

  const onAddDataSelect = () => {
    const combinedItems = tempSelectedVars.reduce((acc: ItemOutputModel[], current) => {
      const updatedItems: ItemOutputModel[] = !!current?.item?.itemPathogen?.length
        ? current?.item?.itemPathogen.map((item) => ({
            ...item,
            value: current?.item?.value,
          }))
        : [];

      return acc.concat(updatedItems);
    }, []);

    const newData = [
      {
        item: {
          ...tempSelectedVars[0]?.item,
          itemPathogen: combinedItems,
          itemGroupType: itemGroupType,
        },
        quantity: tempSelectedVars[0].quantity,
      },
    ];

    setSelectedVars([...selectedVars, ...newData]);
    setTempSelectedVars([]);
  };

  const onUpdateDataSelect = (index: number, quantity: number, sampleId?: number) => {
    if (!dialogID) {
      if (!sampleId) {
        let data = [...selectedVars];
        data[index] = {
          ...data[index],
          quantity: quantity || 1,
        };
        data = data.filter((item) => item.quantity >= 1);
        setSelectedVars(data);
      } else {
        let data = [...selectedVars];
        data[index] = {
          ...data[index],
          item: {
            ...data[index].item,
            sampleId: sampleId,
          },
        };
        data = data.filter((item) => item.quantity >= 1);
        setSelectedVars(data);
      }
    }
  };

  const onDuplicatePro = (index: number) => {
    if (!dialogID) {
      const dataDuplicate = [...selectedVars];
      dataDuplicate.splice(index, 0, dataDuplicate[index]);
      setSelectedVars(dataDuplicate);
      setTempSelectedVars([]);
    }
  };

  const onRemoveProdPathogen = (dataItem: ItemOutputModel, index: number) => {
    const dataSelect = [...selectedVars];
    dataSelect[index] = {
      ...dataSelect[index],
      item: {
        ...dataSelect[index].item,
        itemPathogen: dataSelect[index]?.item?.itemPathogen?.filter((e) => e.id !== dataItem.id),
      },
    };
    setSelectedVars(dataSelect);
  };

  const onAddProPathogen = () => {
    if (!!dialogID) {
      const dataSelect = [...selectedVars];
      dataSelect[(dialogID as number) - 1] = {
        ...dataSelect[(dialogID as number) - 1],
        item: {
          ...dataSelect[(dialogID as number) - 1].item,
          itemPathogen: dataPathogen,
        },
      };
      setSelectedVars(dataSelect);
      setDataPathogen([]);
      setDialogID(null);
    }
  };

  const validProducts = selectedVars.filter((item) => item.quantity);

  /** Điều kiện check trùng: cùng tác nhân, phương pháp,loại mẫu */
  const findDataDuplicate = (array: { item: OrderProduct; quantity: number }[]) => {
    return array.filter((item1, index1) => {
      return array.some((item2, index2) => {
        if (index1 !== index2) {
          return (
            _.isEqual(
              item1.item?.itemPathogen?.map((i) => i.id),
              item2.item?.itemPathogen?.map((i) => i.id)
            ) &&
            _.isEqual(item1.item?.itemGroupType?.id, item2.item?.itemGroupType?.id) &&
            _.isEqual(item1.item?.sampleId, item2.item.sampleId)
          );
        }
        return false;
      });
    });
  };

  const submitForm = (values: any) => {
    setLoading(true);
    const { note } = values;

    if (!validProducts.length) {
      setLoading(false);
    }
    if (selectedVars.findIndex((i) => !i.item.sampleId) > -1) {
      setLoading(false);
      setValid(true);
      enqueueSnackbar(t("SAMPLE_TYPE_NOT_SELECTED"), {
        variant: "error",
      });
    } else if (!requestTypeId) {
      setValid(true);
      setLoading(false);
      enqueueSnackbar(t("REQUEST_TYPE_NOT_SELECTED"), {
        variant: "error",
      });
    } else if (!Boolean(values.totalSample)) {
      setValid(true);
      setLoading(false);
      enqueueSnackbar(t("TOTAL_SAMPLES_NOT_ENTERED"), {
        variant: "error",
      });
    } else if (!Boolean(campID)) {
      setValid(true);
      setLoading(false);
      enqueueSnackbar(t("FARM_NOT_SELECTED"), {
        variant: "error",
      });
    } else if (!!findDataDuplicate(validProducts).length) {
      setValid(true);
      setLoading(false);
      enqueueSnackbar(t("Bạn đang chọn trùng tác nhân gây bệnh và loại mẫu"), {
        variant: "error",
      });
    } else {
      let total = 0;
      const orderDetails = validProducts.map((item) => {
        total += item.item.value * item.quantity;

        return {
          orderlineDetails: !!item?.item?.itemPathogen?.length
            ? item?.item?.itemPathogen.map((val) => {
                return {
                  itemId: val.id,
                  quantity: item.quantity,
                  unitPrice: checkPermissionViewPrice ? Number(val.value) : -1,
                  subTotal: checkPermissionViewPrice ? Number(val.value) * item.quantity : -1,
                  divisionId: item.item.item.division.id,
                  saleUnit: val.saleUnit,
                  baseUoM: val.baseUoM,
                };
              })
            : [],

          itemGroupId: Number(item.item.itemGroupType?.id),
          sampleId: item.item?.sampleId,
          quantity: item.quantity,
          totalQuantity: validProducts.reduce((acc, curr) => acc + curr.quantity, 0),
          additionValue: 0,
          subTotal: checkPermissionViewPrice
            ? item?.item?.itemPathogen?.reduce(
                (acc, curr) => acc + Number(curr.value) * item.quantity,
                0
              )
            : -1,
        };
      });

      const data: OrderInputModel = {
        customerId: ordDetail ? Number(order?.orders[0].customer?.id) : Number(dataCustomer?.id),
        targetId: targetId,
        objectiveId: objectiveId,
        requestTypeId: requestTypeId,
        vat: 0,
        requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
        totalDiscount: 0,
        orderAmount: checkPermissionViewPrice ? (ordDetail ? total + totalDetail : total) : -1,
        totalSample: Number(values.totalSample),
        source: SourceType.LAB,
        isDraft: ordDraft,
        draftId: 0,
        note,
        campId: Number(campID),
        salesOrgId: ordDetail
          ? order?.orders[0].salesOrg?.id
          : dataCustomer?.customerDivisions[0].salesOrg?.id,
        distributionChannelId: ordDetail
          ? order?.orders[0].distributionChannel?.id
          : dataCustomer?.customerDivisions[0].distributionChannel?.id,
        orderDetails: ordDetail
          ? orderDetails.concat(orderDetail.filter((item: any) => item.available === true))
          : (orderDetails as any),
      };

      OrderService.createOrderLab(data)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            if (ordDraft === true) {
              history.push(APP_ROUTES.ORDER_LAB);
            } else {
              setNotiOrder({ visible: true, newOrder: true });
              setDataOrd(res.data.data);
              setLoading(false);
              setPlaying(true);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          if (submitRef.current) {
            submitRef.current.disabled = false;
          }
          if (error.response?.status === 403) {
            setPermission(true);
          } else {
            setNotiOrder({ visible: true, newOrder: false });
          }
        });

      setValid(false);
    }
  };

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <>
      <Dialog fullScreen open={true}>
        <div className="order-modal-lab">
          <SimpleModalHeader
            title={t("order:CREATE_ORDER_LAB")}
            onClose={() => {
              ordDetail ? history.goBack() : history.push(APP_ROUTES.ORDER_LAB);
            }}
          />
          <div className="content">
            <Grid container>
              <Grid item xs={9} className="content-left">
                <Form onSubmit={async (values) => submitForm(values)}>
                  {({ handleSubmit }) => {
                    return (
                      <form onSubmit={handleSubmit} id="order-form">
                        <div className="header">
                          {orderId && (
                            <div className="left">
                              <img
                                src={
                                  checkLinkImage(
                                    order?.orders[0].customer.account?.avatar as any
                                  ) || renderImageProNull()
                                }
                                alt="Avata"
                                onError={renderErrorImageItem()}
                              />
                              <div>
                                <div className="name">
                                  {order?.orders[0].customer.fullName} -{" "}
                                  {order?.orders[0].customer.code}
                                </div>
                                {order?.orders[0].customer.phoneNumber}
                              </div>
                            </div>
                          )}
                          {!orderId && !visibleHeader ? (
                            <>
                              <SelectInput<CustomerOutputModel>
                                getSource={SourceType.LAB}
                                onChange={(value) => {
                                  // setVisibleHeader(true);
                                  setDataCustomer(value);
                                }}
                                onClick={() => {
                                  setVisibleAddCustomer(true);
                                }}
                                className={!visibleAddCustomer ? "customer-textfield" : "active"}
                                placeholder={
                                  !visibleAddCustomer
                                    ? t("SELECT_BY_NAME", { NAME: t("CUSTOMER") })
                                    : t("SEARCH_BY_NAME_CUSTOMER_CODE")
                                }
                                getOptionLabel={(opt) => opt.fullName}
                                renderOption={(p) => (
                                  <>
                                    <img
                                      src={
                                        checkLinkImage(p.account?.avatar as any) ||
                                        renderImageProNull()
                                      }
                                      alt=""
                                      style={{
                                        width: "4.8rem",
                                        height: "4.8rem",
                                        borderRadius: "50%",
                                        marginRight: "1rem",
                                      }}
                                      onError={renderErrorImageItem()}
                                    />
                                    <div>
                                      <span
                                        style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                                        {p.fullName} - {p.code}
                                      </span>
                                      <br />
                                      <span style={{ fontSize: "1.4rem", opacity: "0.4" }}>
                                        {p.phoneNumber}
                                      </span>
                                    </div>
                                  </>
                                )}
                                actionConfig={{
                                  action: CustomerService.getAllCustomer,
                                }}
                                dropdownBoxMaxHeight="45rem"
                                icon={<AddCustomerIcon />}
                              />

                              {/* Camp */}
                              <FormControl error={valid && !campID}>
                                <Select
                                  value={campID || undefined}
                                  onChange={(e: any) => {
                                    setCampID(e.target.value);
                                    setVisibleHeader(true);
                                  }}
                                  className="form-selected"
                                  disabled={!dataCustomer}
                                  renderValue={() => {
                                    return (
                                      <div style={{ alignItems: "center", display: "flex" }}>
                                        <FarmdIcon
                                          color={DEFAULT_COLOR}
                                          size={[30, 20]}
                                          viewBox={[24, 24]}
                                        />
                                        <span
                                          style={{
                                            marginLeft: "2rem",
                                            fontSize: "1.6rem",
                                            color: "#2a7a30",
                                          }}>
                                          {t("order:SELECT_CAMP")}
                                        </span>
                                      </div>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {!!dataCustomer?.camps?.length &&
                                    dataCustomer?.camps?.map((obj, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={obj.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {obj.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </>
                          ) : (
                            !orderId && (
                              <>
                                <div className="left">
                                  <img
                                    src={
                                      checkLinkImage(dataCustomer?.account?.avatar || "") ||
                                      renderImageProNull()
                                    }
                                    alt="Avata"
                                    onError={renderErrorImageItem()}
                                  />
                                  <div>
                                    <div className="name">
                                      {dataCustomer?.fullName} - {dataCustomer?.code}
                                    </div>
                                    {dataCustomer?.phoneNumber}
                                  </div>
                                </div>
                                <div className="col"></div>
                                <div className="col">
                                  <div className="label">{t("FARM_NAME")}</div>
                                  {dataCustomer?.camps[0]?.name || TEXT_HOLDER}
                                </div>
                                <div className="right">
                                  {!visibleValue && (
                                    <ClearIcon
                                      className="clear-icon"
                                      onClick={() => {
                                        setVisibleHeader(false);
                                        setSelectedVars([]);
                                        setTempSelectedVars([]);
                                        setDataCustomer(null);
                                        setTargetId(-1);
                                        setObjectiveId(-1);
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            )
                          )}
                        </div>
                        <div className="product-order">
                          <div className="product-order-header">
                            <div className="stt">{t("order:STT")}</div>
                            <div className="prod">{t("order:PATHOGEN")}</div>
                            <div className="unit">{t("order:TEST_METHOD")}</div>
                            <div className="quantity">{t("order:TEST_QUANTITY")}</div>
                            <div className="type">{t("order:SAMPLE_TYPE")}</div>
                            <div className="pay">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
                            <div className="totalQuan">{t("order:TOTAL_TEST_QUANTITY")}</div>
                            <div className="ic-del"></div>
                          </div>
                          <Divider style={{ marginBottom: "3.3rem" }} />
                          <div className="product-order-list">
                            {Object.keys(itemById).length === 0
                              ? orderDetail.length > 0 && <Loading style={{ height: "40vh" }} />
                              : orderDetail &&
                                orderDetail.map((d: any, idx: number) => {
                                  const orderDetailRender = order?.orders?.reduce(
                                    (result: object | null, od: any) => {
                                      const orderL = od.orderlines.find(
                                        (odl: any) =>
                                          odl.item.id === d.itemId &&
                                          od.division.id === d.divisionId
                                      );
                                      if (orderL) {
                                        result = orderL;
                                      }
                                      return result;
                                    },
                                    null
                                  );
                                  const checkItem = itemById.some(
                                    (f: any) => f.item.id === orderDetailRender.item.id
                                  );
                                  return (
                                    <div key={idx} style={{ paddingBottom: "2.4rem" }}>
                                      <div
                                        className="product-order-card"
                                        style={{ opacity: !checkItem ? 0.5 : 1 }}>
                                        <div className="stt">{idx + 1}</div>
                                        <div className="prod">
                                          <div style={{ display: "flex" }}>
                                            <img
                                              src={
                                                checkLinkImage(
                                                  orderDetailRender?.item?.images?.picture
                                                ) ||
                                                renderImageProNull(orderDetailRender?.item.source)
                                              }
                                              alt="product"
                                              onError={renderErrorImageItem(
                                                orderDetailRender?.item.source
                                              )}
                                            />
                                            <div className="name">
                                              {orderDetailRender?.item?.shortName}
                                              <div className="code">
                                                {orderDetailRender?.item?.name}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="price text">
                                          {checkPermissionViewPrice
                                            ? currencyFormat(d.unitPrice)
                                            : TEXT_ASTERISK}{" "}
                                          đ/<span>{d.saleUnit}</span>
                                        </div>
                                        <div className="quan">
                                          <QuantityBtnGroup
                                            disableMinusBtn={d.quantity <= 1}
                                            quantity={d.quantity}
                                            onChangeQuantity={(value) => {
                                              onUpdateProdVarQuantityDetail(
                                                d.itemId,
                                                value,
                                                +orderDetailRender?.item?.dimension * value
                                              );
                                            }}
                                            style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                          />
                                        </div>
                                        <div className="unit">
                                          {currencyFormat(d.dimension)}{" "}
                                          <span style={{ textTransform: "lowercase" }}>
                                            {d.baseUoM}
                                          </span>
                                        </div>
                                        <div className="pay">
                                          {checkPermissionViewPrice
                                            ? currencyFormat(d.quantity * +d.unitPrice)
                                            : TEXT_ASTERISK}
                                          &ensp;đ
                                        </div>
                                        <div
                                          className="ic-del"
                                          onClick={() => {
                                            onUpdateProdVarQuantityDetail(d.itemId, -1);
                                          }}>
                                          <DeleteOutlineIcon
                                            style={{
                                              fontSize: "2.2rem",
                                              marginLeft: "3rem",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {!checkItem && (
                                        <div className="card-null">
                                          <WarningRoundedIcon />
                                          &ensp;{t("order:PRODUCT_NO_LONGER_AVAILABLE")}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            {!!selectedVars.length &&
                              selectedVars.map((val, index) => {
                                const subTotal =
                                  val.item?.itemPathogen?.reduce(
                                    (acc, curr) => acc + Number(curr.value) * val.quantity,
                                    0
                                  ) || 0;
                                const sampleType =
                                  dataSampleTypeGroup?.find(
                                    (i) => i.id === val.item?.itemGroupType?.id
                                  )?.samples || [];

                                const checkDuplicate =
                                  findDataDuplicate(validProducts).findIndex(
                                    (i) => i.item?.id === val.item?.id
                                  ) > -1;
                                return (
                                  <div
                                    className="product-order-card"
                                    style={{ paddingBottom: "2.4rem" }}
                                    key={
                                      orderDetail.length > 0
                                        ? index + orderDetail.length
                                        : index + 1
                                    }>
                                    <div
                                      className="stt"
                                      style={{
                                        color: valid && checkDuplicate ? "#f44336" : "#312f30",
                                      }}>
                                      {orderDetail.length > 0
                                        ? index + orderDetail.length
                                        : index + 1}
                                    </div>

                                    <div className="prod">
                                      <div style={{ display: "flex" }}>
                                        {!!val.item?.itemPathogen?.length && (
                                          <>
                                            <div className="name">
                                              {val.item?.itemPathogen[0]?.name?.split(" ")[0]}
                                              <div className="action">
                                                <ClearIcon
                                                  className="clear-icon"
                                                  style={{
                                                    width: "2rem",
                                                    color: "#272B2F",
                                                  }}
                                                  onClick={() =>
                                                    val.item?.itemPathogen &&
                                                    onRemoveProdPathogen(
                                                      val.item?.itemPathogen[0],
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {val.item?.itemPathogen?.length > 1 && (
                                              <Tooltip
                                                title={val.item?.itemPathogen
                                                  ?.slice(1)
                                                  .map((i) => i.name)
                                                  .join(", ")}
                                                placement="top-start">
                                                <div className="name" style={{ cursor: "pointer" }}>
                                                  + {val.item.itemPathogen?.length - 1}...
                                                </div>
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className="prod-action"
                                        style={{ opacity: dialogID ? ".5" : "1" }}
                                        onClick={() => {
                                          if (!dialogID) {
                                            setDialogID(index + 1);
                                            val.item?.itemPathogen &&
                                              setDataPathogen(val.item?.itemPathogen);
                                            val.item?.itemGroupType &&
                                              setItemGroupType(val.item?.itemGroupType);
                                          }
                                        }}>
                                        + {t("ADD")}
                                      </div>
                                    </div>

                                    <div
                                      className="unit"
                                      style={{
                                        color: valid && checkDuplicate ? "#f44336" : "#312f30",
                                      }}>
                                      {val.item?.itemGroupType?.name || TEXT_HOLDER}
                                    </div>

                                    <div className="quantity">
                                      <QuantityBtnGroup
                                        disableMinusBtn={val.quantity <= 1}
                                        quantity={val.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateDataSelect(index, value);
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>

                                    <div className="type">
                                      <FormControl error={valid && !val.item?.sampleId}>
                                        <Select
                                          value={val.item?.sampleId || undefined}
                                          onChange={(e: any) => {
                                            onUpdateDataSelect(index, 1, e.target.value);
                                          }}
                                          fullWidth
                                          input={
                                            <OutlinedInput
                                              className="form-text-field"
                                              color="success"
                                            />
                                          }
                                          variant="outlined"
                                          style={{ width: "18rem" }}
                                          className="form-select-field"
                                          renderValue={() => {
                                            return !!val.item?.sampleId ? (
                                              sampleType?.find((i) => i.id === val.item?.sampleId)
                                                ?.name
                                            ) : (
                                              <span style={{ color: "#9e9e9e" }}>
                                                {t("SELECT_BY_NAME", {
                                                  NAME: t("order:SAMPLE_TYPE").toLowerCase(),
                                                })}
                                              </span>
                                            );
                                          }}
                                          displayEmpty
                                          MenuProps={{
                                            className: "select-menu-list",
                                          }}>
                                          {!!sampleType?.length &&
                                            sampleType?.map((tar, index: number) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={tar.id}
                                                  style={{
                                                    borderBottom: "0.1rem solid #EFEFEF",
                                                  }}>
                                                  {tar.name}
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                      </FormControl>
                                    </div>

                                    <div className="pay">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(subTotal)
                                        : TEXT_ASTERISK}
                                      &ensp;đ
                                    </div>

                                    <div className="totalQuan">
                                      {Number(val.item.itemPathogen?.length) * val.quantity}
                                    </div>

                                    <div className="ic-del">
                                      <div onClick={() => {}}>
                                        <ContentCopy
                                          className="copy-icon"
                                          style={{
                                            fontSize: "2rem",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => onDuplicatePro(index)}
                                        />
                                      </div>
                                      <div
                                        onClick={() => {
                                          onUpdateDataSelect(index, -1);
                                        }}>
                                        <DeleteOutlineIcon
                                          style={{
                                            fontSize: "2.2rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="footer">
                          <div className="payment">
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="flex-end"
                              spacing={2}>
                              <Grid item xs={4}>
                                <div className="form-item" style={{ width: "100%" }}>
                                  <div className="form-label">{t("order:TARGET")}</div>
                                  <Select
                                    value={targetId || undefined}
                                    onChange={(e: any) => {
                                      setTargetId(e.target.value);
                                      setSelectedVars([]);
                                      setTempSelectedVars([]);
                                    }}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    variant="outlined"
                                    className="form-select-field"
                                    disabled={!dataCustomer}
                                    renderValue={() => {
                                      return targetId > -1 ? (
                                        dataTarget.find((i) => i.id === targetId)?.name
                                      ) : (
                                        <span style={{ color: "#9e9e9e" }}>
                                          {t("SELECT_BY_NAME", {
                                            NAME: t("order:TARGET").toLowerCase(),
                                          })}
                                        </span>
                                      );
                                    }}
                                    displayEmpty
                                    MenuProps={{
                                      className: "select-menu-list",
                                    }}>
                                    {!!dataTarget?.length &&
                                      dataTarget.map((tar, index: number) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={tar.id}
                                            style={{
                                              borderBottom: "0.1rem solid #EFEFEF",
                                            }}>
                                            {tar.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </div>
                                <div className="form-item text-field">
                                  <div className="form-label">{t("order:TOTAL_SAMPLES")}</div>
                                  <Field
                                    name="totalSample"
                                    validate={FormUtil.composeValidators([
                                      FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                        errorMessage: t("INVALID_NAME", {
                                          NAME: t("order:TOTAL_SAMPLES"),
                                        }),
                                      }),
                                    ])}>
                                    {({ input, meta, ...rest }) => {
                                      return (
                                        <TextField
                                          {...input}
                                          {...rest}
                                          onChange={(e) => {
                                            input.onChange(e.target.value);
                                          }}
                                          placeholder={t("INPUT_BY_NAME", {
                                            NAME: t("order:TOTAL_SAMPLES").toLowerCase(),
                                          })}
                                          color="success"
                                          className="form-text-field form-sel-field"
                                          inputProps={{
                                            className: "input",
                                          }}
                                          autoComplete="off"
                                          helperText={meta.touched ? meta.error : ""}
                                          error={
                                            (valid && !input.value) ||
                                            (Boolean(meta.error) && meta.touched)
                                          }
                                        />
                                      );
                                    }}
                                  </Field>
                                </div>
                              </Grid>
                              <Grid item xs={4} className="info-order">
                                <div className="form-item" style={{ width: "100%" }}>
                                  <div className="form-label">{t("order:OBJECTIVE")}</div>
                                  <Select
                                    value={objectiveId || undefined}
                                    onChange={(e: any) => {
                                      setObjectiveId(e.target.value);
                                      setSelectedVars([]);
                                      setTempSelectedVars([]);
                                    }}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    variant="outlined"
                                    className="form-select-field"
                                    disabled={!dataCustomer}
                                    renderValue={() => {
                                      return objectiveId > -1 ? (
                                        dataObjective.find((i) => i.id === objectiveId)?.name
                                      ) : (
                                        <span style={{ color: "#9e9e9e" }}>
                                          {t("SELECT_BY_NAME", {
                                            NAME: t("order:OBJECTIVE").toLowerCase(),
                                          })}
                                        </span>
                                      );
                                    }}
                                    displayEmpty
                                    MenuProps={{
                                      className: "select-menu-list",
                                    }}>
                                    {!!dataObjective?.length &&
                                      dataObjective.map((obj: any, index: number) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={obj.id}
                                            style={{
                                              borderBottom: "0.1rem solid #EFEFEF",
                                            }}>
                                            {obj.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </div>
                                <div className="form-item">
                                  <div className="form-label">{t("APPOINTMENT_DATE")}</div>
                                  <Field
                                    name="requiredDate"
                                    // validate={FormUtil.Rule.required(
                                    //   "Bạn vẫn chưa chọn ngày giao hàng"
                                    // )}
                                  >
                                    {({ input, meta, ...rest }) => {
                                      return (
                                        <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                          <KeyboardDatePicker
                                            okLabel={t("order:SELECT")}
                                            cancelLabel={t("CANCEL")}
                                            fullWidth
                                            disablePast
                                            disableToolbar
                                            InputProps={{ readOnly: true }}
                                            inputVariant="outlined"
                                            format="dd-MM-yyyy"
                                            className="form-text-field date"
                                            value={input.value || moment().format("YYYY-MM-DD")}
                                            onChange={(value) => {
                                              if (value) {
                                                input.onChange(value);
                                              }
                                            }}
                                            inputProps={{ className: "input" }}
                                            keyboardIcon={
                                              <EventIcon
                                                color="#41AD49"
                                                size={[22, 22]}
                                                viewBox={[22, 22]}
                                              />
                                            }
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                          />
                                        </MuiPickersUtilsProvider>
                                      );
                                    }}
                                  </Field>
                                </div>
                              </Grid>
                              <Grid item xs={4} className="total">
                                <div className="form-item" style={{ width: "100%" }}>
                                  <div className="form-label">{t("order:REQUEST_TYPE")}</div>
                                  <FormControl error={valid && !requestTypeId}>
                                    <Select
                                      value={requestTypeId}
                                      onChange={(e: any) => {
                                        setRequestTypeId(e.target.value);
                                      }}
                                      fullWidth
                                      input={
                                        <OutlinedInput
                                          className="form-text-field"
                                          color="success"
                                        />
                                      }
                                      variant="outlined"
                                      className="form-select-field"
                                      renderValue={() => {
                                        return !!requestTypeId ? (
                                          dataRequestType.find((i) => i.id === requestTypeId)
                                            ?.description
                                        ) : (
                                          <span style={{ color: "#9e9e9e" }}>
                                            {t("SELECT_BY_NAME", {
                                              NAME: t("order:REQUEST_TYPE").toLowerCase(),
                                            })}
                                          </span>
                                        );
                                      }}
                                      disabled={!dataCustomer}
                                      displayEmpty
                                      MenuProps={{
                                        className: "select-menu-list",
                                      }}>
                                      {!!dataRequestType.length &&
                                        dataRequestType.map((i) => {
                                          return (
                                            <MenuItem
                                              key={i.id}
                                              value={i.id}
                                              style={{
                                                borderBottom: "0.1rem solid #EFEFEF",
                                              }}>
                                              {i.description}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="form-item" style={{ width: "100%" }}>
                                  <div className="form-label">{t("NOTES")}</div>
                                  <Field name="note">
                                    {({ input, meta, ...rest }) => {
                                      return (
                                        <TextField
                                          {...input}
                                          {...rest}
                                          fullWidth
                                          placeholder={t("INPUT_BY_NAME", {
                                            NAME: t("NOTES").toLowerCase(),
                                          })}
                                          className="form-text-field"
                                          inputProps={{ className: "input" }}
                                          variant="outlined"
                                          onChange={(e) => input.onChange(e.target.value)}
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                          onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                              event.preventDefault();
                                              return false;
                                            }
                                          }}
                                        />
                                      );
                                    }}
                                  </Field>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div
                            className={
                              (ordDetail && orderDetail.length) || selectedVars.length
                                ? "show"
                                : "hide"
                            }>
                            <div
                              className="btn-cancel-style btn__text-cancel"
                              onClick={() => {
                                history.push(APP_ROUTES.ORDER_LAB);
                              }}>
                              {t("CANCEL")}
                            </div>
                            <button
                              disabled={!orderDetail?.length && !selectedVars?.length}
                              onClick={() => {
                                setOrdDraft(true);
                              }}
                              type="submit"
                              ref={submitRef}
                              className="btn-confirm-style btn-border btn--transparent"
                              style={{ marginLeft: "4rem" }}>
                              {t("SAVE_DRAFT")}
                            </button>
                            <button
                              disabled={!orderDetail?.length && !selectedVars?.length}
                              onClick={() => {}}
                              type="submit"
                              ref={submitRef}
                              className="btn-confirm-style"
                              style={{ marginLeft: "4rem" }}>
                              {t("SEND_REQUEST")}
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </Grid>
              <Grid item xs={3} className="content-right">
                {!dataCustomer || (!ordDetail && (targetId === -1 || objectiveId === -1)) ? (
                  <div className="new-order">
                    <img src={NewOrder} alt="new-order" />
                    <span style={{ fontWeight: 700, fontSize: "1.8rem", lineHeight: "2.7rem" }}>
                      {!dataCustomer
                        ? t("SELECT_BY_NAME", { NAME: t("CUSTOMER").toLowerCase() })
                        : t("SELECT_TARGET_OBJECTIVE")}
                    </span>
                    <span>{t("order:START_CREATING_ORDER")}</span>
                  </div>
                ) : (
                  <>
                    <div className="content-right__title">{t("order:TEST_METHOD")}</div>
                    <TextField
                      placeholder={t("SEARCH_BY_PATHOGEN")}
                      variant="outlined"
                      color="success"
                      className={"search-border-style"}
                      inputProps={{ className: "input" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="large" />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value);
                      }}
                    />
                    {states.itemGroups && states.itemGroups.length > 0 && (
                      <div className="gr-item">
                        <Swiper
                          slidesPerView={3}
                          spaceBetween={1}
                          navigation={{
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                          }}>
                          {[...states.itemGroups].map((cate: any) => {
                            return (
                              <SwiperSlide key={cate?.id}>
                                <div
                                  className={
                                    cate?.id === itemGroupType?.id
                                      ? "btn-gr__item active"
                                      : "btn-gr__item"
                                  }
                                  style={{
                                    cursor:
                                      !tempSelectedVars.length && !dialogID
                                        ? "pointer"
                                        : "not-allowed",
                                  }}
                                  onClick={() => {
                                    !tempSelectedVars.length && !dialogID && setItemGroupType(cate);
                                  }}>
                                  <div className="label">{cate?.name}</div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        <button className="swiper-button-prev button-prev">
                          <ArrowLeftSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                        </button>
                        <button className="swiper-button-next button-next">
                          <ArrowRightSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                        </button>
                      </div>
                    )}

                    {states.loading || (states.fetching && (searchValue || itemGroupType)) ? (
                      <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                    ) : (
                      <>
                        <div className="product-list">
                          {states.data?.length > 0 ? (
                            <>
                              <InfiniteScroll
                                pageStart={0}
                                loadMore={() =>
                                  fetchData({
                                    page: states.page + 1,
                                    limit: 20,
                                    source: SourceType.LAB,
                                    customerId: ordDetail
                                      ? order?.orders[0].customer?.id
                                      : dataCustomer?.id,
                                    searchValue,
                                    itemGroupId: itemGroupType ? itemGroupType?.id : undefined,
                                  })
                                }
                                hasMore={hasMore}
                                useWindow={false}>
                                {states.data?.map((pro: any, index: number) => {
                                  const act = dataPathogen.find((item) => item.id === pro.item.id);
                                  const actOrd = []
                                    .concat(...orderDetail)
                                    .find((item: any) => item.itemId === pro.item.id);

                                  const actItem = !!tempSelectedVars.length
                                    ? tempSelectedVars?.find((item) =>
                                        item?.item?.itemPathogen?.find((i) => i.id === pro.item.id)
                                      )
                                    : null;
                                  const actOrdItem = []
                                    .concat(...orderDetail)
                                    .find((item: any) => item.itemId === pro.item.id);
                                  return (
                                    <ProductItem
                                      class={!!dialogID ? act || actOrd : actItem || actOrdItem}
                                      key={pro.item.code + index}
                                      onClick={() =>
                                        onUpdateProdVarQuantity(pro.item?.id, 1, pro, undefined, 1)
                                      }
                                      image={pro.item.images?.picture}
                                      shortName={pro.item.name}
                                      name={pro.item.shortName}
                                      value={
                                        checkPermissionViewPrice
                                          ? currencyFormat(pro.value)
                                          : TEXT_ASTERISK
                                      }
                                      baseUoM={pro.baseUoM}
                                      source={SourceType.LAB}
                                      interestedItem={pro?.item?.interestedItem}
                                      disableImg
                                      color={pro.item?.color}
                                    />
                                  );
                                })}
                              </InfiniteScroll>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}>
                              <img src={ProNull} alt="search" width={190} height={178} />
                              <div
                                style={{
                                  fontSize: "1.8rem",
                                  fontWeight: "bold",
                                  color: "#536380",
                                  lineHeight: "2.7rem",
                                }}>
                                {t("order:PRODUCT_NOT_FOUND")}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="product-list_footer">
                          <div className="action">
                            <button
                              onClick={() => {
                                setDataPathogen([]);
                                setTempSelectedVars([]);
                                setDialogID(null);
                              }}
                              type="submit"
                              style={{
                                width: "20rem",
                                opacity:
                                  !!dataPathogen.length || !!tempSelectedVars?.length ? "1" : "0.2",
                              }}
                              className="btn-confirm-style btn-border">
                              {t("CANCEL")}
                            </button>
                            <button
                              onClick={() =>
                                !!dataPathogen.length
                                  ? onAddProPathogen()
                                  : !!tempSelectedVars?.length && onAddDataSelect()
                              }
                              type="submit"
                              className="btn-confirm-style"
                              style={{
                                marginLeft: "4rem",
                                width: "20rem",
                                opacity:
                                  !!dataPathogen.length || !!tempSelectedVars?.length ? "1" : "0.2",
                              }}>
                              {t("order:SELECT")}
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          {loading && (
            <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
              <Loading />
            </Backdrop>
          )}

          <ResultOrder
            sourceType={SourceType.LAB}
            visible={notiOrder.visible}
            newOrder={notiOrder.newOrder}
            dataOrd={dataOrd}
            setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
          />

          <Notification
            visible={notiOrderCancel}
            title={t("order:CANCEL_CART")}
            subTitle={
              <span
                dangerouslySetInnerHTML={{ __html: t("order:ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }}
              />
            }
            icon={
              <div className={`notification-icon-box yellow`}>
                <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            }
            actions={
              <>
                <button
                  className="btn-confirm-style"
                  style={{
                    background: "#fff",
                    color: "#0A6836",
                    border: "0.1rem solid #0A6836",
                    width: "20.6rem",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setNotiOrderCancel(false);
                  }}>
                  {t("NO")}
                </button>
                <button
                  className="btn-confirm-style"
                  style={{
                    width: "20.6rem",
                    marginLeft: "2rem",
                  }}
                  onClick={() => {
                    history.push(APP_ROUTES.ORDER_LAB);
                  }}>
                  {t("CONFIRM")}
                </button>
              </>
            }
            onClose={() => {
              setNotiOrderCancel(false);
            }}
          />

          {/*Popup khong co quyen truy cap, state (boolean) la permision*/}
          {permission && <NotAccess />}
        </div>
      </Dialog>
    </>
  );
};

export default OrderLabModal;
