import { IconBase, IconComponent } from "@Core/Icons";

export const KeyIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M14.625 13C14.625 15.6924 12.4424 17.875 9.75 17.875C7.05761 17.875 4.875 15.6924 4.875 13C4.875 10.3076 7.05761 8.125 9.75 8.125C12.4424 8.125 14.625 10.3076 14.625 13Z"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M14.625 13H21.8472M25.4583 13H21.8472M21.8472 13V18.4167"
        stroke={props.color}
        strokeWidth="2"
      />
    </IconBase>
  );
};
