/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import RangePicker from "@Components/RangePicker";
import { APP_ROUTES, TIME } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import useDateRange from "@Hooks/useDateRange";
import { Action, DepositInputModel, DepositOutputModel, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Dialog, Drawer, Grid, InputAdornment, TextField } from "@mui/material";
import { DepositService } from "@Services";
import { PermissionUtil } from "@Utils";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import { EditIcon } from "@Components/Icons/EditIcon";
import Notification from "@Components/Notification";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DepositUpdate from "./Modal/DepositUpdate";

function reducer(state: PaginateFetchReducer<DepositOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const Deposit: React.FC = () => {
  const { t } = useTranslation(["translation", "product"]);
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [visibleDetail, setVisibleDetail] = useState<DepositOutputModel | null>(null);
  const [deleteDeposit, setDeleteDeposit] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [depositId, setDepositId] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isclose, setIsclose] = useState<boolean>(true);
  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await DepositService.getAllDeposit(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    if (refresh && isclose) {
      fetchData(
        {
          page: 1,
          limit: 50,
          searchString: searchValue,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, range.startTime.toISOString(), range.endTime.toISOString(), isclose]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  //error
  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  useEffect(() => {
    setRefresh(true);
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }, [location, profile]);

  const onDeleteDeposit = () => {
    setLoading(true);
    DepositService.deleteDeposit(deleteDeposit)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("DELETE_DEPOSIT_SUCCESSFUL"), {
            variant: "success",
          });
          setLoading(false);
          setDeleteDeposit("");
          setVisibleDetail(null);
          fetchData(
            {
              page: 1,
              limit: 50,
              searchString: searchValue,
            },
            true
          );
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("DELETE_DEPOSIT_FAILED"), {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const onUpdateStatus = async () => {
    if (!!visibleDetail) {
      const data: DepositInputModel = {
        code: visibleDetail.code,
        name: visibleDetail.name,
        type: visibleDetail.type,
        itemGroupTypeIds: visibleDetail.itemGroupTypes.map((i) => i.id),
        rate1: visibleDetail.rate1,
        rate2: visibleDetail.rate2,
        rate3: visibleDetail.rate3,
        isActive: isActive,
        appliedDate: moment(visibleDetail.appliedDate).toISOString(),
      };

      DepositService.updateDeposit(visibleDetail?.id.toString(), data)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setVisibleDetail(null);
            fetchData(
              {
                page: 1,
                limit: 50,
                searchString: searchValue,
              },
              true
            );
            isActive
              ? enqueueSnackbar(t("ACTIVE_DEPOSIT_SUCCESSFUL"), {
                  variant: "success",
                })
              : enqueueSnackbar(t("DISABLE_DEPOSIT_SUCCESSFUL"), {
                  variant: "success",
                });
          }
        })
        .catch((error) => {
          isActive
            ? enqueueSnackbar(t("ACTIVE_DEPOSIT_FAILED"), {
                variant: "error",
              })
            : enqueueSnackbar(t("DISABLE_DEPOSIT_FAILED"), {
                variant: "error",
              });
        });
    }
  };

  const checkPermissionDepositCreate = !PermissionUtil.check(profile?.role?.permissions, "", true);

  const checkPermissionDepositViewDetail = !PermissionUtil.check(
    profile?.role?.permissions,
    "",
    true
  );

  const renderRate = (rate1: number, rate2: number, rate3: number) => {
    const dataRate = [
      { rate: rate1, label: `${rate1}%` },
      { rate: rate2, label: `${rate2}%` },
      { rate: rate3, label: `${rate3}%` },
    ].filter((r) => r.rate > 0);

    return (
      <div>
        {dataRate.map((r, index) => (
          <span key={index}>
            {r.label}
            {index < dataRate.length - 1 && " - "}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div className="deposit">
      <div className="deposit-content">
        <div className="action">
          <TextField
            placeholder={t("Ngành hàng, Phương thức cọc, Nhóm sản phẩm...")}
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          <RangePicker
            startDate={
              location.state?.startTime !== undefined
                ? moment(location.state?.startTime)
                : range.startTime
            }
            endDate={
              location.state?.endTime !== undefined
                ? moment(location.state?.endTime)
                : range.endTime
            }
            onChangeDate={(val) => {
              if (val && val.length) {
                setRange(
                  moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                  moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                );
              }
            }}
          />
        </div>
        <div className="list">
          <div className="list-header">
            <div className="code">{t("CODE")}</div>
            <div className="name">{t("DEPOSIT_METHOD_NAME")}</div>
            <div className="cate">{t("product:PRODUCT_GROUP")}</div>
            <div className="type">{t("DEPOSIT_TYPE")}</div>
            <div className="rate">{t("DEPOSIT_RATE")}</div>
            <div className="date">{t("EFFECTIVE_DATE")}</div>
          </div>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        searchString: searchValue,
                      })
                    }>
                    {state.data.map((item: DepositOutputModel) => {
                      return (
                        <div
                          className="item"
                          key={item.code}
                          onClick={() => {
                            if (checkPermissionDepositViewDetail) {
                              setVisibleDetail(item);
                              setDepositId(item.id);
                              setIsActive(item.isActive);
                            } else {
                              enqueueSnackbar("Bạn không có quyền truy cập", {
                                variant: "error",
                                preventDuplicate: true,
                              });
                            }
                          }}>
                          <div className="code">{item.code}</div>
                          <div className="name">{item.name}</div>
                          <div className="cate" style={{ textTransform: "capitalize" }}>
                            {item.itemGroupTypes
                              ?.map((i) => i.name)
                              .join(" - ")
                              .toLowerCase()}
                          </div>
                          <div className="type">{t(item.type.toUpperCase())}</div>
                          <div className="rate">
                            {renderRate(item.rate1, item.rate2, item.rate3)}
                            <span
                              style={{ marginLeft: "1rem", display: "flex", alignItems: "center" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setDepositId(item?.id.toString());
                                console.log("123123");

                                setIsclose(false);
                                // history.push(
                                //   APP_ROUTES.DEPOSIT_UPDATE.replace(
                                //     ":depositId",
                                //     item?.id.toString()
                                //   )
                                // );
                              }}>
                              <EditIcon color={"#41AD49"} size={[15, 15]} viewBox={[16, 16]} />
                            </span>
                          </div>
                          <div className="date">
                            {moment(item.appliedDate).format(TIME.DAY_MONTH_YEAR)}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>
      {!isclose && (
        <DepositUpdate
          depositId={visibleDetail?.id?.toString() ? visibleDetail?.id?.toString() : depositId}
          onclose={isclose}
          setOnclose={setIsclose}
        />
      )}{" "}
      ;
      <Dialog
        open={!!visibleDetail}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <div className="deposit-modal">
          <SimpleModalHeader
            title={t("DETAIL_BY_NAME", { NAME: visibleDetail?.name })}
            onClose={() => {
              setVisibleDetail(null);
            }}
          />
          <div className="content">
            <div className="deposit-detail">
              {/* <div className="deposit-detail-header">
                <span>{t("DETAIL_BY_NAME", { NAME: visibleDetail?.name })}</span>
                <ClearIcon
                  style={{
                    fontSize: "3rem",
                    cursor: "pointer",
                    position: "absolute",
                    right: "3rem",
                    top: "3rem",
                  }}
                  onClick={() => setVisibleDetail(null)}
                />
              </div> */}
              <div className="deposit-detail-body" style={{ paddingTop: "0" }}>
                {!!visibleDetail ? (
                  <Grid container spacing={3} alignItems={"center"}>
                    <Grid item xs={6}>
                      <div className="label">{t("CODE")}</div>
                      <div className="value">{visibleDetail.code}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="label">{t("DEPOSIT_METHOD_NAME")}</div>
                      <div className="value">{visibleDetail.name}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="label">{t("DEPOSIT_TYPE")}</div>
                      <div className="value">{t(visibleDetail.type.toUpperCase())}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="label">{t("DEPOSIT_RATE")}</div>
                      <div className="value">
                        {renderRate(visibleDetail.rate1, visibleDetail.rate2, visibleDetail.rate3)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="label">{t("EFFECTIVE_DATE")}</div>
                      <div className="value">
                        {moment(visibleDetail.appliedDate).format(TIME.DAY_MONTH_YEAR)}
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="label">{t("STATUS")}</div>
                      <div className="value">
                        {visibleDetail.isActive ? (
                          <span style={{ color: "#41AD49" }}>&#9679;&ensp;{t("IN_USED")}</span>
                        ) : (
                          <span style={{ color: "#B8292F" }}>&#9679;&ensp;{t("NOT_USED")}</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: "2%" }}>
                      <div>
                        <DefaultSwitch
                          checked={isActive}
                          onChange={() => {
                            setIsActive(!isActive);
                            setNotiStatus({
                              visible: true,
                              status: !isActive ? true : false,
                            });
                          }}
                        />
                        {/* {isActive ? (
                      <span style={{ color: "#41AD49" }}>&#9679;&ensp;{t("IN_USED")}</span>
                    ) : (
                      <span style={{ color: "#B8292F" }}>&#9679;&ensp;{t("NOT_USED")}</span>
                    )} */}
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                )}
              </div>

              <div className="deposit-detail-footer">
                <button
                  disabled={visibleDetail?.isActive}
                  onClick={() => {
                    visibleDetail && setDeleteDeposit(visibleDetail?.id?.toString());
                  }}
                  type="button"
                  className="btn-confirm-style"
                  style={{
                    background: "#fff",
                    color: "#A51D23",
                    border: "0.1rem solid #A51D23",
                    width: "20rem",
                    marginRight: "2rem",
                    opacity: visibleDetail?.isActive ? 0.5 : 1,
                  }}>
                  {t("DELETE")}
                </button>
                <button
                  onClick={() =>
                    // visibleDetail &&
                    // history.push(
                    //   APP_ROUTES.DEPOSIT_UPDATE.replace(":depositId", visibleDetail?.id.toString())
                    // )
                    {
                      console.log("visibleDetail", visibleDetail);
                      setVisibleDetail(null);
                      setIsclose(false);
                    }
                  }
                  type="button"
                  className="btn-confirm-style"
                  style={{ width: "20rem" }}>
                  {t("UPDATE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <Drawer open={!!visibleDetail} anchor="right" onClose={() => setVisibleDetail(null)}>
       
      </Drawer> */}
      {!!deleteDeposit && (
        <Notification
          visible={!!deleteDeposit}
          title={t("DELETE_BY_NAME", { NAME: visibleDetail?.name })}
          subTitle={<span>Bạn có chắc muốn xoá phương thức cọc này ?</span>}
          icon={
            <div className={"notification-icon-box yellow"}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#A51D23",
                  border: "0.1rem solid #A51D23",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  setDeleteDeposit("");
                }}>
                {t("CANCEL")}
              </button>
              <button
                disabled={loading}
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => onDeleteDeposit()}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => setDeleteDeposit("")}
        />
      )}
      {notiStatus && (
        <Notification
          visible={notiStatus.visible}
          title={notiStatus.status ? t("ACTIVE_DEPOSIT") : t("DISABLE_DEPOSIT")}
          subTitle={""}
          icon={
            <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                  notiStatus.status ? setIsActive(false) : setIsActive(true);
                }}>
                {t("CANCEL")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  onUpdateStatus();
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            notiStatus.status
              ? setNotiStatus({ visible: false, status: true })
              : setNotiStatus({ visible: false, status: false });
            notiStatus.status ? setIsActive(false) : setIsActive(true);
          }}
        />
      )}
      {checkPermissionDepositCreate && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            history.push(APP_ROUTES.DEPOSIT_CREATE);
            // setOpenAccountModal(true);
          }}
          btns={[]}
        />
      )}
    </div>
  );
};

export default Deposit;
