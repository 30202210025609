/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  PersonAddAlt1 as AddCustomerIcon,
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  Clear as ClearIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
//import { OpenInNewIcon } from "@Components/Icons/OpenInNewIcon";
import urlSound from "@Assets/audio/gf-noti.mp3";
import NewOrder from "@Assets/images/new-order.png";
import ProNull from "@Assets/images/product/nopro.png";
import ItemImg from "@Assets/images/stroke.svg";
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import NotAccess from "@Components/NotAccess";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  Action,
  CustomerOutputModel,
  FetchAction,
  ItemPermission,
  OrderDetailOutputModel,
  OrderStatus,
  ProductOutputModel,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, OrderService, ProductService, ShippingService } from "@Services";
import {
  checkLinkImage,
  currencyFormat,
  PermissionUtil,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./OrderFeedModal.scss";

SwiperCore.use([Navigation]);

type OrderProduct = OrderDetailOutputModel & ProductOutputModel;

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const OrderFeedModal: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const history = useHistory();
  const submitRef = useRef<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleValue] = useState<boolean>(false);
  const [visibleHeader, setVisibleHeader] = useState<boolean>(false);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState<boolean>(false);
  const [value, setValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [selectedVars, setSelectedVars] = useState<{ item: OrderProduct; quantity: number }[]>([]);
  const [permission, setPermission] = useState<boolean>(false);
  const mounted = useRef(false);
  const [salesOffice, setSalesOffice] = useState<any>();
  const [shipping, setShipping] = useState<any>();
  const [salesOfficeId, setSalesOfficeId] = useState<string>("");
  const [shippingAddress, setShippingAddress] = useState<string>("");

  /*Dat lai don hang*/
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(+orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    orderId && orderById();
  }, []);

  const [orderDetail, setOrderDetail] = useState<any>([]);
  const [ordDetailValue, setOrdDetailValue] = useState<any>([]);

  const listItem = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      return {
        itemId: d.item.id,
      };
    });
  });

  const [itemById, setItemById] = useState<any>([]);

  const loadListItemById = async () => {
    const response = await ProductService.getItemById({
      source: "feed",
      itemIds: `${[].concat(...listItem).map((a: any) => {
        return a.itemId;
      })}`,
      customerId: order?.orders[0]?.customer?.id,
    });
    setItemById(response.data);
  };

  useEffect(() => {
    orderId && order && orderDetail && listItem && loadListItemById();
  }, [orderDetail]);

  const divisionIds = Array.from(
    new Set(
      selectedVars
        .map((i) => {
          return i.item.item.division.id;
        })
        .concat(
          orderDetail.map((d: any) => {
            return d.divisionId;
          })
        )
    )
  );

  const getSalesOffice: () => Promise<void> = async () => {
    try {
      const response = await ShippingService.getSalesOffice({
        page: 1,
        limit: 100,
        customerId: ordDetail ? order?.orders[0].customer?.id : value?.id,
        divisionIds: divisionIds.toString(),
      });
      if (mounted.current) {
        setSalesOffice(() => response.data.data);
      }
    } catch (error) {}
  };

  const getShipping: () => Promise<void> = async () => {
    try {
      const response = await ShippingService.getAllShipping({
        page: 1,
        limit: 100,
        customerId: ordDetail ? order?.orders[0].customer?.id : value?.id,
        divisionIds: divisionIds.toString(),
        source: "feed",
      });
      if (mounted.current) {
        setShipping(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    divisionIds.length > 0 && (value?.id || order?.orders[0].customer?.id) && getSalesOffice();
    divisionIds.length > 0 && (value?.id || order?.orders[0].customer?.id) && getShipping();
  }, [value?.id, order?.orders[0].customer?.id, selectedVars, order, divisionIds.length > 0]);

  const shipppingAddressId = shipping?.find((f: any) => f.address === order?.orders[0].address)?.id;

  useEffect(() => {
    order?.orders[0].salesOffice && setSalesOfficeId(order?.orders[0].salesOffice?.id);
    order?.orders[0].address && setShippingAddress(shipppingAddressId ? shipppingAddressId : "");
  }, [order, shipping]);

  const ordDetail = order?.orders.map((o: any) => {
    return o.orderlines.map((d: any) => {
      const checkItem = itemById.some((f: any) => f.item.id === d.item.id);
      const checkTus = o.status === OrderStatus.PROCESSING ? +d.expectQuantity : +d.actualQuantity;
      const ordQuantity = checkTus !== 0 ? checkTus : +d.orderQuantity !== 0 ? +d.orderQuantity : 1;
      return {
        itemId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item?.id
          : d.item?.id,
        divisionId: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.division?.id
          : o.division?.id,
        discount: 0,
        quantity: ordQuantity,
        unitPrice: checkPermissionViewPrice
          ? checkItem
            ? +itemById.find((f: any) => f.item.id === d.item.id)?.value
            : +d.price
          : -1,
        attachValue: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.additionValue
          : d.additionValue,
        saleUnit: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.baseUoM
          : d.saleUnit,
        baseUoM: checkItem
          ? itemById.find((f: any) => f.item.id === d.item.id)?.item.baseUoM
          : d.baseUoM,
        dimension: checkItem
          ? +itemById.find((f: any) => f.item.id === d.item.id)?.item.dimension * ordQuantity
          : +d.dimension * ordQuantity,
        subTotal: checkPermissionViewPrice
          ? checkItem
            ? ordQuantity * +itemById.find((f: any) => f.item.id === d.item.id)?.value
            : ordQuantity * d.price
          : -1,
        available: checkItem ? true : false,
      };
    });
  });

  useEffect(() => {
    order && setOrderDetail([].concat(...ordDetail));
    setOrdDetailValue(
      order?.orders.map((o: any) => {
        return o.orderlines.map((d: any) => {
          return d;
        });
      })
    );
  }, [order]);

  useEffect(() => {
    order && itemById && setOrderDetail([].concat(...ordDetail));
  }, [itemById.length > 0]);

  const onUpdateProdVarQuantityDetail = (
    variantId: number,
    quantity: number,
    dimension?: number
  ) => {
    const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
    let data = [].concat(...orderDetail) as any;

    // update
    if (quantity === -1) {
      data.splice(idx, 1);
      ordDetailValue.splice(idx, 1);
      setOrdDetailValue(ordDetailValue);
    } else {
      data[idx] = {
        ...data[idx],
        quantity: quantity,
        dimension: dimension,
      };
    }
    // data = data.filter((item: any) => item.quantity >= 1);
    setOrderDetail(data);
  };

  const totalDetail = orderDetail
    .filter((item: any) => item.available === true)
    .reduce((sum: number, item: any) => {
      sum += +item.unitPrice * item.quantity;
      return sum;
    }, 0);

  /*------------------------------------- */

  const [itemGroupId, setItemGroupId] = useState<string>("");

  const [states, dispatchs] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchs({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.getProductByAdmin(query);
      if (isReset) {
        dispatchs({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current) {
        dispatchs({
          type: Action.SUCCESS,
          payload: result.data,
        });
      }
    } catch (err) {
      if (mounted)
        dispatchs({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    (value !== undefined || ordDetail) &&
      fetchData(
        {
          page: 1,
          limit: 20,
          source: "feed",
          searchValue: searchValue,
          customerId: ordDetail ? order?.orders[0]?.customer?.id : value?.id,
          itemGroupId: itemGroupId ? itemGroupId : undefined,
        },
        true
      );
  }, [value, searchValue, itemGroupId, order, order?.orders[0]?.customer?.id]);

  const hasMore = states.data?.length < states.totalRecords && states.page < states.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const createId = profile?.employee?.find((e: any) => e.source === "feed")?.id;

  const [dataOrd, setDataOrd] = useState<any>({});

  const onUpdateProdVarQuantity = (
    variantId: number,
    quantity: number,
    addedProduct?: OrderProduct,
    addNew: boolean = false
  ) => {
    const idx = selectedVars.findIndex((item) => item.item.item.id === variantId);
    const actOrd = [].concat(...orderDetail).find((item: any) => item.itemId === variantId);

    let data = [...selectedVars];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      // update

      data[idx] = {
        ...data[idx],
        quantity: quantity || 1,
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }
      if (actOrd) {
        return;
      }
      data.push({ item: addedProduct, quantity });
    }
    data = data.filter((item) => item.quantity >= 1);

    setSelectedVars(data);
  };

  const total = selectedVars.reduce((sum, item) => {
    return sum + item.item.value * item.quantity;
  }, 0);

  const submitForm = (values: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    const validProducts = selectedVars.filter((item) => item.quantity);
    if (!validProducts.length) {
      // setNotiOrderNull(true);
    }
    let total = 0;
    const orderDetails = validProducts.map((item) => {
      total += item.item.value * item.quantity;

      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? item.item.value : -1,
        saleUnit: item.item.baseUoM,
        baseUoM: item.item.item.baseUoM,
        dimension: Number(item.item.item.dimension) * item.quantity,
        subTotal: checkPermissionViewPrice ? item.item.value * item.quantity : -1,
      };
    });

    const data: Parameters<typeof OrderService.createOrder>[0] = {
      ...values,
      customerId: ordDetail ? order?.orders[0].customer?.id : value?.id,
      creatorId: ordDetail ? order.createdBy?.id : createId,
      salesOrgId: ordDetail
        ? order?.orders[0].salesOrg?.id
        : value.customerDivisions[0].salesOrg?.id,
      shippingAddress: shippingAddress ? shippingAddress : shipping[0]?.id,
      salesOfficeId: salesOfficeId ? salesOfficeId : undefined,
      distributionChannelId: ordDetail
        ? order?.orders[0].distributionChannel?.id
        : value.customerDivisions[0].distributionChannel?.id,
      currency: "cash",
      totalDiscount: 0,
      vat: 0,
      orderAmount: checkPermissionViewPrice ? (ordDetail ? total + totalDetail : total) : -1,
      source: "feed",
      orderDetails: ordDetail
        ? orderDetails.concat(orderDetail.filter((item: any) => item.available === true))
        : orderDetails,
      isDraft: ordDraft,
      requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
    } as any;

    OrderService.createOrder(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (ordDraft === true) {
            history.push(APP_ROUTES.ORDER_FEED);
          } else {
            setNotiOrder({ visible: true, newOrder: true });
            setDataOrd(res.data.data);
            setLoading(false);
            setPlaying(true);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        if (submitRef.current) {
          submitRef.current.disabled = false;
        }
        if (error.response?.status === 403) {
          setPermission(true);
        } else {
          setNotiOrder({ visible: true, newOrder: false });
        }
      });
  };

  const totalWeight =
    selectedVars?.reduce((sum, item) => {
      return sum + +item.item.item.dimension * item.quantity;
    }, 0) +
    orderDetail?.reduce((sum: any, item: any) => {
      return sum + +item.dimension;
    }, 0);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal">
        <SimpleModalHeader
          title={t("order:CREATE_ORDER_FEED")}
          onClose={() => {
            ordDetail ? history.goBack() : history.push(APP_ROUTES.ORDER_FEED);
          }}
        />
        <div className="content">
          <Grid container>
            <Grid item xs={9} className="content-left">
              <Form onSubmit={async (values) => submitForm(values)}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="order-form">
                      <div className="header">
                        {orderId && (
                          <div className="left">
                            <img
                              src={
                                checkLinkImage(order?.orders[0].customer.account?.avatar as any) ||
                                renderImageProNull()
                              }
                              alt="Avata"
                              onError={renderErrorImageItem()}
                            />
                            <div>
                              <div className="name">
                                {order?.orders[0].customer.fullName} -{" "}
                                {order?.orders[0].customer.code}
                              </div>
                              {order?.orders[0].customer.phoneNumber}
                            </div>
                          </div>
                        )}
                        {!orderId && !visibleHeader ? (
                          <SelectInput<CustomerOutputModel>
                            getSource={"feed"}
                            onChange={(value) => {
                              setVisibleHeader(true);
                              setValue(value);
                            }}
                            onClick={() => {
                              setVisibleAddCustomer(true);
                            }}
                            className={!visibleAddCustomer ? "customer-textfield" : "active"}
                            placeholder={
                              !visibleAddCustomer
                                ? t("SELECT_BY_NAME", { NAME: t("CUSTOMER") })
                                : t("SEARCH_BY_NAME_CUSTOMER_CODE")
                            }
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(p) => (
                              <>
                                <img
                                  src={
                                    checkLinkImage(p.account?.avatar as any) || renderImageProNull()
                                  }
                                  alt=""
                                  style={{
                                    width: "4.8rem",
                                    height: "4.8rem",
                                    borderRadius: "50%",
                                    marginRight: "1rem",
                                  }}
                                  onError={renderErrorImageItem()}
                                />
                                <div>
                                  <span style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                                    {p.fullName} - {p.code}
                                  </span>
                                  <br />
                                  <span style={{ fontSize: "1.4rem", opacity: "0.4" }}>
                                    {p.phoneNumber}
                                  </span>
                                </div>
                              </>
                            )}
                            actionConfig={{
                              action: CustomerService.getAllCustomer,
                            }}
                            dropdownBoxMaxHeight="45rem"
                            icon={<AddCustomerIcon />}
                          />
                        ) : (
                          !orderId && (
                            <>
                              <div className="left">
                                <img
                                  src={
                                    checkLinkImage(value?.account?.avatar) || renderImageProNull()
                                  }
                                  alt="Avata"
                                  onError={renderErrorImageItem()}
                                />
                                <div>
                                  <div className="name">
                                    {value?.fullName} - {value?.code}
                                  </div>
                                  {value?.phoneNumber}
                                </div>
                              </div>
                              <div className="col"></div>
                              <div className="col">
                                <div className="label">{t("order:BUSINESS_UNIT")} (BU)</div>
                                {value?.customerDivisions?.[0]?.salesOrg?.description ||
                                  TEXT_HOLDER}
                              </div>
                              <div className="right">
                                {!visibleValue && (
                                  <ClearIcon
                                    className="clear-icon"
                                    onClick={() => {
                                      setVisibleHeader(false);
                                      setSelectedVars([]);
                                      setShipping([]);
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          )
                        )}
                      </div>
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="unit">{t("order:WEIGHT")}</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {Object.keys(itemById).length === 0
                            ? orderDetail.length > 0 && <Loading style={{ height: "40vh" }} />
                            : orderDetail &&
                              orderDetail.map((d: any, idx: number) => {
                                const orderDetailRender = order?.orders?.reduce(
                                  (result: object | null, od: any) => {
                                    const orderL = od.orderlines.find(
                                      (odl: any) =>
                                        odl.item.id === d.itemId && od.division.id === d.divisionId
                                    );
                                    if (orderL) {
                                      result = orderL;
                                    }
                                    return result;
                                  },
                                  null
                                );
                                const checkItem = itemById.some(
                                  (f: any) => f.item.id === orderDetailRender.item.id
                                );
                                return (
                                  <div key={idx} style={{ paddingBottom: "2.4rem" }}>
                                    <div
                                      className="product-order-card"
                                      style={{ opacity: !checkItem ? 0.5 : 1 }}>
                                      <div className="prod">
                                        <div style={{ display: "flex" }}>
                                          <img
                                            src={
                                              checkLinkImage(
                                                orderDetailRender?.item?.images?.picture
                                              ) ||
                                              renderImageProNull(orderDetailRender?.item.source)
                                            }
                                            alt="product"
                                            onError={renderErrorImageItem(
                                              orderDetailRender?.item.source
                                            )}
                                          />
                                          <div className="name">
                                            {orderDetailRender?.item?.shortName}
                                            <div className="code">
                                              {orderDetailRender?.item?.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="price text">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(d.unitPrice)
                                          : TEXT_ASTERISK}{" "}
                                        đ/<span>{d.saleUnit}</span>
                                      </div>
                                      <div className="quan">
                                        <QuantityBtnGroup
                                          disableMinusBtn={d.quantity <= 1}
                                          quantity={d.quantity}
                                          onChangeQuantity={(value) => {
                                            onUpdateProdVarQuantityDetail(
                                              d.itemId,
                                              value,
                                              +orderDetailRender?.item?.dimension * value
                                            );
                                          }}
                                          style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                        />
                                      </div>
                                      <div className="unit">
                                        {currencyFormat(d.dimension)}{" "}
                                        <span style={{ textTransform: "lowercase" }}>
                                          {d.baseUoM}
                                        </span>
                                      </div>
                                      <div className="pay">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(d.quantity * +d.unitPrice)
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                      <div
                                        className="ic-del"
                                        onClick={() => {
                                          onUpdateProdVarQuantityDetail(d.itemId, -1);
                                        }}>
                                        <DeleteOutlineIcon
                                          style={{
                                            fontSize: "2.2rem",
                                            marginLeft: "3rem",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {!checkItem && (
                                      <div className="card-null ">
                                        <WarningRoundedIcon />
                                        &ensp;{t("order:PRODUCT_NO_LONGER_AVAILABLE")}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                          {!!selectedVars.length &&
                            selectedVars.map((val, idx: number) => {
                              return (
                                <div
                                  key={idx}
                                  className="product-order-card"
                                  style={{ paddingBottom: "2.4rem" }}>
                                  <div className="prod">
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={
                                          checkLinkImage(val.item.item.images?.picture as any) ||
                                          renderImageProNull(val.item.item.source)
                                        }
                                        alt="product"
                                        onError={renderErrorImageItem(val.item.item.source)}
                                      />
                                      <div className="name">
                                        {val.item.item.shortName}
                                        <div className="code">
                                          {val.item.item.name}{" "}
                                          <div className="OpenInNewIcon" onClick={() => {}}></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="price text">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(val.item.value)
                                      : TEXT_ASTERISK}{" "}
                                    đ/
                                    <span>{val.item.baseUoM}</span>
                                  </div>
                                  <div className="quan">
                                    <QuantityBtnGroup
                                      disableMinusBtn={val.quantity <= 1}
                                      quantity={val.quantity}
                                      onChangeQuantity={(value) => {
                                        onUpdateProdVarQuantity(val.item.item.id, value);
                                      }}
                                      style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                    />
                                  </div>
                                  <div className="unit">
                                    {currencyFormat(Number(val.item.item.dimension) * val.quantity)}{" "}
                                    <span style={{ textTransform: "lowercase" }}>
                                      {val.item.item.baseUoM}
                                    </span>
                                  </div>
                                  <div className="pay">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(val.quantity * val.item.value)
                                      : TEXT_ASTERISK}
                                    &ensp;đ
                                  </div>
                                  <div
                                    className="ic-del"
                                    onClick={() => {
                                      onUpdateProdVarQuantity(val.item.item.id, -1);
                                    }}>
                                    <DeleteOutlineIcon
                                      style={{
                                        fontSize: "2.2rem",
                                        marginLeft: "3rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            spacing={6}>
                            <Grid item xs={3}>
                              <div className="form-item">
                                <div className="form-label">{t("order:DELIVERY_DATE")}</div>
                                <Field
                                  name="requiredDate"
                                  // validate={FormUtil.Rule.required(
                                  //   "Bạn vẫn chưa chọn ngày giao hàng"
                                  // )}
                                >
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          okLabel={t("order:SELECT")}
                                          cancelLabel={t("CANCEL")}
                                          fullWidth
                                          disablePast
                                          disableToolbar
                                          InputProps={{ readOnly: true }}
                                          inputVariant="outlined"
                                          format="dd-MM-yyyy"
                                          className="form-text-field date"
                                          value={input.value || moment().format("YYYY-MM-DD")}
                                          onChange={(value) => {
                                            if (value) {
                                              input.onChange(value);
                                            }
                                          }}
                                          inputProps={{ className: "input" }}
                                          keyboardIcon={
                                            <EventIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[22, 22]}
                                            />
                                          }
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                        />
                                      </MuiPickersUtilsProvider>
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("order:DELIVERY_ADDRESS")}</div>
                                <Select
                                  value={shippingAddress}
                                  onChange={(e) => {
                                    setShippingAddress(e.target.value);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  renderValue={
                                    shippingAddress !== ""
                                      ? undefined
                                      : () =>
                                          shipping?.length > 0 ? (
                                            !visibleHeader ? (
                                              <span style={{ color: "#9e9e9e" }}>
                                                -{" "}
                                                {t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                              </span>
                                            ) : (
                                              shipping[0]?.address
                                            )
                                          ) : (
                                            <span style={{ color: "#9e9e9e" }}>
                                              -{" "}
                                              {t("SELECT_BY_NAME", {
                                                NAME: t("order:DELIVERY_ADDRESS"),
                                              })}
                                            </span>
                                          )
                                  }
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {shipping?.length > 0 &&
                                    shipping.map((ship: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={ship.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                            whiteSpace: "normal",
                                          }}>
                                          {ship.address}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </Grid>
                            <Grid item xs={3} className="info-order">
                              <div className="form-item">
                                <div className="form-label">{t("VEHICLE_NUMBER")}</div>
                                <Field name="vehicleNumber">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("VEHICLE_NUMBER"),
                                        })}
                                        color="success"
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("order:RECEIVING_ADDRESS")}</div>
                                <Select
                                  value={salesOfficeId}
                                  onChange={(e: any) => {
                                    setSalesOfficeId(e.target.value);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  renderValue={
                                    salesOfficeId !== ""
                                      ? undefined
                                      : () => (
                                          <span style={{ color: "#9e9e9e" }}>
                                            -{" "}
                                            {t("SELECT_BY_NAME", {
                                              NAME: t("order:RECEIVING_ADDRESS"),
                                            })}
                                          </span>
                                        )
                                  }
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {salesOffice?.length > 0 &&
                                    salesOffice.map((office: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={office.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {office.description}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                            </Grid>
                            <Grid item xs={6} className="total">
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("NOTES")}</div>
                                <Field name="note">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        fullWidth
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("NOTES"),
                                        })}
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        variant="outlined"
                                        onChange={(e) => input.onChange(e.target.value)}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                              <div className="row">
                                {t("TOTAL_WEIGHT")}
                                <div className="price">{currencyFormat(totalWeight)} kg</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(ordDetail ? total + totalDetail : total)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={
                            (ordDetail && orderDetail.length) || selectedVars.length
                              ? "show"
                              : "hide"
                          }>
                          {/* {ordDetail && (orderDetail.length || selectedVars.length) && (
                            <div
                              className="btn-cancel-style btn__text-cancel"
                              onClick={() => {
                                setNotiOrderCancel(true);
                              }}>
                              Hủy
                            </div>
                          )} */}
                          <button
                            disabled={!orderDetail?.length && !selectedVars?.length}
                            onClick={() => {
                              setOrdDraft(true);
                            }}
                            type="submit"
                            ref={submitRef}
                            className="btn-confirm-style btn-border btn--transparent"
                            style={{ marginLeft: "4rem" }}>
                            {t("order:SAVE_CART")}
                          </button>
                          <button
                            disabled={!orderDetail?.length && !selectedVars?.length}
                            onClick={() => {
                              setLoading(true);
                            }}
                            type="submit"
                            ref={submitRef}
                            className="btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("order:PLACE_ORDER")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>
            <Grid item xs={3} className="content-right">
              {!ordDetail && !visibleHeader ? (
                <div className="new-order">
                  <img src={NewOrder} alt="new-order" />
                  <span style={{ fontWeight: 700, fontSize: "1.8rem", lineHeight: "2.7rem" }}>
                    {t("order:ENTER_CUSTOMER_INFORMATION")}
                  </span>
                  <span>{t("order:START_CREATING_ORDER")}</span>
                </div>
              ) : (
                <>
                  <TextField
                    placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                    variant="outlined"
                    color="success"
                    className={"search-border-style"}
                    inputProps={{ className: "input" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="large" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                  {states.itemGroups && states.itemGroups.length > 1 && (
                    <div className="gr-item">
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={1}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}>
                        {[
                          { id: "", name: t("ALL") },
                          { id: "0", name: t("order:INTEREST"), image: ItemImg },
                          ...states.itemGroups,
                          // { id: "null", name: "Khác" },
                        ].map((cate: any) => {
                          return (
                            <SwiperSlide key={cate?.id}>
                              <div
                                className={
                                  cate?.id === itemGroupId ? "btn-gr__item active" : "btn-gr__item"
                                }
                                onClick={() => {
                                  setItemGroupId(cate.id);
                                }}>
                                {cate.id !== "" && (
                                  <img
                                    src={
                                      cate.id !== "0" ? checkLinkImage(cate?.image) : cate?.image
                                    }
                                    alt={"icon"}
                                  />
                                )}
                                <div className="label">{cate?.name.toLowerCase()}</div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <button className="swiper-button-prev button-prev">
                        <ArrowLeftSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                      </button>
                      <button className="swiper-button-next button-next">
                        <ArrowRightSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                      </button>
                    </div>
                  )}

                  {states.loading || (states.fetching && (searchValue || itemGroupId)) ? (
                    <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                  ) : (
                    <div className="product-list">
                      {states.data?.length > 0 ? (
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={() =>
                            fetchData({
                              page: states.page + 1,
                              limit: 20,
                              source: "feed",
                              customerId: ordDetail ? order?.orders[0].customer?.id : value?.id,
                              searchValue,
                              itemGroupId: itemGroupId ? itemGroupId : undefined,
                            })
                          }
                          hasMore={hasMore}
                          useWindow={false}>
                          {states.data?.map((pro: any, index: number) => {
                            const act = selectedVars.find(
                              (item) => item.item.item.id === pro.item.id
                            );
                            const actOrd = []
                              .concat(...orderDetail)
                              .find((item: any) => item.itemId === pro.item.id);
                            return (
                              <ProductItem
                                class={act || actOrd}
                                key={pro.item.code + index}
                                onClick={() => onUpdateProdVarQuantity(pro.item.id, 1, pro, true)}
                                image={pro.item.images?.picture}
                                shortName={pro.item.shortName}
                                name={pro.item.name}
                                value={
                                  checkPermissionViewPrice
                                    ? currencyFormat(pro.value)
                                    : TEXT_ASTERISK
                                }
                                baseUoM={pro.baseUoM}
                                source="feed"
                                interestedItem={pro?.item?.interestedItem}
                                color={pro.item?.color}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}>
                          <img src={ProNull} alt="search" width={190} height={178} />
                          <div
                            style={{
                              fontSize: "1.8rem",
                              fontWeight: "bold",
                              color: "#536380",
                              lineHeight: "2.7rem",
                            }}>
                            {t("order:PRODUCT_NOT_FOUND")}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>

        {loading && (
          <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
            <Loading />
          </Backdrop>
        )}

        <ResultOrder
          sourceType="feed"
          visible={notiOrder.visible}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
        />

        <Notification
          visible={notiOrderCancel}
          title={t("order:CANCEL_CART")}
          subTitle={
            <span
              dangerouslySetInnerHTML={{ __html: t("order:ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }}
            />
          }
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  setNotiOrderCancel(false);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  history.push(APP_ROUTES.ORDER_FEED);
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            setNotiOrderCancel(false);
          }}
        />

        {/*Popup khong co quyen truy cap, state (boolean) la permision*/}
        {permission && <NotAccess />}
      </div>
    </Dialog>
  );
};

export default OrderFeedModal;
