import { z } from "zod";

export const vehicleSchema = z.object({
  vehicleType: z.string({
    message: "Nhập loại xe",
  }).min(1, { message: "Nhập loại xe" }),
  vehicleInfo: z.string({
    message: "Nhập biển số xe",
  }).min(6, { message: "Biển số xe phải có ít nhất 6 ký tự" }),
  name: z.string().optional(),
  idCard: z
    .string({
      message: "Nhập số CMND/CCCD",
    })
    .min(9, { message: "Số CMND/CCCD phải có ít nhất 9 ký tự" })
    .max(12, { message: "Số CMND/CCCD phải có nhiều nhất 12 ký tự" })
    .regex(/^\d+$/, "Số CMND/CCCD phải là số"),
  phoneNumber: z
    .string({
      message: "Nhập số điện thoại",
    })
    .min(10, { message: "Số điện thoại phải có ít nhất 10 ký tự" })
    .max(11, { message: "Số điện thoại phải có nhiều nhất 11 ký tự" })
    .regex(/^\d+$/, "Số điện thoại phải là số"),
  vehicleLoadCapacity: z
    .string().regex(/^\d+$/, "Tải trọng phải là số")
    .nullable()
    .optional(),
  vehicleLoadCapacityRegistered: z
    .string().regex(/^\d+$/, "Tải trọng đăng kiểm phải là số")
    .nullable()
    .optional(),
  vehicleCategory: z.string().nullable().optional(),
  isActive: z.boolean().optional(),
});

export type VehiclePayload = z.infer<typeof vehicleSchema>;