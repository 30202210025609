// import { BaseEntity, Nullable } from "./shared";

export type PermissionAvailableModal = {
  id: number;
  name: string;
  image: string | null;
  order: number;
  createdAt: string;
  updatedAt: string;
  permissionMasters: [
    {
      id: number;
      name: string;
      order: 1;
      code: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }
  ];
};

export type RoleModal = {
  id: number;
  position: string;
  permissions: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  accounts: number;
  modules: number;
};

export enum RoleType {
  EMPLOYEE = "employee",
  CUSTOMER = "customer",
}
