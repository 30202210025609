import { IconBase, IconComponent } from "@Core/Icons";

export const NewsIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37054 0.9294C1.68242 0.792853 2.04554 0.853828 2.2957 1.08475L4.28575 2.92171L6.27579 1.08475C6.60413 0.781669 7.11022 0.781669 7.43856 1.08475L9.42861 2.92171L11.4187 1.08475C11.747 0.781669 12.2531 0.781669 12.5814 1.08475L14.5715 2.92171L16.5615 1.08475C16.8117 0.853828 17.1748 0.792853 17.4867 0.9294C17.7985 1.06595 18 1.37413 18 1.71458V19.1212C18 20.4233 18.503 20.9297 18.8778 21.1604C19.0925 21.2925 19.3135 21.3618 19.4848 21.3969C19.569 21.4142 19.6366 21.4222 19.6789 21.426C19.6888 21.4268 19.6972 21.4275 19.704 21.4279C19.7116 21.4284 19.7172 21.4287 19.7206 21.4288C19.721 21.4288 19.7213 21.4288 19.7216 21.4288C20.406 21.4268 20.7839 21.1406 21.0288 20.7592C21.305 20.3292 21.4286 19.7358 21.4286 19.2003V12.0662H19.7143C19.2409 12.0662 18.8572 11.6825 18.8572 11.2091C18.8572 10.7357 19.2409 10.3519 19.7143 10.3519H21.4286C22.3754 10.3519 23.1429 11.1194 23.1429 12.0662V19.2003C23.1429 19.9429 22.9808 20.8923 22.4712 21.6857C21.9313 22.5262 21.0253 23.1431 19.7143 23.1431H5.71432C4.3093 23.1431 3.08069 22.7731 2.19415 21.9752C1.29514 21.1661 0.857178 20.0153 0.857178 18.686V1.71458C0.857178 1.37413 1.05867 1.06595 1.37054 0.9294ZM16.8298 21.4289C16.4979 20.8473 16.2857 20.0908 16.2857 19.1212V3.67228L15.1528 4.71804C14.8245 5.02112 14.3184 5.02112 13.9901 4.71804L12 2.88107L10.01 4.71804C9.68165 5.02112 9.17556 5.02112 8.84722 4.71804L6.85718 2.88107L4.86713 4.71804C4.53879 5.02112 4.0327 5.02112 3.70437 4.71804L2.57146 3.67228V18.686C2.57146 19.629 2.87113 20.2782 3.34094 20.701C3.82322 21.135 4.59461 21.4289 5.71432 21.4289H16.8298Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14282 8.57199C5.14282 8.0986 5.52658 7.71484 5.99997 7.71484H12C12.4734 7.71484 12.8571 8.0986 12.8571 8.57199C12.8571 9.04537 12.4734 9.42913 12 9.42913H5.99997C5.52658 9.42913 5.14282 9.04537 5.14282 8.57199ZM5.14282 12.4291C5.14282 11.9557 5.52658 11.572 5.99997 11.572H12C12.4734 11.572 12.8571 11.9557 12.8571 12.4291C12.8571 12.9025 12.4734 13.2863 12 13.2863H5.99997C5.52658 13.2863 5.14282 12.9025 5.14282 12.4291ZM5.14282 16.2863C5.14282 15.8129 5.52658 15.4291 5.99997 15.4291H9.42854C9.90192 15.4291 10.2857 15.8129 10.2857 16.2863C10.2857 16.7597 9.90192 17.1434 9.42854 17.1434H5.99997C5.52658 17.1434 5.14282 16.7597 5.14282 16.2863Z"
        fill={props.color}
      />
    </IconBase>
  );
};
