import moment from "moment";
import { useCallback, useState } from "react";

type TableFilter = {
  searchValue?: string;
  from?: string;
  to?: string;
  isInternal?: boolean;
  page?: number;
  limit?: number;
  filter?: string | number;
};

type UseTableFilterProps = {
  initialValues: TableFilter;
};

export const useTableFilter = ({ initialValues }: UseTableFilterProps) => {
  const [tableFilter, setTableFilter] = useState<TableFilter>(initialValues);

  const onSearch = useCallback((value: string) => {
    setTableFilter({ ...tableFilter, searchValue: value });
  }, [tableFilter]);

  const onChangeFilter = useCallback((value: string | number) => {
    setTableFilter({ ...tableFilter, filter: value });
  }, [tableFilter]);

  const onChangeDateRange = useCallback((from: moment.Moment, to: moment.Moment) => {
    setTableFilter({
      ...tableFilter,
      from: from.toISOString(),
      to: to.toISOString(),
    });
  }, [tableFilter]);

  return {
    tableFilter,
    onSearch,
    onChangeFilter,
    onChangeDateRange,
    onSet: setTableFilter,
  };
};
