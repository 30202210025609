/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Deposit from "./deposit";
import "./index.scss";
import Vehicle from "./vehicle";

const DepositVehicle: React.FC = () => {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  return (
    <div className="deposit-vehicle-wrapper">
        <Navbar />
        <div style={{ display: searchQuery.get("type") === "2" ? "block" : "none", height: "100%" }}>
          <Vehicle />
        </div>
        <div style={{ display: searchQuery.get("type") !== "2" ? "grid" : "none", height: "100%" }}>
          <Deposit />
        </div>
      </div>
  );
};

export default DepositVehicle;
