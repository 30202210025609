import { IconBase, IconComponent } from "@Core/Icons";

export const AccountIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1778 2C9.64767 2 8.40724 3.24017 8.40724 4.77C8.40724 6.29983 9.64767 7.54 11.1778 7.54C12.708 7.54 13.9484 6.29983 13.9484 4.77C13.9484 3.24017 12.708 2 11.1778 2ZM6.40681 4.77C6.40681 2.1356 8.54287 0 11.1778 0C13.8128 0 15.9488 2.1356 15.9488 4.77C15.9488 7.4044 13.8128 9.54 11.1778 9.54C8.54287 9.54 6.40681 7.4044 6.40681 4.77Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1069 17.9681C15.0201 17.9681 14.9497 18.0384 14.9497 18.1251V22.1466C14.9497 22.2334 15.0201 22.3037 15.1069 22.3037H22.146C22.2327 22.3037 22.3031 22.2334 22.3031 22.1466V18.1251C22.3031 18.0384 22.2327 17.9681 22.146 17.9681H15.1069ZM13.2528 18.1251C13.2528 17.1014 14.0829 16.2715 15.1069 16.2715H22.146C23.1699 16.2715 24 17.1014 24 18.1251V22.1466C24 23.1704 23.1699 24.0003 22.146 24.0003H15.1069C14.0829 24.0003 13.2528 23.1704 13.2528 22.1466V18.1251Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1177 12.9466C16.9427 12.9466 16.7748 13.0161 16.6511 13.1398C16.5273 13.2635 16.4578 13.4314 16.4578 13.6063V16.1198C16.4578 16.5883 16.0779 16.9681 15.6093 16.9681C15.1407 16.9681 14.7609 16.5883 14.7609 16.1198V13.6063C14.7609 12.9814 15.0092 12.3821 15.4512 11.9402C15.8932 11.4983 16.4926 11.25 17.1177 11.25H20.1345C20.7595 11.25 21.359 11.4983 21.801 11.9402C22.243 12.3821 22.4913 12.9814 22.4913 13.6063V14.1517C22.4913 14.6202 22.1114 15 21.6428 15C21.1742 15 20.7944 14.6202 20.7944 14.1517V13.6063C20.7944 13.4314 20.7249 13.2635 20.6011 13.1398C20.4773 13.0161 20.3095 12.9466 20.1345 12.9466H17.1177Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5572 11.8152C7.88783 11.2768 9.31321 11 10.7522 11C11.3046 11 11.7524 11.4477 11.7524 12C11.7524 12.5523 11.3046 13 10.7522 13C9.56954 13 8.39902 13.2276 7.30764 13.6691C6.2163 14.1107 5.22617 14.7573 4.3932 15.571C3.44997 16.4923 2.98694 17.3495 2.4895 18.8203C2.12148 19.9084 2.00063 21.2827 2.00042 21.7488C2.00046 21.7495 2.00069 21.7539 2.00173 21.7613C2.00317 21.7716 2.00565 21.784 2.00934 21.7969C2.01327 21.8106 2.01744 21.8212 2.02094 21.8287C2.03312 21.8309 2.05353 21.8333 2.08377 21.8333C2.63618 21.8333 3.08399 22.281 3.08399 22.8333C3.08399 23.3856 2.63618 23.8333 2.08377 23.8333C1.32436 23.8333 0.753189 23.4946 0.405218 23.0114C0.0939674 22.5792 0 22.0906 0 21.75C0 21.1317 0.13831 19.5285 0.594485 18.1797C1.1561 16.5191 1.75632 15.3506 2.99522 14.1404C4.01598 13.1433 5.22654 12.3536 6.5572 11.8152Z"
        fill={props.color}
      />
    </IconBase>
  );
};
