import { OrderService } from "@Services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

export const useOrderDetail = (orderId: number) => {
  const { data, ...rest } = useQuery({
    queryKey: ["order-detail", orderId],
    queryFn: () => {
      if (!orderId) {
        return;
      }
      return OrderService.getOrderById(orderId);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    orderDetail: data?.data?.data,
    ...rest,
  };
};

export const useGetOrderCombination = (orderId: number) => {
  const { data, ...rest } = useQuery({
    queryKey: ["order-combination", orderId],
    queryFn: () => {
      if (!orderId) {
        return;
      }
      return OrderService.getOrderCombinationById(orderId);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    orderCombinations: _.get(data, "data", []),
    ...rest,
  };
};

export const useUpdateOrderCombination = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["update-order-combination"],
    mutationFn: ({ orderId, data }: { orderId: number; data: number[] }) => {
      return OrderService.updateOrderCombination(orderId, data);
    },
    onSuccess: (_, { orderId, data }) => {
      data.forEach((item) => {
        queryClient.invalidateQueries(["order-combination", item]);
      });

      queryClient.invalidateQueries(["order-combination", orderId]);
      queryClient.invalidateQueries(["order-detail", orderId]);
    },
  });
};
