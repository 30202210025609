import React from "react";
import { IconBase, IconComponent } from "@Core/Icons";

export const SearchDetailIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path d="M15.1401 4H5V21H20V8.86529L15.1401 4Z" fill="#0A6836" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5385 9.88893H11.4616V10.7779H13.5385V9.88893ZM10.7693 9V11.6668H14.2308V9H10.7693Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3076 15.2171H14.2306V16.106H16.3076V15.2171ZM13.5383 14.3281V16.9949H16.9999V14.3281H13.5383Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7692 15.2171H8.69231V16.106H10.7692V15.2171ZM8 14.3281V16.9949H11.4615V14.3281H8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1539 13.4497V11.6719H12.8462V13.4497H12.1539Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6153 13.4436L9.38452 13.4436V12.5547L15.6153 12.5547V13.4436Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3846 14.3342V13.4453H10.0769V14.3342H9.3846Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9232 14.3342V13.4453H15.6155V14.3342H14.9232Z"
        fill="white"
      />
    </IconBase>
  );
};
