/* eslint-disable */
import ImageUpload from "@Components/ImageUpload";
import ModalFooter from "@Components/ModalFooter";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ConditionQuantity, REGEX } from "@Constants";
import { useBreakpoints } from "@Hooks";
import { FormUploadImageModel, UploadedImageModel } from "@Models";
import { ProductService } from "@Services";
import { FormDataUtil, FormUtil, checkLinkImage } from "@Utils";
import { makeStyles } from "@material-ui/core/styles";
import {
  ClearRounded as ClearRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Chip,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./ProductModal.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
    fontSize: "1.6rem",
    fontFamily: "SVN-Gotham",
  },
}));

const ProductModal: React.FC = () => {
  const { t } = useTranslation(["translation", "product"]);
  const mounted = useRef(false);
  const history = useHistory();
  const { productId }: { productId: string } = useParams();
  const [product, setProduct] = useState<any>(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  const [quantity, setQuantity] = useState<string>(ConditionQuantity[0].value);
  const [dimension, setDimension] = useState<string>("");
  const [valdimension, setValDimension] = useState<any>([]);
  const breakpoints = useBreakpoints();
  const [notiErrorUpdate, setNotiErrorUpdate] = useState<boolean>(false);
  const [notiDelete, setNotiDelete] = useState<string>("");
  const [notUpdateItem, setNotUpdateItem] = useState<any[]>([]);
  const [deleteDimension, setDeleteDimension] = useState<any[]>([]);
  const [isErrorDimen, setErrorDimen] = useState<string>("");

  useEffect(() => {
    setDeleteDimension([]);
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const getProductById: () => Promise<void> = async () => {
    try {
      const response = await ProductService.getProductById(productId);
      if (mounted.current) {
        setProduct(() => response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    product && product.conditionQuantity !== null
      ? setQuantity(product?.conditionQuantity?.replace(/\d/g, ""))
      : setQuantity(ConditionQuantity[0].value);
    // product &&
    //   product.dimension &&
    //   setDimension(
    //     Array.isArray(product.dimension)
    //       ? product?.dimension
    //           .map((d: any) => {
    //             return d[1] === "null" ? d[0] + "-*" : d[0] + "-" + d[1];
    //           })
    //           .toString()
    //       : [product.dimension.toString()]
    //   );

    product &&
      product.dimension &&
      setNotUpdateItem(
        Array.isArray(product.dimension)
          ? product?.dimension.map((d: any) => {
              if (d[1] === "null") {
                return d[0] + "-*";
              } else if (d[0] === "null") {
                return "*-" + d[1];
              } else {
                return d[0] + "-" + d[1];
              }
            })
          : product?.dimension === 0
          ? []
          : [product.dimension.toString()]
      );
  }, [product]);

  useEffect(() => {
    dimension !== "" && dimension.includes(",")
      ? setValDimension(dimension?.split(","))
      : setDimension(dimension);
    setErrorDimen("");
  }, [dimension]);

  useEffect(() => {
    setAvatars(
      product?.image
        ? [
            {
              id: "",
              src: `${checkLinkImage(product?.image.picture)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [product]);

  const [valData, setValData] = useState<any>();

  useEffect(() => {
    setValData(product?.conditionQuantity?.match(/[0-9]+/g));
  }, [product]);

  const convertDataProduct = product && {
    code: product.code,
    saleUnit: product.saleUnit,
    name: product.name,
    baseUoM: product.baseUoM,
    dimension: dimension,
    val1: valData?.[0],
    val2: !valData?.[1] ? undefined : valData?.[1],
    grossWeight: product.grossWeight,
    netWeight: product.netWeight
  };

  // delete chip component
  const handleDelete = (item: any) => {
    const newSelectedItem = [...valdimension];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    valdimension && setValDimension(newSelectedItem);
    setDimension(newSelectedItem.toString());
  };

  const checkDimension = (input: string) => {
    if (!isNaN(+input)) {
      return true;
    } else {
      const arr = input.split(",");
      return arr.every((a) => /\d+-(\d+)|(\*)/.test(a.trim()));
    }
  };

  // const createProductFarm = async (formData: FormData) => {
  //   try {
  //     await ProductService.createProductFarm(formData);
  //     enqueueSnackbar("Tạo sản phẩm thành công!", {
  //       variant: "success",
  //     });
  //     window.location.reload();
  //   } catch (error) {
  //     enqueueSnackbar("Tạo sản phẩm thất bại!", {
  //       variant: "error",
  //     });
  //   }
  // };

  const updateProductFarm = async (formData: FormData) => {
    ProductService.updateProductFarm(productId.toString(), formData)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("product:PRODUCT_UPDATED_SUCCESSFULLY"), {
            variant: "success",
          });
          history.push(APP_ROUTES.PRODUCT_DETAIL.replace(":productId", productId));
        } else {
          // do something
          console.log(res);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
        if (error.response.data.message === "DUPLICATED_DIMENSION") {
          setErrorDimen(t("product:DUPLICATE_WEIGHT_RANGE"));
        } else {
          enqueueSnackbar(t("product:PRODUCT_UPDATED_FAILED"), {
            variant: "error",
          });
        }
      });
  };

  const submitForm = async (values: any) => {
    const formData = FormDataUtil.convertJsonToFormData(values);
    let isError = false;

    if (dimension.includes("-")) {
      const dimen = dimension.replaceAll(" ", "").split(",");
      const dimensionFormat = dimen.map((d) => {
        let [from, to] = d.split("-");
        if (from && to) {
          if (from === "*") {
            from = "null";
          } else if (to === "*") {
            to = "null";
          } else if (+from >= +to) {
            setErrorDimen(t("product:WEIGHT_RANGE_NOT_INCORRECT_FORMAT"));
            isError = true;
          }
          // if (d?.replace(/\d/g, "").length > 1)
        } else if (d?.replace(/\d/g, "").length > 1) {
          // enqueueSnackbar("Kiểm tra lại khung trọng lượng", {
          //   variant: "error",
          // });
          setErrorDimen(t("product:WEIGHT_RANGE_NOT_INCORRECT_FORMAT"));
          isError = true;
        } else if (!from || !to) {
          setErrorDimen(t("product:WEIGHT_RANGE_NOT_INCORRECT_FORMAT"));
          isError = true;
        }

        return [from, to];
      });
      if (isError) {
        return;
      }
      formData.set("dimension", JSON.stringify(dimensionFormat));
    } else if (!notUpdateItem.toString().includes("-")) {
      formData.set("dimension", dimension);
      formData.delete("dimension[0]");
    }

    if (+deleteDimension.toString() + "" === "NaN") {
      const dimenDel = deleteDimension.toString().replaceAll(" ", "").split(",");
      const dimensionDelFormat = dimenDel.map((d) => {
        let [from, to] = d.split("-");
        if (from && to) {
          if (to === "*") {
            to = "null";
          }
        }
        return [from, to];
      });
      if (isError) {
        return;
      }
      formData.set("deleteDimension", JSON.stringify(dimensionDelFormat));
    } else {
      formData.set("deleteDimension", deleteDimension.toString());
    }

    if (values.val1 && values.val2 !== undefined) {
      formData.set("conditionQuantity", values.val1 + quantity + values.val2);
    } else formData.set("conditionQuantity", quantity + values.val1);

    if (avatars[0] && avatars[0].file) formData.append("image", avatars[0].file);

    if (product && !isErrorDimen) return updateProductFarm(formData);
    // return createProductFarm(formData);
    return;
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="product-modal">
        <SimpleModalHeader
          title={product ? t("product:EDIT_PRODUCT") : t("product:CREATE_PRODUCT")}
          onClose={() => {
            product
              ? history.push(APP_ROUTES.PRODUCT_DETAIL.replace(":productId", product.id))
              : history.push(APP_ROUTES.PRODUCT_FARM);
          }}
        />
        <div className="content">
          <ImageUpload
            className="product-form-avatar"
            fileList={avatars}
            max={1}
            onChange={(data: UploadedImageModel) => {
              setAvatars([
                {
                  id: uuidv4(),
                  src: data.fileStr,
                  alt: data.alt || "",
                  file: data.file,
                },
              ]);
            }}
            onDelete={() => {
              setAvatars([]);
            }}
            config={{ uploadModal: true }}
          />
          <Form
            validate={(values) => {
              const errors: Record<string, unknown> = {};
              if (values.val1 && values.val2) {
                if (+values.val1 > +values.val2 || +values.val1 === +values.val2) {
                  errors.val1 = t("product:FIRST_VALUE_LESS_THAN_SECOND_VALUE");
                } else {
                }
                return errors;
              }
            }}
            onSubmit={async (values) => submitForm(values)}
            initialValues={convertDataProduct}>
            {({ handleSubmit }) => {
              return (
                <form id="product" onSubmit={handleSubmit} className="product-form">
                  <Grid container justifyContent="center"></Grid>
                  <div className="title">{t("GENERAL_INFORMATION")}</div>
                  <Grid container spacing={7}>
                    <Grid item xs={6}>
                      <div className="form-item">
                        <div className="form-label">{t("PRODUCT_CODE")}</div>
                        <Field
                          name="code"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("PRODUCT_CODE") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                disabled={product}
                                placeholder={t("INPUT_BY_NAME", { NAME: t("PRODUCT_CODE") })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item">
                        <div className="form-label">{t("product:SALES_UNIT")}</div>
                        <Field
                          name="saleUnit"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("product:SALES_UNIT") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", { NAME: t("product:SALES_UNIT") })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="form-label">{t("product:NET_WEIGHT")}</div>
                        <Field
                          name="netWeight"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("product:NET_WEIGHT") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                disabled={product}
                                placeholder={t("INPUT_BY_NAME", { NAME: t("product:NET_WEIGHT") })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="form-label">{t("WEIGHT_RANGE")}</div>
                        <Field
                          name="dimension"
                          validate={FormUtil.composeValidators([
                            FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                              errorMessage: t("product:WEIGHT_RANGE_NOT_INCORRECT_FORMAT"),
                            }),
                          ])}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                // disabled={
                                //   notUpdateItem.length !== 0 &&
                                //   !notUpdateItem.toString().includes("-")
                                // }
                                value={dimension}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setDimension(e.target.value);
                                  checkDimension(input.value);
                                  setValDimension(e.target.value.split(","));
                                }}
                                placeholder={
                                  product?.dimension?.length === 0
                                    ? t("INPUT_BY_NAME", { NAME: t("WEIGHT_RANGE") })
                                    : ""
                                }
                                color="success"
                                className="form-text-field form-sel-field"
                                inputProps={{
                                  className: valdimension.length > 0 ? "input-dimen" : "input",
                                }}
                                InputProps={{
                                  startAdornment: (
                                    <>
                                      {notUpdateItem.map((i: any, index: number) => (
                                        <Chip
                                          key={index}
                                          label={i}
                                          size={breakpoints.xl ? "medium" : "small"}
                                          className={classes.chip}
                                          onDelete={() => {
                                            setNotiDelete(i);
                                          }}
                                          deleteIcon={
                                            <ClearRoundedIcon
                                              style={{ fontSize: "1.6rem", color: "#838283" }}
                                            />
                                          }
                                        />
                                      ))}
                                      {valdimension.length > 0 &&
                                        valdimension[0] !== "" &&
                                        valdimension.map((item: any) => (
                                          <Chip
                                            key={item}
                                            tabIndex={-1}
                                            label={item}
                                            className={classes.chip}
                                            size={breakpoints.xl ? "medium" : "small"}
                                            // disabled={notUpdateItem.includes(item)}
                                            onDelete={() => {
                                              handleDelete(item);
                                            }}
                                            deleteIcon={
                                              <ClearRoundedIcon
                                                style={{ fontSize: "1.6rem", color: "#838283" }}
                                              />
                                            }
                                            style={{
                                              backgroundColor: isErrorDimen ? "#FFE0E1" : undefined,
                                              color: isErrorDimen ? "#B8292F" : undefined,
                                            }}
                                          />
                                        ))}
                                    </>
                                  ),
                                }}
                                autoComplete="off"
                                helperText={
                                  isErrorDimen ? (
                                    isErrorDimen
                                  ) : meta.touched && meta.error ? (
                                    meta.touched ? (
                                      meta.error
                                    ) : (
                                      <span
                                        style={{ color: "#838283", fontFamily: "SVN-Gotham" }}
                                        dangerouslySetInnerHTML={{
                                          __html: t("product:ENTER_CORRECT_VALUE_RANGE"),
                                        }}
                                      />
                                    )
                                  ) : (
                                    <span
                                      style={{ color: "#838283", fontFamily: "SVN-Gotham" }}
                                      dangerouslySetInnerHTML={{
                                        __html: t("product:ENTER_CORRECT_VALUE_RANGE"),
                                      }}
                                    />
                                  )
                                }
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-item">
                        <div className="form-label">{t("product:PRODUCT_NAME")}</div>
                        <Field
                          name="name"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("product:PRODUCT_NAME") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("product:PRODUCT_NAME"),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item">
                        <div className="form-label">{t("product:BASIC_UNIT_MEASUREMENT")}</div>
                        <Field
                          name="baseUoM"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("product:BASIC_UNIT_MEASUREMENT") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("product:BASIC_UNIT_MEASUREMENT"),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      
                      <div className="form-item">
                        <div className="form-label">{t("product:GROSS_WEIGHT")}</div>
                        <Field
                          name="grossWeight"
                          validate={FormUtil.Rule.required(
                            t("NOT_INPUT_BY_NAME", { NAME: t("product:GROSS_WEIGHT") })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                disabled={product}
                                placeholder={t("INPUT_BY_NAME", { NAME: t("product:GROSS_WEIGHT") })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="condition">
                        <div className="form-item">
                          <div className="form-label">{t("product:QUANTITY_LIMIT")}</div>
                          <FormControl>
                            <Select
                              value={quantity}
                              onChange={(e) => {
                                setQuantity(e.target.value as string);
                                setValData([]);
                              }}
                              fullWidth
                              input={<OutlinedInput className="form-text-field" color="success" />}
                              variant="outlined"
                              renderValue={
                                quantity !== ""
                                  ? undefined
                                  : () => (
                                      <span className="form__placeholder">
                                        {t("product:SELECT_RANGE")}
                                      </span>
                                    )
                              }
                              displayEmpty
                              className="form-select-field"
                              MenuProps={{
                                className: "select-menu-list",
                              }}>
                              {ConditionQuantity.map((q: any) => {
                                return (
                                  <MenuItem
                                    key={q.value}
                                    value={q.value}
                                    style={{
                                      width: "100%",
                                    }}>
                                    {q.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        &emsp;
                        <div className="form-item">
                          <div className="form-label">{t("VALUE")}</div>
                          <div style={{ display: "flex" }}>
                            <Field
                              name="val1"
                              validate={FormUtil.Rule.required(
                                t("NOT_INPUT_BY_NAME", { NAME: t("VALUE") })
                              )}>
                              {({ input, meta, ...rest }) => {
                                return (
                                  <TextField
                                    {...input}
                                    {...rest}
                                    type="number"
                                    placeholder="00"
                                    color="success"
                                    className="form-text-field"
                                    inputProps={{ className: "input" }}
                                    onChange={(e) => input.onChange(e.target.value)}
                                    helperText={meta.touched ? meta.error : ""}
                                    error={meta.error && meta.touched}
                                    autoComplete="false"
                                  />
                                );
                              }}
                            </Field>
                            {(quantity[1] === "&" || quantity[1] === "|") && (
                              <>
                                &emsp;
                                <div style={{ color: "#D6D5D6", marginTop: "1.5rem" }}>&mdash;</div>
                                &emsp;
                                <Field
                                  name="val2"
                                  validate={FormUtil.Rule.required(
                                    t("NOT_INPUT_BY_NAME", { NAME: t("VALUE") })
                                  )}>
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        type="number"
                                        placeholder="00"
                                        color="success"
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        onChange={(e) => input.onChange(e.target.value)}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        autoComplete="false"
                                      />
                                    );
                                  }}
                                </Field>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="product"
          showDeleteButton={false}
          handleDeleteAction={() => {
            //   toggleDeleteModal();
          }}
          handleCancelAction={() =>
            history.push(APP_ROUTES.PRODUCT_DETAIL.replace(":productId", productId))
          }
          title={product ? t("UPDATE") : t("CREATE")}
        />
      </div>

      <Notification
        visible={notiErrorUpdate}
        title={t("UPDATE_FAILED")}
        subTitle={<span>{t("product:CANNOT_UPDATE_INFORMATION_PRODUCT")}</span>}
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            style={{ borderRadius: "10rem" }}
            className="btn-confirm-style"
            onClick={() => {
              setNotiErrorUpdate(false);
            }}>
            {t("CONFIRM")}
          </button>
        }
        onClose={() => {
          setNotiErrorUpdate(false);
        }}
      />

      <Notification
        visible={notiDelete !== ""}
        title={t("product:DELETE_WEIGHT_RANGE")}
        subTitle={
          <span dangerouslySetInnerHTML={{ __html: t("product:ACTION_AFFECT_ORDER_DATA") }} />
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20rem",
                marginRight: "2rem",
                borderRadius: "3rem",
                height: "4.8rem",
              }}
              onClick={() => {
                setNotiDelete("");
              }}>
              {t("NO")}
            </button>
            <button
              style={{ borderRadius: "3rem", width: "20rem", height: "4.8rem" }}
              className="btn-confirm-style"
              onClick={() => {
                setNotUpdateItem(notUpdateItem.filter((f) => f !== notiDelete));
                deleteDimension.push(notiDelete);
                setNotiDelete("");
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete("");
        }}
      />
    </Dialog>
  );
};
export default ProductModal;
