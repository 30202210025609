export const APP_ROUTES = {
  HOME: "/",
  TEST: "/tests",

  //orders
  ORDER_CREATE: "/order/create",
  ORDER_DETAIL_FEED: "/order-feed/:orderId",
  ORDER_DETAIL_FARM: "/order-farm/detail/:orderId",
  ORDER_DETAIL_VET: "/order-vet/:orderId",
  ORDER_DETAIL_LAB: "/order-lab/:orderId",
  ORDER: "/order",
  ORDER_FEED: "/order-feed",
  ORDER_FARM: "/order-farm",
  ORDER_INTERNAL: "/order-internal",
  ORDER_INTERNAL_DETAIL: "/order-internal-farm/detail/:orderId",

  ORDER_FARM_TRUCK_LOAD: "/order-farm/truck-load",
  ORDER_VET: "/order-vet",
  ORDER_LAB: "/order-lab",

  //farm
  // ORDER_FARM_BREEDING_PIGS: "/order-farm/breeding-pigs",

  ORDER_FARM_MARKET_PIGS: "/order-farm/market-pig",
  ORDER_FARM_CULLED_PIGS: "/order-farm/culled_pigs",
  ORDER_FARM_INTERNAL_PIGS: "/order-farm/internal-pigs",
  ORDER_FARM_BOAR_SEMEN: "/order-farm/boar-semen",

  //plan (kế hoạch đặt hàng)
  PLAN: "/plan",
  PLAN_DETAIL: "/plan-detail/:planId",
  CREATE_PLAN: "/create-plan",
  UPDATE_PLAN: "/update-plan/:planId",
  DRAFT_PLAN: "/draft-plan/:planId",
  RECREATE_PLAN: "/recreate-plan/:planId",

  // SITE_ORDER: "/site-order/:orderId",
  UPDATE_ORDER_DRAFT: "/update-order-draft/:orderId",
  UPDATE_ORDER_DRAFT_FARM: "/update-order-draft-farm/:orderId",
  UPDATE_ORDER_DRAFT_VET: "/update-order-draft-vet/:orderId",
  UPDATE_ORDER_FARM: "/update-order-farm/:orderId",
  UPDATE_ORDER_FEED: "/update-order-feed/:orderId",
  UPDATE_ORDER_VET: "/update-order-vet/:orderId",
  UPDATE_ORDER_LAB: "/update-order-lab/:orderId",
  CREATE_FEED: "/create-feed",
  CREATE_FEED_ID: "/create-feed/:orderId",
  CREATE_FARM: "/create-farm",
  CREATE_BOAR_SEMEN_ORDER: "/create-boar-semen-order",
  CREATE_FARM_ID: "/create-farm/:orderId",
  CREATE_VET: "/create-vet",
  CREATE_LAB: "/create-lab",
  UPDATE_ORDER_LAB_SAMPLE: "/update-order-lab-sample/:orderId",

  //customer
  CUSTOMER: "/customer",
  CUSTOMER_FEED: "/customer-feed",
  CUSTOMER_FARM: "/customer-farm",
  CUSTOMER_VET: "/customer-vet",
  CUSTOMER_LAB: "/customer-lab",
  CUSTOMER_DETAIL_FEED: "/customer-feed/:customerId",
  CUSTOMER_DETAIL_FARM: "/customer-farm/:customerId",
  CUSTOMER_DETAIL_VET: "/customer-vet/:customerId",
  CUSTOMER_DETAIL_LAB: "/customer-lab/:customerId",

  //product
  PRODUCT: "/product",
  PRODUCT_FEED: "/product-feed",
  PRODUCT_FARM: "/product-farm",
  PRODUCT_VET: "/product-vet",
  PRODUCT_LAB: "/product-lab",
  PRODUCT_DETAIL: "/product/:productId",
  CREATE_PRODUCT: "/create-product",
  UPDATE_PRODUCT: "/update-product/:productId",

  //news
  NEWS: "/news",
  NEWS_CREATE: "/create-news",
  NEWS_UPDATE: "/update-news/:newsId",
  NEWS_DETAIL: "/detail-news/:newsId",

  //employee
  EMPLOYEE: "/employee",
  EMPLOYEE_FEED: "/employee-feed",
  EMPLOYEE_FARM: "/employee-farm",
  EMPLOYEE_VET: "/employee-vet",
  EMPLOYEE_LAB: "/employee-lab",
  EMPLOYEE_DETAIL: "/employee/:employeeId",

  //----
  SIGNIN: "/sign-in",
  FORGETPASSWORD: "/forget-password",
  RESETPASSWORD: "/resetpassword",

  //SIGNUP: "/sign-up",
  VERIFY_OTP: "/verify-otp",
  IS_BLOCKED: "/is-blocked",

  //other
  MANAGEMENT: "/management",
  ACCOUNT: "/account",
  ACCOUNT_DETAIL: "/account/:accountId",
  ACCOUNT_ROLE_DETAIL: "/account-role/:roleId",
  SUB_ACCOUNT: "/sub-account",
  CREATE_SUB_ACCOUNT: "/create-sub-account",
  UPDATE_SUB_ACCOUNT: "/update-sub-account/:accountId",
  SUB_ACCOUNT_DETAIL: "/sub-account/:accountId",
  AUTHORIZE_ACCOUNT_DETAIL: "/authorization-account/:accountId",
  AUTHORIZE_ROLE_DETAIL: "/authorization-role/:roleId",

  DEBT_DETAIL: "/debt/:debtId",

  //Deposit
  DEPOSIT: "/deposit",
  DEPOSIT_DETAIL: "/deposit/:depositId",
  DEPOSIT_CREATE: "/create-deposit",
  DEPOSIT_UPDATE: "/update-deposit/:depositId",

  //warehouse
  WAREHOUSE: "/warehouse",

  //setting
  SETTING: "/settings",
  SETTING_SMS: "/settings-sms",

  AUTHORIZATION: "/authorization",
  AUTHORIZATION_ROLE: "/authorization-role",
  AUTHORIZATION_ACCOUNT: "/authorization-account",

  //redirect customer to admin
  REDIRECT: "/redirect/:token",

  //dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_OVERVIEW: "/dashboard/overview",
  REPORT: "/dashboard/report",
  DEBT: "/dashboard/debt",
  DEBT_REPORT: "/dashboard/debt",
  DISCOUNT: "/dashboard/discount",
  REPORT_REVENUE: "/dashboard/report-revenue",

  //coming soon
  COMMING_SOON: "/dashboard/comming-soon",

  PRICE: "/price",
  LIST_PRICE_SHIPPING: "/list-price-shipping",

  //lead
  LEAD: "/lead",
  LEAD_DETAIL: "/lead/:leadId",

  //discount
  DISCOUNT_LETTER: "/discount-letter",

  // approval cancellation
  APPROVAL_ORDER_CANCELLATION: "/approval-order-cancellation",
  APPROVAL_STATUS: "/approval-order-cancellation/status",
  APPROVAL_PROCESS: "/approval-order-cancellation/process",

  // customer manage
  CUSTOMER_MANAGE: "/customer-manage",
  CUSTOMER_MANAGE_DETAIL: "/customer-manage/:customerId",
  CREATE_CUSTOMER_MANAGE: "/create-customer-manage",
  UPDATE_CUSTOMER_MANAGE: "/update-customer-manage/:customerId",
  MODIFY_CUSTOMER_MANAGE: "/modify-customer-manage",
  MODIFY_CUSTOMER_MANAGE_EDIT: "/modify-customer-manage-edit-mode/:customerId",


  // Contract & Contract addendum
  CONTRACT_MANAGE: "/contract-manage",
  CONTRACT: "/contract-manage/contract",
  CONTRACT_ADDENDUM: "/contract-manage/contract-addendum",
  CREATE_CONTRACT: "/contract-manage/create-contract",
  CONTRACT_DETAIL: "/contract-manage/:contractId",
  CONTRACT_UPDATE: "/contract-manage/update-contract/:contractId",

  //not-found:
  NOT_FOUND: "/not-found",

  //access-denied
  ACCESS_DENIED: "/access-denied",
} as const;
