import {
  GFFormItem,
  GFModal,
  GFTextArea,
} from "@Components/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { isFunction } from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RejectReasonPayload, rejectReasonSchema } from "src/schemas/reject-reason";
import { ModalDefaultProps } from "src/types";

type Props = ModalDefaultProps & {
    approval: string,
    onConfirmReject: (reason: string, type: string) => void
};

const defaultValues: RejectReasonPayload = {
    reason: "",
  };

export default function RejectReasonModal({
  open,
  onClose,
  onConfirm,
  approval,
  onConfirmReject
}: Props) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm<RejectReasonPayload>({
    resolver: zodResolver(rejectReasonSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");
  const onSubmit = (data: any) => {
    if (isFunction(onConfirmReject)) {
        onConfirmReject(data, approval);
    }
  };
  return (
    <GFModal
      title={t("customerManage.rejectReason")}
      open={open}
      onClose={onClose}
      width="40rem"
      onConfirm={handleSubmit(onSubmit)}
      showHeaderTitle={false}
      showIconClose={false}
      disabledConfirm={!isValid || !isDirty}
      >
      <form id="reject-reason" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GFFormItem control={control} name="reason">
            <GFTextArea
                fullWidth
                label={t("customerManage.rejectReason")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.rejectReason")}`}
                rows={1}
              />
            </GFFormItem>
          </Grid>
        </Grid>
      </form>
    </GFModal>
  );
}
