import GFTooltip from "@Components/ui/GFTooltip";
import GFTypography from "@Components/ui/GFTypography";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";

const GFCustomerAvatarStyled = styled(Box)<{
  size: "small" | "medium" | "large";
  center: boolean;
  fullWidth?: boolean;
}>(({ size, center, fullWidth }) => ({
  display: "flex",
  alignItems: center ? "center" : "flex-start",
  width: fullWidth ? "100%" : "auto",
  gap: "1rem",
  "& .gf-customer-avatar__image": {
    width: "4.8rem",
    height: "4.8rem",
    borderRadius: "50%",
    objectFit: "cover",
    ...(size === "small" && {
      width: "3.2rem",
      height: "3.2rem",
    }),
  },
  "& .gf-customer-avatar__info": {
    width: "calc(100% - 4.8rem - 1rem)",
  },

  "& .MuiTypography-body1": {
    ...(size === "small" && {
      fontSize: "1.4rem",
    }),
  },
  "& .MuiTypography-caption": {
    ...(size === "small" && {
      fontSize: "1.2rem",
    }),
  },
}));

const Dot = styled("span")({
  position: "absolute",
  width: "1rem",
  height: "1rem",
  borderRadius: "50%",
  backgroundColor: "white",
  top: "0",
  right: "0",
  "&::after": {
    content: '""',
    display: "block",
    width: "0.6rem",
    height: "0.6rem",
    borderRadius: "50%",
    backgroundColor: "var(--primary-green)",
    margin: "0.2rem",
  },
});

type GFCustomerAvatarProps = {
  avatarUrl?: string | null;
  fullName: string;
  code: string;
  phoneNumber?: string;
  size?: "small" | "medium" | "large";
  center?: boolean;
  showDot?: boolean;
  type?: "primary" | "secondary";
  dvkd?: string;
  className?: string;
  fullWidth?: boolean;
};

export default function GFCustomerAvatar({
  avatarUrl,
  fullName,
  code = "",
  phoneNumber,
  size = "medium",
  center = false,
  showDot = false,
  type,
  dvkd,
  className = "",
  fullWidth = true,
}: GFCustomerAvatarProps) {
  return (
    <GFCustomerAvatarStyled
      className={`gf-customer-avatar ${className}`}
      size={size}
      center={center}
      fullWidth={fullWidth}>
      <Box position="relative">
        <img
          className="gf-customer-avatar__image"
          alt={fullName}
          src={checkLinkImage(avatarUrl!) || renderImageProNull()}
          onError={renderErrorImageItem()}
        />
        {showDot && <Dot />}
      </Box>
      <div className="gf-customer-avatar__info">
        <GFTooltip title={fullName}>
          <GFTypography fontWeight={600} type={type}>
            {fullName}
          </GFTypography>
        </GFTooltip>
        <GFTypography fontWeight={600} type="secondary">
          {code && phoneNumber
            ? `${code} - ${phoneNumber}`
            : code && !phoneNumber
            ? code
            : phoneNumber}
        </GFTypography>
        {dvkd && (
          <GFTypography fontWeight={600} type="secondary">
            {dvkd}
          </GFTypography>
        )}
      </div>
    </GFCustomerAvatarStyled>
  );
}
