import { ItemSection } from "@Components/Common";
import { GFAutoComplete, GFCustomerAvatar, GFFormItem, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import GFPageDialog from "@Components/ui/GFPageDialog";
import { APP_ROUTES } from "@Constants";
import OrderDetailItem from "@Containers/OrderCombination/OrderDetailItem";
import "@Containers/OrderCombination/styles.scss";
import { zodResolver } from "@hookform/resolvers/zod";
import { SourceType } from "@Models";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StatusUIUtil, toClientDate, toClientDateTime, toServerDate } from "@Utils";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useOrderDetail } from "src/app/queries/order.query";
import { useAllSiteByCustomer, useUpdateOrderCoordination } from "src/app/queries/site.query";
import { CampCoordinationPayload } from "src/dtos/camp.dto";
import { campCoordinationSchema } from "src/schemas/camp.schema";
import { z } from "zod";

const columns: GFDataTableColumn[] = [
  {
    title: "Mã đơn hàng",
    dataIndex: "code",
    render(cellData, record, rowIndex, onChange) {
      return (
        <Link to={APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", record.id)} target="_blank">
          <GFTypography type="primary" sx={{ textDecoration: "underline" }}>
            {cellData}
          </GFTypography>
        </Link>
      );
    },
  },

  {
    title: "Sản phẩm",
    width: 300,
    autoWidth: true,
    dataIndex: "orders[0].orderlines[0].itemName" as any,
  },
  {
    title: "Thời gian đặt hàng",
    dataIndex: "createdAt",
    width: 250,
    render: (cellData) => toClientDateTime(cellData),
  },
  {
    title: "Ngày giao hàng",
    dataIndex: "orders[0].requiredDate" as any,
    width: 190,
    render: (cellData, record) => {
      return `${toClientDate(cellData)}`;
    },
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    width: 250,
    render: (cellData, record) => {
      return StatusUIUtil.renderCircleStatusNew(null, record?.status, record?.subStatus);
    },
  },
];

const InfoLabel = styled((props) => (
  <GFTypography {...props} variant="body2" color="text.secondary" />
))({
  display: "block",
  marginBottom: "8px",
});

type OrderCombinationProps = {
  onClose: () => void;
};

export default function CampCoordination({ onClose }: OrderCombinationProps) {
  const { enqueueSnackbar } = useSnackbar();

  const orderId = useParams<{ orderId: string }>()?.orderId;
  const { isLoading, orderDetail } = useOrderDetail(Number(orderId));
  const customerId = _.get(orderDetail, "orders[0].customer.id");

  const { sites } = useAllSiteByCustomer({
    limit: 500,
    page: 1,
    source: SourceType.FARM,
    customerId: customerId,
  });
  const { control, trigger, getValues } = useForm<z.infer<typeof campCoordinationSchema>>({
    resolver: zodResolver(campCoordinationSchema),
    mode: "onChange",
  });

  const { mutateAsync, isLoading: updateLoading } = useUpdateOrderCoordination();

  const handleUpdateCamp = () => {
    if (!orderDetail) {
      return;
    }
    trigger().then(async (isValid) => {
      if (isValid) {
        const data = getValues();
        const payload: CampCoordinationPayload = {
          orderGroupId: Number(orderId),
          isCheckWareHouse: false,
          isCreateERP: false,
          customerId,
          orders: orderDetail.orders.map((order: any) => {
            return {
              orderId: order.id,
              requiredDate: toServerDate(order.requiredDate),
              requiredTime: order.requiredTime?.key,
              address: order.address,
              siteId: data.siteId,
              vehicleNumber: "",
              shippingAddress: 0,
              note: order.note,
              orderDetails: order.orderlines?.map((orderDetail: any) => {
                return {
                  id: orderDetail.id,
                  quantity: +orderDetail.orderQuantity,
                  dimension: orderDetail.dimension,
                  subTotal: +orderDetail.subTotal,
                  attachValue: 0,
                };
              }),
            };
          }),
        };

        try {
          await mutateAsync(payload);
          enqueueSnackbar("Điều phối trại thành công", { variant: "success" });
          onClose();
        } catch (error) {
          enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
        }
      }
    });
  };

  return (
    <GFPageDialog
      title="Điều phối trại - Tinh heo"
      loading={isLoading || updateLoading}
      onConfirm={handleUpdateCamp}
      btnConfirmText="Điều phối trại"
      onCancel={onClose}>
      <GFTypography mb={2} variant="body1">
        Thông tin đơn hàng - #{_.get(orderDetail, "code")}
      </GFTypography>
      <ItemSection sx={{ background: "#fff", boxShadow: "none" }}>
        <Grid container spacing={2} sx={{ paddingBlock: 0, marginBottom: "1.6rem" }}>
          <Grid item xs={3}>
            <GFCustomerAvatar
              code={_.get(orderDetail, "orders[0].customer.code")}
              fullName={_.get(orderDetail, "orders[0].customer.fullName")}
              avatarUrl={_.get(orderDetail, "orders[0].customer.account.avatar")}
              phoneNumber={_.get(orderDetail, "orders[0].customer.phoneNumber")}
              dvkd={_.get(orderDetail, "orders[0].salesOrg.description")}
              type="primary"
            />
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Thời gian đặt hàng</InfoLabel>
            <GFTypography>{toClientDateTime(_.get(orderDetail, "createdAt"))}</GFTypography>
          </Grid>
          <Grid item xs={3}>
            <InfoLabel>Trang trại</InfoLabel>
            <GFTypography>{_.get(orderDetail, "orders[0].camp.contactName")}</GFTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Người đặt hàng</InfoLabel>
            <GFTypography>
              {_.get(orderDetail, "createdBy.fullName")}
              {" - "}
              {_.get(orderDetail, "createdBy.account.code")}
            </GFTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Trạng thái</InfoLabel>
            <GFTypography>
              <div>
                {StatusUIUtil.renderCircleStatusNew(
                  null,
                  orderDetail?.status,
                  orderDetail?.subStatus
                )}
              </div>
            </GFTypography>
          </Grid>
        </Grid>
        <Box>
          {orderDetail?.orders?.map((order: any, index: number) => (
            <OrderDetailItem order={order} />
          ))}
        </Box>
      </ItemSection>
      <ItemSection sx={{ background: "#fff", boxShadow: "none" }}>
        <GFFormItem control={control} name="siteId">
          <GFAutoComplete label="Chọn trại" options={sites} />
        </GFFormItem>
      </ItemSection>
    </GFPageDialog>
  );
}
