export const FormUIUtil = {
  renderFormItem,
};

function renderFormItem(label: any, value: any, required: boolean = false) {
  return (
    <div className="form-item">
      <div className={`form-label ${required ? "asterisk" : ""}`}>{label}</div>
      {value}
    </div>
  );
}
