import { FetchingPaginationDataModel, TLanguage } from "@Models";

export const DEFAULT_FETCHING = {
  LIMIT: 25,
};
export const INITIAL_FETCHING_PAGINATION_DATA: FetchingPaginationDataModel<any> = {
  page: 1,
  totalPages: 1,
  limit: DEFAULT_FETCHING.LIMIT,
  totalRecords: 1,
  data: [],
  loading: false,
};
export const CurrencyOptions: {
  id: string;
  locale: string;
}[] = [
  { id: "VND", locale: "vi-VN" },
  { id: "EUR", locale: "en-IE" },
  { id: "USD", locale: "en-US" },
];

export const Source = [
  { src: "feed", name: "Thức ăn chăn nuôi" },
  { src: "farm", name: "Trang trại & con giống" },
  { src: "ccu", name: "Chuỗi cung ứng" },
];

export const LANGUAGES: { [key in TLanguage]: TLanguage } = {
  en: "en",
  vi: "vi",
  km: "km",
  my: "my",
};

export const ItemGroupTypeEnum = {
  PORK_PIG: "EC00002", // Heo thịt
  BREEDING_PIG: "EC00001", // Heo giống
  CULL_PIG: "EC00003", // Heo loại
};

export enum APPROVE_BUSINESS_TYPE {
  ORDER_CANCELLATION = 1,
}

export enum APPROVE_BY {
  FORMULA = 1,
}

export enum CUSTOMER_MANAGE_REQUEST_TYPE {
  CREATE = 'CREATE_CUSTOMER',
  UPDATE = 'MODIFY_CUSTOMER',
  CREATE_ADDRESS = 'ADD_SHIPPING_ADDRESS',
}

export enum CUSTOMER_MANAGE_STATUS {
  DRAFT = 'DRAFT',
  SUBMIT = 'CREATED',
  APPROVED = 'APPROVAL',
  REJECTED = 'REJECTED',
  CONFIRMED = 'CONFIRMED',
  SYNC = 'SYNCED',
  ALL = 'ALL'
}

export const FILTER_DEFAULT = {
  ALL: "ALL"
}

export const CUSTOMER_MANAGE_DEFAULT_VALUE = {
  SALE_ORG: '2000',
  DISTRBUTION_CHANNEL: 10,
  DEVISION: 10
}

export enum APPROVAL_PERSON {
  GDKD = 'GDKD',
  KTBH = 'KTBH'
}

export enum CUSTOMER_MANAGE_DETAIL_OPTION {
  CHECK_DUPLICATE = 1,
}

export enum CONTRACT_PARTY {
  TWO_PARTY = 'TWO_PARTY', // Ký 2 bên
  THREE_PARTY = 'THREE_PARTY', // Ký 3 bên
}

export enum CONTRACT_TERM {
  FIXED_TERM = 'FIXED_TERM', // Có thời hạn
  UNLIMITED_TERM = 'UNLIMITED_TERM' // Không thờ hạn
} 

export enum CONTRACT_TYPE {
  PRINCIPLE = 'PRINCIPLE' // Hợp đồng nguyên tắc
}