/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { AccountIcon } from "@Components/Icons/Account";
import { DashboardIcon } from "@Components/Icons/DashboardIcon";
import { DebtIcon } from "@Components/Icons/DebtIcon";
import { EmployeeIcon } from "@Components/Icons/Employee";
import { FeedIcon } from "@Components/Icons/FeedIcon";
import { KeyIcon } from "@Components/Icons/Key";
import { OrderListIcon } from "@Components/Icons/OrderListIcon";
import { SignInIcon } from "@Components/Icons/SignInIcon";
import { SignUpIcon } from "@Components/Icons/SignUpIcon";
import { UserIcon } from "@Components/Icons/UserIcon";
import ModalFooter from "@Components/ModalFooter";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, DataAccessFarm, DataAccessFeed, TEXT_HOLDER } from "@Constants";
import {
  DivisionModal,
  GetAllSite,
  PermissionAvailableModal,
  RoleType,
  SalesOrgModal,
  SourceType,
} from "@Models";
import { Nullable } from "@Models/shared";
import {
  EmployeeService,
  OrderService,
  PermissionService,
  ProductService,
  SalesOrgService,
} from "@Services";
import { FormUtil } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
// import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import PermissionImg from "@Assets/images/permission.jpg";
import { DEFAULT_SIZE } from "@Core/Layout/routes";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import "./AuthorizeModal.scss";
import { useTranslation } from "react-i18next";
import { PlanIcon } from "@Components/Icons/PlanIcon";
import { AccountSubIcon } from "@Components/Icons/AccountSub";

type P_Props = {
  closeForm: () => void;
  roleType: RoleType;
  dataRoleId?: any;
  type?: string;
};

const AuthorizeModal: React.FC<P_Props> = ({ closeForm, roleType, dataRoleId, type }) => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [salesOrg, setSalesOrg] = useState<SalesOrgModal[]>([]);
  const [division, setDivision] = useState<DivisionModal[]>([]);
  const [product, setProduct] = useState<any>([]);
  const [site, setSite] = useState<GetAllSite[]>([]);
  const [salesMan, setSalesMan] = useState<Nullable<SalesOrgModal[]>>([]);
  const [permissionAvailable, setPermissionAvailable] = useState<PermissionAvailableModal[]>([]);
  const [subTabSource, setSubTabSource] = useState<string>(
    dataRoleId ? dataRoleId?.dataAccessPermissions[0]?.source : SourceType.FEED
  );

  const [typeValue, setTypeValue] = useState<string>("sales-org");
  // const [openDataAccess, setOpenDataAccess] = useState<boolean>(true);
  const [list, setList] = useState<any>(
    dataRoleId ? dataRoleId?.dataAccessPermissions[0]?.source : SourceType.FEED
  );
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useLayoutEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  //Check division
  const [checkedDivision, setCheckedDivision] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FEED)
          ?.divisionIds
      : []
  );
  const [checkedDivisionVet, setCheckedDivisionVet] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.VET)
          ?.divisionIds
      : []
  );
  const [checkedDivisionLab, setCheckedDivisionLab] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.LAB)
          ?.divisionIds
      : []
  );

  //Check sale org
  const [checkedSalesOrg, setCheckedSalesOrg] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FEED)
          ?.salesOrgIds
      : []
  );
  const [checkedSalesOrgFarm, setCheckedSalesOrgFarm] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FARM)
          ?.salesOrgIds
      : []
  );
  const [checkedSalesOrgVet, setCheckedSalesOrgVet] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.VET)
          ?.salesOrgIds
      : []
  );
  const [checkedSalesOrgLab, setCheckedSalesOrgLab] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.LAB)
          ?.salesOrgIds
      : []
  );

  //Check sale man
  const [checkedSalesMan, setCheckedSalesMan] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FEED)
          ?.salesManIds
      : []
  );
  const [checkedSalesManFarm, setCheckedSalesManFarm] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FARM)
          ?.salesManIds
      : []
  );
  const [checkedSalesManVet, setCheckedSalesManVet] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.VET)
          ?.salesManIds
      : []
  );
  const [checkedSalesManLab, setCheckedSalesManLab] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.LAB)
          ?.salesManIds
      : []
  );

  //Check product
  const [checkedProduct, setCheckedProduct] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FARM)
          ?.itemIds
      : []
  );

  //Check site
  const [checkedSite, setCheckedSite] = useState<any>(
    dataRoleId
      ? dataRoleId?.dataAccessPermissions?.find((data: any) => data?.source === SourceType.FARM)
          ?.siteIds
      : []
  );

  //Check permission
  const [checkedPermission, setCheckedPermission] = useState<any>(
    dataRoleId ? dataRoleId?.permissions : []
  );

  useEffect(() => {
    if (type === "update") {
      setSubTabSource(dataRoleId?.dataAccessPermissions[0].source);
      setList(dataRoleId?.dataAccessPermissions[0].source);
    } else {
      profile && setSubTabSource(profile?.role?.dataAccessPermissions[0].source);
      profile && setList(profile?.role?.dataAccessPermissions[0].source);
    }
  }, [profile]);

  const checkSource =
    type === "update"
      ? dataRoleId?.dataAccessPermissions[0].source
      : profile &&
        profile.role?.dataAccessPermissions?.some((source) => source.source?.includes(list));

  const [valuePosition, setValuePosition] = useState<string>(
    dataRoleId
      ? type === "update"
        ? dataRoleId?.position
        : "[Sao chép] " + dataRoleId?.position
      : ""
  );
  const [valueDescription, setValueDescription] = useState<string>(
    dataRoleId
      ? type === "update"
        ? dataRoleId?.description
        : "[Sao chép] " + dataRoleId?.description
      : ""
  );

  const handleChangeTab = (_: any, newList: any) => {
    setList(newList);
  };

  //get sales org
  const fetchDataSaleOrg = async () => {
    SalesOrgService.getAllSalesOrg({
      sources: subTabSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          setSalesOrg(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get division
  const fetchDataDivision = async () => {
    ProductService.getDivisionBySource({
      source: subTabSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          setDivision(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get product
  const fetchDataProduct = async () => {
    ProductService.getListProductByAdmin({
      page: 1,
      limit: 300,
      source: subTabSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          setProduct(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get site
  const fetchDataAllSite = async () => {
    OrderService.getAllSite({
      source: subTabSource,
      limit: 500,
      page: 1,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 204) {
          setSite(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (checkSource) {
      fetchDataSaleOrg();
      fetchDataDivision();
      fetchDataAllSite();
    }
    if (subTabSource === SourceType.FARM) {
      fetchDataProduct();
    }
  }, [subTabSource, checkSource]);

  //get permission available
  useEffect(() => {
    const fetchData = async () => {
      PermissionService.getAllPermissionAvailable()
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setPermissionAvailable(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, []);

  const dataPermissionAvailable = [...checkedPermission];

  //get sales-man
  useEffect(() => {
    const salesOrgIds = () => {
      switch (subTabSource) {
        case SourceType.FEED:
          return !!checkedSalesOrg?.length ? new Array(checkedSalesOrg) : [];
        case SourceType.VET:
          return !!checkedSalesOrgVet?.length ? new Array(checkedSalesOrgVet) : [];
        case SourceType.LAB:
          return !!checkedSalesOrgLab?.length ? new Array(checkedSalesOrgLab) : [];
        default:
          return !!checkedSalesOrgFarm?.length ? new Array(checkedSalesOrgFarm) : [];
      }
    };

    const divisionIds = () => {
      switch (subTabSource) {
        case SourceType.FEED:
          return checkedDivision?.length > 0 ? new Array(checkedDivision) : [];
        case SourceType.VET:
          return checkedDivisionVet?.length > 0 ? new Array(checkedDivisionVet) : [];
        default:
          return checkedDivisionLab?.length > 0 ? new Array(checkedDivisionLab) : [];
      }
    };

    const fetchData = async () => {
      EmployeeService.getSalesMan({
        source: subTabSource,
        salesOrgIds: salesOrgIds(),
        divisionIds: divisionIds(),
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setSalesMan(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const validFeed =
      subTabSource === SourceType.FEED && !checkedSalesOrg?.length && !checkedDivision?.length;
    const validVet =
      subTabSource === SourceType.VET && !checkedSalesOrgVet?.length && !checkedDivisionVet?.length;
    const validLab =
      subTabSource === SourceType.LAB && !checkedSalesOrgLab?.length && !checkedDivisionLab?.length;

    if ((validFeed || validVet || validLab) && typeValue === "sales-man") {
      enqueueSnackbar(t("PLEASE_SELECT_BUSINESS_ORG_AND_INDUSTRY"), {
        variant: "error",
        preventDuplicate: true,
      });
    }

    if (
      subTabSource === SourceType.FARM &&
      !checkedSalesOrgFarm?.length &&
      typeValue === "sales-man"
    ) {
      enqueueSnackbar(t("PLEASE_SELECT_BUSINESS_ORG"), {
        variant: "error",
        preventDuplicate: true,
      });
    }

    const sourceFeed =
      subTabSource === SourceType.FEED && !!checkedSalesOrg?.length && !!checkedDivision?.length;
    const sourceVet =
      subTabSource === SourceType.VET &&
      !!checkedSalesOrgVet?.length &&
      !!checkedDivisionVet?.length;
    const sourceLab =
      subTabSource === SourceType.LAB &&
      !!checkedSalesOrgLab?.length &&
      !!checkedDivisionLab?.length;
    if (
      sourceFeed ||
      sourceVet ||
      sourceLab ||
      (subTabSource === SourceType.FARM && checkedSalesOrgFarm?.length > 0)
    ) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [
    checkedSalesOrg,
    checkedSalesOrgVet,
    checkedSalesOrgLab,
    checkedDivision,
    checkedDivisionVet,
    checkedDivisionLab,
    typeValue,
    subTabSource,
    checkedSalesOrgFarm,
  ]);

  //asynchronous
  const dataDivision = [...(checkedDivision || [])];
  const dataDivisionVet = [...(checkedDivisionVet || [])];
  const dataDivisionLab = [...(checkedDivisionLab || [])];

  const dataSalesMan = [...(checkedSalesMan || [])];
  const dataSalesManFarm = [...(checkedSalesManFarm || [])];
  const dataSalesManVet = [...(checkedSalesManVet || [])];
  const dataSalesManLab = [...(checkedSalesManLab || [])];

  const dataProduct = [...(checkedProduct || [])];
  const dataSite = [...(checkedSite || [])];

  const dataSalesOrg = [...(checkedSalesOrg || [])];
  const dataSalesOrgFarm = [...(checkedSalesOrgFarm || [])];
  const dataSalesOrgVet = [...(checkedSalesOrgVet || [])];
  const dataSalesOrgLab = [...(checkedSalesOrgLab || [])];

  const superAD =
    !!checkedSalesOrg?.length &&
    !!checkedSalesOrgFarm?.length &&
    !!checkedSalesOrgVet?.length &&
    !!checkedSalesOrgLab?.length;

  const sourceAccess = (
    source: SourceType,
    divisionIds: any,
    salesOrgIds: any,
    salesManIds: any,
    siteIds?: any,
    itemIds?: any
  ) => ({
    source,
    divisionIds: divisionIds?.length > 0 ? divisionIds : undefined,
    salesOrgIds,
    salesManIds,
    siteIds: siteIds?.length > 0 ? siteIds : undefined,
    itemIds: itemIds?.length > 0 ? itemIds : undefined,
  });

  const dataAccess: any = superAD
    ? [
        sourceAccess(SourceType.FEED, checkedDivision, dataSalesOrg, dataSalesMan),
        sourceAccess(
          SourceType.FARM,
          undefined,
          dataSalesOrgFarm,
          dataSalesManFarm,
          checkedSite,
          checkedProduct
        ),
        sourceAccess(SourceType.VET, checkedDivisionVet, dataSalesOrgVet, dataSalesManVet),
        sourceAccess(SourceType.LAB, checkedDivisionLab, dataSalesOrgLab, dataSalesManLab),
      ]
    : [
        !!checkedSalesOrg?.length &&
          sourceAccess(SourceType.FEED, checkedDivision, dataSalesOrg, dataSalesMan),
        !!checkedSalesOrgFarm?.length &&
          sourceAccess(
            SourceType.FARM,
            undefined,
            dataSalesOrgFarm,
            dataSalesManFarm,
            checkedSite,
            checkedProduct
          ),
        !!checkedSalesOrgVet?.length &&
          sourceAccess(SourceType.VET, checkedDivisionVet, dataSalesOrgVet, dataSalesManVet),
        !!dataSalesOrgLab?.length &&
          sourceAccess(SourceType.LAB, checkedDivisionLab, dataSalesOrgLab, dataSalesManLab),
      ].filter(Boolean);

  const submitFormCreate = (values: any) => {
    PermissionService.createNewRole({
      position: valuePosition,
      description: valueDescription,
      permissions: dataPermissionAvailable,
      roleType: roleType,
      dataAccessPermissions: dataAccess,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("CREATE_ROLE_SUCCESS"), {
            variant: "success",
          });
          history.push(
            APP_ROUTES.AUTHORIZE_ROLE_DETAIL.replace(":roleId", res.data?.id.toString())
          );
          closeForm();
        }
      })
      .catch(() => {
        enqueueSnackbar(t("CREATE_ROLE_FAILED"), {
          variant: "error",
        });
      });
  };

  const submitFormUpdate = (values: any) => {
    PermissionService.updateRole({
      id: dataRoleId?.id,
      position: valuePosition,
      description: valueDescription,
      permissions: dataPermissionAvailable,
      dataAccessPermissions: dataAccess,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("EDIT_ROLE_SUCCESS"), {
            variant: "success",
          });
          setTimeout(() => {
            window.location.reload();
          });
        }
      })
      .catch((err: AxiosError) => {
        if (
          err.response?.status === 400 &&
          err.response?.data.message === "CAN_NOT_CHANGED_FIXED_ROLE"
        ) {
          enqueueSnackbar(t("CANNOT_EDIT"), {
            variant: "error",
          });
          window.location.reload();
        } else {
          enqueueSnackbar(t("EDIT_ROLE_FAILED"), {
            variant: "error",
          });
        }
      });
  };

  const iConTitle = (name: any) => {
    switch (name) {
      case "Đăng nhập":
        return <SignInIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Khách hàng":
        return <UserIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Đơn hàng":
        return <OrderListIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Sản phẩm":
        return <FeedIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={[22, 24]} />;
      case "Thông tin đăng ký":
        return <SignUpIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Nhân viên":
        return <EmployeeIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Quản lý tài khoản khách hàng":
        return <AccountIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Công nợ":
        return <DebtIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Phân quyền":
        return <KeyIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Báo cáo":
        return <DashboardIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Kế hoạch đặt hàng":
        return <PlanIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      case "Tài khoản phụ":
        return <AccountSubIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />;
      default:
        return;
    }
  };

  const CheckSaleOrgLabel = (tab: any) => {
    switch (tab) {
      case SourceType.FEED:
        return checkedSalesOrg?.length === salesOrg?.length;
      case SourceType.VET:
        return checkedSalesOrgVet?.length === salesOrg?.length;
      case SourceType.LAB:
        return checkedSalesOrgLab?.length === salesOrg?.length;
      default:
        return checkedSalesOrgFarm?.length === salesOrg?.length;
    }
  };
  const CheckSaleOrgGroup = (tab: any, id: string) => {
    switch (tab) {
      case SourceType.FEED:
        return dataSalesOrg?.some((some: any) => some === id);
      case SourceType.VET:
        return dataSalesOrgVet?.some((some: any) => some === id);
      case SourceType.LAB:
        return dataSalesOrgLab?.some((some: any) => some === id);
      default:
        return dataSalesOrgFarm?.some((some: any) => some === id);
    }
  };

  const CheckDivisionLabel = (tab: any) => {
    switch (tab) {
      case SourceType.FEED:
        return checkedDivision?.length === division?.length;
      case SourceType.VET:
        return checkedDivisionVet?.length === division?.length;
      default:
        return checkedDivisionLab?.length === division?.length;
    }
  };
  const CheckDivisionGroup = (tab: any, id: string) => {
    switch (tab) {
      case SourceType.FEED:
        return checkedDivision?.some((some: any) => some === id);
      case SourceType.VET:
        return checkedDivisionVet?.some((some: any) => some === id);
      default:
        return checkedDivisionLab?.some((some: any) => some === id);
    }
  };

  const CheckSaleManLabel = (tab: any) => {
    switch (tab) {
      case SourceType.FEED:
        return dataSalesMan?.length === salesMan?.length;
      case SourceType.VET:
        return dataSalesManVet?.length === salesMan?.length;
      case SourceType.LAB:
        return dataSalesManLab?.length === salesMan?.length;
      default:
        return dataSalesManFarm?.length === salesMan?.length;
    }
  };
  const CheckSaleManGroup = (tab: any, id: string) => {
    switch (tab) {
      case SourceType.FEED:
        return dataSalesMan?.some((some: any) => some === id);
      case SourceType.VET:
        return dataSalesManVet?.some((some: any) => some === id);
      case SourceType.LAB:
        return dataSalesManLab?.some((some: any) => some === id);
      default:
        return dataSalesManFarm?.some((some: any) => some === id);
    }
  };

  return (
    <div className="authorize-modal">
      <SimpleModalHeader
        title={type === "update" ? t("EDIT_ROLE") : t("ADD_ROLE")}
        onClose={() => {
          closeForm();
        }}
      />
      <div className="contain">
        <Form
          initialValues={dataRoleId ? dataRoleId : {}}
          onSubmit={async (values) => {
            if (subTabSource === "feed") {
              if (dataSalesOrg?.length > 0 && dataPermissionAvailable?.length > 0) {
                type === "update" ? submitFormUpdate(values) : submitFormCreate(values);
              } else if (dataSalesOrg?.length === 0) {
                enqueueSnackbar(t("SET_LEAST_ONE_BUSINESS_ORG"), {
                  variant: "error",
                });
              } else if (subTabSource === "feed" && checkedDivision?.length === 0) {
                enqueueSnackbar(t("SET_LEAST_ONE_INDUSTRY"), {
                  variant: "error",
                });
              } else if (dataPermissionAvailable?.length === 0) {
                enqueueSnackbar(t("SET_LEAST_ONE_PERMISSION"), {
                  variant: "error",
                });
              }
            } else {
              if (dataSalesOrgFarm?.length > 0 && dataPermissionAvailable?.length > 0) {
                type === "update" ? submitFormUpdate(values) : submitFormCreate(values);
              } else if (dataSalesOrgFarm?.length === 0) {
                enqueueSnackbar(t("SET_LEAST_ONE_BUSINESS_ORG"), {
                  variant: "error",
                });
              } else if (dataPermissionAvailable?.length === 0) {
                enqueueSnackbar(t("SET_LEAST_ONE_PERMISSION"), {
                  variant: "error",
                });
              }
            }
          }}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="create-role">
                <div className="authorize-form">
                  <Grid container style={{ display: "flex", flexDirection: "row" }}>
                    <Grid item xs={6} className="left">
                      <div style={{ padding: "6rem 6rem 6rem 16rem" }}>
                        <div className="title">{t("GENERAL_INFORMATION")}</div>
                        <div className="form-item">
                          <div className="form-label">{t("NAME")}</div>
                          <Field
                            name="position"
                            validate={FormUtil.Rule.required(
                              t("NOT_INPUT_BY_NAME", { NAME: t("NAME") })
                            )}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  value={valuePosition}
                                  onChange={(e) => {
                                    input.onChange(e.target.value);
                                    setValuePosition(e.target.value as string);
                                  }}
                                  placeholder={t("INPUT_BY_NAME", { NAME: t("NAME") })}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              );
                            }}
                          </Field>
                        </div>
                        <div className="form-item">
                          <div className="form-label">{t("DESCRIPTION")}</div>
                          <Field
                            name="description"
                            validate={FormUtil.Rule.required(
                              t("NOT_INPUT_BY_NAME", { NAME: t("DESCRIPTION") })
                            )}>
                            {({ input, meta, ...rest }) => {
                              return (
                                <TextField
                                  {...input}
                                  {...rest}
                                  value={valueDescription}
                                  onChange={(e) => {
                                    input.onChange(e.target.value);
                                    setValueDescription(e.target.value as string);
                                  }}
                                  placeholder={t("INPUT_BY_NAME", { NAME: t("DESCRIPTION") })}
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              );
                            }}
                          </Field>
                        </div>
                        <div className="access">
                          <div className="title">{t("ACCESS_DATA")}</div>
                          {/* <DefaultSwitch
                            checked={openDataAccess}
                            onChange={() => setOpenDataAccess(!openDataAccess)}
                          /> */}
                        </div>
                        <div className="source-tab">
                          <Tabs
                            className="tab"
                            value={list}
                            onChange={handleChangeTab}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example">
                            <Tab
                              label={t("FEED")}
                              value={SourceType.FEED}
                              onClick={() => {
                                setSubTabSource("feed");
                                setTypeValue("sales-org");
                              }}
                            />
                            <Tab
                              label={t("FARM")}
                              value={SourceType.FARM}
                              onClick={() => {
                                setSubTabSource("farm");
                                setTypeValue("sales-org");
                              }}
                            />
                            <Tab
                              label={t("VET")}
                              value={SourceType.VET}
                              onClick={() => {
                                setSubTabSource("vet");
                                setTypeValue("sales-org");
                              }}
                            />
                            <Tab
                              label={t("LAB")}
                              value={SourceType.LAB}
                              onClick={() => {
                                setSubTabSource("lab");
                                setTypeValue("sales-org");
                              }}
                            />
                          </Tabs>
                        </div>

                        {checkSource ? (
                          <div className="data-access">
                            <div className="header">
                              <div className="type">{t("DATA_TYPE")}</div>
                              <div className="data">{t("DATA")}</div>
                            </div>
                            <div className="body">
                              <div className="data-left">
                                {(subTabSource === SourceType.FEED ||
                                subTabSource === SourceType.VET ||
                                subTabSource === SourceType.LAB
                                  ? DataAccessFeed
                                  : DataAccessFarm
                                )?.map((data: any, index: number) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        data.value === typeValue ? "label active" : "label"
                                      }
                                      onClick={() => {
                                        setTypeValue(data.value);
                                      }}>
                                      {data.label}
                                    </div>
                                  );
                                })}
                              </div>
                              <hr />
                              <div className="data-right">
                                {typeValue === "sales-org" && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={CheckSaleOrgLabel(subTabSource)}
                                          onChange={(_, value) => {
                                            if (subTabSource === SourceType.FEED) {
                                              if (value) {
                                                dataSalesOrg.push(
                                                  ...(salesOrg?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedSalesOrg(
                                                  Array.from(new Set(dataSalesOrg))
                                                );
                                              } else {
                                                setCheckedSalesOrg([]);
                                              }
                                            } else if (subTabSource === SourceType.VET) {
                                              if (value) {
                                                dataSalesOrgVet.push(
                                                  ...(salesOrg?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedSalesOrgVet(
                                                  Array.from(new Set(dataSalesOrgVet))
                                                );
                                              } else {
                                                setCheckedSalesOrgVet([]);
                                              }
                                            } else if (subTabSource === SourceType.LAB) {
                                              if (value) {
                                                dataSalesOrgLab.push(
                                                  ...(salesOrg?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedSalesOrgLab(
                                                  Array.from(new Set(dataSalesOrgLab))
                                                );
                                              } else {
                                                setCheckedSalesOrgLab([]);
                                              }
                                            } else {
                                              if (value) {
                                                dataSalesOrgFarm.push(
                                                  ...(salesOrg?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedSalesOrgFarm(
                                                  Array.from(new Set(dataSalesOrgFarm))
                                                );
                                              } else {
                                                setCheckedSalesOrgFarm([]);
                                              }
                                            }
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                              color: "#41AD49",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("ALL")}
                                    />
                                    <FormGroup>
                                      {salesOrg?.map((s: any) => {
                                        return (
                                          <FormControlLabel
                                            key={s.id}
                                            control={
                                              <Checkbox
                                                checked={
                                                  CheckSaleOrgGroup(subTabSource, s.id) ?? false
                                                }
                                                value={s.id}
                                                onChange={() => {
                                                  if (subTabSource === SourceType.FEED) {
                                                    dataSalesOrg?.some((e: any) => e === s.id)
                                                      ? setCheckedSalesOrg(
                                                          dataSalesOrg?.filter(
                                                            (e: any) => e !== s.id
                                                          )
                                                        )
                                                      : setCheckedSalesOrg([...dataSalesOrg, s.id]);
                                                  } else if (subTabSource === SourceType.VET) {
                                                    dataSalesOrgVet?.some((e: any) => e === s.id)
                                                      ? setCheckedSalesOrgVet(
                                                          dataSalesOrgVet?.filter(
                                                            (e: any) => e !== s.id
                                                          )
                                                        )
                                                      : setCheckedSalesOrgVet([
                                                          ...dataSalesOrgVet,
                                                          s.id,
                                                        ]);
                                                  } else if (subTabSource === SourceType.LAB) {
                                                    dataSalesOrgLab?.some((e: any) => e === s.id)
                                                      ? setCheckedSalesOrgLab(
                                                          dataSalesOrgLab?.filter(
                                                            (e: any) => e !== s.id
                                                          )
                                                        )
                                                      : setCheckedSalesOrgLab([
                                                          ...dataSalesOrgLab,
                                                          s.id,
                                                        ]);
                                                  } else {
                                                    dataSalesOrgFarm?.some((e: any) => e === s.id)
                                                      ? setCheckedSalesOrgFarm(
                                                          dataSalesOrgFarm?.filter(
                                                            (e: any) => e !== s.id
                                                          )
                                                        )
                                                      : setCheckedSalesOrgFarm([
                                                          ...dataSalesOrgFarm,
                                                          s.id,
                                                        ]);
                                                  }
                                                }}
                                                sx={{
                                                  "& .MuiSvgIcon-root": {
                                                    fontSize: 28,
                                                    color: "#41AD49",
                                                  },
                                                }}
                                              />
                                            }
                                            label={s.description || TEXT_HOLDER}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                )}
                                {typeValue === "divisions" && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={CheckDivisionLabel(subTabSource)}
                                          onChange={(_, value) => {
                                            if (subTabSource === SourceType.FEED) {
                                              if (value) {
                                                dataDivision.push(
                                                  ...(division?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedDivision(
                                                  Array.from(new Set(dataDivision))
                                                );
                                              } else {
                                                setCheckedDivision([]);
                                              }
                                            } else if (subTabSource === SourceType.VET) {
                                              if (value) {
                                                dataDivisionVet.push(
                                                  ...(division?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedDivisionVet(
                                                  Array.from(new Set(dataDivisionVet))
                                                );
                                              } else {
                                                setCheckedDivisionVet([]);
                                              }
                                            } else {
                                              if (value) {
                                                dataDivisionLab.push(
                                                  ...(division?.map((s: any) => s?.id) || [])
                                                );
                                                setCheckedDivisionLab(
                                                  Array.from(new Set(dataDivisionLab))
                                                );
                                              } else {
                                                setCheckedDivisionLab([]);
                                              }
                                            }
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                              color: "#41AD49",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("ALL")}
                                    />
                                    <FormGroup>
                                      {division?.map((d: any) => {
                                        return (
                                          <FormControlLabel
                                            key={d.id}
                                            control={
                                              <Checkbox
                                                value={d.id}
                                                checked={CheckDivisionGroup(subTabSource, d.id)}
                                                onChange={(_, value) => {
                                                  if (subTabSource === SourceType.FEED) {
                                                    if (value) {
                                                      dataDivision.push(d.id);
                                                      setCheckedDivision(dataDivision);
                                                    } else {
                                                      setCheckedDivision(
                                                        dataDivision?.filter((id) => {
                                                          return id !== d.id;
                                                        })
                                                      );
                                                    }
                                                  } else if (subTabSource === SourceType.VET) {
                                                    if (value) {
                                                      dataDivisionVet.push(d.id);
                                                      setCheckedDivisionVet(dataDivisionVet);
                                                    } else {
                                                      setCheckedDivisionVet(
                                                        dataDivisionVet?.filter((id) => {
                                                          return id !== d.id;
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    if (value) {
                                                      dataDivisionLab.push(d.id);
                                                      setCheckedDivisionLab(dataDivisionLab);
                                                    } else {
                                                      setCheckedDivisionLab(
                                                        dataDivisionLab?.filter((id) => {
                                                          return id !== d.id;
                                                        })
                                                      );
                                                    }
                                                  }
                                                }}
                                                sx={{
                                                  "& .MuiSvgIcon-root": {
                                                    fontSize: 28,
                                                    color: "#41AD49",
                                                  },
                                                }}
                                              />
                                            }
                                            label={d.description || TEXT_HOLDER}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                )}
                                {typeValue === "site" && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checkedSite?.length === site?.length}
                                          onChange={(_, value) => {
                                            if (value) {
                                              dataSite.push(
                                                ...(site?.map((site: any) => site?.id) || [])
                                              );
                                              setCheckedSite(Array.from(new Set(dataSite)));
                                            } else {
                                              setCheckedSite([]);
                                            }
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                              color: "#41AD49",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("ALL")}
                                    />
                                    <FormGroup>
                                      {site?.map((site: any) => {
                                        return (
                                          <FormControlLabel
                                            key={site.id}
                                            control={
                                              <Checkbox
                                                value={site.id}
                                                checked={dataSite?.some(
                                                  (some: any) => some === site.id
                                                )}
                                                onChange={(_, value) => {
                                                  if (value) {
                                                    dataSite.push(site.id);
                                                    setCheckedSite(dataSite);
                                                  } else {
                                                    setCheckedSite(
                                                      dataSite?.filter((id) => {
                                                        return id !== site.id;
                                                      })
                                                    );
                                                  }
                                                }}
                                                sx={{
                                                  "& .MuiSvgIcon-root": {
                                                    fontSize: 28,
                                                    color: "#41AD49",
                                                  },
                                                }}
                                              />
                                            }
                                            label={site.name || TEXT_HOLDER}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                )}
                                {typeValue === "sales-man" && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={CheckSaleManLabel(subTabSource)}
                                          onChange={(e, value) => {
                                            if (subTabSource === SourceType.FEED) {
                                              if (value === true) {
                                                dataSalesMan.push(
                                                  ...(salesMan?.map((a: any) => a.id) || [])
                                                );
                                                setCheckedSalesMan(
                                                  Array.from(new Set(dataSalesMan))
                                                );
                                              } else {
                                                setCheckedSalesMan([]);
                                              }
                                            } else if (subTabSource === SourceType.VET) {
                                              if (value === true) {
                                                dataSalesManVet.push(
                                                  ...(salesMan?.map((a: any) => a.id) || [])
                                                );
                                                setCheckedSalesManVet(
                                                  Array.from(new Set(dataSalesManVet))
                                                );
                                              } else {
                                                setCheckedSalesManVet([]);
                                              }
                                            } else if (subTabSource === SourceType.LAB) {
                                              if (value === true) {
                                                dataSalesManLab.push(
                                                  ...(salesMan?.map((a: any) => a.id) || [])
                                                );
                                                setCheckedSalesManLab(
                                                  Array.from(new Set(dataSalesManLab))
                                                );
                                              } else {
                                                setCheckedSalesManLab([]);
                                              }
                                            } else {
                                              if (value === true) {
                                                dataSalesManFarm.push(
                                                  ...(salesMan?.map((a: any) => a.id) || [])
                                                );
                                                setCheckedSalesManFarm(
                                                  Array.from(new Set(dataSalesManFarm))
                                                );
                                              } else {
                                                setCheckedSalesManFarm([]);
                                              }
                                            }
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                              color: "#41AD49",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("ALL")}
                                    />
                                    <FormGroup>
                                      {salesMan?.map((m: any) => {
                                        return (
                                          <FormControlLabel
                                            key={m.id}
                                            control={
                                              <Checkbox
                                                value={m.id}
                                                checked={
                                                  CheckSaleManGroup(subTabSource, m.id) ?? false
                                                }
                                                onChange={(_, value) => {
                                                  if (subTabSource === SourceType.FEED) {
                                                    if (value === true) {
                                                      dataSalesMan.push(m.id);
                                                      setCheckedSalesMan(dataSalesMan);
                                                    } else {
                                                      setCheckedSalesMan(
                                                        dataSalesMan?.filter((id) => {
                                                          return id !== m.id;
                                                        })
                                                      );
                                                    }
                                                  } else if (subTabSource === SourceType.VET) {
                                                    if (value === true) {
                                                      dataSalesManVet.push(m.id);
                                                      setCheckedSalesManVet(dataSalesManVet);
                                                    } else {
                                                      setCheckedSalesManVet(
                                                        dataSalesManVet?.filter((id) => {
                                                          return id !== m.id;
                                                        })
                                                      );
                                                    }
                                                  } else if (subTabSource === SourceType.LAB) {
                                                    if (value === true) {
                                                      dataSalesManLab.push(m.id);
                                                      setCheckedSalesManLab(dataSalesManLab);
                                                    } else {
                                                      setCheckedSalesManLab(
                                                        dataSalesManLab?.filter((id) => {
                                                          return id !== m.id;
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    if (value === true) {
                                                      dataSalesManFarm.push(m.id);
                                                      setCheckedSalesManFarm(dataSalesManFarm);
                                                    } else {
                                                      setCheckedSalesManFarm(
                                                        dataSalesManFarm?.filter((id) => {
                                                          return id !== m.id;
                                                        })
                                                      );
                                                    }
                                                  }
                                                }}
                                                sx={{
                                                  "& .MuiSvgIcon-root": {
                                                    fontSize: 28,
                                                    color: "#41AD49",
                                                  },
                                                }}
                                              />
                                            }
                                            label={m.fullName || TEXT_HOLDER}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                )}
                                {typeValue === "products" && (
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checkedProduct?.length === product?.length}
                                          onChange={(_, value) => {
                                            if (value === true) {
                                              dataProduct.push(
                                                ...(product?.map((a: any) => a.id) || [])
                                              );
                                              setCheckedProduct(Array.from(new Set(dataProduct)));
                                            } else {
                                              setCheckedProduct([]);
                                            }
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                              color: "#41AD49",
                                            },
                                          }}
                                        />
                                      }
                                      label={t("ALL")}
                                    />
                                    <FormGroup>
                                      {product?.map((pro: any) => {
                                        return (
                                          <FormControlLabel
                                            key={pro.id}
                                            control={
                                              <Checkbox
                                                checked={dataProduct?.some(
                                                  (some: any) => some === pro.id
                                                )}
                                                value={pro.id}
                                                onChange={() => {
                                                  dataProduct?.some((e: any) => e === pro.id)
                                                    ? setCheckedProduct(
                                                        dataProduct?.filter(
                                                          (e: any) => e !== pro.id
                                                        )
                                                      )
                                                    : setCheckedProduct([...dataProduct, pro.id]);
                                                }}
                                                sx={{
                                                  "& .MuiSvgIcon-root": {
                                                    fontSize: 28,
                                                    color: "#41AD49",
                                                  },
                                                }}
                                              />
                                            }
                                            label={pro.name || TEXT_HOLDER}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}>
                            <img
                              src={PermissionImg}
                              alt="coming-soon"
                              style={{ width: "38rem", height: "26rem", marginTop: "5rem" }}
                              loading="lazy"
                            />
                            <div style={{ fontWeight: 600, fontSize: "1.8rem" }}>
                              {t("ACCESS_DENIED")}
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                    <hr />
                    <div className={checkSource ? "right" : "right right-disable"}>
                      <div className="title">{t("MODULE")}</div>
                      {permissionAvailable?.map((pA) => {
                        const checkedQuantity = pA.permissionMasters.reduce(
                          (quan: number, cur: any) => {
                            if (dataPermissionAvailable?.includes(cur.id)) {
                              quan++;
                            }
                            return quan;
                          },
                          0
                        );

                        if (
                          pA.name === "Báo cáo" ||
                          pA.name === "Đơn hàng" ||
                          pA.name === "Khách hàng" ||
                          pA.name === "Sản phẩm" ||
                          pA.name === "Bảng giá" ||
                          pA.name === "Nhân viên" ||
                          pA.name === "Quản lý tài khoản khách hàng" ||
                          pA.name === "Tài khoản phụ" ||
                          pA.name === "Kế hoạch đặt hàng"
                        ) {
                          return (
                            <Accordion
                              key={pA.id}
                              defaultExpanded={checkedPermission?.length > 0}
                              disabled={!checkSource}
                              className="order">
                              <AccordionSummary
                                // expandIcon={
                                //   <ArrowDropDownIcon
                                //     style={{ color: "#0A6836", minWidth: 80, maxWidth: 400 }}
                                //   />
                                // }
                                className="">
                                <div className="order-header">
                                  <div className="order-left">
                                    {iConTitle(pA.name)}
                                    <div className="order-title">{pA.name}</div>
                                    <ArrowDropDownIcon
                                      style={{ color: "#0A6836", minWidth: "5rem", maxWidth: 400 }}
                                    />
                                  </div>
                                  <div className="order-right">
                                    <div className="count">
                                      {checkedQuantity + "/" + pA.permissionMasters?.length}
                                    </div>

                                    <Checkbox
                                      checked={checkedQuantity === pA.permissionMasters?.length}
                                      className="app-check-box"
                                      onChange={(_, value) => {
                                        if (value === true) {
                                          dataPermissionAvailable.push(
                                            ...(pA.permissionMasters?.map((a: any) => a.id) || [])
                                          );
                                          setCheckedPermission(
                                            Array.from(new Set(dataPermissionAvailable))
                                          );
                                        } else {
                                          // setCheckedPermission([]);
                                          setCheckedPermission(
                                            dataPermissionAvailable?.filter((id) => {
                                              return (
                                                id !==
                                                pA.permissionMasters.find((a: any) => a.id === id)
                                                  ?.id
                                              );
                                            })
                                          );
                                        }
                                      }}
                                      icon={
                                        <CheckBoxOutlineBlankIcon
                                          style={{
                                            fontSize: "2.2rem",
                                          }}
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon
                                          style={{
                                            fontSize: "2.2rem",
                                          }}
                                        />
                                      }
                                    />
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="des">{t("GRANT_ACCESS_FEATURE")}</div>
                                <hr
                                  style={{
                                    width: "50rem",
                                    height: "0.3rem",
                                    background: "#EBECF0",
                                    margin: "1rem 0",
                                  }}
                                />
                                <div className="list-permission">
                                  {pA.permissionMasters?.map((p: any) => {
                                    return (
                                      <div className="ordlist" key={p?.id}>
                                        <Checkbox
                                          className="app-check-box"
                                          value={p.id}
                                          checked={dataPermissionAvailable?.some(
                                            (some) => some === p.id
                                          )}
                                          onChange={(e, value) => {
                                            if (value === true) {
                                              dataPermissionAvailable.push(p.id);
                                              setCheckedPermission(dataPermissionAvailable);
                                            } else {
                                              setCheckedPermission(
                                                dataPermissionAvailable?.filter((id) => {
                                                  return id !== p.id;
                                                })
                                              );
                                            }
                                          }}
                                          icon={
                                            <CheckBoxOutlineBlankIcon
                                              style={{
                                                fontSize: "2.2rem",
                                              }}
                                            />
                                          }
                                          checkedIcon={
                                            <CheckBoxIcon
                                              style={{
                                                fontSize: "2.2rem",
                                              }}
                                            />
                                          }
                                        />
                                        <div className="ordtext">{p.name}</div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          );
                        }
                      })}
                    </div>
                  </Grid>
                </div>
              </form>
            );
          }}
        </Form>
      </div>

      <ModalFooter
        formId="create-role"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={type === "update" ? t("SAVE") : t("CREATE")}
      />
    </div>
  );
};
export default AuthorizeModal;
