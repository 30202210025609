// getById

import { ShippingAddressDetailModel, GetCustomerManageQuery } from "@Models/customer-manage.model";
import { CustomerManageService } from "@Services/customer-manage.service";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import {
  checkQueryPaginationNextPage,
  getCountFilter,
  getQueryPaginationData,
  getQueryPaginationTotal,
} from "@Utils";

export const useCustomerManageDetail = (customerId: number): {
  data: any,
  isLoading: boolean,
} => {
  const { data, isLoading } = useQuery({
    queryKey: ["customer-manage-detail", customerId],
    queryFn: () => {
      if (!customerId) {
        return;
      }
      return CustomerManageService.getCustomerRequestById(customerId);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 0
  });

  return {
    data: data?.data,
    isLoading,
  };
};


export const useCustomerManageInfinite = (params: Omit<GetCustomerManageQuery, "page">) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ["customer-employee-list", JSON.stringify(params)],
    queryFn: async ({ pageParam }) => {
      const query = {
        ...params,
        page: pageParam || 1,
      };
      return CustomerManageService.getAll(query);
    },
    getNextPageParam: (lastPage) => {
      return checkQueryPaginationNextPage(lastPage);
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    retry: false,
    cacheTime: 0
  });
  
  return {
    data: getQueryPaginationData(data),
    hasNextPage: hasNextPage,
    isLoading: isLoading,
    isFetching: isFetching,
    fetchNextPage: fetchNextPage,
    refetch: refetch,
    total: getQueryPaginationTotal(data),
    countFilter: getCountFilter(data)
  };
};
