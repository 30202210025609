import { z } from "zod";

const orderDetailSchema = z.object({
  customerFeedId: z.number({
    message: "Vui lòng chọn khách hàng",
  }),
  customerFeed: z.object({}).optional(),
  condition: z.string(),
  itemId: z.number(),
  divisionId: z.number(),
  discount: z.number(),
  note: z.string().optional(),
  address: z.string({
    message: "Vui lòng nhập địa chỉ giao hàng",
  }),
  requiredDate: z.union([
    z.date({ message: "Vui lòng chọn ngày giao hàng" }),
    z.string({ message: "Vui lòng chọn ngày giao hàng" }),
  ]),
  requiredTime: z.string({
    message: "Vui lòng chọn giờ giao hàng",
  }),
  quantity: z
    .number({
      message: "Số lượng phải lớn hơn 0",
    })
    .min(1, {
      message: "Số lượng phải lớn hơn 0",
    })
    .max(9999, {
      message: "Số lượng phải nhỏ hơn 9999",
    }),
  unitPrice: z.string(),
  saleUnit: z.string(),
  dimensionToShow: z.number(),
  baseUoM: z.string(),
  dimension: z.any().optional(),
  subTotal: z.any().optional(),
  item: z.object({}).optional(),
});

export const createOrderSchema = z.object({
  customerId: z.number(),
  creatorId: z.number(),
  salesOrgId: z.number(),
  distributionChannelId: z.number(),
  address: z.string()?.optional(),
  requiredDate: z.union([z.date(), z.string()])?.optional(),
  currency: z.string().optional(),
  salesOfficeId: z.number().optional(),
  totalDiscount: z.number().optional(),
  vat: z.number().optional(),
  orderAmount: z.number().optional(),
  isDraft: z.boolean().optional(),
  source: z.string().optional(),
  isInternal: z.boolean().optional(),
  orderDetails: z.array(orderDetailSchema),
  itemGroupTypeId: z.number(),
});

export type OrderDetailPayload = z.infer<typeof orderDetailSchema>;

export type CreateOrderPayload = z.infer<typeof createOrderSchema>;
