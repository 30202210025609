import { FeedBackModel, OrderPermission } from "@Models";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Fade, IconButton, Menu, MenuItem, Rating, TextField } from "@mui/material";
import { FeedBackService } from "@Services";
import {
  checkLinkImage,
  dateFormat,
  PermissionUtil,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import FeedBackModal from "../FeedBackModal";
import { useAppSelector } from "@Core/store/hook";
import { LoadMe } from "@Core/store/profile";
import "./index.scss";

type P_Props = {
  data: FeedBackModel;
  onSuccess: () => void;
};

const FeedBack: React.FC<P_Props> = ({ data, onSuccess }) => {
  const { orderId }: { orderId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { data: profile } = useAppSelector(LoadMe);
  const [feedBackUpdate, setFeedBackUpdate] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userReplies = data.replies?.map((item: any) => item.userReply) || [];

  const onCreateFeedback = async () => {
    FeedBackService.createFeedBack(orderId, data.id.toString(), { description: description })
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Phản hồi đã được gửi thành công!", {
            variant: "success",
          });
          onSuccess();
        }
      })
      .catch(() => {
        enqueueSnackbar("Phản hồi gửi thất bại", {
          variant: "error",
        });
      });
  };

  const onUpdateFeedback = async (
    replyId: string,
    isDelete?: boolean,
    dataDescription?: string
  ) => {
    FeedBackService.updateFeedBack(orderId, data.id.toString(), replyId, {
      description: !!description ? description : dataDescription ?? " ",
      isDelete: isDelete ? "true" : undefined,
    })
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (isDelete) {
            enqueueSnackbar("Xoá phản hồi thành công!", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Chỉnh sửa phản hồi thành công!", {
              variant: "success",
            });
          }
          setFeedBackUpdate(null);
          setDescription("");
          onSuccess();
        }
      })
      .catch(() => {
        if (isDelete) {
          enqueueSnackbar("Xoá phản hồi thất bại!", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Chỉnh sửa phản hồi thất bại!", {
            variant: "error",
          });
        }
      });
  };

  const checkPermissionOrderFeedback = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_FEEDBACK_REPLY,
    true
  );

  const checkUser = userReplies?.find((f) => f.id === profile?.id);

  return (
    <div className="feed-back">
      <div className="feed-back__title">Phản hồi và trả lời phản hồi</div>
      <div className="feed-back__content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <div className="feed-back__content__img">
              <img
                src={checkLinkImage(data.customer.account.avatar as any) || renderImageProNull()}
                alt={data.customer.account.nickname}
                onError={renderErrorImageItem()}
              />
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="feed-back__content__nickname">{data.customer.account.nickname}</div>
                <div className="feed-back__content__date">{dateFormat(data.updatedAt, true)}</div>
              </div>
              <Rating value={data.rating} readOnly style={{ fontSize: 18, margin: "2px 0" }} />
              <div>{data.description}</div>
            </div>
          </div>
        </div>

        {data?.replies?.length > 0 &&
          data.replies?.map((item: any) => {
            return (
              <div
                key={item?.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "3rem 0",
                  width: "100%",
                }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}>
                  <div className="feed-back__content__img">
                    <img
                      src={checkLinkImage(item?.userReply?.avatar as any) || renderImageProNull()}
                      alt={item?.userReply?.nickname}
                      onError={renderErrorImageItem()}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    {feedBackUpdate && feedBackUpdate === item.id ? (
                      <div>
                        <div style={{ marginBottom: 8, marginTop: 16 }}>Phản hồi</div>
                        <TextField
                          value={description}
                          placeholder={"Nhập... (tối đa 500 ký tự)"}
                          color="success"
                          className="form-textarea-field"
                          inputProps={{ className: "input" }}
                          rows={2}
                          multiline
                          fullWidth
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div
                            className="btn-cancel"
                            style={{ marginTop: 6 }}
                            onClick={() => setFeedBackUpdate(null)}>
                            Hủy
                          </div>
                          <button
                            className="btn-confirm-style"
                            onClick={() => {
                              !!description
                                ? onUpdateFeedback(feedBackUpdate.toString())
                                : enqueueSnackbar("Phản hồi không được để trống!", {
                                    variant: "error",
                                  });
                            }}>
                            Gửi
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="feed-back__content__nickname">
                            {item?.userReply?.nickname}
                          </div>
                          <div className="feed-back__content__date">
                            {dateFormat(item?.updatedAt, true)}
                          </div>
                        </div>
                        <div>{item?.description}</div>
                      </>
                    )}
                  </div>
                </div>
                {profile?.id === item?.userReply?.id && !feedBackUpdate && (
                  <>
                    <IconButton
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}>
                      <MoreVertIcon style={{ fontSize: 20 }} />
                    </IconButton>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}>
                      <MenuItem
                        onClick={() => {
                          setFeedBackUpdate(item?.id);
                          setDescription(item.description);
                          handleClose();
                        }}>
                        Chỉnh sửa
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onUpdateFeedback(item?.id, true, item.description);
                          handleClose();
                        }}
                        style={{ width: "100%" }}>
                        Xoá
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            );
          })}

        {checkPermissionOrderFeedback && !checkUser && (
          <div>
            <div style={{ marginBottom: 8, marginTop: 16 }}>Phản hồi</div>
            <TextField
              value={description}
              placeholder={"Nhập... (tối đa 500 ký tự)"}
              color="success"
              className="form-textarea-field"
              inputProps={{ className: "input" }}
              rows={2}
              multiline
              fullWidth
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn-confirm-style"
                onClick={() => {
                  !!description
                    ? onCreateFeedback()
                    : enqueueSnackbar("Phản hồi không được để trống!", {
                        variant: "error",
                      });
                }}>
                Gửi
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedBack;
