import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import RangePicker from "@Components/RangePicker";
import { APP_ROUTES, NavHeaderLead, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import useDateRange from "@Hooks/useDateRange";
import { Action, FetchAction, LeadModal } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService } from "@Services";
import { StatusUIUtil } from "@Utils";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

function reducer(state: PaginateFetchReducer<LeadModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const Lead: React.FC = () => {
  const { t } = useTranslation(["translation", "customer"]);
  const [range, setRange] = useDateRange();
  const history = useHistory();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string | undefined>(undefined);
  const [getLead, setGetLead] = useState<boolean>(false);
  const location = useLocation<any>();
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  useEffect(() => {
    location.state?.status !== undefined
      ? setSelectTab(location.state?.status)
      : setSelectTab(undefined);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setGetLead(true);

    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
    // eslint-disable-next-line
  }, [location, profile]);

  const [state, dispatchLead] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatchLead({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllLead(query);
      if (isReset)
        dispatchLead({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatchLead({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatchLead({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    getLead &&
      fetchData(
        {
          page: 1,
          limit: 50,
          searchString: searchValue,
          status: selectTab,
          from: range.startTime.toISOString(),
          to: range.endTime.toISOString(),
        },
        true
      );
    // eslint-disable-next-line
  }, [searchValue, selectTab, range.startTime.toISOString(), range.endTime.toISOString()]);

  const hasMore = state.data?.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  return (
    <div className="lead">
      <div className="lead-content">
        <div className="action">
          <RangePicker
            startDate={
              location.state?.startTime !== undefined
                ? moment(location.state?.startTime)
                : range.startTime
            }
            endDate={
              location.state?.endTime !== undefined
                ? moment(location.state?.endTime)
                : range.endTime
            }
            onChangeDate={(val) => {
              if (val && val.length) {
                setRange(
                  moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                  moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                );
              }
            }}
          />
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="lead__nav-header">
          {NavHeaderLead.map((o, idx: number) => {
            return (
              <div
                key={o.id + idx}
                className={o.value === selectTab ? "tab tab-active" : "tab tab-hover"}
                onClick={() => {
                  setSelectTab(o.value);
                }}>
                {o.label}
                <span>{state.counting && state.counting[`${o.title}`]}</span>
              </div>
            );
          })}
        </div>

        <div className="list">
          <div className="list-header">
            <div className="act">{t("customer:LEAD")}</div>
            <div className="createAt">{t("customer:REGISTRATION_DATE")}</div>
            <div className="phone">{t("PHONE")}</div>
            <div className="idCard">CMND/CCCD</div>
            <div className="sale-org">{t("BUSINESS_ORGANIZATION")}</div>
            <div className="source">{t("customer:REGISTRATION_SECTOR")}</div>
            <div className="status">{t("STATUS")}</div>
          </div>
          {state.loading ||
          (state.fetching && (searchValue || selectTab || (range.startTime && range.endTime))) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line
            <>
              {state.data ? (
                state.data?.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        status: selectTab,
                        searchString: searchValue,
                        from: range.startTime.toISOString(),
                        to: range.endTime.toISOString(),
                      })
                    }>
                    {state.data.map((act: LeadModal) => {
                      return (
                        <div
                          className="item"
                          key={act.code}
                          onClick={() => {
                            // history.push(
                            //   APP_ROUTES.LEAD_DETAIL.replace(":leadId", act.id.toString())
                            // );
                            history.push({
                              pathname: APP_ROUTES.LEAD_DETAIL.replace(
                                ":leadId",
                                act.id.toString()
                              ),
                              state: {
                                startTime: range.startTime.toISOString(),
                                endTime: range.endTime.toISOString(),
                                status: selectTab,
                              },
                            });
                          }}>
                          <div className="act">
                            {act.fullName}
                            <div className="code">{act.code || TEXT_HOLDER}</div>
                          </div>
                          <div className="createAt">
                            {moment(act.createdAt).format("DD/MM/YYYY HH:mm")}
                          </div>
                          <div className="phone">{act.phoneNumber || TEXT_HOLDER}</div>
                          <div className="idCard">{act.idCard || TEXT_HOLDER}</div>
                          <div className="sale-org">{act.salesOrg?.description || TEXT_HOLDER}</div>
                          <div className="source">
                            {(() => {
                              for (const source of act.sources) {
                                return StatusUIUtil.renderSourceLead(source) || TEXT_HOLDER;
                              }
                            })()}
                          </div>
                          <div className="status">
                            {StatusUIUtil.renderStatusLead(act.status) || TEXT_HOLDER}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Lead;
