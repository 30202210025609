import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Checkbox)(({ theme, color, disabled, size }) => ({
  "&.MuiCheckbox-root": {
    padding: 0,
    width: "3.2rem",
    height: "3.2rem",

    "&.Mui-checked": {
      color: "var(--secondary-green)",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "3.2rem",
    },
    ...(size === "small" && {
      width: "2.4rem",
      height: "2.4rem",
      "& .MuiSvgIcon-root": {
        fontSize: "2.4rem",
      },
    }),

    "&.Mui-disabled": {
      cursor: "not-allowed !important",
      pointerEvents: "auto",
      "& .MuiSvgIcon-root": {
        fill: "var(--primary-gray-2)",
        cursor: "not-allowed",
      },
    },
  },
}));
