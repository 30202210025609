import {
  GFAutoComplete,
  GFDatePicker,
  GFFormItem,
  GFIconButton,
  GFLabel,
  GFTextField,
} from "@Components/ui";
import GFAddressSelect from "@Components/ui/GFAddressSelect";
import GFProductItem from "@Components/ui/GFProductItem";
import GFQuantityButton from "@Components/ui/GFQuantityButton";
import GFSelectVirtualized from "@Components/ui/GFSelectVirtualized";
import { CustomerOutputModel, SourceType } from "@Models";
import { DeleteOutline } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import _, { isEmpty, isNil } from "lodash";
import { Control, Controller, useController, useWatch } from "react-hook-form";
import { useSAPDeliveryTimes } from "src/app/queries/product.query";
import { SelectOption } from "src/types";
import "./index.scss";

type OrderBoarSemenItemProps = {
  control: Control<any>;
  rowIndex: number;
  item: any;
  customerFeeds: CustomerOutputModel[];
  onRemoveProduct: (index: number) => void;
  onSetValue: any;
};

export default function OrderBoarSemenItem({
  control,
  rowIndex,
  customerFeeds = [],
  item,
  onRemoveProduct,
  onSetValue,
}: OrderBoarSemenItemProps) {
  const { data } = useSAPDeliveryTimes({ page: 1, limit: 99 });

  const customerOptions = customerFeeds.map((customer) => ({
    ...customer,
    label: customer.fullName,
    value: customer.id,
  }));

  return (
    <div className="order-boarsemen-item" key={`orderDetails.${rowIndex}.requiredDate`}>
      <div className="order-boarsemen-item__content">
        <Grid container columnSpacing={4}>
          <Grid item xs={6}>
            <GFProductItem
              name={_.get(item, "item.item.name")}
              material={_.get(item, "item.item.code")}
              source={_.get(item, "item.item.source")}
              avatarUrl={_.get(item, "item.item.images")}
              size="small"
              hover={false}
              shơwPrice={false}
            />
          </Grid>
          <Grid item xs={2}>
            <Box ml={5}>
              <GFLabel>Số lượng</GFLabel>
            </Box>
            <GFFormItem control={control} name={`orderDetails.${rowIndex}.quantity`}>
              <GFQuantityButton min={1} max={9999} />
            </GFFormItem>
          </Grid>
          <Grid item xs={4}>
            <GFFormItem control={control} name={`orderDetails.${rowIndex}.customerFeedId`}>
              <GFSelectVirtualized
                options={customerOptions}
                label={"Khách hàng"}
                required
                placeholder={"Chọn khách hàng"}
                size="small"
                onChange={(value, option) => {
                  onSetValue(`orderDetails.${rowIndex}.customerFeed`, option);
                }}
              />
            </GFFormItem>
          </Grid>
          <Grid item xs={3}>
            <GFFormItem control={control} name={`orderDetails.${rowIndex}.requiredDate`}>
              <GFDatePicker label="Ngày giao hàng" required size="small" disablePast/>
            </GFFormItem>
          </Grid>
          <Grid item xs={2}>
            <GFFormItem control={control} name={`orderDetails.${rowIndex}.requiredTime`}>
              <GFAutoComplete
                label="Giờ giao hàng"
                required
                size="small"
                options={data}
                placeholder="Chọn giờ giao hàng"
              />
            </GFFormItem>
          </Grid>
          <Grid item xs={3}>
            <GFFormItem control={control} name={`orderDetails.${rowIndex}.note`}>
              <GFTextField label="Ghi chú" fullWidth size="small" placeholder="Nhập ghi chú" />
            </GFFormItem>
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={`orderDetails.${rowIndex}.customerFeed`}
              render={({ field }) => {
                return (
                  <GFFormItem control={control} name={`orderDetails.${rowIndex}.address`}>
                    <GFAddressSelect
                      customer={field.value || { id: "" }}
                      disabled={isNil(field.value?.id)}
                      label="Chọn địa chỉ"
                      required
                      sourceType={SourceType.FARM}
                    />
                  </GFFormItem>
                );
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className="order-boarsemen-item__delete">
        <GFIconButton onClick={() => onRemoveProduct(rowIndex)} size="medium" type="button">
          <DeleteOutline />
        </GFIconButton>
      </div>
    </div>
  );
}
