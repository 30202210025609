import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import GFIconButton from "@Components/ui/GFIconButton";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

type ActionColumnType = {
  onEdit?: () => void;
  onDelete?: () => void;
  showEdit?: boolean;
  showDelete?: boolean;
};

export const actionColumn = ({
  showEdit,
  showDelete,
  onEdit,
  onDelete,
}: ActionColumnType): GFDataTableColumn => ({
  dataIndex: "action",
  title: "ACTION",
  render: () => (
    <Box display="flex" flexWrap="nowrap" gap={2}>
      {showEdit && (
        <GFIconButton onClick={onEdit} size="medium">
          <EditOutlined />
        </GFIconButton>
      )}
      {showDelete && (
        <GFIconButton size="medium">
          <DeleteOutline onClick={onDelete} />
        </GFIconButton>
      )}
    </Box>
  ),
});
