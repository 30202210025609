/* eslint-disable array-callback-return */
import Avatar from "@Assets/images/avt.png";
import Logo from "@Assets/images/logo.png";
import Notification from "@Components/Notification";
import PushNotification from "@Components/PushNotification";
import {
  APP_ROUTES,
  BASE_CUSTOMER_URL,
  DataLanguageHeader,
  STORAGE,
  SubTab,
  TEXT_HOLDER,
} from "@Constants";
import { AuthService, CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import {
  ClearRounded as ClearRoundedIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";
import {
  Box,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
  Paper,
  Popper,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
// import { MenuItem } from "@mui/base/MenuItem";
import { CSSObject, Theme, ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { AxiosError } from "axios";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import SubNavbar from "./SubNavbar";
import "./index.scss";
import moduleList, { DEFAULT_COLOR } from "./routes";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const openedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up("md")]: {
    width: 164,
  },
  [theme.breakpoints.up("lg")]: {
    width: 320,
    position: 'relative'
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 164px)`,
      marginLeft: 164,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 320px)`,
      marginLeft: 320
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    [theme.breakpoints.up("md")]: {
      width: 164,
    },
    [theme.breakpoints.up("lg")]: {
      width: 320
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const detechDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? "Mobile"
    : "Desktop";

const LayoutComponent: React.FC = (props) => {
  const { t, i18n } = useTranslation(["translation", "order"]);
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [openProfileUser, setOpenProfileUser] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);
  const [notiActivated, setNotiActivated] = useState<{
    message: string;
    status: boolean;
  }>({
    message: "",
    status: false,
  });

  const [dataLanguage, setDataLanguage] = useState<any>(DataLanguageHeader[0]);
  // const { pathURL }: { pathURL: string } = useParams();

  const firebaseToken = localStorage.getItem("firebaseToken");

  const deActiveToken = async () => {
    await AuthService.deActiveTokenDevice({
      token: firebaseToken,
    });
  };

  const onLogout = () => {
    if (firebaseToken) deActiveToken();
    localStorage.removeItem("admin-access-token");
    window.location.href = `${BASE_CUSTOMER_URL + `/sign-in`}`;
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const currentRoute = moduleList.find((module) => {
  //   const childRoutes = module.subRoutes.map((s) => s.path);
  //   const paths = [module.path, ...childRoutes];
  //   return paths.some((p) => history.location.pathname.includes(p));
  // });

  const currentRoute = React.useMemo(() => {
    const routes = moduleList.map(({ path }) => path).reverse();
    const selected = routes.find((route) => location.pathname.includes(route));
    return selected;
  }, [location.pathname]);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "rgba(0,0,0,0.75)",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const selectedRoute = React.useMemo(() => {
    const selected = moduleList.find((route) => currentRoute === route.path);

    return selected;
  }, [currentRoute]);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpenProfileUser((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenProfileUser(false);
    } else if (event.key === "Escape") {
      setOpenProfileUser(false);
    }
  }

  const setDefaultImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Avatar;
    event.currentTarget.className = "error";
  };

  const prevOpen = React.useRef(openProfileUser);
  React.useEffect(() => {
    if (prevOpen.current === true && openProfileUser === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openProfileUser;
  }, [openProfileUser]);

  //set image avatar and if token expired => clear cache
  useEffect(() => {
    const fetchData = async () => {
      await CustomerService.getAuthProfile()
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setProfile(res.data);
          }
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            //cần call API Refresh Token để xử lý phần jwt expired --> pending
            if (
              err.response?.data.message === "SESSION_EXPIRED" ||
              err.response?.data.message === "JWT_EXPIRED"
            ) {
              setNotiActivated({ message: "SESSION_EXPIRED", status: true });
            } else {
              setNotiActivated({ message: "UNAUTHORIZED", status: true });
            }
          }
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    detechDevice();
    const languageKey = localStorage.getItem(STORAGE.LOCAL_LANGUAGE);
    if (languageKey) {
      const dataStoreLanguage = DataLanguageHeader.find((i) => i.code === languageKey);
      setDataLanguage(dataStoreLanguage);
    }
  }, [profile]);

  const checkURL = SubTab.filter(
    (s) =>
      profile?.role?.dataAccessPermissions?.some((d: any) => d.source === s.source) ||
      profile?.employee?.some((d: any) => d.source === s.source)
  ).filter((s) => profile?.role?.permissions.includes(s.permission));

  const checkPermissionDashBoard = SubTab.some((s) =>
    profile?.role?.permissions.includes(s.permission)
  );

  const sourceEmploy = profile?.employee.map((s: any) => {
    return s.source;
  });

  const sourceRole = profile?.role?.dataAccessPermissions.map((s: any) => {
    return s.source;
  });

  useEffect(() => {
    if (checkPermissionDashBoard && checkURL) {
      history.push(checkURL[0].path + "-" + checkURL[0].source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === APP_ROUTES.DASHBOARD + "-" + checkURL?.[0]?.source]);
  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} className="app-bar">
        <Toolbar className="app-toolbar">
          <div className="app-toolbar-left">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none !important" }),
              }}>
              <MenuIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="title"
              style={{ paddingLeft: open ? "3rem" : "" }}>
              {t(selectedRoute?.title || TEXT_HOLDER)}
            </Typography>
          </div>
          <div className="app-toolbar-right">
            <div className="language">
              <Select
                className="language-select"
                variant="standard"
                disableUnderline
                value={dataLanguage.id}
                displayEmpty
                MenuProps={{
                  className: "select-menu-list select-menu-sale-org",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {DataLanguageHeader.map((i) => {
                  return (
                    <MenuItem
                      key={i.id}
                      value={i.id}
                      onClick={() => {
                        setDataLanguage(i);
                        localStorage.setItem(STORAGE.LOCAL_LANGUAGE, i.code);
                        i18n.changeLanguage(i.code);
                        window.location.reload();
                      }}>
                      <div className="language-content">
                        <img className="img-right" src={i.icon} alt="right" />
                        <span style={{ paddingLeft: "1rem" }}>{i.label}</span>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="notification">
              <PushNotification />
            </div>
            <div className="menu-user">
              <button
                className="user_menu_btn"
                ref={anchorRef}
                id="composition-button"
                aria-controls={openProfileUser ? "composition-menu" : undefined}
                aria-expanded={openProfileUser ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <img
                  style={{ height: "3.5rem", width: "3.5rem", borderRadius: "50%" }}
                  src={checkLinkImage(profile?.avatar) || Avatar}
                  alt="avt"
                  onError={setDefaultImage}
                />
              </button>
            </div>
            <Popper
              open={openProfileUser}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom-start" ? "right-end" : "left bottom",
                  }}>
                  <Paper>
                    <MenuList
                      autoFocusItem={openProfileUser}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}>
                      <MenuItem
                        style={{ fontSize: "1.5rem", fontFamily: "SVN-Gotham" }}
                        onClick={(e) => {
                          e.preventDefault();
                          onLogout();
                        }}>
                        Đăng xuất
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} PaperProps={{
        className: "gf-sidebar",
      }}>
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <img className="DrawerHeader_img" src={Logo} alt="greenfeed-logo" />
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
          </IconButton>
        </DrawerHeader>
        <ul className={`nav-list ${open ? 'open' : ''}`}>
          {moduleList.map(
            ({ label, defaultIcon, activeIcon, path, subRoutes, permission, withoutSuffix }) => {
              //Chi minh Van hieu

              const selected = currentRoute === path;

              //permission module Bao cao la 1 array
              if (
                permission &&
                Array.isArray(permission) &&
                !profile?.role?.permissions.some((per: any) => permission.includes(per))
              ) {
                return null;
              } else if (
                permission &&
                !Array.isArray(permission) &&
                !profile?.role?.permissions.includes(permission)
              ) {
                return null;
              }

              // const checkLinkReportFarm = profile?.role?.permissions.includes(
              //   ReportPermission.MANAGE_REPORT_DEBT
              // )
              //   ? "/dashboard/debt-farm"
              //   : "/dashboard/report-revenue-farm";

              return (
                <Link
                  key={label}
                  to={
                    subRoutes === undefined || withoutSuffix
                      ? path
                      : path !== "/authorization" && path !== "/account"
                      ? path +
                        "-" +
                        (profile?.employee?.length > 0
                          ? profile?.employee[0]?.source
                          : profile?.role?.dataAccessPermissions[0]?.source)
                      : path + "-account"
                  }
                  replace>
                  <li
                    className={`${"nav-item"} ${path === APP_ROUTES.SETTING ? "away" : ""}`}
                    style={{ padding: open ? "0.5rem" : "" }}>
                    {open ? (
                      <button
                        role="tab"
                        className={selected ? "btn-icon btn--shiny" : "btn-icon"}
                        aria-selected={selected}
                        style={{ padding: open ? "0.9rem" : "" }}>
                        <span>{selected ? activeIcon : defaultIcon}</span>
                      </button>
                    ) : (
                      <ThemeProvider theme={theme}>
                        <Tooltip arrow placement="right" title={<span>{t(label)}</span>}>
                          <button
                            role="tab"
                            className={selected ? "btn-icon btn--shiny" : "btn-icon btn-hover"}
                            aria-selected={selected}>
                            <span>{selected ? activeIcon : defaultIcon}</span>
                          </button>
                        </Tooltip>
                      </ThemeProvider>
                    )}
                    <div
                      className="label"
                      style={{
                        color: selected ? "#0A6836" : DEFAULT_COLOR,
                        fontWeight: selected ? "bold" : "normal",
                      }}>
                      {t(label)}
                    </div>
                  </li>
                </Link>
              );
            }
          )}
        </ul>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: "#F0F3F8",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <DrawerHeader />

        {(profile?.employee?.length > 0
          ? profile?.employee?.length > 1
          : profile?.role?.dataAccessPermissions?.length > 1) &&
          selectedRoute?.subRoutes &&
          selectedRoute?.subRoutes?.length > 1 &&
          // selectedRoute?.title === "Phân quyền" &&
          selectedRoute?.subRoutes?.some((r) => r.label !== "PERMISSION") && (
            <SubNavbar
              routes={selectedRoute?.subRoutes}
              // check SubNavbar theo source.length
              source={
                profile?.employee
                  ? profile.employee[0]?.source
                  : profile?.role?.dataAccessPermissions[0].source
              }
            />
          )}
        {selectedRoute?.title === "PERMISSION" &&
          selectedRoute?.subRoutes?.some((r) => r.label === "PERMISSION") && (
            <SubNavbar
              routes={selectedRoute?.subRoutes}
              source={
                profile?.employee
                  ? profile.employee[0]?.source
                  : profile?.role?.dataAccessPermissions[0].source
              }
            />
          )}

        {/* {selectedRoute?.title === "ORDER" && (
          <SubNavbar
            routes={selectedRoute?.subRoutes}
            source={
              profile?.employee
                ? profile.employee[0]?.source
                : profile?.role?.dataAccessPermissions[0].source
            }
          />
        )} */}
        {/* check do nao ranh check lai  */}
        {
          // (profile?.employee?.length === 1 && profile?.role?.dataAccessPermissions?.length === 0) ||
          //   (profile?.role?.dataAccessPermissions?.length === 1 && profile?.employee?.length === 0) ||
          profile?.employee?.length <= 1 &&
            profile?.role?.dataAccessPermissions?.length <= 1 &&
            // sourceRole[0] === sourceEmploy[0] &&
            selectedRoute?.path === APP_ROUTES.DASHBOARD &&
            checkURL?.length > 1 && (
              <div
                className="sub-tab-menu"
                style={{
                  // check source feed
                  // padding: location.pathname.includes("feed") ? "1.6rem 4rem" : 0,
                  padding: "1.6rem 4rem ",
                }}>
                {(() => {
                  let checkSubtab = checkURL
                    .filter((f) => f.source === location.pathname.slice(-4))
                    .map(({ label, path, source, permission }) => {
                      const classes = clsx(
                        "nav-item",
                        path + "-" + source === location.pathname && "tab-green-style btn--shiny"
                      );
                      if (profile?.role?.permissions.includes(permission))
                        if (!!sourceRole[0] && !!sourceEmploy[0]) {
                          if (sourceRole[0] === sourceEmploy[0])
                            return (
                              <Link key={label} to={path + "-" + source}>
                                <div className={classes}>
                                  <span className="title">{t(label)}</span>
                                </div>
                              </Link>
                            );
                        }
                      return (
                        <Link key={label} to={path + "-" + source}>
                          <div className={classes}>
                            <span className="title">{t(label)}</span>
                          </div>
                        </Link>
                      );
                    });
                  checkSubtab = _.compact(checkSubtab);

                  return checkSubtab?.length > 0 && checkSubtab;
                })()}
              </div>
            )
        }
        <div className="layout__content__body">{props.children}</div>
      </Box>

      <Notification
        visible={notiActivated.status}
        title={
          notiActivated.message === "SESSION_EXPIRED" || notiActivated.message === "JWT_EXPIRED"
            ? "Phiên đăng nhập đã hết hạn"
            : "Tài khoản đã bị vô hiệu hóa"
        }
        subTitle={
          notiActivated.message === "SESSION_EXPIRED" ? (
            <span>Quý khách vui lòng đăng nhập lại</span>
          ) : (
            <span>
              Quý khách vui lòng liên hệ nhân viên kinh doanh GreenFeed <br />
              để được hỗ trợ kích hoạt tài khoản
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box red`}>
            <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              localStorage.removeItem("admin-access-token");
              // window.location.reload();
              // history.push(APP_ROUTES.SIGNIN);
              window.location.href = `${BASE_CUSTOMER_URL + `/sign-in`}`;
            }}>
            {notiActivated.message === "SESSION_EXPIRED" || notiActivated.message === "JWT_EXPIRED"
              ? t("CLOSE")
              : "Quay trở lại"}
          </button>
        }
        onClose={() => {
          localStorage.removeItem("admin-access-token");
          window.location.reload();
          history.push(APP_ROUTES.SIGNIN);
        }}
      />
    </Box>
  );
};
export default LayoutComponent;
