import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const GFBackdrop = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
