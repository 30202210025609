/* eslint-disable array-callback-return */
import { APP_ROUTES } from "@Constants";
import { ButtonType, FARM_ORDER_SUB_STATUS, OrderStatusFarm } from "@Models";

export const OrderUtil = {
  buildOrderDetailPath,
  checkQuantityOrderFarm,
};

function buildOrderDetailPath(orderId: string): string {
  return orderId ? APP_ROUTES.ORDER_DETAIL_FEED.replace(":orderId", orderId) : APP_ROUTES.ORDER;
}

function checkQuantityOrderFarm(quantity: number, value: any) {
  const operator = value.match(/[<>=]/)[0];
  const compareTo = Number(value.replace(/[^0-9.-]+/g, ""));
  if (value.includes(">||>")) {
    if (quantity < +value?.match(/[0-9]+/g)?.[0] || +value?.match(/[0-9]+/g)?.[1] < quantity) {
      return true;
    }
  } else if (value.includes("<&&<")) {
    if (quantity > +value?.match(/[0-9]+/g)?.[0] && +value?.match(/[0-9]+/g)?.[1] > quantity) {
      return true;
    }
    // eslint-disable-next-line
  } else if (eval(quantity + operator + compareTo)) {
    return true;
  }

  return false;
}

const listButtonDisplay = [
  // Button tách đơn hàng
  {
    type: ButtonType.SPLIT_ORDER,
    statusDisplay: [
      {
        status: OrderStatusFarm.CONFIRMED,
        subStatus: [
          FARM_ORDER_SUB_STATUS.ALLOCATED,
          FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP,
        ],
      },
    ],
  },
  // Button điều phối trại
  {
    type: ButtonType.SITE_ORDER,
    statusDisplay: [
      {
        status: OrderStatusFarm.CONFIRMED,
        subStatus: [
          FARM_ORDER_SUB_STATUS.ALLOCATED,
          FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP,
        ],
      },
    ],
  },
  // Button điều phối xe
  {
    type: ButtonType.VEHICLE,
    statusDisplay: [
      {
        status: OrderStatusFarm.CONFIRMED,
        subStatus: [
          FARM_ORDER_SUB_STATUS.FIRST_DEPOSIT_MADE,
          FARM_ORDER_SUB_STATUS.FIRST_DEBT_APPROVAL_GRANTED,
        ],
      },
    ],
  },
  // Button Ghép xe
  {
    type: ButtonType.COMBINE,
    statusDisplay: [
      {
        status: OrderStatusFarm.CONFIRMED,
        subStatus: [
          FARM_ORDER_SUB_STATUS.FIRST_DEPOSIT_MADE,
          FARM_ORDER_SUB_STATUS.FIRST_DEBT_APPROVAL_GRANTED,
        ],
      },
    ],
  },
  // Button đóng đơn hàng
  {
    type: ButtonType.CLOSE_ORDER,
    statusDisplay: [
      {
        status: OrderStatusFarm.VERIFIED,
        subStatus: [],
      },
      {
        status: OrderStatusFarm.HALF_COMPLETED,
        subStatus: [],
      },
    ],
  },
  {
    type: ButtonType.DELIVERY_NOTE,
    statusDisplay: [
      {
        status: OrderStatusFarm.VERIFIED,
        subStatus: [],
      },
      {
        status: OrderStatusFarm.HALF_COMPLETED,
        subStatus: [],
      },
      {
        status: OrderStatusFarm.COMPLETED,
        subStatus: [],
      },
    ],
  },
];

// Check hiển thị button với status
export const checkButtonDisplayWithStatus = (
  status: any,
  type: keyof typeof ButtonType,
  subStatus: any
) => {
  const object = listButtonDisplay.find((item) => item.type === type);
  let display = false;
  if (object) {
    object.statusDisplay.map((item, index) => {
      if (item.status === status) {
        // Trường hợp không truyền subStatus nghĩa là chỉ cần đúng status là được
        if (!subStatus) {
          display = true;
        }
        // Trường hợp có truyền subStatus thì subStatus phải includes trong list mới hiển thị
        else {
          display = item.subStatus.includes(subStatus);
        }
      }
    });
  }
  return display;
};

// Check hiển thị button với từng orderlines hoặc childOrders
export const checkButtonDisplayWithChildOrders = (
  order: any,
  type: "orderlines" | "childOrders"
) => {
  if (type === "childOrders") {
    return true;
  }
  // Trường hợp type là orderlines => check order có childOrders không
  // 1. Có => Không hiển thị
  // 2. Không => Hiển thị
  else {
    return order.childOrders && order.childOrders.length > 0 ? false : true;
  }
};
