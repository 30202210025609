import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useMemo } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import GFLabel from "@Components/ui/GFLabel";
import GFTypography from "@Components/ui/GFTypography";
import { isFunction } from "lodash";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { SelectOption } from "src/types";
import GFTextField from "@Components/ui/GFTextField";
import GFTooltip from "@Components/ui/GFTooltip";

const ITEM_HEIGHT = 32;
const MAX_VISIBLE_ITEMS = 6;

const VirtualizedListbox = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function VirtualizedListbox(props, ref) {
    const { children, ...other } = props;

    const items = React.Children.toArray(children);

    const renderRow = ({ index, style }: ListChildComponentProps) => {
      const item = items[index];
      return (
        <GFTypography
          style={{
            ...style,
            height: 60,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          key={index}
          {...props}>
          {item}
        </GFTypography>
      );
    };

    return (
      <div ref={ref} {...other}>
        <FixedSizeList
          height={Math.min(items.length, MAX_VISIBLE_ITEMS) * ITEM_HEIGHT}
          itemCount={items.length}
          itemSize={ITEM_HEIGHT}
          width="100%">
          {renderRow}
        </FixedSizeList>
      </div>
    );
  }
);

const GFSelectVirtualized: React.FC<{
  label?: string;
  options?: (SelectOption | any)[];
  value?: any;
  error?: any;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  translation?: boolean;
  size?: "small" | "medium";
  onChange?: (value: any, option: any) => void;
}> = ({
  value = null,
  options = [],
  label,
  error,
  required,
  translation = false,
  placeholder,
  onChange,
  size = "medium",
}) => {
  const { t } = useTranslation("translation");

  const handleChange = (event: React.SyntheticEvent, newData: SelectOption | null) => {
    if (isFunction(onChange)) {
      onChange(newData?.value ?? null, newData);
    }
  };

  const tOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      label: translation ? t(option.label) : option.label,
      value: option.value,
    }));
  }, [options, t, translation]);

  return (
    <FormControl fullWidth>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <Autocomplete
        value={tOptions.find((option) => option.value === value) || null}
        onChange={handleChange}
        options={tOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <GFTextField {...params} placeholder={placeholder} error={error} size={size} />
        )}
        ListboxComponent={VirtualizedListbox}
        disableListWrap
      />
    </FormControl>
  );
};

export default GFSelectVirtualized;
