import { APPROVAL_PERSON, CUSTOMER_MANAGE_REQUEST_TYPE, REQUEST_URL } from "@Constants";
import { PaginatedQueryDto } from "@Models";
import { CreateCustomerManageModel, CreateCustomerShippingAddressModel, GetCustomerManageQuery } from "@Models/customer-manage.model";
import { RequestUtil } from "@Utils";
import { GetEmployeePayload } from "src/dtos/employee.dto";
import { ApprovalProcessPayload } from "src/schemas/approval-process.schema";
import { CheckCustomerDuplicatePayload } from "src/schemas/customer-checkduplicate.schema";
import { CreateCustomerShippingAddressPayload } from "src/schemas/customer-shipping-address.schema";

export const CustomerManageService = {
  getAll: (query: GetCustomerManageQuery) => {
    return RequestUtil.get({ url: REQUEST_URL.CUSTOMER_MANAGE_LIST, query: query });
  },

  getDuplicateCustomer: (query: CheckCustomerDuplicatePayload) => {
    return RequestUtil.get({ url: REQUEST_URL.CUSTOMER_MANAGE_CHECK_DUPLICATE, query: query });
  },

  getCustomerRequestById: (id: number) => {
    return RequestUtil.get({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}` });
  },
  
  getCustomerInfoById: (id: number) => {
    return RequestUtil.get({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/customer-info?customerCode=${id}` });
  },

  create: (data: ApprovalProcessPayload) => {
    return RequestUtil.post({ url: REQUEST_URL.CUSTOMER_MANAGE, data: data });
  },

  update: (id: number, data: ApprovalProcessPayload) => {
    return RequestUtil.put({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}`, data: data });
  },

  changeStatus: (id: number, data: any) => {
    return RequestUtil.put({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}/nvkd`, data: data });
  },

  approveOrReject: (id: number, type: APPROVAL_PERSON, data: any) => {
    const url = type === APPROVAL_PERSON.GDKD ? `${REQUEST_URL.CUSTOMER_MANAGE}/${id}/gdkd` : `${REQUEST_URL.CUSTOMER_MANAGE}/${id}/ktbh`
    return RequestUtil.put({ url: url, data: data });
  },

  delete: (id: number) => {
    return RequestUtil.delete({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}` });
  },

  createShippingAddress: (data: CreateCustomerShippingAddressModel) => {
    return RequestUtil.post({ url: REQUEST_URL.CUSTOMER_MANAGE, data: data });
  },

  createOrModifyCustomer: (data: CreateCustomerManageModel) => {
    return RequestUtil.post({ url: REQUEST_URL.CUSTOMER_MANAGE, data: data });
  },

  updateCreateOrModifyCustomer: (id: number, data: CreateCustomerManageModel) => {
    return RequestUtil.put({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}`, data: data });
  },

  updateShippingAddress: (id: number, data: CreateCustomerShippingAddressModel) => {
    return RequestUtil.put({ url: `${REQUEST_URL.CUSTOMER_MANAGE}/${id}`, data: data });
  },

  getMasterData: () => {
    return RequestUtil.get({ url: REQUEST_URL.CUSTOMER_MANAGE_DATA_SOURCE });
  },

  getDataSourceCustomers: () => {
    const payload = {
      source: 'farm',
      page: 1,
      limit: Number.MAX_SAFE_INTEGER
    }
    return RequestUtil.get({ url: REQUEST_URL.CUSTOMER_MANAGE_DATA_SOURCE_CUSTOMER, query: payload });
  },

  getEmployees: () => {
    const query = {
      source: 'farm',
      page: 1,
      limit: Number.MAX_SAFE_INTEGER
    }
    const url = REQUEST_URL.CUSTOMER_MANAGE_EMPLOYEE;

    return RequestUtil.get({ url, query });
  },
};
