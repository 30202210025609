import { GFTypography } from "@Components/ui";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const OrderDetailItemWrapper = styled(Box)<{ isChild?: boolean }>(({ isChild }) => ({
  display: "flex",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  width: "100%",
  ...(!isChild && {
    background: "#F5F5F5",
  }),
  "& .expand-icon": {
    width: "110px",
    flexShrink: 0,
    height: "3.6rem",
    "& .MuiIconButton-root": {
      background: "#fff !important",
      "& svg": {
        fill: "#000 !important",
      },
    },
  },
  "& .gf-order-detail-item__main": {
    flex: 1,
    flexShrink: 0,
  },
}));

export const InfoLabel = styled(
  ({ title, value, type }: { title: string; value: number | string; type?: any }) => {
    return (
      <Box>
        <GFTypography variant="body2" color="text.secondary" className="label">
          {title}
        </GFTypography>
        <GFTypography type={type}>{value}</GFTypography>
      </Box>
    );
  }
)({
  "& .label": {
    display: "block",
    marginBottom: "8px",
  },
});

export const HeaderCell = styled(Grid)({
  minHeight: "3.6rem",
  display: "flex",
  alignItems: "center",
  paddingTop: "0 !important",
  paddingRight: "1rem",
  "&.right": {
    justifyContent: "flex-end",
  },
});
