import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";

const GFGridStatusStyled = styled(Box)<{ isActive?: boolean }>(({ isActive }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  whiteSpace: "nowrap",
  paddingInline: "16px",
  borderRadius: '9999px',
  fontSize: "14px",
  height: "32px",
  ...(isActive
    ? {
        backgroundColor: "var(--secondary-green)",
        color: "white",
      }
    : {
        backgroundColor: "var(--disabled-color)",
      }),
}));

type GFGridStatusProps = {
  isActive?: boolean;
};

export default function GFGridStatus({ isActive, children }: PropsWithChildren<GFGridStatusProps>) {
  return <GFGridStatusStyled isActive={isActive}>{children}</GFGridStatusStyled>;
}
