import { Nullable } from "@Models/shared";
import { CustomerService } from "@Services";
import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { itemGroupTypeFarm } from "@Models";

export const loadProfile = createAsyncThunk("profile/loadProfile", async (_arg) => {
  const response = await CustomerService.getAuthProfile();

  return {
    data: response.data,
  };
});

export type ProfileState = {
  data: Nullable<{
    id: number;
    isAdmin: boolean;
    email: Nullable<string>;
    avatar: Nullable<string>;
    phoneNumber: Nullable<string>;
    isActive: boolean;
    code: string | null;
    fullName: string | null;
    deviceIds: string | null;
    role: {
      id: number;
      position: string;
      permissions: any;
      description: string;
      fixed: true;
      createdAt: string;
      updatedAt: string;
      dataAccessPermissions: [
        {
          id: number;
          source: string;
          isActive: boolean;
          divisionIds: string[];
          salesOrgIds: string[];
          siteIds: string[];
          createdAt: string;
          updatedAt: string;
        }
      ];
    };
    permissions: string | null;
    dateOfBirth: string | null;
    gender: string | null;
    position: string | null;
    source: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    employee: any;
    company: string | null;
    account: {
      id: number;
      email: Nullable<string>;
      avatar: any;
      phoneNumber: Nullable<string>;
      isActive: boolean;
      code: string;
      password: string;
      deviceIds: [string | null];
      permissions: Nullable<string>;
      isEmployee: true;
      idCardFront: Nullable<string>;
      idCardBack: Nullable<string>;
      signature: Nullable<string>;
      createdAt: Nullable<string>;
      updatedAt: Nullable<string>;
    };
    customerDivisionEmployee: Nullable<string>;
    itemGroupTypeFarm: itemGroupTypeFarm[];
  }>;
  loading: boolean;
};

const initialState: ProfileState = {
  data: null,
  loading: false,
};

export const getProfile = (builder: ActionReducerMapBuilder<ProfileState>) => {
  const { pending, fulfilled, rejected } = loadProfile;

  builder.addCase(pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
  });
  builder.addCase(rejected, (state, action) => {
    state.loading = false;
  });
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProfile(builder);
  },
});

// Selectors
export const LoadMe = (state: RootState) => state.profile;

//Reducers
const profileReducer = profileSlice.reducer;
export default profileReducer;
