import { z } from "zod";

export const customerManageSchema = z.object({
  fullName: z
    .string({
      message: "Nhập tên khách hàng",
    })
    .min(1, {
      message: "Tên khách hàng không được để trống",
    }),
  dateOfBirth: z.union([z.date(), z.string()]).optional().nullable(),
  birthPlace: z.string().optional().nullable(),
  idCard: z.string()
  .regex(/^\d*$/, {
    message: "CCCD/CMND chỉ được chứa số",
  })
  .optional()
  .nullable(),
  idIssueDate: z.union([z.date(), z.string()]).optional().nullable(),
  idIssuePlace: z.string().optional().nullable(),
  taxId: z.string().optional().nullable(),
  shippingAddress: z
  .string({
    message: "Nhập Địa chỉ giao hàng",
  })
  .min(1, {
    message: "Nhập Địa chỉ giao hàng không được để trống",
  }),
  bankKey: z.string().optional().nullable(),
  bankAccNum: z.string().optional().nullable(),
  bankAccName: z.string().optional().nullable(),
  businessLiceneseNum: z.string().optional().nullable(),
  nameOfLegalRepresentative: z.string().optional().nullable(),
  position: z.string().optional().nullable(),
  codeOfFeedCus: z.string().optional().nullable(),
  businessLiceneseDate: z.union([z.date(), z.string()]).optional().nullable(),
  phoneNumber: z
    .string({
      message: "Nhập Số điện thoại",
    })
    .min(10, {
      message: "Số điện thoại phải có ít nhất 10 chữ số",
    })
    .max(11, {
      message: "Số điện thoại không được vượt quá 11 chữ số",
    })
    .regex(/^\d+$/, {
      message: "Số điện thoại chỉ được chứa số",
    }),
  email: z
    .string({
      message: "Nhập Email",
    })
    .min(1, {
      message: "Email không được để trống",
    }),
  address: z
    .string({
      message: "Nhập Địa chỉ khách hàng (địa chỉ đầy đủ)",
    })
    .min(1, {
      message: "Địa chỉ khách hàng (địa chỉ đầy đủ) không được để trống",
    }),
  province: z
    .string({
      message: "Vui lòng nhập Tỉnh/Thành",
    }),
  district: z
    .string({
      message: "Vui lòng nhập Quận/Huyện",
    }),
  customerGroup: z
    .number({
      message: "Vui lòng nhập Nhóm khách hàng",
    }),
  slsMan: z
    .number({
      message: "Vui lòng nhập Nhân viên kinh doanh",
    }),
  slsSup: z
    .number({
      message: "Vui lòng nhập Giám đốc kinh doanh",
    }),
  slsAst: z
    .number({
      message: "Vui lòng nhập Giám sát bán hàng",
    }),
  slsRef: z
    .number({
      message: "Vui lòng nhập Trưởng phòng kinh doanh",
    }),
  division: z
    .number({
      message: "Vui lòng nhập Ngành hàng",
    }),
  salesOffice: z
    .number({
      message: "Vui lòng nhập Miền",
    })
    .optional(),
  salesGroup: z
    .number({
      message: "Vui lòng nhập Đơn vị kinh doanh heo thịt",
    })
    .nullable()
    .optional(),
  saleDistrict: z
    .number({
      message: "Vui lòng nhập Đơn vị kinh doanh heo giống",
    })
    .nullable()
    .optional(),
  itemGroupType: z
    .number({
      message: "Vui lòng nhập Nhóm sản phẩm",
    }),
  distributionChannel: z
    .number({
      message: "Vui lòng nhập Kênh bán hàng",
    })
    .optional(),
  saleOrg: z
    .number({
      message: "Vui lòng nhập Tổ chức bán hàng",
    })
    .optional(),
  customerCode: z
    .string({
      message: "Vui lòng nhập Khách hàng",
    })
    .optional(),
});

export type CustomerManagePayload = z.infer<typeof customerManageSchema>;
