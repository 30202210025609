/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import Value from "@Components/Value";
import { APP_ROUTES, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import {
  Action,
  AddressModal,
  FetchAction,
  GetAllSite,
  ItemPermission,
  OrderMismatchWarehouseModel,
  OrderStatus,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  checkQuan,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  HomeOutlined as HomeOutlinedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { vi } from "date-fns/locale";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import "../SiteOrder/SiteOrder.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { MessageError } from "@Models/error.model";
import { isArray } from "lodash";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { time } from "console";
import { Link, useLocation } from "react-router-dom";
import { GFConfirmModal, GFTypography } from "@Components/ui";
import useGetMe from "@Hooks/useGetMe";
import { PigTypeCode } from "src/types";

type P_Props = {
  order: any;
  closeForm: () => void;
  dataItem: any;
};

function reducer(state: PaginateFetchReducer<GetAllSite>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const DetachSiteOrder: React.FC<P_Props> = ({ order, closeForm, dataItem }) => {
  const { t } = useTranslation(["translation", "order"]);
  const [departureTime, setDepartureTime] = useState({
    hour: "00",
    minute: "00",
    second: "00",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCreateERP, setIsCreateERP] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [submitERP, setSubmitERP] = useState<boolean>(false);
  const [notiQuanOver, setNotiQuanOver] = useState<boolean>(false);
  const [alterItem, setAlterItem] = useState<any>();
  const [notiQuan, setNotiQuan] = useState<boolean>(false);
  const mounted = useRef(false);
  const [shippingAddressId, setShippingAddressId] = useState<number>(0);
  const [notiWarehouse, setNotiWarehouse] = useState<{
    open: boolean;
    data: any;
    resData: OrderMismatchWarehouseModel | null;
  }>({ open: false, data: null, resData: null });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const { comparePigType } = useGetMe();

  const isBreeding = comparePigType(searchQuery.get("itemGroupTypeId"), PigTypeCode.Breeding);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [order]);

  const [siteList, dispatch] = useReducer(reducer, {
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: Action.FETCH, payload: {} });
      try {
        const result = await OrderService.getAllSiteFarm({
          source: "farm",
          page: 1,
          limit: 500,
          customerId: order?.orders[0]?.customer?.id,
          itemId: dataItem.orderlines[0].item.id,
        });
        if (!cancel)
          dispatch({
            type: Action.SUCCESS,
            payload: result.data,
          });
      } catch (err) {
        if (!cancel)
          dispatch({
            type: Action.FAILED,
            payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
          });
      }
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, []);

  const [site, setSite] = useState<any>("");
  // const [alterItemId, setAlterItemId] = useState<any>("");

  useEffect(() => {
    dataItem?.site?.id
      ? setSite(dataItem?.site?.id)
      : siteList?.data?.length > 0
      ? setSite(siteList.data[0]?.id)
      : setSite("");
  }, [siteList, dataItem?.site?.id]);
  // const addAlterItem = async () => {
  //   try {
  //     const result = await OrderService.getAlterItem({
  //       itemId: dataItem.orderlines[0].item.id,
  //       siteId: site,
  //     });

  //     if (mounted.current) {
  //       setAlterItem([result.data]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   site && addAlterItem();
  // }, [site]);

  const [addSiteList, setAddSiteList] = useState<
    { id: number; item: any; quantity: number; dimension: number; alterItemId: number }[]
  >(
    dataItem.orderlines?.map((d: any) => {
      const totalChild = dataItem.childOrders
        ?.filter((s: any) => s.status !== OrderStatus.CANCELLED)
        ?.reduce((sum: number, item: any) => {
          sum += +item.orderlines?.[0]?.orderQuantity;
          return sum;
        }, 0);

      const quan =
        totalChild === +d.orderQuantity
          ? +d.orderQuantity * 1.1 - totalChild
          : +d.orderQuantity - totalChild;

      return {
        id: d.id,
        item: d,
        quantity: quan > 0 ? Math.round(quan) : 1,
        dimension:
          (+d.dimension / +d.orderQuantity) * quan > 0
            ? Math.round((+d.dimension / +d.orderQuantity) * quan)
            : Math.round(+d.dimension / +d.orderQuantity),
      };
    })
  );

  // useEffect(() => {
  //   dataItem.orderlines?.[0]?.alterItem && setAlterItemId(dataItem.orderlines?.[0]?.alterItem?.id);
  // }, [dataItem]);

  // useEffect(() => {
  //   site ? setAlterItemId(alterItem?.[0]?.id) : setAlterItemId("");
  // }, [site, alterItem]);

  const checkOrderQuantity = +dataItem.orderlines?.[0].orderQuantity !== addSiteList[0].quantity;
  console.log(addSiteList, "addSiteList");
  const saveAddress = async (val: any) => {
    await CustomerService.createAddress({
      customerId: order?.orders[0].customer.id,
      name: order?.orders[0].customer.fullName,
      phoneNumber: order?.orders[0].customer.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("order:ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  const defaultDimension = Array.isArray(dataItem.orderlines?.[0].item.dimension)
    ? dataItem.orderlines?.[0].item.dimension
        .filter((f: any) => {
          return +f[1] === +dataItem.orderlines?.[0].dimensionToShow;
        })
        .map((d: any) => {
          return d[1] === null ? +d[0] : +d[1];
        })
    : +dataItem.orderlines?.[0].item.dimension;

  const onUpdateQuantity = (id: number, quantity: number, dimension: number) => {
    const idx = addSiteList.findIndex((item) => item.id === id);
    let data = [...addSiteList];

    if (idx !== -1) {
      // update
      data[idx] = {
        ...data[idx],
        id: id,
        quantity: quantity || 1,
        dimension: dimension || 1,
      };
    }

    setAddSiteList(data);
  };

  const total = +dataItem?.orderlines?.[0]?.orderQuantity;

  const checkQuantity = () => {
    if (total !== addSiteList[0].quantity) {
      if (checkQuan(total, addSiteList[0].quantity)) {
        if (total < addSiteList[0].quantity) {
          setNotiQuanOver(true);
        } else {
          setNotiQuan(true);
        }
      } else {
        enqueueSnackbar(t("order:QUANTITY_EXCEEDS_PERCENT", { PERCENT: "10%" }), {
          variant: "error",
          preventDuplicate: true,
        });
      }
      return;
    }
  };

  // const submitForm = async (values: any) => {
  //   if (alterItemId === "") {
  //     enqueueSnackbar(t("order:PLEASE_SELECT_ERP_CODE"), {
  //       preventDuplicate: true,
  //       variant: "error",
  //     });

  //     return;
  //   }

  //   setLoading(true);

  //   const item = addSiteList.map((add) => {
  //     const valDimen = Array.isArray(add.item.item.dimension)
  //       ? add.item.item.dimension
  //         .filter((f: any) => {
  //           return +f[0] === +add.item.dimensionToShow;
  //         })
  //         .map((d: any) => {
  //           return d[1] === null ? +d[0] : +d[1];
  //         })
  //       : +add.item.item.dimension;

  //     const valPriceDimen = +add.item.prices.dimension;

  //     let subTotal = 0;
  //     switch (add.item.prices?.priceType) {
  //       case "bs":
  //         subTotal = +add.dimension * +add.item.prices.value;
  //         break;
  //       case "fixed":
  //         subTotal =
  //           +add.dimension * +add.item.prices.value + +add.item.prices.additionValue * add.quantity;
  //         break;
  //       case "increment":
  //         subTotal =
  //           valPriceDimen * +add.item.prices.value * add.quantity +
  //           Math.max(0, valDimen - valPriceDimen) * add.quantity * +add.item.prices.additionValue;
  //         break;
  //       default:
  //     }
  //     return {
  //       orderId: dataItem?.orderlines?.[0]?.id,
  //       quantity: add.quantity,
  //       dimension: +add.dimension,
  //       subTotal: checkPermissionViewPrice ? subTotal : -1,
  //       note: reason?.length ? reason + " + " + values.note : values.note,
  //       address: values.address,
  //       requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
  //       alterItemId: alterItemId,
  //       siteId: site,
  //     };
  //   });

  //   try {
  //     const res = await OrderService.detachOrder({
  //       orderGroupId: order?.id,
  //       orderId: dataItem?.id,
  //       shippingAddress: shippingAddressId,
  //       orderlines: item as any,
  //       isCreateERP: isCreateERP,
  //     });
  //     if (res && (res.status === 200 || res.status === 201)) {
  //       setLoading(false);
  //       if (isCreateERP !== res.data?.createERPSuccess) {
  //         setSubmitERP(true);
  //       } else {
  //         enqueueSnackbar(t("order:CAMP_COORDINATION_SUCCESSFUL"), {
  //           preventDuplicate: true,
  //           variant: "success",
  //         });
  //         window.location.reload();
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error: any) {
  //     setLoading(false);
  //     if (error.response?.data.message === "ORDER_CANT_UPDATE") {
  //       isCreateERP
  //         ? setSubmitERP(true)
  //         : enqueueSnackbar(t("order:ORDER_STATUS_CHANGED"), {
  //           variant: "error",
  //           preventDuplicate: true,
  //         });
  //     } else if (error.response?.data.message === "EXCEED_THE_ALLOWED_AMOUNT") {
  //       isCreateERP
  //         ? setSubmitERP(true)
  //         : enqueueSnackbar(t("order:QUANTITY_EXCEEDS_PERCENT", { PERCENT: "10%" }), {
  //           variant: "error",
  //           preventDuplicate: true,
  //         });
  //     } else {
  //       enqueueSnackbar(t("order:CAMP_COORDINATION_FAILED"), {
  //         variant: "error",
  //         preventDuplicate: true,
  //       });
  //     }
  //   }
  // };

  console.log(order);

  const submitForm = async (values: any) => {
    // if (alterItemId === "") {
    //   enqueueSnackbar(t("order:PLEASE_SELECT_ERP_CODE"), {
    //     preventDuplicate: true,
    //     variant: "error",
    //   });

    //   return;
    // }

    setLoading(true);

    const data: Parameters<typeof OrderService.updateOrderFarm>[0] = {
      orderGroupId: order?.id,
      customerId: order?.orders[0].customer.id,
      isCreateERP: isCreateERP,
      isCheckWareHouse: true,
      orders: [
        {
          orderId: dataItem.id,
          requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
          vehicleNumber: values.vehicleNumber,
          note: reason?.length ? reason + " + " + values.note : values.note,
          address: values.address,
          departureDate: moment(values.departureDate || new Date()).format("YYYY-MM-DD"),
          departureTime: `${departureTime.hour}:${departureTime.minute}:00`,
          siteId: site,
          requiredTime: order.orders[0].requiredTime?.key,
          shippingAddress: shippingAddressId,
          orderDetails: addSiteList.map((add) => {
            const valDimen = Array.isArray(add.item.item.dimension)
              ? add.item.item.dimension
                  .filter((f: any) => {
                    return +f[1] === +add.item.dimensionToShow;
                  })
                  .map((d: any) => {
                    return d[1] === null ? +d[0] : +d[1];
                  })
              : +add.item.item.dimension;

            const valPriceDimen = +add.item.prices.dimension;
            const itemProduct = { ...add.item.item };
            const additionValue = Number(add.item?.attachValue);
            const unitPrice = Number(add.item?.price);
            const grossWeight = Number(
              !itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0
                ? valDimen
                : itemProduct?.grossWeight
            );
            let subTotal = 0;
            switch (add.item.prices?.priceType) {
              case "bs":
                subTotal = add.quantity * unitPrice * grossWeight;
                break;
              case "fixed":
                // subTotal = add.quantity * (unitPrice + Number(add.item?.prices.additionValue))
                subTotal =
                  add.quantity * grossWeight * unitPrice + add.quantity * Number(additionValue);
                break;
              case "increment":
                subTotal =
                  add.quantity * unitPrice +
                  add.quantity *
                    Number(additionValue) *
                    (grossWeight - Number(itemProduct?.netWeight));
                break;
              default:
            }
            return {
              id: dataItem.orderlines.find((f: any) => f.id === add.id)?.id ? add.id : 0,
              quantity: add.quantity,
              dimension: +add.dimension,
              subTotal: subTotal,
              attachValue: +add.item.attachValue,
              // alterItemId: alterItemId,
            };
          }),
        },
      ],
    } as any;

    await OrderService.updateOrderFarm(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setLoading(false);
          // Trường hợp không đủ tồn kho
          if (
            res.data?.message === MessageError.WAREHOUSE_NOT_ADAPT_ORDER_NUMBER &&
            res?.data?.statusCode === 400
          ) {
            setNotiWarehouse({ open: true, data: data, resData: res.data?.data });
          } else {
            if (isCreateERP !== res.data?.createERPSuccess) {
              setSubmitERP(true);
            } else {
              enqueueSnackbar(t("order:CAMP_COORDINATION_SUCCESSFUL"), {
                preventDuplicate: true,
                variant: "success",
              });
              window.location.reload();
            }
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        enqueueSnackbar(t("order:CAMP_COORDINATION_FAILED"), {
          preventDuplicate: true,
          variant: "error",
        });
        console.log(error);
      });
  };
  const getDimensionOfOrder = (dimensionToShow: string, listDimension: any) => {
    if (isArray(listDimension)) {
      const listDimensionToShow = listDimension.filter((item, index) => {
        if (item.includes(String(dimensionToShow))) {
          return true;
        } else {
          return false;
        }
      });
      let dimensionList = [...listDimensionToShow[0]];
      if (dimensionList[1] === "null") {
        return "trên " + dimensionList[0];
      } else if (dimensionList[0] === "null") {
        return "dưới " + dimensionList[1];
      } else {
        return dimensionList[0] + " - " + dimensionList[1];
      }
    } else {
      return `${listDimension}`;
    }
  };

  const handleAcceptWarehouse = async () => {
    const dataAccpet = { ...notiWarehouse?.data, isCheckWareHouse: false };

    await OrderService.updateOrderFarm(dataAccpet as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setLoading(false);
          setNotiWarehouse({ open: false, data: null, resData: null });
          if (isCreateERP !== res.data?.createERPSuccess) {
            setSubmitERP(true);
          } else {
            enqueueSnackbar(t("order:CAMP_COORDINATION_SUCCESSFUL"), {
              preventDuplicate: true,
              variant: "success",
            });
            window.location.reload();
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        enqueueSnackbar(t("order:CAMP_COORDINATION_FAILED"), {
          preventDuplicate: true,
          variant: "error",
        });
        console.log(error);
      });
  };

  return (
    <div className="update-order">
      <SimpleModalHeader title={t("order:CAMP_COORDINATION")} onClose={closeForm} />
      {!!order && (
        <div className="content">
          <Grid container className="header">
            <Grid item xs={4} className="left">
              <img
                src={
                  checkLinkImage(order?.orders[0].customer?.account?.avatar) || renderImageProNull()
                }
                alt="Avata"
                onError={renderErrorImageItem()}
              />
              <div>
                <div className="name">
                  {order?.orders[0].customer !== null
                    ? order?.orders[0].customer.fullName
                    : order?.orders[0].lead.fullName}
                  {order?.orders[0].customer === null && (
                    <span
                      style={{
                        color: "#838283",
                        fontWeight: 400,
                        fontSize: "1.4rem",
                        marginLeft: "1rem",
                      }}>
                      <span>&#9679;&ensp;</span>
                      {t("order:POTENTIAL")}
                    </span>
                  )}
                </div>
                {order?.orders[0].customer !== null
                  ? order?.orders[0].customer.phoneNumber
                  : order?.orders[0].lead.phoneNumber}
              </div>
            </Grid>
            <Grid item container xs={3} className="col"></Grid>
            <Grid item container xs={3} className="col">
              {order?.orders.map((orders: any, index: number) => {
                const check = Array.from(
                  new Set(
                    order?.orders.map((orders: any) => {
                      return orders.salesOrg.description;
                    })
                  )
                );
                return (
                  <Grid item xs={6}>
                    <div className="label">
                      {check.length > 1
                        ? t("order:BUSINESS_UNIT") + " " + (index + 1)
                        : check[index] !== undefined
                        ? t("order:BUSINESS_UNIT")
                        : ""}
                    </div>
                    {check[index]}
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={2}>
              <div>
                <div className="label">Phương thức cọc</div>
                <div
                  style={{
                    fontSize: "1.8rem",
                  }}>
                  {order.deposit.rate1}%
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container className="update-order-info">
            <Grid item xs={4}>
              <div className="label">{t("order:ORDER_ID")}</div>#{order.code}
            </Grid>
            <Grid item xs={3}>
              <div className="label">{t("order:ORDER_TIME")}</div>
              {moment(order.createdAt).format("DD/MM/YYYY - HH:mm")}
            </Grid>
            <Grid item xs={3}>
              <div className="label">{t("order:ORDER_PLACER")}</div>
              {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
              {order.createdBy?.fullName}
            </Grid>
            <Grid item xs={2} className="update-order-info-status">
              <div className="label">{t("STATUS")}</div>
              {StatusUIUtil.renderBgStatusFarmNew(order.status, "farm", order.subStatus)}
            </Grid>
          </Grid>
          <Form onSubmit={async (values) => submitForm(values)} initialValues={dataItem}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="site-form">
                  <div className="list">
                    <div className="update-order-header">
                      <div className="name">Sản phẩm</div>
                      <div className="quan">{t("QUANTITY")}</div>
                      <div className="dimension">Khung trọng lượng</div>
                      <div className="weight">{t("TOTAL_WEIGHT")}</div>
                      <div className="price">{t("UNIT_PRICE")}</div>
                      <div className="unit">Phí giống</div>
                      <div className="unit">Phần vượt</div>

                      <div className="total">{t("TOTAL_PRICE")}</div>
                    </div>
                    {addSiteList?.map((val: any, idx: number) => {
                      return (
                        <div key={idx}>
                          <div className="item">
                            <div className="name">
                              <img
                                src={
                                  checkLinkImage(val.item.item.images?.picture) ||
                                  renderImageProNull("farm")
                                }
                                alt={val.item.item.shortName}
                                onError={renderErrorImageItem("farm")}
                              />
                              <div style={{ paddingBottom: "2.4rem" }}>
                                {val.item.item.name}
                                {/* &ensp;&#8226;&ensp;
                                {Array.isArray(val.item.item.dimension)
                                  ? val.item.item.dimension
                                      .filter((f: any) => {
                                        return f[0] === val.item.dimensionToShow;
                                      })
                                      .map((d: any) => {
                                        return <span>{d[1] === null ? "Trên " + d[0] : d[1]}</span>;
                                      })
                                  : val.item.item.dimension}
                                &ensp;{val.item.baseUoM} */}
                                <div className="code">{val.item.item.code}</div>
                              </div>
                            </div>
                            <div className="quan">
                              <Value
                                dimension={val.quantity}
                                onChangeQuantity={(value) => {
                                  onUpdateQuantity(val.id, value, value * defaultDimension);
                                }}
                                style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                              />
                              {/* <span style={{ color: "#41ad49" }}>{val.item.saleUnit}</span> */}
                            </div>
                            <div className="dimension">
                              <span>
                                <Value
                                  dimension={getDimensionOfOrder(
                                    dataItem.orderlines[0].dimensionToShow,
                                    dataItem.orderlines[0].item.dimension
                                  )}
                                  onChangeQuantity={(value) => {
                                    // onUpdateDetachOrder(index, "quantity", value);
                                  }}
                                  isDisable={true}
                                  style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                />
                                &ensp;kg
                              </span>
                            </div>
                            <div className="weight" style={{ display: "flex" }}>
                              <Value
                                dimension={val.dimension}
                                onChangeQuantity={(value) => {
                                  onUpdateQuantity(val.id, val.quantity, value);
                                }}
                                style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                isDisable={true}
                              />
                              <span style={{ color: "#41ad49" }}>
                                &ensp;kg
                                {/* {val.item.baseUoM} */}
                              </span>
                            </div>
                            <div className="price">
                              {checkPermissionViewPrice
                                ? currencyFormat(val.item.price)
                                : TEXT_ASTERISK}
                              &ensp;đ/
                              <span style={{ color: "#41ad49" }}>{val.item.baseUoM}</span>
                            </div>
                            {/* Phí giống */}
                            <div className="unit">
                              {(() => {
                                switch (val.item.prices?.priceType) {
                                  case "fixed":
                                    return (
                                      <span>
                                        {(checkPermissionViewPrice
                                          ? currencyFormat(Number(val.item?.attachValue))
                                          : TEXT_ASTERISK) + " đ/"}
                                        <span style={{ color: "#41ad49" }}>
                                          {val.item.saleUnit}
                                        </span>
                                      </span>
                                    );
                                  default:
                                    return (
                                      <span>
                                        {(checkPermissionViewPrice
                                          ? currencyFormat(0)
                                          : TEXT_ASTERISK) + " đ/"}
                                        <span style={{ color: "#41ad49" }}>
                                          {val.item.saleUnit}
                                        </span>
                                      </span>
                                    );
                                }
                              })()}
                            </div>
                            {/* Phần vượt */}
                            <div className="unit">
                              {(() => {
                                switch (val.item.prices?.priceType) {
                                  case "increment":
                                    return (
                                      <span>
                                        {(checkPermissionViewPrice
                                          ? currencyFormat(Number(val.item?.attachValue))
                                          : TEXT_ASTERISK) + " đ/"}
                                        <span style={{ color: "#41ad49" }}>
                                          {val.item.saleUnit}
                                        </span>
                                      </span>
                                    );
                                  default:
                                    return (
                                      <span>
                                        {(checkPermissionViewPrice
                                          ? currencyFormat(0)
                                          : TEXT_ASTERISK) + " đ/"}
                                        <span style={{ color: "#41ad49" }}>
                                          {val.item.saleUnit}
                                        </span>
                                      </span>
                                    );
                                }
                              })()}
                            </div>
                            <div className="total" style={{ color: "#41AD49", fontWeight: 700 }}>
                              {(() => {
                                const valDimen = Array.isArray(val.item.item.dimension)
                                  ? val.item.item.dimension
                                      .filter((f: any) => {
                                        return +f[1] === +val.item.dimensionToShow;
                                      })
                                      .map((d: any) => {
                                        return d[1] === null ? +d[0] : +d[1];
                                      })
                                  : +val.item.item.dimension;

                                const valPriceDimen = +val.item.prices.dimension;
                                const itemProduct = { ...val.item.item };
                                const additionValue = Number(val.item?.attachValue);

                                const unitPrice = Number(val.item?.price);
                                const grossWeight = Number(
                                  !itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0
                                    ? valDimen
                                    : itemProduct?.grossWeight
                                );
                                switch (val.item.prices?.priceType) {
                                  case "bs":
                                    return checkPermissionViewPrice
                                      ? currencyFormat(val.quantity * unitPrice * grossWeight)
                                      : TEXT_ASTERISK;
                                  case "fixed":
                                    return checkPermissionViewPrice
                                      ? currencyFormat(
                                          // val.quantity * (unitPrice + Number(val.item?.prices.additionValue))
                                          val.quantity * grossWeight * unitPrice +
                                            val.quantity * Number(additionValue)
                                        )
                                      : TEXT_ASTERISK;
                                  case "increment":
                                    return checkPermissionViewPrice
                                      ? currencyFormat(
                                          val.quantity * unitPrice +
                                            val.quantity *
                                              Number(additionValue) *
                                              (grossWeight - Number(itemProduct?.netWeight))
                                        )
                                      : TEXT_ASTERISK;
                                }
                              })()}
                              &ensp;đ
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              background: "rgba(239, 239, 239, 0.3)",
                              padding: "0 0 2.6rem 8.5rem",
                            }}>
                            <div style={{ width: "33%", marginRight: "11rem" }}>
                              <FormControl fullWidth>
                                <Select
                                  value={site}
                                  onChange={(event: SelectChangeEvent) => {
                                    setSite(event.target.value);
                                  }}
                                  variant="standard"
                                  color="success"
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}
                                  renderValue={
                                    site !== ""
                                      ? undefined
                                      : () => (
                                          <span className="form__placeholder">
                                            {t("order:SELECT_CAMP")}
                                          </span>
                                        )
                                  }
                                  fullWidth
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <HomeOutlinedIcon
                                        style={{
                                          color: "#41AD49",
                                          fontSize: "2.5rem",
                                        }}
                                      />
                                    </InputAdornment>
                                  }>
                                  {siteList?.data?.length > 0 ? (
                                    siteList.data.map((site: any) => {
                                      return (
                                        <MenuItem key={site.id} value={site.id}>
                                          {site.name}
                                        </MenuItem>
                                      );
                                    })
                                  ) : (
                                    <MenuItem
                                      value=""
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        color: "#838283",
                                        textAlign: "center",
                                      }}>
                                      {t("order:NO_SUITABLE_CAMP")}
                                      <br />
                                      {t("order:PLEASE_DECLARE_ALLOWED_COORDINATION_CAMP")}
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                            {/* <div style={{ width: "30rem" }}>
                              <FormControl fullWidth>
                                <Select
                                  value={alterItemId}
                                  onChange={(e) => {
                                    setAlterItemId(e.target.value);
                                  }}
                                  error={!alterItemId}
                                  fullWidth
                                  variant="standard"
                                 
                                  renderValue={
                                    alterItemId !== ""
                                      ? undefined
                                      : () => (
                                        <span className="form__placeholder">
                                          {t("order:SELECT_ERP_CODE")}
                                        </span>
                                      )
                                  }
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {alterItem && alterItem?.length > 0 &&
                                    alterItem?.map((alter: any) => {
                                      const checkCode = addSiteList.find(
                                        (f: any) => f.alterItemId === alter.id
                                      )?.alterItemId;
                                      return (
                                        <MenuItem
                                          key={alter.code}
                                          value={alter.id}
                                          disabled={checkCode ? true : false}>
                                          {alter.code}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                <FormHelperText>
                                  {!alterItemId && t("order:PLEASE_SELECT_ERP_CODE")}
                                </FormHelperText>
                              </FormControl>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}

                    <Grid container className="ship">
                      <Grid item xs={4}>
                        <div className="label">{t("order:REFERENCE_CODE")}</div>
                        {dataItem?.orderGFId || TEXT_HOLDER}
                      </Grid>
                      <Grid item xs={8} style={{ paddingLeft: "11rem" }}>
                        <div className="label">{t("order:RECEIVING_LOCATION")}</div>
                        <Field name="address">
                          {({ input, meta, ...rest }) => {
                            return (
                              <ShippingAddress<AddressModal>
                                value={input.value}
                                customerId={order?.orders[0].customer.id}
                                handleSaveAddress={saveAddress}
                                className={"form-text-field addr"}
                                placeholder={t("SELECT_BY_NAME", {
                                  NAME: t("order:DELIVERY_ADDRESS"),
                                })}
                                getOptionLabel={(opt) =>
                                  typeof opt === "string"
                                    ? opt
                                    : opt.address || (opt.inputValue as any)
                                }
                                onChange={(value) => {
                                  setShippingAddressId(value?.id as number);
                                  input.onChange(value?.address || value?.inputValue);
                                }}
                                renderOption={(p) => (
                                  <div
                                    className="item-addr"
                                    style={{
                                      fontSize: "1.6rem",
                                      fontFamily: "SVN-Gotham",
                                      color: p.title ? "#41AD49" : "",
                                      borderBottom: "0.1rem solid #EFEFEF",
                                      padding: "1rem 2rem",
                                      width: "100%",
                                    }}>
                                    {p.title ? p.title : p.address}
                                  </div>
                                )}
                                actionConfig={{
                                  action: CustomerService.getAddress,
                                }}
                                dropdownBoxMaxHeight="24rem"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: "4rem" }}>
                        <div className="label">Ngày xuất trại</div>
                        <Field name="departureDate">
                          {({ input, meta, ...rest }) => {
                            return (
                              <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  okLabel={t("order:SELECT")}
                                  cancelLabel={t("CANCEL")}
                                  fullWidth
                                  disableToolbar
                                  disablePast
                                  inputVariant="standard"
                                  format="dd-MM-yyyy"
                                  className="form-text-field date"
                                  value={input.value || moment().format("YYYY-MM-DD")}
                                  onChange={(value) => {
                                    if (value) {
                                      input.onChange(value);
                                    }
                                  }}
                                  InputProps={{ readOnly: true }}
                                  inputProps={{ className: "input" }}
                                  InputAdornmentProps={{ position: "start" }}
                                  keyboardIcon={
                                    <EventIcon color="#41AD49" size={[20, 20]} viewBox={[22, 22]} />
                                  }
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              </MuiPickersUtilsProvider>
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: "4rem" }}>
                        <div className="label">Giờ xuất trại</div>
                        <button type="button" onClick={handleClick} className="btn-white">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                            &emsp;{departureTime.hour}:{departureTime.minute}
                          </div>
                        </button>
                      </Grid>

                      <Grid item xs={8} style={{ padding: "4rem 0 0 11rem" }}>
                        <div className="label">{t("NOTES")}</div>
                        <Field name="note">
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                fullWidth
                                placeholder={t("INPUT_BY_NAME", { NAME: t("NOTES") })}
                                className="form-text-field form-text-field-note"
                                inputProps={{ className: "input" }}
                                variant="standard"
                                onChange={(e) => input.onChange(e.target.value)}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    event.preventDefault();
                                    return false;
                                  }
                                }}
                                autoComplete="off"
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: "4rem" }}>
                        <div className="label">{t("order:DELIVERY_DATE")}</div>
                        <Field name="requiredDate">
                          {({ input, meta, ...rest }) => {
                            return (
                              <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  okLabel={t("order:SELECT")}
                                  cancelLabel={t("CANCEL")}
                                  fullWidth
                                  disableToolbar
                                  disablePast
                                  inputVariant="standard"
                                  format="dd-MM-yyyy"
                                  className="form-text-field date"
                                  value={input.value || moment().format("YYYY-MM-DD")}
                                  onChange={(value) => {
                                    if (value) {
                                      input.onChange(value);
                                    }
                                  }}
                                  InputProps={{ readOnly: true }}
                                  inputProps={{ className: "input" }}
                                  InputAdornmentProps={{ position: "start" }}
                                  keyboardIcon={
                                    <EventIcon color="#41AD49" size={[20, 20]} viewBox={[22, 22]} />
                                  }
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                />
                              </MuiPickersUtilsProvider>
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={2} style={{ marginTop: "4rem" }}>
                        <div className="label">Giờ giao hàng</div>
                        <div>{order.orders[0]?.requiredTime?.delieryTime}</div>
                      </Grid>
                      <Grid item xs={8} style={{ marginTop: "4rem" }}></Grid>
                    </Grid>
                  </div>

                  <div className="site-footer">
                    {searchQuery.get("itemGroupTypeId") !== "18" && (
                      <button
                        type={checkOrderQuantity ? "button" : "submit"}
                        className="btn-cancel-style"
                        onClick={() => {
                          setIsCreateERP(true);
                          checkOrderQuantity && checkQuantity();
                        }}>
                        {t("order:COORDINATE_AND_SYNCHRONIZE_ERP")}
                      </button>
                    )}
                    <button
                      type={checkOrderQuantity ? "button" : "submit"}
                      className="btn-confirm-style"
                      style={{ marginLeft: "2.4rem" }}
                      onClick={() => {
                        setIsCreateERP(false);
                        checkOrderQuantity && checkQuantity();
                      }}>
                      {t("order:DISPATCH")}
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      )}

      {loading && (
        <Backdrop
          sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}>
          <Loading />
        </Backdrop>
      )}

      <GFConfirmModal
        open={notiWarehouse.open}
        onClose={() => setNotiWarehouse({ open: false, data: null, resData: null })}
        title="Điều phối trại"
        type="warning"
        textContent={
          notiWarehouse.resData?.id !== 0
            ? "Trại được chọn không thoả tồn kho, bạn có muốn xác nhận không?"
            : "Sản phẩm đang điều phối chưa được nhập kho, vui lòng kiểm tra lại."
        }
        onConfirm={() => {
          setLoading(true);
          handleAcceptWarehouse();
        }}>
        <Link
          to={`${APP_ROUTES.WAREHOUSE}?type=${isBreeding ? "1" : "2"}`}
          target="_blank"
          style={{
            color: "var(--primary-green)",
            textDecoration: "underline",
          }}>
          <GFTypography fontWeight={600}>
            Xem tồn kho {isBreeding ? "heo giống" : "heo thịt"}
          </GFTypography>
        </Link>
      </GFConfirmModal>

      <Notification
        visible={notiQuan}
        title={t("order:CHANGE_QUANTITY")}
        subTitle={
          <span>
            <b>Tổng số lượng điều phối</b> và <b>Tổng số lượng ban đầu</b> khác nhau.
            <br />
            Bạn có muốn thay đổi số lượng?
          </span>
        }
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#272B2F",
                border: "0.1rem solid #272B2F",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "10rem",
              }}
              onClick={() => {
                setNotiQuan(false);
              }}>
              {t("NO")}
            </button>
            <input
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "10rem",
              }}
              form="site-form"
              type="submit"
              aria-label="submit supplier form"
              value={t("CONFIRM")}
              onClick={() => setNotiQuan(false)}
            />
          </>
        }
        onClose={() => {
          setNotiQuan(false);
        }}
      />

      <Notification
        visible={notiQuanOver}
        title={t("order:EXCEEDING_ORDER_QUANTITY")}
        subTitle={"Bạn có chắc chắn muốn điều phối vượt số lượt đặt hàng"}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        onTextChange={(text) => setReason(text.target.value)}
        isCoorinate
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "3rem",
              }}
              onClick={() => {
                setNotiQuanOver(false);
              }}>
              {t("NO")}
            </button>
            <input
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "10rem",
              }}
              form="site-form"
              type={reason === "" ? "text" : "submit"}
              aria-label="submit supplier form"
              value={t("order:DISPATCH")}
              onClick={() => {
                reason === "" &&
                  enqueueSnackbar(t("order:PLEASE_ENTER_REASON"), {
                    preventDuplicate: true,
                    variant: "error",
                  });
              }}
            />
          </>
        }
        onClose={() => setNotiQuanOver(false)}
      />

      <Notification
        visible={submitERP}
        title={t("order:SYNCHRONIZATION_FAILED")}
        subTitle={t("order:ORDER_COORDINATED_NOT_SUCCESSFULLY_SYNCHRONIZED_ERP")}
        icon={
          <div className={"notification-icon-box red"}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#272B2F",
                border: "0.1rem solid #272B2F",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "10rem",
              }}
              onClick={() => {
                setSubmitERP(false);
                window.location.reload();
              }}>
              {t("EXIT")}
            </button>
            <input
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "10rem",
              }}
              form="site-form"
              type="submit"
              aria-label="submit supplier form"
              value={t("order:RETRY_SYNC")}
              onClick={() => setSubmitERP(false)}
            />
          </>
        }
        onClose={() => {
          setSubmitERP(false);
          window.location.reload();
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div
          className="popover-date"
          style={{
            minWidth: 180,
          }}>
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={departureTime.hour}
                onChange={(e) => {
                  setDepartureTime({ ...departureTime, hour: e.target.value });
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  paddingX: 2,
                }}>
                {(() => {
                  const menuItems = [];
                  for (let i = 0; i <= 23; i++) {
                    menuItems.push(
                      <MenuItem
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        value={i < 10 ? `0${i}` : `${i}`}>
                        {i < 10 ? `0${i}` : `${i}`}
                      </MenuItem>
                    );
                  }
                  return menuItems;
                })()}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Select
                value={departureTime.minute}
                onChange={(e) => {
                  setDepartureTime({ ...departureTime, minute: e.target.value });
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  paddingX: 2,
                }}>
                {(() => {
                  const menuItems = [];
                  for (let i = 0; i <= 59; i++) {
                    menuItems.push(
                      <MenuItem
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        value={i < 10 ? `0${i}` : `${i}`}>
                        {i < 10 ? `0${i}` : `${i}`}
                      </MenuItem>
                    );
                  }
                  return menuItems;
                })()}
              </Select>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};

export default DetachSiteOrder;
