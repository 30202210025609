import { z } from "zod";

export const contractSchema = z.object({
  party: z
    .string({
      message: "Vui lòng nhập Đối tượng ký hợp đồng.",
    }),
  type: z
    .string({
      message: "Vui lòng nhập Hình thức hợp đồng.",
    }),
  term: z
    .string({
      message: "Vui lòng nhập Thời hạn hợp đồng.",
    }),
  signingDate: z.union([z.date(
    {
      message: "Vui lòng nhập Ngày ký hợp đồng."
    }
  ), z.string({
    message: "Vui lòng nhập Ngày ký hợp đồng."
  })]),
  firstSellerId: z
    .number({
      message: "Vui lòng nhập Tên công ty.",
    }),
  secondSellerId: z
    .number({
      message: "Vui lòng nhập Tên công ty.",
    }).nullable().optional(),
  buyerId: z
    .number({
      message: "Vui lòng nhập Tên khách hàng.",
    }),
  dateStart: z.date({
    message: "Vui lòng nhập ngày bắt đầu hiệu lực."
  }),
  dateEnd: z.date({
    message: "Vui lòng nhập ngày kết thúc hiệu lực."
  }).optional().nullable(),

  // Fields not sent to api
  // First seller
  tax: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  bankNum: z.string().optional().nullable(),
  bankName: z.string().optional().nullable(),
  legalRepresentative: z.string().optional().nullable(),
  position: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  // Second seller
  tax2: z.string().optional().nullable(),
  phoneNumber2: z.string().optional().nullable(),
  bankNum2: z.string().optional().nullable(),
  bankName2: z.string().optional().nullable(),
  legalRepresentative2: z.string().optional().nullable(),
  position2: z.string().optional().nullable(),
  address2: z.string().optional().nullable(),
  // Buyer
  tax3: z.string().optional().nullable(),
  idCard3: z.string().optional().nullable(),
  idCardDate3: z.string().optional().nullable(),
  phoneNumber3: z.string().optional().nullable(),
  bankNum3: z.string().optional().nullable(),
  bankName3: z.string().optional().nullable(),
  bankAccount3: z.string().optional().nullable(),
  legalRepresentative3: z.string().optional().nullable(),
  position3: z.string().optional().nullable(),
  address3: z.string().optional().nullable(),
})


export type ContractPayload = z.infer<typeof contractSchema>;