import { APP_ROUTES } from "@Constants";
import { WithChildren } from "@Models/shared";
import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children }: WithChildren<{}>) => {
  const token = localStorage.getItem("admin-access-token");
  return <Route render={() => (token ? children : <Redirect to={APP_ROUTES.SIGNIN} />)} />;
};

export default React.memo(PrivateRoute);
