/* eslint-disable array-callback-return */
import DataNull from "@Assets/images/product/nopro.png";
import { AccountIcon } from "@Components/Icons/Account";
import { DashboardIcon } from "@Components/Icons/DashboardIcon";
import { DebtIcon } from "@Components/Icons/DebtIcon";
import { EmployeeIcon } from "@Components/Icons/Employee";
import { FeedIcon } from "@Components/Icons/FeedIcon";
import { KeyIcon } from "@Components/Icons/Key";
import { OrderListIcon } from "@Components/Icons/OrderListIcon";
import { SignInIcon } from "@Components/Icons/SignInIcon";
import { SignUpIcon } from "@Components/Icons/SignUpIcon";
import { UserIcon } from "@Components/Icons/UserIcon";
import Loading from "@Components/Loading";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { DEFAULT_SIZE } from "@Core/Layout/routes";
import { Nullable } from "@Models/shared";
import { PermissionService } from "@Services";
import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuthorizeModal from "../Modal/AuthorizeModal";
import "./AuthorizeDetail.scss";
import { useTranslation } from "react-i18next";
import { RoleType, SourceType } from "@Models";

type P_Props = {
  roleType: RoleType;
};

const AuthorizeDetail: React.FC<P_Props> = ({ roleType }) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { roleId }: { roleId: string } = useParams();
  const [role, setRole] = useState<Nullable<any>>(null);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState<{ open: boolean; type: string }>(
    { open: false, type: "" }
  );

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await PermissionService.getRoleById(+roleId);
      if (mounted.current) {
        setRole(result.data.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [role]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId]);

  const iConTitle = (name: any) => {
    switch (name) {
      case "Đăng nhập":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SignInIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Khách hàng":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Đơn hàng":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <OrderListIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Sản phẩm":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FeedIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={[22, 24]} />
            &ensp;{name}
          </div>
        );
      case "Thông tin đăng ký":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <SignUpIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Nhân viên":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <EmployeeIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Quản lý tài khoản khách hàng":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <AccountIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Công nợ":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DebtIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Phân quyền":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <KeyIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      case "Báo cáo":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <DashboardIcon color={"#0A6836"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;{name}
          </div>
        );
      default:
        return <div style={{ display: "flex", alignItems: "center" }}>{name}</div>;
    }
  };

  return (
    <Dialog fullScreen open={true} className="authorize-detail">
      <SimpleModalHeader
        title={t("ROLE_DETAILS")}
        onClose={() => {
          roleType === RoleType.EMPLOYEE
            ? history.push(APP_ROUTES.AUTHORIZATION_ROLE)
            : history.push(APP_ROUTES.ACCOUNT + "-role");
        }}
      />
      <div className="content">
        {role ? (
          <Grid container>
            <Grid item xs={6} style={{ borderRight: "0.2rem solid #EFEFEF" }}>
              <div className="col col-left">
                <div className="title">{t("GENERAL_INFORMATION")}</div>
                <div className="label">{t("NAME")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{role.position}</div>
                <div className="label">{t("DESCRIPTION")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{role.description}</div>
                <div className="title" style={{ paddingTop: "6rem" }}>
                  {t("ACCESS_DATA")}
                </div>
                {role.dataAccessPermissions.length > 0 ? (
                  <div className="col-left-list">
                    {role.dataAccessPermissions.map((access: any) => {
                      return (
                        <div className="item" key={access?.id}>
                          <div className="source">
                            {(() => {
                              switch (access.source) {
                                case SourceType.FEED:
                                  return t("FEED");
                                case SourceType.FARM:
                                  return t("FARM");
                                case SourceType.VET:
                                  return t("VET");
                                case SourceType.LAB:
                                  return t("LAB");
                                case SourceType.CCU:
                                  return t("CCU");
                                default:
                                  return;
                              }
                            })()}
                          </div>
                          <div className="label">{t("BUSINESS_ORGANIZATION")}</div>
                          {access.salesOrgs.map((s: any) => {
                            return <span key={s.id}>{s.description},&ensp;</span>;
                          })}
                          <div className="label">
                            {access.source === "feed" ? t("PRODUCT_CATEGORY") : t("FARMS")}
                          </div>
                          {access.source === "feed"
                            ? access.divisions.map((d: any) => {
                                return <span key={d.id}>{d.description},&ensp;</span>;
                              })
                            : access.sites.map((sites: any) => {
                                return <span>{sites.name},&ensp;</span>;
                              })}
                          <div className="label">{t("BUSINESS_DIRECTOR")}</div>
                          {access?.salesMans?.map((s: any) => {
                            return <span key={s.id}>{s?.fullName || TEXT_HOLDER},&ensp;</span>;
                          })}
                          {access.source === "farm" && (
                            <>
                              <div className="label">{t("PRODUCT")}</div>
                              {access?.items?.map((pro: any) => {
                                return <span key={pro.id}>{pro?.name || TEXT_HOLDER},&ensp;</span>;
                              })}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}>
                    <img src={DataNull} alt="search" width={190} height={179} />
                    <div
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "bold",
                        color: "#536380",
                        lineHeight: "2.7rem",
                      }}>
                      {t("NO_RELEVANT_DATA")}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="col col-right">
                <div className="title">{t("MODULE")}</div>
                <div className="col-right-list">
                  {role.permissionGroup.map((group: any) => {
                    return (
                      <div className="item" key={group?.id}>
                        <div className="name">
                          {iConTitle(group.name)}&ensp;
                          <span style={{ color: "#ADACAC" }}>
                            ({group.permissionMasters.length})
                          </span>
                        </div>
                        {group.permissionMasters.map((m: any) => {
                          return (
                            <div key={m?.id} className="val">
                              &#8226;&ensp;{m.description}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Loading />
        )}
      </div>
      <div className="authorize-detail-footer">
        <button
          className="btn-cancel-style"
          onClick={() => {
            setOpenCreateOrderModal({ open: true, type: "copy" });
          }}>
          {t("COPY_SETTINGS")}
        </button>
        {role && !role.fixed && (
          <button
            className="btn-confirm-style"
            style={{ marginLeft: "2.4rem" }}
            onClick={() => {
              setOpenCreateOrderModal({ open: true, type: "update" });
            }}>
            {t("EDIT")}
          </button>
        )}
      </div>

      <Dialog fullScreen open={openCreateOrderModal.open}>
        <AuthorizeModal
          closeForm={() => {
            setOpenCreateOrderModal({ open: false, type: "" });
          }}
          dataRoleId={role}
          type={openCreateOrderModal.type}
          roleType={roleType}
        />
      </Dialog>
    </Dialog>
  );
};
export default AuthorizeDetail;
