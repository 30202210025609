import { REQUEST_URL } from "@Constants";
import { PaginatedQueryDto } from "@Models";
import { RequestUtil } from "@Utils";
import { ApprovalProcessPayload } from "src/schemas/approval-process.schema";

export const ApprovalProcessService = {
  getAll: (query: PaginatedQueryDto) => {
    return RequestUtil.get({ url: REQUEST_URL.APPROVAL_PROCESS, query: query });
  },

  create: (data: ApprovalProcessPayload) => {
    return RequestUtil.post({ url: REQUEST_URL.APPROVAL_PROCESS, data: data });
  },

  update: (id: number, data: ApprovalProcessPayload) => {
    return RequestUtil.put({ url: `${REQUEST_URL.APPROVAL_PROCESS}/${id}`, data: data });
  },

  delete: (id: number) => {
    return RequestUtil.delete({ url: `${REQUEST_URL.APPROVAL_PROCESS}/${id}` });
  },
};
