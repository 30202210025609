/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import NewOrder from "@Assets/images/new-order.png";
import {
  Action,
  AddressModal,
  AuthCustomerProfileOutputModel,
  CustomerOutputModel,
  FetchAction,
  ItemPermission,
  LeadModal,
  OrderDetailOutputModel,
  ProductOutputModel,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, DepositService, OrderService, ProductService } from "@Services";
import {
  FormUtil,
  OrderUtil,
  PermissionUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  PersonAddAlt1 as AddCustomerIcon,
  DeleteOutline as DeleteOutlineIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _, { get } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
// import { DetachIcon } from "@Components/Icons/DetachIcon";
import { EventIcon } from "@Components/Icons/EventIcon";
import { MapIcon } from "@Components/Icons/MapIcon";
import Loading from "@Components/Loading";
import OrderHeader from "@Components/OrderHeader";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, ConditionQuantity, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { useTranslation } from "react-i18next";
import CreateLead from "../Farm/Lead/CreateLead";
import "./OrderFarmModal.scss";
import OrderHeaderFarm from "@Components/OrderHeaderFarm";

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

function reducerForCustomer(state: PaginateFetchReducer<CustomerOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

function reducerForLead(state: PaginateFetchReducer<LeadModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

function reducerForDeposit(state: PaginateFetchReducer<LeadModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        // ...action.payload,
        fetching: false,
        data: action.payload,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

function reducerForSite(state: PaginateFetchReducer<LeadModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        // ...action.payload,
        fetching: false,
        data: action.payload,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}


type OrderProduct = OrderDetailOutputModel & ProductOutputModel;

const OrderFarmModalType: React.FC = () => {

  const { t } = useTranslation(["translation", "order"]);
  const [depositId, setDepositId] = useState<any>("");
  const [siteId, setSiteId] = useState<any>("");
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);
  const deliveryTimes = [
    { value: '08:00', label: '08:00 - 10:00' },
    { value: '10:00', label: '10:00 - 12:00' },
    { value: '14:00', label: '14:00 - 16:00' },
    { value: '16:00', label: '16:00 - 18:00' },
  ];

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);
  const [openLeadModal, setOpenLeadModal] = useState<boolean>(false);
  const [list, setList] = useState<number>(1);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);
  const [listTimeFromSap, setListTimeFromSap] = useState([]);

  const [visibleHeader, setVisibleHeader] = useState<boolean>(false);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchCus, setSearchCus] = useState<string>("");
  const [createLead, setCreateLead] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [showCus, setShowCus] = useState<boolean>(true);
  const [showLead, setShowLead] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const mounted = useRef(false);
  const mountedCus = useRef(false);
  const mountedLead = useRef(false);
  const [dataOrd, setDataOrd] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});
  const [customerLead, setCustomerLead] = useState<any>({});
  const [leadDataCreated, setLeadDataCreated] = useState<any>({});
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const handleChangeTab = (event: any, newList: any) => {
    setList(newList);
  };

  const createId = profile?.employee?.find((e: any) => e.source === "farm")?.id || profile?.id;
  const itemGroupTypeCode = profile?.itemGroupTypeFarm?.find((e: any) => e.id == searchQuery.get("itemGroupType"))?.code
  //Call API get lead
  const [lead, dispatchLead] = useReducer(reducerForLead, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const [deposit, dispatchDeposit] = useReducer(reducerForDeposit, {
    data: [],
    fetching: false,
    errorMessage: "",
  })

  const [site, dispatchSite] = useReducer(reducerForSite, {
    data: [],
    fetching: false,
    errorMessage: "",
  })

  console.log(site, "site");

  useEffect(() => {
    mountedLead.current = true;
    return () => {
      mountedLead.current = false;
    };
  }, []);

  // const getRequiredTime

  const fetchDataLead = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatchLead({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllLead(query);
      if (isReset)
        dispatchLead({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mountedLead.current)
        dispatchLead({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mountedLead)
        dispatchLead({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchDataLead(
      {
        page: 1,
        limit: 10,
        searchString: searchCus,
        isCreateOrder: true,
      },
      true
    );
    // eslint-disable-next-line
  }, [searchCus]);

  const hasMoreLead = lead.data.length < lead.totalRecords && lead.page < lead.totalPages;

  //Call API get all customer
  const [state, dispatch] = useReducer(reducerForCustomer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mountedCus.current = true;
    return () => {
      mountedCus.current = false;
    };
  }, []);

  const fetchDataCus = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllCustomer(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchDataCus(
      {
        page: 1,
        limit: 10,
        source: "farm",
        strSearch: searchCus,
        isActive: true,
        hasCusDiv: true,
      },
      true
    );
    // eslint-disable-next-line
  }, [searchCus]);

  const hasMoreCus = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearchCus = _.debounce((value) => {
    setSearchCus(value);
  }, 800);

  //API get prod
  const [states, dispatchs] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);



  const fetchDataSite = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchSite({ type: Action.FETCH, payload: {} });
    try {
      const result = await OrderService.getAllSiteFarm(query);
      console.log(result);
      if (isReset)
        dispatchSite({
          type: Action.RESET,
          payload: result.data.data,
        });
      else if (mounted.current)
        dispatchSite({
          type: Action.SUCCESS,
          payload: result.data,
        });

    } catch (err) {
      dispatchSite({
        type: Action.SUCCESS,
        payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
      });
      // if (mounted)
      //   dispatchs({
      //     type: Action.FAILED,
      //     payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
      //   });
    }
  }


  const fetchDataDeposit = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchDeposit({ type: Action.FETCH, payload: {} });

    try {
      const result = await DepositService.getDepositGroupType(searchQuery.get("itemGroupType") ?? "0");
      if (result.data.length === 1) {
        setDepositId(
          result.data[0]
        )
      }
      if (isReset)
        dispatchDeposit({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatchDeposit({
          type: Action.SUCCESS,
          payload: result.data,
        });

    } catch (err) {
      if (mounted)
        dispatchs({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  }

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchs({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.getProductByAdminFarm(query);
      const resultTime = await ProductService.getTimeFromSap({
        page: 1,
        limit: 99
      })
      if (resultTime) {
        setListTimeFromSap(resultTime.data.data);
      }
      if (isReset)
        dispatchs({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatchs({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatchs({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchDataDeposit(
      searchQuery.get("itemGroupType"),
      true
    );
  }, [])

  useEffect(() => {
    // Object.keys(customer).length !== 0 &&
    //   fetchData(
    //     {
    //       page: 1,
    //       limit: 20,
    //       source: "farm",
    //       searchValue: searchValue,
    //       customerId: customer?.id,
    //       itemGroupId: searchQuery.get("itemGroupType"),
    //     },
    //     true
    //   );
    Object.keys(customer).length !== 0 &&
      fetchDataSite(
        {
          source: "farm",
          page: 1,
          limit: 500,
          customerId: customer?.id,
        },
        true
      );


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, customer]);

  useEffect(() => {
    if (siteId) {
      fetchData({
        page: 1,
        limit: 20,
        source: "farm",
        searchValue: searchValue,
        customerId: customer?.id,
        itemGroupId: searchQuery.get("itemGroupType"),
        siteId: siteId,
      },
        true
      )
    }
  }, [siteId]);

  useEffect(() => {
    console.log(123);
    Object.keys(customerLead).length !== 0 &&
      fetchData(
        {
          page: 1,
          limit: 20,
          source: "farm",
          searchValue: searchValue,
          leadId: customerLead.id,
        },
        true
      );
  }, [searchValue, customerLead]);

  useEffect(() => {
    Object.keys(leadDataCreated).length !== 0 &&
      fetchData(
        {
          page: 1,
          limit: 20,
          source: "farm",
          searchValue: searchValue,
          leadId: leadDataCreated.id,
        },
        true
      );
  }, [searchValue, leadDataCreated]);

  const hasMore = states.data?.length < states.totalRecords && states.page < states.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 800);

  const [proFarm, setProFarm] = useState<
    {
      item: OrderProduct;
      quantity: number;
      dimension: any;
      date?: any;
      orderAddress?: string;
      note?: string;
      requiredTime?: string;
    }[]
  >([]);

  const onUpdateProdVarDimension = (
    index: number,
    variantId: number,
    quantity: number,
    dimen: any,
    addedProduct?: OrderProduct,
    orderDate?: any,
    ordAddress?: any,
    note?: string,
  ) => {
    
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);

    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress || "",
        note: note || "",
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }

      data.push({
        item: addedProduct,
        quantity,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress,
        note: note,
      });
    }

    data = data.filter((item) => item.quantity >= 1);

    setProFarm(data);
  };

  const updateRequiredTime = (index: number, variantId: number, idRequiredtime: any) => {
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);
    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        requiredTime: idRequiredtime
      };
    }
    data = data.filter((item) => item.quantity >= 1);
    setProFarm(data);
  }
  // Tổng cộng nè
  console.log(proFarm, "profarm");
  const total = proFarm.reduce((sum, val) => {
    const itemProduct = { ...val.item.item };
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0]
        : val.dimension[1]
      : +val.item.item.dimension;
    const unitPrice = Number(val.item.value);
    const grossWeight = Number(!itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0 ? valDimen : itemProduct?.grossWeight)
    const valPriceDimen = +val.item.dimension;

    switch (val.item.priceType) {
      case "increment":
        return sum += val.quantity * unitPrice + val.quantity * Number(val.item.additionValue) * (grossWeight - Number(itemProduct?.netWeight));
      case "fixed":
        return sum += (val.quantity * grossWeight * unitPrice) + (val.quantity * Number(val.item.additionValue))
      case "bs":
        return sum += val.quantity * unitPrice * grossWeight
      default:
        return sum += 0
    }
  }, 0);

  const [checkError, setCheckError] = useState<boolean>(false);
  const checkDate = proFarm.filter((item) => item.date === undefined)?.length;
  const checkAddress = proFarm.filter((item) => item.orderAddress === "")?.length;
  const checkRequiredTime = proFarm.filter((item) => !item?.requiredTime)?.length;
  const checkQuantityError = (): boolean => {
    let error = false
    proFarm.map((val, index) => {
      let quantityError = OrderUtil.checkQuantityOrderFarm(
        val.quantity,
        val.item.item.conditionQuantity as any
      )
      if (!quantityError) {
        error = true;
      }
    })
    return error;
  }
  const submitForm = async (values: any) => {
    if (checkError) {
      return;
    }

    const validProDim = proFarm.filter((item) => item.quantity);
    if (!validProDim.length) {
      // setNotiOrderNull(true);
    }


    let total = 0;
    let subTotal = 0;
    const orderDetails = validProDim.map((item) => {

      console.log(item);
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.dimension;

      // const valDimentionToShow = Array.isArray(item.dimension)
      //   ? !+item.dimension[0]
      //     ? item.dimension[1]
      //     : item.dimension[0]
      //   : 0;
      // Đặt 3 cái item kiểu này FE thua luôn =)))
      const itemProduct = { ...item.item.item };

      const unitPrice = Number(item.item.value);
      const grossWeight = Number(!itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0 ? valDimention : itemProduct?.grossWeight)


      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.dimension;

      const valPriceDimen = +item.item.dimension;

      switch (item.item.priceType) {
        case "bs":
          subTotal = item.quantity * unitPrice * grossWeight
          break;
        case "fixed":
          subTotal = item.quantity * (unitPrice + Number(item.item.additionValue))
          break;
        case "increment":
          subTotal = item.quantity * unitPrice + item.quantity * Number(item.item.additionValue) * (grossWeight - Number(itemProduct?.netWeight));
          break;
        default:
      }

      total += subTotal;

      return {
        condition: get(item, "item.condition", undefined),
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        note: item.note,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        requiredTime: item.requiredTime,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? item.item.value : -1,
        saleUnit: item.item.item.saleUnit,
        dimensionToShow: +valDimentionToShow,
        baseUoM: item.item.item.baseUoM,
        dimension: valDimention * item.quantity,
        subTotal: checkPermissionViewPrice ? subTotal : -1,
      };
    });
    // Get debt of customer
    if (!ordDraft) {
      try {
        const debtData = await OrderService.getDebtCustomer(customer?.id);
        setLoading(false);
        if (debtData && debtData.data.debt < 1000) {
          const data: Parameters<typeof OrderService.createOrder>[0] = {
            // ...values,
            customerId: showCus ? customer?.id : undefined,
            siteId: siteId,
            leadId: showCus
              ? undefined
              : Object.keys(leadDataCreated).length === 0
                ? customerLead.id
                : leadDataCreated.id,
            creatorId: createId,
            salesOrgId: showCus
              ? customer?.customerDivisions[0].salesOrg.id
              : Object.keys(leadDataCreated).length === 0
                ? customerLead.salesOrg?.id
                : leadDataCreated?.salesOrg.id,
            distributionChannelId: showCus ? customer?.customerDivisions[0].distributionChannel.id : 4,
            address: showCus ? customer?.address : "",
            requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
            currency: "cash",
            salesOfficeId: customer?.customerDivisions[0]?.salesOfficeFarm?.id ?? null,
            totalDiscount: 0,
            vat: 0,
            orderAmount: checkPermissionViewPrice ? total : -1,
            isDraft: ordDraft,
            source: "farm",
            orderDetails: orderDetails,
            itemGroupTypeId: Number(searchQuery.get('itemGroupType')),
            depositId: depositId.id
          } as any;

          OrderService.createOrder(data as any)
            .then((res) => {
              if (res && (res.status === 200 || res.status === 201)) {
                setNotiOrder({ visible: true, newOrder: true });
                setDataOrd(res.data.data);
                setPlaying(true);
                setLoading(false);
              } else {
                setLoading(false);
              }
            })
            .catch((err: AxiosError) => {
              setLoading(false);
              if (err.response?.status === 400) {
                enqueueSnackbar("Số lượng không hợp lệ", {
                  variant: "error",
                });
              } else {
                setNotiOrder({ visible: true, newOrder: false });
              }
            });
        }
        else {
          enqueueSnackbar("Khách hàng vượt quá công nợ cho phép đặt hàng, vui lòng kiểm tra lại", {
            variant: "error",
          });
        }
      }
      catch (err) {
        setLoading(false);
      }
    }
    else {
      const data: Parameters<typeof OrderService.createOrder>[0] = {
        // ...values,
        customerId: showCus ? customer?.id : undefined,
        leadId: showCus
          ? undefined
          : Object.keys(leadDataCreated).length === 0
            ? customerLead.id
            : leadDataCreated.id,
        creatorId: createId,
        siteId: siteId,
        salesOrgId: showCus
          ? customer?.customerDivisions[0].salesOrg.id
          : Object.keys(leadDataCreated).length === 0
            ? customerLead.salesOrg?.id
            : leadDataCreated?.salesOrg.id,
        distributionChannelId: showCus ? customer?.customerDivisions[0].distributionChannel.id : 4,
        address: showCus ? customer?.address : "",
        requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
        currency: "cash",
        totalDiscount: 0,
        vat: 0,
        orderAmount: checkPermissionViewPrice ? total : -1,
        isDraft: ordDraft,
        source: "farm",
        orderDetails: orderDetails,
        itemGroupTypeId: Number(searchQuery.get('itemGroupType')),
        depositId: depositId.id,
        salesOfficeId: customer?.customerDivisions[0]?.salesOfficeFarm?.id ?? null,
      } as any;

      OrderService.createOrder(data as any)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            if (ordDraft === true) {
              history.push(APP_ROUTES.ORDER_FARM);
            } else {
              setNotiOrder({ visible: true, newOrder: true });
              setDataOrd(res.data.data);
              setPlaying(true);
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((err: AxiosError) => {
          setLoading(false);
          if (err.response?.status === 400) {
            enqueueSnackbar("Số lượng không hợp lệ", {
              variant: "error",
            });
          } else {
            setNotiOrder({ visible: true, newOrder: false });
          }
        });
    }

  };



  const saveAddress = async (val: string) => {
    await CustomerService.createAddress({
      customerId: customer.id,
      name: customer.fullName,
      phoneNumber: customer.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("order:ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal">
        {!openLeadModal && (
          <SimpleModalHeader
            title={t("order:CREATE_ORDER_FARM")}
            onClose={() => {
              history.push({
                pathname: APP_ROUTES.ORDER_FARM,
                search: `?itemGroupTypeId=${searchQuery.get("itemGroupType")}`,
              });
            }}
          />
        )}
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form
                onSubmit={async (values: any) => {
                  submitForm(values);
                }}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="header header-order-type">
                        {Object.keys(leadDataCreated).length === 0 ? (
                          <>
                            {!visibleHeader ? (
                              <div className="header-content">
                                <div className="customer">
                                  <div
                                    className={!visibleAddCustomer ? "customer-textfield" : "active"}
                                    onClick={() => {
                                      setVisibleAddCustomer(true);
                                    }}>
                                    <AddCustomerIcon />
                                    <TextField
                                      placeholder={
                                        !visibleAddCustomer
                                          ? t("SELECT_CUSTOMER")
                                          : t("SEARCH_BY_NAME_CUSTOMER_CODE")
                                      }
                                      onChange={(e) => {
                                        setCreateLead(e.target.value);
                                        onSearchCus(e.target.value);
                                      }}
                                      variant="outlined"
                                      className="textfield__root"
                                    />
                                  </div>
                                  {visibleAddCustomer && (
                                    <div className="customer-list">
                                      <div className="customer-list__tab">
                                        <Tabs className="tab" value={list} onChange={handleChangeTab}>
                                          <Tab
                                            style={{ fontFamily: "SVN-Gotham" }}
                                            label={`${t("CUSTOMER")} (${state.totalRecords}) `}
                                            value={1}
                                            onClick={() => {
                                              setShowCus(true);
                                              setShowLead(false);
                                            }}
                                          />
                                          <Tab
                                            style={{ fontFamily: "SVN-Gotham" }}
                                            label={` ${t("order:POTENTIAL")} (${lead.totalRecords})`}
                                            value={2}
                                            onClick={() => {
                                              setShowCus(false);
                                              setShowLead(true);
                                            }}
                                          />
                                        </Tabs>
                                      </div>
                                      {showLead === true && (
                                        <div
                                          className="customer-list__create"
                                          onClick={() => setOpenLeadModal(true)}>
                                          <div className="icon">+</div>
                                          {t("CREATE_NEW_BY_NAME", {
                                            NAME: "CUSTOMER",
                                          })}{" "}
                                          {createLead ? `"${createLead}"` : t("order:POTENTIAL")}
                                        </div>
                                      )}
                                      <div style={{ maxHeight: "49.5rem", overflow: "auto" }}>
                                        {state.fetching && searchCus && <Loading />}
                                        {showCus && (
                                          <InfiniteScroll
                                            useWindow={false}
                                            pageStart={0}
                                            initialLoad={false}
                                            hasMore={hasMoreCus}
                                            loadMore={() =>
                                              fetchDataCus({
                                                limit: 10,
                                                page: state.page + 1,
                                                source: "farm",
                                                strSearch: searchCus,
                                                hasCusDiv: true,
                                              })
                                            }>
                                            {state.data.map(
                                              (
                                                c: AuthCustomerProfileOutputModel,
                                                indexCus: number
                                              ) => {
                                                return (
                                                  <>
                                                    <div
                                                      key={indexCus}
                                                      className="customer-list__content"
                                                      onClick={() => {
                                                        setCustomer(state.data[indexCus]);
                                                        setVisibleHeader(true);
                                                      }}>
                                                      <img
                                                        src={
                                                          checkLinkImage(c.account?.avatar) ||
                                                          renderImageProNull()
                                                        }
                                                        alt=""
                                                        style={{
                                                          width: "4.8rem",
                                                          height: "4.8rem",
                                                          borderRadius: "50%",
                                                          marginRight: "1rem",
                                                        }}
                                                        onError={renderErrorImageItem()}
                                                      />
                                                      <div>
                                                        <span
                                                          style={{
                                                            fontSize: "1.8rem",
                                                            textTransform: "uppercase",
                                                          }}>
                                                          {c.fullName}
                                                        </span>
                                                        <br />
                                                        <span
                                                          style={{
                                                            fontSize: "1.4rem",
                                                            opacity: "0.4",
                                                          }}>
                                                          {c.code || TEXT_HOLDER}
                                                          <span
                                                            style={{
                                                              marginLeft: "1rem",
                                                            }}>
                                                            &#9679;&ensp;
                                                          </span>
                                                          {c.phoneNumber || TEXT_HOLDER}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <Divider style={{ margin: "0 2rem" }} />
                                                  </>
                                                );
                                              }
                                            )}
                                          </InfiniteScroll>
                                        )}
                                        {showLead && (
                                          <InfiniteScroll
                                            useWindow={false}
                                            pageStart={0}
                                            initialLoad={false}
                                            hasMore={hasMoreLead}
                                            loadMore={() =>
                                              fetchDataLead({
                                                limit: 10,
                                                page: lead.page + 1,
                                                searchString: searchCus,
                                                isCreateOrder: true,
                                              })
                                            }>
                                            {lead.data.map((c: LeadModal, indexLead: number) => {
                                              return (
                                                <>
                                                  <div
                                                    key={indexLead}
                                                    className="customer-list__content"
                                                    onClick={() => {
                                                      setCustomerLead(lead.data[indexLead]);
                                                      setVisibleHeader(true);
                                                    }}>
                                                    <img
                                                      src={renderImageProNull()}
                                                      alt=""
                                                      style={{
                                                        width: "4.8rem",
                                                        height: "4.8rem",
                                                        borderRadius: "50%",
                                                        marginRight: "1rem",
                                                      }}
                                                      onError={renderErrorImageItem()}
                                                    />
                                                    <div>
                                                      <span
                                                        style={{
                                                          fontSize: "1.8rem",
                                                          textTransform: "uppercase",
                                                        }}>
                                                        {c.fullName}
                                                      </span>
                                                      <br />
                                                      <span
                                                        style={{
                                                          display: "flex",
                                                          flexDirection: "row",
                                                          justifyContent: "space-between",
                                                          fontSize: "1.4rem",
                                                          opacity: "0.4",
                                                        }}>
                                                        {c.code || TEXT_HOLDER}
                                                        <span
                                                          style={{
                                                            marginLeft: "0.7rem",
                                                          }}>
                                                          &#9679;&ensp;
                                                        </span>
                                                        {c.phoneNumber}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <Divider style={{ margin: "0 2rem" }} />
                                                </>
                                              );
                                            })}
                                          </InfiniteScroll>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {!depositId ? (
                                  <div className="deposit-order">
                                    <Field
                                      name="depositId"
                                      validate={
                                        !depositId
                                          ? FormUtil.Rule.required("Bạn chưa chọn hình thức cọc")
                                          : undefined
                                      }>
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <FormControl style={{ width: "100%", height: "auto" }}>
                                            <Select
                                              {...input}
                                              {...rest}
                                              className="select-deposit"
                                              fullWidth
                                              required={true}
                                              value={depositId}
                                              variant="outlined"
                                              renderValue={() => {
                                                let placeHolder = "Phương thức cọc"
                                                return (
                                                  depositId ? (
                                                    <span className="form-value-text">
                                                      {depositId?.code} - {depositId?.rate1}
                                                    </span>
                                                  ) : (
                                                    <span className="form__placeholder">
                                                      {placeHolder}
                                                    </span>
                                                  )
                                                )
                                              }}
                                              // error={meta.error && meta.touched}
                                              onChange={(e) => {
                                                let depositIdChoosen = e.target.value;
                                                if (depositIdChoosen) {
                                                  let depositObject = deposit?.data?.find((item: any, index: any) => {
                                                    return Number(depositIdChoosen) === Number(item.id);
                                                  })
                                                  if (depositObject) {
                                                    setDepositId(depositObject)
                                                  }
                                                }
                                                else {
                                                  setDepositId("")
                                                }
                                              }}
                                              error={meta.error && meta.touched}
                                              displayEmpty
                                              MenuProps={{
                                                className: "select-menu-list",
                                                style: { maxHeight: "40rem" },
                                              }}>
                                              {deposit?.data?.map((item: any, index: any) => {
                                                return (
                                                  <MenuItem key={item.code} value={item.id}>
                                                    {`${item.code}-${item.rate1}`}
                                                  </MenuItem>
                                                )
                                              })}
                                            </Select>
                                          </FormControl>
                                        )
                                      }}
                                    </Field>
                                  </div>
                                )
                                  : (
                                    <div className="deposit-order-value">
                                      <div className="deposit-order-header">Phương thức cọc</div>
                                      <div>{depositId?.code} - {depositId?.rate1}</div>
                                    </div>
                                  )
                                }
                              </div>
                            )
                              : (
                                <>
                                  <OrderHeaderFarm
                                    setSiteId={setSiteId}
                                    sourceType={"farm"}
                                    siteId={siteId}
                                    avt={dataOrd?.account?.avatar}
                                    code={showCus === true ? customer.code : customerLead.code}
                                    fullName={
                                      showCus === true ? customer.fullName : customerLead.fullName
                                    }
                                    phoneNumber={
                                      showCus === true
                                        ? customer.phoneNumber
                                        : customerLead.phoneNumber
                                    }
                                    salesOrg={
                                      showCus === true
                                        ? customer.customerDivisions[0].salesOrg?.description
                                        : customerLead.salesOrg?.description
                                    }
                                    depositId={depositId}
                                    listDeposit={deposit?.data}
                                    setDepositId={setDepositId}
                                    listSite={site?.data}
                                    onClear={() => {
                                      setVisibleHeader(false);
                                      setProFarm([]);
                                      setCustomerLead({});
                                      setSearchCus("");
                                      if (deposit.data.length !== 1) {
                                        setDepositId("");
                                      }
                                    }}
                                    groupType={itemGroupTypeCode}
                                    salesDistrict={
                                      customer.customerDivisions[0].bu?.name
                                    }
                                    salesGroup={
                                      customer.customerDivisions[0].salesGroup?.description
                                    }
                                  />
                                </>
                              )}
                          </>
                        ) : (
                          <>
                            <OrderHeaderFarm
                              sourceType={"farm"}
                              setSiteId={setSiteId}
                              siteId={siteId}
                              avt={leadDataCreated?.avatar}
                              code={leadDataCreated?.code}
                              fullName={leadDataCreated?.fullName}
                              phoneNumber={leadDataCreated?.phoneNumber}
                              salesOrg={leadDataCreated?.salesOrg?.description}
                              depositId={depositId}
                              listDeposit={deposit?.data}
                              listSite={site?.data}
                              setDepositId={setDepositId}
                              onClear={() => {
                                setVisibleHeader(false);
                                setLeadDataCreated({});
                                if (deposit.data.length !== 1) {
                                  setDepositId("");
                                }
                              }}
                              groupType={itemGroupTypeCode}
                                salesDistrict={
                                  customer.customerDivisions[0].bu?.name
                                }
                                salesGroup={
                                  customer.customerDivisions[0].salesGroup?.description
                                }
                            />
                          </>
                        )}
                      </div>
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="dimen">{t("WEIGHT_RANGE")}</div>
                          <div className="unit">{t("TOTAL_WEIGHT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="additionValue">Phí giống</div>
                          <div className="additionValue">Phần vượt</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {!!proFarm.length &&
                            proFarm.map((val, index) => {
                              return (
                                <div key={index}>
                                  <div className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(val.item.item.images?.picture as any) ||
                                          renderImageProNull(val.item.item.source)
                                        }
                                        alt="product"
                                        onError={renderErrorImageItem(val.item.item.source)}
                                      />
                                      <div className="name">
                                        {val.item.item.name}
                                        <div className="code">{val.item.item.code}</div>
                                      </div>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={val.quantity <= 1}
                                        quantity={val.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarDimension(
                                            index,
                                            val.item.item.id,
                                            value,
                                            val.dimension,
                                            val.item,
                                            val.date,
                                            val.orderAddress,
                                            val.note
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>
                                    {Array.isArray(val.item.item.dimension) === true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(val.item.item.dimension) && (
                                          <>
                                            <Select
                                              defaultValue={
                                                val.dimension
                                                  ? val.dimension?.toString()
                                                  : val.item.item.dimension[0]?.toString()
                                              }
                                              // value={valueWeight.toString()}
                                              onChange={(e) => {
                                                onUpdateProdVarDimension(
                                                  index,
                                                  val.item.item.id,
                                                  val.quantity,
                                                  e.target.value.split(","),
                                                  val.item,
                                                  val.date,
                                                  val.orderAddress,
                                                  val.note
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {val.item.item.dimension.map((d, idx: number) => {
                                                return (
                                                  <MenuItem
                                                    key={idx}
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                    }}
                                                    value={d.toString()}>
                                                    {(() => {
                                                      if (d[1] === "null") {
                                                        return "trên " + d[0];
                                                      } else if (d[0] === "null") {
                                                        return "dưới " + d[1];
                                                      } else {
                                                        return d[0] + " - " + d[1];
                                                      }
                                                    })()}
                                                    &#160;kg
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {/* {!OrderUtil.checkQuantityOrderFarm(
                                              val.quantity,
                                              val.item.item.conditionQuantity as any
                                            ) && (
                                                <div
                                                  className={
                                                    OrderUtil.checkQuantityOrderFarm(
                                                      val.quantity,
                                                      val.item.item.conditionQuantity as any
                                                    )
                                                      ? "err-quantity"
                                                      : "err-quantity err"
                                                  }>
                                                  {t("order:REQUIRED_QUANTITY")}&nbsp;
                                                  <span style={{ textTransform: "lowercase" }}>
                                                    {
                                                      ConditionQuantity.find(
                                                        (f: any) =>
                                                          val.item.item.conditionQuantity?.replace(
                                                            /\d/g,
                                                            ""
                                                          ) === f.value
                                                      )?.title
                                                    }
                                                  </span>
                                                  &nbsp;
                                                  {val.item.item.conditionQuantity?.match(
                                                    /[0-9]+/g
                                                  )?.[1]
                                                    ? val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0] +
                                                    "-" +
                                                    val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[1]
                                                    : val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0]}
                                                </div>
                                              )} */}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {val.item.item.dimension}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{val.item.item.baseUoM}
                                        </span>
                                        {/* {!OrderUtil.checkQuantityOrderFarm(
                                          val.quantity,
                                          val.item.item.conditionQuantity as any
                                        ) && (
                                            <div
                                              className={
                                                OrderUtil.checkQuantityOrderFarm(
                                                  val.quantity,
                                                  val.item.item.conditionQuantity as any
                                                )
                                                  ? "err-quantity"
                                                  : "err-quantity err"
                                              }>
                                              {t("order:REQUIRED_QUANTITY")}&nbsp;
                                              <span style={{ textTransform: "lowercase" }}>
                                                {
                                                  ConditionQuantity.find(
                                                    (f: any) =>
                                                      val.item.item.conditionQuantity?.replace(
                                                        /\d/g,
                                                        ""
                                                      ) === f.value
                                                  )?.title
                                                }
                                              </span>
                                              &nbsp;
                                              {val.item.item.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                                ? val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0] +
                                                "-" +
                                                val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                                : val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0]}{" "}
                                            </div>
                                          )} */}
                                      </div>
                                    )}
                                    <div className="unit unit-farm">
                                      {Array.isArray(val.dimension)
                                        ? !+val.dimension[1]
                                          ? currencyFormat(val.dimension[0] * val.quantity)
                                          : currencyFormat(val.dimension[1] * val.quantity)
                                        : currencyFormat(+val.dimension * val.quantity)}

                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {val.item.item.baseUoM} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(val.item.value)
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{val.item.baseUoM}</span>
                                    </div>
                                    {/* Phí giống */}
                                    <div className="additionValue">
                                      {(() => {
                                        // Phí giống chỉ có giá trị khi priceType = fixed
                                        {/* Nếu priceType = increment thì bên cột Phần vượt sẽ = additionValue và Phí giống = 0 */ }
                                        // Nếu priceType = fixed thì bên cột Phần vượt sẽ = 0 và Phí giống = additionValue
                                        // nếu priceType = bs thì cả 2 cột phần vượt và phí giống đều = 0 
                                        switch (val.item.priceType) {
                                          case "fixed":
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          default:
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(0)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                        }
                                      })()}
                                    </div>
                                    {/* Phần vượt */}
                                    <div className="additionValue">
                                      {(() => {
                                        // Phần vượt chỉ có giá trị khi priceType = increment
                                        {/* Nếu priceType = increment thì bên cột Phần vượt sẽ = additionValue và Phí giống = 0 */ }
                                        // Nếu priceType = fixed thì bên cột Phần vượt sẽ = 0 và Phí giống = additionValue
                                        // nếu priceType = bs thì cả 2 cột phần vượt và phí giống đều = 0 
                                        switch (val.item.priceType) {
                                          case "increment":
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          default:
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(0)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                        }
                                      })()}
                                    </div>
                                    <div className="pay">
                                      {(() => {
                                        const itemProduct = { ...val.item.item };
                                        const valDimen = Array.isArray(val.dimension)
                                          ? !+val.dimension[1]
                                            ? val.dimension[0]
                                            : val.dimension[1]
                                          : +val.dimension;
                                        const unitPrice = Number(val.item.value);
                                        const grossWeight = Number(!itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0 ? valDimen : itemProduct?.grossWeight)
                                        const valPriceDimen = +val.item.dimension;

                                        switch (val.item.priceType) {
                                          case "increment":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                    val.quantity * unitPrice + val.quantity * Number(val.item.additionValue) * (grossWeight - Number(itemProduct?.netWeight))
                                                  )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                    (val.quantity * grossWeight * unitPrice) + (val.quantity * Number(val.item.additionValue))
                                                  )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice ?
                                                  currencyFormat(
                                                    val.quantity * unitPrice * grossWeight
                                                  )
                                                  : TEXT_ASTERISK
                                                }
                                              </>

                                            )
                                          // default:
                                          //   return (
                                          //     <>
                                          //       {checkPermissionViewPrice
                                          //         ? currencyFormat(
                                          //           valPriceDimen *
                                          //           val.item.value *
                                          //           val.quantity +
                                          //           Math.max(0, valDimen - valPriceDimen) *
                                          //           val.quantity *
                                          //           parseFloat(val.item.additionValue)
                                          //         )
                                          //         : TEXT_ASTERISK}
                                          //     </>
                                          //   );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        onUpdateProdVarDimension(
                                          index,
                                          val.item.item.id,
                                          -1,
                                          val.dimension
                                        );
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("order:SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                className="form-text-field date"
                                                value={val.date || null}
                                                placeholder={t("order:DELIVERY_DATE")}
                                                onChange={(e) => {
                                                  input.onChange(e);
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    e,
                                                    val.orderAddress,
                                                    val.note
                                                  );
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          val.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("order:DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>

                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{
                                              className: "input",
                                              maxLength: 300
                                            }}
                                            variant="standard"
                                            autoComplete="off"
                                            value={val.note}
                                            onChange={(e) => {
                                              input.onChange(e);
                                              onUpdateProdVarDimension(
                                                index,
                                                val.item.item.id,
                                                val.quantity,
                                                val.dimension,
                                                val.item,
                                                val.date,
                                                val.orderAddress,
                                                e.target.value
                                              );
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            {showLead ? (
                                              <TextField
                                                {...input}
                                                {...rest}
                                                fullWidth
                                                placeholder={t("INPUT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                className="form-text-field addr form-text-field-addr"
                                                inputProps={{ className: "input" }}
                                                variant="standard"
                                                value={val.orderAddress}
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    e.target.value,
                                                    val.note
                                                  );
                                                }}
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                                onKeyDown={(event) => {
                                                  if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                autoComplete="off"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <MapIcon
                                                        color="#41AD49"
                                                        size={[22, 22]}
                                                        viewBox={[10, 20]}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            ) : (
                                              <ShippingAddress<AddressModal>
                                                customerId={
                                                  showCus ? customer?.id : customerLead.id
                                                }
                                                handleSaveAddress={saveAddress}
                                                className={"form-text-field addr"}
                                                placeholder={t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                getOptionLabel={(opt) =>
                                                  opt.address || (opt.inputValue as any)
                                                }
                                                onChange={(value) => {
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    value?.address || value?.inputValue,
                                                    val.note
                                                  );
                                                }}
                                                renderOption={(p) => (
                                                  <div
                                                    className="item-addr"
                                                    style={{
                                                      fontSize: "1.6rem",
                                                      fontFamily: "SVN-Gotham",
                                                      color: p.title ? "#41AD49" : "",
                                                      borderBottom: "0.1rem solid #EFEFEF",
                                                      padding: "1rem 2rem",
                                                      width: "100%",
                                                    }}>
                                                    {p.title ? p.title : p.address}
                                                  </div>
                                                )}
                                                actionConfig={{
                                                  action: CustomerService.getAddress,
                                                }}
                                                dropdownBoxMaxHeight="24rem"
                                              />
                                            )}
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                val.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                  <div className="product-order-shipping">
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                      <Field name={`requiredTime${index}`} validate={(value) => (value ? undefined : "Chọn giờ giao hàng")}>
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <>
                                              <Select
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  updateRequiredTime(
                                                    index,
                                                    val.item.item.id,
                                                    e.target.value,
                                                  )
                                                }}
                                                value={input.value || ''}
                                                displayEmpty
                                                style={{
                                                  height: 32,
                                                  width: "14vw",
                                                  padding: "0px 10px"
                                                }}
                                                className="form-select-field"
                                                MenuProps={{
                                                  className: "select-menu-list",
                                                  style: { maxHeight: "40rem" },
                                                }}
                                                renderValue={(selected) => {
                                                  if (!selected) {
                                                    return <div style={{
                                                      color: "#a7a7a7",
                                                      // opacity: 1,
                                                      fontWeight: 600,
                                                      fontSize: 10
                                                    }}>Giờ giao hàng</div>
                                                  }
                                                  const findObject: any = listTimeFromSap.find((item: any, index) => item.key === selected)
                                                  if (findObject) {
                                                    return findObject.delieryTime;
                                                  }
                                                }}
                                              >
                                                {listTimeFromSap.map((time: any) => (
                                                  <MenuItem key={time.key} value={time.key}>
                                                    {time.delieryTime}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                              {meta.touched && meta.error && (
                                                <div style={{ color: "#e53935", fontSize: "1.6rem" }}>
                                                  {meta.error}
                                                </div>
                                              )}
                                            </>
                                          )
                                        }}
                                      </Field>
                                    </FormControl>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid container justifyContent="space-between">
                            <Grid item xs={7} className="info-order"></Grid>
                            <Grid item xs={4} className="total">
                              <div className="row">
                                {t("order:ESTIMATED")}
                                <div className="price">
                                  {checkPermissionViewPrice ? currencyFormat(total) : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                              <div className="row">
                                {t("order:SHIPPING_FEE")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TAX")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice ? currencyFormat(total) : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={proFarm.length ? "show" : "hide"}
                          onClick={() => {
                            // // !proFarm.length && setNotiOrderNull(false);
                          }}>
                          <button
                            disabled={!proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0 || checkQuantityError() || !depositId || checkRequiredTime > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setOrdDraft(true);
                                // setLoading(true);
                              }
                            }}
                            type="submit"
                            className="btn-confirm-style btn-border"
                            style={{ marginLeft: "4rem" }}>
                            {t("order:SAVE_CART")}
                          </button>
                          <button
                            disabled={!proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0 || checkQuantityError() || !depositId || checkRequiredTime > 0) {
                                console.log(proFarm);
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className="btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("order:PLACE_ORDER")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              {!visibleHeader ? (
                <div className="new-order">
                  <img src={NewOrder} alt="new-order" />
                  <span style={{ fontWeight: 700, fontSize: "1.8rem", lineHeight: "2.7rem" }}>
                    {t("order:ENTER_CUSTOMER_INFORMATION")}
                  </span>
                  <span>{t("order:START_CREATING_ORDER")}</span>
                </div>
              ) : (
                <>
                  <TextField
                    variant="outlined"
                    color="success"
                    placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                    className={"search-border-style"}
                    inputProps={{ className: "input" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="large" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />
                  <div className="col-text">{t("order:PIG_PRICE_TEMPORARY")}</div>
                  {(states.loading && siteId) || (states.fetching && searchValue && siteId) ? (
                    <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                  ) : (
                    <div className="product-list">
                      {states.data?.length > 0 && (
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={() =>
                            fetchData({
                              page: states.page + 1,
                              limit: 20,
                              source: "farm",
                              searchValue,
                            })
                          }
                          hasMore={hasMore}
                          useWindow={false}>
                          {states.data?.map((pro: any, index: number) => {
                            const act = proFarm.find((item) => item.item.item.id === pro.item.id);
                            const checkDimen = Array.isArray(pro.item.dimension);

                            return (
                              <ProductItem
                                class={act}
                                key={pro.item.code + index}
                                onClick={() => {
                                  if (act) {
                                    return;
                                  }
                                  onUpdateProdVarDimension(
                                    index,
                                    pro.item.id,
                                    1,
                                    checkDimen ? pro.item.dimension[0] : pro.item.dimension,
                                    pro
                                  );
                                }}
                                image={pro.item.images?.picture}
                                shortName={pro.item.name}
                                name={pro.item.code}
                                value={
                                  checkPermissionViewPrice
                                    ? currencyFormat(pro.value)
                                    : TEXT_ASTERISK
                                }
                                baseUoM={pro.baseUoM}
                                source="farm"
                                priceType={pro.priceType}
                                farmBaseUoM={pro.item.baseUoM}
                                farmSaleUnit={pro.item.saleUnit}
                                additionalValue={currencyFormat(pro.additionValue)}
                                interestedItem={pro?.item?.interestedItem}
                              />
                            );
                          })}
                        </InfiniteScroll>
                      )}
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>

        {
          loading && (
            <Backdrop
              sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <Loading />
            </Backdrop>
          )
        }

        <ResultOrder
          sourceType="farm"
          visible={notiOrder.visible}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
        />

        {
          openLeadModal && (
            <CreateLead
              closeForm={() => setOpenLeadModal(false)}
              setLead={(lead: any) => {
                setLeadDataCreated(lead);
                setVisibleHeader(true);
              }}
            />
          )
        }
      </div >
    </Dialog >
  );
};
export default OrderFarmModalType;
