import SelectedChip from "@Components/ui/GFSelectedChip";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";

type SelectedOrderProps = {
  data: any[];
  onRemoveSelectedById: (id: number) => void;
};

function SelectedOrder({ data, onRemoveSelectedById }: SelectedOrderProps) {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <Box mb={1}>
      {data.map((record: any) => (
        <SelectedChip title={record.code} onDelete={() => onRemoveSelectedById(record.id)} />
      ))}
    </Box>
  );
}

export default SelectedOrder;
