import { GFCustomerAvatar, GFDataTable, GFTooltip, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import OrderAccordionDetails from "@Containers/Order/components/OrderAccordionDetails";
import useGetMe from "@Hooks/useGetMe";
import { OrderOutputModel, OrderStatus, SourceType } from "@Models";
import { StatusUIUtil, toClientDate } from "@Utils";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { FarmType } from "src/types";
import { styled } from "@mui/material/styles";

const CombinationIndicator = styled("span")({
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  marginLeft: "5px",
  backgroundColor: "#FFD700",
});

const columns: GFDataTableColumn<OrderOutputModel>[] = [
  {
    title: "Mã đơn hàng",
    dataIndex: "code",
    width: 250,
    render: (cellData, record) => {
      return (
        <GFTypography type="primary">
          {cellData}
          {record.isCombination ? <CombinationIndicator /> : null}
        </GFTypography>
      );
    },
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    width: 250,
    render: (cellData, record) => {
      return StatusUIUtil.renderCircleStatusNew(null, record?.status, record?.subStatus);
    },
  },
  {
    title: "Sản phẩm",
    width: 300,
    autoWidth: true,
    dataIndex: "orders[0].orderlines[0].itemName" as any,
  },
  {
    title: "Người đặt hàng",
    width: 250,
    dataIndex: "createdBy" as any,
    showTooltip: true,
    render: (cellData) =>
      `${cellData?.fullName || TEXT_HOLDER} - ${cellData?.account?.code || TEXT_HOLDER}`,
  },
  {
    title: "Khách hàng",
    dataIndex: "orders[0]" as any,
    width: 300,
    render: (cellData) => (
      <GFCustomerAvatar
        avatarUrl={cellData?.customer?.account?.avatar}
        fullName={cellData?.customer?.fullName || cellData?.lead?.fullName || TEXT_HOLDER}
        code={cellData?.customer?.code || cellData?.lead?.code || TEXT_HOLDER}
      />
    ),
  },
  {
    title: "Đơn vị kinh doanh",
    dataIndex: "orders[0].salesOrg" as any,
    render: (cellData) => cellData?.description,
    width: 180,
    showTooltip: true,
  },
  {
    title: "Thời gian đặt hàng",
    dataIndex: "createdAt",
    width: 190,
    render: (cellData) => toClientDate(cellData),
  },
  {
    title: "Ngày giao hàng",
    dataIndex: "orders[0].requiredDate" as any,
    width: 190,
    render: (cellData) => toClientDate(cellData),
  },
];

type BoarSemenProps = {
  data: OrderOutputModel[];
  hasMore: boolean;
  loading: boolean;
  synchronized: boolean;
  sourceType: SourceType;
  itemGroupTypeId: string | null;
  range: {
    startTime: moment.Moment;
    endTime: moment.Moment;
  };
  selectTab: string;
  type?: FarmType;
  onLoadMore: () => void;
};

export default function BoarSemen({
  data,
  hasMore,
  loading,
  synchronized,
  sourceType,
  range,
  selectTab,
  type,
  itemGroupTypeId,
  onLoadMore,
}: BoarSemenProps) {
  const { permissions, getPigTypeIdByCode, profile } = useGetMe();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleRowClick = (code: string | number | null, record: any) => {
    let detailUrl: string = APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", record.id.toString());
    if (type === FarmType.Internal) {
      detailUrl = APP_ROUTES.ORDER_INTERNAL_DETAIL.replace(":orderId", record.id.toString());
    }

    if (
      synchronized === false &&
      record.status === OrderStatus.DRAFT &&
      sourceType === SourceType.FARM
    ) {
      history.push({
        pathname: APP_ROUTES.UPDATE_ORDER_FARM.replace(":orderId", record.id.toString()),
        state: {
          type: "DRAFT",
        },
        search: `?itemGroupTypeId=${itemGroupTypeId}`,
      });
    } else {
      permissions.hasPermissionOrderDetail
        ? history.push({
            pathname: detailUrl,
            state: {
              startTime: range.startTime.toISOString(),
              endTime: range.endTime.toISOString(),
              status: selectTab,
            },
            search: `?itemGroupTypeId=${itemGroupTypeId}`,
          })
        : enqueueSnackbar("Bạn không có quyền truy cập", {
            preventDuplicate: true,
            variant: "error",
          });
    }
  };

  return (
    <GFDataTable
      columns={columns}
      dataSource={data}
      hasMore={hasMore}
      onLoadMore={onLoadMore}
      showDateRange={false}
      showExcelButton={false}
      showCreateButton={false}
      showSearchSection={false}
      loading={loading}
      rowPrefix="boar-semen"
      onRowClick={handleRowClick}
      expandable={{
        expandedRowRender: (record) => {
          return <OrderAccordionDetails order={record} />;
        },
        rowExpandable: (record) => record?.orders?.length > 0,
      }}
      height="calc(100vh - 400px)"
    />
  );
}
