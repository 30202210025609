import { REQUEST_URL } from "@Constants";
import { DepositInputModel, PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const DepositService = {
  getAllDeposit: (
    query: PaginationQueryModel & {
      from: string;
      to: string;
      searchString: string;
    }
  ) => {
    const url = REQUEST_URL.DEPOSIT_GET_ALL_OR_CREATE;
    return RequestUtil.get({ url, query: query });
  },

  getDepositById: (id: string) => {
    const url = REQUEST_URL.DEPOSIT_DETAIL_UPDATE_DELETE.replace("{depositId}", id);
    return RequestUtil.get({ url });
  },

  createDeposit: (data: DepositInputModel) => {
    const url = REQUEST_URL.DEPOSIT_GET_ALL_OR_CREATE;
    return RequestUtil.post({ url, data: data });
  },

  updateDeposit: (id: string, data: DepositInputModel) => {
    const url = REQUEST_URL.DEPOSIT_DETAIL_UPDATE_DELETE.replace("{depositId}", id);
    return RequestUtil.patch({ url, data });
  },

  deleteDeposit: (id: string) => {
    const url = REQUEST_URL.DEPOSIT_DETAIL_UPDATE_DELETE.replace("{depositId}", id);
    return RequestUtil.delete({ url });
  },

  getDepositGroupType: (id: string) => {
    const url = REQUEST_URL.DEPOSIT_GET_ITEM_GROUP_TYPE.replace("{id}", id);
    return RequestUtil.get({ url });
  },
};
