import { ItemSection } from "@Components/Common";
import { GFCustomerAvatar, GFDataTable, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import GFPageDialog from "@Components/ui/GFPageDialog";
import { APP_ROUTES } from "@Constants";
import OrderAccordionDetails from "@Containers/Order/components/OrderAccordionDetails";
import OrderDetailItem from "@Containers/OrderCombination/OrderDetailItem";
import SelectedOrder from "@Containers/OrderCombination/SelectedOrder";
import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StatusUIUtil, toClientDate, toClientDateTime } from "@Utils";
import _, { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetOrderCombination,
  useOrderDetail,
  useUpdateOrderCombination,
} from "src/app/queries/order.query";
import "./styles.scss";

const columns: GFDataTableColumn[] = [
  {
    title: "Mã đơn hàng",
    dataIndex: "code",
    render(cellData, record, rowIndex, onChange) {
      return (
        <Link to={APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", record.id)} target="_blank">
          <GFTypography type="primary" sx={{ textDecoration: "underline" }}>
            {cellData}
          </GFTypography>
        </Link>
      );
    },
  },

  {
    title: "Sản phẩm",
    width: 300,
    autoWidth: true,
    dataIndex: "orders[0].orderlines[0].itemName" as any,
  },
  {
    title: "Thời gian đặt hàng",
    dataIndex: "createdAt",
    width: 250,
    render: (cellData) => toClientDateTime(cellData),
  },
  {
    title: "Ngày giao hàng",
    dataIndex: "orders[0].requiredDate" as any,
    width: 190,
    render: (cellData, record) => {
      return `${toClientDate(cellData)}`;
    },
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    width: 250,
    render: (cellData, record) => {
      return StatusUIUtil.renderCircleStatusNew(null, record?.status, record?.subStatus);
    },
  },
];

const InfoLabel = styled((props) => (
  <GFTypography {...props} variant="body2" color="text.secondary" />
))({
  display: "block",
  marginBottom: "8px",
});

type OrderCombinationProps = {
  onClose: () => void;
};

export default function OrderCombination({ onClose }: OrderCombinationProps) {
  const [search, setSearch] = useState("");

  const orderId = useParams<{ orderId: string }>()?.orderId;
  const [selectedRecord, setSelectedRecord] = useState<any>([]);
  const { isLoading, orderDetail } = useOrderDetail(Number(orderId));
  const { orderCombinations } = useGetOrderCombination(Number(orderId));

  const filteredOrderCombinations = orderCombinations.filter((item: any) => {
    return (
      item.code?.toLowerCase().includes(search.toLowerCase()) ||
      item.orders[0].orderlines[0].itemName?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const { mutateAsync, isLoading: updateLoading } = useUpdateOrderCombination();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateOrderCombination = async () => {
    if (selectedRecord.length === 0) {
      enqueueSnackbar("Vui lòng chọn đơn hàng để ghép", { variant: "error" });
      return;
    }
    try {
      await mutateAsync({
        orderId: Number(orderId),
        data: selectedRecord.map((item: any) => item.id),
      });
      enqueueSnackbar("Ghép đơn hàng thành công", { variant: "success" });
      setSelectedRecord([]);
      onClose();
    } catch (error) {
      enqueueSnackbar("Có lỗi xảy ra", { variant: "error" });
    }
  };

  const handleRemoveSelectedById = (id: number) => {
    setSelectedRecord((prevState: any) => _.filter(prevState, (item) => item.id !== id));
  };

  return (
    <GFPageDialog
      title="Ghép đơn hàng"
      loading={isLoading || updateLoading}
      onConfirm={handleUpdateOrderCombination}
      onCancel={onClose}>
      <GFTypography mb={2} variant="body1">
        Thông tin đơn hàng - #{_.get(orderDetail, "code")}
      </GFTypography>
      <ItemSection sx={{ background: "#fff", boxShadow: "none" }}>
        <Grid container spacing={2} sx={{ paddingBlock: 0, marginBottom: "1.6rem" }}>
          <Grid item xs={3}>
            <GFCustomerAvatar
              code={_.get(orderDetail, "orders[0].customer.code")}
              fullName={_.get(orderDetail, "orders[0].customer.fullName")}
              avatarUrl={_.get(orderDetail, "orders[0].customer.account.avatar")}
              phoneNumber={_.get(orderDetail, "orders[0].customer.phoneNumber")}
              dvkd={_.get(orderDetail, "orders[0].salesOrg.description")}
              type="primary"
            />
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Thời gian đặt hàng</InfoLabel>
            <GFTypography>{toClientDateTime(_.get(orderDetail, "createdAt"))}</GFTypography>
          </Grid>
          <Grid item xs={3}>
            <InfoLabel>Trang trại</InfoLabel>
            <GFTypography>{_.get(orderDetail, "orders[0].camp.contactName")}</GFTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Người đặt hàng</InfoLabel>
            <GFTypography>
              {_.get(orderDetail, "createdBy.fullName")}
              {" - "}
              {_.get(orderDetail, "createdBy.account.code")}
            </GFTypography>
          </Grid>
          <Grid item xs={2}>
            <InfoLabel>Trạng thái</InfoLabel>
            <GFTypography>
              <div>
                {StatusUIUtil.renderCircleStatusNew(
                  null,
                  orderDetail?.status,
                  orderDetail?.subStatus
                )}
              </div>
            </GFTypography>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          {orderDetail?.orders?.map((order: any, index: number) => (
            <OrderDetailItem order={order} />
          ))}
        </Box>
      </ItemSection>
      {!isEmpty(orderDetail?.combinedOrderGroups) && (
        <>
          <GFTypography variant="body1">
            Đơn đã ghép
          </GFTypography>
          <ItemSection
            sx={{
              background: "#fff",
              "& .gf-datatable__body__item": {
                background: "#f5f5f5",
              },
            }}>
            <GFDataTable
              columns={columns}
              dataSource={orderDetail.combinedOrderGroups}
              showExcelButton={false}
              showDateRange={false}
              showSearchSection={false}
              height="300px"
            />
          </ItemSection>
        </>
      )}

      <GFTypography sx={{ mb: "14px" }} variant="body1">
        Chọn đơn ghép
      </GFTypography>
      <GFDataTable
        columns={columns}
        dataSource={filteredOrderCombinations}
        showExcelButton={false}
        showDateRange={false}
        onSearch={(value) => setSearch(value)}
        searchPlaceholder="Nhập mã đơn hàng, tên sản phẩm,..."
        bellowFilterContent={
          <SelectedOrder data={selectedRecord} onRemoveSelectedById={handleRemoveSelectedById} />
        }
        showCheckboxConfigs={{
          showCheckbox: () => true,
          selectedRecord,
          onSelectedChange: (records) => setSelectedRecord(records),
        }}
        expandable={{
          expandedRowRender: (record) => {
            return <OrderAccordionDetails order={record} />;
          },
          rowExpandable: (record) => record?.orders?.length > 0,
        }}
      />
    </GFPageDialog>
  );
}
