import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Checkbox, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { CustomerModel, SourceType } from "@Models";
import { CustomerService, EmployeeService } from "@Services";
import { FormUtil } from "@Utils";
import ModalFooter from "@Components/ModalFooter";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { TEXT_HOLDER } from "@Constants";
import "./CreateEmployee.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  closeForm: () => void;
  dataSource?: any;
};

const CreateEmployee: React.FC<P_Props> = ({ closeForm, dataSource }) => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [requireOTP, setRequireOTP] = useState<boolean>(false);
  const [selectedEmployeeFeed, setSelectedEmployeeFeed] = useState<CustomerModel | null>(
    dataSource?.source === SourceType.FEED ? dataSource : null
  );
  const [selectedEmployeeFarm, setSelectedEmployeeFarm] = useState<CustomerModel | null>(
    dataSource?.source === SourceType.FARM ? dataSource : null
  );
  const [selectedEmployeeVet, setSelectedEmployeeVet] = useState<CustomerModel | null>(
    dataSource?.source === SourceType.VET ? dataSource : null
  );
  const [selectedEmployeeLab, setSelectedEmployeeLab] = useState<CustomerModel | null>(
    dataSource?.source === SourceType.LAB ? dataSource : null
  );
  const [selectedEmployeeCcu, setSelectedEmployeeCcu] = useState<CustomerModel | null>(
    dataSource?.source === SourceType.CCU ? dataSource : null
  );
  const [employee, setEmployee] = useState<
    { item: CustomerModel; userId: string; source: string }[]
  >([]);

  const [isActiveSource, setIsActiveSource] = useState<{
    feed: boolean;
    farm: boolean;
    vet: boolean;
    lab: boolean;
    ccu: boolean;
  }>({
    feed: false,
    farm: false,
    vet: false,
    lab: false,
    ccu: false,
  });

  const onUpdateCustomer = (
    variantId: number,
    userId: string,
    source: string,
    addedEmployee?: CustomerModel
  ) => {
    const idx = employee.findIndex((item) => item.item.id === variantId);
    let data = [...employee];
    if (idx !== -1) {
      // update

      data[idx] = {
        ...data[idx],
        userId: userId,
        source: source,
      };
    } else {
      // thêm mới
      if (!addedEmployee) {
        return;
      }

      data.push({ item: addedEmployee, userId, source });
    }

    setEmployee(data);
  };

  const submitForm = async (values: any) => {
    if (!dataSource) {
      if (employee.length === 0) {
        enqueueSnackbar("Chưa chọn Nhân viên!", {
          variant: "error",
        });
        return;
      }
    }
    const validEmployee = employee.filter((item) => item.source);
    const usersOld = validEmployee.map((item) => {
      switch (item.item.source) {
        case SourceType.FEED:
          return {
            userId: selectedEmployeeFeed?.id,
            source: selectedEmployeeFeed?.source,
            isActive: selectedEmployeeFeed === null ? undefined : isActiveSource.feed,
          };
        case SourceType.FARM:
          return {
            userId: selectedEmployeeFarm?.id,
            source: selectedEmployeeFarm?.source,
            isActive: selectedEmployeeFarm === null ? undefined : isActiveSource.farm,
          };
        case SourceType.VET:
          return {
            userId: selectedEmployeeVet?.id,
            source: selectedEmployeeVet?.source,
            isActive: selectedEmployeeVet === null ? undefined : isActiveSource.vet,
          };
        case SourceType.LAB:
          return {
            userId: selectedEmployeeLab?.id,
            source: selectedEmployeeLab?.source,
            isActive: selectedEmployeeLab === null ? undefined : isActiveSource.lab,
          };
        default:
          return {
            userId: selectedEmployeeCcu?.id,
            source: selectedEmployeeCcu?.source,
            isActive: selectedEmployeeCcu === null ? undefined : isActiveSource.ccu,
          };
      }
    });

    const mapOldData = dataSource
      ? [
          ...usersOld,
          { userId: dataSource.id, source: dataSource.source, isActive: dataSource.isActive },
        ]
      : usersOld;

    const data: Parameters<typeof EmployeeService.createEmployeeAccount>[0] = {
      ...values,
      email: dataSource ? undefined : values.email,
      isActive: isActive,
      requireOTP: requireOTP,
      users: mapOldData,
      isEmployee: true,
      nickname: dataSource ? values.fullName : values.nickname,
    } as any;

    CustomerService.createSignUpAccount(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Tạo tài khoản thành công!", {
            variant: "success",
          });
          // history.push(
          //   APP_ROUTES.EMPLOYEE_DETAIL.replace(":employeeId", res.data?.accountId.toString())
          // );
          // closeForm();
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.message?.includes("email")) {
            enqueueSnackbar("Email đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber")) {
            enqueueSnackbar("Số điện thoại đã tồn tại!", {
              variant: "error",
            });
          } else if (
            error.response.data.message === `Key (code)=(${values.code}) already exists.`
          ) {
            enqueueSnackbar("Mã đăng nhập đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber must be a string")) {
            enqueueSnackbar("Vui lòng điền SĐT", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Tạo tài khoản thất bại!", {
            variant: "error",
          });
        }
      });
  };

  const dataFarmMap = {
    nickname: selectedEmployeeFarm?.fullName,
    phoneNumber: selectedEmployeeFarm?.phoneNumber,
    code: selectedEmployeeFarm?.code,
    email: selectedEmployeeFarm?.email,
  };

  return (
    <div className="employee-modal">
      <SimpleModalHeader title={"Cấp tài khoản nhân viên"} onClose={closeForm} />
      <div className="content">
        <Form
          onSubmit={async (values) => submitForm(values)}
          initialValues={dataSource ? dataSource : dataFarmMap}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="emp-account">
                <div className="form-item">
                  <div className="form-label">Trạng thái hoạt động</div>
                  <div>
                    <DefaultSwitch checked={isActive} onChange={() => setIsActive(!isActive)} />
                    {isActive === true ? (
                      <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>Hoạt động</span>
                    ) : (
                      <span style={{ fontSize: "1.6rem", color: "#838283" }}>Vô hiệu hóa</span>
                    )}
                  </div>
                </div>
                <div className="title">Thông tin tài khoản</div>
                <div>
                  <div className="header">
                    <div className="source">Mảng truy cập</div>
                    <div className="cus">Nhân viên</div>
                    <div className="access">Cho phép truy cập</div>
                  </div>
                  <div className="table">
                    <div className="col source">
                      <div>{t("FEED")}</div>
                      <div>{t("FARM")}</div>
                      <div>{t("VET")}</div>
                      <div>{t("LAB")}</div>
                      <div>{t("CCU")}</div>
                    </div>
                    <div className="col cus">
                      {!selectedEmployeeFeed ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FEED}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm nhân viên"
                          onChange={(value) => {
                            setSelectedEmployeeFeed(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeFeed.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFeed.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeFeed.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFeed.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeFeed(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedEmployeeFarm ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FARM}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm nhân viên"
                          onChange={(value) => {
                            setSelectedEmployeeFarm(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeFarm.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFarm.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeFarm.phoneNumber} - {selectedEmployeeFarm.email}
                          </div>
                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeFarm(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedEmployeeVet ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.VET}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm nhân viên"
                          onChange={(value) => {
                            setSelectedEmployeeVet(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeVet.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeVet.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeVet.phoneNumber} - {selectedEmployeeVet.email}
                          </div>
                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeVet(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedEmployeeLab ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.LAB}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm nhân viên"
                          onChange={(value) => {
                            setSelectedEmployeeLab(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeLab.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeLab.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeLab.phoneNumber} - {selectedEmployeeLab.email}
                          </div>
                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeLab(null);
                            }}
                          />
                        </div>
                      )}
                      {!selectedEmployeeCcu ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.CCU}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm nhân viên"
                          onChange={(value) => {
                            setSelectedEmployeeCcu(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeCcu.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeCcu.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeCcu.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedEmployeeCcu.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeCcu(null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col access">
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.feed}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedEmployeeFeed === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: !isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.farm}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedEmployeeFarm === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: !isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.vet}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedEmployeeFarm === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: !isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.lab}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedEmployeeFarm === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: !isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.ccu}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color: selectedEmployeeCcu === null ? "#efefef" : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: !isActiveSource.ccu,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="title">{t("LOGIN_NAME")}</div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "100%", marginRight: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">{t("DISPLAY_NAME")}</div>
                      <Field
                        name={dataSource ? "fullName" : "nickname"}
                        validate={FormUtil.Rule.required("Nhập tên đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập tên đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_PHONE")}</div>
                      <Field
                        name="phoneNumber"
                        validate={
                          requireOTP === true
                            ? FormUtil.Rule.required(
                                t("INPUT_BY_NAME", { NAME: t("LOGIN_PHONE").toLowerCase() })
                              )
                            : undefined
                        }>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_PHONE").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="otp">
                        <Checkbox
                          className="app-check-box"
                          checked={requireOTP}
                          onChange={() => setRequireOTP(!requireOTP)}
                        />
                        <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">Mã đăng nhập</div>
                      <Field name="code" validate={FormUtil.Rule.required("Nhập mã đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập mã đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_EMAIL")}</div>
                      <Field
                        name="email"
                        // validate={FormUtil.Rule.required("Nhập Email đăng nhập")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_EMAIL").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <ModalFooter
        formId="emp-account"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={"Cấp tài khoản và mật khẩu"}
      />
    </div>
  );
};

export default CreateEmployee;
