import { CUSTOMER_MANAGE_STATUS, CUSTOMER_MANAGE_REQUEST_TYPE } from "@Constants";
import { GFDataTableFilterType, SelectOption, StatusColor } from "src/types";

export const statusOptions: SelectOption[] = [
  {
    label: "customerManage.draft",
    value: CUSTOMER_MANAGE_STATUS.DRAFT,
  },
  {
    label: "customerManage.submit",
    value: CUSTOMER_MANAGE_STATUS.SUBMIT,
  },
  {
    label: "customerManage.approved",
    value: CUSTOMER_MANAGE_STATUS.APPROVED,
  },
  {
    label: "customerManage.confirmed",
    value: CUSTOMER_MANAGE_STATUS.CONFIRMED,
  },
  {
    label: "customerManage.sync",
    value: CUSTOMER_MANAGE_STATUS.SYNC,
  },
  {
    label: "customerManage.rejected",
    value: CUSTOMER_MANAGE_STATUS.REJECTED,
  },
];

export const statusColors: StatusColor[] = [
    {
      key: CUSTOMER_MANAGE_STATUS.DRAFT,
      color: 'var(--draft-status-color)'
    },
    {
      key: CUSTOMER_MANAGE_STATUS.SUBMIT,
      color: 'var(--submit-status-color)'
    },
    {
      key: CUSTOMER_MANAGE_STATUS.APPROVED,
      color: 'var(--approve-status-color)'
    },
    {
      key: CUSTOMER_MANAGE_STATUS.CONFIRMED,
      color: 'var(--confirm-status-color)'
    },
    {
      key: CUSTOMER_MANAGE_STATUS.SYNC,
      color: 'var(--sync-status-color)'
    },
    {
      key: CUSTOMER_MANAGE_STATUS.REJECTED,
      color: 'var(--reject-color)'
    },
  ];


export const requestTypeOptions: SelectOption[] = [
  {
    label: "customerManage.create",
    value: CUSTOMER_MANAGE_REQUEST_TYPE.CREATE,
  },
  {
    label: "customerManage.update",
    value: CUSTOMER_MANAGE_REQUEST_TYPE.UPDATE,
  },
  {
    label: "customerManage.createAddress",
    value: CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS,
  },
];

export const customerManageFilters: GFDataTableFilterType[] = [
  {
    label: 'customerManage.all',
    value: CUSTOMER_MANAGE_STATUS.ALL,
    color: 'var(--primary-green)'
  },
  {
    label: 'customerManage.draft',
    value: CUSTOMER_MANAGE_STATUS.DRAFT,
    color: '#777777'
  },
  {
    label: 'customerManage.submit',
    value: CUSTOMER_MANAGE_STATUS.SUBMIT,
    color: 'var(--submit-status-color)'
  },
  {
    label: 'customerManage.approved',
    value: CUSTOMER_MANAGE_STATUS.APPROVED,
    color: 'var(--approve-status-color)'
  },
  {
    label: 'customerManage.confirmed',
    value: CUSTOMER_MANAGE_STATUS.CONFIRMED,
    color: 'var(--confirm-status-color)'
  },
  {
    label: 'customerManage.sync',
    value: CUSTOMER_MANAGE_STATUS.SYNC,
    color: 'var(--sync-status-color)'
  },
  {
    label: 'customerManage.rejected',
    value: CUSTOMER_MANAGE_STATUS.REJECTED,
    color: 'var(--reject-color)'
  }
]


export const getStatusLabel = (t: any, value: number | string) => {
  const option = statusOptions.find((item) => item.value === value);
  return option ? `${'●'} ${t(option.label)}` : "";
}

export const toCustomerManageStatus = (value?: string) => {
  const option = statusOptions.find((item) => item.value === value);
  return option && option.label
}

export const getRequestTypeLabel = (value: number) => {
  const option = requestTypeOptions.find((item) => item.value === value);
  return option ? option.label : "";
}

export const getStatusColor = (value: number) => {
    const matchedStatus = statusColors.find(item => item.key === value)
    return matchedStatus ? matchedStatus.color : "";
}
