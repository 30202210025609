import React from "react";
import ShippingAddress from "@Components/ShippingAddress";
import { AddressModal, CustomerOutputModel, SourceType } from "@Models";
import { CustomerService } from "@Services";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { isFunction } from "lodash";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const GFAddressSelectWrapper = styled(Box)({
  "& .MuiInputBase-root": {
    height: "3.6rem !important",
    padding: "0 !important",
    "&.Mui-focused": {
      "& fieldset": {
        borderColor: "var(--primary-green) !important",
      },
    },
  },
});

type GFAddressSelectProps = {
  customer: CustomerOutputModel | null;
  value?: string;
  onChange?: (value: string) => void;
  error?: any;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  sourceType?: SourceType;
};

export default function GFAddressSelect({
  customer,
  value,
  error,
  label,
  required,
  disabled = false,
  onChange,
  sourceType = SourceType.FARM,
}: GFAddressSelectProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const saveAddress = async (val: string) => {
    if (!customer) return;

    await CustomerService.createAddress({
      customerId: customer.id as any,
      name: customer.fullName,
      phoneNumber: customer.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("order:ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  const handleChange = (value: any) => {
    if (isFunction(onChange)) {
      onChange(value?.address || value?.inputValue);
    }
  };

  return (
    <GFAddressSelectWrapper>
      <ShippingAddress<AddressModal>
        customerId={customer?.id as any}
        variant="outlined"
        label={label}
        required={required}
        handleSaveAddress={saveAddress}
        className={"form-text-field addr"}
        placeholder={t("SELECT_BY_NAME", {
          NAME: t("order:DELIVERY_ADDRESS"),
        })}
        getOptionLabel={(opt: any) => opt.address || (opt.inputValue as any)}
        onChange={handleChange}
        disabled={disabled}
        sourceType={sourceType}
        renderOption={(p: any) => (
          <div
            className="item-addr"
            style={{
              fontSize: "1.6rem",
              fontFamily: "SVN-Gotham",
              color: p.title ? "#41AD49" : "",
              borderBottom: "0.1rem solid #EFEFEF",
              padding: "1rem 2rem",
              width: "100%",
            }}>
            {p.title ? p.title : p.address}
          </div>
        )}
        actionConfig={{
          action: CustomerService.getAddress,
        }}
        dropdownBoxMaxHeight="24rem"
        error={error}
      />
    </GFAddressSelectWrapper>
  );
}
