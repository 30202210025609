/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import ProductPig from "@Assets/images/mock/pig.png";
import { DetachIcon } from "@Components/Icons/DetachIcon";
import { EventIcon } from "@Components/Icons/EventIcon";
import { MapIcon } from "@Components/Icons/MapIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import Value from "@Components/Value";
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { AddressModal, ItemPermission } from "@Models";
import { CustomerService, OrderService } from "@Services";
import { checkLinkImage, checkQuan, currencyFormat, PermissionUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  DeleteOutline as DeleteOutlineIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Backdrop, Divider, InputAdornment, TextField } from "@mui/material";
import { vi } from "date-fns/locale";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import "./DetachOrder.scss";
import { isArray } from "lodash";
import NotificationOrder from "@Components/NotificationOrder";

type P_Props = {
  closeForm: () => void;
  orderGroupId: number;
  orderId: number;
  orderLines: any;
  createBy: any;
  remainQuantity: any;
  remainDimention: any;
  // childOrders: any;
  checkLead: boolean;
  address: any;
  note: any;
  requiredDate: any;
  childOrders: any
};

const DetachOrder: React.FC<P_Props> = ({
  closeForm,
  orderGroupId,
  orderId,
  orderLines,
  createBy,
  // childOrders,
  remainQuantity,
  remainDimention,
  checkLead,
  address,
  note,
  requiredDate,
  childOrders,
}) => {
  const { t } = useTranslation(["translation", "order"]);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);


  const [itemDetach, setItemDetach] = useState<
    {
      item: any;
      quantity: number;
      dimension: number;
      orderId: number;
    }[]
  >([]);

  const [openNotification, setOpenNotification] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const remainChildOrders: any = [];
  remainChildOrders.push({
    item: Object.assign(
      { ...orderLines },
      {
        addressDetach: address || "",
        noteDetach: note || "",
        requireDateDetach: new Date(requiredDate),
      }
    ),
    quantity: remainQuantity <= 0 ? 1 : remainQuantity,
    dimension: remainDimention,
  });

  const childOrdersOld = childOrders.map((child: any, idx: number) => {
    for (let index = 0; index < child.orderlines.length; index++) {
      const childOrderlines = child.orderlines[index];

      return {
        item: Object.assign(
          { ...childOrderlines },
          {
            addressDetach: child.address || "",
            noteDetach: child.note || "",
            requireDateDetach: child.requiredDate || new Date(),
          }
        ),
        quantity: +childOrderlines.orderQuantity,
        dimension: +childOrderlines.dimension,
        orderId: child.id,
      };
    }
  });
  console.log(childOrders, "childOrders");

  useEffect(() => {
    childOrders.length > 0
      ? setItemDetach([...childOrdersOld])
      :
      setItemDetach([...remainChildOrders]);
  }, []);

  // const calcQuantity = () => {
  //   const sumQuantity = itemDetach.reduce((sum: any, val: any) => sum + val.item.quantity, 0);

  //   if (+orderLines.orderQuantity - sumQuantity <= 0) return 1;
  //   return +orderLines.orderQuantity - sumQuantity;
  // };

  const onDetachOrder = (
    orderlinesDetach: any,
    numId: number,
    quanti: number,
    dimension: number
  ) => {
    const idx = itemDetach.findIndex((item) => item.item.item.id !== orderlinesDetach.item.id);
    let data = [...itemDetach];

    if (idx !== -1) {
      data[idx] = {
        ...data[idx],
        quantity: quanti,
        dimension: dimension,
      };
    } else {
      // thêm mới
      data.push({
        item: Object.assign(
          { ...orderlinesDetach },
          {
            addressDetach: address || "",
            noteDetach: "",
            requireDateDetach: new Date(requiredDate),
          }
        ),
        // quantity: calcQuantity(),
        quantity: 1,
        // dimension: calcQuantity() * (+orderLines.dimension / +orderLines.orderQuantity),
        dimension: +orderLines.dimension / +orderLines.orderQuantity,
        orderId: 0,
      });
    }

    data = data.filter((item) => item.quantity >= 1);

    setItemDetach(data);
  };

  const onUpdateDetachOrder = (
    orderIndex: number,
    fieldName: "dimension" | "quantity" | "requireDateDetach" | "noteDetach" | "addressDetach",
    value: any
  ) => {
    console.log(orderIndex);
    let data = [...itemDetach];

    data[orderIndex].item[fieldName] = value;
    if (fieldName === "quantity") {
      data[orderIndex].item["dimension"] =
        data[orderIndex].item["quantity"] * (+orderLines.dimension / +orderLines.orderQuantity);
    }

    setItemDetach(data);
  };

  console.log(itemDetach);

  const submitForm = async (reason: any) => {

    if (openNotification === true) {
      if (reason === "") {
        enqueueSnackbar(t("order:PLEASE_ENTER_REASON"), {
          preventDuplicate: true,
          variant: "error",
        });
        return;
      }
    }

    setLoading(true);
    const item = itemDetach.map((i) => {
      const valDimen = Array.isArray(i.item.item.dimension)
        ? +orderLines.dimension / +orderLines.orderQuantity
        : +i.item.item.dimension;
      // const itemProduct = { ...i.item.item };
      // const unitPrice = Number(i.item?.price);
      // const grossWeight = Number(!itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0 ? valDimen : itemProduct?.grossWeight)
      // const additionValue = Number(i.item?.attachValue)
      // const valPriceDimen = +i.item.prices.dimension;

      // let subTotal = 0;
      // switch (i.item.prices?.priceType) {
      //   case "bs":
      //     subTotal = i.item.quantity * unitPrice * grossWeight
      //     break;
      //   case "fixed":
      //     subTotal = (i.item.quantity * unitPrice * grossWeight) + (i.item.quantity * Number(additionValue))
      //     break;
      //   case "increment":
      //     subTotal = i.item.quantity * unitPrice + i.item.quantity * Number(additionValue) * (grossWeight - Number(itemProduct?.netWeight))
      //     break;
      //   default:
      // }

      return {
        orderId: i.orderId ? i.orderId : undefined,
        quantity: i.item.quantity,
        dimension: i.item.dimension,
        subTotal: 0,
        note: reason.length ? reason + i.item.noteDetach : i.item.noteDetach,
        address: i.item.addressDetach,
        requiredDate: moment(i.item.requireDateDetach).format("YYYY-MM-DD"),
      };
    });


    const data: Parameters<typeof OrderService.detachOrder>[0] = {
      orderGroupId: orderGroupId,
      orderId: orderId,
      orderlines: item
    } as any;


    await OrderService.detachOrder(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setLoading(false);
          enqueueSnackbar(t("order:SPLIT_ORDER_SUCCESSFUL"), {
            variant: "success",
          });
          // history.push(APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", orderGroupId.toString()));
          window.location.reload();
        } else {
          // do something
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data.message === "ORDER_CANT_UPDATE") {
          enqueueSnackbar(t("order:ORDER_STATUS_CHANGED"), {
            variant: "error",
            preventDuplicate: true,
          });
        } else {
          enqueueSnackbar(t("order:SPLIT_ORDER_FAILED"), {
            variant: "error",
            preventDuplicate: true,
          });
        }
      });
  };

  const setDefaultImageFarm = (e: any) => {
    e.target.src = ProductPig;
  };

  const saveAddress = async (val: string) => {
    await CustomerService.createAddress({
      customerId: createBy.id,
      name: createBy.fullName,
      phoneNumber: createBy.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("order:ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:ADDRESS_CREATION_FAILED"), {
          variant: "error",
          preventDuplicate: true,
        });
      });
  };
  // const totalDetachQuantity =
  //   +orderLines.orderQuantity -
  //   remainQuantity +
  //   itemDetach.reduce((sum: any, item: any) => {
  //     return (sum += +item.item.quantity);
  //   }, 0);

  const totalDetachFunction = () => {
    let clone = +orderLines.orderQuantity -
      remainQuantity +
      itemDetach.reduce((sum: any, item: any) => {
        return (sum += +item.item.quantity);
      }, 0);
  }

  const getDimensionOfOrder = (dimensionToShow: string, listDimension: any) => {
    if (isArray(listDimension)) {
      const listDimensionToShow = listDimension.filter((item, index) => {
        if (item.includes(String(dimensionToShow))) {
          return true;
        }
        else {
          return false;
        }
      })
      let dimensionList = [...listDimensionToShow[0]];
      if (dimensionList[1] === "null") {
        return "trên " + dimensionList[0];
      } else if (dimensionList[0] === "null") {
        return "dưới " + dimensionList[1];
      } else {
        return dimensionList[0] + " - " + dimensionList[1];
      }
    }
    else {
      return `${listDimension}`;
    }

  }

  totalDetachFunction()

  return (
    <div className="detach-modal">
      <SimpleModalHeader title={t("order:SPLIT_ORDER")} onClose={closeForm} />

      <div className="content">
        <Form
          onSubmit={async () => {
            // itemDetach.length > 0 && submitForm();
          }}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="detach">
                <div className="title">{t("order:ORDER_PLACED")}</div>
                <div className="list">
                  <div className="header">
                    <div className="name">Sản phẩm</div>
                    <div className="quan">{t("QUANTITY")}</div>
                    <div className="dimension">Khung trọng lượng</div>
                    <div className="weight">{t("TOTAL_WEIGHT")}</div>
                  </div>
                  <div className="item">
                    <div className="name">
                      <img
                        src={checkLinkImage(orderLines.item.images?.picture) || ProductPig}
                        alt={orderLines.item.shortName}
                        onError={setDefaultImageFarm}
                      />
                      <div>
                        {orderLines.item.name}
                        {/* &ensp;&#8226;&ensp;
                        {+orderLines.dimension / +orderLines.orderQuantity}&ensp;
                        {orderLines.baseUoM} */}
                        <div className="code">
                          {orderLines.item.code}
                          <div style={{ color: "#5A5959" }}>
                            {t("order:QUANTITY_ALLOCATED")}:
                            {+orderLines.orderQuantity - remainQuantity}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="quan">{+orderLines.orderQuantity}</div>
                    <div className="dimension">
                      {getDimensionOfOrder(orderLines.dimensionToShow, orderLines.item.dimension)}
                    </div>
                    <div className="weight">
                      {orderLines.dimension}&ensp;kg
                      {/* {orderLines.baseUoM} */}
                    </div>
                  </div>
                </div>
                <div className="title">{t("order:SPLIT_ORDER")}</div>
                <div className="detached">
                  {itemDetach.map((d, index) => {
                    return (
                      <div className="item-dt" key={index}>
                        <div className="item item-detached">
                          <div className="name">
                            <div className="num">{index + 1}</div>
                            <div>
                              {d.item.item.name}
                              {/* &ensp;&#8226;&ensp;
                                {+orderLines.dimension / +orderLines.orderQuantity}&ensp;
                                {orderLines.baseUoM} */}
                              <div className="code">{orderLines.item.code}</div>
                            </div>
                          </div>
                          <div className="quan">
                            <span>
                              <Value
                                dimension={d.quantity}
                                onChangeQuantity={(value) => {
                                  onUpdateDetachOrder(index, "quantity", value);
                                }}
                                style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                              />
                              {/* {d.item.saleUnit} */}
                            </span>
                          </div>
                          <div className="dimension">
                            <span>
                              <Value
                                dimension={getDimensionOfOrder(orderLines.dimensionToShow, orderLines.item.dimension)}
                                onChangeQuantity={() => { }}
                                isDisable={true}
                                style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                              />
                              &ensp;kg
                            </span>
                          </div>
                          <div className="weight">
                            <span>
                              <Value
                                dimension={
                                  d.dimension ===
                                    d.item.quantity *
                                    (+orderLines.dimension / +orderLines.orderQuantity)
                                    ? d.dimension
                                    : d.item.dimension
                                }
                                onChangeQuantity={(value) => {
                                  onUpdateDetachOrder(index, "dimension", value);
                                }}
                                style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                isDisable={true}
                              />
                              &ensp;kg
                              {/* {d.item.baseUoM} */}
                            </span>
                          </div>
                          <div
                            className="ic-del"
                            onClick={() => {
                              setItemDetach(itemDetach.filter((_, idx) => idx !== index));
                            }}>
                            <DeleteOutlineIcon
                              style={{
                                fontSize: "2.2rem",
                                marginLeft: "5rem",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                        <div className="shipping-detached">
                          <div className="date">
                            <Field name="requiredDate">
                              {({ input, meta, ...rest }) => {
                                return (
                                  <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                      okLabel={t("order:SELECT")}
                                      cancelLabel={t("CANCEL")}
                                      fullWidth
                                      disableToolbar
                                      disablePast
                                      inputVariant="standard"
                                      format="dd-MM-yyyy"
                                      className="form-text-field date"
                                      value={d.item.requireDateDetach}
                                      onChange={(e) => {
                                        onUpdateDetachOrder(
                                          index,
                                          "requireDateDetach",
                                          e?.toISOString()
                                        );
                                      }}
                                      InputProps={{ readOnly: true }}
                                      inputProps={{ className: "input" }}
                                      keyboardIcon={
                                        <EventIcon
                                          color="#41AD49"
                                          size={[22, 22]}
                                          viewBox={[22, 22]}
                                        />
                                      }
                                      helperText={meta.touched ? meta.error : ""}
                                      error={meta.error && meta.touched}
                                    />
                                  </MuiPickersUtilsProvider>
                                );
                              }}
                            </Field>
                          </div>
                          <div className="note">
                            <Field name="note">
                              {({ input, meta, ...rest }) => {
                                return (
                                  <TextField
                                    {...input}
                                    {...rest}
                                    color="success"
                                    variant="standard"
                                    value={d.item.noteDetach}
                                    onChange={(value) => {
                                      onUpdateDetachOrder(index, "noteDetach", value.target.value);
                                    }}
                                    placeholder={t("NOTES")}
                                    className={"note-text"}
                                    inputProps={{ className: "input" }}
                                    helperText={meta.touched ? meta.error : ""}
                                    error={meta.error && meta.touched}
                                  />
                                );
                              }}
                            </Field>
                          </div>

                          <Field name="address">
                            {({ input, meta, ...rest }) => {
                              return (
                                <>
                                  {checkLead === true ? (
                                    <TextField
                                      {...input}
                                      {...rest}
                                      fullWidth
                                      placeholder={t("INPUT_BY_NAME", {
                                        NAME: t("order:DELIVERY_ADDRESS"),
                                      })}
                                      className="form-text-field form-text-field-addr"
                                      inputProps={{ className: "input" }}
                                      variant="standard"
                                      value={d.item.addressDetach}
                                      onChange={(e) => {
                                        input.onChange(e.target.value);
                                        onUpdateDetachOrder(index, "addressDetach", e.target.value);
                                      }}
                                      helperText={meta.touched ? meta.error : ""}
                                      error={meta.error && meta.touched}
                                      onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                          event.preventDefault();
                                          return false;
                                        }
                                      }}
                                      autoComplete="off"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <MapIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[10, 20]}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <ShippingAddress<AddressModal>
                                      value={d.item.addressDetach}
                                      customerId={createBy.id}
                                      handleSaveAddress={saveAddress}
                                      className={"form-text-field addr"}
                                      placeholder={t("SELECT_BY_NAME", {
                                        NAME: t("order:DELIVERY_ADDRESS"),
                                      })}
                                      getOptionLabel={(opt) =>
                                        typeof opt === "string"
                                          ? opt
                                          : opt.address || (opt.inputValue as any)
                                      }
                                      onChange={(value) => {
                                        onUpdateDetachOrder(
                                          index,
                                          "addressDetach",
                                          value?.address || value?.inputValue
                                        );
                                      }}
                                      renderOption={(p) => (
                                        <div
                                          className="item-addr"
                                          style={{
                                            fontSize: "1.6rem",
                                            fontFamily: "SVN-Gotham",
                                            color: p.title ? "#41AD49" : "",
                                            borderBottom: "0.1rem solid #EFEFEF",
                                            padding: "1rem 2rem",
                                            width: "100%",
                                          }}>
                                          {p.title ? p.title : p.address}
                                        </div>
                                      )}
                                      actionConfig={{
                                        action: CustomerService.getAddress,
                                      }}
                                      dropdownBoxMaxHeight="24rem"
                                    />
                                  )}
                                </>
                              );
                            }}
                          </Field>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Divider style={{ margin: "3.6rem 0 1.9rem" }} />
                <div
                  style={{
                    color: "#41AD49",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.8rem",
                    margin: "1.8rem 3.5rem",
                    cursor: "pointer",
                    width: "20rem",
                    position: "absolute",
                    bottom: "60px",
                  }}
                  onClick={() => {
                    onDetachOrder(orderLines, itemDetach.length, 1, 1);
                  }}>
                  <DetachIcon color="#41AD49" size={[16, 16]} viewBox={[15, 9]} />
                  {t("order:SPLIT_ONE_MORE_ORDER")}
                </div>
                <div className={itemDetach.length ? "show" : "hide"}>
                  <div className="detach-footer">
                    <button type={"submit"}
                      disabled={itemDetach.length ? false : true}
                      className="btn-cancel-style" onClick={closeForm}>
                      {t("CANCEL")}
                    </button>
                    <button
                      type={"submit"}
                      className="btn-confirm-style"
                      style={{ marginLeft: "2.4rem" }}
                      disabled={itemDetach.length ? false : true}
                      onClick={() => {
                        let totalQuantity = itemDetach.reduce((sum: any, val: any) => sum + val.item.quantity, 0);
                        if (checkQuan(+orderLines.orderQuantity, totalQuantity)) {
                          if (totalQuantity > +orderLines.orderQuantity) {
                            setOpenNotification(true);
                          }
                          else if (totalQuantity !== +orderLines.orderQuantity) {
                            enqueueSnackbar("Tổng số lượng các đơn tách phải bằng hoặc không vượt quá 10% số lượng của đơn tổng", {
                              variant: "error",
                              preventDuplicate: true,
                            });
                          }
                          else {
                            submitForm("");
                          }
                        } else {
                          enqueueSnackbar(t("order:QUANTITY_SPLIT_ORDER_EXCEEDS_PERCENT"), {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        }
                      }}>
                      Tách đơn
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <NotificationOrder
          visible={openNotification}
          title={t("order:EXCEEDING_ORDER_QUANTITY")}
          subTitle={t("order:ARE_YOU_SURE_YOU_WANT_SPLIT_ORDER")}
          icon={
            <div className={"notification-icon-box yellow"}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          onTextChange={(text) => setReason(text.target.value)}
          isCoorinate
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                onClick={() => setOpenNotification(false)}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                onClick={() => {
                  submitForm(reason);
                  reason !== "" && setOpenNotification(false);
                }}>
                {t("order:SPLIT_ORDER")}
              </button>
            </>
          }
          onClose={() => setOpenNotification(false)}
        />
      </div>
    </div >
  );
};

export default DetachOrder;
