import { useTranslation } from "react-i18next";
import { Filter, PigTypeCode } from "src/types";

export const priceFarmFilters: Filter[] = [
  {
    label: "BREEDING",
    code: PigTypeCode.Breeding,
  },
  {
    label: "PORK",
    code: PigTypeCode.Fork,
  },
  {
    label: "CULLED",
    code: PigTypeCode.Culled,
  },
];

const getFilters = (profile: any) => {
  const { itemGroupTypeFarm } = profile || {};

  const filterMapped = priceFarmFilters.map((item: Filter) => {
    item.value = itemGroupTypeFarm?.find((x: any) => x.code === item.code)?.id;
    return item;
  });

  return [
    {
      label: "ALL",
      code: PigTypeCode.ALL,
    },
    ...filterMapped,
  ];
};

type PriceFarmFilterProps = {
  activeId?: number;
  onChange: (value: number) => void;
  count: any;
  profile: any;
  isFetching?: boolean;
};

export default function PriceFarmFilter({
  activeId,
  count,
  profile,
  isFetching,
  onChange,
}: PriceFarmFilterProps) {
  const { t } = useTranslation(["translation"]);

  const filters = getFilters(profile);

  const handleChange = (id: any) => {
    if (isFetching) return;
    onChange(id);
  };

  return (
    <div className="flex">
      {filters.map((filter, index) => (
        <div className="marketing-employee__nav-header" onClick={() => handleChange(filter.value)}>
          <div
            className={activeId === filter.value ? "tab tab-active btn--shiny" : `tab tab-hover`}>
            {t(`translation:${filter.label}`)} <span>{count?.[filter.code!] || 0}</span>
          </div>
        </div>
      ))}
    </div>
  );
}
