import { REQUEST_URL } from "@Constants";
import { PaginationModel } from "@Models";
import { RequestUtil } from "@Utils";

export const ReportService = {
  getAllReport: (
    data: PaginationModel & {
      source: string;
      searchString?: string;
      period: string;
      employeeIds?: number[];
    }
  ) => {
    const url = REQUEST_URL.GET_ALL_REPORT;

    return RequestUtil.put({ url, data: data });
  },

  getReportTopCustomer(data: { period: string; source: string; salesOrgId?: number }) {
    const url = REQUEST_URL.REPORT_TOP_CUSTOMER;

    return RequestUtil.get({ url, query: data });
  },

  getReportSales(data: { period: string; source: string; salesOrgId?: number }) {
    const url = REQUEST_URL.REPORT_SALES;

    return RequestUtil.get({ url, query: data });
  },

  getReportItem(data: { period: string; source: string; salesOrgId?: number }) {
    const url = REQUEST_URL.REPORT_ITEM_GROUP;

    return RequestUtil.get({ url, query: data });
  },

  getReportDensity(data: {
    period: string;
    source: string;
    salesOrgId?: number;
    sortType?: string;
    sortField?: string;
  }) {
    const url = REQUEST_URL.REPORT_DENSITY;

    return RequestUtil.get({ url, query: data });
  },

  getReportBu(data: { source: string }) {
    const url = REQUEST_URL.REPORT_BU;

    return RequestUtil.get({ url, query: data });
  },

  getAllFarmDebt: (
    data: PaginationModel & {
      source: string;
      searchString?: string;
      legalIds?: number[];
      buIds?: number[];
    }
  ) => {
    const url = REQUEST_URL.DEBT_FARM_REPORT;

    return RequestUtil.put({ url, data: data });
  },

  getBuFarm() {
    const url = REQUEST_URL.REPORT_BU_FARM;

    return RequestUtil.get({ url });
  },

  getLegalFarm() {
    const url = REQUEST_URL.REPORT_LEGAL_FARM;

    return RequestUtil.get({ url });
  },

  getReportAllRevenue: (
    data: PaginationModel & {
      source: string;
      period: string;
      searchString?: string;
      areaIds?: number[];
      buIds?: number[];
      siteIds?: number[];
    }
  ) => {
    const url = REQUEST_URL.REPORT_REVENUE;

    return RequestUtil.put({ url, data: data });
  },

  getArea() {
    const url = REQUEST_URL.REPORT_AREA;

    return RequestUtil.get({ url });
  },
};
