import { GFButton, GFCustomerAvatar, GFTypography } from "@Components/ui";
import GFLabelValue from "@Components/ui/GFLabelValue";
import GFPageDialog from "@Components/ui/GFPageDialog";
import { APP_ROUTES, APPROVAL_PERSON, CUSTOMER_MANAGE_DETAIL_OPTION, CUSTOMER_MANAGE_REQUEST_TYPE, CUSTOMER_MANAGE_STATUS, ItemGroupTypeEnum, TEXT_HOLDER } from "@Constants";
import useGetMe from "@Hooks/useGetMe";
import { useHistory, useParams } from "react-router-dom";
import { useCustomerManageDetail } from "src/app/queries/customer-manage.query";
import "./index.scss";
import _ from "lodash";
import { getErrorResponse, toClientDate } from "@Utils";
import { toCustomerManageStatus } from "@Containers/CustomerManage/CustomerManage.config";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CustomerManageService } from "@Services/customer-manage.service";
import { useSnackbar } from "notistack";
import { ApproveOrRejectModel } from "@Models/customer-manage.model";
import RejectReasonModal from "@Containers/CustomerManage/RejectModal";
import { GridView } from "@mui/icons-material";
import CheckDuplicateModal from "@Containers/CustomerManage/CheckDuplicate";
import { IconButton } from "@mui/material";
import GFDropdown from "@Components/ui/GFDropdown";
import { SelectOption } from "src/types";
import { ArrowDownIcon } from "@Components/Icons/ArrowDownIcon";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SAPCodeConfirmModal from "@Containers/CustomerManage/ConfirmModal";

export default function CustomerManageDetail() {
  const { t } = useTranslation("translation");
  const customerId = useParams<{ customerId: string }>().customerId;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data } = useCustomerManageDetail(Number(customerId));
  const { permissions } = useGetMe();
  const [isOpenRejectModal, setIsOpenRejectModal] = useState<boolean>(false);
  const [approval, setApproval] = useState<string>("")
  const [isOpendModalCheckDuplicate, setIsOpendModalCheckDuplicate] = useState<boolean>(false);
  const [isSAPModal, setIsSAPModal] = useState<boolean>(false);
  const handleClose = () => {
    history.push(APP_ROUTES.CUSTOMER_MANAGE);
  };

  if (!customerId) {
    return null;
  }

  if (!isLoading && !data) {
    return <div>Không tìm thấy dữ liệu</div>;
  }


  const handleApproveOrReject = async(id: number, type: APPROVAL_PERSON, data: ApproveOrRejectModel) => {
    try {
      await CustomerManageService.approveOrReject(id, type, data);
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      history.push(APP_ROUTES.CUSTOMER_MANAGE)
    }
    catch(error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  }

  const handleChanageRequest = async() => {
    if (!customerId) return
    try {
      const payload = {
        status: CUSTOMER_MANAGE_STATUS.SUBMIT
      }
      await CustomerManageService.changeStatus(Number(customerId), payload);
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      history.push(APP_ROUTES.CUSTOMER_MANAGE)
    }
    catch(error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  }

  const handleGDKDApprove = () => {
    if (!customerId) return
    handleApproveOrReject(Number(customerId), APPROVAL_PERSON.GDKD, { isAccepted: true })
  }

  const handleGDKDReject = (reason: string) => {
    if (!customerId) return
    handleApproveOrReject(Number(customerId), APPROVAL_PERSON.GDKD, { isAccepted: false, reason })
  }

  const handleKTBHReject = (reason: string) => {
    if (!customerId) return
    handleApproveOrReject(Number(customerId), APPROVAL_PERSON.KTBH, { isAccepted: false, reason })
  }

  const handleKTBHConfirm = () => {
    if (!customerId) return
    handleApproveOrReject(Number(customerId), APPROVAL_PERSON.KTBH, { isAccepted: true })
  }

  const handleGDKDClickReject = () => {
    setApproval(APPROVAL_PERSON.GDKD)
    setIsOpenRejectModal(true)
  }

  const handleKTBHClickReject = () => {
    setApproval(APPROVAL_PERSON.KTBH)
    setIsOpenRejectModal(true)
  }

  const handleCloseRejectModal = () => {
    setIsOpenRejectModal(false)
  }

  const handleKTBHClickConfirm = () => {
    setIsSAPModal(true)
  }

  const handleCloseSAPModal = () => {
    setIsSAPModal(false)
  }

  const handleConfirmSAPCode = (sapCode: string) => {
    if (!customerId) return
    handleApproveOrReject(Number(customerId), APPROVAL_PERSON.KTBH, { isAccepted: true, sapCode: sapCode })
    setIsSAPModal(false)
  }

  const handleConfirmRejectModal = (reason: string, approval: string) => {
    if (approval === APPROVAL_PERSON.GDKD) handleGDKDReject(reason);
    if (approval === APPROVAL_PERSON.KTBH) handleKTBHReject(reason);
    setIsOpenRejectModal(false)
  }

  const cancelButtons = (
    <>
      <GFButton onClick={handleClose} variant="outlined" minWidth={15}>
        {t('CANCEL')}
      </GFButton>
    </>
  )

  const actionDrafts = (
    <>
      <GFButton onClick={handleClose} variant="outlined" minWidth={15}>
        {t('CANCEL')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        onClick={handleChanageRequest}
        minWidth={15}>
        {t('customerManage.btnSendRequest')}
      </GFButton>
    </>
  )

  const actionSubmits = (
    <>
      <GFButton className="btn-reject" 
        variant="outlined" 
        minWidth={15}
        onClick={handleGDKDClickReject}
        >
        {t('customerManage.btnReject')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        onClick={handleGDKDApprove}
        minWidth={15}>
        {t('customerManage.btnApprove')}
      </GFButton>
    </>
  )

  const actionApproves = (
    <>
      <GFButton className="btn-reject" 
        variant="outlined" minWidth={15}
        onClick={handleKTBHClickReject}
        >
        {t('customerManage.btnReject')}
      </GFButton>
      <GFButton
        type="submit"
        variant="contained"
        minWidth={15}
        onClick={handleKTBHClickConfirm}
        >
        {t('customerManage.confirm')}
      </GFButton>
    </>
  )

  let finalActions;
  switch (data?.status) {
    case CUSTOMER_MANAGE_STATUS.DRAFT:
      finalActions = actionDrafts;
      break;

    case CUSTOMER_MANAGE_STATUS.SUBMIT:
      if (permissions.hasPermissionExportExcel) {
        finalActions = actionSubmits;
      }
      else {
        finalActions = cancelButtons
      }
      break;

    case CUSTOMER_MANAGE_STATUS.APPROVED:
      if (permissions.hasPermissionExportExcel) {
        finalActions = actionApproves;
      }
      else {
        finalActions = cancelButtons
      }
      break;

    default:
      finalActions = cancelButtons;
      break;
  }


  const handleCheckDuplicateModal = () => {
    setIsOpendModalCheckDuplicate(false)
  }

  const menuOptions: SelectOption[] = [{
    value: CUSTOMER_MANAGE_DETAIL_OPTION.CHECK_DUPLICATE,
    label: "Kiểm tra trùng thông tin"
  }]

  const handleClickCheckDuplicate = (value: any) => {
    if (value === CUSTOMER_MANAGE_DETAIL_OPTION.CHECK_DUPLICATE) {
    setIsOpendModalCheckDuplicate(true)
    }
  };

  return (
    <>
      {isOpenRejectModal && (
        <RejectReasonModal
          open={isOpenRejectModal}
          onClose={handleCloseRejectModal}
          onConfirmReject={handleConfirmRejectModal}
          approval={approval}
        />
      )}

      {isSAPModal && (
        <SAPCodeConfirmModal
          open={isSAPModal}
          onClose={handleCloseSAPModal}
          onConfirmSAPCode={handleConfirmSAPCode}
          requestType={CUSTOMER_MANAGE_REQUEST_TYPE.CREATE}
        />
      )}
      
      {isOpendModalCheckDuplicate && (
        <CheckDuplicateModal
          open={isOpendModalCheckDuplicate}
          onClose={handleCheckDuplicateModal}
          initialValues={data}
        />
      )}

      <GFPageDialog
        title="Chi tiết khách hàng"
        onCancel={handleClose}
        variant="default"
        loading={isLoading}
        actions={finalActions}
        headerBottom={
          data && (
            <div className="u-flex u-flex--between u-items-center u-px-12 u-bg-white u-py-4 u-border-b">
              <GFCustomerAvatar
                code={data.customerCode}
                fullName={data.fullName}
                phoneNumber={data.phoneNumber}
                fullWidth={false}
              />
              <div className="customer-status">
                <GFTypography className="!u-mb-1">Trạng thái</GFTypography>
                <GFTypography
                  variant="body2"
                  className={`u-rounded-full u-py-2 u-px-6 u-status-${data.status.toLocaleLowerCase()} u-text-white`}>
                  {t(toCustomerManageStatus(data.status) || '')}
                </GFTypography>
              </div>

              {data.status === CUSTOMER_MANAGE_STATUS.APPROVED && permissions.hasPermissionExportExcel && (
                <div className="btn-green-style customer-detail-optional-menu">
                  <GFDropdown
                    trigger={
                      <IconButton className="gf-datatable__header__icon">
                        <GridView /> <span>&nbsp;Tùy chọn&nbsp;</span><ArrowDownIcon size={[20, 15]} viewBox={[24, 24]} color="white" />
                      </IconButton>
                    }
                    onItemClick={(value) => handleClickCheckDuplicate(value)}
                    >
                    {menuOptions.map((option, index) => {
                      return (
                        <div key={index} className="customer-detail-optional-menu_option" {...option}>
                          <div className="menu-option_item">
                            <ManageSearchIcon fontSize="large" />&nbsp;<span>{t(option.label)}</span>
                          </div>
                          
                        </div>
                      );
                    })}
                  </GFDropdown>
                </div>
              )}
            </div>
          )
        }>
        {data && (
          <div className="u-grid u-grid-cols-3-1 u-gap-6">
            <div>
              <div className="u-card u-mb-6 ">
                <GFTypography className="!u-mb-7">Thông tin cá nhân</GFTypography>
                <div className="u-grid u-grid-cols-3 u-gap-6">
                  <GFLabelValue label="Ngày tháng năm sinh" value={data.dateOfBirth ? toClientDate(data.dateOfBirth) : TEXT_HOLDER} />
                  <GFLabelValue label="Số CCCD/CMND" value={data.idCard} />
                  <GFLabelValue label="Ngày cấp" value={data.idIssueDate ? toClientDate(data.idIssueDate) : TEXT_HOLDER} />
                  <GFLabelValue label="Nơi cấp" value={data.idIssuePlace} />
                  <GFLabelValue
                    label="Địa chỉ khách hàng (địa chỉ đầy đủ)"
                    value={data.address}
                  />
                  <GFLabelValue label="Tỉnh/Thành" value={_.get(data, "province.description")} />
                  <GFLabelValue label="Quận/Huyện" value={_.get(data, "district.description")} />
                  <GFLabelValue label="Email" value={data.email} />
                  <GFLabelValue label="Mã số thuế" value={data.taxId} />
                  <GFLabelValue
                    label="Địa chỉ giao hàng"
                    value={data.shippingAddress}
                  />
                </div>
              </div>
              <div className="u-card">
                <GFTypography className="!u-mb-7">Phân vùng kinh doanh</GFTypography>
                <div className="u-grid u-grid-cols-3 u-gap-6">
                  <GFLabelValue label="Nhân viên kinh doanh" value={_.get(data, "slsMan.fullName")} />
                  <GFLabelValue label="Giám sát bán hàng" value={_.get(data, "slsAst.fullName")} />
                  <GFLabelValue label="Trường phòng kinh doanh" value={_.get(data, "slsRef.fullName")} />
                  <GFLabelValue label="Giám đốc kinh doanh" value={_.get(data, "slsSup.fullName")} />
                  <GFLabelValue label="Nhóm sản phẩm" value={_.get(data, "itemGroupType.name")} />
                  <GFLabelValue label="Tổ chức bán hàng" value={_.get(data, "saleOrg.description")} />
                  <GFLabelValue 
                    label={_.get(data, "itemGroupType.code") === ItemGroupTypeEnum.BREEDING_PIG 
                        ? "Đơn vị kinh doanh heo giống" 
                        : "Đơn vị kinh doanh heo thịt"} 
                    value={_.get(data, "itemGroupType.code") === ItemGroupTypeEnum.BREEDING_PIG 
                        ? _.get(data, "saleDistrict.name")
                        : _.get(data, "salesGroup.description")} />
                  <GFLabelValue label="Kênh bán hàng" value={_.get(data, "distributionChannel.description")} />
                  <GFLabelValue label="Ngành hàng" value={_.get(data, "division.description")} />
                  <GFLabelValue label="Miền" value={_.get(data, "salesOffice.description")} />
                  <GFLabelValue label="Nhóm khách hàng" value={_.get(data, "customerGroup.description")} />
                </div>
              </div>
            </div>
            <div>
              <div className="u-card u-space-y-6 u-mb-6">
                <GFTypography>Thông tin khác</GFTypography>
                <GFLabelValue label="Số giấy phép đăng ký kinh doanh" value={data.businessLiceneseNum} />
                <GFLabelValue label="Ngày cấp GP ĐKKD" value={data.businessLiceneseDate ? toClientDate(data.businessLiceneseDate) : TEXT_HOLDER} />
                <GFLabelValue label="Tên người đại diện pháp luật" value={data.nameOfLegalRepresentative} />
                <GFLabelValue label="Chức vụ" value={data.position} />
                <GFLabelValue label="Mã code KH mua cám (Feed)" value={data.codeOfFeedCus} />
                <GFLabelValue label="Nơi sinh" value={data.birthPlace} />
              </div>
              <div className="u-card u-space-y-6">
                <GFTypography>Tài khoản ngân hàng</GFTypography>
                <GFLabelValue label="Tên ngân hàng" value={data.bankKey} />
                <GFLabelValue label="Số tài khoản" value={data.bankAccNum} />
                <GFLabelValue label="Tên chủ tài khoản" value={data.bankAccName} />
              </div>
            </div>
          </div>
        )}
      </GFPageDialog>
    </>
  );
}
