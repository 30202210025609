import accountImage from "@Assets/images/avt.png";
import Loading from "@Components/Loading";
import ModalFooter from "@Components/ModalFooter";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { TEXT_HOLDER } from "@Constants";
import { Action, CustomerModel, FetchAction, RoleModal, RoleType, SourceType } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, PermissionService } from "@Services";
import { FormUtil, checkLinkImage } from "@Utils";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useReducer, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "./AccountModal.scss";

type P_Props = {
  closeForm: () => void;
  infoAccount: any;
};

function reducer(state: PaginateFetchReducer<RoleModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const UpdateAccount: React.FC<P_Props> = ({ closeForm, infoAccount }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const { accountId }: { accountId: string } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCustomerFeed, setSelectedCustomerFeed] = useState<CustomerModel | null>(null);
  const [selectedCustomerFarm, setSelectedCustomerFarm] = useState<CustomerModel | null>(null);
  const [selectedCustomerVet, setSelectedCustomerVet] = useState<CustomerModel | null>(null);
  const [selectedCustomerLab, setSelectedCustomerLab] = useState<CustomerModel | null>(null);
  const [selectedCustomerCcu, setSelectedCustomerCcu] = useState<CustomerModel | null>(null);
  const [customer, setCustomer] = useState<
    { item: CustomerModel; customerId: string; source: string }[]
  >([]);
  const [requireOTP, setRequireOTP] = useState<boolean>(infoAccount.requireOTP);
  const [isActive, setIsActive] = useState<boolean>(infoAccount.isActive);

  const [roleId, setRoleId] = useState<any>("");

  const [role, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const fetchData = async (query: any) => {
    let cancel = false;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await PermissionService.getAllRole(query);
      if (!cancel)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (!cancel)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData({
      page: 1,
      limit: 200,
      roleType: RoleType.CUSTOMER,
    });
  }, []);

  const [isActiveSource, setIsActiveSource] = useState<{
    feed: boolean;
    farm: boolean;
    vet: boolean;
    lab: boolean;
    ccu: boolean;
  }>({
    feed: false,
    farm: false,
    vet: false,
    lab: false,
    ccu: false,
  });

  useEffect(() => {
    setIsActiveSource({
      feed: infoAccount.customers.find((c: any) => c.source === SourceType.FEED)
        ? infoAccount.customers.find((c: any) => c.source === SourceType.FEED)?.isActive
        : false,
      farm: infoAccount.customers.find((c: any) => c.source === SourceType.FARM)
        ? infoAccount.customers.find((c: any) => c.source === SourceType.FARM)?.isActive
        : false,
      vet: infoAccount.customers.find((c: any) => c.source === SourceType.VET)
        ? infoAccount.customers.find((c: any) => c.source === SourceType.VET)?.isActive
        : false,
      lab: infoAccount.customers.find((c: any) => c.source === SourceType.LAB)
        ? infoAccount.customers.find((c: any) => c.source === SourceType.LAB)?.isActive
        : false,
      ccu: infoAccount.customers.find((c: any) => c.source === SourceType.CCU)
        ? infoAccount.customers.find((c: any) => c.source === SourceType.CCU)?.isActive
        : false,
    });
    setRoleId(infoAccount?.role?.id);
  }, [infoAccount]);

  const onUpdateCustomer = (
    variantId: number,
    customerId: string,
    source: string,
    addedCustomer?: CustomerModel
  ) => {
    const idx = customer.findIndex((item) => item.item.id === variantId);
    let data = [...customer];
    if (idx !== -1) {
      // update

      data[idx] = {
        ...data[idx],
        customerId: customerId,
        source: source,
      };
    } else {
      // thêm mới
      if (!addedCustomer) {
        return;
      }

      data.push({ item: addedCustomer, customerId, source });
    }

    setCustomer(data);
  };

  const submitForm = async (values: any) => {
    const validCustomer = customer.filter((item) => item.source);
    const users = validCustomer.map((item) => {
      switch (item.item.source) {
        case SourceType.FEED:
          return {
            customerId: selectedCustomerFeed!.id,
            source: selectedCustomerFeed?.source,
            isActive: selectedCustomerFeed === null ? undefined : isActiveSource.feed,
          };
        case SourceType.FARM:
          return {
            customerId: selectedCustomerFarm!.id,
            source: selectedCustomerFarm?.source,
            isActive: selectedCustomerFarm === null ? undefined : isActiveSource.farm,
          };
        case SourceType.VET:
          return {
            customerId: selectedCustomerVet!.id,
            source: selectedCustomerVet?.source,
            isActive: selectedCustomerVet === null ? undefined : isActiveSource.vet,
          };
        case SourceType.LAB:
          return {
            customerId: selectedCustomerLab!.id,
            source: selectedCustomerLab?.source,
            isActive: selectedCustomerLab === null ? undefined : isActiveSource.lab,
          };
        default:
          return {
            customerId: selectedCustomerCcu!.id,
            source: selectedCustomerCcu?.source,
            isActive: selectedCustomerCcu === null ? undefined : isActiveSource.ccu,
          };
      }
    });

    const usersData = [
      infoAccount.customers.find((c: any) => c.source === SourceType.FEED) && {
        customerId: infoAccount.customers.find((c: any) => c.source === SourceType.FEED)?.id,
        source: infoAccount.customers.find((c: any) => c.source === SourceType.FEED)?.source,
        isActive: isActiveSource.feed,
      },
      infoAccount.customers.find((c: any) => c.source === SourceType.FARM) && {
        customerId: infoAccount.customers.find((c: any) => c.source === SourceType.FARM)?.id,
        source: infoAccount.customers.find((c: any) => c.source === SourceType.FARM)?.source,
        isActive: isActiveSource.farm,
      },
      infoAccount.customers.find((c: any) => c.source === SourceType.VET) && {
        customerId: infoAccount.customers.find((c: any) => c.source === SourceType.VET)?.id,
        source: infoAccount.customers.find((c: any) => c.source === SourceType.VET)?.source,
        isActive: isActiveSource.vet,
      },
      infoAccount.customers.find((c: any) => c.source === SourceType.LAB) && {
        customerId: infoAccount.customers.find((c: any) => c.source === SourceType.LAB)?.id,
        source: infoAccount.customers.find((c: any) => c.source === SourceType.LAB)?.source,
        isActive: isActiveSource.lab,
      },
      infoAccount.customers.find((c: any) => c.source === SourceType.CCU) && {
        customerId: infoAccount.customers.find((c: any) => c.source === SourceType.CCU)?.id,
        source: infoAccount.customers.find((c: any) => c.source === SourceType.CCU)?.source,
        isActive: isActiveSource.ccu,
      },
    ].filter((f) => f !== undefined);

    const data: Parameters<typeof CustomerService.updateAccount>[0] = {
      id: accountId,
      code: values.code,
      email: values.email,
      requireOTP: requireOTP,
      isActive: isActive,
      phoneNumber: values.phoneNumber,
      name: values.nickname,
      customers: users.concat(usersData),
      roleId: roleId,
    } as any;

    CustomerService.updateAccount(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Cập nhập tài khoản thành công!", {
            variant: "success",
          });
          window.location.reload();
        } else {
          //do something
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.message?.includes("email")) {
            enqueueSnackbar("Email đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber")) {
            enqueueSnackbar("Số điện thoại đã tồn tại!", {
              variant: "error",
            });
          } else if (
            error.response.data.message === `Key (code)=(${values.code}) already exists.`
          ) {
            enqueueSnackbar("Mã đăng nhập đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber must be a string")) {
            enqueueSnackbar("Vui lòng điền SĐT", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Cập nhập tài khoản thất bại!", {
            variant: "error",
          });
        }
      });
  };

  const accountImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = accountImage;
    event.currentTarget.className = "error";
  };

  const bingingDataForm = (data: any) => {
    return {
      ...data,
      roleId: data.role?.id,
    };
  };

  return (
    <div className="account-modal">
      <SimpleModalHeader title={"Chỉnh sửa tài khoản khách hàng"} onClose={closeForm} />
      {!!infoAccount ? (
        <div className="content">
          <Form
            initialValues={bingingDataForm(infoAccount)}
            onSubmit={async (values) => submitForm(values)}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="account">
                  <div className="form-item">
                    <div className="form-label">{t("OPERATIONAL_STATUS")}</div>
                    <div>
                      <DefaultSwitch checked={isActive} onChange={() => setIsActive(!isActive)} />
                      {isActive === true ? (
                        <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>
                          {t("ACTIVATE")}
                        </span>
                      ) : (
                        <span style={{ fontSize: "1.6rem", color: "#838283" }}>{t("DISABLE")}</span>
                      )}
                    </div>
                  </div>
                  <div className="title">{t("ACCOUNT_INFO")}</div>
                  <div>
                    <div className="header">
                      <div className="source">{t("ACCESS_SCOPE")}</div>
                      <div className="cus">{t("CUSTOMER")}</div>
                      <div className="access">{t("ACCESS_GRANT")}</div>
                    </div>
                    <div className="table">
                      <div className="col source">
                        <div>{t("FEED")}</div>
                        <div>{t("FARM")}</div>
                        <div>{t("VET")}</div>
                        <div>{t("LAB")}</div>
                        <div>{t("CCU")}</div>
                      </div>
                      <div className="col cus">
                        {infoAccount.customers.find((c: any) => c.source === SourceType.FEED) ? (
                          <div className="cus-info">
                            <div className="cus-info__img">
                              <img
                                src={
                                  checkLinkImage(
                                    infoAccount.customers.find(
                                      (c: any) => c.source === SourceType.FEED
                                    )?.avatar
                                  ) || accountImage
                                }
                                alt="img"
                                onError={accountImageOnErrorHandler}
                              />
                            </div>
                            <div>
                              <div className="cus-info__name">
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FEED
                                ).fullName || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FEED
                                ).code || TEXT_HOLDER}
                              </div>
                              <div className="cus-info__phone">
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FEED
                                ).phoneNumber || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FEED
                                ).email || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        ) : !selectedCustomerFeed ? (
                          <SelectInput<CustomerModel>
                            getSource={SourceType.FEED}
                            className={"item-textfield"}
                            icon={<SearchIcon />}
                            onChange={(value) => {
                              setSelectedCustomerFeed(value);
                              onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                            }}
                            placeholder="Tìm kiếm khách hàng"
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(opt) => (
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  margin: "2.1rem 0 0",
                                  width: "100%",
                                  borderBottom: "1px solid #d6d5d6",
                                }}>
                                <div>
                                  <div style={{ fontSize: "1.8rem" }}>
                                    {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#adacac",
                                      padding: "0.4rem 0 2rem",
                                    }}>
                                    {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            )}
                            actionConfig={{
                              action: CustomerService.getCustomer,
                            }}
                          />
                        ) : (
                          <div className="item-selected">
                            <div className="name">
                              {selectedCustomerFeed.fullName || TEXT_HOLDER} -{" "}
                              {selectedCustomerFeed.code || TEXT_HOLDER}
                            </div>
                            <div className="phone">
                              {selectedCustomerFeed.phoneNumber || TEXT_HOLDER} -{" "}
                              {selectedCustomerFeed.email || TEXT_HOLDER}
                            </div>

                            <ClearIcon
                              className="clear-icon"
                              onClick={() => {
                                setSelectedCustomerFeed(null);
                              }}
                            />
                          </div>
                        )}

                        {infoAccount.customers.find((c: any) => c.source === SourceType.FARM) ? (
                          <div className="cus-info">
                            <div className="cus-info__img">
                              <img
                                src={
                                  checkLinkImage(
                                    infoAccount.customers.find(
                                      (c: any) => c.source === SourceType.FARM
                                    )?.avatar
                                  ) || accountImage
                                }
                                alt="img"
                                onError={accountImageOnErrorHandler}
                              />
                            </div>
                            <div>
                              <div className="cus-info__name">
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FARM
                                ).fullName || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FARM
                                ).code || TEXT_HOLDER}
                              </div>
                              <div className="cus-info__phone">
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FARM
                                ).phoneNumber || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find(
                                  (c: any) => c.source === SourceType.FARM
                                ).email || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        ) : !selectedCustomerFarm ? (
                          <SelectInput<CustomerModel>
                            getSource={SourceType.FARM}
                            className={"item-textfield"}
                            icon={<SearchIcon />}
                            onChange={(value) => {
                              setSelectedCustomerFarm(value);
                              onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                            }}
                            placeholder="Tìm kiếm khách hàng"
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(opt) => (
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  margin: "2.1rem 0 0",
                                  width: "100%",
                                  borderBottom: "1px solid #d6d5d6",
                                }}>
                                <div>
                                  <div style={{ fontSize: "1.8rem" }}>
                                    {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#adacac",
                                      padding: "0.4rem 0 2rem",
                                    }}>
                                    {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            )}
                            actionConfig={{
                              action: CustomerService.getCustomer,
                            }}
                          />
                        ) : (
                          <div className="item-selected">
                            <div className="name">
                              {selectedCustomerFarm.fullName || TEXT_HOLDER} -{" "}
                              {selectedCustomerFarm.code || TEXT_HOLDER}
                            </div>
                            <div className="phone">
                              {selectedCustomerFarm.phoneNumber || TEXT_HOLDER} -{" "}
                              {selectedCustomerFarm.email || TEXT_HOLDER}
                            </div>

                            <ClearIcon
                              className="clear-icon"
                              onClick={() => {
                                setSelectedCustomerFarm(null);
                              }}
                            />
                          </div>
                        )}

                        {infoAccount.customers.find((c: any) => c.source === SourceType.VET) ? (
                          <div className="cus-info">
                            <div className="cus-info__img">
                              <img
                                src={
                                  checkLinkImage(
                                    infoAccount.customers.find(
                                      (c: any) => c.source === SourceType.VET
                                    )?.avatar
                                  ) || accountImage
                                }
                                alt="img"
                                onError={accountImageOnErrorHandler}
                              />
                            </div>
                            <div>
                              <div className="cus-info__name">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.VET)
                                  .fullName || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.VET)
                                  .code || TEXT_HOLDER}
                              </div>
                              <div className="cus-info__phone">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.VET)
                                  .phoneNumber || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.VET)
                                  .email || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        ) : !selectedCustomerVet ? (
                          <SelectInput<CustomerModel>
                            getSource={SourceType.VET}
                            className={"item-textfield"}
                            icon={<SearchIcon />}
                            onChange={(value) => {
                              setSelectedCustomerVet(value);
                              onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                            }}
                            placeholder="Tìm kiếm khách hàng"
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(opt) => (
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  margin: "2.1rem 0 0",
                                  width: "100%",
                                  borderBottom: "1px solid #d6d5d6",
                                }}>
                                <div>
                                  <div style={{ fontSize: "1.8rem" }}>
                                    {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#adacac",
                                      padding: "0.4rem 0 2rem",
                                    }}>
                                    {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            )}
                            actionConfig={{
                              action: CustomerService.getCustomer,
                            }}
                          />
                        ) : (
                          <div className="item-selected">
                            <div className="name">
                              {selectedCustomerVet.fullName || TEXT_HOLDER} -{" "}
                              {selectedCustomerVet.code || TEXT_HOLDER}
                            </div>
                            <div className="phone">
                              {selectedCustomerVet.phoneNumber || TEXT_HOLDER} -{" "}
                              {selectedCustomerVet.email || TEXT_HOLDER}
                            </div>

                            <ClearIcon
                              className="clear-icon"
                              onClick={() => {
                                setSelectedCustomerVet(null);
                              }}
                            />
                          </div>
                        )}

                        {infoAccount.customers.find((c: any) => c.source === SourceType.LAB) ? (
                          <div className="cus-info">
                            <div className="cus-info__img">
                              <img
                                src={
                                  checkLinkImage(
                                    infoAccount.customers.find(
                                      (c: any) => c.source === SourceType.LAB
                                    )?.avatar
                                  ) || accountImage
                                }
                                alt="img"
                                onError={accountImageOnErrorHandler}
                              />
                            </div>
                            <div>
                              <div className="cus-info__name">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.LAB)
                                  .fullName || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.LAB)
                                  .code || TEXT_HOLDER}
                              </div>
                              <div className="cus-info__phone">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.LAB)
                                  .phoneNumber || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.LAB)
                                  .email || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        ) : !selectedCustomerLab ? (
                          <SelectInput<CustomerModel>
                            getSource={SourceType.LAB}
                            className={"item-textfield"}
                            icon={<SearchIcon />}
                            onChange={(value) => {
                              setSelectedCustomerLab(value);
                              onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                            }}
                            placeholder="Tìm kiếm khách hàng"
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(opt) => (
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  margin: "2.1rem 0 0",
                                  width: "100%",
                                  borderBottom: "1px solid #d6d5d6",
                                }}>
                                <div>
                                  <div style={{ fontSize: "1.8rem" }}>
                                    {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#adacac",
                                      padding: "0.4rem 0 2rem",
                                    }}>
                                    {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            )}
                            actionConfig={{
                              action: CustomerService.getCustomer,
                            }}
                          />
                        ) : (
                          <div className="item-selected">
                            <div className="name">
                              {selectedCustomerLab.fullName || TEXT_HOLDER} -{" "}
                              {selectedCustomerLab.code || TEXT_HOLDER}
                            </div>
                            <div className="phone">
                              {selectedCustomerLab.phoneNumber || TEXT_HOLDER} -{" "}
                              {selectedCustomerLab.email || TEXT_HOLDER}
                            </div>

                            <ClearIcon
                              className="clear-icon"
                              onClick={() => {
                                setSelectedCustomerLab(null);
                              }}
                            />
                          </div>
                        )}

                        {infoAccount.customers.find((c: any) => c.source === SourceType.CCU) ? (
                          <div className="cus-info">
                            <div className="cus-info__img">
                              <img
                                src={
                                  checkLinkImage(
                                    infoAccount.customers.find(
                                      (c: any) => c.source === SourceType.CCU
                                    )?.avatar
                                  ) || accountImage
                                }
                                alt="img"
                                onError={accountImageOnErrorHandler}
                              />
                            </div>
                            <div>
                              <div className="cus-info__name">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.CCU)
                                  .fullName || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.CCU)
                                  .code || TEXT_HOLDER}
                              </div>
                              <div className="cus-info__phone">
                                {infoAccount.customers.find((c: any) => c.source === SourceType.CCU)
                                  .phoneNumber || TEXT_HOLDER}{" "}
                                -{" "}
                                {infoAccount.customers.find((c: any) => c.source === SourceType.CCU)
                                  .email || TEXT_HOLDER}
                              </div>
                            </div>
                          </div>
                        ) : !selectedCustomerCcu ? (
                          <SelectInput<CustomerModel>
                            getSource={SourceType.CCU}
                            className={"item-textfield"}
                            icon={<SearchIcon />}
                            onChange={(value) => {
                              setSelectedCustomerCcu(value);
                              onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                            }}
                            placeholder="Tìm kiếm khách hàng"
                            getOptionLabel={(opt) => opt.fullName}
                            renderOption={(opt) => (
                              <div
                                style={{
                                  display: "flex",
                                  cursor: "pointer",
                                  margin: "2.1rem 0 0",
                                  width: "100%",
                                  borderBottom: "1px solid #d6d5d6",
                                }}>
                                <div>
                                  <div style={{ fontSize: "1.8rem" }}>
                                    {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color: "#adacac",
                                      padding: "0.4rem 0 2rem",
                                    }}>
                                    {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                            )}
                            actionConfig={{
                              action: CustomerService.getCustomer,
                            }}
                          />
                        ) : (
                          <div className="item-selected">
                            <div className="name">
                              {selectedCustomerCcu.fullName || TEXT_HOLDER} -{" "}
                              {selectedCustomerCcu.code || TEXT_HOLDER}
                            </div>
                            <div className="phone">
                              {selectedCustomerCcu.phoneNumber || TEXT_HOLDER} -{" "}
                              {selectedCustomerCcu.email || TEXT_HOLDER}
                            </div>

                            <ClearIcon
                              className="clear-icon"
                              onClick={() => {
                                setSelectedCustomerCcu(null);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col access">
                        {/* {infoAccount.customers.find((c: any) => c.source === SourceType.FEED) ? (
                 <div className="ic-green">
                   <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                 </div>
               ) : (
                 <Checkbox
                   className="app-check-box"
                   checked={selectedCustomerFeed !== null}
                   icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                   checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                 />
               )}

               {infoAccount.customers.find((c: any) => c.source === "farm") ? (
                 <div className="ic-green">
                   <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                 </div>
               ) : (
                 <Checkbox
                   className="app-check-box"
                   checked={selectedCustomerFarm !== null}
                   icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                   checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                 />
               )}

               {infoAccount.customers.find((c: any) => c.source === "ccu") ? (
                 <div className="ic-green">
                   <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                 </div>
               ) : (
                 <Checkbox
                   className="app-check-box"
                   checked={selectedCustomerCcu !== null}
                   icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                   checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                 />
               )} */}
                        <Checkbox
                          className="app-check-box"
                          checked={isActiveSource.feed}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                color:
                                  selectedCustomerFeed === null &&
                                  !infoAccount.customers.find(
                                    (c: any) => c.source === SourceType.FEED
                                  )
                                    ? "#efefef"
                                    : "",
                              }}
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          onChange={() =>
                            setIsActiveSource({
                              feed: !isActiveSource.feed,
                              farm: isActiveSource.farm,
                              vet: isActiveSource.vet,
                              lab: isActiveSource.lab,
                              ccu: isActiveSource.ccu,
                            })
                          }
                        />
                        <Checkbox
                          className="app-check-box"
                          checked={isActiveSource.farm}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                color:
                                  selectedCustomerFarm === null &&
                                  !infoAccount.customers.find(
                                    (c: any) => c.source === SourceType.FARM
                                  )
                                    ? "#efefef"
                                    : "",
                              }}
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          onChange={() =>
                            setIsActiveSource({
                              feed: isActiveSource.feed,
                              farm: !isActiveSource.farm,
                              vet: isActiveSource.vet,
                              lab: isActiveSource.lab,
                              ccu: isActiveSource.ccu,
                            })
                          }
                        />
                        <Checkbox
                          className="app-check-box"
                          checked={isActiveSource.vet}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                color:
                                  selectedCustomerVet === null &&
                                  !infoAccount.customers.find(
                                    (c: any) => c.source === SourceType.VET
                                  )
                                    ? "#efefef"
                                    : "",
                              }}
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          onChange={() =>
                            setIsActiveSource({
                              feed: isActiveSource.feed,
                              farm: isActiveSource.farm,
                              vet: !isActiveSource.vet,
                              lab: isActiveSource.lab,
                              ccu: isActiveSource.ccu,
                            })
                          }
                        />
                        <Checkbox
                          className="app-check-box"
                          checked={isActiveSource.lab}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                color:
                                  selectedCustomerLab === null &&
                                  !infoAccount.customers.find(
                                    (c: any) => c.source === SourceType.LAB
                                  )
                                    ? "#efefef"
                                    : "",
                              }}
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          onChange={() =>
                            setIsActiveSource({
                              feed: isActiveSource.feed,
                              farm: isActiveSource.farm,
                              vet: isActiveSource.vet,
                              lab: !isActiveSource.lab,
                              ccu: isActiveSource.ccu,
                            })
                          }
                        />
                        <Checkbox
                          className="app-check-box"
                          checked={isActiveSource.ccu}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                color:
                                  selectedCustomerCcu === null &&
                                  !infoAccount.customers.find(
                                    (c: any) => c.source === SourceType.CCU
                                  )
                                    ? "#efefef"
                                    : "",
                              }}
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                          onChange={() =>
                            setIsActiveSource({
                              feed: isActiveSource.feed,
                              farm: isActiveSource.farm,
                              vet: isActiveSource.vet,
                              lab: isActiveSource.lab,
                              ccu: !isActiveSource.ccu,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="title">{t("LOGIN_NAME")}</div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "100%", marginRight: "3.5rem" }}>
                      <div className="form-item">
                        <div className="form-label">{t("DISPLAY_NAME")}</div>
                        <Field
                          name="nickname"
                          validate={FormUtil.Rule.required("Nhập tên đăng nhập")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder="Nhập tên đăng nhập..."
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item">
                        <div className="form-label">{t("LOGIN_PHONE")}</div>
                        <Field
                          name="phoneNumber"
                          validate={
                            requireOTP === true
                              ? FormUtil.Rule.required(
                                  t("INPUT_BY_NAME", { NAME: t("LOGIN_PHONE").toLowerCase() })
                                )
                              : undefined
                          }>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("LOGIN_PHONE").toLowerCase(),
                                })}
                                color="success"
                                onChange={(e) => input.onChange(e.target.value)}
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="form-label">Vai trò</div>
                        <Field
                          name="roleId"
                          validate={FormUtil.Rule.required("Bạn chưa chọn vai trò")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  {...input}
                                  {...rest}
                                  value={roleId || undefined}
                                  onChange={(e, value) => {
                                    input.onChange(e.target.value);
                                  }}
                                  error={!roleId && meta.error && meta.touched}
                                  fullWidth
                                  style={{ fontFamily: "SVN-Gotham" }}
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  renderValue={() => {
                                    const dataRoleSel: RoleModal = role.data?.data?.find(
                                      (i: RoleModal) => i.id === roleId
                                    );
                                    return !!dataRoleSel ? (
                                      dataRoleSel?.position
                                    ) : (
                                      <span className="form__placeholder">
                                        Chọn vai trò cho người dùng
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  className="form-select-field"
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {role.data.data?.map((s: RoleModal) => {
                                    return (
                                      <MenuItem
                                        key={s.id}
                                        value={s.position}
                                        onClick={() => setRoleId(s.id)}>
                                        {s.position}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>
                                  {!roleId && meta.touched ? meta.error : ""}
                                </FormHelperText>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="otp">
                          <Checkbox
                            className="app-check-box"
                            checked={requireOTP}
                            onChange={() => setRequireOTP(!requireOTP)}
                          />
                          <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                      <div className="form-item">
                        <div className="form-label">Mã đăng nhập</div>
                        <Field name="code" validate={FormUtil.Rule.required("Nhập mã đăng nhập")}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder="Nhập mã đăng nhập..."
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="form-item">
                        <div className="form-label">{t("LOGIN_EMAIL")}</div>
                        <Field
                          name="email"
                          // validate={FormUtil.Rule.required("Nhập Email đăng nhập")}
                        >
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("LOGIN_EMAIL").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={meta.error && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      ) : (
        <Loading style={{ margin: "2rem 0", height: "80vh" }} />
      )}
      <ModalFooter
        formId="account"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={"Cập nhật tài khoản"}
      />
    </div>
  );
};

export default UpdateAccount;
