import { IconBase, IconComponent } from "@Core/Icons";

export const SignInIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.31778 11.3889L6.5 8.57111L8.07111 7L13.5711 12.5L8.07111 18L6.5 16.4289L9.31778 13.6111H2V11.3889H9.31778ZM10.8889 2.5H19.7778C21 2.5 22 3.5 22 4.72222V20.2778C22 21.5 21 22.5 19.7778 22.5H10.8889V20.2778H19.7778V4.72222H10.8889V2.5Z"
        fill={props.color}
      />
    </IconBase>
  );
};
