import { IconBase, IconComponent } from "@Core/Icons";

export const WarehouseIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M21.125 13.7363C21.4375 13.7363 21.75 14.0488 21.75 14.3613V19.3613C21.75 19.7129 21.4375 19.9863 21.125 19.9863H4.875C4.52344 19.9863 4.25 19.7129 4.25 19.3613V9.36133C4.25 9.04883 4.52344 8.73633 4.875 8.73633H13.625C13.9375 8.73633 14.25 9.04883 14.25 9.36133V13.7363H21.125ZM12.375 18.1113V15.6113H6.125V18.1113H12.375ZM12.375 13.1113V10.6113H6.125V13.1113H12.375ZM19.875 18.1113V15.6113H14.25V18.1113H19.875ZM23.8203 4.32227C24.8359 4.71289 25.5 5.68945 25.5 6.7832V19.3613C25.5 19.7129 25.1875 19.9863 24.875 19.9863H24.25C23.8984 19.9863 23.625 19.7129 23.625 19.3613V6.7832C23.625 6.43164 23.4297 6.1582 23.1172 6.04102L13.2734 1.93945C13.0781 1.86133 12.8828 1.86133 12.6875 1.93945L2.84375 6.04102C2.53125 6.1582 2.375 6.43164 2.375 6.7832V19.3613C2.375 19.7129 2.0625 19.9863 1.75 19.9863H1.125C0.773438 19.9863 0.5 19.7129 0.5 19.3613V6.7832C0.5 5.68945 1.125 4.71289 2.14062 4.32227L11.9453 0.220703C12.6094 -0.0527344 13.3516 -0.0527344 14.0156 0.220703L23.8203 4.32227Z"
        fill={props.color}
      />
    </IconBase>
  );
};
