import { PaginatedQueryDto, PaginatedResponseDto } from "@Models";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

const initialPage = 1;
const DEFAULT_LIMIT = 50;

type PaginateState<T> = PaginatedQueryDto & {
  loading: boolean;
  fetching: boolean;
  hasMore: boolean;
  error?: any;
  data: PaginatedResponseDto<T>;
};

export default function useQueryPagination<T>(
  fetchApi: (query: PaginatedQueryDto) => Promise<any>
) {
  const [pagination, setPagination] = useState<PaginateState<T>>({
    page: initialPage,
    limit: DEFAULT_LIMIT,
    searchValue: undefined,
    loading: false,
    hasMore: false,
    fetching: false,
    data: {
      page: 0,
      totalPages: 0,
      totalRecords: 0,
      data: [],
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleFetchApi = useCallback(
    async (query: Partial<PaginatedQueryDto>, isSearch = false, isInitiate = false) => {
      if (pagination.loading || pagination.fetching) return;

      const newState = {
        ...pagination,
        ...query,
        hasMore: false,
        loading: isInitiate ? true : false,
        fetching: true,
      };

      setPagination(newState);
      try {
        const response = await fetchApi({
          limit: newState.limit,
          searchValue: newState.searchValue,
          page: newState.page,
        });

        if (response.status === 200) {
          const newData = [...pagination.data.data, ...response.data.data];
          setPagination((prev) => ({
            ...prev,
            loading: false,
            fetching: false,
            hasMore: response.data.totalPages > response.data.page,
            data: isSearch ? response.data : { ...response.data, data: newData },
          }));
        }
      } catch (error) {
        enqueueSnackbar(`Lấy dữ liệu thất bại`, {
          variant: "error",
        });
        setPagination((prev) => ({ ...prev, loading: false, fetching: false }));
      }
    },
    [fetchApi, pagination, enqueueSnackbar]
  );

  const onLoadMore = () => {
    handleFetchApi({ page: pagination.page + 1 });
  };

  const onChangePage = (page: number) => {
    handleFetchApi({ page });
  };

  const onSearch = (searchValue: string) => {
    handleFetchApi({ page: initialPage, searchValue }, true);
  };

  const onReset = () => {
    handleFetchApi({ page: initialPage, searchValue: undefined });
  };

  const onUpdateSuccess = (id: any, data: any) => {
    const newData = pagination.data.data.map((item) =>
      (item as any).id === id ? { ...item, ...data } : item
    );
    setPagination((prev) => ({ ...prev, data: { ...prev.data, data: newData } }));
  };

  const onCreateSuccess = (data: any) => {
    const newData = [...pagination.data.data, data];
    setPagination((prev) => ({ ...prev, data: { ...prev.data, data: newData } }));
  };

  const onRemoveSuccess = (id: any) => {
    const newData = pagination.data.data.filter((item) => (item as any).id !== id);
    setPagination((prev) => ({ ...prev, data: { ...prev.data, data: newData } }));
  };

  useEffect(() => {
    handleFetchApi(pagination, false, true);
  }, []);

  return {
    ...pagination,
    onLoadMore,
    onChangePage,
    onSearch,
    onReset,
    onUpdateSuccess,
    onCreateSuccess,
    onRemoveSuccess,
  };
}
