/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Search from "@Assets/images/icons/img-search.png";
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import RangePicker from "@Components/RangePicker";
import { APP_ROUTES, NavHeaderOrder, NavHeaderOrderFarm, TEXT_ASTERISK } from "@Constants";
import useDateRange from "@Hooks/useDateRange";
import { Action, FetchAction, ItemPermission, OrderOutputModel, SourceType } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService } from "@Services";
import { PermissionUtil, StatusUIUtil, currencyFormat } from "@Utils";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import "./CustomerOrderDetail.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";

type P_Props = {
  source: any;
};

function reducer(state: PaginateFetchReducer<OrderOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const CustomerOrderDetail: React.FC<P_Props> = ({ source }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>("");
  const [range, setRange] = useDateRange();
  const [selectTab, setSelectTab] = useState<string>("all");
  const { customerId }: { customerId: string } = useParams();
  const mounted = useRef(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getOrdCustomerById(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err: any) {
      console.log(err);
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        from: range.startTime.toISOString(),
        to: range.endTime.toISOString(),
        strSearch: searchValue,
        status: selectTab,
        id: customerId,
        source: source,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.startTime.toISOString(), range.endTime.toISOString(), searchValue, selectTab, source]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="customer-order-detail">
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          placeholder={t("ENTER_SEARCH")}
          variant="outlined"
          className="search-style"
          inputProps={{ className: "input" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="icon" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
        <RangePicker
          startDate={range.startTime}
          endDate={range.endTime}
          onChangeDate={(val) => {
            if (val && val.length) {
              setRange(
                moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
              );
            }
          }}
        />
      </div>
      <div className="customer-order-detail-nav-header">
        {(source === "farm" ? NavHeaderOrderFarm : NavHeaderOrder).map((o) => {
          return (
            <div
              key={o.id}
              className={o.value === selectTab ? "tab tab-active" : `tab ${o.value} tab-hover`}
              onClick={() => {
                setSelectTab(o.value);
              }}>
              {t(o.label)} <span>{state.count && state.count[`${o.value}`]}</span>
            </div>
          );
        })}
      </div>
      <div className="list">
        <div className="list-header">
          <div className="id col">{t("order:ORDER_ID")} </div>
          <div className="setTime col">{t("order:ORDER_TIME")}</div>
          <div className="orderTime col">{t("customer:REQUESTED_DELIVERY_DATE")}</div>
          <div className="status col">{t("STATUS")}</div>
          <div className="total col">{t("customer:TOTAL_AMOUNT")}</div>
        </div>
        {state.data ? (
          state.data?.length > 0 ? (
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              initialLoad={false}
              hasMore={hasMore}
              loadMore={() =>
                fetchData({
                  limit: 50,
                  page: state.page + 1,
                  strSearch: searchValue,
                  status: selectTab,
                  from: range.startTime.toISOString(),
                  to: range.endTime.toISOString(),
                  id: customerId,
                  source: source,
                })
              }>
              {state.fetching && searchValue && <Loading />}
              {state.data.map((ord: any) => {
                return (
                  <div
                    className={`item ${ord.status}`}
                    onClick={() => {
                      if (source === SourceType.FEED) {
                        history.push({
                          pathname: APP_ROUTES.ORDER_DETAIL_FEED.replace(":orderId", ord.id),
                          state: { header: "1", source: source },
                        });
                      } else if (source === SourceType.VET) {
                        history.push({
                          pathname: APP_ROUTES.ORDER_DETAIL_VET.replace(":orderId", ord.id),
                          state: { header: "1", source: source },
                        });
                      } else {
                        history.push({
                          pathname: APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", ord.id),
                          state: { header: "1", source: source },
                        });
                      }
                    }}>
                    <div className={`id id-${ord.status} col`}>{ord.code}</div>
                    <div className="setTime col">
                      {moment(ord.orders[0].createdAt).format("DD/MM/YYYY HH:mm")}
                    </div>
                    <div className="orderTime col">
                      {moment(ord.orders[0].requiredDate).format("DD/MM/YYYY")}
                    </div>
                    <div className="status col">
                      {StatusUIUtil.renderCircleStatus(selectTab, ord.status, source)}
                    </div>
                    <div className="total col">
                      {checkPermissionViewPrice
                        ? currencyFormat(parseInt(ord.totalAmount))
                        : TEXT_ASTERISK}{" "}
                      đ
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : visibleSearch === true ? (
            <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
          ) : (
            <EmptyList
              img={Ord}
              title={t("order:NO_ORDER_PLACED")}
              text={t("order:PLACE_ORDER_NOW")}
            />
          )
        ) : (
          <EmptyList
            img={Ord}
            title={t("order:NO_ORDER_PLACED")}
            text={t("order:PLACE_ORDER_NOW")}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerOrderDetail;
