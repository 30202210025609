import { IconBase, IconComponent } from "@Core/Icons";

export const InterestedIcon3: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M14.9083 5.2958C14.8612 5.1619 14.7755 5.04429 14.6617 4.95723C14.5479 4.87017 14.4108 4.8174 14.267 4.80532L10.0243 4.19771L8.12285 0.412962C8.0618 0.289192 7.96646 0.18481 7.84777 0.111773C7.72909 0.0387369 7.59184 0 7.45177 0C7.31169 0 7.17445 0.0387369 7.05576 0.111773C6.93707 0.18481 6.84174 0.289192 6.78068 0.412962L4.87927 4.19039L0.63651 4.80532C0.498507 4.82458 0.368765 4.88143 0.262007 4.96942C0.155249 5.05741 0.0757505 5.17302 0.0325326 5.30312C-0.00702795 5.43026 -0.010578 5.56558 0.0222633 5.69455C0.0551047 5.82352 0.123098 5.94128 0.218946 6.03518L3.29849 8.96342L2.55284 13.1215C2.52622 13.2588 2.54015 13.4006 2.59301 13.5303C2.64586 13.6601 2.73541 13.7722 2.8511 13.8536C2.96385 13.9327 3.09684 13.9794 3.23514 13.9885C3.37344 13.9975 3.51157 13.9686 3.63403 13.9048L7.45177 11.9502L11.2546 13.9121C11.3592 13.9701 11.4774 14.0004 11.5976 14C11.7555 14.0005 11.9096 13.9518 12.0375 13.8609C12.1532 13.7795 12.2428 13.6674 12.2956 13.5377C12.3485 13.4079 12.3624 13.2661 12.3358 13.1288L11.5901 8.97074L14.6697 6.0425C14.7773 5.95296 14.8569 5.83526 14.8991 5.70308C14.9414 5.57091 14.9445 5.42968 14.9083 5.2958Z"
        fill="url(#paint0_linear_16903_60817)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16903_60817"
          x1="7.46667"
          y1="0"
          x2="7.46667"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A6836" />
          <stop offset="1" stopColor="#41AD49" />
        </linearGradient>
      </defs>
    </IconBase>
  );
};
