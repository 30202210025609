import { GetListPriceShippingQuery } from "@Models";
import { ProductService } from "@Services";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  checkQueryPaginationNextPage,
  getQueryPaginationData,
  getQueryPaginationTotal,
} from "@Utils";
import _ from "lodash";
import { GetAllFarmProductQuery } from "src/dtos/product.dto";
import { SelectOption } from "src/types";

export const useSAPDeliveryTimes = (payload: { page: number; limit: number }) => {
  const { data, ...props } = useQuery({
    queryKey: ["sap-delivery-times", payload],
    queryFn: () => ProductService.getTimeFromSap(payload),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const responseData: SelectOption[] = _.get(data, "data.data", []).map((item: any) => ({
    label: item.delieryTime,
    value: item.key,
  }));

  return {
    data: responseData,
    ...props,
  };
};

export const useProductsInfinite = (params: Omit<GetAllFarmProductQuery, "page">) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["product-farm", params],
    queryFn: async ({ pageParam }) => {
      const query = {
        ...params,
        page: pageParam || 1,
      };
      return ProductService.getAllFarmProduct(query);
    },
    getNextPageParam: (lastPage) => {
      return checkQueryPaginationNextPage(lastPage);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    products: getQueryPaginationData(data),
    total: getQueryPaginationTotal(data),
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
  };
};

export const useListPriceShipping = (params: Omit<GetListPriceShippingQuery, "page">) => {
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ["list-price-shipping", params],
    queryFn: async ({ pageParam }) => {
      const query: GetListPriceShippingQuery = {
        ...params,
        page: pageParam || 1,
      };

      if (!query.itemGroupTypeId) {
        delete query.itemGroupTypeId
      }

      return ProductService.getListPriceShipping(query);
    },
    getNextPageParam: (lastPage) => {
      return checkQueryPaginationNextPage(lastPage);
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    data: getQueryPaginationData(data),
    total: getQueryPaginationTotal(data),
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
  };
};
