/* eslint-disable react/jsx-no-useless-fragment */

import Avatar from "@Assets/images/avt.png";
import Excel from "@Assets/images/icons/excel_w.png";
import SampleEmpty from "@Assets/images/ordnull.png";
import ProNull from "@Assets/images/product/nopro.png";
import Loading from "@Components/Loading";
import NotAccess from "@Components/NotAccess";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
  APP_ROUTES,
  BASE_LINK_IMAGE_V2,
  OrderStatusLab,
  ReasonStatus,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  TIME,
  UpdateHistory,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  ItemPermission,
  OrderDetailLabOutputModel,
  OrderElementModel,
  OrderLabOutcomeImage,
  OrderLabOutComeResult,
  OrderLabOutComeSample,
  OrderLine,
  OrderPermission,
  OrderStatus,
  Reason,
  SourceType,
} from "@Models";
import { Nullable } from "@Models/shared";
import { OrderService, SalesOrgService } from "@Services";
import {
  checkLinkImage,
  currencyFormat,
  PermissionUtil,
  renderErrorImageItem,
  StatusUIUtil,
} from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon,
  InfoOutlined as InfoIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import GridViewIcon from "@mui/icons-material/GridView";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
} from "@mui/material";
import { AxiosError } from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import Bill from "./Bill";
import "./OrderDetailLab.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type DuplicateModel = {
  sampleCode: string;
  item: {
    itemGroup: {
      id: number;
      code: string;
      name: string;
      source: SourceType;
      image: string | null;
      createdAt: string;
      updatedAt: string;
    };
    items: any[];
  }[];
};

SwiperCore.use([Autoplay, Pagination]);

const OrderDetailLab: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<OrderDetailLabOutputModel | null>(null);
  const [showConfirm, setShowConfirm] = useState<string | null>(null);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [permission, setPermission] = useState<boolean>(false);
  const submitRef = useRef<any | null>(null);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);
  const [openBill, setOpenBill] = useState<boolean>(false);
  const [openSaleOrg, setOpenSaleOrg] = useState<boolean>(false);
  const [valueSaleOrg, setValueSaleOrg] = useState<string>("");
  const [valueRecallOrderVet, setValueRecallOrderVet] = useState<Nullable<any>>(null);
  const [salesOrgForRecall, setSalesOrgForRecall] = useState<any[]>([]);
  const [priceList, setPriceList] = useState<OrderLine[]>([]);
  const [visibleDetail, setVisibleDetail] = useState<number | null>(null);
  const [visibleDuplicate, setVisibleDuplicate] = useState<boolean>(false);
  const [visibleResultSample, setVisibleResultSample] = useState<OrderLabOutcomeImage[]>([]);
  const [visibleSampleDetail, setVisibleSampleDetail] = useState<OrderLabOutcomeImage[]>([]);
  const [activeOutcome, setActiveOutcome] = useState<{
    target: boolean;
    data: OrderLabOutcomeImage | null;
  }>({ target: false, data: null });
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [notiERP, setNotiERP] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getOrderById(+orderId);
      if (mounted.current) {
        setOrder(result.data.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataSalesOrgForRecall: () => Promise<void> = async () => {
    try {
      const result = await SalesOrgService.getSalesOrgForRecall({
        divisionId: valueRecallOrderVet?.division.id,
        customerId: valueRecallOrderVet?.customer.id,
      });
      if (mounted.current) {
        setSalesOrgForRecall(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    valueRecallOrderVet && fetchDataSalesOrgForRecall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRecallOrderVet, openSaleOrg === true]);

  // bo phan tu trung trong mang
  const status = Array.from(
    new Set(
      order?.orders.map((o: any) => {
        return o.status;
      })
    )
  );

  const cancelOrderLab = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrderLab({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: SourceType.LAB,
      });
      enqueueSnackbar(t("order:ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_LAB);
    } catch (error: any) {
      if (error.response?.status === 403) {
        setPermission(true);
      } else {
        enqueueSnackbar(t("order:CANCEL_ORDER_FAILED"), {
          variant: "error",
        });
      }
      setSubmitCancel(false);
      if (submitRef.current) {
        submitRef.current.disabled = false;
      }
    }
    setCancelValue(Reason.NOT_RESPONSE);
    setReason("");
  };

  const recallOrderVet = (valueSaleOrg?: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    OrderService.recallOrder({
      orderId: valueRecallOrderVet?.id,
      salesOrgId: valueSaleOrg ? +valueSaleOrg : undefined,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          if (res.data.message === "NEED_ADD_SALES_ORG") {
            setOpenSaleOrg(true);
          } else if (res.data.message.includes("SUCCEED")) {
            setNotiERP({ visible: true, status: true });
          } else {
            setNotiERP({ visible: true, status: false });
            setOpenSaleOrg(false);
            submitRef.current.disabled = false;
          }
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        if (err.response?.status === 403) {
          setPermission(true);
        } else {
          setNotiERP({ visible: true, status: false });
          submitRef.current.disabled = false;
        }
      });
  };

  useEffect(() => {
    valueRecallOrderVet && recallOrderVet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRecallOrderVet?.id]);

  //them quyen can check
  // const checkPermissionRecallOrder = PermissionUtil.check(
  //   profile?.role?.permissions,
  //   OrderPermission.MANAGE_ORDER_RECALL,
  //   true
  // );

  const checkPermissionCancelOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CANCEL,
    true
  );

  const checkPermissionUpdateOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE,
    true
  );

  const checkPermissionCreateOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );

  const checkPermissionUpdateSample = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE_SAMPLE,
    true
  );

  const checkPermissionConfirmOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CONFIRM,
    true
  );

  const checkPermissionRejectOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_REJECT,
    true
  );

  // const gridSize: any = 12 / (!!order?.orders?.length ? order?.orders?.length + 2 : 1);

  const stepIcon = (props: StepIconProps, idx: number) => {
    const { active, completed } = props;
    return (
      <div
        className={`step-icon ${active ? "active" : completed ? "completed" : "default"}`}
        key={idx}>
        <div>{idx + 1}</div>
      </div>
    );
  };

  const checkStatus =
    order &&
    (order.status === OrderStatusLab.DRAFT ||
      order.status === OrderStatusLab.CANCELLED ||
      order.status === OrderStatusLab.CREATED ||
      order.status === OrderStatusLab.REJECTED);

  const checkOption =
    order &&
    (order.status === OrderStatusLab.CANCELLED ||
      order.status === OrderStatusLab.COMPLETED ||
      order.status === OrderStatusLab.REJECTED ||
      order.status === OrderStatusLab.RECEIVED ||
      order.status === OrderStatusLab.SCHEDULED);

  const checkViewSample =
    (order && order.status === OrderStatusLab.PROCESSING) ||
    order?.status === OrderStatusLab.CONFIRMED;

  const checkOutcomeSample =
    order &&
    (order.status === OrderStatusLab.COMPLETED ||
      order.status === OrderStatusLab.SCHEDULED ||
      order.status === OrderStatusLab.RECEIVED);

  const statusActive = Object.keys(OrderStatusLab)
    .filter(
      (i) =>
        OrderStatusLab[i as keyof typeof OrderStatusLab] !== OrderStatusLab.DRAFT &&
        OrderStatusLab[i as keyof typeof OrderStatusLab] !== OrderStatusLab.CANCELLED
    )
    .findIndex((i) => OrderStatusLab[i as keyof typeof OrderStatusLab] === order?.status);

  const handleChangeStatusLab = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    if (showConfirm) {
      const data = { status: showConfirm, reason: reason, reasonStatus: cancelValue };
      try {
        await OrderService.updateOrderLabStatus(orderId, data);
        enqueueSnackbar(t("order:UPDATE_STATUS_LAB_SUCCESSFUL"), {
          variant: "success",
        });
        window.location.reload();
      } catch (error: any) {
        if (error.response?.status === 403) {
          setPermission(true);
        } else {
          enqueueSnackbar(t("order:UPDATE_STATUS_LAB_FAILED"), {
            variant: "error",
          });
        }
        setShowConfirm(null);
        if (submitRef.current) {
          submitRef.current.disabled = false;
        }
      }
    }
  };

  const handleDataDuplicate = (obj: any) => {
    return Object.entries(obj).map(([key, value]) => ({
      sampleCode: key,
      item: value as any[],
    }));
  };

  const dataDuplicate: DuplicateModel[] = !!order?.duplicateSample
    ? handleDataDuplicate(order?.duplicateSample)
    : [];

  const renderTitle = (status: string) => {
    switch (status) {
      case OrderStatusLab.SCHEDULED:
        return t("SAMPLE_RESULT_RECEPTION");
      case OrderStatusLab.COMPLETED:
        return t("RESULT_BIOLAB");
      case OrderStatusLab.RECEIVED:
        return t("DETAIL_RECEIVED");
      default:
        return t("DETAIL_BIOLAB");
    }
  };

  const sampleInfo: OrderElementModel | null = order?.orders.find(
    (i) => i.id === visibleDetail || i.id === visibleDetail
  )!;

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-detail-lab">
        <SimpleModalHeader
          title={order && renderTitle(order?.status)}
          onClose={() => {
            location.state?.header
              ? history.push({
                  pathname: APP_ROUTES.CUSTOMER_DETAIL_FEED.replace(
                    ":customerId",
                    order?.orders[0]?.customer?.id + ""
                  ),
                  state: {
                    header: location.state?.header,
                    source: location.state?.source,
                    status: location.state?.status,
                  },
                })
              : order?.orderType &&
                history.push({
                  pathname: APP_ROUTES.ORDER + "-" + order?.orderType,
                  state: {
                    startTime: location.state?.startTime,
                    endTime: location.state?.endTime,
                    status: location.state?.status,
                  },
                });
          }}
        />
        <div className="detail-container">
          {!!order ? (
            <>
              <div className="content">
                <Grid container className="content-header">
                  <Grid item xs={3} className="left">
                    <img
                      src={checkLinkImage(order.orders[0].customer?.account?.avatar) || Avatar}
                      alt="Avata"
                      onError={renderErrorImageItem(order?.orderType)}
                    />
                    <div>
                      <div className="name">
                        {order.orders[0].customer !== null
                          ? order.orders[0].customer?.fullName || TEXT_HOLDER
                          : order.orders[0]?.lead?.fullName || TEXT_HOLDER}
                        &#160;-&#160;
                        {order.orders[0].customer !== null
                          ? order.orders[0].customer?.code || TEXT_HOLDER
                          : order.orders[0]?.lead?.code || TEXT_HOLDER}
                        {order.orders[0].customer === null && (
                          <span
                            style={{
                              color: "#838283",
                              fontWeight: 400,
                              fontSize: "1.4rem",
                              marginLeft: "1rem",
                            }}>
                            <span>&#9679;&ensp;</span> {t("order:POTENTIAL")}
                          </span>
                        )}
                      </div>
                      {order.orders[0].customer !== null
                        ? order.orders[0]?.customer?.phoneNumber || TEXT_HOLDER
                        : order.orders[0]?.lead?.phoneNumber || TEXT_HOLDER}
                    </div>
                  </Grid>

                  {checkStatus && (
                    <Grid item container xs={3} className="col">
                      {order?.orders.map((orders: any, index: number) => {
                        const check = Array.from(
                          new Set(
                            order?.orders.map((orders: any) => {
                              return orders.salesOrg?.description;
                            })
                          )
                        );
                        return (
                          <Grid item xs={6} key={index}>
                            <div className="label">
                              {check.length > 1
                                ? t("order:BUSINESS_UNIT") + " " + (index + 1)
                                : check[index] !== undefined
                                ? t("order:BUSINESS_UNIT")
                                : ""}
                            </div>
                            {/* {orders.division.description} */}
                            {check[index]}
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}

                  <Grid item xs={2} className="col">
                    <div className="label">{t("FARM_NAME")}</div>
                    {order.orders[0].camp.name || TEXT_HOLDER}
                  </Grid>

                  {!checkStatus && (
                    <Grid item xs={5} className="col">
                      <Stepper className="content-step" alternativeLabel activeStep={statusActive}>
                        {Object.keys(OrderStatusLab)
                          .filter(
                            (i) =>
                              OrderStatusLab[i as keyof typeof OrderStatusLab] !==
                                OrderStatusLab.DRAFT &&
                              OrderStatusLab[i as keyof typeof OrderStatusLab] !==
                                OrderStatusLab.CANCELLED
                          )
                          .map((label, idx) => {
                            return (
                              <Step key={idx}>
                                <StepLabel
                                  className="content-step_label"
                                  StepIconComponent={(e) => stepIcon(e, idx)}>
                                  {t(
                                    `LAB_${OrderStatusLab[
                                      label as keyof typeof OrderStatusLab
                                    ].toUpperCase()}`
                                  )}
                                </StepLabel>
                              </Step>
                            );
                          })}
                      </Stepper>
                    </Grid>
                  )}

                  {!checkOption && (
                    <Grid item xs={2} justifyContent="flex-end" display="flex">
                      {status.length !== 1 ? (
                        <Select
                          variant="outlined"
                          className="btn-green-style btn--shiny"
                          renderValue={() => <span>{t("order:OPTIONS")}</span>}
                          displayEmpty
                          startAdornment={
                            <InputAdornment position="start">
                              <GridViewIcon />
                            </InputAdornment>
                          }
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list",
                            anchorOrigin: {
                              vertical: 60,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {order?.orders.map((o: any) => {
                            return (() => {
                              switch (o.status) {
                                case !OrderStatus.CONFIRMED && !OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("order:CANCEL_ORDER")}
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionUpdateOrder
                                                ? history.push(
                                                    APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                      ":orderId",
                                                      order.id.toString()
                                                    )
                                                  )
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("order:EDIT_ORDER")}
                                          </MenuItem>
                                        </>
                                      )}
                                    </>
                                  );
                                case OrderStatus.PROCESSING:
                                  return (
                                    <>
                                      <MenuItem
                                        onClick={() => {
                                          checkPermissionCreateOrder
                                            ? history.push(
                                                APP_ROUTES.CREATE_FEED_ID.replace(
                                                  ":orderId",
                                                  order.id.toString()
                                                )
                                              )
                                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }}>
                                        {t("order:RESET_ORDER")}
                                      </MenuItem>
                                    </>
                                  );
                                case OrderStatus.COMPLETED:
                                  return (
                                    <>
                                      <MenuItem
                                        onClick={() => {
                                          checkPermissionCreateOrder
                                            ? history.push(
                                                APP_ROUTES.CREATE_FEED_ID.replace(
                                                  ":orderId",
                                                  order.id.toString()
                                                )
                                              )
                                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }}>
                                        {t("order:RESET_ORDER")}
                                      </MenuItem>
                                    </>
                                  );
                                case OrderStatus.CREATED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionUpdateOrder
                                              ? history.push(
                                                  APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                    ":orderId",
                                                    order.id.toString()
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("order:EDIT_ORDER")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                case OrderStatus.CONFIRMED:
                                  return (
                                    <>
                                      {o.canCancelOrUpdate === true && (
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCancelOrder
                                              ? setShowCancel(true)
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("order:CANCEL_ORDER")}
                                        </MenuItem>
                                      )}
                                    </>
                                  );
                                default:
                                  return;
                              }
                            })();
                          })}
                        </Select>
                      ) : (
                        <Select
                          variant="outlined"
                          className="btn-green-style btn--shiny"
                          renderValue={() => (
                            <span style={{ color: "#fff", WebkitTextFillColor: "#fff" }}>
                              {t("order:OPTIONS")}
                            </span>
                          )}
                          displayEmpty
                          startAdornment={
                            <InputAdornment position="start">
                              <GridViewIcon />
                            </InputAdornment>
                          }
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list",
                            anchorOrigin: {
                              vertical: 60,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {(() => {
                            switch (order.status) {
                              case OrderStatusLab.DRAFT:
                                return (
                                  <>
                                    {order.orders[0]?.canCancelOrUpdate === true && (
                                      <MenuItem
                                        onClick={() => {
                                          checkPermissionUpdateOrder
                                            ? history.push(
                                                APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                  ":orderId",
                                                  order.id.toString()
                                                )
                                              )
                                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }}>
                                        {t("EDIT_REQUEST")}
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );

                              case OrderStatusLab.CREATED:
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionUpdateOrder
                                          ? history.push(
                                              APP_ROUTES.UPDATE_ORDER_LAB.replace(
                                                ":orderId",
                                                order.id.toString()
                                              )
                                            )
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("EDIT_REQUEST")}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionConfirmOrder
                                          ? setShowConfirm(OrderStatusLab.CONFIRMED)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CONFIRM_REQUEST")}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionRejectOrder
                                          ? setShowReject(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("LAB_REJECTED")}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );

                              case OrderStatusLab.CONFIRMED:
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionUpdateSample
                                          ? history.push(
                                              APP_ROUTES.UPDATE_ORDER_LAB_SAMPLE.replace(
                                                ":orderId",
                                                order.id.toString()
                                              )
                                            )
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("UPDATE_SAMPLE")}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );

                              case OrderStatusLab.PROCESSING:
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        checkPermissionCancelOrder
                                          ? setShowCancel(true)
                                          : enqueueSnackbar(t("ACCESS_DENIED"), {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }}>
                                      {t("CANCEL_REQUEST")}
                                    </MenuItem>
                                  </>
                                );

                              default:
                                return;
                            }
                          })()}
                        </Select>
                        // eslint-disable-next-line array-callback-return
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid container className="content-info">
                  <Grid item xs={3}>
                    <div className="label">{t("order:ORDER_ID")}</div>
                    <div className="value">#{order.code}</div>
                    <br /> <br />
                    {checkStatus ? (
                      <>
                        <div className="label">{t("order:TOTAL_SAMPLES")}</div>
                        <div className="value">
                          <span>{order.totalSample}</span>
                        </div>
                        <br /> <br />
                        <div className="label">{t("NOTES")}</div>
                        <div className="value e-text-ellipsis">
                          <Tooltip
                            title={order.orders[0].note || TEXT_HOLDER}
                            placement="top-start">
                            <span>{order.orders[0].note || TEXT_HOLDER}</span>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="label">{t("order:TOTAL_SAMPLES_SEND")}</div>
                        <div className="value"> {order.totalSample}</div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {checkStatus ? (
                      <>
                        <div className="label">{t("order:TARGET")}</div>
                        <div className="value">{order.target?.name || TEXT_HOLDER}</div>
                        <br /> <br />
                        <div className="label">{t("APPOINTMENT_DATE")}</div>
                        <div className="value">
                          {moment(order.orders[0]?.requiredDate).format(TIME.DAY_MONTH_YEAR)}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="label">{t("order:REQUESTER")}</div>
                        <div className="value">
                          {`${order.orders[0]?.customer?.code} - ${order.orders[0]?.customer?.fullName}`}
                        </div>
                        <br /> <br />
                        <div className="label">{t("order:TOTAL_SAMPLES_RECEIVED")}</div>
                        <div className="value">{order.totalSample}</div>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={3} className="info-status">
                    {checkStatus ? (
                      <>
                        <div className="label">{t("order:OBJECTIVE")}</div>
                        <div className="value">{order.objective?.name || TEXT_HOLDER}</div>
                        <br /> <br />
                        <div className="label">{t("CREATOR")}</div>
                        <div className="value">
                          {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                          {order.createdBy?.fullName}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="label">{t("APPOINTMENT_DATE")}</div>
                        <div className="value">
                          {moment(order.createdAt).format(TIME.DAY_MONTH_YEAR)}
                        </div>
                        <br /> <br />
                        <div className="label">{t("STATUS")}</div>
                        {StatusUIUtil.renderBgStatus(order.status, SourceType.LAB)}
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    className="info-status"
                    style={{ paddingRight: "4rem", alignItems: "start" }}>
                    {checkStatus ? (
                      <>
                        <div className="label">{t("order:REQUEST_TYPE")}</div>
                        <div className="value">
                          {order.orders[0]?.requestType?.description || TEXT_HOLDER}
                        </div>
                        <br /> <br />
                        <div className="label">{t("STATUS")}</div>
                        {StatusUIUtil.renderBgStatus(order.status, SourceType.LAB)}
                      </>
                    ) : (
                      <>
                        <div className="label">{t("TIME_UPDATE")}</div>
                        <div className="value">
                          {moment(order.updatedAt).format(TIME.DAY_MONTH_YEAR)}
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>

                <div className="content-list">
                  {!checkOutcomeSample ? (
                    <div className="list-header">
                      <div className="stt">{t("order:STT")}</div>
                      <div className="method">{t("order:TEST_METHOD")}</div>
                      <div className="pathogen">{t("order:PATHOGEN")}</div>
                      <div className="quan">{t("order:TEST_QUANTITY")}</div>
                      <div className="type">{t("order:SAMPLE_TYPE")}</div>
                      <div className="total-quan">{t("order:TOTAL_TEST_QUANTITY")}</div>
                      <div className="total">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
                      <div className="detail"></div>
                    </div>
                  ) : (
                    <div className="list-header">
                      <div className="index">{t("order:STT")}</div>
                      <div className="result">
                        {order.status === OrderStatusLab.SCHEDULED
                          ? t("SAMPLE_RESULT_BOXES")
                          : t("SAMPLE_RESULT_CONTENT")}
                      </div>
                      <div className="time">{t("SAMPLE_RESULT_TIME")}</div>
                      <div className="content">
                        {order.status === OrderStatusLab.SCHEDULED ? t("IMAGE") : t("VIEW_DETAIL")}
                      </div>
                    </div>
                  )}

                  {/* tất cả trạng thái trừ đã nhận mẫu(scheduled) và đã hoàn thành(complete) và đã nhận kết quả (received) */}
                  {!checkOutcomeSample ? (
                    <>
                      {order.orders.map((g: OrderElementModel, idx: number) => {
                        const orderLine = [...g.orderlines];
                        return (
                          <div key={idx}>
                            <div className="item">
                              <div className="stt">
                                <div className="code">
                                  {idx + 1}
                                  {checkViewSample && (
                                    <div
                                      className="code"
                                      onClick={() => {
                                        !!g.orderLabDetails.length && setVisibleDetail(g.id);
                                      }}
                                      style={{
                                        color: !!g.orderLabDetails.length ? "#0A6836" : "#A51D23",
                                        fontSize: "1.3rem",
                                        fontWeight: 400,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}>
                                      {!!g.orderLabDetails.length
                                        ? t("VIEW_SAMPLE_INFO")
                                        : t("NOT_SAMPLE_INFO")}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="method">{g.itemGroup?.name}</div>
                              <div className="pathogen">
                                {orderLine.map((i) => i.item.name).join(", ")}
                              </div>

                              <div className="quan">{Number(g.quantity)}</div>
                              <div className="type">{g.sample.name}</div>
                              <div className="total-quan">
                                {orderLine.reduce(
                                  (acc, curr) => acc + Number(curr.orderQuantity),
                                  0
                                )}
                              </div>
                              <div className="total">
                                {checkPermissionViewPrice
                                  ? currencyFormat(Number(g.orderAmount))
                                  : TEXT_ASTERISK}
                                &ensp;đ
                              </div>
                              <div className="detail">
                                <div onClick={() => setPriceList(orderLine)}>
                                  <InfoIcon style={{ fontSize: "2.5rem", color: "#272B2F" }} />
                                </div>
                              </div>
                            </div>
                            <Divider />
                          </div>
                        );
                      })}
                    </>
                  ) : order.status === OrderStatusLab.COMPLETED ||
                    order.status === OrderStatusLab.RECEIVED ? (
                    <>
                      {/* result */}
                      {!!order.orderLabOutcome.length &&
                        order.orderLabOutcome?.map((g: OrderLabOutComeResult, idx: number) => {
                          return (
                            <div key={idx}>
                              <div className="item">
                                <div className="index">
                                  <div className="code">{idx + 1}</div>
                                </div>
                                <div className="result">{g.description || TEXT_HOLDER}</div>
                                <div className="time">
                                  {moment(g.responseDate).format(
                                    TIME.DAY_MONTH_YEAR_HOUR_MINUTE_SECOND
                                  ) || TEXT_HOLDER}
                                </div>
                                <div
                                  className="content"
                                  style={{
                                    color: "#41AD49",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setVisibleSampleDetail(g.orderLabOutcomeImages)}>
                                  {t("VIEW_DETAIL")}
                                </div>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {/* result samples */}
                      {!!order?.orderLabOutcomeSample?.length &&
                        order.orderLabOutcomeSample.map((g: OrderLabOutComeSample, idx: number) => {
                          return (
                            <div key={idx}>
                              <div className="item">
                                <div className="index">
                                  <div className="code">{idx + 1}</div>
                                </div>
                                <div className="result">{g.name || TEXT_HOLDER}</div>
                                <div className="time">
                                  {moment(g.responseDate).format(
                                    TIME.DAY_MONTH_YEAR_HOUR_MINUTE_SECOND
                                  ) || TEXT_HOLDER}
                                </div>
                                <div
                                  className="content"
                                  style={{
                                    color: "#41AD49",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setVisibleResultSample(g.orderLabOutcomeImages);
                                  }}>
                                  {t("VIEW_DETAIL")}
                                </div>
                              </div>
                              <Divider />
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
                {checkViewSample && (
                  <div
                    style={{
                      marginTop: "2rem",
                    }}>
                    <span
                      style={{
                        color: "#0A6836",
                        fontWeight: 500,
                        fontSize: "1.8rem",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setVisibleDuplicate(true)}>
                      {t("DUPLICATE_SAMPLE_CODE")}
                    </span>
                  </div>
                )}
              </div>
              <div className="footer">
                <Accordion className="update-history" disabled>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={3}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("order:ACTION")}
                      </Grid>
                      <Grid item xs={2}>
                        {t("order:IMPLEMENT")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("DETAIL")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={3}>
                            {u.time}
                          </Grid>
                          <Grid item xs={3}>
                            {u.update}
                          </Grid>
                          <Grid item xs={2}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </div>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("CANCEL_REQUEST_REASON")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(`LAB_${r.label}`)}
                  className="text"
                />
              );
            })}
            {cancelValue === Reason.OTHER && (
              <>
                <div className="label asterisk">{t("REASON")}</div>
                <TextField
                  fullWidth
                  placeholder={t("INPUT_BY_NAME", { NAME: t("REASON").toLowerCase() })}
                  className="form-textarea-field"
                  multiline
                  rows={3}
                  variant="outlined"
                  color="success"
                  inputProps={{ className: "input" }}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  error={!reason}
                />
              </>
            )}
          </RadioGroup>
          <div className="btn-group" style={{ justifyContent: "center" }}>
            <button
              className="btn-cancel"
              onClick={() => {
                setShowCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                if (!reason && cancelValue === Reason.OTHER) {
                } else {
                  setShowCancel(false);
                  setSubmitCancel(true);
                }
              }}>
              {t("CANCEL_REQUEST")}
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog maxWidth="md" open={showReject}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("Lý do từ chối")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowReject(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(`LAB_${r.label}`)}
                  className="text"
                />
              );
            })}
            {cancelValue === Reason.OTHER && (
              <>
                <div className="label asterisk">{t("REASON")}</div>
                <TextField
                  fullWidth
                  placeholder={t("INPUT_BY_NAME", { NAME: t("REASON").toLowerCase() })}
                  className="form-textarea-field"
                  multiline
                  rows={3}
                  variant="outlined"
                  color="success"
                  inputProps={{ className: "input" }}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  error={!reason}
                />
              </>
            )}
          </RadioGroup>
          <div className="btn-group" style={{ justifyContent: "center" }}>
            <button
              className="btn-cancel"
              onClick={() => {
                setShowCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                if (!reason && cancelValue === Reason.OTHER) {
                } else {
                  setShowCancel(false);
                  showCancel ? setSubmitCancel(true) : setShowConfirm(OrderStatusLab.REJECTED);
                }
              }}>
              {t("Xác nhận")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={submitCancel}
        title={showCancel ? t("CONFIRM_CANCEL_ORDER_LAB") : t("Xác nhận từ chối yêu cầu")}
        subTitle={
          <span>
            {t("CANNOT_RECOVER_CANCELED_ORDER_LAB")}
            <br />
            {t("ARE_YOU_SURE_CANCEL_ORDER_LAB_BY_NAME", { NAME: `#${order?.code}` })}
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setSubmitCancel(false);
                setCancelValue(Reason.NOT_RESPONSE);
                setReason("");
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                cancelOrderLab();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setSubmitCancel(false);
        }}
      />

      <Notification
        visible={notiERP.visible}
        title={notiERP.status ? t("order:ERP_UPDATE_SUCCESSFUL") : t("order:ERP_UPDATE_FAILED")}
        subTitle={
          notiERP.status ? (
            <span>
              {t("ORDER")}
              <span style={{ color: "#41ad49" }}> #{order?.code}</span>{" "}
              {t("order:YOUR_ERP_UPDATE_SUCCESSFUL")}
            </span>
          ) : (
            <span>
              {t("ORDER")}
              <span style={{ color: "#41ad49" }}> #{order?.code}</span>{" "}
              {t("order:YOUR_ERP_UPDATE_FAILED")}
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiERP.status ? "green" : "red"}`}>
            {notiERP.status ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        actions={
          !notiERP.status && (
            <>
              <button
                ref={submitRef}
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  recallOrderVet();
                  setOpenSaleOrg(false);
                  setLoading(true);
                  setNotiERP({ visible: false, status: false });
                }}>
                {t("order:RETRY_SYNC")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  setValueRecallOrderVet(null);
                  setValueSaleOrg("");
                  setOpenSaleOrg(false);
                  setNotiERP({ visible: false, status: false });
                }}>
                {t("EXIT")}
              </button>
            </>
          )
        }
        onClose={() => {
          setValueRecallOrderVet(null);
          if (notiERP.status) {
            setTimeout(() => {
              fetchData();
              setNotiERP({ visible: false, status: true });
              setOpenSaleOrg(false);
            }, 1000);
          } else {
            setNotiERP({ visible: false, status: false });
          }
        }}
      />

      {!!showConfirm && (
        <Notification
          visible={!!showConfirm}
          title={showConfirm === OrderStatusLab.REJECTED ? t("LAB_REJECTED") : t("CONFIRM_REQUEST")}
          subTitle={
            showConfirm === OrderStatusLab.REJECTED ? (
              <span>{t("ARE_YOU_SURE_REJECT_ORDER_BY_NAME", { NAME: `#${order?.code}` })}</span>
            ) : (
              <span>{t("ARE_YOU_SURE_CONFIRM_ORDER_BY_NAME", { NAME: `#${order?.code}` })}</span>
            )
          }
          icon={
            <div className={`notification-icon-box red`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  setShowConfirm(null);
                }}>
                {t("CANCEL")}
              </button>
              <button
                ref={submitRef}
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  handleChangeStatusLab();
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            setShowConfirm(null);
          }}
        />
      )}

      {loading && (
        <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
          <Loading />
        </Backdrop>
      )}

      {permission && <NotAccess />}

      <Dialog
        maxWidth="lg"
        open={openBill}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // width: "427px",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onClose={() => {
          setOpenBill(false);
        }}>
        <Bill billData={order} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSaleOrg}
        onClose={() => {
          setOpenSaleOrg(false);
        }}>
        <div className="dialog-recall-order-detail">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "3rem",
            }}>
            <div className="title">{t("order:ORDER_SYNC")}</div>
            <ClearIcon
              style={{ fontSize: "2.2rem", cursor: "pointer" }}
              onClick={() => {
                setValueSaleOrg("");
                setValueRecallOrderVet(null);
                setOpenSaleOrg(false);
              }}
            />
          </div>
          <div className="label">{t("BUSINESS_ORGANIZATION")}</div>
          <Select
            value={valueSaleOrg}
            onChange={(e) => {
              setValueSaleOrg(e.target.value as string);
            }}
            fullWidth
            input={<OutlinedInput className="form-text-field" color="success" />}
            variant="outlined"
            className="form-select-field"
            renderValue={
              valueSaleOrg !== ""
                ? undefined
                : () => (
                    <span style={{ color: "#D6D5D6" }}>
                      {t("SELECT_BY_NAME", { NAME: t("BUSINESS_ORGANIZATION") })}
                    </span>
                  )
            }
            displayEmpty
            color="success"
            MenuProps={{
              className: "select-menu-list",
            }}>
            {salesOrgForRecall.length > 0 &&
              salesOrgForRecall.map((so, index: number) => {
                return (
                  <MenuItem key={index} value={so.salesOrg.id}>
                    {so.salesOrg?.description}
                  </MenuItem>
                );
              })}
          </Select>
          <div className="des">
            {t("order:SYNCHRONIZED_ORDERS_CANNOT_CHANGE_BUSINESS_ORGANIZATION")}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setValueSaleOrg("");
                setValueRecallOrderVet(null);
                setOpenSaleOrg(false);
              }}>
              {t("CLOSE")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              onClick={() => {
                recallOrderVet(valueSaleOrg);
              }}>
              {t("order:SYNC")}
            </button>
          </div>
        </div>
      </Dialog>

      {/* chi tiết giá */}
      <Dialog fullWidth maxWidth="md" open={!!priceList.length}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setPriceList([]);
          }}
        />
        <div className="dialog-price-list">
          <div className="header">
            <div className="title">{t("TOTAL_AMOUNT_DETAIL")}</div>
          </div>
          <div className="content">
            <div className="content-header">
              <div className="name">{t("order:PATHOGEN")}</div>
              <div className="unit">{t("UNIT_PRICE")}</div>
              <div className="quantity">{t("QUANTITY")}</div>
              <div className="price">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
            </div>
            <div className="body">
              {!!priceList.length &&
                priceList?.map((item, idx) => {
                  return (
                    <div className="content-item" key={idx}>
                      <div className="name">{item.item?.name || TEXT_HOLDER}</div>
                      <div className="unit">
                        {checkPermissionViewPrice
                          ? currencyFormat(Number(item.price))
                          : TEXT_ASTERISK}
                        &ensp;đ
                      </div>
                      <div className="quantity">{parseFloat(item.orderQuantity)}</div>
                      <div className="price">
                        {checkPermissionViewPrice
                          ? currencyFormat(Number(item.subTotal))
                          : TEXT_ASTERISK}
                        &ensp;đ
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Dialog>

      {/* chi tiết mẫu */}
      <Dialog fullWidth maxWidth="md" open={!!visibleDetail}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setVisibleDetail(null);
          }}
        />
        <div className="dialog-detail">
          <div className="header">
            <div className="title">{t("DETAIL")}</div>
          </div>
          <div className="info">
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
              }}>
              {t("order:TEST_METHOD")} : {sampleInfo?.itemGroup?.name || TEXT_HOLDER}
            </div>
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
                padding: "1rem 0 2rem",
                borderBottom: "1px solid #f5f2f2",
              }}>
              {t("order:PATHOGEN")} :{" "}
              {sampleInfo?.orderlines?.map((i) => i.item.name).join(", ") || TEXT_HOLDER}
            </div>
          </div>
          <div className="content" style={{ height: "58rem" }}>
            <div className="content-header">
              <div className="id">{t("Stt")}</div>
              <div className="name">{t("OBJECTIVE_DETAIL")}</div>
              <div className="age">{t("AGE")}</div>
              <div className="code">{t("SAMPLE_CODE")}</div>
              {sampleInfo?.itemGroup?.code === "PCRG" && (
                <div className="age" style={{ display: "flex" }}>
                  {t("TEMPLATE_SAMPLE_GROUP")}
                  <Tooltip title={t("TEMPLATE_SAMPLE_PCRG_ACCEPT_VALUE")} placement="top-end">
                    <span
                      style={{
                        paddingLeft: "1rem",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <HelpIcon />
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="body">
              {order?.orders
                .find((i) => i.id === visibleDetail)
                ?.orderLabDetails.map((item, idx) => {
                  return (
                    <div className="content-item" key={idx}>
                      <div className="id">{idx + 1}</div>
                      <div className="name">{item.objectiveDetail?.name || TEXT_HOLDER}</div>
                      <div className="age">{item.age}</div>
                      <div className="code">{item.sampleCode}</div>
                      {sampleInfo?.itemGroup?.code === "PCRG" && (
                        <div className="age">{item.mergeSample || TEXT_HOLDER}</div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal mẫu trùng */}
      <Dialog fullWidth maxWidth="md" open={!!visibleDuplicate}>
        <ClearIcon
          style={{
            fontSize: "4rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
            backgroundColor: "#EFEFEF",
            padding: "5px",
            borderRadius: "5rem",
          }}
          onClick={() => {
            setVisibleDuplicate(false);
          }}
        />
        <div className="dialog-detail">
          <div className="header">
            <div className="title">{t("DUPLICATE_SAMPLE_CODE_INFO")}</div>
          </div>
          <div
            className="content"
            style={{
              height: !!dataDuplicate.length ? "55rem" : "auto",
            }}>
            <div className="content-header" style={{ backgroundColor: "#ffffff" }}>
              <div className="code">{t("SAMPLE_CODE")}</div>
              <div className="method">{t("order:TEST_METHOD")}</div>
            </div>
            <div className="body">
              {!!dataDuplicate.length ? (
                dataDuplicate?.map((val, idx) => {
                  return (
                    <Accordion className="list-child" key={idx}>
                      <AccordionSummary
                        className="title"
                        expandIcon={
                          <ExpandMoreIcon
                            style={{
                              color: "#0A6836",
                              fontSize: "2.2rem",
                            }}
                          />
                        }>
                        <div className="content-item-duplicate">
                          <div className="code">{val.sampleCode || TEXT_HOLDER}</div>
                          <div className="method">
                            {val.item?.length} {t("Phương pháp")}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="content-item-duplicate-detail">
                          <div className="code"></div>
                          <div className="method">
                            {!!val.item?.length &&
                              val.item?.map((i, index: number) => {
                                return (
                                  <div className="method-item" key={index}>
                                    <div className="method-group">{i.itemGroup?.name}</div>
                                    <div className="method-pathogen">
                                      {!!i.items?.length &&
                                        i.items?.map((e: any, eIndex: number) => {
                                          return <div key={eIndex}>{e}</div>;
                                        })}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>
                  <img src={SampleEmpty} alt="new-order" />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1.6rem",
                      fontWeight: 500,
                    }}>
                    {t("Chưa có thông tin mẫu trùng")}
                  </div>
                </div>
              )}
            </div>
            <div className="footer_sample">
              <div className="footer_sample_content">
                {t("order:TOTAL_SAMPLES")}: {dataDuplicate.length}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* chi tiết kết quả nhân mẫu */}
      <Dialog fullWidth maxWidth="lg" open={!!visibleSampleDetail.length}>
        <ClearIcon
          style={{
            fontSize: "3rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
          }}
          onClick={() => {
            setVisibleSampleDetail([]);
            setActiveOutcome({ target: false, data: null });
          }}
        />
        <div className="dialog-sample-detail-result">
          <div className="header">
            <div className="title">{t("SAMPLE_DETAIL")}</div>
          </div>
          <div className="content">
            <div className="content-left">
              <div className="content-left_header">
                <div className="name">No.</div>
                <div className="unit">
                  {t("SELECT_BY_NAME", {
                    NAME: t("ALL").toLowerCase(),
                  })}
                </div>
              </div>
              <div className="content-left_body">
                {visibleSampleDetail.map((i, idx) => {
                  return (
                    <div className="item" key={idx}>
                      <div className="name">{i.code || TEXT_HOLDER}</div>
                      <div className="unit">
                        <Checkbox
                          checked={
                            !!activeOutcome &&
                            i.id === activeOutcome?.data?.id &&
                            activeOutcome.target
                          }
                          checkedIcon={
                            <CheckBoxIcon style={{ fontSize: "2.2rem", color: "#41ad49" }} />
                          }
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                              }}
                            />
                          }
                          onChange={(e) => {
                            const result = {
                              target: e.target.checked,
                              data: e.target.checked ? i : null,
                            };
                            setActiveOutcome(result);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="content-right">
              <div className="content-right_header">
                <div className="title">{activeOutcome?.data?.code || TEXT_HOLDER}</div>
                <div className="action">
                  <button
                    className="btn-excel"
                    onClick={() => {}}
                    type="button"
                    style={{
                      background: "rgba(10, 104, 54, 1)",
                      color: "#fff",
                      marginRight: "2rem",
                    }}>
                    <img src={Excel} alt="icon-import-excel" />
                    {!!activeOutcome?.data ? (
                      <a
                        href={BASE_LINK_IMAGE_V2 + activeOutcome?.data?.data}
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("EXPORT_DATA")}
                      </a>
                    ) : (
                      t("EXPORT_DATA")
                    )}
                  </button>
                  <ClearIcon
                    style={{ fontSize: "3.5rem", cursor: "pointer" }}
                    onClick={() => {
                      const result = {
                        target: false,
                        data: null,
                      };
                      setActiveOutcome(result);
                    }}
                  />
                </div>
              </div>
              <div className="content-right_body">
                {!!activeOutcome.data ? (
                  <Document
                    file={BASE_LINK_IMAGE_V2 + activeOutcome?.data?.data}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {Array.from(Array(numPages || 0)).map((_, index) => (
                      <Page
                        key={index}
                        pageNumber={index + 1}
                        width={Math.min(595, window.innerWidth * 0.9)}
                      />
                    ))}
                  </Document>
                ) : (
                  <img src={ProNull} alt="search" width={190} height={178} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal hình ảnh mẫu nhận */}
      <Dialog fullWidth maxWidth="md" open={!!visibleResultSample.length}>
        <ClearIcon
          style={{
            fontSize: "4rem",
            cursor: "pointer",
            margin: "2rem 2rem",
            position: "absolute",
            top: "1.6rem",
            right: "1.6rem",
            backgroundColor: "#EFEFEF",
            padding: "5px",
            borderRadius: "5rem",
          }}
          onClick={() => {
            setVisibleResultSample([]);
          }}
        />
        <div className="dialog-result-sample">
          <div className="header">
            <div className="title">{t("DETAIL_RESULT_SAMPLE")}</div>
          </div>
          <div className="content">
            <Swiper
              modules={[Pagination]}
              spaceBetween={300}
              slidesPerView={1}
              pagination={{ clickable: true }}
              loop
              autoplay={{
                delay: 1000000,
                disableOnInteraction: false,
              }}>
              {visibleResultSample?.map((item: OrderLabOutcomeImage, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="content__item">
                      <div className="content__item__image">
                        <img src={BASE_LINK_IMAGE_V2 + item.data} alt="#" />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};

export default OrderDetailLab;
