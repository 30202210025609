import ModalFooter from "@Components/ModalFooter";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { APP_ROUTES } from "@Constants";
import { Action, FetchAction, RoleModal, RoleType } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, PermissionService } from "@Services";
import { FormUtil } from "@Utils";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useReducer, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import "./CreateAcc.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  closeForm: () => void;
};

function reducer(state: PaginateFetchReducer<RoleModal>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

const CreateSaleAccount: React.FC<P_Props> = ({ closeForm }) => {
  const { t } = useTranslation(["translation", "customer"]);
  const { enqueueSnackbar } = useSnackbar();
  const [roleId, setRoleId] = useState<any>("");
  const [requireOTP, setRequireOTP] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const history = useHistory();

  const [role, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const fetchData = async (query: any) => {
    let cancel = false;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await PermissionService.getAllRole(query);
      if (!cancel)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (!cancel)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData({
      page: 1,
      limit: 200,
      roleType: RoleType.EMPLOYEE,
    });
  }, []);

  const submitForm = async (values: any) => {
    const data: Parameters<typeof CustomerService.createSignUpAccount>[0] = {
      ...values,
      roleId: roleId,
      requireOTP: false,
      isActive: isActive,
      isEmployee: true,
    } as any;
    CustomerService.createSignUpAccount(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Tạo tài khoản thành công!", {
            variant: "success",
          });
          history.push(
            APP_ROUTES.AUTHORIZE_ACCOUNT_DETAIL.replace(
              ":accountId",
              res.data?.accountId.toString()
            )
          );
          closeForm();
        } else {
          //do something
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.message?.includes("email")) {
            enqueueSnackbar("Email đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber")) {
            enqueueSnackbar("Số điện thoại đã tồn tại!", {
              variant: "error",
            });
          } else if (
            error.response.data.message === `Key (code)=(${values.code}) already exists.`
          ) {
            enqueueSnackbar("Mã đăng nhập đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber must be a string")) {
            enqueueSnackbar("Vui lòng điền SĐT", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Tạo tài khoản thất bại!", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Tạo tài khoản thất bại!", {
            variant: "error",
          });
        }
      });
  };

  return (
    <div className="create-account-modal">
      <SimpleModalHeader title={"Cấp tài khoản nhân viên"} onClose={closeForm} />
      <div className="content-modal">
        <Form onSubmit={async (values) => submitForm(values)}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="account-sale">
                <div className="form-item">
                  <div className="form-label">{t("OPERATIONAL_STATUS")}</div>
                  <div>
                    <DefaultSwitch checked={isActive} onChange={() => setIsActive(!isActive)} />
                    {isActive === true ? (
                      <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>{t("ACTIVATE")}</span>
                    ) : (
                      <span style={{ fontSize: "1.6rem", color: "#838283" }}>{t("DISABLE")}</span>
                    )}
                  </div>
                </div>
                <div className="form-item">
                  <div className="form-label">Mã đăng nhập</div>
                  <Field
                    name="code"
                    validate={FormUtil.Rule.required("Bạn chưa nhập mã đăng nhập")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          placeholder="Nhập mã đăng nhập..."
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">{t("DISPLAY_NAME")}</div>
                  <Field name="nickname" validate={FormUtil.Rule.required("Bạn chưa nhập tên")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Nhập tên đăng nhập..."}
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">Email</div>
                  <Field
                    name="email"
                    // validate={FormUtil.Rule.required("Bạn chưa nhập Email đăng nhập")}
                  >
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          placeholder={t("INPUT_BY_NAME", {
                            NAME: t("LOGIN_EMAIL").toLowerCase(),
                          })}
                          color="success"
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">SĐT</div>
                  <Field
                    name="phoneNumber"
                    // validate={FormUtil.Rule.required("Bạn chưa nhập tên đăng nhập")}
                  >
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          placeholder={t("INPUT_BY_NAME", { NAME: t("LOGIN_PHONE").toLowerCase() })}
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">Vai trò</div>
                  <Field name="roleId" validate={FormUtil.Rule.required("Bạn chưa chọn vai trò")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <FormControl style={{ width: "100%" }}>
                          <Select
                            {...input}
                            {...rest}
                            // value={salesOrg}
                            onChange={(e, value) => {
                              input.onChange(e.target.value);
                            }}
                            error={meta.error && meta.touched}
                            fullWidth
                            style={{ fontFamily: "SVN-Gotham" }}
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            variant="outlined"
                            renderValue={
                              input.value !== ""
                                ? undefined
                                : () => (
                                    <span className="form__placeholder">
                                      Chọn vai trò cho người dùng
                                    </span>
                                  )
                            }
                            displayEmpty
                            className="form-select-field"
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {role.data.data?.map((s: RoleModal) => {
                              return (
                                <MenuItem
                                  key={s.id}
                                  value={s.position}
                                  onClick={() => setRoleId(s.id)}>
                                  {s.position}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="otp">
                    <Checkbox
                      className="app-check-box"
                      checked={requireOTP}
                      onChange={() => setRequireOTP(!requireOTP)}
                    />
                    <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <ModalFooter
        formId="account-sale"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={"Cấp tài khoản "}
      />
    </div>
  );
};

export default CreateSaleAccount;
