import React from "react";
import Update from "@Assets/images/update.png";

const ComingSoon: React.FC = () => {
  return (
    <div
      className="coming-soon"
      style={{
        display: "flex",
        paddingTop: "14.5rem",
        alignItems: "center",
        flexDirection: "column",
        background: "#F0F3F8",
        height: "100vh",
        width: "100%",
        objectFit: "contain",
        fontFamily: "SVN-Gotham",
      }}>
      <div style={{ fontSize: "3.6rem", fontWeight: 700 }}>Tính năng vẫn đang được cập nhật</div>
      <div style={{ fontSize: "2rem", padding: "1.2rem 0 5rem" }}>
        Chúng tôi đang nổ lực để ra mắt tính năng này
      </div>
      <img src={Update} alt="comingsoon" width="488px" height="260px" />
    </div>
  );
};

export default ComingSoon;
