import { AppState } from "@Models";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AppState.AppNotifications = {
  list: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    // [combineActions(
    //   NotificationAcionTypes.enqueueSnackbar,
    //   NotificationAcionTypes.removeSnackbar
    // ).toString()]: (state: AppState.AppNotifications, action) => ({
    //   ...state,
    //   ...action.payload,
    // }),
    removeTest(state, action) {
      console.log(action.payload);
    },
  },
});

// Selectors

// ACTIONS
export const notiActions = notificationsSlice.actions;

//Reducers
export const NotificationReducer = notificationsSlice.reducer;
export default NotificationReducer;
