import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";
import { UpdateWarehouseTransferPayload } from "src/types";

export const WarehouseService = {
  getAll: (
    query: PaginationQueryModel & {
      from: string;
      to: string;
      searchString: string;
    }
  ) => {
    const url = REQUEST_URL.WAREHOUSE_GET_ALL;
    return RequestUtil.get({ url, query: query });
  },

  getAllPork: (
    query: any,
    itemGroupTypeId?: number,
  ) => {
    const url = REQUEST_URL.WAREHOUSE_GET_ALL_PORK.replace(':id', itemGroupTypeId?.toString() || '');
    return RequestUtil.get({ url, query: query });

  },

  getWarehouseTransfer: (
    itemGroupTypeId: number
  ) => {
    const url = `${REQUEST_URL.WAREHOUSE_GET_TRANSFER}/${itemGroupTypeId}`;
    return RequestUtil.get({ url, query: null });

  },
  updateWarehouseTransfer: (payload: UpdateWarehouseTransferPayload) => {
    const url = REQUEST_URL.WAREHOUSE_GET_TRANSFER;
    return RequestUtil.put({ url, data: payload });
  },

};
