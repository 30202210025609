import {
  GFButton,
  GFFormItem,
  GFModal,
  GFTextField,
  GFTypography,
} from "@Components/ui";

import { zodResolver } from "@hookform/resolvers/zod";
import { CustomerDuplicateGridModel } from "@Models/customer-manage.model";
import { Box, Grid} from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModalDefaultProps } from "src/types";
import { CheckCustomerDuplicatePayload, checkCustomerDuplicateSchema } from "src/schemas/customer-checkduplicate.schema";
import GFDataTable, { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { CustomerManageService } from "@Services/customer-manage.service";
import { getErrorResponse } from "@Utils";
import { useSnackbar } from "notistack";
import { DocumentSearchIcon } from "@Components/Icons/DocumentSearchIcon";

type Props = ModalDefaultProps & {
  initialValues?: CheckCustomerDuplicatePayload;
};

const columnSettings = (t: any): GFDataTableColumn<CustomerDuplicateGridModel>[] => [
  {
    title: "customerManage.employee",
    dataIndex: "employeeName",
    autoWidth: true,
  },
  {
    title: "customerManage.idNo",
    dataIndex: "idCard",
    autoWidth: true,
  },
  {
    title: "customerManage.tax",
    dataIndex: "taxId",
    autoWidth: true,
  },
  {
    title: "customerManage.phoneNumber",
    dataIndex: "phoneNumber",
    autoWidth: true,
  },
  {
    title: "customerManage.email",
    dataIndex: "email",
    autoWidth: true,
  }
];

export default function CheckDuplicateModal({
  initialValues,
  open,
  onClose,
  onConfirm,
}: Props) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm<CheckCustomerDuplicatePayload>({
    resolver: zodResolver(checkCustomerDuplicateSchema),
    defaultValues: initialValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();
  const [datatable, setDataTable] = useState<CustomerDuplicateGridModel[]>([])
  const [clicked, setClicked] = useState<boolean>(false)

  const onSubmit = (data: any) => {
    handleCheckDuplicate(data)
  };
  
  const handleCheckDuplicate = async(data: any) => {
    try {
      const response = await CustomerManageService.getDuplicateCustomer(data);
      setDataTable(response.data)
      setClicked(true)
    }
    catch(error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  }

  const columns = useMemo(() => columnSettings(t), [t]);

  return (
    <GFModal
      title={t("customerManage.checkDuplicateCustomer")}
      open={open}
      onClose={onClose}
      width="120rem"
      actions={[]}
      >
      <form id="check-duplicate-customer" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GFFormItem control={control} name="idCard">
              <GFTextField
                fullWidth
                label={t("customerManage.idNo")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.idNo")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={6}>
            <GFFormItem control={control} name="taxId">
              <GFTextField
                fullWidth
                label={t("customerManage.tax")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.tax")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={6}>
            <GFFormItem control={control} name="phoneNumber">
              <GFTextField
                fullWidth
                label={t("customerManage.phoneNumber")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.phoneNumber")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={6}>
            <GFFormItem control={control} name="email">
            <GFTextField
                fullWidth
                label={t("customerManage.email")}
                required
                placeholder={`${t("inputPlaceholder")} ${t("customerManage.email")}`}
              />
            </GFFormItem>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent={ "center" } gap={3}>
              <GFButton
                onClick={handleSubmit(onSubmit)}
                variant="contained"
                form="check-duplicate-customer"
                minWidth={15}>
                {t('customerManage.btnCheckValidate')}
              </GFButton>
            </Box>
          </Grid>

          {datatable && datatable.length > 0 ? (
            <Grid item xs={12}>
              <GFTypography color={'var(--primary-green)'}>{datatable.length < 10 ?`0${datatable.length} kết quả` : `${datatable.length} kết quả`}</GFTypography>
              <GFDataTable
                columns={columns}
                showSearchSection={false}
                dataSource={datatable}
                height="calc(100vh - 300px)"
              />
            </Grid>
          ) : (
            <>
             {clicked && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent={ "center" } gap={3}>
               
                <GFTypography color={'var(--primary-green)'}>Không tìm thấy kết quả.</GFTypography>
                </Box>
              </Grid>
              )}
              
              <Grid item xs={12}>
                <Box display="flex" justifyContent={ "center" } gap={3}>
                  <DocumentSearchIcon size={[20, 15]} viewBox={[24, 24]}  color="white"/>
                </Box>
              </Grid>
            </>
            
          )}
        </Grid>
        
      </form>
    </GFModal>
  );
}
