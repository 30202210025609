/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import IconImport from "@Assets/images/icons/ic_import.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import { InterestedIcon1 } from "@Components/Icons/InterestedIcon1";
import { InterestedIcon2 } from "@Components/Icons/InterestedIcon2";
import { InterestedIcon3 } from "@Components/Icons/InterestedIcon3";
import { InterestedIcon4 } from "@Components/Icons/InterestedIcon4";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import Notification from "@Components/Notification";
import {
  BASE_LINK_IMAGE,
  DEFAULT_STORE_INFOS,
  TEXT_ASTERISK,
  TEXT_HOLDER
} from "@Constants";
import PriceFarmFilter from "@Containers/Price/PriceFarmFilter";
import PriceSidebar from "@Containers/Price/PriceSidebar";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  Action,
  FetchAction,
  ItemPermission,
  PriceOutputModel,
  SalesOrgModal,
  SourceType,
} from "@Models";
import { SalesOfficeModal } from "@Models/sales_office.model";
import { PaginateFetchReducer } from "@Models/shared";
import { ExportService, PriceService, SalesOrgService } from "@Services";
import { SalesOfficeService } from "@Services/sales-office.service";
import {
  PermissionUtil,
  buildFarmPrice,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { Divider } from "@material-ui/core";
import {
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Dialog, Grid, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";

function reducer(state: PaginateFetchReducer<PriceOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

type P_Props = {
  sourceType: string;
};

const PriceListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer", "product"]);
  const { enqueueSnackbar } = useSnackbar();
  const checkExportRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const mounted = useRef(false);
  const [salesOrg, setSalesOrg] = useState<SalesOrgModal[]>([]);
  const [salesOffices, setSalesOffices] = useState<SalesOfficeModal[]>([]);
  const [salesOrgId, setSalesOrgId] = useState<string>();
  const [salesOfficeId, setSalesOfficeId] = useState<string>();
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [activeTabId, setActiveTabId] = useState<number>();

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchDataSalesOrg = async () => {
      SalesOrgService.getAllSalesOrg({ sources: sourceType })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setSalesOrg(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const fetchDataSalesOffice = async () => {
      SalesOfficeService.getAllSalesOfficeFarm({ sources: sourceType })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setSalesOffices(res.data.data);

            if (res.data.data.length > 0) {
              setSalesOfficeId(res.data.data[0].id.toString());
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (sourceType) {
      if (sourceType === SourceType.FARM) fetchDataSalesOffice();
      else fetchDataSalesOrg();
    }
  }, []);

  useEffect(() => {
    salesOrg && setSalesOrgId(salesOrg[0]?.id.toString());
  }, [salesOrg]);

  const handleChangeActiveTab = (id: number) => {
    setActiveTabId(id);
  };

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      let result = { data: {} };
      if (sourceType === SourceType.FARM) {
        result = await PriceService.getPriceFarmList(query);
      } else {
        result = await PriceService.getPriceList(query);
      }
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const [dataUpdate, setDataUpdate] = useState<any>(null);

  useEffect(() => {
    (salesOrgId || salesOfficeId) &&
      fetchData(
        {
          page: 1,
          limit: 50,
          source: sourceType,
          searchValue: searchValue,
          salesOrgId: salesOrgId,
          salesOfficeId: salesOfficeId,
          itemGroupTypeId: activeTabId,
        },
        true
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sourceType,
    searchValue,
    salesOrgId,
    salesOfficeId,
    activeTabId,
    success?.isExport === !isExport,
    dataUpdate === null,
  ]);

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "price",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
  }, [sourceType, salesOrgId, state.loading, success?.isExport === !isExport]);

  const exportPrice = async () => {
    ExportService.exportPrice({
      page: 1,
      limit: 500,
      source: sourceType,
      salesOrgId: salesOrgId as any,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const onFileChange = (event: any) => {
    const file: File = event.target.files[0];
    setSelectedFile(file);
  };

  const [checkErr, setCheckErr] = useState<boolean | string>(false);

  const checkFile = () => {
    const file = selectedFile?.name.slice(
      selectedFile?.name.lastIndexOf("."),
      selectedFile?.name.length
    );
    if (file !== ".xls") {
      if (file !== ".xlsx") {
        return setCheckErr("Tập tin tải lên phải có định dạng .xls hoặc .xlsx");
      }
    }
    return setCheckErr(true);
  };

  useEffect(() => {
    selectedFile && checkFile();
  }, [selectedFile]);

  const importPrice = async () => {
    if (selectedFile === null) {
      return setCheckErr("Bạn chưa chọn tập tin để tải lên");
    }
    if (checkErr === true) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      await PriceService.importPrice(formData)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setOpenUpdate(false);
            setDataUpdate(res.data);
            setSelectedFile(null);
          } else {
            enqueueSnackbar("Cập nhật giá bán sản phẩm thất bại!", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar("Cập nhật giá bán sản phẩm thất bại!", {
            variant: "error",
          });
        });
    }
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [ItemPermission.MANAGE_ITEM_EXPORT],
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="price-listing">
      <div className="price-listing-content">
        <PriceSidebar
          items={sourceType === SourceType.FARM ? salesOffices : salesOrg}
          activeId={sourceType === SourceType.FARM ? salesOfficeId : salesOrgId}
          title={t("BUSINESS_ORGANIZATION")}
          isFetching={state.fetching}
          onClick={(id: string) => {
            sourceType === SourceType.FARM ? setSalesOfficeId(id) : setSalesOrgId(id);
          }}
        />
        <div className="content">
          <div className="header">
            <div className="title">
              {sourceType === SourceType.FARM ? (
                <PriceFarmFilter 
                  activeId={activeTabId}
                  count={state.count}
                  profile={profile}
                  isFetching={state.fetching}
                  onChange={handleChangeActiveTab}
                />
              ) : (
                `${t("ALL")} (${state.totalRecords})`
              )}
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder={t("ENTER_SEARCH")}
                variant="outlined"
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              {!isExport && checkPermissionExportExcel && (
                <button
                  className="btn-excel"
                  onClick={() => {
                    exportPrice();
                    setNotiExport(true);
                    setSuccess(undefined);
                  }}>
                  <img src={Excel} alt="icon-excel" />
                  {t("EXPORT_DATA")}
                </button>
              )}
            </div>
          </div>
          {state.loading || state.fetching ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <div className={sourceType === "farm" ? "list" : "list list-feed"}>
                    {(sourceType === SourceType.FEED ||
                      sourceType === SourceType.VET ||
                      sourceType === SourceType.LAB) && (
                      <div className="list-header">
                        <div className="id">{t("PRODUCT_CODE")}</div>
                        <div className="product">{t("product:PRODUCT_NAME")}</div>
                        <div className="cus">{t("CUSTOMER")}</div>
                        <div className="price">Giá bán</div>
                        <div className="unit">Đơn vị bán</div>
                      </div>
                    )}
                    {sourceType === "farm" && (
                      <div className="list-header">
                        <div className="product">{t("PRODUCT")}</div>
                        <div className="price">
                          {t("UNIT_PRICE")}/{t("product:UNIT_MEASUREMENT")}
                        </div>
                        <div className="addition">Phần vượt</div>
                        <div className="addition">Phí giống</div>
                        <div className="weight">Định mức</div>
                        <div className="unit">Đơn vị bán</div>
                      </div>
                    )}
                    <InfiniteScroll
                      useWindow={false}
                      pageStart={0}
                      initialLoad={false}
                      hasMore={hasMore}
                      loadMore={() =>
                        fetchData({
                          limit: 50,
                          page: state.page + 1,
                          source: sourceType,
                          searchValue: searchValue,
                          salesOrgId: salesOrgId,
                        })
                      }>
                      {/* {state.fetching && searchValue && <Loading />} */}
                      {(sourceType === SourceType.FEED ||
                        sourceType === SourceType.VET ||
                        sourceType === SourceType.LAB) &&
                        state.data.map((pro: any, index: number) => {
                          return (
                            <div
                              key={pro.id + index}
                              className="item"
                              style={{
                                borderLeft: `3px solid ${
                                  pro?.item?.color ? pro?.item?.color : "transparent"
                                }`,
                              }}>
                              <div className="icon-interested">
                                {(() => {
                                  if (pro?.item?.interestedItem) {
                                    if (
                                      pro?.item?.interestedItem?.stared === false &&
                                      pro?.item?.interestedItem?.bought > 0
                                    ) {
                                      return (
                                        <InterestedIcon1
                                          color="#41AD49"
                                          size={[24, 24]}
                                          viewBox={[36, 36]}
                                        />
                                      );
                                    } else if (
                                      pro?.item?.interestedItem?.stared === true &&
                                      pro?.item?.interestedItem?.bought > 0
                                    ) {
                                      return (
                                        <InterestedIcon2
                                          color="#41AD49"
                                          size={[24, 24]}
                                          viewBox={[36, 36]}
                                        />
                                      );
                                    } else if (
                                      pro?.item?.interestedItem?.stared === false &&
                                      pro?.item?.interestedItem?.bought === 0
                                    ) {
                                      return (
                                        <div className="icon-star">
                                          <InterestedIcon4
                                            color="#41AD49"
                                            size={[13, 13]}
                                            viewBox={[15, 14]}
                                          />
                                        </div>
                                      );
                                    } else if (
                                      pro?.item?.interestedItem?.stared === true &&
                                      pro?.item?.interestedItem?.bought === 0
                                    ) {
                                      return (
                                        <div className="icon-star">
                                          <InterestedIcon3
                                            color="#41AD49"
                                            size={[13, 13]}
                                            viewBox={[15, 14]}
                                          />
                                        </div>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                              <div className="id" style={{ padding: 0 }}>
                                {pro.item.code}
                              </div>
                              <div className="product" style={{ color: pro?.item?.color }}>
                                <img
                                  src={
                                    checkLinkImage(pro.item?.image?.picture) ||
                                    renderImageProNull(sourceType)
                                  }
                                  alt="img-pro"
                                  onError={renderErrorImageItem(sourceType)}
                                />
                                {pro.item.name}
                              </div>
                              <div className="cus">
                                {state.data[index].customer?.fullName} -{" "}
                                {state.data[index].customer?.code}
                              </div>
                              <div className="price price-feed">
                                {(checkPermissionViewPrice
                                  ? currencyFormat(pro.value)
                                  : TEXT_ASTERISK) || TEXT_HOLDER}
                                đ
                              </div>
                              <div className="unit">{pro.baseUoM}</div>
                            </div>
                          );
                        })}
                      {sourceType === "farm" &&
                        state.data
                          .filter((f: any) => f.value)
                          .map((pro: any, index: number) => {
                            const prices = buildFarmPrice(pro);
                            return (
                              <div
                                key={index}
                                className="item"
                                style={{ flexDirection: "column", alignItems: "flex-end" }}>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <div className="product">
                                    <img
                                      src={
                                        checkLinkImage(pro.item.image?.picture) ||
                                        renderImageProNull(sourceType)
                                      }
                                      alt="img-pro"
                                      onError={renderErrorImageItem(sourceType)}
                                    />
                                    <span>
                                      {pro.item.name}
                                      <div className="id">{pro.item.code}</div>
                                    </span>
                                  </div>
                                  <div className="price">
                                    {prices?.unitPrice ? (
                                      <>
                                        <span style={{ color: "#41AD49" }}>
                                          {checkPermissionViewPrice
                                            ? currencyFormat(pro.value)
                                            : TEXT_ASTERISK}
                                        </span>
                                        /{pro.baseUoM}
                                      </>
                                    ) : (
                                      TEXT_HOLDER
                                    )}
                                  </div>
                                  <div className="addition">
                                    {prices?.excessPortion ? (
                                      <>
                                        <span style={{ color: "#41AD49" }}>
                                          {checkPermissionViewPrice
                                            ? currencyFormat(prices.excessPortion)
                                            : TEXT_ASTERISK}
                                        </span>{" "}
                                        đ
                                      </>
                                    ) : (
                                      TEXT_HOLDER
                                    )}
                                  </div>
                                  <div className="addition">
                                    {prices?.breedingFee ? (
                                      <>
                                        <span style={{ color: "#41AD49" }}>
                                          {checkPermissionViewPrice
                                            ? currencyFormat(prices.breedingFee)
                                            : TEXT_ASTERISK}
                                        </span>{" "}
                                        đ
                                      </>
                                    ) : (
                                      TEXT_HOLDER
                                    )}
                                  </div>
                                  <div className="weight">
                                    {pro.dimension
                                      ? Array.isArray(pro?.dimension)
                                        ? // pro.item.dimension.map((d: any) => {
                                          //     return (
                                          //       <span
                                          //         style={{
                                          //           display: "flex",
                                          //           flexDirection: "column",
                                          //         }}>
                                          //         {d[1] === null ? "Trên " + d[0] + " kg" : d[1] + " kg"}
                                          //       </span>
                                          //     );
                                          //   })
                                          TEXT_HOLDER
                                        : pro.dimension + " kg"
                                      : TEXT_HOLDER}
                                  </div>
                                  <div className="unit">con</div>
                                </div>
                                <div
                                  style={{
                                    textAlign: "end",
                                    fontSize: "1rem",
                                    color: "#5A5959",
                                  }}>
                                  Cập nhật lần cuối lúc{" "}
                                  {moment(pro.updatedAt).format("HH:mm - DD/MM/YYYY")}
                                </div>
                              </div>
                            );
                          })}
                    </InfiniteScroll>
                  </div>
                ) : visibleSearch ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>

      <NotiExportExcel
        notiExport={notiExport}
        title="Bảng giá"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />

      <Dialog fullWidth maxWidth="md" open={openUpdate}>
        <div className="import-excel">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "4rem",
            }}>
            <div className="title">Cập nhật giá bán sản phẩm</div>
            <div
              onClick={() => {
                setOpenUpdate(false);
                setSelectedFile(null);
              }}>
              <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="left">
              <button className="file-import">
                {selectedFile !== null ? (
                  <div>
                    {selectedFile.name}
                    <button
                      className="ic-click"
                      onClick={(e) => {
                        setSelectedFile(null);
                        setCheckErr(false);
                      }}>
                      <DeleteOutlineIcon style={{ fontSize: "2rem", color: "#fff" }} />
                    </button>
                  </div>
                ) : (
                  <>
                    <img src={IconImport} alt="ic_import" />
                    Kéo thả hoặc lựa chọn file
                    <br />
                    danh sách sản phẩm
                    <input type="file" onChange={onFileChange} />
                  </>
                )}
              </button>
              {typeof checkErr === "string" && <span className="err">{checkErr}</span>}
            </div>
            <div className="right">
              <div>
                <Divider style={{ marginBottom: "2.1rem" }} />
                <div className="file-link">
                  Xem file mẫu
                  <a
                    href={DEFAULT_STORE_INFOS.FILE_EXCEL_IMPORT_PRICE}
                    download="[Farm]Banggia_mau.xlsx"
                    target="_blank"
                    rel="noreferrer"
                    className="file-link-color">
                    File .xlsx mẫu
                  </a>
                </div>
                <Divider style={{ margin: "2.1rem 0 1.6rem" }} />
                Việc cập nhật lại giá cho sản phẩm sẽ thay đổi một số thông tin liên quan đến sản
                phẩm và giá trị những đơn hàng hiện tại chưa xuất hóa đơn
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn-cancel-style"
                  onClick={() => {
                    setOpenUpdate(false);
                    setSelectedFile(null);
                  }}>
                  {t("CANCEL")}
                </button>
                <button
                  className="btn-confirm-style"
                  onClick={() => {
                    importPrice();
                  }}>
                  Cập nhật
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={dataUpdate !== null}
        title={(() => {
          if (dataUpdate?.failed > 0 && dataUpdate?.succeed <= 0 && dataUpdate?.updated <= 0) {
            return "Cập nhật giá bán không thành công!";
          } else if (
            dataUpdate?.failed > 0 &&
            (dataUpdate?.succeed > 0 || dataUpdate?.updated > 0)
          ) {
            return "Cập nhật một phần!";
          } else {
            return "Cập nhật giá thành công!";
          }
        })()}
        subTitle={
          <div className="update-price-noti">
            <div>
              Kết quả cụ thể như sau cập nhật giá bán sản phẩm:
              <br />
              <br />
            </div>
            <Grid container style={{ maxWidth: "43.5rem", color: "#000000" }}>
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                className="col">
                <div>Thêm thành công</div>
                <div>Lỗi</div>
                <div>{t("UPDATE_SUCCESSFUL")}</div>
                <div>Bỏ qua</div>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                className="col">
                <div>
                  {dataUpdate?.succeed} {t("PRODUCT")}
                </div>
                <div>
                  {dataUpdate?.failed} {t("PRODUCT")}
                </div>
                <div>
                  {dataUpdate?.updated} {t("PRODUCT")}
                </div>
                <div>
                  {dataUpdate?.skipped} {t("PRODUCT")}
                </div>
              </Grid>
            </Grid>
          </div>
        }
        icon={(() => {
          if (dataUpdate?.failed > 0 && dataUpdate?.succeed <= 0 && dataUpdate?.updated <= 0) {
            return (
              <div className={"notification-icon-box"} style={{ background: "#B8292F" }}>
                <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          } else if (
            dataUpdate?.failed > 0 &&
            (dataUpdate?.succeed > 0 || dataUpdate?.updated > 0)
          ) {
            return (
              <div className={"notification-icon-box"} style={{ background: "#FFBC1F" }}>
                <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          } else {
            return (
              <div className={"notification-icon-box"} style={{ background: "#0A6836" }}>
                <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          }
        })()}
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "3rem",
              }}
              onClick={() => {
                setDataUpdate(null);
                setSelectedFile(null);
              }}>
              {t("CLOSE")}
            </button>
            {dataUpdate?.linkToErrorFile !== null && (
              <a
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                rel="noreferrer"
                href={`${BASE_LINK_IMAGE + dataUpdate?.linkToErrorFile}`}
                onClick={() => {
                  setTimeout(() => {
                    setDataUpdate(null);
                  }, 3000);
                }}>
                Tải file lỗi
              </a>
            )}
          </>
        }
        onClose={() => {
          setDataUpdate(null);
          setSelectedFile(null);
        }}
      />
    </div>
  );
};

export default PriceListing;
