import NotiExportExcel from "@Components/NotiExportExcel";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { LeadModal } from "@Models";
import { Nullable } from "@Models/shared";
import { CustomerService, ExportService } from "@Services";
import { StatusUIUtil, checkLinkImage } from "@Utils";
import { Clear as ClearIcon } from "@mui/icons-material";
import { Dialog, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

const LeadDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { leadId }: { leadId: string } = useParams();
  const [lead, setLead] = useState<Nullable<LeadModal>>(null);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [isExport, setIsExport] = useState<any>();
  const location = useLocation<any>();
  const [visibleIDCard, setVisibleIDCard] = useState<boolean>(false);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await CustomerService.getLeadById(leadId);
      if (mounted.current) {
        setLead(result.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [lead]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [leadId]);

  const exportLeadData = async () => {
    ExportService.getExportLeadData({
      id: leadId,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.dir);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog fullScreen open={true} className="lead-detail">
      <SimpleModalHeader
        title={t("customer:REGISTRATION_DETAIL")}
        onClose={() => {
          history.push({
            pathname: APP_ROUTES.LEAD,
            state: {
              startTime: location.state?.startTime,
              endTime: location.state?.endTime,
              status: location.state?.status,
            },
          });
        }}
      />
      <div className="content">
        {lead && (
          <Grid container>
            <Grid item xs={6}>
              <div className="col col-left">
                <div className="title">
                  {t("GENERAL_INFORMATION")}{" "}
                  <span style={{ fontSize: "1.6rem", fontWeight: 200, marginLeft: "2rem" }}>
                    {StatusUIUtil.renderStatusLead(lead.status) || TEXT_HOLDER}
                  </span>
                </div>
                <div className="label">{t("customer:LEAD_NAME")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead.fullName || TEXT_HOLDER} -{" "}
                  <span style={{ color: "#41AD49" }}>{lead.code}</span>
                </div>
                <div className="label">{t("EMAIL")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.email || TEXT_HOLDER}</div>
                <div className="label">{t("COMPANY")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.company || TEXT_HOLDER}</div>
                <div className="label">{t("customer:CUSTOMER_LINK")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead.customer?.fullName || TEXT_HOLDER} -{" "}
                  <span style={{ color: "#41AD49" }}>{lead.customer?.code}</span>
                </div>
                <div className="label">{t("customer:PLACE_ISSUE")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.idIssuePlace || TEXT_HOLDER}</div>
                <div className="label">{t("customer:PROVINCE_CITY")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.province || TEXT_HOLDER}</div>
                <div className="label">{t("customer:WARD_COMMUNE")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.ward || TEXT_HOLDER}</div>
                <div className="label">{t("NOTES")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.note || TEXT_HOLDER}</div>
                <div className="label">{t("customer:REGISTRATION_DATE")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {moment(lead.createdAt).format("DD/MM/YYYY HH:mm") || TEXT_HOLDER}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="col col-right">
                <div className="title title-none">{t("GENERAL_INFORMATION")}</div>
                <div className="label">{t("PHONE_NUMBER")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.phoneNumber || TEXT_HOLDER}</div>
                <div className="label">{t("BUSINESS_ORGANIZATION")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead?.salesOrg?.description || TEXT_HOLDER}
                </div>
                <div className="label">{t("TIN")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.taxId || TEXT_HOLDER}</div>
                <div className="label">CMND/CCCD</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead.idCard || TEXT_HOLDER}
                  <div
                    className="text-color"
                    onClick={() => {
                      setVisibleIDCard(true);
                    }}>
                    {t("customer:VIEW_ID_IMAGE")}
                  </div>
                </div>

                <div className="label">{t("customer:ISSUE_DATE")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead.idIssueDate ? moment(lead.idIssueDate).format("DD/MM/YYYY") : TEXT_HOLDER}
                </div>
                <div className="label">{t("customer:DISTRICT")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.district || TEXT_HOLDER}</div>
                <div className="label">{t("customer:ADDRESS")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>{lead.address || TEXT_HOLDER}</div>
                <div style={{ paddingBottom: "3.2rem", color: "transparent" }}>
                  {t("customer:NONE")}
                </div>
                <div className="label">{t("customer:REGISTRANT")}</div>
                <div style={{ paddingBottom: "3.2rem" }}>
                  {lead.creator?.name || TEXT_HOLDER} -{" "}
                  <span style={{ color: "#41AD49" }}>{lead.creator?.code}</span>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog fullWidth maxWidth="md" open={visibleIDCard}>
        <div className="card-group">
          <div className="title">
            {t("customer:ID_IMAGE")}
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setVisibleIDCard(false);
              }}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div className="card">
                {lead?.idCardFront ? (
                  <img src={checkLinkImage(lead?.idCardFront) || ""} alt="card1" />
                ) : (
                  <>
                    {/* <img src={Icon} alt="card1" className="icon" /> */}
                    {/* <div className="text">
                          Kéo thả hoặc lựa chọn ảnh
                          <br />
                          Kích thước tối đa dưới 2MB
                        </div> */}
                    <div className="text">{t("customer:NO_IMAGE")}</div>
                  </>
                )}
              </div>
              <div>{t("customer:FRONT_SIDE")}</div>
            </div>
            <div>
              <div className="card">
                {lead?.idCardFront ? (
                  <img src={checkLinkImage(lead?.idCardBack) || ""} alt="card2" />
                ) : (
                  <>
                    {/* <img src={Icon} alt="card2" className="icon" />
                        <div className="text">
                          Kéo thả hoặc lựa chọn ảnh
                          <br />
                          Kích thước tối đa dưới 2MB
                        </div> */}
                    <div className="text">{t("customer:NO_IMAGE")}</div>
                  </>
                )}
              </div>
              <div>{t("customer:BACK_SIDE")}</div>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="lead-detail-footer">
        <button className="btn-cancel-style" onClick={() => history.push(APP_ROUTES.LEAD)}>
          {t("CLOSE")}
        </button>
        {lead && (
          <button
            className="btn-confirm-style"
            style={{ marginLeft: "2.4rem" }}
            onClick={() => {
              exportLeadData();
              setNotiExport(true);
            }}>
            Tải excel
          </button>
        )}
      </div>

      <NotiExportExcel
        notiExport={notiExport}
        title={t("customer:LEAD")}
        closeForm={() => {
          setNotiExport(false);
        }}
        success={isExport ? isExport : undefined}
      />
    </Dialog>
  );
};

export default LeadDetail;
