import GFIconButton from "@Components/ui/GFIconButton";
import GFTypography from "@Components/ui/GFTypography";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";

const GFPageHeaderWrapper = styled(Box)<{ variant?: "default" | "contained" }>(({ variant }) => ({
  minHeight: "6.4rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "1px solid #BDC6D7",
  background: "white",
  ...(variant === "contained" && {
    background: "var(--primary-green)",
    color: "#fff",
  }),
}));

const GFIconButtonWrapper = styled(Box)({
  position: "absolute",
  right: "1.6rem",
  top: "1.6rem",
});

type GFPageHeaderProps = PropsWithChildren<{
  onClose?: () => void;
  title?: string;
  variant?: "default" | "contained";
}>;

export default function GFPageHeader({ children, title, variant, onClose }: GFPageHeaderProps) {
  return (
    <GFPageHeaderWrapper variant={variant}>
      {title && <GFTypography variant="h4">{title}</GFTypography>}
      {children}
      <GFIconButtonWrapper>
        <GFIconButton onClick={onClose}>
          <Close sx={{ color: variant === "contained" ? "white" : "inherit" }} />
        </GFIconButton>
      </GFIconButtonWrapper>
    </GFPageHeaderWrapper>
  );
}
