import { Grid, MenuItem, OutlinedInput, Popover, Select } from "@mui/material";
import React, { useRef, useState } from "react";
import { Form } from "react-final-form";
import { ExportService } from "@Services";
import { FormUIUtil, YearList } from "@Utils";
import { EventIcon } from "@Components/Icons/EventIcon";
import NotiExportExcel from "@Components/NotiExportExcel";
import { Month, StatusNoti, TypeNoti } from "@Constants";
import "./index.scss";
import { useTranslation } from "react-i18next";

const NotiProfile: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const checkExportRef = useRef<any>(null);
  const submitRef = useRef<any | null>(null);
  const [type, setType] = useState<string>("all");
  const [status, setStatus] = useState<string>("all");
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [success, setSuccess] = useState<any>();
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "notification",
      });
      setSuccess(() => response.data);
      if (response.data.isExport === false) {
        clearInterval(checkExportRef.current);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (values: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      const res = await ExportService.exportNoti({
        type: type,
        period: time,
        status: status,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        if (res.data.isExport === true) {
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        }
        if (submitRef.current) {
          submitRef.current.disabled = false;
        }
      }
    } catch (error: any) {
      if (submitRef.current) {
        submitRef.current.disabled = false;
      }
    }
  };

  return (
    <div className="noti-profile">
      <div className="title">Báo cáo Thông báo</div>
      <Form onSubmit={async (values) => submitForm(values)}>
        {({ handleSubmit }) => {
          return (
            <form
              onSubmit={handleSubmit}
              id="export-noti-profile"
              style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              {FormUIUtil.renderFormItem(
                "Loại thông báo",
                <Select
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value as string);
                  }}
                  fullWidth
                  input={<OutlinedInput className="form-text-field" color="success" />}
                  variant="outlined"
                  renderValue={
                    type !== ""
                      ? undefined
                      : () => <span className="form__placeholder">- Chọn Loại SMS</span>
                  }
                  displayEmpty
                  className="form-select-field"
                  MenuProps={{
                    className: "select-menu-list",
                  }}>
                  {TypeNoti.map((type, index: number) => {
                    return (
                      <MenuItem key={index} value={type.value}>
                        {type.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              <div className="form-item">
                <div className="form-label">Kỳ</div>
                <button onClick={handleClick} className="btn-white">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <div style={{ fontSize: "1.6rem" }}>
                      {month}/{year}
                    </div>
                    <EventIcon color="#5A5959" size={[16, 16]} viewBox={[20, 20]} />
                  </div>
                </button>
              </div>
              {FormUIUtil.renderFormItem(
                t("STATUS"),
                <Select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value as string);
                  }}
                  fullWidth
                  input={<OutlinedInput className="form-text-field" color="success" />}
                  variant="outlined"
                  renderValue={
                    status !== ""
                      ? undefined
                      : () => <span className="form__placeholder">- Chọn Trạng thái</span>
                  }
                  displayEmpty
                  className="form-select-field"
                  MenuProps={{
                    className: "select-menu-list",
                  }}>
                  {StatusNoti.map((status, index: number) => {
                    return (
                      <MenuItem key={index} value={status.value}>
                        {status.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              <button
                className="btn-confirm-style"
                type="submit"
                ref={submitRef}
                onClick={() => {
                  setNotiExport(true);
                  setSuccess(undefined);
                }}>
                Xuất excel
              </button>
            </form>
          );
        }}
      </Form>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <NotiExportExcel
        notiExport={notiExport}
        title="Báo cáo Thông báo"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default NotiProfile;
