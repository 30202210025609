import { GFCustomerAvatar, GFDataTable, GFLayoutWrapper, GFModal, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { ApproveOrRejectModel, CreateCustomerShippingAddressModel, CustomerManageGridModel, ICustomer, ShippingAddressDetailState } from "@Models/customer-manage.model";
import { CustomerManageService } from "@Services/customer-manage.service";
import { getErrorResponse, toClientDate } from "@Utils";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalState, ModalType } from "src/types";
import { getRequestTypeLabel, getStatusColor, getStatusLabel, customerManageFilters } from "./CustomerManage.config";
import { FILTER_DEFAULT, CUSTOMER_MANAGE_STATUS, APP_ROUTES, CUSTOMER_MANAGE_REQUEST_TYPE, APPROVAL_PERSON } from "@Constants";
import { useHistory } from "react-router-dom";
import CreateOrUpdateCustomerShippingAddress from "./CreateShippingAddress";
import { CreateCustomerShippingAddressPayload } from "src/schemas/customer-shipping-address.schema";
import GFFloatingButton from "@Components/ui/GFFloatingButton";
import { Add, Edit } from "@mui/icons-material";
import { CreateShippingAddressIcon } from "@Components/Icons/CreateShippingAddressIcon";
import { useCustomerManageInfinite } from "src/app/queries/customer-manage.query";
import CustomerShippingAddressDetail from "./ShippingAddressDetail";

const columnSettings = (t: any): GFDataTableColumn<CustomerManageGridModel>[] => [
  {
    title: "customerManage.requestCode",
    dataIndex: "code",
    width: 150
  },
  {
    title: "customerManage.customer",
    dataIndex: "customer",
    width: 350,
    render: (cellData) => {
      return <GFCustomerAvatar fullName={cellData.name} code={cellData.code} />
    },
  },
  {
    title: "customerManage.dateCreate",
    dataIndex: "createdAt",
    width: 150,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{toClientDate(cellData)}</GFTypography>;
    },
  },
  {
    title: "customerManage.phoneNumber",
    dataIndex: "phoneNumber",
    width: 150,
  },
  {
    title: "customerManage.address",
    dataIndex: "shippingAddress",
    width: 350,
  },
  {
    title: "customerManage.email",
    dataIndex: "email",
    width: 250,
    autoWidth: true,
  },
  {
    title: "customerManage.businessUnit",
    dataIndex: 'saleOrg',
    width: 220,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{cellData}</GFTypography>;
    },
  },
  {
    title: "customerManage.itemGroupType",
    dataIndex: 'itemGroupType',
    autoWidth: true,
    width: 220,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{cellData}</GFTypography>;
    },
  },
  {
    title: "customerManage.requestType",
    dataIndex: "type",
    width: 150,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{t(getRequestTypeLabel(cellData))}</GFTypography>;
    },
  },
  {
    title: "customerManage.status",
    dataIndex: "status",
    autoWidth: true,
    width: 150,
    render: (cellData) => {
      return <GFTypography color={getStatusColor(cellData)} fontWeight={600}>{getStatusLabel(t, cellData)}</GFTypography>;
    },
  },
];

const canAcctionStatuses = [CUSTOMER_MANAGE_STATUS.DRAFT]

const CustomerManage = () => {

  const [searchValue, setSearchValue] = useState<string>("")
  const [activeFilter, setActiveFilter] = useState<string>(FILTER_DEFAULT.ALL)
  
  const {
    data,
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
    total,
    refetch,
    countFilter
   } = useCustomerManageInfinite({
    limit: 10,
    strSearch: searchValue,
    status: activeFilter
  })

  const onSearch = (value: string) => {
    setSearchValue(value)
  }

  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });

  const [modalShippingAddress, setModalShippingAddress] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });


  const history = useHistory();

  const [modalShippingAddressDetail, setModalShippingAddressDetail] = useState<ShippingAddressDetailState>({
    isOpen: false
  })


  const isOpenCreateOrUpdateModalShippingAdress =
    modalShippingAddress.open && (modalShippingAddress.modalType === ModalType.Create || modalShippingAddress.modalType === ModalType.Update);

  const isOpenDeleteModal = modal.open && modal.modalType === ModalType.Delete;
  const [masterData, setMasterData] = useState({})
  const [customers, setCustomers] = useState<ICustomer[]>([])

  useEffect( () => {
    const fetchData = async () => {
      try {
        const response = await CustomerManageService.getMasterData();
        setMasterData(response.data)
      } catch (error) {
        console.error('Error fetching data source:', error);
      }
    };

    const fetchCustomer = async () => {
      try {
        const response = await CustomerManageService.getDataSourceCustomers();
        if (response && response.data && response.data.data) {
          const customers: ICustomer[] = response.data.data.map((item: any) => {
            return {
              value: item.code,
              label: item.fullName,
              id: item.id
            }
          })

          setCustomers(customers)
        }
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    }
  
    fetchData();
    fetchCustomer();
  }, [])

  const filters = useMemo(() => customerManageFilters.map(item => {
    item.label = t(item.label)
    item.counter = countFilter && countFilter[item.value]
    return item;
  }), [countFilter]);

  /**START - Logic handle create/update customer shipping address */
  const handleOpenCreateModalShippingAddress = () => {
    setModalShippingAddress({
      open: true,
      selectedId: null,
      modalType: ModalType.Create,
    });
  };

  const handleOpenUpdateModalShippingAddress = async (id: number, record: any) => {
    const data = await CustomerManageService.getCustomerRequestById(id)
    if (data) {
      const initUpdateData: CreateCustomerShippingAddressPayload = {
        customerCode: data.data.customerCode,
        district: data.data.address[0].district?.code,
        province: data.data.address[0].province?.code,
        shippingAddress: data.data.address[0].shippingAddress,
      }
      setModalShippingAddress({
        open: true,
        selectedId: id,
        modalType: ModalType.Update,
        initUpdateData: initUpdateData,
      });
    }
    
  };

  const handleCloseModalShippingAddress = () => {
    setModalShippingAddress({
      open: false,
      selectedId: null,
      initUpdateData: null,
      modalType: ModalType.Create,
    });
  };

  const handleConfirmShippingAddress = (data: CreateCustomerShippingAddressPayload, type: ModalType, isDraft?: boolean) => {
    if (type === ModalType.Create) {
      handleSaveCustomerShippingAddress(data, isDraft);
    } else {
      handleUpdateCustomerShippingAddress(data, undefined, isDraft);
    }
  };

  const handleSaveCustomerShippingAddress = async (data: CreateCustomerShippingAddressPayload, isDraft?: boolean) => {
    try {
      setModal((prev) => ({ ...prev, loading: true }));
      const payload: CreateCustomerShippingAddressModel = {
        type: CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS,
        status: isDraft ? CUSTOMER_MANAGE_STATUS.DRAFT : CUSTOMER_MANAGE_STATUS.SUBMIT,
        addShippingAddressParms: {
          customerCode: data.customerCode,
          address: [{
            province: data.province,
            district: data.district,
            shippingAddress: data.shippingAddress
          }]
        }
      }

      await CustomerManageService.createShippingAddress(payload);
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      refetch()
      handleCloseModalShippingAddress();
    } catch (error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateCustomerShippingAddress = async (data: CreateCustomerShippingAddressPayload, id?: number, isDraft?: boolean) => {
    try {
      const updateId = id || modalShippingAddress.selectedId;
      if (!updateId) return;

      setModalShippingAddress((prev) => ({ ...prev, loading: true }));

      const payload: CreateCustomerShippingAddressModel = {
        type: CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS,
        status: isDraft ? CUSTOMER_MANAGE_STATUS.DRAFT : CUSTOMER_MANAGE_STATUS.SUBMIT,
        addShippingAddressParms: {
          customerCode: data.customerCode,
          address: [{
            province: data.province,
            district: data.district,
            shippingAddress: data.shippingAddress
          }]
        }
      }

      await CustomerManageService.updateShippingAddress(updateId as number, payload);

      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });

      refetch()
      handleCloseModalShippingAddress();
    } catch (error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleChangeRequest = async(id?: number) => {
    if (!id) return
    try {
      const payload = {
        status: CUSTOMER_MANAGE_STATUS.SUBMIT
      }
      await CustomerManageService.changeStatus(id, payload);
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      handleCloseModalShippingAddressDetail()
      refetch()
    }
    catch(error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  }

  const handleApproveOrReject = async(id: number, type: APPROVAL_PERSON, data: ApproveOrRejectModel) => {
    try {
      await CustomerManageService.approveOrReject(id, type, data);
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      handleCloseModalShippingAddressDetail()
      refetch()
    }
    catch(error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  }

  /**END - Logic handle create/update customer shipping address */

  const handleOpenCreateModal = () => {
    history.push(APP_ROUTES.CREATE_CUSTOMER_MANAGE)
  };

  const handleOpenModifyCustomer = () => {
    history.push(APP_ROUTES.MODIFY_CUSTOMER_MANAGE)
  };

  const handleOpenUpdateModal = (id: number, record: any) => {
    if (record.type === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS) {
      handleOpenUpdateModalShippingAddress(id, record)
    }
    else if (record.type === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE) {
      history.push(APP_ROUTES.UPDATE_CUSTOMER_MANAGE.replace(":customerId", id.toString()))
    }
    else {
      history.push(APP_ROUTES.MODIFY_CUSTOMER_MANAGE_EDIT.replace(":customerId", id.toString()))
    }
  };

  const handleOpenDeleteModal = (id: number) => {
    setModal({
      open: true,
      selectedId: id,
      modalType: ModalType.Delete,
    });
  };

  const handleCloseModal = () => {
    setModal({
      open: false,
      selectedId: null,
      initUpdateData: null,
      modalType: ModalType.Create,
    });
  };

  const handleDeleteCustomerManage = async () => {
    try {
      if (!modal.selectedId) return;

      setModal((prev) => ({ ...prev, loading: true }));

      await CustomerManageService.delete(modal.selectedId as number);

      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });

      handleCloseModal();
      refetch()
    } catch (error) {
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const columns = useMemo(() => columnSettings(t), [t]);

  const handleFilter = (value: any) => {
    setActiveFilter(value)
  }

  const menuItems = [
    {
      title: t("customerManage.create"),
      icon: <Add />,
      onClick: () => handleOpenCreateModal(),
    },
    {
      title:  t("customerManage.update"),
      icon: <Edit />,
      onClick: () => handleOpenModifyCustomer(),
    },
    {
      title:  t("customerManage.createAddress"),
      icon: <CreateShippingAddressIcon color={"white"} size={[24, 24]} viewBox={[24, 24]} />,
      onClick: () => handleOpenCreateModalShippingAddress(),
    },
  ];

  const handelOpenModalShippingAddressDetail = async (code: string | number | null, record: any) => {
    try {
      const response = await CustomerManageService.getCustomerRequestById(record.id);
      setModalShippingAddressDetail({
        isOpen: true,
        data: {
          ...response.data,
          id: record.id
        }
      })
    } catch (error) {
      console.error('Error fetching data source:', error);
    }
  }

  const handleCloseModalShippingAddressDetail = () => {
    setModalShippingAddressDetail({
      isOpen: false,
      data: undefined
    });
  };

  const handleRowClick = (code: string | number | null, record: any) => {
    if (record.type === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS) {
      handelOpenModalShippingAddressDetail(code, record)
    }
    else {
      history.push(APP_ROUTES.CUSTOMER_MANAGE_DETAIL.replace(":customerId", record.id))
    }
  }

  return (
    <GFLayoutWrapper>
      {isOpenCreateOrUpdateModalShippingAdress && (
        <CreateOrUpdateCustomerShippingAddress
          open={isOpenCreateOrUpdateModalShippingAdress}
          onClose={handleCloseModalShippingAddress}
          onConfirm={handleConfirmShippingAddress}
          modalType={modalShippingAddress.modalType}
          initialValues={modalShippingAddress.initUpdateData}
          loading={modalShippingAddress.loading}
          masterData={masterData}
          customers={customers}
        />
      )}

      {modalShippingAddressDetail.isOpen && (
        <CustomerShippingAddressDetail
          open={modalShippingAddressDetail.isOpen}
          onClose={handleCloseModalShippingAddressDetail}
          data={modalShippingAddressDetail.data}
          onChangeRequest={handleChangeRequest}
          onApproveOrReject={handleApproveOrReject}
        />
      )}

      {isOpenDeleteModal && (
        <GFModal
          title={t("confirmInformationTitle")}
          open={isOpenDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDeleteCustomerManage}
          loading={modal.loading}>
          <GFTypography>{t("customerManage.deleteCustomerManageConfirmation")}</GFTypography>
        </GFModal>
      )}

      <GFDataTable
        columns={columns}
        dataSource={data}
        hasMore={hasNextPage}
        onLoadMore={fetchNextPage}
        showDateRange={false}
        showExcelButton={false}
        showCreateButton={false}
        onCreate={handleOpenCreateModal}
        activeFilter={activeFilter}
        filters={filters}
        onFilter={handleFilter}
        showSearchSection={true}
        onRowClick={handleRowClick}
        showActions={{
          showActionDeleteButton(record) {
            return canAcctionStatuses.includes(record.status);
          },
          showActionEditButton(record) {
            return canAcctionStatuses.includes(record.status);
          },
          onActionEdit(id, record) {
            handleOpenUpdateModal(id, record);
          },
          onActionDelete(id, record) {
            handleOpenDeleteModal(id);
          },
        }}
        searchValue={searchValue}
        searchPlaceholder={t('customerManage.searchPlaceholder')}
        onSearch={onSearch}
        loading={isLoading}
        height="calc(100vh - 300px)"
      />
      <GFFloatingButton visible={false} menuItems={menuItems} />
    </GFLayoutWrapper>
  );
}

export default CustomerManage