/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { APP_ROUTES, NavHeaderSubAccount, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { AccountModel, Action, FetchAction, SubAccountModel, SubAccountPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { FiberManualRecord as DotIcon, Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { CustomerService, ExportService } from "@Services";
import { PermissionUtil } from "@Utils";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import "./index.scss";

type P_Props = {
  accountType: string;
};

function reducer(state: PaginateFetchReducer<AccountModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const SubAccount: React.FC<P_Props> = ({ accountType }) => {
  const { t } = useTranslation(["translation", "customer"]);
  const checkExportRef = useRef<any>(null);
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<any>("");
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, setRefresh] = useState<boolean>(false);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);
  useEffect(() => {
    setRefresh(true);
    if (!!location?.state?.selectTab) {
      setSelectTab(location.state?.selectTab);
    } else {
      setSelectTab("ALL");
    }
  }, [location]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllSubAccount(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    if (refresh) {
      fetchData(
        {
          page: 1,
          limit: 50,
          strSearch: searchValue,
          status: selectTab,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectTab, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "customerAccount",
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTab, state.loading, success?.isExport === !isExport]);

  const exportCus = async () => {
    ExportService.exportAccount()
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [SubAccountPermission.MANAGE_SUBACCOUNT_EXPORT],
    true
  );

  const checkPermissionSubAccountCreate = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_ADD,
    true
  );

  const checkPermissionSubAccountViewDetail = PermissionUtil.check(
    profile?.role?.permissions,
    SubAccountPermission.MANAGE_SUBACCOUNT_VIEWDETAILS,
    true
  );

  return (
    <div className="sub-account">
      <div className="account-content">
        <div className="action">
          <div className="account__nav-header">
            {NavHeaderSubAccount.map((o, idx: number) => {
              return (
                <div
                  key={o.id + idx}
                  className={
                    // cancel
                    //   ? o.value === "cancelled"
                    //     ? "tab tab-active"
                    //     : "tab tab-hover"
                    //   :
                    o.value === selectTab ? "tab tab-active" : "tab tab-hover"
                  }
                  onClick={() => {
                    setSelectTab(o.value);
                    // if (o.title === "cancelled") {
                    //   setCancel(true);
                    // } else {
                    //   setCancel(false);
                    // }
                  }}>
                  {t(`SUB_${o.label}`)} {o.title && <span>{state?.count?.[o.title]}</span>}
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            {!isExport && checkPermissionExportExcel && (
              <button
                className="btn-excel"
                onClick={() => {
                  exportCus();
                  setNotiExport(true);
                  setSuccess(undefined);
                }}>
                <img src={Excel} alt="icon-excel" />
                {t("EXPORT_DATA")}
              </button>
            )}
          </div>
        </div>
        <div className="list">
          <div className="list-header">
            <div className="name">{t("customer:SUB_ACCOUNT_NAME")}</div>
            <div className="code">{t("LOGIN_CODE")}</div>
            <div className="phone">{t("PHONE_NUMBER")}</div>
            <div className="email">{t("EMAIL")}</div>
            <div className="name">{t("customer:MAIN_ACCOUNT")}</div>
            <div className="role">{t("ROLE")}</div>
            <div className="status">{t("STATUS")}</div>
            <div className="policy">{t("POLICY")}</div>
          </div>
          {state.loading || (state.fetching && (selectTab || searchValue)) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        strSearch: searchValue,
                        status: selectTab,
                      })
                    }>
                    {state.data.map((act: SubAccountModel) => {
                      return (
                        <div
                          className="item"
                          key={act.code}
                          onClick={() => {
                            checkPermissionSubAccountViewDetail
                              ? history.push({
                                  pathname: APP_ROUTES.SUB_ACCOUNT_DETAIL.replace(
                                    ":accountId",
                                    act.id.toString()
                                  ),
                                  state: {
                                    selectTab: selectTab,
                                  },
                                })
                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                  variant: "error",
                                  preventDuplicate: true,
                                });
                          }}>
                          <div className="name ellipsis">
                            <Tooltip title={act.nickname} placement="top-start">
                              <span>{act.nickname}</span>
                            </Tooltip>
                          </div>
                          <div className="code">{act.code}</div>
                          <div className="phone">{act.phoneNumber || TEXT_HOLDER}</div>
                          <div className="email ellipsis">
                            <Tooltip title={act.email || TEXT_HOLDER} placement="top-start">
                              <span>{act.email || TEXT_HOLDER}</span>
                            </Tooltip>
                          </div>
                          <div className="name">{act.parentAccount?.nickname}</div>
                          <div className="role">{act.role?.position || TEXT_HOLDER}</div>
                          <div className={`status ${act.status.toLowerCase()}`}>
                            <DotIcon style={{ fontSize: "1rem" }} />{" "}
                            {t(`SUB_${act.status.toUpperCase()}`)}
                          </div>
                          <div
                            className={`policy ${act.policyAccepted ? "accepted" : "notAccepted"}`}>
                            <DotIcon style={{ fontSize: "1rem" }} />{" "}
                            {act.policyAccepted ? t("CONFIRM") : t("NOT_CONFIRM")}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )}
            </>
          )}
        </div>
      </div>

      {checkPermissionSubAccountCreate && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            history.push("create-" + accountType);
            // setOpenAccountModal(true);
          }}
          btns={[]}
        />
      )}

      <NotiExportExcel
        notiExport={notiExport}
        title={t("SUB_ACCOUNT")}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default SubAccount;
