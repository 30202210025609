import { Menu } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Menu)({
  "& .MuiMenu-paper": {
    minWidth: "12rem",
    borderRadius: "0.8rem",
    boxShadow: "0px 0px 0.8rem rgba(0, 0, 0, 0.1)",
  },
});
