export type TCb = {
  success?: (data?: any) => void;
  fail?: (data?: any) => void;
  error?: (data?: any) => void;
};

export type IconModel = {
  color: string;
  size: [number, number]; // mang kich thuoc x,y (width, height)
  viewBox?: [number, number]; // mang kich thuoc x,y (width, height)
  className?: string;
  style?: React.CSSProperties;
  options?: {
    color: string;
    backgroundColor: string;
  };
};

export type PaginationQueryModel = {
  page: number;
  limit: number;
  source?: string;
};

export type PaginationModel = {
  page: number;
  limit: number;
};

export type PaginatedQueryDto = Pick<PaginationQueryModel, "page" | "limit"> & {
  searchValue?: string;
};

export type GetListPriceShippingQuery = PaginatedQueryDto & {
  from?: string;
  to?: string;
  itemGroupTypeId?: number;
  isInternal: boolean;
}

export type PaginatedResponseDto<T> = {
  page: number;
  totalPages: number;
  totalRecords: number;
  data: T[];
};

export type PaginationQueryModelPartner = {
  pageNumber: number;
  pageLimit: number;
};

export type ActionState = {
  type: string;
  payload: {
    data: any;
    complete?: () => void;
    error?: (err: any) => void;
  };
};

export type FetchingPaginationDataModel<T> = {
  page: number;
  totalPages: number;
  limit: number;
  totalRecords: number;
  data: T[];
  loading: boolean;
};

export type FetchingDataModel<T> = {
  data: T;
  loading: boolean;
};

export enum FORM_MODE {
  CREATE,
  EDIT,
  VIEW,
}

/**
 * dùng cho <ImageUpload />
 */
export type UploadedImageModel = {
  id?: string;
  file: any;
  fileStr: any;
  alt?: string;
};

export type FormUploadImageModel = {
  id: string;
  src: string;
  alt?: string;
};

export const Action = {
  FETCH: "FETCH",
  SUCCESS: "FETCH_SUCCESS",
  FAILED: "FETCH_FAILED",
  RESET: "FETCH_RESET",
  LOGOUT: "LOGOUT",
} as const;

export type ValueOf<T> = T[keyof T];

export type FetchAction = {
  type: ValueOf<typeof Action>;
  payload: any;
};

export type FetchReducer<T> = {
  data: T[];
  fetching: boolean;
  failMessage: string;
};

export enum ManageRegistration {
  REFUSE = "refuse",
  NOT_APPROVED = "not approved",
  APPROVED = "approved",
}

export enum RegistrationArray {
  FARM = "farm",
  FEED = "feed",
  CCU = "ccu",
}

export enum SourceType {
  FARM = "farm",
  FEED = "feed",
  VET = "vet",
  LAB = "lab",
  CCU = "ccu",
}

export enum AccountType {
  ACCOUNT = "account",
  SUB_ACCOUNT = "sub-account",
}

export enum Direction {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum LeadStatus {
  DENIED = "denied",
  PENDING = "pending",
  APPROVED = "approved",
}

export enum SubAccountStatus {
  OPENING = "opening",
  PENDING = "pending",
  ACTIVATED = "activated",
  LOCKED = "locked",
  CANCELLED = "cancelled",
}

export enum Reason {
  NOT_RESPONSE = "notResponse",
  CHANGE_OF_MIND = "changeOfMind",
  DUPLICATED = "duplicated",
  NOT_ENOUGH = "notEnough",
  CUSTOMER_SUGGEST = "customerSuggest",
  OTHER = "other",
}

export enum CancelOrCompleteOrder {
  CANCEL = "Hủy đơn hàng",
  COMPLETE = "Đóng đơn hàng",
}

export enum CustomerPermission {
  MANAGE_CUS_VIEW_LIST = "MANAGE-CUS_VIEW-LIST",
  MANAGE_CUS_VIEW_INFORMATION_DETAILS = "MANAGE-CUS_VIEW-INFORMATION-DETAILS",
  MANAGE_CUS_ORDER_LIST = "MANAGE-CUS_ORDER-LIST",
  MANAGE_CUS_DEBT = "MANAGE-CUS_DEBT",
  MANAGE_CUS_EXPORT = "MANAGE-CUS_EXPORT",
}

export enum OrderPermission {
  MANAGE_ORDER_VIEWLIST = "MANAGE-ORDER_VIEWLIST",
  MANAGE_ORDER_VIEWDETAILS = "MANAGE-ORDER_VIEWDETAILS",
  MANAGE_ORDER_DELIVERY_ORDER_VIEWDETAILS = "MANAGE-ORDER-DELIVERY-ORDER_VIEWDETAILS",
  MANAGE_ORDER_ADD = "MANAGE-ORDER_ADD",
  MANAGE_ORDER_ADD_FOR_LEAD = "MANAGE-ORDER_ADD-FOR-LEAD",
  MANAGE_ORDER_ALLOCATE_CUSTOMER = "MANAGE-ORDER_ALLOCATE-CUSTOMER",
  MANAGE_ORDER_UPDATE = "MANAGE-ORDER_UPDATE",
  MANAGE_ORDER_SPLIT = "MANAGE-ORDER_SPLIT",
  MANAGE_ORDER_ALLOCATE = "MANAGE-ORDER_ALLOCATE",
  MANAGE_ORDER_VIEW_ORDER_SO = "MANAGE-ORDER_VIEW-ORDER-SO",
  MANAGE_ORDER_RECALL = "MANAGE-ORDER_RECALL",
  MANAGE_ORDER_CANCEL = "MANAGE-ORDER_CANCEL",
  MANAGE_ORDER_GROUP_CANCEL = "MANAGE-ORDER-GROUP_CANCEL",
  MANAGE_ORDER_ALLOCATE_CANCEL = "MANAGE-ORDER-ALLOCATE_CANCEL",
  MANAGE_ORDER_COMPLETE = "MANAGE-ORDER_COMPLETE",
  MANAGE_ORDER_GROUP_COMPLETE = "MANAGE-ORDER-GROUP_COMPLETE",
  MANAGE_ORDER_ALLOCATE_COMPLETE = "MANAGE-ORDER-ALLOCATE_COMPLETE",
  MANAGE_ORDER_EXPORT = "MANAGE-ORDER_EXPORT",
  MANAGE_ORDER_REJECT = "MANAGE-ORDER_REJECT",
  MANAGE_ORDER_UPDATE_SAMPLE = "MANAGE-ORDER_UPDATE-SAMPLE",
  MANAGE_ORDER_CONFIRM = "MANAGE-ORDER_CONFIRM",
  MANAGE_ORDER_SEND_SAMPLE = "MANAGE-ORDER_SEND-SAMPLE",
  MANAGE_ORDER_VIEW_PRICE = "MANAGE-ORDER_VIEW-PRICE",
  MANAGE_ORDER_FEEDBACK = "MANAGE-ORDER_FEEDBACK",
  MANAGE_ORDER_FEEDBACK_REPLY = "MANAGE-ORDER_FEEDBACK-REPLY",
}

export enum ItemPermission {
  MANAGE_ITEM_VIEWLIST = "MANAGE-ITEM_VIEWLIST",
  MANAGE_ITEM_VIEWDETAILS = "MANAGE-ITEM_VIEWDETAILS",
  MANAGE_ITEM_ADD_AVT = "MANAGE-ITEM_ADD-AVT",
  MANAGE_ITEM_UPDATE = "MANAGE-ITEM_UPDATE",
  MANAGE_ITEM_ADD_INFORMATION = "MANAGE-ITEM_ADD-INFORMATION",
  MANAGE_ITEM_EXPORT = "MANAGE-ITEM_EXPORT",
  MANAGE_PRICE_TABLE_VIEWLIST = "MANAGE-PRICE-TABLE_VIEWLIST",
  MANAGE_PRICE_UPDATE = "MANAGE-PRICE_UPDATE",
  MANAGE_PRICE_TABLE_EXPORT = "MANAGE-PRICE-TABLE_EXPORT",
  MANAGE_PRICE_TABLE_IMPORT = "MANAGE-PRICE-TABLE_IMPORT",
  MANAGE_ITEM_VIEW_PRICE = "MANAGE-ITEM_VIEW-PRICE",
}
export enum LeadPermissions {
  MANAGE_LEAD_ADD = "MANAGE-LEAD_ADD",
  MANAGE_LEAD_VIEWLIST = "MANAGE-LEAD_VIEWLIST",
  MANAGE_LEAD_VIEWDETAILS = "MANAGE-LEAD_VIEWDETAILS",
  MANAGE_LEAD_EXPORT = "MANAGE-LEAD_EXPORT",
}

export enum EmployeePermission {
  MANAGE_EMPLOYEE_VIEW_LIST = "MANAGE-EMPLOYEE_VIEW-LIST",
  MANAGE_EMPLOYEE_VIEW_DETAILS = "MANAGE-EMPLOYEE_VIEW-DETAILS",
  MANAGE_EMPLOYEE_ACCOUNT_VIEW_LIST = "MANAGE-EMPLOYEE-ACCOUNT_VIEW-LIST",
  MANAGE_EMPLOYEE_ACCOUNT_VIEW_DETAILS = "MANAGE-EMPLOYEE-ACCOUNT_DETAILS",
  MANAGE_EMPLOYEE_ACCOUNT_ADD = "MANAGE-EMPLOYEE-ACCOUNT_ADD",
  MANAGE_EMPLOYEE_ACCOUNT_EXPORT = "MANAGE-EMPLOYEE-ACCOUNT_EXPORT",
  MANAGE_EMPLOYEE_ACCOUNT_EDIT = "MANAGE-EMPLOYEE-ACCOUNT_EDIT",
  MANAGE_EMPLOYEE_ACCOUNT_RESET_PASSWORD = "MANAGE-EMPLOYEE-ACCOUNT_RESET-PASSWORD",
}
export enum OrderPlanPermission {
  MANAGE_ORDERPLAN_VIEWLIST = "MANAGE-ORDERPLAN_VIEWLIST",
  MANAGE_ORDERPLAN_VIEWDETAILS = "MANAGE-ORDERPLAN_VIEWDETAILS",
  MANAGE_ORDERPLAN_ADD = "MANAGE-ORDERPLAN_ADD",
  MANAGE_ORDERPLAN_UPDATE = "MANAGE-ORDERPLAN_UPDATE",
  MANAGE_ORDERPLAN_UPDATE_STATUS = "MANAGE-ORDERPLAN_UPDATE-STATUS",
  MANAGE_ORDERPLAN_EXPORT = "MANAGE-ORDERPLAN_EXPORT",
  MANAGE_ORDERPLAN_ADAPT = "MANAGE-ORDERPLAN_ADAPT",
}

export enum SubAccountPermission {
  MANAGE_SUBACCOUNT_VIEWLIST = "MANAGE-SUBACCOUNT_VIEWLIST",
  MANAGE_SUBACCOUNT_VIEWDETAILS = "MANAGE-SUBACCOUNT_VIEWDETAILS",
  MANAGE_SUBACCOUNT_ADD = "MANAGE-SUBACCOUNT_ADD",
  MANAGE_SUBACCOUNT_UPDATE_STATUS = "MANAGE-SUBACCOUNT_UPDATE-STATUS",
  MANAGE_SUBACCOUNT_EXPORT = "MANAGE-SUBACCOUNT_EXPORT",
  MANAGE_SUBACCOUNT_UPDATE = "MANAGE-SUBACCOUNT_UPDATE",
  MANAGE_SUBACCOUNT_RESET_PASSWORD = "MANAGE-SUBACCOUNT_RESET-PASSWORD",
}

export enum AccountCustomerPermission {
  MANAGE_CUSTOMER_ACCOUNT_VIEW_LIST = "MANAGE-CUSTOMER-ACCOUNT_VIEW-LIST",
  MANAGE_CUSTOMER_ACCOUNT_VIEW_DETAILS = "MANAGE-CUSTOMER-ACCOUNT_VIEW-DETAILS",
  MANAGE_CUSTOMER_ACCOUNT_ADD = "MANAGE-CUSTOMER-ACCOUNT_ADD",
  MANAGE_CUSTOMER_ACCOUNT_CHANGE_STATUS = "MANAGE-CUSTOMER-ACCOUNT_CHANGE-STATUS",
  MANAGE_CUSTOMER_ACCOUNT_UPDATE = "MANAGE-CUSTOMER-ACCOUNT_UPDATE",
  MANAGE_CUSTOMER_ACCOUNT_EXPORT = "MANAGE-CUSTOMER-ACCOUNT_EXPORT",
}

export enum DebtPermission {
  MANAGE_DEBT_VIEW_LIST = "MANAGE-DEBT_VIEW-LIST",
  MANAGE_DEBT_VIEW_DETAILS = "MANAGE-DEBT_VIEW-DETAILS",
}

export enum PermissionPermission {
  MANAGE_PERMISION_VIEW_LIST = "MANAGE-PERMISION_VIEW-LIST",
  MANAGE_PERMISION_VIEW_DETAILS = "MANAGE-PERMISION_VIEW-DETAILS",
  MANAGE_PERMISION_ADD = "MANAGE-PERMISION_ADD",
  MANAGE_ROLES_VIEW_LIST = "MANAGE-ROLES_VIEW-LIST",
  MANAGE_ROLES_VIEW_DETAILS = "MANAGE-ROLES_VIEW-DETAILS",
  MANAGE_ROLES_ADD = "MANAGE-ROLES_ADD",
  MANAGE_REPORT_OVERVIEW_EXPORT = "MANAGE-REPORT_OVERVIEW_EXPORT",
}

export enum ReportPermission {
  MANAGE_REPORT_OVERVIEW = "MANAGE-REPORT_OVERVIEW",
  MANAGE_REPORT_SALES_VOLUMES = "MANAGE-REPORT_SALES_VOLUMES",
  MANAGE_REPORT_DEBT = "MANAGE-REPORT_DEBT",
  MANAGE_REPORT_SALES_VOLUMES_EXPORT = "MANAGE-REPORT_SALES_VOLUMES_EXPORT",
  MANAGE_REPORT_DEBT_EXPORT = "MANAGE-REPORT_DEBT_EXPORT",
  MANAGE_REPORT_REVENUE = "MANAGE-REPORT_REVENUE",
  MANAGE_REPORT_REVENUE_EXPORT = "MANAGE-REPORT_REVENUE_EXPORT",
  MANAGE_REPORT_DISCOUNT = "MANAGE-REPORT_DISCOUNT",
}

export enum BulkDiscountPermission {
  MANAGE_BULK_DISCOUNT_DELETE = "MANAGE-BULK_DISCOUNT-DELETE",
}

export type TLanguage = "en" | "vi" | "km" | "my";
