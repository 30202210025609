import { IconBase, IconComponent } from "@Core/Icons";

export const KeySmallIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      {/* <path
        d="M14.625 13C14.625 15.6924 12.4424 17.875 9.75 17.875C7.05761 17.875 4.875 15.6924 4.875 13C4.875 10.3076 7.05761 8.125 9.75 8.125C12.4424 8.125 14.625 10.3076 14.625 13Z"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M14.625 13H21.8472M25.4583 13H21.8472M21.8472 13V18.4167"
        stroke={props.color}
        strokeWidth="2"
      /> */}
      <path
        d="M6.68421 6.95929C6.68421 8.52894 5.41176 9.8014 3.84211 9.8014C2.27245 9.8014 1 8.52894 1 6.95929C1 5.38964 2.27245 4.11719 3.84211 4.11719C5.41176 4.11719 6.68421 5.38964 6.68421 6.95929Z"
        stroke="#312F30"
        strokeWidth="2"
      />
      <path
        d="M6.68433 6.96094H10.8949M13.0001 6.96094H10.8949M10.8949 6.96094V10.1188"
        stroke="#312F30"
        strokeWidth="2"
      />
    </IconBase>
  );
};
