import { EventIcon } from "@Components/Icons/EventIcon";
import { UploadImageIcon } from "@Components/Icons/UploadImageIcon";
import { UploadSuccessIcon } from "@Components/Icons/UploadSuccessIcon";
import ImageUpload from "@Components/ImageUpload";
import ModalFooter from "@Components/ModalFooter";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { FormUploadImageModel, LocationModel, SalesOrgModal, UploadedImageModel } from "@Models";
import { CustomerService, SalesOrgService } from "@Services";
import { FormUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { vi } from "date-fns/locale";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { v4 as uuidv4 } from "uuid";
import "./CreateLead.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  closeForm: () => void;
  setLead: (lead: any) => void;
};

const useInputStyles = makeStyles({
  root: {
    width: "auto",
    color: "inherit",
    fontSize: "1.6rem",
    verticalAlign: "middle",
  },
});

const usePopoverStyles = makeStyles({
  paper: {
    backgroundColor: "green",
  },
});

const CreateLead: React.FC<P_Props> = ({ closeForm, setLead }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const { enqueueSnackbar } = useSnackbar();
  const [loca, setLoca] = useState<LocationModel[]>([]);
  const [province, setProvince] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [ward, setWard] = useState<string>("");
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [salesOrg, setSalesOrg] = useState<SalesOrgModal[]>([]);
  const [salesOrgId, setSalesOrgId] = useState<string>("");
  const [visibleIDCard, setVisibleIDCard] = useState<boolean>(false);
  const [identityFrontImage, setIdentityFrontImage] = useState<
    Array<FormUploadImageModel & { file: any }>
  >([]);
  const [identityBackImage, setIdentityBackImage] = useState<
    Array<FormUploadImageModel & { file: any }>
  >([]);
  const districts = loca.find((city) => city.name === province)?.districts || [];
  const wards = districts.find((d) => d.name === district)?.wards || [];

  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const inputClasses = useInputStyles();
  const popoverClasses = usePopoverStyles();

  useEffect(() => {
    axios
      .get("https://api-administrative.exceltech.vn/api/?depth=3")
      .then((res) => {
        setLoca(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      SalesOrgService.getAllSalesOrg({ sources: "farm" })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setSalesOrg(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, []);

  const createLead = async (values: any) => {
    await CustomerService.createLead({
      ...values,
      sources: "farm",
      salesOrgId: salesOrgId,
      idIssueDate: selectedDate || new Date(),
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("order:SUCCESSFULLY_CREATED_POTENTIAL_CUSTOMER"), {
            variant: "success",
          });
          setLead(res.data);
          setTimeout(() => uploadIdentity(res.data?.id));
          closeForm();
        } else {
          // do something
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:FAILED_CREATE_POTENTIAL_CUSTOMER"), {
          variant: "error",
        });
      });
  };

  const uploadIdentity = async (leadId: string) => {
    try {
      const formData = new FormData();
      formData.set("leadId", leadId);

      formData.append("imgs", identityFrontImage[0].file);
      formData.append("imgs", identityBackImage[0].file);
      if (identityFrontImage[0].file && identityBackImage[0].file) {
        await CustomerService.uploadIdentity(formData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="lead-modal">
      <SimpleModalHeader title={t("order:CREATED_POTENTIAL_CUSTOMER")} onClose={closeForm} />
      <div className="content">
        <Form onSubmit={async (values) => createLead(values)}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className="lead-modal__form" id="lead">
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <div className="form-item">
                      <div className="form-label">{t("NAME")}</div>
                      <Field
                        name="fullName"
                        validate={FormUtil.Rule.required("Bạn chưa nhập Họ tên")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Họ tên..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">E-mail</div>
                      <Field name="email" validate={FormUtil.Rule.required("Bạn chưa nhập E-mail")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập E-mail..."}
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("customer:ISSUE_DATE")}</div>
                      <Field name="idIssueDate">
                        {({ input, meta, ...rest }) => {
                          return (
                            <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                {...rest}
                                okLabel={t("order:SELECT")}
                                cancelLabel={t("CANCEL")}
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                value={selectedDate}
                                inputVariant="outlined"
                                disableToolbar
                                onChange={(selectedDate) => setSelectedDate(selectedDate)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                inputProps={{ className: "input" }}
                                InputProps={{ classes: inputClasses }}
                                PopoverProps={{ classes: popoverClasses }}
                                keyboardIcon={
                                  <EventIcon color="#41AD49" size={[22, 22]} viewBox={[22, 22]} />
                                }
                                maxDateMessage="Ngày không hợp lệ"
                                invalidDateMessage="Ngày không hợp lệ"
                                minDateMessage="Ngày không hợp lệ"
                              />
                            </MuiPickersUtilsProvider>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("COMPANY")}</div>
                      <Field
                        name="company"
                        // validate={FormUtil.Rule.required("Bạn chưa nhập Công ty")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập tên Công ty..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("order:BUSINESS_UNIT")}</div>
                      <Field
                        name="salesOrgId"
                        validate={FormUtil.Rule.required("Bạn chưa chọn đơn vị kinh doanh")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                // value={salesOrg}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">
                                          {t("SELECT_BY_NAME", {
                                            NAME: t("order:BUSINESS_UNIT"),
                                          })}
                                        </span>
                                      )
                                }
                                displayEmpty
                                className="form-select-field"
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {salesOrg.map((s: SalesOrgModal) => {
                                  return (
                                    <MenuItem
                                      key={s.id}
                                      value={s.description}
                                      onClick={() => setSalesOrgId(s.id.toString())}>
                                      {s.description}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Quận/ Huyện</div>
                      <Field
                        name="district"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Quận/ Huyện")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={district}
                                onChange={(e: any) => {
                                  input.onChange(e.target.value);
                                  setDistrict(e.target.value as string);
                                }}
                                fullWidth
                                error={meta.error && meta.touched}
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                className="form-select-field"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">Chọn Quận/ Huyện</span>
                                      )
                                }
                                displayEmpty
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {districts.length &&
                                  districts.map((d) => (
                                    <MenuItem key={d.code} value={d.name}>
                                      {d.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="form-item">
                      <div className="form-label">{t("PHONE_NUMBER")}</div>
                      <Field
                        name="phoneNumber"
                        validate={FormUtil.composeValidators([
                          FormUtil.Rule.required("Bạn vẫn chưa nhập Số điện thoại"),
                          //   FormUtil.Rule.pattern(REGEX.PHONE, {
                          //     errorMessage: "Số điện thoại không đúng định dạng",
                          //   }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Số điện thoại..."}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={
                                meta.touched
                                  ? meta.error || (submitError === false && meta.submitError)
                                  : ""
                              }
                              error={
                                (meta.error || (submitError === false && meta.submitError)) &&
                                meta.touched
                              }
                              onChange={(e) => {
                                input.onChange(e.target.value);
                                setSubmitError(true);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">CMND/CCCD</div>
                      <Field
                        name="idCard"
                        validate={FormUtil.Rule.required("Bạn vẫn chưa nhập CMND")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập CMND"}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {identityFrontImage.length > 0 ? (
                                      <UploadSuccessIcon
                                        color="#41AD49"
                                        size={[20, 20]}
                                        viewBox={[20, 20]}
                                      />
                                    ) : (
                                      <UploadImageIcon
                                        color="#41AD49"
                                        size={[20, 20]}
                                        viewBox={[20, 20]}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          );
                        }}
                      </Field>
                      <div
                        className="text-color"
                        onClick={() => {
                          setVisibleIDCard(true);
                        }}>
                        {`${identityFrontImage.length > 0 ? "Xem lại" : "Tải"} hình ảnh CMND/CCCD`}
                      </div>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Nơi cấp</div>
                      <Field
                        name="idIssuePlace"
                        validate={FormUtil.Rule.required("Bạn vẫn chưa nhập nơi cấp")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập nơi cấp"}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("TIN")}</div>
                      <Field
                        name="taxId"
                        // validate={FormUtil.Rule.required("Bạn chưa nhập Mã số thuế")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              color="success"
                              placeholder={"Nhập Mã số thuế"}
                              className={"form-text-field"}
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Tỉnh/ Thành phố</div>
                      <Field
                        name="province"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Tỉnh/ Thành phố")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={province}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setProvince(e.target.value as string);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">
                                          Chọn Tỉnh/ Thành phố
                                        </span>
                                      )
                                }
                                displayEmpty
                                className="form-select-field"
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {loca.map((p) => {
                                  return (
                                    <MenuItem key={p.code} value={p.name}>
                                      {p.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">Phường/ Xã</div>
                      <Field
                        name="ward"
                        validate={FormUtil.Rule.required("Bạn chưa chọn Phường/ Xã")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <FormControl>
                              <Select
                                {...input}
                                {...rest}
                                value={ward}
                                onChange={(e) => {
                                  input.onChange(e.target.value);
                                  setWard(e.target.value as string);
                                }}
                                error={meta.error && meta.touched}
                                fullWidth
                                input={
                                  <OutlinedInput className="form-text-field" color="success" />
                                }
                                variant="outlined"
                                className="form-select-field"
                                renderValue={
                                  input.value !== ""
                                    ? undefined
                                    : () => (
                                        <span className="form__placeholder">Chọn Phường/ Xã</span>
                                      )
                                }
                                displayEmpty
                                MenuProps={{
                                  className: "select-menu-list",
                                }}>
                                {wards.length &&
                                  wards.map((d) => (
                                    <MenuItem key={d.code} value={d.name}>
                                      {d.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                              <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </div>
                  </Grid>
                </Grid>
                <div className="form-item">
                  <div className="form-label">Địa chỉ</div>
                  <Field name="address" validate={FormUtil.Rule.required("Bạn chưa nhập Địa chỉ")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          variant="standard"
                          placeholder={"Nhập địa chỉ..."}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">{t("NOTES")}</div>
                  <Field name="note">
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          variant="standard"
                          placeholder={t("INPUT_BY_NAME", { NAME: t("NOTES") })}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      {/*Dialog Identity */}
      <Dialog fullWidth maxWidth="md" open={visibleIDCard}>
        <Form onSubmit={() => {}}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="card-group">
                  <div className="title">
                    Hình ảnh CMND/CCCD
                    <ClearIcon
                      style={{ fontSize: "3rem", cursor: "pointer" }}
                      onClick={() => {
                        setVisibleIDCard(false);
                      }}
                    />
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <div className="text-face">Mặt trước</div>
                      <ImageUpload
                        title="Hình ảnh CMND/CCCD"
                        className="card card-create"
                        max={1}
                        fileList={identityFrontImage}
                        onChange={(data: UploadedImageModel) => {
                          setIdentityFrontImage([
                            {
                              id: uuidv4(),
                              src: data.fileStr,
                              alt: data.alt || "",
                              file: data.file,
                            },
                          ]);
                        }}
                        onDelete={() => {
                          setIdentityFrontImage([]);
                        }}
                        config={{ uploadModal: true }}
                      />
                    </div>
                    <div>
                      <div className="text-face">Mặt sau</div>
                      <ImageUpload
                        className="card card-create"
                        title="Hình ảnh CMND/CCCD"
                        max={1}
                        fileList={identityBackImage}
                        onChange={(data: UploadedImageModel) => {
                          setIdentityBackImage([
                            {
                              id: uuidv4(),
                              src: data.fileStr,
                              alt: data.alt || "",
                              file: data.file,
                            },
                          ]);
                        }}
                        onDelete={() => {
                          setIdentityBackImage([]);
                        }}
                        config={{ uploadModal: true }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      className={"btn-confirm-style "}
                      onClick={(e) => {
                        e.preventDefault();
                        identityFrontImage.length > 0 &&
                          identityBackImage.length > 0 &&
                          enqueueSnackbar("Tải ảnh lên thành công", {
                            variant: "success",
                            preventDuplicate: true,
                          });
                        if (identityFrontImage.length === 0 || identityBackImage.length === 0) {
                          enqueueSnackbar("Vui lòng tải lên đủ mặt trước và sau", {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        }
                        setTimeout(() => setVisibleIDCard(false), 200);
                      }}>
                      Lưu
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </Dialog>
      <ModalFooter
        formId="lead"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={t("CREATE")}
      />
    </div>
  );
};

export default CreateLead;
