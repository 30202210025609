import GFTypography from "@Components/ui/GFTypography";
import { TEXT_HOLDER } from "@Constants";
import React from "react";

type GFLabelValueProps = {
  label: string;
  value: string;
  labelColor?: string;
  valueColor?: string;
};
export default function GFLabelValue({
  label,
  value,
  labelColor = "var(--label-color)",
  valueColor = "var(--value-color)",
}: GFLabelValueProps) {
  return (
    <div>
      <GFTypography variant="body2" className="!u-mb-2" color={labelColor} fontWeight={700}>
        {label}
      </GFTypography>
      <GFTypography variant="body1" fontWeight={400} color={valueColor}>
        {value || TEXT_HOLDER}
      </GFTypography>
    </div>
  );
}
