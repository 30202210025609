import { ItemGroupTypeEnum, TEXT_HOLDER } from "@Constants";
import { checkLinkImage, FormUtil, renderErrorImageItem, renderImageProNull } from "@Utils";
import { Clear as ClearIcon } from "@mui/icons-material";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import "./index.scss";

export type P_Props = {
  sourceType: string;
  avt: any;
  fullName: string;
  phoneNumber: string;
  code?: string;
  salesOrg: string;
  division?: string;
  onClear: () => void;
  depositId?: any;
  listDeposit?: any;
  setDepositId?: any;
  groupType?: string;
  salesGroup: string;
  salesDistrict: string;
};

const OrderHeader: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation", "order"]);
  return (
    <>
      <div className="header-left">
        <img
          src={checkLinkImage(props.avt) || renderImageProNull()}
          alt="Avata"
          onError={renderErrorImageItem()}
        />
        <div>
          <div className="header-name">
            {props.fullName} - {props.code}
          </div>
          {props.phoneNumber}
        </div>
      </div>
      {/* {props.sourceType === "farm" && (
        <div className="header-col">
          <div className="header-label">Mã khách hàng</div>
          {props.code || TEXT_HOLDER}
        </div>
      )} */}
      {props.sourceType === "feed" && (
        <div className="header-col">
          <div className="header-label">{t("PRODUCT_CATEGORY")}</div>
          {props.division || TEXT_HOLDER}
        </div>
      )}
      <div className="header-col">
        <div className="header-label">{t("order:HEADER_BUSINESS_UNIT")}</div>
        {props.salesOrg || TEXT_HOLDER}
      </div>
      {props.groupType === ItemGroupTypeEnum.BREEDING_PIG && (
        <div className="header-col">
          <div className="header-label">{t("order:HEADER_SALES_DISTRICT_EC00001")}</div>
          {props.salesDistrict || TEXT_HOLDER}
        </div>
      )}

      {props.groupType === ItemGroupTypeEnum.PORK_PIG && (
        <div className="header-col">
          <div className="header-label">{t("order:HEADER_SALES_GROUP_EC00002")}</div>
          {props.salesGroup || TEXT_HOLDER}
        </div>
      )}
      {props.groupType === ItemGroupTypeEnum.CULL_PIG && (
        <div className="header-col">
          <div className="header-label">{t("order:HEADER_SALES_GROUP_EC00003")}</div>
          {props.salesGroup || TEXT_HOLDER}
        </div>
      )}

      {props.sourceType === "farm" &&
        (!props.depositId ? (
          <div className="deposit-order">
            <Field
              name="type"
              validate={
                !props.depositId ? FormUtil.Rule.required("Bạn chưa chọn hình thức cọc") : undefined
              }>
              {({ input, meta, ...rest }) => {
                return (
                  <FormControl style={{ width: "100%", height: "auto" }}>
                    <Select
                      {...input}
                      {...rest}
                      className="select-deposit"
                      fullWidth
                      required={true}
                      value={props.depositId?.id}
                      error={meta.error && meta.touched}
                      // input={
                      //   <OutlinedInput className="form-text-field" color="success" />
                      // }
                      variant="outlined"
                      renderValue={() => {
                        let placeHolder = "Phương thức cọc";
                        return <span className="form__placeholder">{placeHolder}</span>;
                      }}
                      onChange={(e) => {
                        let depositIdChoosen = e.target.value;
                        if (depositIdChoosen) {
                          let depositObject = props?.listDeposit?.find((item: any, index: any) => {
                            return Number(depositIdChoosen) === Number(item.id);
                          });
                          if (depositObject) {
                            props?.setDepositId(depositObject);
                          }
                        } else {
                          props?.setDepositId("");
                        }
                      }}
                      displayEmpty
                      MenuProps={{
                        className: "select-menu-list",
                        style: { maxHeight: "40rem" },
                      }}>
                      {props?.listDeposit?.map((ele: any, index: any) => {
                        return (
                          <MenuItem key={ele.code} value={ele.id}>
                            {`${ele.code}-${ele.rate1}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
                  </FormControl>
                );
              }}
            </Field>
          </div>
        ) : (
          <div className="deposit-order-value">
            <div className="deposit-order-header">Phương thức cọc</div>
            <div>
              {props.depositId?.code} - {props.depositId?.rate1}%
            </div>
          </div>
        ))}
      <div className="header-right">
        <ClearIcon className="header-clear-icon" onClick={props.onClear} />
      </div>
    </>
  );
};

export default OrderHeader;
