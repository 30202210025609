import { REQUEST_URL } from "@Constants";
import { OrderInputModel, OrderSampleInputModel, PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const OrderService = {
  filterOrders: (
    query: PaginationQueryModel & {
      status?: string;
      from?: string;
      to?: string;
      strSearch?: string;
      source?: string;
      subStatus?: string;
      view?: string;
    } = {
      page: 1,
      limit: 10,
      source: "",
    }
  ) => {
    const url = REQUEST_URL.ORDER;

    return RequestUtil.get({ url, query: query });
  },

  getOrderById(id: number) {
    const url = `${REQUEST_URL.ORDER}/${id}`;
    return RequestUtil.get({ url });
  },

  getOrderCombinationById(id: number) {
    const url = REQUEST_URL.ORDER_COMBINATION.replace(":orderId", id.toString());
    return RequestUtil.get({ url });
  },

  updateOrderCombination: (id: number, orderGroupIds: number[]) => {
    const url = REQUEST_URL.ORDER_COMBINATION.replace(":orderId", id.toString());
    return RequestUtil.put({ url, data: { orderGroupIds } });
  },

  createOrder: (data: {
    customerId: number;
    campId?: number;
    isInternal?: boolean;
    creatorId: number;
    salesOrgId: number;
    distributionChannelId: number;
    address: string;
    currency: string;
    requiredDate: string;
    totalDiscount: number;
    vat: number;
    orderAmount: number;
    source: string;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.ORDER;

    return RequestUtil.post({ url, data });
  },

  createOrderLab: (data: OrderInputModel) => {
    const url = REQUEST_URL.CREATE_ORDER_LAB;

    return RequestUtil.post({ url, data });
  },

  createOrderByLead: (data: {
    leadId: number;
    creatorId: number;
    salesOrgId: number;
    distributionChannelId: number;
    address: string;
    currency: string;
    requiredDate: string;
    totalDiscount: number;
    vat: number;
    orderAmount: number;
    source: string;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.ORDER;

    return RequestUtil.post({ url, data });
  },

  updateOrderFarm: (data: {
    orderGroupId: number;
    customerId?: number;
    isCreateERP?: boolean;
    orders: {
      orderId: number;
      requiredDate: string;
      vehicleNumber: string | null;
      note: string | null;
      address: string | null;
      siteId: number;
      shippingAddress: number;
      orderDetails: {
        id: number;
        quantity: number;
        dimension: string;
        subTotal: number;
        attachValue: number;
        alterItemId?: number;
      }[];
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER;
    return RequestUtil.put({ url, data });
  },

  getAllSite: (
    query: PaginationQueryModel & {
      searchValue?: string;
      source?: string;
      customerId?: number;
      itemId?: number;
    } = {
      page: 1,
      limit: 10,
      searchValue: "",
      source: "",
    }
  ) => {
    const url = REQUEST_URL.GET_ALL_SITE;
    return RequestUtil.get({ url, query: query });
  },

  getAllSiteFarm: (
    query: PaginationQueryModel & {
      searchValue?: string;
      source?: string;
      customerId?: number;
      itemId?: number;
    } = {
      page: 1,
      limit: 10,
      searchValue: "",
      source: "",
    }
  ) => {
    const url = REQUEST_URL.GET_ALL_SITE_FARM;
    return RequestUtil.get({ url, query: query });
  },

  getAlterItem: (data: { itemId: number; siteId: number }) => {
    const url = REQUEST_URL.ALTER_ITEM;
    return RequestUtil.get({ url, query: data });
  },

  updateOrderDraft: (data: {
    customerId: number;
    leadId: number;
    creatorId: number;
    salesOrgId: number;
    distributionChannelId: number;
    address: string;
    currency: string;
    requiredDate: string;
    totalDiscount: number;
    vat: number;
    orderAmount: number;
    source: string;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.CREATE_ORDER_DRAFT;

    return RequestUtil.post({ url, data });
  },

  updateOrderItem: (data: {
    address: string;
    vehicleNumber: string;
    requiredDate: string;
    vat: number;
    orderAmount: number;
    note: string;
    orderGroupId: number;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: string;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER_ITEM;

    return RequestUtil.put({ url, data });
  },

  deleteOrderDraft: (data: { orderGroupId: number; source: string; customerId: number }) => {
    const url = REQUEST_URL.DELETE_ORDER_DRAFT;

    return RequestUtil.post({ url, data });
  },

  detachOrder: (data: {
    orderGroupId: number;
    orderId: number;
    isCreateERP?: boolean;
    shippingAddress: number;
    orderlines: {
      orderId?: number;
      quantity: number;
      dimension: string;
      subTotal: number;
      note?: string;
      address?: string;
      requiredDate?: string;
      alterItemId?: number;
      siteId?: number;
    }[];
  }) => {
    const url = REQUEST_URL.DETACH_ORDER_FARM;

    return RequestUtil.post({ url, data });
  },

  updateOrderFeed: (data: {
    address: string;
    requiredDate: string;
    vat: number;
    orderAmount: number;
    orderGroupId: number;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: number;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER_FEED;

    return RequestUtil.put({ url, data });
  },

  updateOrderVet: (data: {
    address: string;
    requiredDate: string;
    vat: number;
    orderAmount: number;
    orderGroupId: number;
    note: string;
    orderDetails: {
      itemId: number;
      divisionId: number;
      discount: number;
      quantity: number;
      unitPrice: number;
      saleUnit: string;
      baseUoM: string;
      dimension: number;
      subTotal: number;
    }[];
  }) => {
    const url = REQUEST_URL.UPDATE_ORDER_VET;

    return RequestUtil.put({ url, data });
  },

  updateOrderLab: (id: string, data: OrderInputModel) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB.replace("{id}", id);

    return RequestUtil.put({ url, data });
  },

  updateOrderLabStatus: (
    id: string,
    data: { status: string; reasonStatus?: string; reason?: string }
  ) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB_STATUS.replace("{id}", id);

    return RequestUtil.put({ url, data });
  },

  updateOrderLabSample: (data: OrderSampleInputModel) => {
    const url = REQUEST_URL.UPDATE_ORDER_LAB_SAMPLE;

    return RequestUtil.put({ url, data });
  },

  exportOrderLabSample: (id: string, data: any) => {
    const url = REQUEST_URL.EXPORT_ORDER_LAB_SAMPLE.replace("{id}", id);

    return RequestUtil.post({ url, data });
  },

  importOrderLabSample: (id: string, data: any) => {
    const url = REQUEST_URL.IMPORT_ORDER_LAB_SAMPLE.replace("{id}", id);

    return RequestUtil.post({ url, data });
  },

  cancelOrder: (data: {
    reasonStatus: string;
    orderGroupId: number;
    reason: string;
    source: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_FEED;

    return RequestUtil.put({ url, data });
  },

  cancelOrderLab: (data: {
    reasonStatus: string;
    orderGroupId: number;
    reason: string;
    source: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_LAB;

    return RequestUtil.put({ url, data });
  },

  recallOrder: (data: { orderId: number; salesOrgId?: number }) => {
    const url = REQUEST_URL.RECALL_ORDER_FEED;

    return RequestUtil.post({ url, data });
  },

  recallOrderList: (data: { orderGroupIds: any }) => {
    const url = REQUEST_URL.RECALL_ORDER_LIST_FEED;

    return RequestUtil.post({ url, data });
  },

  getDeliveryOrderById(query: { orderId: any }) {
    const url = REQUEST_URL.DELIVERY_ORDER;
    return RequestUtil.get({ url, query: query });
  },

  getOrderCompareById(id: number) {
    const url = `${REQUEST_URL.ORDER_COMPARE}/${id}`;
    return RequestUtil.get({ url });
  },

  cancelOrderFarm: (data: {
    id: number;
    type: string;
    isCancel: boolean;
    reasonStatus?: string;
    reason?: string;
  }) => {
    const url = REQUEST_URL.CANCEL_ORDER_FARM;

    return RequestUtil.put({ url, data });
  },

  createERPFarm: (data: { orderGroupId: number; orderId: number }) => {
    const url = REQUEST_URL.CREATE_ERP_FARM;

    return RequestUtil.post({ url, data });
  },

  getRequestType: () => {
    const url = REQUEST_URL.REQUEST_TYPE_GET_OR_CREATE;
    return RequestUtil.get({ url });
  },

  allocateOrderFarm: (data: any) => {
    const url = REQUEST_URL.CONFIRM_ORDER;
    return RequestUtil.put({ url, data });
  },

  getDebtCustomer: (data: any) => {
    const url = REQUEST_URL.GET_CUSTOMER_DEBT.replace("{id}", data);
    return RequestUtil.get({ url });
  },

  getInfoDeliveryNote: (orderId: any) => {
    const url = `/order/farm/${orderId}/outcome`;
    return RequestUtil.get({ url });
  },

  confirmOrder: (data: any) => {
    const url = "order/complete-farm-order";
    return RequestUtil.put({ url, data: data });
  },

  confirmOrderType: (orderId: any, data: any) => {
    const url = `order/farm/status/${orderId}`;
    return RequestUtil.put({ url, data: data });
  },

  getDetailDeliveryFee: (data: { orderGroupId?: string; orderId?: string }) => {
    const url = `/order/farm/vehicles`;
    return RequestUtil.get({ url, query: data });
  },

  syncOrderErp: (id: string) => {
    const url = `/order/farm-order/${id}/sync-erp`;
    return RequestUtil.put({ url });
  },
};
