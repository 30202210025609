/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "@Components/Loading";
import { useAppDispatch } from "@Core/store/hook";
import { loadProfile } from "@Core/store/profile";
import { Action, DepositOutputModel, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Grid,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import CTable from "./components";
import { IColumn } from "./components/const";
import { WarehouseService } from "@Services/warehouse.service";
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import { Month } from "@Constants";
import { YearList } from "@Utils";
import { IWareHouse } from "@Models/warehouse.model";
import { get } from "lodash";
import Navbar from "./components/Navbar";
import BreedingPigs from "./breeding";
import { useLocation } from "react-router-dom";
import Pork from "./pork";

function reducer(state: PaginateFetchReducer<DepositOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const Warehouse: React.FC = () => {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);


  return (
    <>
      <div className="warehouse">
        <Navbar />
        <div style={{ display: searchQuery.get("type") === "2" ? "grid" : "none", height: "calc(100vh - 130px", width: '100%' }}>
          <Pork />
        </div>
        <div style={{ display: searchQuery.get("type") !== "2" ? "grid" : "none", height: "calc(100vh - 130px)", width: '100%' }}>
          <BreedingPigs />
        </div>
      </div>
    </>
  );
};

export default Warehouse;
