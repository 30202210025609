import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(IconButton)<{ variant?: "contained" | 'secondary'; noPadding?: boolean }>(
  ({ noPadding = true, color, size, variant, disabled }) => ({
    "&.MuiButtonBase-root": {
      textTransform: "none",
      color: "var(--text-foreground)",
      fontSize: "1.6rem",
      lineHeight: 1,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
      fontWeight: 700,
      ...(noPadding && {
        padding: 0,
      }),
      ...(size === "large" && {
        height: "48px",
        width: "48px",
      }),
      ...(size === "medium" && {
        height: "3.2rem",
        width: "3.2rem",
      }),
      ...(size === "small" && {
        height: "2.4rem",
        width: "2.4rem",
      }),
      "& .MuiSvgIcon-root": {
        fontSize: "3.2rem",
        ...(size === "medium" && {
          height: "3.2rem",
          fontSize: "2.4rem",
        }),
        ...(size === "small" && {
          fontSize: "1.4rem",
        }),
      },

      // --- COLORS ---
      ...(color === "primary" && {
        color: "var(--primary-green)",
      }),
      ...(variant === "contained" && {
        backgroundColor: "var(--primary-green) !important",
        color: "white",
        "& svg": {
          fill: "white",
          color: "white",
        },
        "&:hover": {
          backgroundColor: "var(--primary-green)",
        },
      }),
      ...(variant === "secondary" && {
        backgroundColor: "var(--primary-gray-2) !important",
        color: "white",
        "& svg": {
          fill: "white",
          color: "white",
        },
        "&:hover": {
          backgroundColor: "var(--primary-green)",
        },
      }),



      // --- DISABLED ---

      ...(disabled && {
        backgroundColor: "var(--primary-gray-1) !important",

        color: "var(--text-foreground)",
        "& svg": {
          fill: "var(--text-foreground)",
          color: "var(--text-foreground)",
        },
      }),
    },
  })
);
