import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ItemSection = styled(Box)({
  padding: "2.4rem",
  borderRadius: "0.8rem",
  marginBottom: "2.4rem",
  boxShadow: "0px 0px 0.8rem rgba(0, 0, 0, 0.1)",
  border: "1px solid var(--border-color)",
});
