import GFMenu from "@Components/ui/GFMenu";
import GFMenuItem from "@Components/ui/GFMenuItem";
import { styled } from "@mui/material/styles";
import React from "react";
import { SelectOption } from "src/types";

const StyledTrigger = styled("div")({
  fontSize: "1.6rem",
  cursor: "pointer",
});

type GFDropdownProps = {
  children: React.ReactNode;
  trigger: React.ReactNode;
  onItemClick?: (value: any) => void;
};

export default function GFDropdown({ children, trigger, onItemClick }: GFDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (value: any) => {
    handleClose();
    onItemClick && onItemClick(value);
  };

  return (
    <div className="gf-dropdown" tabIndex={-1}>
      <StyledTrigger
        onClick={handleClick}
        aria-haspopup="menu"
        aria-expanded={open ? "true" : "false"}>
        {trigger}
      </StyledTrigger>
      <GFMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        MenuListProps={{
          "aria-labelledby": "menu-button",
          role: "menu",
        }}>
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return (
              <GFMenuItem onClick={() => handleItemClick(child.props.value)}>
                {React.cloneElement(child, { ...child.props })}
              </GFMenuItem>
            );
          }

          return (
            <GFMenuItem role="menuitem" key={`menu-item-${index}`} onClick={handleClose}>
              {child}
            </GFMenuItem>
          );
        })}
      </GFMenu>
    </div>
  );
}
