import accountImage from "@Assets/images/avt.png";
import ModalFooter from "@Components/ModalFooter";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { TEXT_HOLDER } from "@Constants";
import { CustomerModel, SourceType } from "@Models";
import { CustomerService, EmployeeService } from "@Services";
import { FormUtil, checkLinkImage } from "@Utils";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Checkbox, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import "./CreateEmployee.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  closeForm: () => void;
  infoEmployee: any;
};

const CreateEmployee: React.FC<P_Props> = ({ closeForm, infoEmployee }) => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEmployeeFeed, setSelectedEmployeeFeed] = useState<CustomerModel | null>(null);
  const [selectedEmployeeFarm, setSelectedEmployeeFarm] = useState<CustomerModel | null>(null);
  const [selectedEmployeeVet, setSelectedEmployeeVet] = useState<CustomerModel | null>(null);
  const [selectedEmployeeLab, setSelectedEmployeeLab] = useState<CustomerModel | null>(null);
  const [selectedEmployeeCcu, setSelectedEmployeeCcu] = useState<CustomerModel | null>(null);
  const [employee, setEmployee] = useState<
    { item: CustomerModel; customerId: string; source: string }[]
  >([]);
  const [requireOTP, setRequireOTP] = useState<boolean>(infoEmployee.account.requireOTP || false);
  const [isActive, setIsActive] = useState<boolean>(infoEmployee.account.isActive);

  const [isActiveSource, setIsActiveSource] = useState<{
    feed: boolean;
    farm: boolean;
    vet: boolean;
    lab: boolean;
    ccu: boolean;
  }>({
    feed: false,
    farm: false,
    vet: false,
    lab: false,
    ccu: false,
  });

  useEffect(() => {
    setIsActiveSource({
      feed: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED)
        ? infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED)?.isActive
        : false,
      farm: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM)
        ? infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM)?.isActive
        : false,
      vet: infoEmployee.account.employees.find((c: any) => c.source === SourceType.VET)
        ? infoEmployee.account.employees.find((c: any) => c.source === SourceType.VET)?.isActive
        : false,
      lab: infoEmployee.account.employees.find((c: any) => c.source === SourceType.LAB)
        ? infoEmployee.account.employees.find((c: any) => c.source === SourceType.LAB)?.isActive
        : false,
      ccu: infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU)
        ? infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU)?.isActive
        : false,
    });
  }, [infoEmployee]);

  const onUpdateCustomer = (
    variantId: number,
    customerId: string,
    source: string,
    addedEmployee?: CustomerModel
  ) => {
    const idx = employee.findIndex((item) => item.item.id === variantId);
    let data = [...employee];
    if (idx !== -1) {
      // update

      data[idx] = {
        ...data[idx],
        customerId: customerId,
        source: source,
      };
    } else {
      // thêm mới
      if (!addedEmployee) {
        return;
      }

      data.push({ item: addedEmployee, customerId, source });
    }

    setEmployee(data);
  };

  const submitForm = async (values: any) => {
    const validEmployee = employee.filter((item) => item.source);

    const users = validEmployee.map((item) => {
      switch (item.item.source) {
        case SourceType.FEED:
          return {
            customerId: selectedEmployeeFeed?.id,
            source: selectedEmployeeFeed?.source,
            isActive: selectedEmployeeFeed === null ? undefined : isActiveSource.feed,
          };
        case SourceType.FARM:
          return {
            customerId: selectedEmployeeFarm?.id,
            source: selectedEmployeeFarm?.source,
            isActive: selectedEmployeeFarm === null ? undefined : isActiveSource.farm,
          };
        case SourceType.VET:
          return {
            customerId: selectedEmployeeVet?.id,
            source: selectedEmployeeVet?.source,
            isActive: selectedEmployeeVet === null ? undefined : isActiveSource.vet,
          };
        case SourceType.LAB:
          return {
            customerId: selectedEmployeeLab?.id,
            source: selectedEmployeeLab?.source,
            isActive: selectedEmployeeLab === null ? undefined : isActiveSource.lab,
          };
        default:
          return {
            customerId: selectedEmployeeCcu?.id,
            source: selectedEmployeeCcu?.source,
            isActive: selectedEmployeeCcu === null ? undefined : isActiveSource.ccu,
          };
      }
    });

    const usersData = [
      infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED) && {
        customerId: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED)
          ?.id,
        source: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED)
          ?.source,
        isActive: isActiveSource.feed,
      },
      infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM) && {
        customerId: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM)
          ?.id,
        source: infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM)
          ?.source,
        isActive: isActiveSource.farm,
      },
      infoEmployee.account.employees.find((c: any) => c.source === SourceType.VET) && {
        customerId: infoEmployee.account.employees.find((c: any) => c.source === SourceType.VET)
          ?.id,
        source: infoEmployee.account.employees.find((c: any) => c.source === SourceType.VET)
          ?.source,
        isActive: isActiveSource.vet,
      },
      infoEmployee.account.employees.find((c: any) => c.source === SourceType.LAB) && {
        customerId: infoEmployee.account.employees.find((c: any) => c.source === SourceType.LAB)
          ?.id,
        source: infoEmployee.account.employees.find((c: any) => c.source === SourceType.LAB)
          ?.source,
        isActive: isActiveSource.lab,
      },
      infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU) && {
        customerId: infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU)
          ?.id,
        source: infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU)
          ?.source,
        isActive: isActiveSource.ccu,
      },
    ].filter((f) => f !== undefined);

    const data: Parameters<typeof CustomerService.updateAccount>[0] = {
      // ...values,
      id: infoEmployee?.account?.id,
      isActive: isActive,
      requireOTP: requireOTP,
      customers: users.concat(usersData),
      isEmployee: true,
      name: values.account["nickname"],
      code: values.account["code"],
      phoneNumber: values.account["phoneNumber"],
      email: values.account["email"],
    } as any;

    CustomerService.updateAccount(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Cập nhập tài khoản thành công!", {
            variant: "success",
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.message?.includes("email")) {
            enqueueSnackbar("Email đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber")) {
            enqueueSnackbar("Số điện thoại đã tồn tại!", {
              variant: "error",
            });
          } else if (
            error.response.data.message === `Key (code)=(${values.code}) already exists.`
          ) {
            enqueueSnackbar("Mã đăng nhập đã tồn tại!", {
              variant: "error",
            });
          } else if (error.response.data.message?.includes("phoneNumber must be a string")) {
            enqueueSnackbar("Vui lòng điền SĐT", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Cập nhật tài khoản thất bại!", {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar("Cập nhật tài khoản thất bại!", {
            variant: "error",
          });
        }
      });
  };

  const accountImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = accountImage;
    event.currentTarget.className = "error";
  };

  return (
    <div className="account-modal">
      <SimpleModalHeader title={"Chỉnh sửa tài khoản nhân viên"} onClose={closeForm} />
      <div className="content">
        <Form onSubmit={async (values) => submitForm(values)} initialValues={infoEmployee}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} id="emp-account">
                <div className="form-item">
                  <div className="form-label">Trạng thái hoạt động</div>
                  <div>
                    <DefaultSwitch checked={isActive} onChange={() => setIsActive(!isActive)} />
                    {isActive === true ? (
                      <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>Hoạt động</span>
                    ) : (
                      <span style={{ fontSize: "1.6rem", color: "#838283" }}>Vô hiệu hóa</span>
                    )}
                  </div>
                </div>
                <div className="title">Thông tin tài khoản</div>
                <div>
                  <div className="header">
                    <div className="source">Mảng truy cập</div>
                    <div className="cus">Nhân viên</div>
                    <div className="access">Cho phép truy cập</div>
                  </div>
                  <div className="table">
                    <div className="col source">
                      <div>{t("FEED")}</div>
                      <div>{t("FARM")}</div>
                      <div>{t("VET")}</div>
                      <div>{t("LAB")}</div>
                      <div>{t("CCU")}</div>
                    </div>
                    <div className="col cus">
                      {/* feed */}
                      {infoEmployee.account.employees.find(
                        (c: any) => c.source === SourceType.FEED
                      ) ? (
                        <div className="cus-info">
                          <div className="cus-info__img">
                            <img
                              src={
                                checkLinkImage(
                                  infoEmployee.account.employees.find(
                                    (c: any) => c.source === SourceType.FEED
                                  )?.avatar
                                ) || accountImage
                              }
                              alt="img"
                              onError={accountImageOnErrorHandler}
                            />
                          </div>
                          <div>
                            <div className="cus-info__name">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FEED
                              ).fullName || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FEED
                              ).code || TEXT_HOLDER}
                            </div>
                            <div className="cus-info__phone">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FEED
                              ).phoneNumber || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FEED
                              ).email || TEXT_HOLDER}
                            </div>
                          </div>
                        </div>
                      ) : !selectedEmployeeFeed ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FEED}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm khách hàng"
                          onChange={(value) => {
                            setSelectedEmployeeFeed(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeFeed.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFeed.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeFeed.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFeed.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeFeed(null);
                            }}
                          />
                        </div>
                      )}

                      {/* farm */}
                      {infoEmployee.account.employees.find(
                        (c: any) => c.source === SourceType.FARM
                      ) ? (
                        <div className="cus-info">
                          <div className="cus-info__img">
                            <img
                              src={
                                checkLinkImage(
                                  infoEmployee.account.employees.find(
                                    (c: any) => c.source === SourceType.FARM
                                  )?.avatar
                                ) || accountImage
                              }
                              alt="img"
                              onError={accountImageOnErrorHandler}
                            />
                          </div>
                          <div>
                            <div className="cus-info__name">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FARM
                              ).fullName || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FARM
                              ).code || TEXT_HOLDER}
                            </div>
                            <div className="cus-info__phone">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FARM
                              ).phoneNumber || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.FARM
                              ).email || TEXT_HOLDER}
                            </div>
                          </div>
                        </div>
                      ) : !selectedEmployeeFarm ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.FARM}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm khách hàng"
                          onChange={(value) => {
                            setSelectedEmployeeFarm(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeFarm.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeFarm.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeFarm.phoneNumber} - {selectedEmployeeFarm.email}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeFarm(null);
                            }}
                          />
                        </div>
                      )}

                      {/* vet */}
                      {infoEmployee.account.employees.find(
                        (c: any) => c.source === SourceType.VET
                      ) ? (
                        <div className="cus-info">
                          <div className="cus-info__img">
                            <img
                              src={
                                checkLinkImage(
                                  infoEmployee.account.employees.find(
                                    (c: any) => c.source === SourceType.VET
                                  )?.avatar
                                ) || accountImage
                              }
                              alt="img"
                              onError={accountImageOnErrorHandler}
                            />
                          </div>
                          <div>
                            <div className="cus-info__name">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.VET
                              ).fullName || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.VET
                              ).code || TEXT_HOLDER}
                            </div>
                            <div className="cus-info__phone">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.VET
                              ).phoneNumber || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.VET
                              ).email || TEXT_HOLDER}
                            </div>
                          </div>
                        </div>
                      ) : !selectedEmployeeVet ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.VET}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm khách hàng"
                          onChange={(value) => {
                            setSelectedEmployeeVet(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeVet.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeVet.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeVet.phoneNumber} - {selectedEmployeeVet.email}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeVet(null);
                            }}
                          />
                        </div>
                      )}

                      {/* lab */}
                      {infoEmployee.account.employees.find(
                        (c: any) => c.source === SourceType.LAB
                      ) ? (
                        <div className="cus-info">
                          <div className="cus-info__img">
                            <img
                              src={
                                checkLinkImage(
                                  infoEmployee.account.employees.find(
                                    (c: any) => c.source === SourceType.LAB
                                  )?.avatar
                                ) || accountImage
                              }
                              alt="img"
                              onError={accountImageOnErrorHandler}
                            />
                          </div>
                          <div>
                            <div className="cus-info__name">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.LAB
                              ).fullName || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.LAB
                              ).code || TEXT_HOLDER}
                            </div>
                            <div className="cus-info__phone">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.LAB
                              ).phoneNumber || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.LAB
                              ).email || TEXT_HOLDER}
                            </div>
                          </div>
                        </div>
                      ) : !selectedEmployeeLab ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.LAB}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm khách hàng"
                          onChange={(value) => {
                            setSelectedEmployeeLab(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeLab.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeLab.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeLab.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedEmployeeLab.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeLab(null);
                            }}
                          />
                        </div>
                      )}

                      {/* ccu */}
                      {infoEmployee.account.employees.find(
                        (c: any) => c.source === SourceType.CCU
                      ) ? (
                        <div className="cus-info">
                          <div className="cus-info__img">
                            <img
                              src={
                                checkLinkImage(
                                  infoEmployee.account.employees.find(
                                    (c: any) => c.source === SourceType.CCU
                                  )?.avatar
                                ) || accountImage
                              }
                              alt="img"
                              onError={accountImageOnErrorHandler}
                            />
                          </div>
                          <div>
                            <div className="cus-info__name">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.CCU
                              ).fullName || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.CCU
                              ).code || TEXT_HOLDER}
                            </div>
                            <div className="cus-info__phone">
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.CCU
                              ).phoneNumber || TEXT_HOLDER}{" "}
                              -{" "}
                              {infoEmployee.account.employees.find(
                                (c: any) => c.source === SourceType.CCU
                              ).email || TEXT_HOLDER}
                            </div>
                          </div>
                        </div>
                      ) : !selectedEmployeeCcu ? (
                        <SelectInput<CustomerModel>
                          getSource={SourceType.CCU}
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          placeholder="Tìm kiếm khách hàng"
                          onChange={(value) => {
                            setSelectedEmployeeCcu(value);
                            onUpdateCustomer(value!.id, value!.id, value!.source, value!);
                          }}
                          getOptionLabel={(opt) => opt.fullName}
                          renderOption={(opt) => (
                            <div
                              style={{
                                display: "flex",
                                cursor: "pointer",
                                margin: "2.1rem 0 0",
                                width: "100%",
                                borderBottom: "1px solid #d6d5d6",
                              }}>
                              <div>
                                <div style={{ fontSize: "1.8rem" }}>
                                  {opt.fullName || TEXT_HOLDER} - {opt.code || TEXT_HOLDER}
                                </div>
                                <div
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#adacac",
                                    padding: "0.4rem 0 2rem",
                                  }}>
                                  {opt.phoneNumber || TEXT_HOLDER} - {opt.email || TEXT_HOLDER}
                                </div>
                              </div>
                            </div>
                          )}
                          actionConfig={{
                            action: EmployeeService.getEmployee,
                          }}
                        />
                      ) : (
                        <div className="item-selected">
                          <div className="name">
                            {selectedEmployeeCcu.fullName || TEXT_HOLDER} -{" "}
                            {selectedEmployeeCcu.code || TEXT_HOLDER}
                          </div>
                          <div className="phone">
                            {selectedEmployeeCcu.phoneNumber || TEXT_HOLDER} -{" "}
                            {selectedEmployeeCcu.email || TEXT_HOLDER}
                          </div>

                          <ClearIcon
                            className="clear-icon"
                            onClick={() => {
                              setSelectedEmployeeCcu(null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col access">
                      {/* {infoEmployee.account.employees.find((c: any) => c.source === SourceType.FEED) ? (
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                        </div>
                      ) : (
                        <Checkbox
                          className="app-check-box"
                          checked={selectedEmployeeFeed !== null}
                          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        />
                      )}

                      {infoEmployee.account.employees.find((c: any) => c.source === SourceType.FARM) ? (
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                        </div>
                      ) : (
                        <Checkbox
                          className="app-check-box"
                          checked={selectedEmployeeFarm !== null}
                          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        />
                      )}

                      {infoEmployee.account.employees.find((c: any) => c.source === SourceType.CCU) ? (
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "1.7rem", color: "#fff" }} />
                        </div>
                      ) : (
                        <Checkbox
                          className="app-check-box"
                          checked={selectedEmployeeCcu !== null}
                          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        />
                      )} */}
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.feed}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color:
                                selectedEmployeeFeed === null &&
                                !infoEmployee.account.employees.find(
                                  (c: any) => c.source === SourceType.FEED
                                )
                                  ? "#efefef"
                                  : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: !isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.farm}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color:
                                selectedEmployeeFarm === null &&
                                !infoEmployee.account.employees.find(
                                  (c: any) => c.source === SourceType.FARM
                                )
                                  ? "#efefef"
                                  : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: !isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.vet}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color:
                                selectedEmployeeVet === null &&
                                !infoEmployee.account.employees.find(
                                  (c: any) => c.source === SourceType.VET
                                )
                                  ? "#efefef"
                                  : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: !isActiveSource.vet,
                            lab: isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.lab}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color:
                                selectedEmployeeLab === null &&
                                !infoEmployee.account.employees.find(
                                  (c: any) => c.source === SourceType.LAB
                                )
                                  ? "#efefef"
                                  : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.vet,
                            lab: !isActiveSource.lab,
                            ccu: isActiveSource.ccu,
                          })
                        }
                      />
                      <Checkbox
                        className="app-check-box"
                        checked={isActiveSource.ccu}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: "2.2rem",
                              color:
                                selectedEmployeeCcu === null &&
                                !infoEmployee.account.employees.find(
                                  (c: any) => c.source === SourceType.CCU
                                )
                                  ? "#efefef"
                                  : "",
                            }}
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        onChange={() =>
                          setIsActiveSource({
                            feed: isActiveSource.feed,
                            farm: isActiveSource.farm,
                            vet: isActiveSource.farm,
                            lab: isActiveSource.farm,
                            ccu: !isActiveSource.ccu,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="title">{t("LOGIN_NAME")}</div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "100%", marginRight: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">{t("DISPLAY_NAME")}</div>
                      <Field
                        name="account.nickname"
                        validate={FormUtil.Rule.required("Nhập tên đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập tên đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_PHONE")}</div>
                      <Field
                        name="account.phoneNumber"
                        // validate={
                        //   requireOTP ? FormUtil.Rule.required("Nhập SĐT đăng nhập") : undefined
                        // }
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_PHONE").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="otp">
                        <Checkbox
                          className="app-check-box"
                          checked={requireOTP}
                          onChange={() => setRequireOTP(!requireOTP)}
                        />
                        <div className="otp-text">{t("OTP_VERIFICATION")}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "100%", marginLeft: "3.5rem" }}>
                    <div className="form-item">
                      <div className="form-label">Mã đăng nhập</div>
                      <Field
                        name="account.code"
                        validate={FormUtil.Rule.required("Nhập mã đăng nhập")}>
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder="Nhập mã đăng nhập..."
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="form-item">
                      <div className="form-label">{t("LOGIN_EMAIL")}</div>
                      <Field
                        name="account.email"
                        // validate={FormUtil.Rule.required("Nhập Email đăng nhập")}
                      >
                        {({ input, meta, ...rest }) => {
                          return (
                            <TextField
                              {...input}
                              {...rest}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("LOGIN_EMAIL").toLowerCase(),
                              })}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
      <ModalFooter
        formId="emp-account"
        showDeleteButton={false}
        handleDeleteAction={() => {
          // toggleDeleteModal();
        }}
        handleCancelAction={() => closeForm()}
        buttonSelect={false}
        title={"Lưu"}
      />
    </div>
  );
};

export default CreateEmployee;
