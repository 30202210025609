import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const VehicleService = {
  updateVehicleOrder: (
    data : any
  ) => {
    const url = REQUEST_URL.UPDATE_ORDER_VEHICLE;
    return RequestUtil.put({url, data})
  },

  getListCombineOrder: (data : any) => {
    const url = REQUEST_URL.GET_LIST_COMBINE_ORDER;
    return RequestUtil.get({url, query : data})
  },

  updateShippingFee: (data: any) => {
    const url = REQUEST_URL.ADD_SHIPPING_FEE;
    return RequestUtil.put({url, data})
  },

  getAllVehicle: (data : any) => {
    const url = REQUEST_URL.GET_ALL_VEHICLE;
    return RequestUtil.get({url, query: data});
  },

  updateVehicle: (id : string, data: any) => {
    const url = REQUEST_URL.UPDATE_VEHICLE.replace("{id}", id);
    return RequestUtil.put({url, data});
  },

  createVehicle: (data : any) => {
    const url = REQUEST_URL.CREATE_VEHICLE;
    return RequestUtil.post({url, data});
  },

  getDetailVehicle: (id : string) => {
    const url = REQUEST_URL.UPDATE_VEHICLE.replace("{id}", id);
    return RequestUtil.get({url});

  }
  
};
