import { useAppSelector } from "@Core/store/hook";
import { LoadMe } from "@Core/store/profile";
import { ItemPermission, OrderPermission, SourceType } from "@Models";
import { PermissionUtil } from "@Utils";
import _, { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { DivisionCode, PigTypeCode } from "src/types";

export default function useGetMe () {
  const { data: profile } = useAppSelector(LoadMe);

  const permissions = useMemo(() => {
    const allPermissions = profile?.role?.permissions || [];
    return {
      hasPermissionOrder: PermissionUtil.check(
        allPermissions,
        OrderPermission.MANAGE_ORDER_RECALL,
        true
      ),
      hasPermissionOrderDetail: PermissionUtil.check(
        allPermissions,
        OrderPermission.MANAGE_ORDER_VIEWDETAILS,
        true
      ),
      hasPermissionViewPrice: PermissionUtil.check(
        allPermissions,
        ItemPermission.MANAGE_ITEM_VIEW_PRICE,
        true
      ),
      hasPermissionExportExcel: PermissionUtil.check(
        allPermissions,
        OrderPermission.MANAGE_ORDER_EXPORT,
        true
      ),
      hasPermissionOrderCreate: PermissionUtil.check(
        allPermissions,
        OrderPermission.MANAGE_ORDER_ADD,
        true
      ),
      hasPesmissionApprovalOrRejectCustomerRequest: PermissionUtil.check(
        allPermissions,
        //OrderPermission.MANAGE_ORDER_ADD,
        'aaaaaaaaa',
        true
      )
    };
  }, [profile]);

  const getPigTypeIdByCode = useCallback((code: PigTypeCode) => {
    return profile?.itemGroupTypeFarm?.find((x) => x.code === code)?.id;
  }, [profile]);

  const getDivisionIdByCode = useCallback((source: SourceType, code: DivisionCode) => {
    const dataAccessPermissions = _.get(profile, "role.dataAccessPermissions", []).find(
      (x) => x.source === source
    );

    if (!dataAccessPermissions) return null;

    return _.find((dataAccessPermissions as any)?.divisions, { code })?.id;
  }, [profile])

  const comparePigType = (id: number | string | null, code: PigTypeCode): boolean => {
    if (isNil(id)) return false;

    return getPigTypeIdByCode(code)?.toString() === id?.toString();
  };

  return {
    profile,
    permissions,
    getPigTypeIdByCode,
    comparePigType,
    getDivisionIdByCode,
  };
}
