import { REQUEST_URL } from "@Constants";
import {
  GetListPriceShippingQuery,
  PaginationQueryModel,
  PaginationQueryModelPartner,
} from "@Models";
import { RequestUtil } from "@Utils";
import { GetAllFarmProductQuery } from "src/dtos/product.dto";

export const ProductService = {
  getDivision: (
    query: PaginationQueryModel & { searchValue?: string } = {
      page: 1,
      limit: 10,
      source: "feed",
    }
  ) => {
    const url = REQUEST_URL.DIVISION;
    return RequestUtil.get({ url, query: query });
  },

  getDivisionBySource: (query: {
    getAll?: boolean;
    searchValue?: string;
    source?: string;
    sources?: string;
  }) => {
    const url = REQUEST_URL.DIVISION;
    return RequestUtil.get({ url, query: query });
  },

  getItems: (divisionId: number, customerId: string, source: string) => {
    const url = REQUEST_URL.FILTER_PRODUCT;
    return RequestUtil.get({
      url,
      query: { divisionId: divisionId, customerId: customerId, source: source },
    });
  },

  createDivisionById(divisionId: string, formData: FormData) {
    const url = `${REQUEST_URL.DIVISION_UPLOAD_IMG}/${divisionId}`;

    return RequestUtil.post({ url, data: formData });
  },

  getProductByAdmin: (
    query: PaginationQueryModel & {
      customerId: string;
      divisionId?: string | null;
      searchValue?: string;
      itemGroupId?: string;
    }
  ) => {
    const url = REQUEST_URL.FILTER_PRODUCT;
    return RequestUtil.get({ url, query });
  },

  getListProductByAdmin: (
    query: PaginationQueryModel & {
      divisionId?: string | null;
      searchValue?: string;
    }
  ) => {
    const url = REQUEST_URL.LIST_PRODUCT;
    return RequestUtil.get({ url, query });
  },

  getAllProduct: (query: PaginationQueryModelPartner = { pageLimit: 10, pageNumber: 1 }) => {
    return RequestUtil.get({
      url: `${REQUEST_URL.FILTER_PRODUCT}`,
      query,
    });
  },

  getProductById: (id: string) => {
    return RequestUtil.get({
      url: `${REQUEST_URL.FILTER_PRODUCT}/${id}`,
    });
  },

  createProductFarm: (formData: FormData) => {
    const url = REQUEST_URL.CREATE_PRODUCT_FARM;

    return RequestUtil.post({ url, data: formData });
  },

  updateProductFarm: (id: string, formData: FormData) => {
    const url = REQUEST_URL.UPDATE_PRODUCT_FARM.replace("{id}", id);

    return RequestUtil.put({ url, data: formData });
  },

  uploadImage(id: string, formData: FormData) {
    const url = REQUEST_URL.UPDATE_IMG_PRODUCT.replace("{id}", id);

    return RequestUtil.post({ url, data: formData });
  },

  getItemById: (query: {
    itemIds: any;
    source: string;
    customerId: number;
    targetId?: number;
    objectiveId?: number;
  }) => {
    const url = REQUEST_URL.ITEM_BY_ID;
    return RequestUtil.get({
      url,
      query: query,
    });
  },

  // chỗ này chỉ gọi ở farm

  getProductByAdminFarm: (
    query: PaginationQueryModel & {
      customerId: string;
      divisionId?: string | null;
      searchValue?: string;
      itemGroupId?: string;
    }
  ) => {
    const url = REQUEST_URL.FILTER_PRODUCT_BY_ADMIN;
    return RequestUtil.get({ url, query });
  },
  getAllFarmProduct: (query: GetAllFarmProductQuery) => {
    let url: string = REQUEST_URL.FILTER_PRODUCT_BY_ADMIN;

    if (query.isInternal) {
      url = REQUEST_URL.FILTER_PRODUCT_BY_ADMIN_INTERNAL;
    }

    delete query.isInternal;

    return RequestUtil.get({ url, query });
  },
  getTimeFromSap: (query: { page: number; limit: number }) => {
    const url = "/order/deliery-times";
    return RequestUtil.get({ url, query });
  },

  getListPriceShipping: (query: GetListPriceShippingQuery) => {
    const url = REQUEST_URL.LIST_PRICE_SHIPPING;
    return RequestUtil.get({ url, query });
  },
};
