import { GFProductItemAvatar, GFProductItemWrapper } from "@Components/ui/GFProductItem/styles";
import GFTypography from "@Components/ui/GFTypography";
import { TEXT_ASTERISK } from "@Constants";
import useGetMe from "@Hooks/useGetMe";
import { Box } from "@mui/material";
import {
  checkLinkImage,
  currencyFormat,
  formatCurrency,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";

type GFProductItemProps = {
  avatarUrl?: string;
  name?: string;
  material?: string;
  value?: number | string;
  baseUoM?: string;
  source?: string;
  isActive?: boolean;
  shơwPrice?: boolean;
  hover?: boolean;
  size?: "small" | "medium";
  showImg?: boolean;
};

export default function GFProductItem({
  avatarUrl,
  baseUoM,
  material,
  name,
  value,
  source,
  isActive = false,
  shơwPrice = true,
  hover = true,
  size = "medium",
  showImg = true,
}: GFProductItemProps) {
  const {
    permissions: { hasPermissionViewPrice },
  } = useGetMe();
  return (
    <GFProductItemWrapper isActive={isActive} hover={hover}>
      {showImg && (
        <GFProductItemAvatar
          src={checkLinkImage(avatarUrl!) || renderImageProNull(source)}
          alt={name}
          onError={renderErrorImageItem()}
          size={size}
        />
      )}
      <Box>
        <GFTypography type="primary">{name}</GFTypography>
        <GFTypography type="secondary">{material}</GFTypography>
        {shơwPrice && (
          <GFTypography variant="body1" type="primary-light">
            {hasPermissionViewPrice
              ? `${formatCurrency(value as number)}/${baseUoM}`
              : TEXT_ASTERISK}
          </GFTypography>
        )}
      </Box>
    </GFProductItemWrapper>
  );
}
