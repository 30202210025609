import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.scss"
export default function Navbar() {
    const location = useLocation();
    const searchQuery = new URLSearchParams(location.search);
    const data = [
        {
            label: "Phương thức tỉ lệ cọc",
            value: "1",
        },
        {
            label: "Loại xe",
            value: "2",
        }
    ]
    return (
        <div className="wrapper-warehouse">
            <div className={"sub-route-menu"}>
                <div style={{ display: "flex" }}>
                    {data.map((item, index) => {
                        let isActive = false;
                        if (!searchQuery.get("type")) {
                            isActive = item.value === "1"
                        }
                        else {
                            isActive = searchQuery.get("type") === item.value
                        }
                        return (
                            <Link key={item.label} to={`?type=${item.value}`}>
                                <div
                                    className={
                                        isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                                    }>
                                    {/* <div className="icon">{isActive ? activeIcon : defaultIcon}</div> */}
                                    <span className="title">{item.label}</span>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}