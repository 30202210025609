import { REQUEST_URL } from "@Constants";
import { PaginationQueryModel } from "@Models";
import { RequestUtil } from "@Utils";

export const ExportService = {
  exportAccount() {
    const url = REQUEST_URL.EXPORT_ACCOUNT;

    return RequestUtil.get({ url });
  },

  getExportEmployee(data: { source: any }) {
    const url = REQUEST_URL.EXPORT_EMPLOYEE;

    return RequestUtil.get({ url, query: data });
  },

  exportOrder: (query: { from: string; to: string; source: string; strSearch: string }) => {
    const url = REQUEST_URL.EXPORT_ORDER;

    return RequestUtil.get({ url, query: query });
  },

  exportPrice: (
    query: PaginationQueryModel & { searchValue?: string; source?: string; salesOrgId: number }
  ) => {
    const url = REQUEST_URL.EXPORT_PRICE;

    return RequestUtil.get({ url, query: query });
  },

  exportCus(data: { source: any }) {
    const url = REQUEST_URL.EXPORT_CUSTOMER;

    return RequestUtil.get({ url, query: data });
  },

  getExportItem(data: { source: any; divisionId: any }) {
    const url = REQUEST_URL.EXPORT_ITEM;

    return RequestUtil.get({ url, query: data });
  },

  getExportDebt(data: { period: string }) {
    const url = REQUEST_URL.EXPORT_DEBT;

    return RequestUtil.get({ url, query: data });
  },

  getExportReport(data: { period: string }) {
    const url = REQUEST_URL.EXPORT_REPORT;

    return RequestUtil.get({ url, query: data });
  },

  getRefreshStatus(data: { type: string; source?: string }) {
    const url = REQUEST_URL.REFRESH_STATUS;

    return RequestUtil.get({ url, query: data });
  },

  getExportLeadData(data: { id: string }) {
    const url = REQUEST_URL.EXPORT_LEAD.replace("{id}", data.id);

    return RequestUtil.get({ url });
  },

  exportSms(data: { type: string; period: string; status: string; accountType?: string }) {
    const url = REQUEST_URL.EXPORT_SMS;

    return RequestUtil.get({ url, query: data });
  },

  exportNoti(data: { type: string; period: string; status: string }) {
    const url = REQUEST_URL.EXPORT_NOTIFICATION;

    return RequestUtil.get({ url, query: data });
  },

  exportOrderFarm: (query: { from: string; to: string }) => {
    const url = REQUEST_URL.EXPORT_ORDER_FARM;

    return RequestUtil.get({ url, query: query });
  },

  getExportFarmDebt(data: { period: string }) {
    const url = REQUEST_URL.EXPORT_DEBT_FARM;

    return RequestUtil.get({ url, query: data });
  },

  getExportReportRevenue(data: { period: string }) {
    const url = REQUEST_URL.EXPORT_REPORT_REVENUE;

    return RequestUtil.get({ url, query: data });
  },

  exportPlanById(planId: string) {
    const url = REQUEST_URL.EXPORT_ORDER_PLAN?.replace("{planId}", planId);
    return RequestUtil.get({ url });
  },
};
