import GFCheckbox from "@Components/ui/GFCheckbox";
import { GFDataTableColumn, GFDataTableExpandable } from "@Components/ui/GFDataTable";
import { actionColumn } from "@Components/ui/GFDataTable/GFDataTableAction";
import GFDataTableCell from "@Components/ui/GFDataTable/GFDataTableCell";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { styled } from "@mui/material/styles";
import _, { isFunction, isNil } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { GFDataTableAction } from "src/types";

const StyledAccordion = styled(Accordion)({
  "&.MuiAccordion-root": {
    position: "unset",
    margin: "0px",
    boxShadow: "unset",
    "&:before": {
      display: "none",
    },
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  "&.MuiAccordionSummary-root": {
    position: "absolute",
    left: 12,
    top: 2,
    zIndex: 1,
    minWidth: "4.8rem",
    minHeight: "4.8rem",
    maxHeight: "4.8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "2.4rem",
    },
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  "&.MuiAccordionDetails-root": {
    borderTop: "2px dashed #dddd",
    padding: "1.6rem",
    fontSize: "1.6rem",
  },
});

export type GFCheckboxProps = {
  showCheckbox: (record: any) => boolean;
  selectedRecord: any[];
  onSelectedChange: (selectedRecords: any[]) => void;
};

type GFDataTableRowProps = {
  record: any;
  columns: GFDataTableColumn[];
  rowIndex: number;
  expandable?: GFDataTableExpandable;
  showActions?: GFDataTableAction;
  rowPrefix?: string;
  cellClick?: string[];
  showCheckboxConfigs?: GFCheckboxProps;
  bottomContent?: (record: any, rowIndex: number) => React.ReactNode;
  onRowClick?: (code: string | number | null, record: any) => void;
  onChange: (record: any, dataIndex: string, newValue: any) => void;
};

export default function GFDataTableRow({
  record,
  columns,
  rowIndex,
  expandable,
  showActions,
  rowPrefix = "",
  showCheckboxConfigs,
  cellClick,
  bottomContent,
  onChange,
  onRowClick,
}: GFDataTableRowProps) {
  const [currentSelected, setCurrentSelected] = useState(false);
  const hasExpandable = isNil(expandable) ? false : true;
  const isRowExpandable = expandable && expandable.rowExpandable(record);

  const { onSelectedChange, selectedRecord, showCheckbox } = showCheckboxConfigs || {};

  const hasCheckbox = !isNil(showCheckboxConfigs) ? true : false;
  const isShowCheckbox = !isNil(showCheckbox) ? showCheckbox(record) : false;

  const isShowEditButton = showActions
    ? isNil(showActions.showActionEditButton)
      ? true
      : showActions.showActionEditButton(record)
    : false;

  const isShowDeleteButton = showActions
    ? isNil(showActions.showActionDeleteButton)
      ? true
      : showActions.showActionDeleteButton(record)
    : false;

  const showActionColumn = isNil(showActions) ? false : true;

  const handleEdit = () => {
    showActions?.onActionEdit && showActions?.onActionEdit(record.id, record);
  };

  const handleDelete = () => {
    showActions?.onActionDelete && showActions?.onActionDelete(record.id, record);
  };

  const handleRowClick = (code: any, record: any) => {
    if (cellClick && cellClick.length > 0)
      onRowClick && cellClick?.includes(code) && onRowClick(code, record);
    else
      onRowClick && onRowClick(code, record);
  };

  const handleExpandClick = () => {
    if (!isRowExpandable) {
      handleRowClick("expand", record);
    }
  };

  const debouncedSelectedChange = useCallback(
    _.debounce((selectedIds: (number | string)[]) => {
      onSelectedChange && onSelectedChange(selectedIds);
    }, 300),
    [onSelectedChange]
  );

  const handleToggleSelected = () => {
    setCurrentSelected(!currentSelected);

    if (isFunction(onSelectedChange)) {
      if (currentSelected) {
        debouncedSelectedChange(selectedRecord?.filter((item) => item.id !== record.id) ?? []);
      } else {
        debouncedSelectedChange([...(selectedRecord ?? []), record]);
      }
    }
  };

  useEffect(() => {
    if (!isNil(selectedRecord)) {
      const isSelected = selectedRecord.some((item) => item.id === record.id);
      setCurrentSelected(isSelected);
    }
  }, [selectedRecord, record.id]);

  const prefixClassName = rowPrefix
    ? `${rowPrefix} ${record?.status ? `${rowPrefix}--${record.status}` : ""}`
    : "";

  return (
    <div
      className={`gf-datatable__body__item ${prefixClassName} ${
        isFunction(onRowClick) ? "pointer" : ""
      }`}>
      <div className="gf-datatable__row">
        {hasExpandable && (
          <div className="gf-datatable__expanded__temp" onClick={handleExpandClick}></div>
        )}
        {hasCheckbox && (
          <GFDataTableCell
            cellData=""
            dataIndex=""
            record={record}
            rowIndex={rowIndex}
            column={{
              width: 48,
              render: () =>
                isShowCheckbox ? (
                  <GFCheckbox
                    size="small"
                    checked={currentSelected}
                    onChange={handleToggleSelected}
                  />
                ) : null,
            }}
          />
        )}
        {columns.map((column, index) => {
          const cellData = column.dataIndex ? _.get(record, column.dataIndex) : "";
          return (
            <GFDataTableCell
              key={(column.dataIndex as any) ?? index}
              cellData={cellData}
              record={record}
              rowIndex={rowIndex}
              column={column}
              onChange={onChange}
              dataIndex={column.dataIndex}
              onCLick={handleRowClick}
            />
          );
        })}
        {showActionColumn && (
          <GFDataTableCell
            cellData=""
            dataIndex=""
            record={record}
            rowIndex={rowIndex}
            column={actionColumn({
              onEdit: handleEdit,
              onDelete: handleDelete,
              showEdit: isShowEditButton,
              showDelete: isShowDeleteButton,
            })}
            onChange={onChange}
          />
        )}
      </div>
      {isRowExpandable && (
        <StyledAccordion>
          <StyledAccordionSummary expandIcon={<ExpandMore />} />
          <StyledAccordionDetails>
            {expandable && expandable.rowExpandable(record) && (
              <div className="gf-datatable__expanded">{expandable.expandedRowRender(record)}</div>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      )}
      {isFunction(bottomContent) && bottomContent(record, rowIndex)}
    </div>
  );
}
