import { Clear as ClearIcon } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import Banner from "@Assets/images/banner-not-access.png";
import Icon from "@Assets/images/permission.jpg";

const NotAccess: React.FC = () => {
  const [show, setShow] = useState<boolean>(true);
  return (
    <Dialog open={show} fullScreen>
      <div style={{ height: "100vh", width: "100%", position: "relative" }}>
        <img src={Banner} alt="comingsoon" width="100%" height="100%" />
        <ClearIcon
          style={{
            fontSize: "2.2rem",
            cursor: "pointer",
            position: "absolute",
            top: "2.5rem",
            right: "3rem",
          }}
          onClick={() => setShow(false)}
        />
        <div
          style={{
            position: "absolute",
            top: "30%",
            left: "20%",
            fontFamily: "SVN-Gotham",
            color: "#312F30",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <img src={Icon} alt="permission" width="113" height="150" />
          <div
            style={{
              fontWeight: 700,
              fontSize: "3.6rem",
              lineHeight: "4.2rem",
              padding: "8rem 0 1.2rem",
            }}>
            Không có quyền truy cập
          </div>
          <div style={{ fontWeight: 400, fontSize: "2rem", lineHeight: "3.1rem" }}>
            Rất tiếc, bạn không có quyền truy cập tính năng này
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default NotAccess;
