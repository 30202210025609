//output
export type DepositOutputModel = {
  id: number;
  code: string;
  name: string;
  type: string;
  rate1: number;
  rate2: number;
  rate3: number;
  appliedDate: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  itemGroupTypes: ItemGroupTypeModel[];
};

export type ItemGroupTypeModel = {
  id: number;
  code: string;
  name: string;
  otherName: string;
  source: string;
  image: string;
  codePoint: string | null;
  createdAt: string;
  updatedAt: string;
};

//enum
export enum E_DEPOSIT_TYPE {
  DEPOSIT_1 = "deposit1",
  DEPOSIT_2 = "deposit2",
  DEPOSIT_3 = "deposit3",
}

//input
export type DepositInputModel = {
  code?: string;
  name: string;
  type: string;
  itemGroupTypeIds: number[];
  rate1: number;
  rate2: number;
  rate3: number;
  appliedDate: string;
  isActive?: boolean;
};

//mock
export const DATA_DEPOSIT_METHOD = [
  { id: E_DEPOSIT_TYPE.DEPOSIT_1, label: "Cọc 1 lần" },
  { id: E_DEPOSIT_TYPE.DEPOSIT_2, label: "Cọc 2 lần" },
  { id: E_DEPOSIT_TYPE.DEPOSIT_3, label: "Cọc 3 lần" },
];
