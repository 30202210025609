import {
  GFAutoComplete,
  GFCustomerAvatar,
  GFFormItem,
  GFIconButton,
  GFTypography,
} from "@Components/ui";
import GFSearchWithInfiniteScroll from "@Components/ui/GFSearchWithInfiniteScroll";
import { OrderBoarSemenHeaderWrapper } from "@Containers/Order/components/BoarSemen/OrderBoarSemen/OrderBoarSemenHeader/styles";
import { CustomerOutputModel, SourceType } from "@Models";
import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { useCustomersInfinite } from "src/app/queries/customer.query";
import { CreateOrderPayload } from "src/schemas/order.schema";
import { FarmType, SelectOption } from "src/types";

type OrderBoarSemenHeaderProps = {
  sourceType: SourceType;
  type: FarmType;
  control: Control<CreateOrderPayload>;
  customer: CustomerOutputModel | null;
  onSelectCustomer: (customer: CustomerOutputModel | null) => void;
  setValue: UseFormSetValue<CreateOrderPayload>;
};

export default function OrderBoarSemenHeader({
  type,
  sourceType,
  control,
  customer,
  onSelectCustomer,
  setValue,
}: OrderBoarSemenHeaderProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const camps: SelectOption[] =
    customer?.camps?.map((c) => {
      return {
        label: c.name,
        value: c.id,
      };
    }) || [];

  const {
    customers,
    hasCustomerNextPage,
    customerFetching,
    customerLoading,
    fetchCustomerNextPage,
    totalCustomer,
  } = useCustomersInfinite({
    limit: 10,
    source: sourceType,
    strSearch: searchValue,
    isActive: true,
    hasCusDiv: true,
    isInternal: type === FarmType.Internal,
    isSemenFarm: true,
  });

  const handleSelectCustomer = (customer: CustomerOutputModel) => {
    setValue(
      "distributionChannelId",
      _.get(customer, "customerDivisions[0].distributionChannel.id", 0)
    );
    setValue("salesOfficeId", _.get(customer, "customerDivisions[0].salesOfficeFarm.id", 0));
    setValue("salesOrgId", _.get(customer, "customerDivisions[0].salesOrg.id", 0));
    setValue("orderDetails", []);
    onSelectCustomer(customer);
  };

  const handleRemoveCustomer = () => {
    onSelectCustomer(null);
    setValue("distributionChannelId", 0);
    setValue("salesOrgId", 0);
    setValue("salesOfficeId", 0);
    setValue("orderDetails", []);
  };

  return (
    <OrderBoarSemenHeaderWrapper>
      {customer ? (
        <>
          <GFCustomerAvatar
            avatarUrl={customer.account?.avatar}
            fullName={customer.fullName}
            code={customer.code}
            phoneNumber={customer.phoneNumber}
            dvkd={_.get(customer, "customerDivisions[0].salesOrg.description")}
            type="primary"
          />
          <Box sx={{ minWidth: "45rem" }}>
            <GFTypography variant="body1" type="primary">{customer.company}</GFTypography>
          </Box>
          <GFIconButton onClick={handleRemoveCustomer}>
            <Close />
          </GFIconButton>
        </>
      ) : (
        <GFSearchWithInfiniteScroll
          data={customers}
          loading={customerLoading}
          fetchingNextPage={customerFetching}
          hasMore={hasCustomerNextPage}
          searchPlaceholder={"Tìm kiếm khách hàng"}
          onGetNextPage={fetchCustomerNextPage}
          width="45rem"
          popoverHeight="30rem"
          renderItem={(item) => (
            <GFCustomerAvatar
              avatarUrl={item.account?.avatar}
              fullName={item.fullName}
              code={item.code}
              phoneNumber={item.phoneNumber}
              size="small"
              center
              showDot={item.camps?.length > 0 ? true : false}
            />
          )}
          searchValue={searchValue}
          onClickItem={handleSelectCustomer}
          onSearchChange={(value) => {
            setSearchValue(value);
          }}
        />
      )}
    </OrderBoarSemenHeaderWrapper>
  );
}
