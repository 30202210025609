import { REQUEST_URL } from "@Constants";
import { PaginationModel, PaginationQueryModel, SubAccountInputModel } from "@Models";
import { RequestUtil } from "@Utils";
import { GetAllBoarSemenCustomerPayload } from "src/dtos/customer.dto";

export const CustomerService = {
  getCustomer: (query: PaginationQueryModel & { source: string; strSearch?: string }) => {
    const url = REQUEST_URL.CUSTOMER;

    return RequestUtil.get({ url, query });
  },

  getAllCustomer: (
    query: PaginationModel & {
      source: string;
      group?: number;
      strSearch?: string;
      isActive?: boolean;
    }
  ) => {
    const url = REQUEST_URL.ALL_CUSTOMER;

    return RequestUtil.get({ url, query });
  },

  getAllBoarSemenCustomer: (
    query: GetAllBoarSemenCustomerPayload
  ) => {
    const url = REQUEST_URL.ALL_CUSTOMER;

    return RequestUtil.get({ url, query });
  },

  changePassword: (data: { oldPassword: string; newPassword: string }) => {
    const url = REQUEST_URL.CHANGE_PW;

    return RequestUtil.put({ url, data: data });
  },

  getCustomerById(customerId: string) {
    const url = `${REQUEST_URL.CUSTOMER_DETAIL}/${customerId}`;

    return RequestUtil.get({ url });
  },

  getAuthProfile() {
    const url = REQUEST_URL.AUTH_PROFILE;

    return RequestUtil.get({ url });
  },

  createSignUpAccount(data: {
    email: string;
    phoneNumber: string;
    nickname: string;
    code: string;
    users?: {
      userId: number;
      source: string;
    }[];
    roleId?: number;
    requireOTP?: boolean;
    isActive?: boolean;
    isEmployee: boolean;
  }) {
    const url = REQUEST_URL.SIGNUP_ACCOUNT;

    return RequestUtil.post({ url, data });
  },

  updateAccount: (data: {
    id: string;
    code: string;
    email: string;
    phoneNumber: string;
    name: string;
    requireOTP: boolean;
    isActive: boolean;
    customers?: {
      customerId: string;
      source: string;
    }[];
    isEmployee?: boolean;
  }) => {
    const url = REQUEST_URL.UPDATE_ACCOUNT.replace("{id}", data.id);
    return RequestUtil.put({ url, data });
  },

  uploadImage(type: string, formData: FormData) {
    const url = REQUEST_URL.CUSTOMER_UPLOADIMAGE.replace("{type}", type);

    return RequestUtil.put({ url, data: formData });
  },

  getOrdCustomerById: (
    query: PaginationModel & {
      from?: string;
      to?: string;
      searchValue?: string;
      id: string;
      source: string;
    }
  ) => {
    const url = `${REQUEST_URL.CUSTOMER_ORDER}/${query.id}`;
    return RequestUtil.get({ url, query });
  },

  getDebtCustomerById: (
    query: PaginationModel & {
      from?: string;
      to?: string;
      searchValue?: string;
      customerId: string;
      source: string;
    }
  ) => {
    const url = REQUEST_URL.CUSTOMER_DEBT;

    return RequestUtil.get({ url, query });
  },

  getAllAccount: (
    query: PaginationModel & {
      from?: string;
      to?: string;
      searchString?: string;
      isActive?: boolean;
      isCancel?: boolean;
      isEmployee: boolean;
    }
  ) => {
    const url = REQUEST_URL.ACCOUNT;

    return RequestUtil.get({ url, query });
  },

  getAccountById: (id: string) => {
    const url = REQUEST_URL.ACCOUNT_DETAIL.replace("{id}", id);

    return RequestUtil.get({ url });
  },

  getAllLead: (
    query: PaginationModel & {
      from?: string;
      to?: string;
      status?: string;
      searchString?: string;
      getAll?: boolean;
      isCreateOrder?: boolean;
    }
  ) => {
    const url = REQUEST_URL.GET_LEAD;

    return RequestUtil.get({ url, query });
  },

  getLeadById: (id: string) => {
    const url = REQUEST_URL.LEAD_DETAIL.replace("{id}", id);

    return RequestUtil.get({ url });
  },

  createLead(data: {
    email: string;
    phoneNumber: string;
    fullName: string;
    address: string;
    sources: string;
    company: string;
    taxId: string;
    province: string;
    district: string;
    ward: string;
    note: string;
    employeeId?: number;
    idCard: string;
    idIssueDate: string;
    idIssuePlace: string;
    salesOrgId: 0[];
  }) {
    const url = REQUEST_URL.CREATE_LEAD;

    return RequestUtil.post({ url, data });
  },

  getAddress: (
    query: PaginationModel & {
      customerId: number;
      searchValue?: string;
      source: string;
    }
  ) => {
    const url = REQUEST_URL.GET_ADDRESS.replace(":customerId", query.customerId.toString());

    return RequestUtil.get({ url, query });
  },

  createAddress(data: {
    customerId: number;
    name: string;
    phoneNumber: string;
    country?: string | null;
    province?: string | null;
    district?: string | null;
    ward?: string | null;
    address: string;
  }) {
    const url = REQUEST_URL.CREATE_ADDRESS;

    return RequestUtil.post({ url, data });
  },

  changeStatus(data: { accountId: number; status: boolean; accountIds?: string[] }) {
    const url = REQUEST_URL.CHANGE_STATUS;

    return RequestUtil.post({ url, data });
  },

  getAllDebtReport: (
    data: PaginationQueryModel & {
      source: string;
      period: string;
      searchString?: string;
      salesOrgIds?: number[];
      divisionIds?: number[];
    }
  ) => {
    const url = REQUEST_URL.DEBT_REPORT;

    return RequestUtil.put({ url, data: data });
  },

  uploadIdentity: (formData: FormData) => {
    const url = REQUEST_URL.CUSTOMER_IDENTITY;
    return RequestUtil.put({ url, data: formData });
  },

  recoverAccount: (data: { id: number }) => {
    const url = REQUEST_URL.RECOVER_ACCOUNT;
    return RequestUtil.put({ url, data });
  },

  assignCustomerFarmOrder(data: {
    orderGroupId: number;
    leadId: number;
    customerId: number;
    fetchAll: boolean;
  }) {
    const url = REQUEST_URL.ASSIGN_CUSTOMER_FARM_ORDER;

    return RequestUtil.post({ url, data });
  },

  //sub-account
  getAllSubAccount: (
    query: PaginationModel & {
      strSearch?: string;
      status?: boolean;
    }
  ) => {
    const url = REQUEST_URL.SUB_ACCOUNT_GET_ALL;

    return RequestUtil.get({ url, query });
  },

  getSubAccountById: (id: string) => {
    const url = REQUEST_URL.SUB_ACCOUNT_GET_DETAIL.replace("{id}", id);

    return RequestUtil.get({ url });
  },

  createSubAccount(data: SubAccountInputModel) {
    const url = REQUEST_URL.SUB_ACCOUNT_CREATE;

    return RequestUtil.post({ url, data });
  },

  updateSubAccount(id: string, data: SubAccountInputModel) {
    const url = REQUEST_URL.SUB_ACCOUNT_UPDATE.replace("{id}", id);

    return RequestUtil.put({ url, data });
  },

  reviewSubAccount(
    id: string,
    data: {
      review: string;
      reason: string;
    }
  ) {
    const url = REQUEST_URL.SUB_ACCOUNT_REVIEW.replace("{id}", id);

    return RequestUtil.post({ url, data });
  },

  changeSubAccountStatus: (data: { accountId: number; isActive: boolean }) => {
    const url = REQUEST_URL.SUB_ACCOUNT_CHANGE_STATUS;

    return RequestUtil.put({ url, data: data });
  },

  changeSubAccountProvision: (id: string, data: { status: string }) => {
    const url = REQUEST_URL.SUB_ACCOUNT_PROVISION.replace("{id}", id);

    return RequestUtil.put({ url, data: data });
  },
};
