import { Widgets } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isNil, round } from "lodash";

export const GFButton = styled(Button)<{
  isActive?: boolean;
  rounded?: boolean;
  minWidth?: number;
}>(({ theme, color, variant, size, isActive, disabled, rounded, minWidth }) => ({
  "&.MuiButtonBase-root": {
    textTransform: "none",
    borderRadius: 4,
    paddingInline: "1.6rem",
    color: "var(--text-foreground)",
    fontSize: "1.6rem",
    height: "4.8rem",
    lineHeight: 1,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    fontWeight: 700,
    gap: "0.8rem",
    border: "1px solid transparent",
    ...(rounded && {
      borderRadius: "9999px",
    }),

    ...(minWidth && { minWidth: `${minWidth}rem` }),

    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },

    "& img": {
      width: "24px",
    },

    "&:focus": {
      borderColor: "var(--primary-green)",
    },
    "&:hover": {
      borderColor: "var(--primary-green)",
      backgroundColor: "var(--primary-green-hover)",
    },
    // --- VARIANTS ---
    ...((variant === "contained" || isActive) && {
      backgroundColor: "var(--primary-green)",
      color: "white",
      "& span": {
        color: "white",
      },
      "&:hover": {
        backgroundColor: "var(--primary-green)",
      },
    }),

    ...(variant === "outlined" && {
      borderColor: "var(--primary-green)",
      color: "var(--primary-green)",
    }),

    ...(variant === "text" && {
      backgroundColor: "white",
    }),

    ...(disabled && {
      backgroundColor: "var(--primary-gray-1)",
      color: "var(--text-foreground)",
      opacity: 0.5,
      "& span": {
        color: "var(--text-foreground)",
      },
      "&:hover": {
        backgroundColor: "var(--primary-gray-1)",
      },
    }),
    // --- SIZES ---
    ...(size === "small" && {
      fontSize: "1.4rem",
      height: "3.6rem",
      paddingInline: "0.8rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.6rem",
      },
    }),
    ...(size === "large" && {
      fontSize: "1.8rem",
      height: "5.6rem",
      paddingInline: "2rem",
      "& .MuiSvgIcon-root": {
        fontSize: "2.4rem",
      },
    }),

    // --- COLORS ---
    ...(color === "primary" && {
      color: "var(--primary-green)",
    }),
  },
}));

export const Counter = styled("span")({
  fontSize: "1.4rem",
  background: "white",
  borderRadius: "9999px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "2.4rem",
  fontWeight: 600,
  padding: "0.4rem",
  maxHeight: "2.7rem",
  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
  color: "var(--text-foreground)",
});

type GFButtonCounterProps = {
  counter?: number;
  textColor?: string;
  isActive?: boolean;
  children: React.ReactNode;
};

const GFButtonCounterStyled = styled(GFButton)<GFButtonCounterProps>(
  ({ theme, variant, isActive, textColor }) => ({
    "&.MuiButton-root": {
      padding: "0.95rem 1.6rem",
      borderRadius: "9999px",
      color: "var(--primary-green)",

      "&:hover": {
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
      },

      ...(textColor && {
        color: textColor,
      }),

      ...(isActive && {
        color: "white",
        "& .counter": {
          background: "var(--secondary-green)",
          color: "white",
        },
      }),
    },
  })
);

export const GFButtonCounter = ({
  counter,
  textColor,
  isActive,
  children,
  ...props
}: GFButtonCounterProps & ButtonProps) => {
  return (
    <GFButtonCounterStyled textColor={textColor} isActive={isActive} {...props}>
      {children}
      {!isNil(counter) && <Counter className="counter">{counter}</Counter>}
    </GFButtonCounterStyled>
  );
};
