import React, { useRef, useState, useEffect, PropsWithChildren, MouseEvent } from "react";
import "./styles.scss";

type GFGrabbingScrollProps = {
  className?: string;
  style?: React.CSSProperties;
  hiddenScrollBottom?: boolean;
};

const GFGrabbingScroll: React.FC<PropsWithChildren<GFGrabbingScrollProps>> = ({
  children,
  className = "",
  style = {},
  hiddenScrollBottom = false,
}) => {
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [canScrollHorizontally, setCanScrollHorizontally] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const position = useRef({ startX: 0, scrollLeft: 0, isDragging: false });
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollHorizontally(container.scrollWidth > container.clientWidth);
    }
  }, [children]);

  const handleMouseDown = (e: MouseEvent) => {
    if (!canScrollHorizontally) return;

    position.current = {
      startX: e.clientX,
      scrollLeft: scrollContainerRef.current?.scrollLeft || 0,
      isDragging: false,
    };

    timerRef.current = window.setTimeout(() => {
      setIsGrabbing(true);
      position.current.isDragging = true;
    }, 200);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!position.current.isDragging || !scrollContainerRef.current) return;

    const dx = e.clientX - position.current.startX;
    scrollContainerRef.current.scrollLeft = position.current.scrollLeft - dx;
  };

  const handleMouseUp = () => {
    setIsGrabbing(false);
    position.current.isDragging = false;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  return (
    <div
      ref={scrollContainerRef}
      className={`grabbing-scroll ${
        isGrabbing ? "grabbing" : canScrollHorizontally ? "grab" : ""
      } ${className} ${hiddenScrollBottom ? "hidden-scroll-bottom" : ""}`}
      style={style}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}>
      {children}
    </div>
  );
};

export default GFGrabbingScroll;
