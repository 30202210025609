/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import NotiExportExcel from "@Components/NotiExportExcel";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { APP_ROUTES, BASE_LINK_IMAGE, TEXT_ASTERISK, TEXT_HOLDER, TIME } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import useDateRange from "@Hooks/useDateRange";
import {
  Action,
  FetchAction,
  ItemGroupType,
  ItemPermission,
  OrderOutputModel,
  OrderPermission,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { OrderService } from "@Services";
import {
  PermissionUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
} from "@mui/material";
import { InputAdornment, TextField } from "@material-ui/core";
import { createTheme } from "@mui/material/styles";
import _, { get, uniqueId } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory, useLocation } from "react-router";
// import { DEFAULT_SIZE } from "../Authorize/Role";
import "./index.scss";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Divider from "@mui/material/Divider";
import { ITruckLoadModel } from "@Models/truckload.model";
import { VehicleService } from "@Services/vehicle.service";
import { IDriver, IVehicleData } from "@Models/veihicle.model";
import {
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import vi from "date-fns/locale/vi";
import DateFnsUtils from "@date-io/date-fns";
import { EventIcon } from "@Components/Icons/EventIcon";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import DatePicker from "@Components/DatePicker";
import TimePicker from "@Components/TimePicker";
import { TimePickerValue } from "src/types";
import { GFTypography } from "@Components/ui";

type P_Props = {
  data: any;
  setOpenTruckLoad: any;
};

interface FormValues {
  departureTime: any;
  departureDate: any;
  estimatedDeliveryDate: any;
  vehicles: {
    id?: string;
    vehicleType: string;
    vehicleInfo: string;
    nameOfDriver: string;
    phoneNumberOfDriver: string;
    escortStaff: string;
    shippingCode: string;
    note: string;
    vehicleCapacity: number;
  }[];
}

function reducer(state: PaginateFetchReducer<IVehicleData>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const TruckLoadModal: React.FC<P_Props> = ({ data, setOpenTruckLoad }) => {
  const [departureTime, setDepartureTime] = useState({
    hour: "00",
    minute: "00",
    second: "00",
  });

  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState({
    hour: "00",
    minute: "00",
    second: "00",
  });

  const { control, handleSubmit, setValue, getValues, watch } = useForm<FormValues>({
    defaultValues: {
      vehicles: [
        {
          id: uniqueId(),
          vehicleType: "",
          vehicleInfo: "",
          nameOfDriver: "",
          phoneNumberOfDriver: "",
          escortStaff: "",
          shippingCode: "",
          note: "",
          vehicleCapacity: 0,
        },
      ],
    },
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleChangeDepartureTime = (value: TimePickerValue) => {
    setDepartureTime({ ...departureTime, ...value });
  };

  const handleChangeEstimatedDeliveryTime = (value: TimePickerValue) => {
    setEstimatedDeliveryTime({ ...estimatedDeliveryTime, ...value });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "vehicles",
  });
  const { t } = useTranslation(["translation", "order"]);
  const [moreInfo, setMoreInfo] = useState<string[]>([]);
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });
  const checkExportRef = useRef<any>(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleFloatingBtn, setVisibleFloatingBtn] = useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>("all");
  const [selectSubStatus, setSelectSubStatus] = useState<string>("all");
  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [checkedSource, setCheckedSource] = useState<string[]>([]);
  const mounted = useRef(false);
  const location = useLocation<any>();
  const [getOrder, setGetOrder] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [filterOrder, setFilterOrder] = useState<"G" | "D">("G");
  const searchQuery = new URLSearchParams(location.search);
  const [listDriver, setListDriver] = useState<IDriver[]>([]);
  const [inputDriver, setInputDriver] = useState({});

  const toggleFloatingBtn = () => {
    setVisibleFloatingBtn(!visibleFloatingBtn);
  };

  const handleAddDriver = async (value: string, index: number) => {
    if (value) {
      const id = getValues(`vehicles.${index}.vehicleType`);
      console.log(id);
      const objectUpdate = state.data.find((item: IVehicleData) => item.id == Number(id));
      if (objectUpdate) {
        let cloneObject = { ...objectUpdate };
        cloneObject.drivers = [
          {
            nameOfDriver: value,
          },
        ];
        const res = await VehicleService.updateVehicle(id, cloneObject);
      }
    }
  };

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Cheat
  // useEffect(() => {
  //     if (!searchQuery.get("itemGroupTypeId") && sourceType === "farm") {
  //         history.push(`${location.pathname}?itemGroupTypeId=8`)
  //     }
  // }, [
  //     searchQuery
  // ])

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await VehicleService.getAllVehicle(query);
      if (isReset) {
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current) {
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
      }
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    setGetOrder(true);
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }, [location, profile]);

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        getAll: true,
      },
      true
    );
  }, []);

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  const getGroupType = () => {
    const pathNameFull = location.pathname;
    switch (pathNameFull) {
      case APP_ROUTES.ORDER_FARM:
        return ItemGroupType.BREEDING_PIGS;
      case APP_ROUTES.ORDER_FARM_BOAR_SEMEN:
        return ItemGroupType.BOAR_SEMEN;
      case APP_ROUTES.ORDER_FARM_MARKET_PIGS:
        return ItemGroupType.MARKET_PIGS;
      case APP_ROUTES.ORDER_FARM_CULLED_PIGS:
        return ItemGroupType.CULLED_PIGS;
      case APP_ROUTES.ORDER_FARM_INTERNAL_PIGS:
        return ItemGroupType.INTERNAL_PIGS;
    }
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const recallOrderList = () => {
    OrderService.recallOrderList({
      orderGroupIds: checkedSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res.data.message === "ONE PART") {
            enqueueSnackbar(t("order:PARTIAL_ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else if (res.data.message === "SUCCESS") {
            enqueueSnackbar(t("order:ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
              variant: "error",
            });
          }
          setCheckedSource([]);
          setSynchronized(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    location.state?.status !== undefined
      ? setSelectTab(location.state?.status)
      : setSelectTab("all");
  }, []);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  // const onCheckExport = async () => {
  //     try {
  //         const response = await ExportService.getRefreshStatus({
  //             type: "order",
  //             source: sourceType,
  //         });
  //         if (mounted.current) {
  //             setSuccess(() => response.data);
  //             if (response.data.isExport === false) {
  //                 setIsExport(false);
  //                 clearInterval(checkExportRef.current);
  //             }
  //         }
  //     } catch (error) {f
  //         console.log(error);
  //     }
  // };

  // const handleToCreate = () => {
  //     if (sourceType === "farm") {
  //         history.push("create-" + sourceType + `?itemGroupType=${searchQuery.get("itemGroupTypeId")}`);
  //     }
  //     else {
  //         history.push("create-" + sourceType);
  //     }
  // }

  // useEffect(() => {
  //     setIsExport(state?.isExport);
  // }, [sourceType, selectSubStatus, state.loading, success?.isExport === !isExport]);

  // const exportOrder = async () => {
  //     ExportService.exportOrder({
  //         // from:
  //         //   moment(range.startTime).diff(range.endTime, "days") > -31
  //         //     ? range.startTime.toISOString()
  //         //     : moment(range.endTime).subtract(31, "days").toISOString(),
  //         from: range.startTime.toISOString(),
  //         to: range.endTime.toISOString(),
  //         source: sourceType,
  //         strSearch: searchValue,
  //     })
  //         .then((res) => {
  //             if (res && (res.status === 200 || res.status === 201)) {
  //                 setIsExport(res.data.isExport);
  //                 checkExportRef.current = setInterval(() => {
  //                     onCheckExport();
  //                 }, 5000);
  //             } else {
  //                 // do something
  //             }
  //         })
  //         .catch((err) => { });
  // };

  // const exportOrderFarm = async () => {
  //     try {
  //         const res = await ExportService.exportOrderFarm({
  //             from:
  //                 moment(range.startTime).diff(range.endTime, "days") > -31
  //                     ? range.startTime.toISOString()
  //                     : moment(range.endTime).subtract(31, "days").toISOString(),
  //             to: range.endTime.toISOString(),
  //         });
  //         if (res && (res.status === 200 || res.status === 201)) {
  //             setIsExport(res.data.isExportFarm);
  //             checkExportRef.current = setInterval(() => {
  //                 onCheckExport();
  //             }, 5000);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  //permission
  const checkPermissionOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_RECALL,
    true
  );

  const checkPermissionOrderDetail = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_VIEWDETAILS,
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_EXPORT,
    true
  );

  const checkPermissionOrderCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );
  const styles = {
    resize: {
      fontSize: 50,
    },
  };

  const onSubmit: SubmitHandler<any> = async (dataVehicles: any) => {
    let listOrderId;
    let totalQuantity = 0;

    if (data.type === "order") {
      listOrderId = data.order.orders.map((item: any, index: number) => {
        totalQuantity += Number(get(item, "orderlines[0].orderQuantity", 0));
        return item.id;
      });
    } else {
      totalQuantity = Number(get(data, "order.orderlines[0].orderQuantity", 0));
      listOrderId = [data.order.id];
    }

    let totalCapcacity = 0;
    [...dataVehicles?.vehicles].map((item, index) => {
      totalCapcacity += Number(get(item, "vehicleCapacity", 0));
    });

    if (totalQuantity !== totalCapcacity) {
      enqueueSnackbar("Số lượng vận chuyển phải bằng số lượng đơn hàng", {
        variant: "error",
      });
      return;
    }

    let vehicleInfo = [...dataVehicles?.vehicles];

    vehicleInfo = vehicleInfo.map((item) => {
      const findObject = state.data.find((ele: any) => {
        return ele.id === item.vehicleType;
      });
      if (findObject) {
        item.vehicleType = findObject.vehicleType;
      }
      // Ép vehicle capacity về kiểu Number
      return {
        ...item,
        vehicleCapacity: Number(item?.vehicleCapacity),
      };
    });
    const dataUpdateVehicles: any = {
      type: "COORDINATE",
      orderIds: listOrderId,
      customerId: 0,
      departureDate: moment(dataVehicles.departureDate || new Date()).format("YYYY-MM-DD"),
      estimatedDeliveryDate: moment(dataVehicles.estimatedDeliveryDate || new Date()).format(
        "YYYY-MM-DD"
      ),
      departureTime: `${departureTime.hour}:${departureTime.minute}:00`,
      vehicles: vehicleInfo,
    };
    try {
      const res = await VehicleService.updateVehicleOrder(dataUpdateVehicles);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Điều phối xe thành công", {
          variant: "success",
        });
        setOpenTruckLoad(false);
        window.location.reload();
      }
    } catch {
      enqueueSnackbar("Điều phối xe thất bại", {
        variant: "error",
      });
    }
  };

  const handleListDriver = (vehicleType: any) => {
    if (vehicleType) {
      const findListDrivers: IVehicleData = state.data.find((item: any) => {
        return String(item.id) === String(vehicleType);
      });
      if (findListDrivers) {
        return findListDrivers.drivers;
      }
      return [];
    } else {
      return [];
    }
  };

  return (
    <Dialog fullScreen open={true} className="truckload-modal">
      <SimpleModalHeader
        title={"Điều phối xe"}
        onClose={() => {
          setOpenTruckLoad({ order: null, open: false, type: "order" });
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="hook-form">
        <div className="truckload truckload-modal__body">
          <div className="truckload-content">
            <GFTypography variant="h5">Thông tin đơn</GFTypography>
            <div className={"truckload__list__truck_load"}>
              <div className="header">
                <div className="truck-load-name">Con giống </div>
                <div className="truck-load-amount">Số lượng</div>
                <div className="truck-load-total-weight">Khung trọng lượng</div>
                <div className="truck-load-day">Đơn giá</div>
                {/* <div className="phone">Số điện thoại</div> */}
                <div className="truck-load-address">Phí giống</div>
                <div className="truck-load-address">Phần vượt</div>
                <div className="truck-load-total">Thành tiền</div>
              </div>
              {data.type === "order"
                ? data.order.orders.map((ele: any) => {
                    return ele.orderlines?.map((itemOrderLines: any, indexOrderLines: number) => {
                      return (
                        <div className="truck-load-item">
                          <div className="truck-load-name">
                            <div className="item-name">
                              <img
                                src={
                                  checkLinkImage(itemOrderLines.item.images?.picture) ||
                                  renderImageProNull("farm")
                                }
                                alt={itemOrderLines.item.shortName}
                                onError={renderErrorImageItem("farm")}
                              />
                              <div className="name">
                                <div>{itemOrderLines.item.name}</div>
                                <div className="code">{itemOrderLines.item.code}</div>
                              </div>
                            </div>
                          </div>
                          <div className="truck-load-amount">
                            {itemOrderLines?.orderQuantity
                              ? parseFloat(itemOrderLines.orderQuantity)
                              : TEXT_HOLDER}
                          </div>
                          <div className="truck-load-total-weight">
                            {currencyFormat(+itemOrderLines.dimension)}&ensp;kg
                          </div>
                          <div className="truck-load-day">
                            {" "}
                            {checkPermissionViewPrice
                              ? currencyFormat(Number(itemOrderLines?.price) ?? 0)
                              : TEXT_ASTERISK}
                            &ensp;đ/
                            <span style={{ color: "#41ad49" }}>{itemOrderLines.item.baseUoM}</span>
                          </div>
                          {/* Phí giống */}
                          <div className="truck-load-address">
                            {(() => {
                              switch (itemOrderLines.prices?.priceType) {
                                case "fixed":
                                  return (
                                    <span>
                                      {(checkPermissionViewPrice
                                        ? currencyFormat(Number(itemOrderLines.attachValue))
                                        : TEXT_ASTERISK) + " đ/"}
                                      <span style={{ color: "#41ad49" }}>
                                        {itemOrderLines.baseUoM}
                                      </span>
                                    </span>
                                  );
                                default:
                                  return (
                                    <span>
                                      {(checkPermissionViewPrice
                                        ? currencyFormat(Number(0))
                                        : TEXT_ASTERISK) + " đ/"}
                                      <span style={{ color: "#41ad49" }}>
                                        {itemOrderLines.baseUoM}
                                      </span>
                                    </span>
                                  );
                              }
                            })()}
                          </div>
                          {/* Phần vượt */}
                          <div className="truck-load-address">
                            {(() => {
                              switch (itemOrderLines.prices?.priceType) {
                                case "increment":
                                  return (
                                    <span>
                                      {(checkPermissionViewPrice
                                        ? currencyFormat(Number(itemOrderLines.attachValue))
                                        : TEXT_ASTERISK) + " đ/"}
                                      <span style={{ color: "#41ad49" }}>
                                        {itemOrderLines.baseUoM}
                                      </span>
                                    </span>
                                  );
                                default:
                                  return (
                                    <span>
                                      {(checkPermissionViewPrice
                                        ? currencyFormat(Number(0))
                                        : TEXT_ASTERISK) + " đ/"}
                                      <span style={{ color: "#41ad49" }}>
                                        {itemOrderLines.baseUoM}
                                      </span>
                                    </span>
                                  );
                              }
                            })()}
                          </div>
                          <div
                            className="truck-load-total"
                            style={{
                              color: "#41ad49",
                            }}>
                            {checkPermissionViewPrice
                              ? currencyFormat(Number(itemOrderLines.subTotal))
                              : TEXT_ASTERISK}
                            &ensp;đ
                          </div>
                        </div>
                      );
                    });
                  })
                : data.order.orderlines?.map((itemOrderLines: any, indexOrderLines: number) => {
                    return (
                      <div className="truck-load-item">
                        <div className="truck-load-name">
                          <div className="item-name">
                            <img
                              src={
                                checkLinkImage(itemOrderLines.item.images?.picture) ||
                                renderImageProNull("farm")
                              }
                              alt={itemOrderLines.item.shortName}
                              onError={renderErrorImageItem("farm")}
                            />
                            <div className="name">
                              <div>{itemOrderLines.item.name}</div>
                              <div className="code">{itemOrderLines.item.code}</div>
                            </div>
                          </div>
                        </div>
                        <div className="truck-load-amount">
                          {itemOrderLines?.orderQuantity
                            ? parseFloat(itemOrderLines.orderQuantity)
                            : TEXT_HOLDER}
                        </div>
                        <div className="truck-load-total-weight">
                          {currencyFormat(+itemOrderLines.dimension)}&ensp;kg
                        </div>
                        <div className="truck-load-day">
                          {" "}
                          {checkPermissionViewPrice
                            ? currencyFormat(Number(itemOrderLines?.price) ?? 0)
                            : TEXT_ASTERISK}
                          &ensp;đ/
                          <span style={{ color: "#41ad49" }}>{itemOrderLines.item.baseUoM}</span>
                        </div>
                        {/* <div className="phone">Số điện thoại</div> */}
                        <div className="truck-load-address">
                          {(() => {
                            switch (itemOrderLines.prices?.priceType) {
                              case "fixed":
                                return (
                                  <span>
                                    {(checkPermissionViewPrice
                                      ? currencyFormat(Number(itemOrderLines.attachUnit))
                                      : TEXT_ASTERISK) + " đ/"}
                                    <span style={{ color: "#41ad49" }}>
                                      {itemOrderLines.baseUoM}
                                    </span>
                                  </span>
                                );
                              default:
                                return (
                                  <span>
                                    {(checkPermissionViewPrice
                                      ? currencyFormat(Number(0))
                                      : TEXT_ASTERISK) + " đ/"}
                                    <span style={{ color: "#41ad49" }}>
                                      {itemOrderLines.baseUoM}
                                    </span>
                                  </span>
                                );
                            }
                          })()}
                        </div>
                        {/* Phần vượt */}
                        <div className="truck-load-address">
                          {(() => {
                            switch (itemOrderLines.prices?.priceType) {
                              case "increment":
                                return (
                                  <span>
                                    {(checkPermissionViewPrice
                                      ? currencyFormat(Number(itemOrderLines.attachUnit))
                                      : TEXT_ASTERISK) + " đ/"}
                                    <span style={{ color: "#41ad49" }}>
                                      {itemOrderLines.baseUoM}
                                    </span>
                                  </span>
                                );
                              default:
                                return (
                                  <span>
                                    {(checkPermissionViewPrice
                                      ? currencyFormat(Number(0))
                                      : TEXT_ASTERISK) + " đ/"}
                                    <span style={{ color: "#41ad49" }}>
                                      {itemOrderLines.baseUoM}
                                    </span>
                                  </span>
                                );
                            }
                          })()}
                        </div>
                        <div
                          className="truck-load-total"
                          style={{
                            color: "#41ad49",
                          }}>
                          {checkPermissionViewPrice
                            ? currencyFormat(Number(itemOrderLines.subTotal))
                            : TEXT_ASTERISK}
                          &ensp;đ
                        </div>
                      </div>
                    );
                  })}
            </div>
            <GFTypography variant="h5" mt={4}>
              Giao hàng
            </GFTypography>
            <Grid container spacing={3} mb={4}>
              <Grid item xs={6}>
                <div className="truckload-modal__label">Ngày giao hàng</div>
                <div className="form-item">
                  <TextField
                    style={{ width: "100%" }}
                    disabled={true}
                    variant="outlined"
                    className="form-text-field"
                    value={
                      data.type === "order"
                        ? get(data, "order.orders[0].requiredDate", "")
                        : get(data, "order.requiredDate", "")
                    }
                    inputProps={{
                      className: "input",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="truckload-modal__label">Ghi chú</div>
                <div className="form-item">
                  <TextField
                    style={{ width: "100%" }}
                    disabled={true}
                    variant="outlined"
                    id="standard-basic"
                    // label={}
                    value={
                      data.type === "order"
                        ? get(data, "order.orders[0].note", "")
                        : get(data, "order.note", "")
                    }
                    className="form-text-field"
                    inputProps={{
                      className: "input",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="truckload-modal__label">Địa chỉ</div>
                <div className="form-item">
                  <TextField
                    style={{ width: "100%", fontSize: "1.6rem" }}
                    disabled={true}
                    variant="outlined"
                    id="standard-basic"
                    // label={}
                    value={
                      data.type === "order"
                        ? get(data, "order.orders[0].address", "")
                        : get(data, "order.address", "")
                    }
                    className="form-text-field"
                    inputProps={{
                      className: "input",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <div className="truckload-modal__label">Ngày xe nhận heo tại trại</div>
                    <Controller
                      name="departureDate"
                      control={control}
                      rules={{ required: "Ngày xe nhận heo tại trại là bắt buộc" }} // Validation
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          locale={vi}
                          error={error}
                          value={field.value}
                          onChange={(value) => {
                            setValue("departureDate", value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="truckload-modal__label">Giờ xe nhận heo tại trại</div>
                    <TimePicker value={departureTime} onChange={handleChangeDepartureTime} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <div className="truckload-modal__label">Ngày giao hàng dự kiến</div>
                    <Controller
                      name="estimatedDeliveryDate"
                      control={control}
                      rules={{ required: "Ngày giao hàng dự kiến là bắt buộc" }} // Validation
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          locale={vi}
                          error={error}
                          value={field.value}
                          onChange={(value) => {
                            setValue("estimatedDeliveryDate", value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="truckload-modal__label">Giờ giao hàng dự kiến</div>
                    <TimePicker
                      value={estimatedDeliveryTime}
                      onChange={handleChangeEstimatedDeliveryTime}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <GFTypography variant="h5">Thông tin xe</GFTypography>
            {fields.map((item: any, index: number) => {
              return (
                <div className="truckload-modal__section">
                  <Grid container className="truck-item" spacing={2} key={item.id}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">
                            Loại xe <span>*</span>
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleType`}
                              control={control}
                              defaultValue={item.vehicleType}
                              rules={{
                                validate: {
                                  required: (value) => {
                                    if (!value) {
                                      return "Trường thông tin không được để trống";
                                    }
                                  },
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <div
                                    style={{
                                      width: "100%",
                                    }}>
                                    <Select
                                      {...field}
                                      fullWidth
                                      input={
                                        <OutlinedInput
                                          className="form-text-field"
                                          color="success"
                                        />
                                      }
                                      renderValue={(selected) => {
                                        if (!selected) {
                                          return (
                                            <div
                                              style={{
                                                color: "gray",
                                                fontWeight: 200,
                                              }}>
                                              Chọn loại xe
                                            </div>
                                          );
                                        }
                                        // Tìm object tương ứng với id được chọn để lấy vehicleType
                                        const selectedVehicle = state.data.find(
                                          (item: IVehicleData) => item.id === Number(selected)
                                        );
                                        return selectedVehicle ? selectedVehicle.vehicleType : "";
                                      }}
                                      displayEmpty
                                      className="form-select-field"
                                      MenuProps={{
                                        className: "select-menu-list",
                                        style: { maxHeight: "40rem" },
                                      }}
                                      error={!!error}
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          const object = state.data.find(
                                            (item: IVehicleData) =>
                                              item.id == Number(e.target.value)
                                          );
                                          if (object) {
                                            let driverIsInput = get(inputDriver, `${index}`, false);
                                            console.log(object);
                                            setValue(`vehicles.${index}`, {
                                              vehicleType: e.target.value,
                                              vehicleInfo: object.vehicleInfo,
                                              nameOfDriver: !driverIsInput
                                                ? object.drivers[0].name
                                                : object.drivers[0].name,
                                              phoneNumberOfDriver: "",
                                              escortStaff: "",
                                              shippingCode: "",
                                              note: "",
                                              vehicleCapacity: 0,
                                            });
                                          }
                                        }
                                      }}>
                                      {state.data &&
                                        state.data.map((value: IVehicleData, index: number) => {
                                          return (
                                            <MenuItem key={value.id} value={value.id}>
                                              {value.vehicleType}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    {error && (
                                      <FormHelperText error>{error.message}</FormHelperText>
                                    )}{" "}
                                    {/* Hiển thị thông báo lỗi */}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">Biển số xe</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleInfo`}
                              control={control}
                              defaultValue={item.vehicleInfo}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    InputProps={{
                                      readOnly: true, // Set the TextField to read-only
                                    }}
                                    placeholder="Nhập biển số xe"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
                            <div
                              className="truckload-modal__label"
                              style={{
                                marginBottom: 0,
                                marginRight: "8px !impotant",
                              }}>
                              Tên tài xế
                            </div>
                            <FormControlLabel
                              sx={{
                                height: 16,
                                marginLeft: 1,
                              }}
                              control={
                                <Checkbox
                                  // name="SomeName"
                                  value={get(inputDriver, `${index}`, false)}
                                  onChange={(e) => {
                                    let cloneInputDriver = {
                                      ...inputDriver,
                                      [index]: e.target.checked,
                                    };
                                    setInputDriver(cloneInputDriver);
                                  }}
                                />
                              }
                              label={<span style={{ fontSize: "1.6rem" }}>Tự nhập tên tài xế</span>}
                            />
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.nameOfDriver`}
                              control={control}
                              defaultValue={item.nameOfDriver}
                              render={({ field }) => {
                                return get(inputDriver, `${index}`, false) ? (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    placeholder="Nhập tên tài xế"
                                    inputProps={{
                                      className: "input",
                                    }}
                                  />
                                ) : (
                                  <Select
                                    {...field}
                                    fullWidth
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return (
                                          <div
                                            style={{
                                              color: "gray",
                                              fontWeight: 200,
                                            }}>
                                            Chọn tài xế
                                          </div>
                                        );
                                      }
                                      // Tìm object tương ứng với id được chọn để lấy vehicleType

                                      return selected;
                                    }}
                                    placeholder="Chọn mã vận chuyển"
                                    displayEmpty
                                    className="form-select-field"
                                    MenuProps={{
                                      className: "select-menu-list",
                                      style: { maxHeight: "40rem" },
                                    }}
                                    // error={!!error}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setValue(`vehicles.${index}.nameOfDriver`, e.target.value);
                                      }
                                    }}>
                                    {watch(`vehicles.${index}.vehicleType`) &&
                                      handleListDriver(watch(`vehicles.${index}.vehicleType`)) &&
                                      handleListDriver(watch(`vehicles.${index}.vehicleType`)).map(
                                        (value: IDriver, index: number) => {
                                          return (
                                            <MenuItem key={value.name} value={value.name}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                  </Select>
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">Số điện thoại</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.phoneNumberOfDriver`}
                              control={control}
                              defaultValue={item.phoneNumberOfDriver}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập số điện thoại"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">Nhân viên áp tải</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.escortStaff`}
                              control={control}
                              defaultValue={item.escortStaff}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập nhân viên áp tải"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">Mã chuyến xe</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.shippingCode`}
                              control={control}
                              defaultValue={item.shippingCode}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập mã"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">
                            Số lượng vận chuyển <span>*</span>
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleCapacity`}
                              control={control}
                              defaultValue={item.vehicleCapacity}
                              rules={{
                                validate: {
                                  required: (value) => {
                                    if (!value) {
                                      return "Trường thông tin không được để trống";
                                    }
                                  },
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập số lượng vận chuyển"
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    onKeyPress={(e) => {
                                      // Chỉ cho phép các phím số từ 0 đến 9
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="truckload-modal__label">Ghi chú</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.note`}
                              control={control}
                              defaultValue={item.note}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập ghi chú"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={1}>
                      <div
                        onClick={() => {
                          remove(index);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          marginTop: "15px",
                        }}>
                        <DeleteOutlineIcon
                          style={{
                            fontSize: "30px",
                            marginLeft: "3rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
            <div
              style={{ color: "#41ad49", cursor: "pointer" }}
              onClick={() =>
                append({
                  id: uniqueId(),
                  vehicleType: "",
                  vehicleInfo: "",
                  nameOfDriver: "",
                  phoneNumberOfDriver: "",
                  escortStaff: "",
                  shippingCode: "",
                  note: "",
                  vehicleCapacity: 0,
                })
              }>
              + Thêm xe
            </div>
          </div>
          <NotiExportExcel
            notiExport={notiExport}
            title={t("ORDER")}
            closeForm={() => {
              setNotiExport(false);
              clearInterval(checkExportRef.current);
            }}
            success={success?.isExport === false ? success?.fileName : undefined}
          />
        </div>
      </form>

      <div className="truckload-footer  truckload-modal__footer">
        <button
          // type={checkOrderQuantity ? "button" : "submit"}
          className="btn-cancel-style"
          onClick={() => {
            setOpenTruckLoad({ order: null, type: "order", open: false });
          }}>
          Huỷ
        </button>
        <button
          type="submit"
          form="hook-form"
          className="btn-confirm-style"
          style={{ marginLeft: "2.4rem" }}
          onClick={() => {
            handleSubmit((d) => console.log(d));
          }}>
          Điều phối xe
        </button>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div
          className="popover-date"
          style={{
            minWidth: 180,
          }}>
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={departureTime.hour}
                onChange={(e) => {
                  setDepartureTime({ ...departureTime, hour: e.target.value });
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  paddingX: 2,
                }}>
                {(() => {
                  const menuItems = [];
                  for (let i = 0; i <= 23; i++) {
                    menuItems.push(
                      <MenuItem
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        value={i < 10 ? `0${i}` : `${i}`}>
                        {i < 10 ? `0${i}` : `${i}`}
                      </MenuItem>
                    );
                  }
                  return menuItems;
                })()}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Select
                value={departureTime.minute}
                onChange={(e) => {
                  setDepartureTime({ ...departureTime, minute: e.target.value });
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  paddingX: 2,
                }}>
                {(() => {
                  const menuItems = [];
                  for (let i = 0; i <= 59; i++) {
                    menuItems.push(
                      <MenuItem
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        value={i < 10 ? `0${i}` : `${i}`}>
                        {i < 10 ? `0${i}` : `${i}`}
                      </MenuItem>
                    );
                  }
                  return menuItems;
                })()}
              </Select>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </Dialog>
  );
};

export default TruckLoadModal;
