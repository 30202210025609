import { REQUEST_URL } from "@Constants";
import { RequestUtil, RequestUtilV2 } from "@Utils";

export const AuthService = {
  signIn,
  loadMe,
  verifyOtp_login,
  verifyOtp_forgotPassword,
  retryOtp,
  updatePassword,
  getTokenToChangePassword,
  getprofile,
  saveTokenDevice,
  deActiveTokenDevice,
  grantNewPassword,
  destroyToken,
};

function signIn(data: { user: string; password: string; deviceId: string; platform: string }) {
  const url = REQUEST_URL.LOGIN;
  return RequestUtilV2.post({ url, data });
}
function getTokenToChangePassword(data: { user: string; platform: string }) {
  const url = REQUEST_URL.GETTOKEN;
  return RequestUtil.post({ url, data });
}
function updatePassword(data: {
  password: string;
  requestToken: string | undefined;
  isEmployee: Boolean;
}) {
  const url = REQUEST_URL.UPDATE_PASSWORD;
  return RequestUtil.post({ url, data });
}

function verifyOtp_login(data: { otp: string; requestToken: string | null; type: string }) {
  const url = REQUEST_URL.VERIFY_OTP_LOGIN;
  return RequestUtil.post({ url, data });
}
function verifyOtp_forgotPassword(data: {
  otp: string;
  requestToken: string | null;
  deviceId: string;
}) {
  const url = REQUEST_URL.VERIFY_OTP_FORGOT_PASSWORD;
  return RequestUtil.post({ url, data });
}
function retryOtp(data: { requestToken: string | null; type: string }) {
  const url = REQUEST_URL.RETRY_OTP;
  return RequestUtil.post({ url, data });
}
function getprofile(token: string | null) {
  const url = "/auth/get-profile";
  return RequestUtil.get({ url }, { Authorization: `Bearer ${token}` });
}

function loadMe() {
  const url = REQUEST_URL.LOAD_ME;
  return RequestUtil.get({ url });
}

function saveTokenDevice(data: {
  type_device: "browser";
  token: string | null;
  meta_data: string;
}) {
  const url = REQUEST_URL.SAVE_TOKEN_DEVICE;
  return RequestUtil.post({ url, data });
}

function deActiveTokenDevice(data: { token: string | null }) {
  const url = REQUEST_URL.DEACTIVE_TOKEN_DEVICE;
  return RequestUtil.post({ url, data });
}

function grantNewPassword(accountId: string) {
  const url = REQUEST_URL.SEND_PASSWORD;
  return RequestUtil.post({ url, data: { accountId } });
}

function destroyToken(data: { requestToken: string | null }) {
  return RequestUtil.put({
    url: REQUEST_URL.DESTROY_TOKEN,
    data,
  });
}
