import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const GFTypography = styled(Typography)<{ type?: "primary" | "primary-light" | "secondary" }>(
  ({ theme, variant, type, fontWeight }) => ({
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 600,
      wordBreak: "break-word",
      ...(fontWeight && {
        fontWeight: fontWeight,
      }),
      ...(type === "primary" && {
        color: "var(--primary-green)",
      }),
      ...(type === "primary-light" && {
        color: "var(--secondary-green)",
      }),
      ...(type === "secondary" && {
        color: "var(--primary-gray-6)",
      }),

      lineHeight: 1.5,
      ...(variant === "h1" && {
        fontSize: "96px",
        lineHeight: "111px",
      }),
      ...(variant === "h2" && {
        fontSize: "76px",
        lineHeight: "92px",
      }),
      ...(variant === "h3" && {
        fontSize: "64px",
        lineHeight: "77.5px",
      }),
      ...(variant === "h4" && {
        fontSize: "28px",
        lineHeight: 1.5,
      }),
      ...(variant === "h5" && {
        fontSize: "24px",
        lineHeight: "28px",
      }),

      ...(variant === "subtitle1" && {
        fontSize: "20px",
        lineHeight: "26px",
      }),

      ...(variant === "subtitle2" && {
        fontSize: "16px",
        lineHeight: "22px",
      }),

      ...(variant === "body1" && {
        fontSize: "18px",
        lineHeight: "23.4px",
        marginBottom: '14px',
      }),

      ...(variant === "body2" && {
        fontSize: "14px",
        lineHeight: "18.2px",
      }),

      ...(variant === "caption" && {
        fontSize: "12px",
        lineHeight: "18px",
      }),
    },
  })
);

export default GFTypography;
