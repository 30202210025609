/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
import Excel from "@Assets/images/icons/excel.png";
import IconExcel from "@Assets/images/icons/ic_xls.png";
import ImportExcel from "@Assets/images/icons/import.png";
import SampleEmpty from "@Assets/images/ordnull.png";
import GifImport from "@Assets/importLetter.gif";
import { EventIcon } from "@Components/Icons/EventIcon";
import { SearchFile } from "@Components/Icons/SearchFile";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { TEXT_ASTERISK, TEXT_HOLDER, TIME } from "@Constants";
import Bill from "@Containers/Order/OrderDetail/Bill";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  ItemPermission,
  LabSampleGroupCodeOutputModel,
  objectiveOutputModel,
  OrderDetailLabOutputModel,
  OrderElementModel,
  OrderImportModel,
  OrderLabDetail,
  OrderPermission,
  OrderSampleDetail,
  OrderSampleInputModel,
  SourceType,
} from "@Models";
import { ExportService, ObjectiveService, OrderService } from "@Services";
import { currencyFormat, PermissionUtil, StatusUIUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Clear as ClearIcon,
  ContentCopy,
  DeleteOutline as DeleteOutlineIcon,
  Help as HelpIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { vi } from "date-fns/locale";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./OrderDetailLabSample.scss";

const UpdateOrderLabSample: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const checkExportRef = useRef<any>(null);
  const submitRef = useRef<any | null>(null);
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<OrderDetailLabOutputModel | null>(null);
  const [dataOrderLine, setDataOrderLine] = useState<OrderElementModel[]>([]);
  const [tempDataSample, setTempDataSample] = useState<OrderLabDetail[]>([]); //xử lý riêng cho orderLabDetail (sample trong popup)

  const [openBill, setOpenBill] = useState<boolean>(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [dataObjective, setDataObjective] = useState<objectiveOutputModel | null>(null);
  const [visibleDetail, setVisibleDetail] = useState<OrderElementModel | null>(null);
  const [visibleUpdate, setVisibleUpdate] = useState<OrderElementModel | null>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [success, setSuccess] = useState<any>();
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const [dataLabSampleGroupCode, setDataLabSampleGroupCode] = useState<
    LabSampleGroupCodeOutputModel[]
  >([]);

  const [importExcel, setImportExcel] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [checkErr, setCheckErr] = useState<boolean | string>(false);
  const [importFile, setImportFile] = useState<boolean>(false);
  const [dataImportSample, setDataImportSample] = useState<OrderSampleDetail[]>([]);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(Number(orderId));
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  const onFetchApiObject: () => Promise<void> = async () => {
    try {
      const response = await ObjectiveService.getObjectiveById(order?.objective.id.toString()!);
      if (mounted.current) {
        setDataObjective(() => response.data);
      }
    } catch (error) {}
  };

  const onFetchApiListLabSampleGroupCode: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getListLabSampleGroupCode();
      if (mounted.current) {
        setDataLabSampleGroupCode(() => response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    orderById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!order) {
      const newOrderLine = order.orders.map((val) => {
        return {
          ...val,
          updated: !!val.orderLabDetails.length,
        };
      });
      setDataOrderLine(newOrderLine);
      onFetchApiObject();
      onFetchApiListLabSampleGroupCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const handleDialog = (updated: boolean, g: OrderElementModel) => {
    //lưu tạm orderlabDetails để xử lý riêng
    const tempData = dataOrderLine.find((item) => item?.id === g.id)?.orderLabDetails || [];
    setTempDataSample(tempData);
    if (updated) {
      //đã update => xem detail
      setVisibleDetail(g);
    } else {
      setVisibleUpdate(g);
    }
  };

  const handleUpdateSample = (
    data: OrderLabDetail | null,
    action: "add" | "delete" | "update",
    value?: any,
    type?: "objectiveDetail" | "age" | "sampleCode" | "sampleTemple"
  ) => {
    const hasDataSample =
      type === "sampleCode"
        ? dataOrderLine.flatMap((i) => i.orderLabDetails).find((o) => o.sampleCode === value) ||
          null
        : null;

    const tempSample: OrderLabDetail = {
      id: uuidv4(),
      source: SourceType.LAB,
      age: "1",
      sampleCode: "",
      createdAt: "",
      updatedAt: "",
      objectiveDetail: null,
      mergeSample: "",
    };
    if (action === "add") {
      setTempDataSample((prev) => [...prev, tempSample]);
    } else if (action === "update") {
      const result = tempDataSample.map((i) => {
        return i.id === data?.id
          ? {
              ...i,
              age: type === "age" ? value : hasDataSample ? hasDataSample.age : i.age,
              sampleCode: type === "sampleCode" ? value : i.sampleCode,
              mergeSample:
                type === "sampleTemple"
                  ? value
                  : hasDataSample
                  ? hasDataSample.mergeSample
                  : i.mergeSample,
              objectiveDetail:
                type === "objectiveDetail"
                  ? dataObjective?.objectiveDetails?.find((i) => i.id === value)!
                  : hasDataSample
                  ? hasDataSample.objectiveDetail
                  : i.objectiveDetail,
            }
          : { ...i };
      });
      setTempDataSample(result);
    } else {
      const result = tempDataSample.filter((i) => i.id !== data?.id);
      setTempDataSample(result);
    }

    setValid(false);
  };

  /** check mẫu trùng trên 1 form */
  const hasDuplicates = (array: OrderLabDetail[]) => {
    return array.filter((item1, index1) => {
      return _.some(array, (item2, index2) => {
        if (index1 !== index2) {
          return _.isEqual(item1.sampleCode, item2.sampleCode);
        }
        return false;
      });
    });
  };

  /** nếu sử dụng lại mã mẫu phải trùng đối tương và tuối */
  /** tìm kiếm các sample trên orderLabDetails khác orderLabDetails hiện tại */
  const findMismatchedItems = () => {
    return tempDataSample.filter((d2) => {
      const matchingItem = dataOrderLine
        .filter((i) => i.id !== visibleUpdate?.id)
        .flatMap((i) => i.orderLabDetails)
        .find((d1) => d1.sampleCode === d2.sampleCode);

      if (matchingItem) {
        return (
          !_.isEqual(matchingItem.age, d2.age) ||
          !_.isEqual(matchingItem.objectiveDetail?.id, d2.objectiveDetail?.id)
        );
      }
      return false;
    });
  };

  /** nếu sử ppxn là pcrg 5 gộp 1 không được trùng mã mẫu */
  // const findMismatchedItemsPCRG = () => {
  //   const currGroupCode =
  //     dataOrderLine.findIndex((i) => i.id === visibleUpdate?.id && i.itemGroup.code === "PCRG") >
  //     -1;
  //   if (currGroupCode) {
  //     return tempDataSample.filter((d2) => {
  //       const matchingItem = dataOrderLine
  //         .filter((i) => i.id !== visibleUpdate?.id && i.itemGroup.code === "PCRG")
  //         .flatMap((i) => i.orderLabDetails);

  //       return matchingItem.some((e) => e.sampleCode === d2.sampleCode);
  //     });
  //   }
  //   return [];
  // };

  const findMismatchedItemsGroup = () => {
    return tempDataSample.filter((d2) => {
      const matchingItem = dataOrderLine
        .filter(
          (i) => i.id !== visibleUpdate?.id && i.itemGroup.code === visibleUpdate?.itemGroup.code
        )
        .flatMap((i) => i.orderLabDetails);

      return matchingItem.some((e) => e.sampleCode === d2.sampleCode);
    });
  };

  // Đếm số lần xuất hiện của mỗi giá trị mergeSample
  const counts = _.countBy(tempDataSample, "mergeSample");
  // Lọc ra các giá trị mergeSample bị trùng (xuất hiện hơn 5 lần)
  const result = _.some(counts, (count) => count > 5);
  const dataSampleDuplicate = _.keys(_.pickBy(counts, (count) => count > 5));

  const sampleInfo: OrderElementModel | null = dataOrderLine.find(
    (i) => i.id === visibleUpdate?.id || i.id === visibleDetail?.id
  )!;

  const handleSaveSample = () => {
    const hasInvalidFields =
      !!tempDataSample?.length &&
      tempDataSample?.findIndex((i) => !i.objectiveDetail?.id || !i.sampleCode) > -1;

    const isPCRG = sampleInfo?.itemGroup?.code === "PCRG";
    const hasInvalidMergeSample =
      !!tempDataSample?.length && tempDataSample.findIndex((i) => !i.mergeSample) > -1 && isPCRG;

    const hasDuplicateSamples = !!hasDuplicates(tempDataSample).length;

    const hasMismatchedItems = !!findMismatchedItems().length;

    const hasMismatchedItemsGroup = !!findMismatchedItemsGroup().length;

    const exceedsMergeSampleLimit = result;

    if (hasInvalidFields) {
      setValid(true);
    } else if (hasInvalidMergeSample) {
      enqueueSnackbar(t("TEMPLATE_SAMPLE_INVALID"), { variant: "error" });
      setValid(true);
    } else if (hasDuplicateSamples) {
      enqueueSnackbar(t("SAMPLE_CODE_DUPLICATE"), { variant: "error" });
      setValid(true);
      return;
    } else if (hasMismatchedItems) {
      enqueueSnackbar(t("VALID_REUSE_SAMPLE_CODE"), { variant: "error" });
      setValid(true);
      return;
    } else if (hasMismatchedItemsGroup) {
      enqueueSnackbar(t("Không được trùng mã mẫu trong 1 đơn hàng với cùng một phương pháp"), {
        variant: "error",
      });
      setValid(true);
    } else if (exceedsMergeSampleLimit) {
      enqueueSnackbar(t("VALID_COMBINED_SAMPLE_GROUP"), { variant: "error" });
      setValid(true);
      return;
    } else {
      const updatedOrderLines = dataOrderLine.map((item) =>
        item.id === visibleUpdate?.id
          ? { ...item, orderLabDetails: tempDataSample, updated: !!tempDataSample.length }
          : { ...item }
      );

      setDataOrderLine(updatedOrderLines);
      setVisibleUpdate(null);
    }
  };

  const onDuplicateSample = (item: OrderLabDetail) => {
    const tempSample: OrderLabDetail = {
      ...item,
      id: uuidv4(),
      sampleCode: "",
    };
    setTempDataSample((prev) => [...prev, tempSample]);
  };

  const handleCancelSample = () => {
    setTempDataSample([]);
    setVisibleUpdate(null);
  };

  //export
  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "lab_sample_template",
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          // setIsExport(false);
          clearInterval(checkExportRef.current);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSampleExport = async () => {
    OrderService.exportOrderLabSample(orderId, {})
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          // setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        }
      })
      .catch((err) => {});
  };

  //import
  useEffect(() => {
    selectedFile && checkFile();
    //eslint-disable-next-line
  }, [selectedFile]);

  const checkFile = () => {
    const fileExcel = selectedFile.excel?.name.slice(
      selectedFile?.name?.lastIndexOf("."),
      selectedFile?.name?.length
    );

    if (fileExcel && fileExcel !== ".xls") {
      if (fileExcel !== ".xlsx") {
        return setCheckErr(t("FILE_FORMAT_ERROR"));
      }
    }

    if (selectedFile.excel?.size >= 100000000) {
      return setCheckErr(t("FILE_SIZE_ERROR"));
    }

    return setCheckErr(true);
  };

  const onFileChangeExcel = (event: any) => {
    const fileExcel: File = event.target.files[0];
    setSelectedFile(fileExcel);
  };

  const handleSampleImport = async () => {
    if (!selectedFile) {
      return setCheckErr(t("NO_FILE_SELECTED"));
    }
    if (checkErr) {
      setImportFile(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const res = await OrderService.importOrderLabSample(orderId, formData);
        if (res && (res.status === 200 || res.status === 201)) {
          const resData: OrderImportModel = res.data;
          const result = resData.orderDetails.flatMap((order) => order.orderSampleDetails);
          setDataImportSample(result);
          setImportExcel(false);
          setSelectedFile(null);

          const data: any = dataOrderLine
            .map((i) => {
              return resData?.orderDetails.map((s) => {
                return s.orderId === i.id
                  ? {
                      ...i,
                      updated: !!s.orderSampleDetails.length,
                      orderLabDetails: s.orderSampleDetails.map((o) => {
                        return {
                          id: uuidv4(),
                          age: o.age,
                          sampleCode: o.sampleCode,
                          objectiveDetail: o.objectiveDetail,
                        };
                      }),
                    }
                  : {};
              });
            })
            .flatMap((i) => i?.filter((e) => !!e && Object.keys(e).length > 0));

          setDataOrderLine(data);
          setTimeout(() => {
            setImportFile(false);
            enqueueSnackbar(t("IMPORT_SAMPLE_INFO_SUCCESSFUL"), {
              variant: "success",
            });
          }, 2000);
        }
      } catch (error: any) {
        enqueueSnackbar(t("IMPORT_SAMPLE_INFO_FAILED"), {
          variant: "error",
        });
        setImportFile(false);
      }
    }
  };

  //submit
  const submitForm = async (values: any) => {
    const { shipPhone, sentSampleDate, receivedSampleDate, shipInfo } = values;
    if (!checkPermissionSendSample) {
      enqueueSnackbar(t("ACCESS_DENIED"), {
        preventDuplicate: true,
        variant: "error",
      });
      return;
    }
    if (!ordDraft && dataOrderLine.findIndex((i) => !i.updated) > -1) {
      enqueueSnackbar(t("YOU_NEED_UPDATE_ALL_SAMPLE"), {
        variant: "error",
      });
    }
    // else if (!ordDraft && !shipInfo) {
    //   enqueueSnackbar(t("SHIPPING_INFOMATION_NOT_EMPTY"), {
    //     variant: "error",
    //   });
    //   setValid(true);
    // } else if (!ordDraft && !shipPhone) {
    //   enqueueSnackbar(t("SHIPPING_PHONE_NOT_EMPTY"), {
    //     variant: "error",
    //   });
    //   setValid(true);
    // }
    else {
      const data: OrderSampleInputModel = {
        shipInfo,
        orderGroupId: Number(order?.id),
        targetId: Number(order?.target?.id),
        objectiveId: Number(order?.objective?.id),
        shipPhone: shipPhone ?? "",
        sentSampleDate: !!sentSampleDate
          ? moment(sentSampleDate).format("YYYY-MM-DD")
          : moment(new Date()).toISOString(),
        receivedSampleDate: !!receivedSampleDate
          ? moment(receivedSampleDate).format("YYYY-MM-DD")
          : moment(new Date()).toISOString(),
        orderDetails: dataOrderLine.map((i) => {
          return {
            orderId: i.id,
            itemGroupId: i.itemGroup.id,
            sampleId: i.sample.id,
            orderSampleDetails: i.orderLabDetails.map((o) => {
              return {
                objectiveDetailId: Number(o.objectiveDetail?.id),
                age: o.age,
                sampleCode: o.sampleCode,
                mergeSample: o.mergeSample,
              };
            }),
          };
        }),
        isDraft: ordDraft,
      };

      OrderService.updateOrderLabSample(data)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            enqueueSnackbar(t("UPDATE_SAMPLE_SUCCESSFULLY"), {
              variant: "success",
            });
            history.goBack();
          } else {
            enqueueSnackbar(t("UPDATE_SAMPLE_FAIlED"), {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("UPDATE_SAMPLE_FAIlED"), {
            variant: "error",
          });
        });
    }
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const checkPermissionSendSample = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_SEND_SAMPLE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-detail-sample">
        <SimpleModalHeader
          title={t("UPDATE_SAMPLE_LAB")}
          onClose={() => {
            history.goBack();
          }}
        />
        <div className="order-container">
          {!!order ? (
            <>
              <Form onSubmit={async (values) => submitForm(values)} initialValues={order}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="order-form">
                      <div className="content">
                        <div>
                          <Grid container className="header">
                            <Grid item xs={3} className="left">
                              <div className="label">{t("FARM_NAME")}</div>
                              <span className="name">
                                {order.orders[0]?.camp?.name || TEXT_HOLDER}
                              </span>
                            </Grid>

                            <Grid item xs={2} className="col"></Grid>

                            <Grid item xs={2} justifyContent="flex-end" display="flex"></Grid>
                          </Grid>
                        </div>
                        <Grid container className="info">
                          <Grid item xs={3}>
                            <div className="label">{t("order:ORDER_ID")}</div>#{order.code}
                            <br /> <br />
                            <div className="label">{t("order:TOTAL_SAMPLES")}</div>
                            {order.totalSample}
                            <br /> <br />
                            <div className="label">{t("NOTES")}</div>
                            {order.orders[0].note || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={3}>
                            <div className="label">{t("order:TARGET")}</div>
                            {order.target?.name || TEXT_HOLDER}
                            <br /> <br />
                            <div className="label">{t("APPOINTMENT_DATE")}</div>
                            {moment(order.createdAt).format(TIME.DAY_MONTH_YEAR)}
                          </Grid>
                          <Grid item xs={3}>
                            <div className="label">{t("order:OBJECTIVE")}</div>
                            {order.objective?.name || TEXT_HOLDER}
                            <br /> <br />
                            <div className="label">{t("CREATOR")}</div>
                            {order.createdBy?.account?.code &&
                              order.createdBy?.account?.code + " - "}
                            {order.createdBy?.fullName}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            className="info-status"
                            style={{ paddingRight: "4rem", alignItems: "start" }}>
                            <div className="label">{t("order:REQUEST_TYPE")}</div>
                            {t(order.orders[0]?.requestType?.description) || TEXT_HOLDER}
                            <br /> <br />
                            <div className="label">{t("STATUS")}</div>
                            {StatusUIUtil.renderBgStatus(order.status, order.orders[0].source)}
                          </Grid>
                        </Grid>

                        <div
                          style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                          <button
                            className="btn-excel"
                            type="button"
                            onClick={() => {
                              handleSampleExport();
                              setNotiExport(true);
                              setSuccess(undefined);
                            }}>
                            <img src={Excel} alt="icon-excel" />
                            {t("EXPORT_DATA")}
                          </button>
                          <button
                            className="btn-excel"
                            onClick={() => {
                              setImportExcel(true);
                            }}
                            type="button"
                            style={{
                              background: "rgba(10, 104, 54, 1)",
                              color: "#fff",
                              marginLeft: "1rem",
                            }}>
                            <img src={ImportExcel} alt="icon-import-excel" />
                            {t("IMPORT_DATA")}
                          </button>
                        </div>
                        <div className="list">
                          <div className="order-detail-header">
                            <div className="stt">{t("STT")}</div>
                            <div className="quan">{t("order:TOTAL_SAMPLES")}</div>
                            <div className="method">{t("order:TEST_METHOD")}</div>
                            <div className="pathogen">{t("order:PATHOGEN")}</div>
                            <div className="quan">{t("order:TEST_QUANTITY")}</div>
                            <div className="type">{t("order:SAMPLE_TYPE")}</div>
                            <div className="total-quan">{t("order:TOTAL_TEST_QUANTITY")}</div>
                            <div className="total">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
                          </div>
                          <div className="order-detail-body">
                            {!!dataOrderLine.length &&
                              dataOrderLine.map((g: OrderElementModel, idx: number) => {
                                const orderLine = [...g.orderlines];
                                return (
                                  <div key={idx}>
                                    <div className="item">
                                      <div className="stt">
                                        <div className="code">{idx + 1}</div>
                                        <div
                                          className="code"
                                          onClick={() => {
                                            handleDialog(g.updated!, g);
                                          }}
                                          style={{
                                            color: g.updated ? "#0A6836" : "#A51D23",
                                            fontSize: "1.3rem",
                                            fontWeight: 400,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}>
                                          {g.updated
                                            ? t("VIEW_SAMPLE_INFO")
                                            : t("UPDATE_SAMPLE_INFO")}
                                        </div>
                                      </div>
                                      <div className="quan">{g.orderLabDetails.length}</div>
                                      <div className="method">{g.itemGroup?.name}</div>
                                      <div className="pathogen">
                                        {orderLine.map((i) => i.item.name).join(", ")}
                                      </div>

                                      <div className="quan">{Number(g.quantity)}</div>
                                      <div className="type">{g.sample.name}</div>
                                      <div className="total-quan">
                                        {orderLine.reduce(
                                          (acc, curr) => acc + Number(curr.orderQuantity),
                                          0
                                        )}
                                      </div>
                                      <div className="total">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(Number(g.orderAmount))
                                          : TEXT_ASTERISK}
                                        &ensp;đ
                                      </div>
                                    </div>
                                    <Divider />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            spacing={2}>
                            <Grid item xs={12}>
                              <div className="form-item" style={{ marginTop: "0" }}>
                                <div className="form-label">{t("order:SHIPPING_INFORMATION")}</div>
                                <Field name="shipInfo">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        onChange={(e) => {
                                          input.onChange(e.target.value);
                                        }}
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("order:SHIPPING_INFORMATION").toLowerCase(),
                                        })}
                                        color="success"
                                        className="form-text-field form-sel-field"
                                        inputProps={{
                                          className: "input",
                                          maxLength: 300,
                                        }}
                                        autoComplete="off"
                                        helperText={meta.touched ? meta.error : ""}
                                        error={
                                          (valid && !input.value && !ordDraft) ||
                                          (Boolean(meta.error) && meta.touched)
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className="form-item">
                                <div className="form-label">{t("TRANSPORT_PHONE_NUMBER")}</div>
                                <Field name="shipPhone">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        onChange={(e) => {
                                          input.onChange(e.target.value);
                                        }}
                                        type="number"
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("TRANSPORT_PHONE_NUMBER").toLowerCase(),
                                        })}
                                        color="success"
                                        className="form-text-field form-sel-field"
                                        inputProps={{
                                          className: "input",
                                        }}
                                        autoComplete="off"
                                        helperText={meta.touched ? meta.error : ""}
                                        error={
                                          (valid && !input.value && !ordDraft) ||
                                          (Boolean(meta.error) && meta.touched)
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                            <Grid item xs={4} className="info-order">
                              <div className="form-item">
                                <div className="form-label">{t("SAMPLE_TRANSFER_DATE")}</div>
                                <Field name="sentSampleDate">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          okLabel={t("order:SELECT")}
                                          cancelLabel={t("CANCEL")}
                                          fullWidth
                                          disablePast
                                          disableToolbar
                                          InputProps={{ readOnly: true }}
                                          inputVariant="outlined"
                                          format="dd-MM-yyyy"
                                          className="form-text-field date"
                                          value={input.value || moment().format("YYYY-MM-DD")}
                                          onChange={(value) => {
                                            if (value) {
                                              input.onChange(value);
                                            }
                                          }}
                                          inputProps={{ className: "input" }}
                                          keyboardIcon={
                                            <EventIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[22, 22]}
                                            />
                                          }
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                        />
                                      </MuiPickersUtilsProvider>
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                            <Grid item xs={4} className="total">
                              <div className="form-item">
                                <div className="form-label">{t("ESTIMATE_DATE")}</div>
                                <Field name="receivedSampleDate">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          okLabel={t("order:SELECT")}
                                          cancelLabel={t("CANCEL")}
                                          fullWidth
                                          disablePast
                                          disableToolbar
                                          InputProps={{ readOnly: true }}
                                          inputVariant="outlined"
                                          format="dd-MM-yyyy"
                                          className="form-text-field date"
                                          value={input.value || moment().format("YYYY-MM-DD")}
                                          onChange={(value) => {
                                            if (value) {
                                              input.onChange(value);
                                            }
                                          }}
                                          inputProps={{ className: "input" }}
                                          keyboardIcon={
                                            <EventIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[22, 22]}
                                            />
                                          }
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                        />
                                      </MuiPickersUtilsProvider>
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={"show"}
                          // className={selectedVars.length ? "show" : "hide"}
                        >
                          <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => {
                              history.goBack();
                            }}>
                            {t("CANCEL")}
                          </div>
                          <button
                            onClick={() => {
                              setOrdDraft(true);
                            }}
                            type="submit"
                            ref={submitRef}
                            className="btn-confirm-style btn-border btn--transparent"
                            style={{ marginLeft: "4rem" }}>
                            {t("UPDATE")}
                          </button>
                          <button
                            onClick={() => {}}
                            type="submit"
                            ref={submitRef}
                            className="btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {t("SAVE_SAMPLE")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      <Dialog
        maxWidth="lg"
        open={openBill}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // width: "427px",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onClose={() => {
          setOpenBill(false);
        }}>
        <Bill billData={order} />
      </Dialog>

      <Dialog fullWidth maxWidth="md" open={openUpdate}>
        <div className="import-excel">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "4rem",
            }}>
            <div className="title"></div>
            <div
              onClick={() => {
                setOpenUpdate(false);
              }}>
              <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}></div>
        </div>
      </Dialog>

      {/* chi tiết mẫu */}
      <Dialog fullWidth maxWidth="md" open={!!visibleDetail}>
        <div className="dialog-sample-detail">
          <div className="header">
            <div className="title">{t("DETAIL")}</div>
          </div>
          <div className="info">
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
              }}>
              {t("order:TEST_METHOD")} : {sampleInfo?.itemGroup?.name || TEXT_HOLDER}
            </div>
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
                padding: "1rem 0 2rem",
                borderBottom: "1px solid #f5f2f2",
              }}>
              {t("order:PATHOGEN")} :{" "}
              {sampleInfo?.orderlines?.map((i) => i.item.name).join(", ") || TEXT_HOLDER}
            </div>
          </div>
          <div className="content">
            <div className="content-header">
              <div className="name">{t("OBJECTIVE_DETAIL")}</div>
              <div className="age">{t("AGE")}</div>
              <div className="code">{t("SAMPLE_CODE")}</div>
              {sampleInfo?.itemGroup?.code === "PCRG" && (
                <div className="age" style={{ display: "flex" }}>
                  {t("TEMPLATE_SAMPLE_GROUP")}
                  <Tooltip title={t("TEMPLATE_SAMPLE_PCRG_ACCEPT_VALUE")} placement="top-end">
                    <span
                      style={{
                        paddingLeft: "1rem",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <HelpIcon />
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="body">
              {dataOrderLine
                .find((i) => i.id === visibleDetail?.id)
                ?.orderLabDetails.map((item, idx) => {
                  return (
                    <div className="content-item" key={idx}>
                      <div className="name">{item.objectiveDetail?.name || TEXT_HOLDER}</div>
                      <div className="age">{item.age}</div>
                      <div className="code">{item.sampleCode}</div>
                      {sampleInfo?.itemGroup?.code === "PCRG" && (
                        <div className="age">{item.mergeSample || TEXT_HOLDER}</div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="footer">
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setVisibleDetail(null)}>
              {t("CANCEL")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setVisibleUpdate(visibleDetail);
                setVisibleDetail(null);
              }}>
              {t("EDIT")}
            </button>
          </div>
        </div>
      </Dialog>

      {/* cập nhật mẫu */}
      <Dialog fullWidth maxWidth="lg" open={!!visibleUpdate}>
        <div className="dialog-sample-update">
          <div className="header">
            <div className="title">{t("UPDATE_INFO")}</div>
          </div>

          <div className="info">
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
              }}>
              {t("order:TEST_METHOD")} : {sampleInfo?.itemGroup?.name || TEXT_HOLDER}
            </div>
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 500,
                padding: "1rem 0 2rem",
                borderBottom: "1px solid #f5f2f2",
              }}>
              {t("order:PATHOGEN")} :{" "}
              {sampleInfo?.orderlines?.map((i) => i.item.name).join(", ") || TEXT_HOLDER}
            </div>
          </div>

          <div
            className="content"
            style={{
              height: !!tempDataSample.length ? "58rem" : "auto",
              paddingTop: "1rem",
            }}>
            {!!tempDataSample.length ? (
              tempDataSample.map((item: OrderLabDetail, idx: number) => {
                const checkPCRG: Boolean = sampleInfo?.itemGroup?.code === "PCRG";

                //ktra  mã mấu nếu sử dụng lại ở form khác
                const checkReuseSampleCode =
                  findMismatchedItems().findIndex((i) => i.id === item.id) > -1;

                //ktra  mã mấu nếu sử dụng lại ở form khác
                const checkReuseSampleCodeGroup =
                  findMismatchedItemsGroup().findIndex((i) => i.id === item.id) > -1;

                //ktra 1 form có bị trùng sampleCode (mã mẫu ) không
                const checkDuplicate =
                  hasDuplicates(tempDataSample).findIndex((i) => i.id === item.id) > -1;

                //ktra 1 form có sử dung 1 mergeSample( nhóm mẫu gộp) quá 5 lần không
                const checkDuplicateSample =
                  dataSampleDuplicate.findIndex((i) => i === item.mergeSample) > -1;

                //lấy data sampleCode trong orderlabdetail đã nhập trước đó
                const dataFlatSampleCode = dataOrderLine.flatMap((i) => i.orderLabDetails) || [];
                const mergeDataSampleCode = _.keys(
                  _.pickBy(_.countBy(dataFlatSampleCode, "sampleCode"))
                );

                const dataImportObjective: any =
                  dataImportSample.map((i) => i.objectiveDetail).filter((i) => !!i) || [];

                return (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    // spacing={1}
                    key={idx}
                    className="content-grid">
                    <Grid item xs={1} style={{ maxWidth: "3rem" }}>
                      <div className="form-item" style={{ fontSize: "1.6rem" }}>
                        <div
                          className="form-label"
                          style={{
                            color:
                              (valid && checkReuseSampleCode) ||
                              (valid && checkReuseSampleCodeGroup) ||
                              (valid && checkDuplicate) ||
                              (valid && checkDuplicateSample)
                                ? "#f44336"
                                : "#312f30",
                          }}>
                          {idx + 1}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={checkPCRG ? 3 : 4}>
                      <div className="form-item" style={{ width: "100%" }}>
                        <div className="form-label">{t("OBJECTIVE_DETAIL")}</div>
                        <FormControl error={valid && !item.objectiveDetail?.id}>
                          <Select
                            value={item.objectiveDetail?.id || undefined}
                            onChange={(e: any) =>
                              handleUpdateSample(item, "update", e.target.value, "objectiveDetail")
                            }
                            fullWidth
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            variant="outlined"
                            style={{ width: "100%" }}
                            className="form-select-field"
                            renderValue={() =>
                              !!item.objectiveDetail ? (
                                <span>
                                  {
                                    dataObjective?.objectiveDetails?.find(
                                      (i) => i.id === item.objectiveDetail?.id
                                    )?.name
                                  }
                                </span>
                              ) : (
                                <span style={{ color: "#9e9e9e" }}>
                                  {t("SELECT_BY_NAME", {
                                    NAME: t("OBJECTIVE_DETAIL").toLowerCase(),
                                  })}
                                </span>
                              )
                            }
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                              classes: {
                                paper: "paper-select-menu-list",
                              },
                            }}>
                            {!!dataObjective &&
                              dataObjective?.objectiveDetails
                                ?.concat(dataImportObjective)
                                ?.map((i) => {
                                  return (
                                    <MenuItem
                                      key={i.id}
                                      value={i.id}
                                      style={{
                                        borderBottom: "0.1rem solid #EFEFEF",
                                      }}>
                                      {t(i.name)}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={checkPCRG ? 2 : 3} className="info-order">
                      <div className="form-item" style={{ width: "100%" }}>
                        <div className="form-label">{t("AGE")}</div>
                        <Autocomplete
                          freeSolo
                          options={[...dataImportSample.map((i) => i.age)]}
                          value={item.age}
                          onInputChange={(e, newInputValue) => {
                            handleUpdateSample(item, "update", newInputValue, "age");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className="form-text-field"
                              variant="outlined"
                              error={valid && !item.age}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("AGE").toLowerCase(),
                              })}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={checkPCRG ? 2 : 3} className="item">
                      <div className="form-item" style={{ width: "100%" }}>
                        <div
                          className="form-label"
                          style={{
                            color:
                              (valid && checkReuseSampleCode) ||
                              (valid && checkReuseSampleCodeGroup) ||
                              (valid && checkDuplicate)
                                ? "#f44336"
                                : "#312f30",
                          }}>
                          {t("SAMPLE_CODE")}
                        </div>
                        <Autocomplete
                          freeSolo
                          options={[
                            ...dataImportSample.map((i) => i.sampleCode),
                            ...mergeDataSampleCode,
                          ]}
                          value={item.sampleCode}
                          onInputChange={(e, newInputValue) => {
                            handleUpdateSample(item, "update", newInputValue, "sampleCode");
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              className="form-text-field"
                              variant="outlined"
                              error={valid && !item.sampleCode}
                              placeholder={t("INPUT_BY_NAME", {
                                NAME: t("SAMPLE_CODE").toLowerCase(),
                              })}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                    {checkPCRG && (
                      <Grid item xs={2} className="info-order">
                        <div className="form-item" style={{ width: "100%" }}>
                          <div
                            className="form-label"
                            style={{
                              color: valid && checkDuplicateSample ? "#f44336" : "#312f30",
                            }}>
                            {t("TEMPLATE_SAMPLE_GROUP")}
                            <Tooltip title={t("Mẫu gộp cho PCRG 5 gộp 1")} placement="top-end">
                              <span
                                style={{
                                  paddingLeft: "1rem",
                                  cursor: "pointer",
                                  display: "flex",
                                }}>
                                <HelpIcon />
                              </span>
                            </Tooltip>
                          </div>
                          {/* <TextField
                            value={item.mergeSample}
                            fullWidth
                            placeholder={t("INPUT_BY_NAME", {
                              NAME: t("TEMPLATE_SAMPLE_GROUP").toLowerCase(),
                            })}
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            error={valid && !item.mergeSample}
                            onChange={(e) =>
                              handleUpdateSample(item, "update", e.target.value, "sampleTemple")
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                return false;
                              }
                            }}
                          /> */}
                          <FormControl error={valid && !item.mergeSample}>
                            <Select
                              value={item.mergeSample}
                              onChange={(e) => {
                                handleUpdateSample(item, "update", e.target.value, "sampleTemple");
                              }}
                              fullWidth
                              input={<OutlinedInput className="form-text-field" color="success" />}
                              variant="outlined"
                              style={{ width: "100%" }}
                              className="form-select-field"
                              renderValue={() =>
                                !!item.mergeSample ? (
                                  <span>{item.mergeSample}</span>
                                ) : (
                                  <span style={{ color: "#9e9e9e" }}>
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("TEMPLATE_SAMPLE_GROUP").toLowerCase(),
                                    })}
                                  </span>
                                )
                              }
                              displayEmpty
                              MenuProps={{
                                className: "select-menu-list",
                                classes: {
                                  paper: "paper-select-menu-list",
                                },
                              }}>
                              {!!dataLabSampleGroupCode &&
                                dataLabSampleGroupCode?.map(
                                  (code: LabSampleGroupCodeOutputModel) => {
                                    return (
                                      <MenuItem key={code.id} value={`${code.code} - ${code.name}`}>
                                        {`${code.code} - ${code.name}`}
                                      </MenuItem>
                                    );
                                  }
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={1} className="item">
                      <div className="ic-del">
                        <div>
                          <ContentCopy
                            className="copy-icon"
                            style={{
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                            onClick={() => onDuplicateSample(item)}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: "3rem",
                          }}
                          onClick={() => handleUpdateSample(item, "delete")}>
                          <DeleteOutlineIcon
                            style={{
                              fontSize: "3rem",
                              cursor: "pointer",
                              color: "#5A5959",
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <div style={{ textAlign: "center" }}>
                <img src={SampleEmpty} alt="new-order" />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1.6rem",
                    fontWeight: 500,
                  }}>
                  {t("SAMPLE_EMPTY")}
                </div>
              </div>
            )}
          </div>

          <div className="action">
            <span
              onClick={() => {
                handleUpdateSample(null, "add");
              }}>
              + {t("ADD")}
            </span>
          </div>

          <div className="footer">
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => handleCancelSample()}>
              {t("CANCEL")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => handleSaveSample()}>
              {t("SAVE")}
            </button>
          </div>
        </div>
      </Dialog>

      <NotiExportExcel
        notiExport={notiExport}
        title={t("ORDER")}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />

      <Dialog fullWidth maxWidth={"sm"} open={importExcel}>
        <div className="dialog-import-letter">
          <div className="title">
            <div>{t("IMPORT_SAMPLE_INFO")}</div>
            <div
              onClick={() => {
                setImportExcel(false);
                setSelectedFile(null);
              }}>
              <ClearIcon style={{ fontSize: "2.8rem", cursor: "pointer" }} />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center", margin: "3rem 0" }}>
            <div className="file-import">
              <button className={selectedFile ? "file-btn file-btn-success" : "file-btn"}>
                {selectedFile !== null ? (
                  <>
                    <div className="file-name" style={{ marginTop: "8rem" }}>
                      <span>{selectedFile?.name}</span>
                      <button
                        className="ic-click"
                        onClick={(e) => {
                          setSelectedFile(null);
                          setCheckErr(false);
                        }}>
                        <ClearIcon style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <div className="ic-click-update">
                      <SearchFile color={"#312F30"} size={[24, 24]} viewBox={[24, 24]} />
                      &ensp;{t("SELECT_FILE_FROM_COMPUTER")}
                      <input type="file" onChange={onFileChangeExcel} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src={IconExcel} alt="ic_import_excel" />
                    <span dangerouslySetInnerHTML={{ __html: t("DRAG_DROP_SELECT_FILE") }} />
                    <input type="file" onChange={onFileChangeExcel} />
                  </>
                )}
              </button>
              <div> {t("EXCEL_SAMPLE_INFO_FILE")} </div>
              {typeof checkErr === "string" && <span className="err">{checkErr}</span>}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setImportExcel(false);
                setSelectedFile(null);
              }}>
              {t("CANCEL")}
            </button>
            <button
              disabled={!selectedFile}
              className="btn-confirm-style"
              onClick={(e: any) => {
                handleSampleImport();
              }}
              style={{ opacity: !selectedFile ? 0.6 : 1 }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={importFile}
        title={t("UPLOADING_SAMPLE_INFO_FILE")}
        subTitle={t("ACTION_IN_PROGRESS")}
        icon={
          <img src={GifImport} alt="importLetter" style={{ width: "20rem", height: "20rem" }} />
        }
        actions={
          <button
            className="btn-confirm-style"
            style={{
              width: "20.6rem",
              borderRadius: "3rem",
            }}
            onClick={() => {
              setImportFile(false);
            }}>
            {t("CLOSE")}
          </button>
        }
        onClose={() => {
          setImportFile(false);
        }}
      />
    </Dialog>
  );
};

export default UpdateOrderLabSample;
