/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import ExcelWhite from "@Assets/images/icons/excel_w.png";
import IconPdfs from "@Assets/images/icons/ic_pdf.png";
import IconExcel from "@Assets/images/icons/ic_xls.png";
import Search from "@Assets/images/icons/img-search.png";
import ImportExcel from "@Assets/images/icons/import_excel.png";
import LetterNull from "@Assets/images/letter-null.png";
import GifImport from "@Assets/importLetter.gif";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import { SearchFile } from "@Components/Icons/SearchFile";
import { UploadNullIcon } from "@Components/Icons/UploadNullIcon";
import { UploadSuccessIcon } from "@Components/Icons/UploadSuccessIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import { BASE_LINK_IMAGE, DEFAULT_STORE_INFOS, Month, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { useBreakpoints } from "@Hooks";
import { Action, BulkDiscountPermission, DiscountOutputModel, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { DiscountService } from "@Services";
import { PermissionUtil, YearList, convertTv, currencyFormat } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  PriorityHigh as PriorityHighIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  Search as SearchIcon,
  TripOrigin as TripOriginIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<DiscountOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const DiscountLetter: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const breakpoints = useBreakpoints();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + new Date().getFullYear()
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [detailLetter, setDetailLetter] = useState<any>(null);
  const [importLetter, setImportLetter] = useState<boolean>(false);
  const [monthImport, setMonthImport] = useState<string>(
    ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [yearImport, setYearImport] = useState<string>(new Date().getFullYear().toString());
  const [isReplace, setIsReplace] = useState<boolean>(true);
  const [letterSuccess, setLetterSuccess] = useState<{ show: boolean; data: any }>({
    show: false,
    data: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [importFile, setImportFile] = useState<boolean>(false);
  const [keyImport, setKeyImport] = useState<string>("");
  const [notiDelete, setNotiDelete] = useState<string>("");
  const [idsDelete, setIdsDelete] = useState<number[]>([]);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 20,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await DiscountService.getDiscount(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        // searchString: searchValue !== "" ? searchValue : undefined,
        period: time,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const [selectedFile, setSelectedFile] = useState<{ pdfs: any; excel: any }>({
    pdfs: [],
    excel: null,
  });

  const onFileChangePdfs = (e: any) => {
    let files = e.target.files;
    let filesArr = Array.prototype.slice.call(files);
    if (selectedFile.pdfs?.length > 0) {
      return setSelectedFile({
        pdfs: [...selectedFile?.pdfs, ...filesArr],
        excel: selectedFile.excel,
      });
    }
    return setSelectedFile({
      pdfs: [...filesArr],
      excel: selectedFile.excel,
    });
  };

  const onFileChangeExcel = (event: any) => {
    const fileExcel: File = event.target.files[0];
    setSelectedFile({ pdfs: selectedFile.pdfs, excel: fileExcel });
  };

  const [checkErr, setCheckErr] = useState<{
    pdfs: boolean | string;
    excel: boolean | string;
  }>({ pdfs: false, excel: false });

  const checkFile = () => {
    const filePdfs = selectedFile.pdfs?.map((pdf: any) => {
      return pdf?.name.slice(pdf?.name.lastIndexOf("."), pdf?.name.length);
    });

    const fileExcel = selectedFile.excel?.name.slice(
      selectedFile.excel?.name.lastIndexOf("."),
      selectedFile.excel?.name.length
    );

    const totalFilePdfs = selectedFile.pdfs?.reduce((sum: number, item: any) => {
      sum += +item.size;
      return sum;
    }, 0);

    if (fileExcel && fileExcel !== ".xls") {
      if (fileExcel !== ".xlsx") {
        return setCheckErr({
          pdfs: checkErr.pdfs,
          excel: "Tập tin tải lên phải có định dạng .xls hoặc .xlsx",
        });
      }
    }
    if (filePdfs?.length > 0 && filePdfs.filter((f: any) => f !== ".pdf")?.length > 0) {
      return setCheckErr({
        pdfs: "Tập tin tải lên phải có định dạng .pdf",
        excel: checkErr.excel,
      });
    }

    if (selectedFile.excel?.size + totalFilePdfs >= 100000000) {
      return setCheckErr({
        pdfs: "Bạn chỉ có thể tải lên tập tin có dung lượng dưới 100 Mb",
        excel: "Bạn chỉ có thể tải lên tập tin có dung lượng dưới 100 Mb",
      });
    }

    return setCheckErr({ pdfs: true, excel: true });
  };

  useEffect(() => {
    selectedFile && checkFile();
  }, [selectedFile]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (keyImport !== "") {
      const x = setInterval(async () => {
        try {
          const res = await DiscountService.getRefreshDiscount({
            key: keyImport.toString(),
          });
          setLoading(true);
          if (res && (res.status === 200 || res.status === 201)) {
            if (res.data.status === true) {
              setImportFile(false);
              setLetterSuccess({ show: true, data: res.data });
              setKeyImport("");
              clearInterval(x);
              setLoading(false);
            } else if (res.data.status === 400) {
              setKeyImport("");
              enqueueSnackbar("Import Thư CN/CK thất bại!", {
                variant: "error",
              });
              setImportFile(false);
            }
          } else {
          }
        } catch (error) {
          setLoading(false);
          enqueueSnackbar("Import Thư CN/CK thất bại!", {
            variant: "error",
          });
          setImportFile(false);
          console.log(error);
        }
      }, 5000);
    }
  }, [keyImport !== ""]);

  const importDiscount = async () => {
    if (selectedFile.pdfs?.length === 0) {
      return setCheckErr({ pdfs: "Bạn chưa chọn tập tin để tải lên", excel: checkErr.excel });
    }
    if (selectedFile.excel === null) {
      return setCheckErr({ pdfs: checkErr.pdfs, excel: "Bạn chưa chọn tập tin để tải lên" });
    }
    if (checkErr.pdfs === true && checkErr.excel === true) {
      setImportFile(true);
      const formData = new FormData();
      for (const file of selectedFile.pdfs) {
        formData.append("pdfs", file);
      }
      formData.append("excel", selectedFile.excel);
      formData.set("period", monthImport + "-" + yearImport);
      formData.set("isReplace", isReplace as any);
      try {
        setLoading(true);
        const res = await DiscountService.importDiscount(formData);
        if (res && (res.status === 200 || res.status === 201)) {
          if (res.data?.key) {
            setKeyImport(res.data?.key.toString());
          }
          setImportLetter(false);
          setLoading(false);
          setSelectedFile({ pdfs: [], excel: null });
        } else if (res && res.status === 400 && res.data.message === "IMPORTING") {
          setImportLetter(true);
        } else {
          enqueueSnackbar("Import Thư CN/CK thất bại!", {
            variant: "error",
          });
          setImportFile(false);
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar("Import Thư CN/CK thất bại!", {
          variant: "error",
        });
        setImportFile(false);
      }
    }
  };

  useEffect(() => {
    if (loading) {
      window.onbeforeunload = (event: any) => {
        const e = event || window.event;
        if (e) {
          e.returnValue = "";
        }
        return "";
      };
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [loading]);

  const deleteDiscount = async () => {
    try {
      const result = await DiscountService.deleteDiscount({ ids: idsDelete, period: time });
      if (result && (result.status === 200 || result.status === 201)) {
        enqueueSnackbar("Xóa thư chiết khấu thành công!", {
          variant: "success",
        });
        setNotiDelete("");
        setIdsDelete([]);
        await fetchData(
          {
            page: 1,
            limit: 50,
            // searchString: searchValue !== "" ? searchValue : undefined,
            period: time,
          },
          true
        );
      }
    } catch (err) {
      enqueueSnackbar("Xóa thư chiết khấu thất bại!", {
        variant: "error",
      });
    }
  };

  const [valueLimit, setValueLimit] = useState<number>(50);
  const searchString = convertTv(searchValue);

  //loop
  // const data = Array.from({ length: 1000 }, (_, idx: number) => {
  //   return state.data[idx % state.data.length];
  // });

  const checkPermissionDeleteBulkDiscount = PermissionUtil.check(
    profile?.role?.permissions,
    BulkDiscountPermission.MANAGE_BULK_DISCOUNT_DELETE,
    true
  );

  return (
    <div className="discount-letter">
      <div className="discount-letter-content">
        <div className="action">
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Nhập mã, tên khách hàng..."
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>
            <button
              className="btn-excel"
              onClick={() => {
                setImportLetter(true);
              }}>
              <img src={ImportExcel} alt="icon-import-excel" />
              Import Excel
            </button>
          </div>
          {idsDelete?.length > 0 && (
            <div style={{ display: "flex" }}>
              <button
                className="btn-confirm-style"
                style={{
                  marginRight: "2.4rem",
                  color: "#312F30",
                  background: "#fff",
                }}
                onClick={() => {
                  setIdsDelete([]);
                }}>
                {t("CANCEL")}
              </button>
              <button
                className="btn-confirm-style"
                onClick={() => {
                  setNotiDelete(idsDelete?.length.toString());
                }}>
                Xóa
              </button>
            </div>
          )}
        </div>

        <div className="list">
          <div className="list-left" style={{ width: detailLetter !== null ? "30%" : "100%" }}>
            <div className="list-header">
              <div className="item-left" style={{ width: detailLetter !== null ? "70%" : "100%" }}>
                <div className="id" style={{ width: detailLetter && "50%" }}>
                  No.
                </div>
                {!detailLetter && (
                  <>
                    <div className="cus">{t("CUSTOMER")}</div>
                    <div className="division">{t("PRODUCT_CATEGORY")}</div>
                    <div className="time">Thời gian cập nhật</div>
                  </>
                )}
                <div
                  className="dis"
                  style={{
                    width: detailLetter && "50%",
                  }}>
                  {t("order:DISCOUNT")}
                </div>
                {!detailLetter && <div className="pic">Hình ảnh</div>}
              </div>
              {checkPermissionDeleteBulkDiscount && (
                <div
                  className="del"
                  style={{
                    width: detailLetter && "25%",
                    marginLeft: detailLetter ? "2rem" : "0",
                    backgroundColor: "#f0f3f8",
                  }}>
                  <span
                    onClick={() => {
                      idsDelete.length === state.data?.length
                        ? setIdsDelete([])
                        : setIdsDelete(
                            state.data.map((item: any) => {
                              return item.id;
                            })
                          );
                    }}>
                    {idsDelete?.length > 0 && idsDelete?.length < state.data?.length
                      ? "Chọn tất cả"
                      : idsDelete.length > 0 && idsDelete.length === state.data?.length
                      ? "Hoàn tác"
                      : ""}
                  </span>
                </div>
              )}
            </div>
            {state.loading ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : (
              <>
                {state.data ? (
                  state.data.length > 0 ? (
                    <InfiniteScroll
                      useWindow={false}
                      pageStart={0}
                      initialLoad={false}
                      hasMore={valueLimit < state.totalRecords}
                      loadMore={() => {
                        const newValue = valueLimit + 50;
                        setValueLimit(newValue);
                      }}>
                      {(searchValue
                        ? state.data.filter(
                            (f: any) =>
                              f.code.toLowerCase().includes(searchString) ||
                              convertTv(f.customer?.fullName).includes(searchString) ||
                              f.customer?.code.toLowerCase().includes(searchString)
                          )
                        : state.data
                      )
                        .slice(0, valueLimit)
                        .map((item: any, index: number) => {
                          return (
                            <div key={index} className="item">
                              <div
                                className="item-left"
                                style={{ width: detailLetter !== null ? "70%" : "100%" }}
                                onClick={() => {
                                  setDetailLetter(item);
                                }}>
                                <div className="id" style={{ width: detailLetter && "50%" }}>
                                  {item.code}
                                </div>
                                {!detailLetter && (
                                  <>
                                    <div className="cus">
                                      {item?.customer?.fullName || TEXT_HOLDER}
                                      <div>{item.customer.code || TEXT_HOLDER}</div>
                                    </div>
                                    <div className="division">
                                      {item.divisions?.map((s: any, idx: number) => {
                                        return (
                                          <span key={idx}>
                                            {s?.description || TEXT_HOLDER},&ensp;
                                          </span>
                                        );
                                      })}
                                    </div>
                                    <div className="time">
                                      {moment(item?.updatedAt).format("DD/MM/YYYY - HH:mm")}
                                    </div>
                                  </>
                                )}
                                <div
                                  className="dis"
                                  style={{
                                    width: detailLetter && "50%",
                                    // marginRight: detailLetter ? "5rem" : "0",
                                  }}>
                                  {currencyFormat(item.amount)} đ
                                </div>
                                {!detailLetter && (
                                  <>
                                    <div className="pic">
                                      {item.file ? (
                                        <UploadSuccessIcon
                                          color="#41AD49"
                                          size={[20, 20]}
                                          viewBox={[20, 20]}
                                        />
                                      ) : (
                                        <UploadNullIcon
                                          color="#ADACAC"
                                          size={[20, 20]}
                                          viewBox={[20, 20]}
                                        />
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              {checkPermissionDeleteBulkDiscount && (
                                <div
                                  className="del"
                                  style={{
                                    width: detailLetter && "25%",
                                    marginLeft: detailLetter ? "2rem" : "0",
                                  }}>
                                  <Checkbox
                                    style={{ position: "relative" }}
                                    icon={
                                      <RadioButtonUncheckedIcon
                                        style={{ color: "#ADACAC", fontSize: 22 }}
                                      />
                                    }
                                    checkedIcon={
                                      <TripOriginIcon
                                        style={{
                                          borderRadius: "50%",
                                          backgroundColor: "#41AD49",
                                          backgroundImage:
                                            "radial-gradient(#fff,#fff 8%,transparent 32%)",
                                          color: "#41AD49",
                                          fontSize: 20,
                                        }}
                                      />
                                    }
                                    checked={idsDelete.includes(item.id)}
                                    value={item.id}
                                    onChange={() => {
                                      idsDelete.includes(item.id)
                                        ? setIdsDelete(
                                            idsDelete
                                              .filter((e) => e !== item.id)
                                              .map((item: any) => {
                                                return item;
                                              })
                                          )
                                        : setIdsDelete([...idsDelete, item.id]);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </InfiniteScroll>
                  ) : visibleSearch === true ? (
                    <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                  ) : (
                    <EmptyList img={Img} title={t("EMPTY_LIST")} />
                  )
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )}
              </>
            )}
          </div>
          {detailLetter && (
            <div className="view">
              <div className="view-header">
                <div className="title">{detailLetter.code || TEXT_HOLDER}</div>
                <div className="view-header-button">
                  {!detailLetter.file ? (
                    <button
                      className="btn-confirm-style"
                      onClick={() => {}}
                      disabled={!detailLetter.file}
                      style={{ opacity: !detailLetter.file ? 0.6 : 1 }}>
                      <img src={ExcelWhite} alt="icon-excel_w" />
                      Xuất file
                    </button>
                  ) : (
                    <a
                      href={BASE_LINK_IMAGE + detailLetter.file}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="btn-confirm-style">
                      <img src={ExcelWhite} alt="icon-excel_w" />
                      Xuất file
                    </a>
                  )}
                  <button
                    className="btn-white"
                    onClick={(e) => {
                      setDetailLetter(null);
                    }}>
                    <ClearIcon fontSize="large" />
                  </button>
                </div>
              </div>
              <div className="view-image">
                {detailLetter.file ? (
                  <>
                    <iframe
                      style={{ width: "96%", height: "100%" }}
                      src={BASE_LINK_IMAGE + detailLetter.file}
                      title="pdf"
                    />
                  </>
                ) : (
                  <img src={LetterNull} alt="letter" />
                )}
                <br />
                {!detailLetter.file && "Chưa tìm thấy thư CN/CK phù hợp"}
              </div>
            </div>
          )}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(month + "-" + year);
                handleClose();
                setDetailLetter(null);
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <Dialog fullWidth maxWidth={breakpoints.lg ? "md" : "sm"} open={importLetter}>
        <div className="dialog-import-letter">
          <div className="title">
            <div>Nhập thư CN/CK</div>
            <div
              onClick={() => {
                setImportLetter(false);
                setSelectedFile({ pdfs: null, excel: null });
              }}>
              <ClearIcon style={{ fontSize: "2.8rem", cursor: "pointer" }} />
            </div>
          </div>
          <div className="period">
            Chọn kỳ:
            <Select
              value={monthImport}
              onChange={(e) => {
                setMonthImport(e.target.value as string);
              }}
              color="success"
              MenuProps={{
                className: "select-menu-list select-letter",
              }}>
              {Month.map((m: any, idx: number) => {
                return (
                  <MenuItem key={idx} value={m.month}>
                    {m.month}
                  </MenuItem>
                );
              })}
            </Select>
            <Select
              value={yearImport}
              onChange={(e) => setYearImport(e.target.value as string)}
              color="success"
              MenuProps={{
                className: "select-menu-list select-letter",
              }}>
              {YearList().map((y: any) => {
                return <MenuItem value={y}>{y}</MenuItem>;
              })}
            </Select>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="file-import" style={{ marginRight: "2rem" }}>
              <button
                className={
                  selectedFile.pdfs?.length > 0 ? "file-btn file-btn-success" : "file-btn"
                }>
                {selectedFile.pdfs?.length > 0 ? (
                  <>
                    <div style={{ overflow: "auto", height: "60%", margin: "2rem 0" }}>
                      {selectedFile.pdfs?.map((pdf: any, idx: number) => {
                        return (
                          <div className="file-name" style={{ marginBottom: "1.6rem" }} key={idx}>
                            <span>{pdf?.name}</span>
                            <br />
                            <button
                              className="ic-click"
                              onClick={(e) => {
                                setSelectedFile({
                                  pdfs: selectedFile.pdfs.filter((x: any) => x !== pdf),
                                  excel: selectedFile.excel,
                                });
                                setCheckErr({ pdfs: false, excel: checkErr.excel });
                              }}>
                              <ClearIcon style={{ cursor: "pointer" }} />
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="ic-click-update">
                      <SearchFile color={"#312F30"} size={[24, 24]} viewBox={[24, 24]} />
                      &ensp;Chọn tệp từ máy tính
                      <input type="file" multiple onChange={onFileChangePdfs} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src={IconPdfs} alt="ic_import_pdf" />
                    Kéo thả hoặc lựa chọn
                    <br />
                    file .pdf để tải lên
                    <input type="file" multiple onChange={onFileChangePdfs} />
                  </>
                )}
              </button>
              <div>1. Thư CN/CK</div>
              {typeof checkErr.pdfs === "string" && <span className="err">{checkErr.pdfs}</span>}
            </div>
            <div className="file-import" style={{ marginLeft: "2rem" }}>
              <button className={selectedFile.excel ? "file-btn file-btn-success" : "file-btn"}>
                {selectedFile.excel !== null ? (
                  <>
                    <div className="file-name" style={{ marginTop: "8rem" }}>
                      <span>{selectedFile.excel?.name}</span>
                      <button
                        className="ic-click"
                        onClick={(e) => {
                          setSelectedFile({ pdfs: selectedFile.pdfs, excel: null });
                          setCheckErr({ pdfs: checkErr.pdfs, excel: false });
                        }}>
                        <ClearIcon style={{ cursor: "pointer" }} />
                      </button>
                    </div>
                    <div className="ic-click-update">
                      <SearchFile color={"#312F30"} size={[24, 24]} viewBox={[24, 24]} />
                      &ensp;Chọn tệp từ máy tính
                      <input type="file" onChange={onFileChangeExcel} />
                    </div>
                  </>
                ) : (
                  <>
                    <img src={IconExcel} alt="ic_import_excel" />
                    Kéo thả hoặc lựa chọn
                    <br />
                    file .xls để tải lên
                    <input type="file" onChange={onFileChangeExcel} />
                  </>
                )}
              </button>
              <div>2. File excel khai báo thư </div>
              {typeof checkErr.excel === "string" && <span className="err">{checkErr.excel}</span>}
            </div>
          </div>
          <div style={{ margin: "2.6rem 0 2rem" }}>
            <Checkbox
              defaultChecked
              value={isReplace}
              onChange={() => {
                setIsReplace(!isReplace);
              }}
              style={{ transform: "scale(1.5)", color: "#60B867" }}
            />
            Ghi đè lên file PDF trùng tên
          </div>
          <a
            href={DEFAULT_STORE_INFOS.FILE_EXCEL_DISCOUNT}
            download="file-mau.xlsx"
            target="_blank"
            rel="noreferrer"
            className="file-link-color">
            Tải file mẫu khai báo thư CN/CK
          </a>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setImportLetter(false);
                setSelectedFile({ pdfs: null, excel: null });
              }}>
              {t("CANCEL")}
            </button>
            <button
              disabled={!selectedFile.pdfs || !selectedFile.excel}
              className="btn-confirm-style"
              onClick={(e: any) => {
                importDiscount();
              }}
              style={{ opacity: !selectedFile.pdfs || !selectedFile.excel ? 0.6 : 1 }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={letterSuccess.show === true}
        title={(() => {
          if (
            letterSuccess?.data?.result?.error > 0 &&
            letterSuccess?.data?.result?.create <= 0 &&
            letterSuccess?.data?.result?.update <= 0
          ) {
            return "Nhập thư CN/CK thất bại";
          } else if (
            letterSuccess?.data?.result?.error > 0 &&
            (letterSuccess?.data?.result?.create > 0 || letterSuccess?.data?.result?.update > 0)
          ) {
            return "Nhập 1 phần thư CN/CK";
          } else {
            return "Nhập thư CN/CK thành công";
          }
        })()}
        subTitle={
          <div className="letter-file-success">
            <div>
              Thư CN/CK đã nhập thất bại. Kết quả như sau:
              <br />
              <br />
            </div>
            <Grid container style={{ maxWidth: "43.5rem", color: "#000000" }}>
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                className="col">
                <div>Thêm thành công</div>
                <div>Lỗi</div>
                <div>{t("UPDATE_SUCCESSFUL")}</div>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
                className="col">
                <div>{letterSuccess?.data?.result?.create} thư</div>
                <div>{letterSuccess?.data?.result?.error} thư</div>
                <div>{letterSuccess?.data?.result?.update} thư</div>
              </Grid>
            </Grid>
          </div>
        }
        icon={(() => {
          if (
            letterSuccess?.data?.result?.error > 0 &&
            letterSuccess?.data?.result?.create <= 0 &&
            letterSuccess?.data?.result?.update <= 0
          ) {
            return (
              <div className={"notification-icon-box"} style={{ background: "#B8292F" }}>
                <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          } else if (
            letterSuccess?.data?.result?.error > 0 &&
            (letterSuccess?.data?.result?.create > 0 || letterSuccess?.data?.result?.update > 0)
          ) {
            return (
              <div className={"notification-icon-box"} style={{ background: "#FFBC1F" }}>
                <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          } else {
            return (
              <div className={"notification-icon-box"} style={{ background: "#0A6836" }}>
                <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
              </div>
            );
          }
        })()}
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "3rem",
              }}
              onClick={() => {
                setLetterSuccess({ show: false, data: letterSuccess?.data });
              }}>
              {t("CLOSE")}
            </button>
            {letterSuccess?.data?.errorFile !== null && (
              <a
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                rel="noreferrer"
                href={`${BASE_LINK_IMAGE + letterSuccess?.data?.errorFile}`}
                onClick={() => {
                  setTimeout(() => {
                    setLetterSuccess({ show: false, data: letterSuccess?.data });
                  }, 3000);
                }}>
                Tải file lỗi
              </a>
            )}
          </>
        }
        onClose={() => {
          setLetterSuccess({ show: false, data: letterSuccess?.data });
        }}
      />

      <Notification
        visible={importFile}
        title={"Đang tải tệp thư CK/CN"}
        subTitle={"Thao tác đang được thực hiện. Xin vui lòng chờ trong giây lát"}
        icon={
          <img src={GifImport} alt="importLetter" style={{ width: "20rem", height: "20rem" }} />
        }
        actions={
          <button
            className="btn-confirm-style"
            style={{
              width: "20.6rem",
              borderRadius: "3rem",
            }}
            onClick={() => {
              setImportFile(false);
            }}>
            {t("CLOSE")}
          </button>
        }
        onClose={() => {
          setImportFile(false);
        }}
      />

      <Notification
        visible={notiDelete !== ""}
        title={"Xóa thư chiết khấu?"}
        subTitle={`Bạn có chắc chắn muốn xóa ${notiDelete} thư chiết khấu ?`}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setNotiDelete("")}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                deleteDiscount();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete("");
        }}
      />
    </div>
  );
};

export default DiscountLetter;
