import GFCheckbox from "@Components/ui/GFCheckbox";
import { GFDataTableColumn, GFDataTableExpandable } from "@Components/ui/GFDataTable";
import { actionColumn } from "@Components/ui/GFDataTable/GFDataTableAction";
import GFDataTableHeaderCell from "@Components/ui/GFDataTable/GFDataTableHeaderCell";
import { isNil } from "lodash";

type GFDataTableHeaderProps = {
  columns: GFDataTableColumn[];
  expandable?: GFDataTableExpandable;
  isShowActions?: boolean;
  showCheckbox?: boolean;
};

export default function GFDataTableHeader({
  columns,
  expandable,
  isShowActions = false,
  showCheckbox,
}: GFDataTableHeaderProps) {
  const hasExpandable = isNil(expandable) ? false : true;
  return (
    <div className="gf-datatable__row gf-datatable__header">
      {hasExpandable && <div className="gf-datatable__expanded__temp"></div>}
      {showCheckbox && <div className="gf-datatable__expanded__temp"></div>}
      {columns.map((column, index) => (
        <GFDataTableHeaderCell column={column} key={column.dataIndex as string} />
      ))}

      {isShowActions && <GFDataTableHeaderCell column={actionColumn({})} />}
    </div>
  );
}
