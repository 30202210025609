/* eslint-disable react/jsx-no-useless-fragment */
import Avatar from "@Assets/images/avt.png";
import IconBuy from "@Assets/images/icons/ic_buy.png";
import Cancel from "@Assets/images/icons/ic_cancel.png";
import IconClose from "@Assets/images/icons/ic_close.png";
import Create from "@Assets/images/icons/ic_create.png";
import Decrease from "@Assets/images/icons/ic_decrease.png";
import Increase from "@Assets/images/icons/ic_increase.png";
import Loading from "@Components/Loading";
import NotAccess from "@Components/NotAccess";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
  APP_ROUTES,
  OrderStatusERP,
  ReasonStatus,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  UpdateHistory,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { ItemPermission, OrderPermission, OrderStatus, Reason } from "@Models";
import { Nullable } from "@Models/shared";
import { OrderService, SalesOrgService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Autorenew as AutorenewIcon,
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
  GridView as GridViewIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  ShareOutlined as ShareOutlinedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AxiosError } from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Bill from "./Bill";
import FeedBack from "./FeedBack";
import "./OrderDetail.scss";

const OrderModal: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<Nullable<any>>(null);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [permission, setPermission] = useState<boolean>(false);
  const submitRef = useRef<any | null>(null);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);
  const [openBill, setOpenBill] = useState<boolean>(false);
  const [openSaleOrg, setOpenSaleOrg] = useState<boolean>(false);
  const [valueSaleOrg, setValueSaleOrg] = useState<string>("");
  const [valueRecallOrderFeed, setValueRecallOrderFeed] = useState<Nullable<any>>(null);
  const [salesOrgForRecall, setSalesOrgForRecall] = useState<any[]>([]);
  const [feedBackSuccess, setFeedBackSuccess] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const [notiERP, setNotiERP] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getOrderById(+orderId);
      if (mounted.current) {
        setOrder(result.data.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedBackSuccess]);

  const fetchDataSalesOrgForRecall: () => Promise<void> = async () => {
    try {
      const result = await SalesOrgService.getSalesOrgForRecall({
        divisionId: valueRecallOrderFeed?.division.id,
        customerId: valueRecallOrderFeed?.customer.id,
      });
      if (mounted.current) {
        setSalesOrgForRecall(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    valueRecallOrderFeed && fetchDataSalesOrgForRecall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRecallOrderFeed, openSaleOrg === true]);

  // bo phan tu trung trong mang
  const status = Array.from(
    new Set(
      order?.orders.map((o: any) => {
        return o.status;
      })
    )
  );

  const cancelOrderFeed = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrder({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: "feed",
      });
      enqueueSnackbar(t("order:ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_FEED);
    } catch (error: any) {
      if (error.response?.status === 403) {
        setPermission(true);
      } else {
        enqueueSnackbar(t("order:CANCEL_ORDER_FAILED"), {
          variant: "error",
        });
      }
      setSubmitCancel(false);
      submitRef.current.disabled = false;
    }
  };

  const recallOrderFeed = (valueSaleOrg?: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    OrderService.recallOrder({
      orderId: valueRecallOrderFeed?.id,
      salesOrgId: valueSaleOrg ? +valueSaleOrg : undefined,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          if (res.data.message === "NEED_ADD_SALES_ORG") {
            setOpenSaleOrg(true);
          } else if (res.data.message.includes("SUCCEED")) {
            setNotiERP({ visible: true, status: true });
          } else {
            setNotiERP({ visible: true, status: false });
            setOpenSaleOrg(false);
            submitRef.current.disabled = false;
          }
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        if (err.response?.status === 403) {
          setPermission(true);
        } else {
          setNotiERP({ visible: true, status: false });
          submitRef.current.disabled = false;
        }
      });
  };

  useEffect(() => {
    valueRecallOrderFeed && recallOrderFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRecallOrderFeed?.id]);

  //them quyen can check
  const checkPermissionRecallOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_RECALL,
    true
  );

  const checkPermissionCancelOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_CANCEL,
    true
  );

  const checkPermissionUpdateOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_UPDATE,
    true
  );

  const checkPermissionCreateOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );

  const gridSize: any = 12 / (order?.orders?.length + 2);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-detail">
        <SimpleModalHeader
          title={t("order:ORDER_DETAIL")}
          onClose={() => {
            location.state?.header
              ? history.push({
                  pathname: APP_ROUTES.CUSTOMER_DETAIL_FEED.replace(
                    ":customerId",
                    order?.orders[0]?.customer?.id
                  ),
                  state: {
                    header: location.state?.header,
                    source: location.state?.source,
                  },
                })
              : order?.orderType &&
                history.push({
                  pathname: APP_ROUTES.ORDER + "-" + order?.orderType,
                  state: {
                    startTime: location.state?.startTime,
                    endTime: location.state?.endTime,
                    status: location.state?.status,
                  },
                });
          }}
        />
        <div className="order-detail-container">
          {!!order ? (
            <>
              <div className="content">
                <div>
                  <Grid container className="header">
                    <Grid item xs={4} className="left">
                      <img
                        src={checkLinkImage(order.orders[0].customer?.account?.avatar) || Avatar}
                        alt="Avata"
                        onError={renderErrorImageItem(order?.orderType)}
                      />
                      <div>
                        <div className="name">
                          {order.orders[0].customer !== null
                            ? order.orders[0].customer.fullName || TEXT_HOLDER
                            : order.orders[0].lead.fullName || TEXT_HOLDER}
                          &#160;-&#160;
                          {order.orders[0].customer !== null
                            ? order.orders[0].customer.code || TEXT_HOLDER
                            : order.orders[0].lead.code || TEXT_HOLDER}
                          {order.orders[0].customer === null && (
                            <span
                              style={{
                                color: "#838283",
                                fontWeight: 400,
                                fontSize: "1.4rem",
                                marginLeft: "1rem",
                              }}>
                              <span>&#9679;&ensp;</span> {t("order:POTENTIAL")}
                            </span>
                          )}
                        </div>
                        {order.orders[0].customer !== null
                          ? order.orders[0].customer.phoneNumber || TEXT_HOLDER
                          : order.orders[0].lead.phoneNumber || TEXT_HOLDER}
                      </div>
                    </Grid>
                    <Grid item container xs={5} className="col">
                      {order.orders.map((orders: any, index: number) => {
                        const check = Array.from(
                          new Set(
                            order.orders.map((orders: any) => {
                              return orders.salesOrg.description;
                            })
                          )
                        );
                        return (
                          <Grid item xs={6} key={index}>
                            <div className="label">
                              {check.length > 1
                                ? t("order:BUSINESS_UNIT") + " " + (index + 1)
                                : check[index] !== undefined
                                ? t("order:BUSINESS_UNIT")
                                : ""}
                            </div>
                            {/* {orders.division.description} */}
                            {check[index]}
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <Grid item xs={2} className="col">
                      <div className="label">Đơn vị kinh doanh (BU)</div>
                      {order.orders[0].salesOrg.description}
                    </Grid> */}
                    <Grid item xs={3} justifyContent="flex-end" display="flex">
                      {
                        <button className="btn-cancel-style" onClick={() => setOpenBill(true)}>
                          <ShareOutlinedIcon fontSize="large" />
                          &ensp;{t("order:SHARE")}
                        </button>
                      }
                      {
                        // (checkPermissionCancelOrder || checkPermissionUpdateOrder) &&
                        status.length !== 1 ? (
                          <Select
                            variant="outlined"
                            className="btn-green-style btn--shiny"
                            renderValue={() => <span>{t("order:OPTIONS")}</span>}
                            displayEmpty
                            startAdornment={
                              <InputAdornment position="start">
                                <GridViewIcon />
                              </InputAdornment>
                            }
                            inputProps={{ readOnly: false }}
                            MenuProps={{
                              className: "select-menu-list",
                              anchorOrigin: {
                                vertical: 60,
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                            }}>
                            {order?.orders.map((o: any) => {
                              return (() => {
                                switch (o.status) {
                                  case !OrderStatus.CONFIRMED && !OrderStatus.CREATED:
                                    return (
                                      <>
                                        {o.canCancelOrUpdate === true && (
                                          <>
                                            <MenuItem
                                              onClick={() => {
                                                checkPermissionCancelOrder
                                                  ? setShowCancel(true)
                                                  : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                      preventDuplicate: true,
                                                      variant: "error",
                                                    });
                                              }}>
                                              {t("order:CANCEL_ORDER")}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                checkPermissionUpdateOrder
                                                  ? history.push(
                                                      APP_ROUTES.UPDATE_ORDER_FEED.replace(
                                                        ":orderId",
                                                        order.id
                                                      )
                                                    )
                                                  : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                      preventDuplicate: true,
                                                      variant: "error",
                                                    });
                                              }}>
                                              {t("order:EDIT_ORDER")}
                                            </MenuItem>
                                          </>
                                        )}
                                      </>
                                    );
                                  case OrderStatus.PROCESSING:
                                    return (
                                      <>
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCreateOrder
                                              ? history.push(
                                                  APP_ROUTES.CREATE_FEED_ID.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("order:RESET_ORDER")}
                                        </MenuItem>
                                      </>
                                    );
                                  case OrderStatus.COMPLETED:
                                    return (
                                      <>
                                        <MenuItem
                                          onClick={() => {
                                            checkPermissionCreateOrder
                                              ? history.push(
                                                  APP_ROUTES.CREATE_FEED_ID.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          {t("order:RESET_ORDER")}
                                        </MenuItem>
                                      </>
                                    );
                                  case OrderStatus.CREATED:
                                    return (
                                      <>
                                        {o.canCancelOrUpdate === true && (
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionUpdateOrder
                                                ? history.push(
                                                    APP_ROUTES.UPDATE_ORDER_FEED.replace(
                                                      ":orderId",
                                                      order.id
                                                    )
                                                  )
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("order:EDIT_ORDER")}
                                          </MenuItem>
                                        )}
                                      </>
                                    );
                                  case OrderStatus.CONFIRMED:
                                    return (
                                      <>
                                        {o.canCancelOrUpdate === true && (
                                          <MenuItem
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            {t("order:CANCEL_ORDER")}
                                          </MenuItem>
                                        )}
                                      </>
                                    );
                                  default:
                                    return;
                                }
                              })();
                            })}
                          </Select>
                        ) : (
                          // eslint-disable-next-line array-callback-return
                          order?.orders.map((o: any, index: number) => {
                            if (status[index])
                              return (() => {
                                switch (o.status) {
                                  case OrderStatus.CONFIRMED:
                                    return (
                                      <>
                                        {o.canCancelOrUpdate === true && (
                                          <button
                                            className="btn-confirm-style"
                                            onClick={() => {
                                              checkPermissionCancelOrder
                                                ? setShowCancel(true)
                                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                    preventDuplicate: true,
                                                    variant: "error",
                                                  });
                                            }}>
                                            <img src={IconClose} alt="icon-close" />
                                            {t("order:CANCEL_ORDER")}
                                          </button>
                                        )}
                                      </>
                                    );
                                  case OrderStatus.PROCESSING:
                                    return (
                                      <>
                                        <button
                                          className="btn-confirm-style"
                                          onClick={() => {
                                            checkPermissionCreateOrder
                                              ? history.push(
                                                  APP_ROUTES.CREATE_FEED_ID.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          <img src={IconBuy} alt="icon-buy" />
                                          {t("order:RESET_ORDER")}
                                        </button>
                                      </>
                                    );
                                  case OrderStatus.COMPLETED:
                                    return (
                                      <>
                                        <button
                                          className="btn-confirm-style"
                                          onClick={() => {
                                            checkPermissionCreateOrder
                                              ? history.push(
                                                  APP_ROUTES.CREATE_FEED_ID.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          <img src={IconBuy} alt="icon-buy" />
                                          {t("order:RESET_ORDER")}
                                        </button>
                                      </>
                                    );
                                  case OrderStatus.CANCELLED:
                                    return (
                                      <>
                                        <button
                                          className="btn-confirm-style"
                                          onClick={() => {
                                            checkPermissionCreateOrder
                                              ? history.push(
                                                  APP_ROUTES.CREATE_FEED_ID.replace(
                                                    ":orderId",
                                                    order.id
                                                  )
                                                )
                                              : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                  preventDuplicate: true,
                                                  variant: "error",
                                                });
                                          }}>
                                          <img src={IconBuy} alt="icon-buy" />
                                          {t("order:RESET_ORDER")}
                                        </button>
                                      </>
                                    );
                                  case OrderStatus.CREATED:
                                    return (
                                      <>
                                        {o.canCancelOrUpdate === true && (
                                          <Select
                                            variant="outlined"
                                            className="btn-green-style btn--shiny"
                                            renderValue={() => <span>{t("order:OPTIONS")}</span>}
                                            displayEmpty
                                            startAdornment={
                                              <InputAdornment position="start">
                                                <GridViewIcon />
                                              </InputAdornment>
                                            }
                                            inputProps={{ readOnly: false }}
                                            MenuProps={{
                                              className: "select-menu-list",
                                              anchorOrigin: {
                                                vertical: 60,
                                                horizontal: "left",
                                              },
                                              transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                              },
                                            }}>
                                            <MenuItem
                                              onClick={() => {
                                                checkPermissionUpdateOrder
                                                  ? history.push(
                                                      APP_ROUTES.UPDATE_ORDER_FEED.replace(
                                                        ":orderId",
                                                        order.id
                                                      )
                                                    )
                                                  : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                      preventDuplicate: true,
                                                      variant: "error",
                                                    });
                                              }}>
                                              {t("order:EDIT_ORDER")}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                checkPermissionCancelOrder
                                                  ? setShowCancel(true)
                                                  : enqueueSnackbar(t("ACCESS_DENIED"), {
                                                      preventDuplicate: true,
                                                      variant: "error",
                                                    });
                                              }}>
                                              {t("order:CANCEL_ORDER")}
                                            </MenuItem>
                                          </Select>
                                        )}
                                      </>
                                    );
                                  default:
                                    return;
                                }
                              })();
                          })
                        )
                      }
                    </Grid>
                  </Grid>
                </div>

                <Grid container className="order-detail-info">
                  <Grid item xs={4}>
                    <div className="label">{t("order:ORDER_ID")}</div>#{order.code}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="label">{t("order:ORDER_TIME")}</div>
                    {moment(order.createdAt).format("DD/MM/YYYY - HH:mm")}
                  </Grid>
                  <Grid item xs={3}>
                    <div className="label">{t("order:ORDER_PLACER")}</div>
                    {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                    {order.createdBy?.fullName}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className="order-detail-info-status"
                    style={{ paddingRight: "4rem" }}>
                    <div className="label">{t("STATUS")}</div>
                    {StatusUIUtil.renderBgStatus(order.status)}
                  </Grid>
                </Grid>

                <div className="list">
                  {order.orders.map((g: any, index: number) => {
                    return (
                      <div key={index}>
                        <div className="order-detail-header">
                          <div className="name">
                            {g.division?.description} ({g.orderlines.length})
                          </div>
                          <div className="sub-status">
                            {(() => {
                              switch (g.subStatus) {
                                case OrderStatusERP.NOT_CREATE_ERP:
                                  return (
                                    <div style={{ color: "#0E76D9", background: "#F3FCFE" }}>
                                      {t("ERP_NOT_CREATED")}
                                      <>
                                        &ensp;|&ensp;
                                        <AutorenewIcon
                                          onClick={() => {
                                            if (checkPermissionRecallOrder) {
                                              setValueRecallOrderFeed(g);
                                              setLoading(true);
                                            } else {
                                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                            }
                                          }}
                                          style={{
                                            fontSize: "2rem",
                                            transform: "rotate(45deg)",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </>
                                    </div>
                                  );
                                case OrderStatusERP.NOT_UPDATED_ERP:
                                  return (
                                    <div style={{ color: "#E89806", background: "#FFFBEE" }}>
                                      {t("ERP_NOT_UPDATED")}
                                      <>
                                        &ensp;|&ensp;
                                        <AutorenewIcon
                                          onClick={() => {
                                            if (checkPermissionRecallOrder) {
                                              setValueRecallOrderFeed(g);
                                              setLoading(true);
                                            } else {
                                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                            }
                                          }}
                                          style={{
                                            fontSize: "2rem",
                                            transform: "rotate(45deg)",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </>
                                    </div>
                                  );
                                case "notCancelledERP":
                                  return (
                                    <div style={{ color: "#B8292F", background: "#FFEFEF" }}>
                                      {t("order:ERP_NOT_CANCEL")}
                                      <>
                                        &ensp;|&ensp;
                                        <AutorenewIcon
                                          onClick={() => {
                                            if (checkPermissionRecallOrder) {
                                              setValueRecallOrderFeed(g);
                                              setLoading(true);
                                            } else {
                                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                            }
                                          }}
                                          style={{
                                            fontSize: "2rem",
                                            transform: "rotate(45deg)",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </>
                                    </div>
                                  );
                                default:
                                  return;
                              }
                            })()}
                          </div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="order-quan">{t("order:REFERENCE_QUANTITY")}</div>
                          <div className="expect-quan">{t("order:CONFIRMED_QUANTITY")}</div>
                          <div className="actual-quan">{t("order:RECEIVED_QUANTITY")}</div>
                          <div className="unit">{t("order:WEIGHT")}</div>
                          <div className="total">{t("TOTAL_PRICE")}</div>
                          <div className="chip">{StatusUIUtil.renderBgStatus(g.status)}</div>
                        </div>
                        {g.orderlines.map((o: any) => {
                          return (
                            <div
                              className="item"
                              key={o.id}
                              style={{
                                borderLeft: `3px solid ${
                                  o.item?.color ? o.item?.color : "transparent"
                                }`,
                              }}>
                              <div className="name">
                                <img
                                  src={
                                    checkLinkImage(o.item.images?.picture) ||
                                    renderImageProNull(order?.orderType)
                                  }
                                  alt={o.item.shortName}
                                  onError={renderErrorImageItem(order?.orderType)}
                                />
                                <div>
                                  <span style={{ color: o.item?.color }}>{o.item.shortName}</span>
                                  <div className="code">{o.item.name}</div>
                                  <div
                                    style={{
                                      fontSize: "1.4rem",
                                      color:
                                        o?.note !== "" && o?.note !== null && o?.note !== undefined
                                          ? "#41AD49"
                                          : "#5A5959",
                                    }}>
                                    <b style={{ color: "#5A5959" }}>{t("NOTES")}:</b>{" "}
                                    {o?.note || TEXT_HOLDER}
                                  </div>
                                </div>
                              </div>
                              <div className="sub-status"></div>
                              <div className="price">
                                {checkPermissionViewPrice ? currencyFormat(o.price) : TEXT_ASTERISK}
                                &ensp;đ/
                                <span style={{ color: "#41ad49", fontWeight: "bold" }}>
                                  {o.saleUnit}
                                </span>
                              </div>
                              <div className="order-quan">
                                {o.orderQuantity ? parseFloat(o.orderQuantity) : TEXT_HOLDER}
                              </div>

                              <div className="expect-quan">
                                {o.expectQuantity ? parseFloat(o.expectQuantity) : TEXT_HOLDER}
                              </div>
                              <div className="actual-quan">
                                {o.actualQuantity ? parseFloat(o.actualQuantity) : TEXT_HOLDER}
                              </div>
                              <div className="unit">
                                {currencyFormat(+o.dimension)} {o.item.baseUoM}
                              </div>
                              <div className="total">
                                {checkPermissionViewPrice
                                  ? currencyFormat(o.subTotal)
                                  : TEXT_ASTERISK}
                                &ensp;đ
                              </div>
                              <div className="chip">
                                {o.expectQuantity !== null &&
                                  g.status === OrderStatus.CONFIRMED &&
                                  g.status === OrderStatus.PROCESSING &&
                                  (() => {
                                    if (
                                      +o.expectQuantity > +o.orderQuantity &&
                                      +o.orderQuantity !== 0
                                    ) {
                                      return <img src={Increase} alt="Increase" />;
                                    } else if (
                                      +o.expectQuantity < +o.orderQuantity &&
                                      +o.expectQuantity !== 0
                                    ) {
                                      return <img src={Decrease} alt="Decrease" />;
                                    } else if (+o.expectQuantity !== 0 && +o.orderQuantity === 0) {
                                      return <img src={Create} alt="Create" />;
                                    } else if (+o.expectQuantity === 0 && +o.orderQuantity !== 0) {
                                      return <img src={Cancel} alt="Cancel" />;
                                    } else return <></>;
                                  })()}
                                {o.actualQuantity !== null &&
                                  g.status === OrderStatus.COMPLETED &&
                                  (() => {
                                    if (
                                      +o.actualQuantity > +o.orderQuantity &&
                                      +o.orderQuantity !== 0
                                    ) {
                                      return <img src={Increase} alt="Increase" />;
                                    } else if (
                                      +o.actualQuantity < +o.orderQuantity &&
                                      +o.actualQuantity !== 0
                                    ) {
                                      return <img src={Decrease} alt="Decrease" />;
                                    } else if (+o.actualQuantity !== 0 && +o.orderQuantity === 0) {
                                      return <img src={Create} alt="Create" />;
                                    } else if (+o.actualQuantity === 0 && +o.orderQuantity !== 0) {
                                      return <img src={Cancel} alt="Cancel" />;
                                    } else return <></>;
                                  })()}
                              </div>
                            </div>
                          );
                        })}
                        <Divider />
                        <Grid container className="ship" spacing={1}>
                          <Grid item xs={2}>
                            <div className="label">{t("order:REFERENCE")}</div>
                            {(!!g.orderGFId ? g.orderGFId : "") +
                              (!!g.orderGFId && !!g.doNumber ? " / " : "") +
                              (!!g.doNumber ? g.doNumber : "") || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("order:DELIVERY_DATE")}</div>
                            {g.requiredDate}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("VEHICLE_NUMBER")}</div>
                            {g.vehicleNumber || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("order:DELIVERY_ADDRESS")}</div>
                            <ThemeProvider theme={theme}>
                              <Tooltip arrow title={<span>{g.address || TEXT_HOLDER}</span>}>
                                <span>{g.address || TEXT_HOLDER}</span>
                              </Tooltip>
                            </ThemeProvider>
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("order:RECEIVING_ADDRESS")}</div>
                            {g.salesOffice?.description || TEXT_HOLDER}
                          </Grid>
                          <Grid item xs={2}>
                            <div className="label">{t("NOTES")}</div>
                            <div className="order-note">
                              <ThemeProvider theme={theme}>
                                <Tooltip arrow title={<span>{g.note || TEXT_HOLDER}</span>}>
                                  <span
                                    style={{
                                      color:
                                        g.note !== "" && g.note !== null && g.note !== undefined
                                          ? "#0A6836"
                                          : "#5A5959",
                                    }}>
                                    {g.note || TEXT_HOLDER}
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </div>
              </div>
              {Object.keys(order.feedback).length !== 0 && (
                <FeedBack
                  data={order.feedback}
                  onSuccess={() => setFeedBackSuccess(!feedBackSuccess)}
                />
              )}
              <div className="footer">
                <div className="total-gr">
                  <div style={{ width: "40rem" }}>
                    {order.orders.some((f: any) => f.invoice !== null) &&
                      order.orders.some((f: any) => f.invoice?.invoiceKey !== "") && (
                        <>
                          <div className="title">{t("order:INVOICE_LIST")}</div>
                          {order.orders
                            .filter((f: any) => f.invoice && f.invoice?.invoiceKey !== "")
                            .map((i: any, idx: number) => {
                              return (
                                <div style={{ display: "flex" }} key={idx}>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={i.invoice?.invoiceUrl}
                                    style={{ color: "#41AD49", cursor: "pointer" }}>
                                    #{i.invoice?.arNumber}
                                    <br />
                                    <br />
                                  </a>
                                  &ensp;
                                  <div>
                                    &#8226;&ensp;{t("LOOKUP_CODE")}&ensp;
                                    <span style={{ fontWeight: "bold" }}>
                                      {i.invoice?.invoiceKey}
                                    </span>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              );
                            })}
                        </>
                      )}
                  </div>
                  <Grid
                    container
                    className="summary"
                    style={{ width: order?.orders?.length > 1 ? "55vw" : "30vw" }}>
                    <Grid item xs={gridSize}>
                      <div style={{ color: "transparent" }}>{t("order:ESTIMATED")}</div>
                      <div>{t("TOTAL_WEIGHT")}</div>
                      <div>{t("order:COMMERCIAL_DISCOUNT")}</div>
                      <div>{t("order:WITHHOLDING_TAX")}</div>
                      <div>{t("WITHHOLDING_PIP")}</div>
                      <div>{t("VAT")}</div>
                      <div className="text-bold">{t("TOTAL")}</div>
                    </Grid>
                    {order?.orders?.length > 1 &&
                      order?.orders.map((o: any, idx: number) => {
                        return (
                          <Grid item xs={gridSize} className="right" key={idx}>
                            <div className="right-label">{o.division.description}</div>
                            <div>
                              {currencyFormat(
                                o.orderlines.reduce((sum: any, item: any) => {
                                  return (sum += +item.dimension);
                                }, 0)
                              )}{" "}
                              kg
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.preDiscount))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.commerceDiscount))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.personalVat))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div>
                              {checkPermissionViewPrice
                                ? currencyFormat(parseInt(o.vat))
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                            <div className="text-bold" style={{ color: "#0A6836" }}>
                              {checkPermissionViewPrice
                                ? currencyFormat(+o.totalPay)
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                          </Grid>
                        );
                      })}
                    <Grid item xs={gridSize} className="right">
                      {order?.orders?.length > 1 ? (
                        <div className="right-label">{t("order:MASTER_ORDER")}</div>
                      ) : (
                        <div style={{ color: "transparent" }}>{t("order:MASTER_ORDER")}</div>
                      )}
                      <div>
                        {currencyFormat(
                          order.orders.reduce((sum: any, item: any) => {
                            const a = item.orderlines.reduce((sum: any, line: any) => {
                              sum += +line.dimension;
                              return sum;
                            }, 0);
                            return (sum += a);
                          }, 0)
                        )}{" "}
                        kg
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.preDiscount))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.commerceDiscount))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.personalVat))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div>
                        {checkPermissionViewPrice
                          ? currencyFormat(parseInt(order.totalVat))
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                      <div className="text-bold" style={{ color: "#0A6836" }}>
                        {checkPermissionViewPrice
                          ? currencyFormat(+order.totalAmount)
                          : TEXT_ASTERISK}{" "}
                        đ
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <Accordion className="update-history" disabled>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.2rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={3}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={3}>
                        {t("order:ACTION")}
                      </Grid>
                      <Grid item xs={2}>
                        {t("order:IMPLEMENT")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("DETAIL")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={3}>
                            {u.time}
                          </Grid>
                          <Grid item xs={3}>
                            {u.update}
                          </Grid>
                          <Grid item xs={2}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </div>
            </>
          ) : (
            <Loading style={{ margin: "2rem 0", height: "80vh" }} />
          )}
        </div>
      </div>

      <Dialog maxWidth="md" open={showCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("order:CANCEL_ORDER")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setShowCancel(false);
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(r.label)}
                  className="text"
                />
              );
            })}
            <div className="label">{t("REASON")}</div>
            <TextField
              fullWidth
              placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
              className="form-textarea-field"
              multiline
              rows={3}
              variant="outlined"
              color="success"
              inputProps={{ className: "input" }}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel"
              onClick={() => {
                setShowCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                setShowCancel(false);
                setSubmitCancel(true);
              }}>
              {t("order:CANCEL_ORDER")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={submitCancel}
        title={t("order:CANCEL_ORDER")}
        subTitle={
          <span>
            {t("order:CANNOT_RECOVER_CANCELED_ORDER")}
            <br />
            {t("order:ARE_YOU_SURE_YOU_WANT_CANCEL_ORDER_BY_NAME", { NAME: `#${order?.code}` })}
          </span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setSubmitCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                cancelOrderFeed();
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setSubmitCancel(false);
        }}
      />

      <Notification
        visible={notiERP.visible}
        title={notiERP.status ? t("order:ERP_UPDATE_SUCCESSFUL") : t("order:ERP_UPDATE_FAILED")}
        subTitle={
          notiERP.status ? (
            <span>
              {t("ORDER")}
              <span style={{ color: "#41ad49" }}> #{order?.code}</span>{" "}
              {t("order:YOUR_ERP_UPDATE_SUCCESSFUL")}
            </span>
          ) : (
            <span>
              {t("ORDER")}
              <span style={{ color: "#41ad49" }}> #{order?.code}</span>{" "}
              {t("order:YOUR_ERP_UPDATE_FAILED")}
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiERP.status ? "green" : "red"}`}>
            {notiERP.status ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        actions={
          !notiERP.status && (
            <>
              <button
                ref={submitRef}
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  recallOrderFeed();
                  setOpenSaleOrg(false);
                  setLoading(true);
                  setNotiERP({ visible: false, status: false });
                }}>
                {t("order:RETRY_SYNC")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  setValueRecallOrderFeed(null);
                  setValueSaleOrg("");
                  setOpenSaleOrg(false);
                  setNotiERP({ visible: false, status: false });
                }}>
                {t("EXIT")}
              </button>
            </>
          )
        }
        onClose={() => {
          setValueRecallOrderFeed(null);
          if (notiERP.status) {
            setTimeout(() => {
              fetchData();
              setNotiERP({ visible: false, status: true });
              setOpenSaleOrg(false);
            }, 1000);
          } else {
            setNotiERP({ visible: false, status: false });
          }
        }}
      />

      {loading && (
        <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
          <Loading />
        </Backdrop>
      )}

      {permission && <NotAccess />}

      <Dialog
        maxWidth="lg"
        open={openBill}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            // width: "427px",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        onClose={() => {
          setOpenBill(false);
        }}>
        <Bill billData={order} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSaleOrg}
        onClose={() => {
          setOpenSaleOrg(false);
        }}>
        <div className="dialog-recall-order-detail">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "3rem",
            }}>
            <div className="title">{t("order:ORDER_SYNC")}</div>
            <ClearIcon
              style={{ fontSize: "2.2rem", cursor: "pointer" }}
              onClick={() => {
                setValueSaleOrg("");
                setValueRecallOrderFeed(null);
                setOpenSaleOrg(false);
              }}
            />
          </div>
          <div className="label">{t("BUSINESS_ORGANIZATION")}</div>
          <Select
            value={valueSaleOrg}
            onChange={(e) => {
              setValueSaleOrg(e.target.value as string);
            }}
            fullWidth
            input={<OutlinedInput className="form-text-field" color="success" />}
            variant="outlined"
            className="form-select-field"
            renderValue={
              valueSaleOrg !== ""
                ? undefined
                : () => (
                    <span style={{ color: "#D6D5D6" }}>
                      {t("SELECT_BY_NAME", { NAME: t("BUSINESS_ORGANIZATION") })}
                    </span>
                  )
            }
            displayEmpty
            color="success"
            MenuProps={{
              className: "select-menu-list",
            }}>
            {salesOrgForRecall.length > 0 &&
              salesOrgForRecall.map((so, index: number) => {
                return (
                  <MenuItem key={index} value={so.salesOrg.id}>
                    {so.salesOrg.description}
                  </MenuItem>
                );
              })}
          </Select>
          <div className="des">
            {t("order:SYNCHRONIZED_ORDERS_CANNOT_CHANGE_BUSINESS_ORGANIZATION")}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setValueSaleOrg("");
                setValueRecallOrderFeed(null);
                setOpenSaleOrg(false);
              }}>
              {t("CLOSE")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              onClick={() => {
                recallOrderFeed(valueSaleOrg);
              }}>
              {t("order:SYNC")}
            </button>
          </div>
        </div>
      </Dialog>
    </Dialog>
  );
};

export default OrderModal;
