import { IconBase, IconComponent } from "@Core/Icons";

export const AccountAuthorSmallIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M7 6.46875C8.26853 6.46875 9.29688 5.4404 9.29688 4.17188C9.29688 2.90335 8.26853 1.875 7 1.875C5.73147 1.875 4.70312 2.90335 4.70312 4.17188C4.70312 5.4404 5.73147 6.46875 7 6.46875Z"
        stroke="#312F30"
        strokeWidth="2"
      />
      <path
        d="M7 8.76562C6.27964 8.76562 5.56634 8.90425 4.90081 9.17358C4.23529 9.44292 3.63058 9.83769 3.12121 10.3353C2.5216 10.9212 2.07514 11.6287 1.80701 12.3984C1.6613 12.8168 1.58844 13.0259 1.68336 13.3489C1.74081 13.5444 1.94057 13.817 2.1115 13.9331C2.39389 14.125 2.70052 14.125 3.31379 14.125H10.6862C11.2995 14.125 11.6061 14.125 11.8885 13.9331C12.0594 13.817 12.2592 13.5444 12.3166 13.3489C12.4116 13.0259 12.3387 12.8168 12.193 12.3984C11.9249 11.6287 11.4784 10.9212 10.8788 10.3353C9.85007 9.33027 8.45483 8.76562 7 8.76562Z"
        stroke="#312F30"
        strokeWidth="2"
      />
    </IconBase>
  );
};
