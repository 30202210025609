import ImageUpload from "@Components/ImageUpload";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { ActorForNews, APP_ROUTES, BASE_LINK_IMAGE } from "@Constants";
import {
  DivisionModal,
  FormUploadImageModel,
  NewsDetailOutputModel,
  NewsInputModel,
  SalesOrgModal,
  SourceType,
  UploadedImageModel,
} from "@Models";
import { NewService, ProductService, SalesOrgService } from "@Services";
import { FormDataUtil, FormUtil } from "@Utils";
import {
  Checkbox,
  Dialog,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./NewsModal.scss";

const UpdateNews: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const submitRef = useRef<any | null>(null);
  const { newsId }: { newsId: string } = useParams();

  const mounted = useRef(false);
  const [valid, setValid] = useState<boolean>(false);
  const [dataNewsDetail, setDataNewsDetail] = useState<NewsDetailOutputModel | null>(null);

  const [sourceId, setSourceId] = useState<string[]>([]);
  const [dataSalesOrg, setDataSalesOrg] = useState<SalesOrgModal[]>([]);
  const [salesOrgId, setSalesOrgId] = useState<number[]>([]);

  const [newsDraft, setNewsDraft] = useState<boolean>(false);

  const [dataDivision, setDataDivision] = useState<DivisionModal[]>([]);
  const [divisionId, setDivisionId] = useState<number[]>([]);

  const [objectiveId, setObjectiveId] = useState<string[]>([]);
  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);

  const dataSourceCheck = [...(sourceId || [])];
  const dataSaleOrgCheck = [...(salesOrgId || [])];
  const dataDivisionCheck = [...(divisionId || [])];
  const dataObjectiveCheck = [...(objectiveId || [])];

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (newsId) {
      NewsById();
    }

    //eslint-disable-next-line
  }, [newsId]);

  const NewsById: () => Promise<void> = async () => {
    try {
      const response = await NewService.getNewsById(newsId);
      if (mounted.current) {
        const resData: NewsDetailOutputModel = response.data.data;
        setDataNewsDetail(() => resData);
        setSourceId(resData.sources);
        setSalesOrgId(resData.salesOrgIds);
        setDivisionId(resData.divisionIds);
        setObjectiveId(resData.actors);
        onFetchApiSaleOrg(resData.sources);
        onFetchApiDivision(resData.sources);
        setAvatars([
          {
            id: uuidv4(),
            src: BASE_LINK_IMAGE + resData.image,
            alt: "",
            file: null,
          },
        ]);
      }
    } catch (err: any) {
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  const onFetchApiSaleOrg: (source: any[]) => Promise<void> = async (source: any[]) => {
    const data = source.join(",");
    try {
      const response = await SalesOrgService.getAllSalesOrg({ sources: data });
      if (mounted.current) {
        setDataSalesOrg(() => response.data.data);
      }
    } catch (error) {}
  };

  const onFetchApiDivision: (source: any[]) => Promise<void> = async (source: any[]) => {
    const data = source.join(",");
    try {
      const response = await ProductService.getDivisionBySource({ getAll: true, sources: data });
      if (mounted.current) {
        setDataDivision(() => response.data.data);
      }
    } catch (error) {}
  };

  const submitForm = async (values: any) => {
    const { title, body, url } = values;

    if (!sourceId.length) {
      enqueueSnackbar(t("NO_ACCESS_ARRAY_SELECTED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!divisionId.length) {
      enqueueSnackbar(t("NO_INDUSTRY_SELECTED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!salesOrgId.length) {
      enqueueSnackbar(t("NO_BUSINESS_ORG_SELECTED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!objectiveId.length) {
      enqueueSnackbar(t("NO_OBJECTIVE_SELECTED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!title) {
      enqueueSnackbar(t("NO_TITLE_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    if (!body) {
      enqueueSnackbar(t("NO_CONTENT_ENTERED"), {
        variant: "error",
      });
      setValid(true);
      return;
    }
    // if (!avatars.length) {
    //   enqueueSnackbar(t("NO_IMAGE_SELECTED"), {
    //     variant: "error",
    //   });
    //   setValid(true);
    //   return;
    // }
    // if (file.size > 3 * 1024 * 1024) {
    //   message.error(this.props.t("UPLOAD_ERROR_SIZE", { SIZE: 3 }));
    //   continue;
    // }
    const data: NewsInputModel = {
      divisionIds: divisionId,
      salesOrgIds: salesOrgId,
      sources: sourceId,
      actors: objectiveId,
      title,
      body,
      isDraft: newsDraft ? false : !!dataNewsDetail?.isDraft,
      isActive: newsDraft
        ? true
        : !dataNewsDetail?.isDraft && !dataNewsDetail?.isActive
        ? false
        : !dataNewsDetail?.isDraft,
      postedDate: moment(new Date()).toISOString(),
      oldImage: !!avatars.length && !!avatars[0]?.file ? null : dataNewsDetail?.image,
      url,
    };

    const formData = FormDataUtil.convertJsonToFormData(data);

    formData.append("image", avatars[0].file);

    NewService.updateNews(newsId, formData)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("UPDATE_NEWS_SUCCESSFUL"), {
            variant: "success",
          });
          history.push(APP_ROUTES.NEWS_DETAIL.replace(":newsId", newsId));
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("UPDATE_NEWS_FAILED"), {
          variant: "error",
        });
        setValid(false);
      });
  };

  const bindingDataForm = (data: NewsDetailOutputModel) => {
    return {
      ...data,
    };
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="news-modal">
        <SimpleModalHeader
          title={t("EDIT_NEWS")}
          onClose={() => {
            history.push(APP_ROUTES.NEWS_DETAIL.replace(":newsId", newsId.toString()));
          }}
        />
        <div className="content">
          <Form
            onSubmit={async (values) => submitForm(values)}
            initialValues={!!dataNewsDetail ? bindingDataForm(dataNewsDetail) : null}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="account" style={{ width: "50%" }}>
                  <Grid container spacing={2} className="body">
                    <Grid item xs={6}>
                      <div className="form-item select-multiple">
                        <div className="form-label">{t("ACCESS_ARRAY")}</div>
                        <FormControl fullWidth error={valid && !sourceId.length}>
                          <Select
                            value={sourceId}
                            multiple
                            fullWidth
                            variant="outlined"
                            className="form-selected"
                            renderValue={() => {
                              return !!sourceId.length ? (
                                <span className="input-multi">
                                  <span className="input-multi-item">
                                    {t(sourceId[0].toUpperCase())}
                                  </span>
                                  {sourceId.length > 1 && (
                                    <span className="input-multi-item">
                                      + {sourceId.length - 1} ...
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#9e9e9e",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <span className="form-selected-placeholder">
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("ACCESS_ARRAY").toLowerCase(),
                                    })}
                                  </span>
                                </span>
                              );
                            }}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {Object.keys(SourceType)
                              .filter(
                                (i) => SourceType[i as keyof typeof SourceType] !== SourceType.CCU
                              )
                              .map((item, index: number) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={SourceType[item as keyof typeof SourceType]}
                                    className="checked-item">
                                    <ListItemText
                                      primary={t(
                                        SourceType[item as keyof typeof SourceType].toUpperCase()
                                      )}
                                    />
                                    <Checkbox
                                      checked={sourceId.some(
                                        (some: any) =>
                                          some === SourceType[item as keyof typeof SourceType]
                                      )}
                                      onChange={(_, value) => {
                                        if (value === true) {
                                          dataSourceCheck.push(
                                            SourceType[item as keyof typeof SourceType]
                                          );
                                          setSourceId(dataSourceCheck);
                                          onFetchApiSaleOrg(dataSourceCheck);
                                          onFetchApiDivision(dataSourceCheck);
                                        } else {
                                          setSourceId(
                                            dataSourceCheck.filter((value) => {
                                              return (
                                                value !==
                                                SourceType[item as keyof typeof SourceType]
                                              );
                                            })
                                          );
                                          onFetchApiSaleOrg(dataSourceCheck);
                                          onFetchApiDivision(dataSourceCheck);
                                        }
                                      }}
                                      color="success"
                                      style={{ transform: "scale(1.5)" }}
                                    />
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="form-item select-multiple">
                        <div className="form-label asterisk">{t("order:BUSINESS_UNIT")}</div>
                        <FormControl fullWidth error={valid && !salesOrgId.length}>
                          <Select
                            value={salesOrgId}
                            multiple
                            fullWidth
                            disabled={!sourceId.length}
                            variant="outlined"
                            className="form-selected"
                            renderValue={() => {
                              const name =
                                (!!dataSalesOrg?.length &&
                                  dataSalesOrg.find((i) => i.id === salesOrgId[0])?.description) ||
                                "";
                              return !!salesOrgId.length ? (
                                <span className="input-multi">
                                  <span className="input-multi-item">{t(name)}</span>
                                  {salesOrgId.length > 1 && (
                                    <span className="input-multi-item">
                                      + {salesOrgId.length - 1} ...
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#9e9e9e",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <span className="form-selected-placeholder">
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("order:BUSINESS_UNIT").toLowerCase(),
                                    })}
                                  </span>
                                </span>
                              );
                            }}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {dataSalesOrg.map((item, index: number) => {
                              return (
                                <MenuItem key={index} value={item.id} className="checked-item">
                                  <ListItemText primary={item.description} />
                                  <Checkbox
                                    checked={salesOrgId.some((some: any) => some === item.id)}
                                    onChange={(_, value) => {
                                      if (value === true) {
                                        dataSaleOrgCheck.push(item.id);
                                        setSalesOrgId(dataSaleOrgCheck);
                                      } else {
                                        setSalesOrgId(
                                          dataSaleOrgCheck.filter((value) => {
                                            return value !== item.id;
                                          })
                                        );
                                      }
                                    }}
                                    color="success"
                                    style={{ transform: "scale(1.5)" }}
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="form-item select-multiple">
                        <div className="form-label asterisk">{t("customer:INDUSTRY")}</div>
                        <FormControl fullWidth error={valid && !divisionId.length}>
                          <Select
                            value={divisionId}
                            multiple
                            fullWidth
                            disabled={!sourceId.length}
                            variant="outlined"
                            className="form-selected"
                            renderValue={() => {
                              const name =
                                (!!dataDivision.length &&
                                  dataDivision?.find((i) => i.id === divisionId[0])?.description) ||
                                "";
                              return !!divisionId.length ? (
                                <span className="input-multi">
                                  <span className="input-multi-item">{t(name)}</span>
                                  {divisionId.length > 1 && (
                                    <span className="input-multi-item">
                                      + {divisionId.length - 1} ...
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#9e9e9e",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <span className="form-selected-placeholder">
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("customer:INDUSTRY").toLowerCase(),
                                    })}
                                  </span>
                                </span>
                              );
                            }}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {dataDivision.map((item, index: number) => {
                              return (
                                <MenuItem key={index} value={item.id} className="checked-item">
                                  <ListItemText primary={item.description} />
                                  <Checkbox
                                    checked={divisionId.some((some: any) => some === item.id)}
                                    onChange={(_, value) => {
                                      if (value === true) {
                                        dataDivisionCheck.push(item.id);
                                        setDivisionId(dataDivisionCheck);
                                      } else {
                                        setDivisionId(
                                          dataDivisionCheck.filter((value) => {
                                            return value !== item.id;
                                          })
                                        );
                                      }
                                    }}
                                    color="success"
                                    style={{ transform: "scale(1.5)" }}
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="form-item select-multiple">
                        <div className="form-label asterisk">{t("order:OBJECTIVE")}</div>
                        <FormControl fullWidth error={valid && !objectiveId.length}>
                          <Select
                            value={objectiveId}
                            multiple
                            fullWidth
                            variant="outlined"
                            className="form-selected"
                            renderValue={() => {
                              // return !!objectiveId.length ? (
                              //   <span className="input-multi">
                              //     {objectiveId.map((val, idx) => {
                              //       return (
                              //         <span key={idx} className="input-multi-item">
                              //           {t(val).toUpperCase()}
                              //         </span>
                              //       );
                              //     })}
                              //   </span>
                              const name =
                                (!!ActorForNews.length &&
                                  ActorForNews?.find((i) => i.value === objectiveId[0])?.label) ||
                                "";
                              return !!objectiveId.length ? (
                                <span className="input-multi">
                                  <span className="input-multi-item">{t(name)}</span>
                                  {objectiveId.length > 1 && (
                                    <span className="input-multi-item">
                                      + {objectiveId.length - 1} ...
                                    </span>
                                  )}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "#9e9e9e",
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <span className="form-selected-placeholder">
                                    {t("SELECT_BY_NAME", {
                                      NAME: t("order:OBJECTIVE").toLowerCase(),
                                    })}
                                  </span>
                                </span>
                              );
                            }}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {ActorForNews.map((item, index: number) => {
                              return (
                                <MenuItem key={index} value={item.value} className="checked-item">
                                  <ListItemText primary={t(item.label)} />
                                  <Checkbox
                                    checked={objectiveId.some((some: any) => some === item.value)}
                                    onChange={(_, value) => {
                                      if (value === true) {
                                        dataObjectiveCheck.push(item.value);
                                        setObjectiveId(dataObjectiveCheck);
                                      } else {
                                        setObjectiveId(
                                          dataObjectiveCheck.filter((value) => {
                                            return value !== item.value;
                                          })
                                        );
                                      }
                                    }}
                                    color="success"
                                    style={{ transform: "scale(1.5)" }}
                                  />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container className="body">
                    <Grid item xs={12}>
                      <div className="form-item">
                        <div className="form-label asterisk">{t("TITLE")}</div>
                        <Field
                          name="title"
                          validate={FormUtil.Rule.required(
                            t("INPUT_BY_NAME", { NAME: t("TITLE").toLowerCase() })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("TITLE").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={
                                  (valid && !input.value) || (Boolean(meta.error) && meta.touched)
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="form-label asterisk">{t("CONTENT")}</div>
                        <Field
                          name="body"
                          validate={FormUtil.Rule.required(
                            t("INPUT_BY_NAME", { NAME: t("CONTENT").toLowerCase() })
                          )}>
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("CONTENT").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={
                                  (valid && !input.value) || (Boolean(meta.error) && meta.touched)
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>

                      <div className="form-item">
                        <div className="form-label asterisk">{t("Link bài viết")}</div>
                        <Field name="url">
                          {({ input, meta, ...rest }) => {
                            return (
                              <TextField
                                {...input}
                                {...rest}
                                placeholder={t("INPUT_BY_NAME", {
                                  NAME: t("link bài viết").toLowerCase(),
                                })}
                                color="success"
                                className="form-text-field"
                                inputProps={{ className: "input" }}
                                helperText={meta.touched ? meta.error : ""}
                                error={Boolean(meta.error) && meta.touched}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <ImageUpload
                        className="product-form-avatar"
                        fileList={avatars}
                        max={1}
                        onChange={(data: UploadedImageModel) => {
                          setAvatars([
                            {
                              id: uuidv4(),
                              src: data.fileStr,
                              alt: data.alt || "",
                              file: data.file,
                            },
                          ]);
                        }}
                        onDelete={() => {
                          setAvatars([]);
                        }}
                        config={{ uploadModal: true }}
                        limitSize={true}
                      />
                    </Grid>
                  </Grid>

                  <div className="footer">
                    <div
                      // className={(ordDetail && orderDetail.length) || selectedVars.length ? "show" : "hide"}
                      className="show">
                      <div
                        className="btn-cancel-style btn__text-cancel"
                        onClick={() => {
                          history.push(
                            APP_ROUTES.NEWS_DETAIL.replace(":newsId", newsId.toString())
                          );
                        }}>
                        {t("CANCEL")}
                      </div>

                      {dataNewsDetail?.isDraft && (
                        <button
                          // disabled={!orderDetail?.length && !selectedVars?.length}
                          onClick={() => {
                            setNewsDraft(true);
                          }}
                          type="submit"
                          ref={submitRef}
                          className="btn-confirm-style btn-border btn--transparent"
                          style={{ marginLeft: "4rem" }}>
                          {t("SAVE_PUBLISH")}
                        </button>
                      )}
                      <button
                        // disabled={!orderDetail?.length && !selectedVars?.length}
                        onClick={() => {}}
                        type="submit"
                        ref={submitRef}
                        className="btn-confirm-style"
                        style={{ marginLeft: "4rem" }}>
                        {t("SAVE")}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateNews;
