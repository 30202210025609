import {
  GFAutoComplete,
  GFButton,
  GFDatePicker,
  GFFormItem,
  GFTextField,
} from "@Components/ui";
import { APP_ROUTES, CUSTOMER_MANAGE_DEFAULT_VALUE, CUSTOMER_MANAGE_REQUEST_TYPE, CUSTOMER_MANAGE_STATUS, ItemGroupTypeEnum } from "@Constants";

import { zodResolver } from "@hookform/resolvers/zod";
import { CreateCustomerManageModel, ICustomer, IDistrict, IEmployee, IProvince } from "@Models/customer-manage.model";
import { Box, CircularProgress, Dialog, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CustomerManagePayload,
  customerManageSchema,
} from "src/schemas/customer-manage.schema";
import { ModalDefaultProps, ModalType, SelectOption } from "src/types";
import _UniqBy from "lodash/uniqBy";
import _Filter from "lodash/filter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import "./index.scss";
import { CustomerManageService } from "@Services/customer-manage.service";
import { useSnackbar } from "notistack";
import { getErrorResponse, toClientDate } from "@Utils";
import { useHistory, useParams } from "react-router-dom";
import GFSelectVirtualized from "@Components/ui/GFSelectVirtualized";
import Loading from "@Components/Loading";
import DatePicker from "@Components/DatePicker";

type Props = {
  type: string;
  isUpdate: boolean;
};

const displayItemGroupTypes = [ItemGroupTypeEnum.BREEDING_PIG, ItemGroupTypeEnum.PORK_PIG]

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode;
  value: number;
  index: number;
}) => {
  return (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

 
const CreateOrUpdateCustomerManage: React.FC<Props>  = ({ type, isUpdate }) => {
  const customerId = useParams<{ customerId: string }>().customerId;
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isValid, isDirty, errors },
  } = useForm<CustomerManagePayload>({
    resolver: zodResolver(customerManageSchema),
    mode: "onChange",
  });  

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("translation");
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any>({})
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [defaultSaleOrgId, setDefaultSaleOrgId] = useState<any>(null)
  const [disabledUnit, setDisableUinit] = useState<any>({
    breedingPig: true,
    porkPig: true
  })
  const [customers, setCustomers] = useState<ICustomer[]>([])
  const [employees, setEmployees] = useState<IEmployee[]>([])

  const provinces : IProvince[] = masterData.region?.map((item : any) => {
    return {
        value: item.code,
        label: item.description,
        code: item.code
    }
  })

  const districts : IDistrict[] = masterData.structureGroup?.map((item : any) => {
    return {
        value: item.code,
        label: item.description,
        provinceCode: item.code.slice(0, 2)
    }
  })

  const selectedProvince = watch('province')
  let matchedDistricts = useMemo(() => {
    return _UniqBy(
      _Filter(districts, (x) => x.provinceCode === selectedProvince),
      "value"
    )
  }, [selectedProvince]);

  const salesOrgs: SelectOption[] = masterData.salesOrg?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })

  const salesOffices: SelectOption[] = masterData.salesOffices?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })

  const itemGroupTypes: SelectOption[] = masterData.itemGroupType?.filter((item: any) => displayItemGroupTypes.includes(item.code)).map((item : any) => {
    return {
        value: item.id,
        label: item.name,
    }
  })

  const distributionChannels: SelectOption[] = masterData.distributionChannel?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })
  
  const defaultDistributeionChannelId = masterData.distributionChannel?.find((item : any) => item.code === CUSTOMER_MANAGE_DEFAULT_VALUE.DISTRBUTION_CHANNEL)?.id

  const divisions: SelectOption[] = masterData.division?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })

  const defaultdivisionId = masterData.division?.find((item : any) => item.code === CUSTOMER_MANAGE_DEFAULT_VALUE.DEVISION)?.id

  const saleDistricts: SelectOption[] = masterData.saleDistrict?.map((item : any) => {
    return {
        value: item.id,
        label: item.name,
    }
  })
  
  const salesGroups: SelectOption[] = masterData.salesGroup?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })
  
  const customerGroups: SelectOption[] = masterData.customerGroup?.map((item : any) => {
    return {
        value: item.id,
        label: item.description,
    }
  })

  useEffect(() => {
    if (customerId) {
      getCustomerId(Number(customerId))
    }
  },[customerId])

  useEffect( () => {
    const fetchData = async () => {
      try {
        const response = await CustomerManageService.getMasterData();
        setMasterData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchCustomer = async () => {
      try {
        const response = await CustomerManageService.getDataSourceCustomers();
        if (response && response.data && response.data.data) {
          const customers: ICustomer[] = response.data.data.map((item: any) => {
            return {
              value: item.code,
              label: item.fullName,
              id: item.id
            }
          })

          setCustomers(customers)
        }
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    }

    const fetchEmployees = async () => {
      try {
        const response = await CustomerManageService.getEmployees();
        if (response && response.data && response.data.data) {
          const employees: IEmployee[] = response.data.data.map((item: any) => {
            return {
              value: item.id,
              label: item.fullName,
              id: item.id
            }
          })

          setEmployees(employees)
        }
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    }
  
    fetchCustomer()
    fetchData();
    fetchEmployees()
  }, [])

  const getCustomerId = async (customerId: number) => {
    try {
      setLoadingPage(true)
      const response = await CustomerManageService.getCustomerRequestById(customerId)
      matchedDistricts = _UniqBy(
        _Filter(districts, (x) => x.provinceCode === response.data?.province?.code),
        "value"
      )
      reset({
        customerCode: response.data.customerCode ?? '123123',
        fullName: response.data.fullName,
        dateOfBirth: response.data.dateOfBirth,
        birthPlace:  response.data.birthPlace,
        idCard: response.data.idCard,
        idIssueDate: response.data.idIssueDate,
        idIssuePlace: response.data.idIssuePlace,
        taxId: response.data.taxId,
        shippingAddress: response.data.shippingAddress,
        bankKey: response.data.bankKey,
        bankAccNum: response.data.bankAccNum,
        bankAccName: response.data.bankAccName,
        businessLiceneseNum: response.data.businessLiceneseNum,
        nameOfLegalRepresentative: response.data.nameOfLegalRepresentative,
        position: response.data.position,
        codeOfFeedCus: response.data.codeOfFeedCus,
        businessLiceneseDate: response.data.businessLiceneseDate,
        phoneNumber: response.data.phoneNumber,
        email: response.data.email,
        address: response.data.address,
        province: response.data.province.code,
        district: response.data.district.code,
        customerGroup: response.data.customerGroup?.id,    
        slsMan: response.data.slsMan?.id,  
        slsSup: response.data.slsSup?.id,  
        slsAst:response.data.slsAst?.id,  
        slsRef: response.data.slsRef?.id,
        division: response.data.division?.id,
        salesOffice: response.data.salesOffice?.id, 
        salesGroup: response.data.salesGroup?.id,
        saleDistrict: response.data.saleDistrict?.id,
        itemGroupType: response.data.itemGroupType?.id,   
        distributionChannel: response.data.distributionChannel?.id,
        saleOrg: response.data.saleOrg?.id,
      })

      if (response.data.itemGroupType?.code === ItemGroupTypeEnum.BREEDING_PIG) {
        setDisableUinit({
          breedingPig: false,
          porkPig: true
        })
      }
      else {
        setDisableUinit({
          breedingPig: true,
          porkPig: false
        })
      }
      // Bypass data
      forcePVKD()
      setLoadingPage(false)
    }
    catch (error) {
      console.error('Error fetching data:', error);
      setLoadingPage(false)
    }
  }

  const onSubmit = async (data: CustomerManagePayload) => {
    await handleSave(data, isDraft)
  };

  useEffect(() => {
    if (!isUpdate) {
      setValue('saleOrg', defaultSaleOrgId)
    }
  }, [defaultSaleOrgId])

  useEffect(() => {
    if (!isUpdate) {
      setValue('distributionChannel', defaultDistributeionChannelId)
    }
  }, [defaultDistributeionChannelId])

  useEffect(() => {
    if (!isUpdate) {
      setValue('division', defaultdivisionId)
    }
  }, [defaultdivisionId])


  const handleSave = async (data: CustomerManagePayload, isDraft?: boolean) => {
    try {
      setLoading(true)
      const payload: CreateCustomerManageModel = {
        type: type,
        status: isDraft ? CUSTOMER_MANAGE_STATUS.DRAFT : CUSTOMER_MANAGE_STATUS.SUBMIT,
        createCustomerParms: {...data, customerCode: ''},
        updateCustomerParams: data
      }
      if (isUpdate){
        await CustomerManageService.updateCreateOrModifyCustomer(Number(customerId), payload)
      }
      else {
        await CustomerManageService.createOrModifyCustomer(payload);
      }
      
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      setLoading(false)
      history.push(APP_ROUTES.CUSTOMER_MANAGE)
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleClose = () => {
    history.push(APP_ROUTES.CUSTOMER_MANAGE);
  };

  const getTitle = () => {
    if (type === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE){
      return isUpdate ? t("customerManage.updateCustomerInformation") : t("customerManage.createCustomerInformation")
    }
    else {
      return t("customerManage.updateCustomerInformation")
    }
  }

  const handleChangeCustomer = async(value: number | null) => {
    if (!value) return
    const response = await CustomerManageService.getCustomerInfoById(value)
    if (response && response.data) {
      setValue('dateOfBirth', response.data.dateOfBirth)
      setValue('birthPlace',  response.data.birthPlace)
      setValue('idCard', response.data.idCard)
      setValue('idIssueDate', response.data.idIssueDate)
      setValue('idIssuePlace', response.data.idIssuePlace)
      setValue('taxId', response.data.taxId)
      setValue('shippingAddress', response.data.shippingAddress)
      setValue('bankKey', response.data.bankKey)
      setValue('bankAccNum', response.data.bankAccNum)
      setValue('bankAccName', response.data.bankAccName)
      setValue('businessLiceneseNum', response.data.businessLiceneseNum)
      setValue('nameOfLegalRepresentative', response.data.nameOfLegalRepresentative)
      setValue('position', response.data.position)
      setValue('codeOfFeedCus', response.data.codeOfFeedCus)
      setValue('businessLiceneseDate', response.data.businessLiceneseDate)
      setValue('phoneNumber', response.data.phoneNumber)
      setValue('email', response.data.email)
      setValue('address', response.data.address)
      setValue('province', response.data.province?.code)
      setValue('district', response.data.district?.code)
      setValue('fullName', response.data.fullName)
      setValue('salesGroup', response.data.salesGroup?.id)
      setValue('saleDistrict', response.data.saleDistrict?.id)
      setValue('itemGroupType', response.data.itemGroupType?.id)   
      setValue('saleOrg', response.data.saleOrg?.id)

      // Bypass data
      forcePVKD()

    }
  }

  const forcePVKD = () => {
    // Force default value for disable tab
    const force_slsMan = employees.length > 0 && employees[0].id
    const force_slsSup = employees.length > 0 && employees[0].id
    const force_slsAst = employees.length > 0 && employees[0].id
    const force_slsRef = employees.length > 0 && employees[0].id
    const force_distributionChannel = distributionChannels?.length > 0 && distributionChannels[0].value
    const force_salesOffice = salesOffices.length > 0 && salesOffices[0].value
    const force_customerGroup = customerGroups.length > 0 && customerGroups[0].value
    const force_division = divisions.length > 0 && divisions[0].value
    const force_salesOrgs = salesOrgs.length > 0 && salesOrgs[0].value
    const force_itemGroupType = itemGroupTypes.length > 0 && itemGroupTypes[0].value
    
    setValue('customerGroup', Number(force_customerGroup))  
    setValue('slsMan', Number(force_slsMan))
    setValue('slsSup', Number(force_slsSup)) 
    setValue('slsAst',Number(force_slsAst))
    setValue('slsRef', Number(force_slsRef))
    setValue('division',  Number(force_division))
    setValue('salesOffice', Number(force_salesOffice))
    setValue('distributionChannel', Number(force_distributionChannel))
    setValue('saleOrg', Number(force_salesOrgs))
    setValue('itemGroupType', Number(force_itemGroupType))
  }

  const handleChangeItemGroupType = (value: string | number | null) => {
    const selectedItemGroupType = masterData.itemGroupType.find((item: any) => item.id === value)?.code
    if (selectedItemGroupType === ItemGroupTypeEnum.BREEDING_PIG) {
      const breedingSaleOrgId = masterData.salesOrg?.find((item : any) => item.code === CUSTOMER_MANAGE_DEFAULT_VALUE.SALE_ORG)?.id
      setDefaultSaleOrgId(breedingSaleOrgId)
      setDisableUinit({
        breedingPig: false,
        porkPig: true
      })
      setValue('salesGroup', null)
      setValue('saleOrg', breedingSaleOrgId)
    }
    else if (selectedItemGroupType === ItemGroupTypeEnum.PORK_PIG) {
      setDefaultSaleOrgId(null)
      setDisableUinit({
        breedingPig: true,
        porkPig: false
      })
      setValue('saleDistrict', null)
      setValue('saleOrg', undefined)
    }
    else {
      setDefaultSaleOrgId(null)
      setValue('salesGroup', null)
      setValue('saleDistrict', null)
      setValue('saleOrg', undefined)
      setDisableUinit({
        breedingPig: true,
        porkPig: true
      })
    }
  }

  return (
    <Dialog fullScreen open={true}>
      <div className="customermanage-dialog-content">
        <SimpleModalHeader
          title={getTitle()}
          onClose={handleClose}
        />
        <div className="dialog-content">
          <form id="create-customer" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                variant="fullWidth"
              >
                <Tab
                  label={t("customerManage.personalInformation")}
                  sx={{ fontSize: "1.4rem", fontWeight: "600" }}
                />
                <Tab
                  label={t("customerManage.bankAccountInformation")}
                  sx={{ fontSize: "1.4rem", fontWeight: "600" }}
                />
                <Tab
                  label={t("customerManage.businessDivisionInformation")}
                  sx={{ fontSize: "1.4rem", fontWeight: "600" }}
                  disabled={type===CUSTOMER_MANAGE_REQUEST_TYPE.UPDATE}
                />
                <Tab
                  label={t("customerManage.otherInfomationInformation")}
                  sx={{ fontSize: "1.4rem", fontWeight: "600" }}
                />
              </Tabs>
            </Box>

            <TabPanel value={tabIndex} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {type === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE ?
                    (
                      <GFFormItem control={control} name="fullName">
                      <GFTextField
                        fullWidth
                        label={t("customerManage.customerName")}
                        required
                        placeholder={`${t("inputPlaceholder")} ${t(
                          "customerManage.customerName"
                        )}`}
                      />
                    </GFFormItem>
                    ) :
                    (
                      <GFFormItem control={control} name="customerCode">
                        <GFSelectVirtualized
                          options={customers}
                          label={t("customerManage.customerId")}
                          required
                          onChange={handleChangeCustomer}
                          placeholder={`${t("inputPlaceholder")} ${t("customerManage.customerId")}`}
                        />
                    </GFFormItem>
                    )
                  }
                  
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="dateOfBirth">
                    <GFDatePicker
                      label={t("customerManage.dateOfBirth")}
                    />
                  </GFFormItem>
                </Grid>
                
                <Grid item xs={6}>
                  <GFFormItem control={control} name="idCard">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.idNo")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.idNo"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="idIssueDate">
                    <GFDatePicker
                      label={t("customerManage.dateOfIssueIdNo")}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="idIssuePlace">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.placeOfIssueIdNo")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.placeOfIssueIdNo"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>

                <Grid item xs={6}>
                  <GFFormItem control={control} name="taxId">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.tax")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.tax"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="phoneNumber">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.phoneNumber")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.phoneNumber"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="email">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.email")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.email"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>

                <Grid item xs={6}>
                  <GFFormItem control={control} name="address">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.fullAddress")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.fullAddress"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="province">
                    <GFAutoComplete
                      label={t("customerManage.province")}
                      options={provinces}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.province"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="district">
                    <GFAutoComplete
                      label={t("customerManage.district")}
                      options={matchedDistricts}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.district"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="shippingAddress">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.shippingAddress")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.shippingAddress"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="bankKey">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.bankName")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.bankName"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="bankAccNum">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.bankAccount")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.bankAccount"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="bankAccName">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.accountHolder")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.accountHolder"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="slsMan">
                    <GFSelectVirtualized
                      options={employees}
                      label={t("customerManage.salesPerson")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t("customerManage.salesPerson")}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="slsAst">
                    <GFSelectVirtualized
                      options={employees}
                      label={t("customerManage.salesSupervisor")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t("customerManage.salesSupervisor")}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="slsRef">
                    <GFSelectVirtualized
                      options={employees}
                      label={t("customerManage.salesManager")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t("customerManage.salesManager")}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="slsSup">
                    <GFSelectVirtualized
                      options={employees}
                      label={t("customerManage.salesDirector")}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t("customerManage.salesDirector")}`}
                    />
                  </GFFormItem>
                </Grid>
                
                <Grid item xs={6}>
                  <GFFormItem control={control} name="itemGroupType">
                    <GFAutoComplete
                      label={t("customerManage.itemGroupType")}
                      options={itemGroupTypes}
                      required
                      onChange={handleChangeItemGroupType}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.itemGroupType"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="saleOrg">
                    <GFAutoComplete
                      label={t("customerManage.salesStructure")}
                      options={salesOrgs}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.salesStructure"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="saleDistrict">
                    <GFAutoComplete
                      label={t("customerManage.breedingPigBusinessUnit")}
                      options={saleDistricts}
                      disabled={disabledUnit.breedingPig}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.breedingPigBusinessUnit"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="salesGroup">
                    <GFAutoComplete
                      label={t("customerManage.meatPigBusinessUnit")}
                      options={salesGroups}
                      disabled={disabledUnit.porkPig}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.meatPigBusinessUnit"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="distributionChannel">
                    <GFAutoComplete
                      label={t("customerManage.distributionChannel")}
                      options={distributionChannels}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.distributionChannel"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="division">
                    <GFAutoComplete
                      label={t("customerManage.productLine")}
                      options={divisions}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.productLine"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                
                <Grid item xs={6}>
                  <GFFormItem control={control} name="salesOffice">
                    <GFAutoComplete
                      label={t("customerManage.region")}
                      options={salesOffices}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.region"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="customerGroup">
                    <GFAutoComplete
                      label={t("customerManage.customerGroup")}
                      options={customerGroups}
                      required
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.customerGroup"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="businessLiceneseNum">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.businessLicenseNumber")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.businessLicenseNumber"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="businessLiceneseDate">
                    <GFDatePicker
                      label={t("customerManage.businessLicenseIssueDate")}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="nameOfLegalRepresentative">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.legalRepresentative")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.legalRepresentative"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="position">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.legalRepresentativeTitle")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.legalRepresentativeTitle"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="birthPlace">
                    <GFTextField
                      fullWidth
                      label={t("customerManage.legalRepresentativePlaceOfBirth")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.legalRepresentativePlaceOfBirth"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
                <Grid item xs={6}>
                  <GFFormItem control={control} name="codeOfFeedCus">
                    <GFTextField
                      fullWidth
                      disabled={disabledUnit.breedingPig}
                      label={t("customerManage.customerCodeFeed")}
                      placeholder={`${t("inputPlaceholder")} ${t(
                        "customerManage.customerCodeFeed"
                      )}`}
                    />
                  </GFFormItem>
                </Grid>
              </Grid>
            </TabPanel>
          </form>
        </div>
        <div className="dialog-content__footer">
          <GFButton onClick={handleClose} variant="outlined">
            {t("customerManage.cancel")}
          </GFButton>
          <GFButton variant="contained" type="submit" form="create-customer" 
            onClick={() => setIsDraft(true)}
            disabled={(!isValid && !isUpdate) || !isDirty || loading}
            >
            {loading && <CircularProgress size={18} />}
            
            {t("customerManage.btnSave")}
          </GFButton>
          <GFButton variant="contained" type="submit" form="create-customer" onClick={() => setIsDraft(false)} disabled={(!isValid && !isUpdate) || !isDirty || loading}>
            {loading && <CircularProgress size={18} />}
            {t("customerManage.btnSendRequest")}
          </GFButton>
        </div>
      </div>
      {loadingPage && <Loading />}
    </Dialog>
  );
};

export default CreateOrUpdateCustomerManage;
