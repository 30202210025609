import Loading from "@Components/Loading";
import { GFButton } from "@Components/ui";
import { GFBackdrop } from "@Components/ui/GFCommon";
import GFPageFooter from "@Components/ui/GFPageFooter";
import GFPageHeader from "@Components/ui/GFPageHeader";
import { Box, Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";

const GFPageWrapper = styled(Dialog)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  overflow: "hidden",
  "& .MuiDialog-paper": {
    background: "var(--primary-gray)",
  },
});

const GFPageContent = styled(Box)({
  height: "calc(100vh - 12.8rem)",
  overflow: "auto",
  padding: "24px 48px",
});

type GFPageDialogProps = PropsWithChildren<{
  onConfirm?: () => void;
  onCancel?: () => void;
  btnConfirmText?: string;
  btnCancelText?: string;
  title?: string;
  actions?: React.ReactNode;
  headerBottom?: React.ReactNode;
  loading?: boolean;
  variant?: "default" | "contained";
}>;

export default function GFPageDialog({
  btnCancelText = "Hủy",
  btnConfirmText = "Xác nhận",
  onCancel,
  onConfirm,
  children,
  title,
  actions,
  loading = false,
  variant = "contained",
  headerBottom,
}: GFPageDialogProps) {
  return (
    <GFPageWrapper open={true} fullScreen>
      {loading && (
        <GFBackdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "rgba(0, 0, 0, 0.5)",
          }}>
          <Loading />
        </GFBackdrop>
      )}
      <GFPageHeader title={title} onClose={onCancel} variant={variant} />
      {headerBottom}
      <GFPageContent>
        <Box>{children}</Box>
      </GFPageContent>
      <GFPageFooter mt="auto" variant={variant}>
        {actions || (
          <>
            <GFButton variant="outlined" minWidth={15} onClick={onCancel}>
              {btnCancelText}
            </GFButton>
            <GFButton variant="contained" minWidth={15} onClick={onConfirm}>
              {btnConfirmText}
            </GFButton>
          </>
        )}
      </GFPageFooter>
    </GFPageWrapper>
  );
}
