export const REQUEST_URL = {
  //login
  LOGIN: "/auth/login",
  VERIFY_TOKEN: "/auth/check-token",
  LOAD_ME: "/auth/admin/me",
  VERIFY_OTP_LOGIN: "/auth/verify-otp",
  VERIFY_OTP_FORGOT_PASSWORD: "/auth/verify-forgot-password-otp",
  RETRY_OTP: "/auth/retry-otp",
  UPDATE_PASSWORD: "/user/update-password",
  GETTOKEN: "/user/forgot-password",
  SAVE_TOKEN_DEVICE: "/auth/save-token-device",
  DEACTIVE_TOKEN_DEVICE: "/auth/deactive-token-device",
  SEND_PASSWORD: "/auth/send-password",
  DESTROY_TOKEN: "/auth/destroy-token",

  // product
  LIST_PRICE_SHIPPING: "/item/list-price-shipping",
  FILTER_PRODUCT_BY_ADMIN: "/item/farm",
  FILTER_PRODUCT_BY_ADMIN_INTERNAL: "/item/farm/internal",
  FILTER_PRODUCT: "/item",
  LIST_PRODUCT: "/item/list",
  PRODUCT_DETAIL: "/item/{id}",
  CUSTOMER: "/customer/for-admin",
  ALL_CUSTOMER: "/customer/belong-to-employee",
  CUSTOMER_GROUPS: "/customer/groups",
  CUSTOMER_DETAIL: "/customer",
  DIVISION: "/division",
  DIVISION_UPLOAD_IMG: "/division/upload-image-division/{id}",
  CREATE_PRODUCT_FARM: "/item/create-farm-item",
  UPDATE_IMG_PRODUCT: "/item/upload-image-item/{id}",
  UPDATE_PRODUCT_FARM: "/item/update-farm-item/{id}",
  ITEM_BY_ID: "/item/by-id",

  //order
  ORDER: "/order",
  ORDER_COMBINATION: "/order/farm/:orderId/combination",
  CONFIRM_ORDER: "/order/confirm-farm-order",
  UPDATE_ORDER: "/order/update-farm-order",
  GET_ALL_SITE_FARM: "/site/farm",
  GET_ALL_SITE: "/site",
  ALTER_ITEM: "/alter-item/info",
  CREATE_ORDER_DRAFT: "/order/create-by-draft-order",
  DELETE_ORDER_DRAFT: "/order/delete-draft-order",
  DETACH_ORDER_FARM: "/order/divide-farm-order",
  UPDATE_ORDER_FEED: "/order/update-feed-order",
  CANCEL_ORDER_FEED: "/order/cancel-order",
  RECALL_ORDER_FEED: "/order/recall-feed-order",
  RECALL_ORDER_LIST_FEED: "/order/recall-list-order",
  DELIVERY_ORDER: "/delivery-order",
  ORDER_COMPARE: "/order/compare",
  CANCEL_ORDER_FARM: "/order/complete-farm-order",
  UPDATE_ORDER_VET: "/order/update-vet-order",
  CREATE_ORDER_LAB: "/order/create-order-lab",
  UPDATE_ORDER_LAB: "/order/lab/{id}",
  CANCEL_ORDER_LAB: "/order/cancel-order-lab",
  UPDATE_ORDER_LAB_STATUS: "/order/update-lab-status/{id}",
  UPDATE_ORDER_LAB_SAMPLE: "/order/update-sample-lab-order",
  EXPORT_ORDER_LAB_SAMPLE: "/order/lab/{id}/sample-template/export",
  IMPORT_ORDER_LAB_SAMPLE: "/order/lab/{id}/sample-template/import",
  UPDATE_ORDER_VEHICLE: "/order/update-farm-order/vehicles",
  GET_LIST_COMBINE_ORDER: "/order/farm/list-to-combine",
  ADD_SHIPPING_FEE: "/order/update-farm-order/shipping-fee",
  GET_LIST_LAB_SAMPLE_GROUP_CODE: "/lab-sample-group-code/list",
  //customer
  CHANGE_PW: "/auth/change-password",
  AUTH_PROFILE: "/auth/get-profile",
  SIGNUP_ACCOUNT: "/user/sign-up-account",
  CUSTOMER_UPLOADIMAGE: "/customer/upload/{type}",
  CUSTOMER_DEBT: "/customer/debts",
  GET_CUSTOMER_DEBT: "customer/farm/{id}/debt",
  ACCOUNT: "/user/get-account",
  ACCOUNT_DETAIL: "/user/get-account/{id}",
  CUSTOMER_ORDER: "/order/of-customer",
  GET_LEAD: "/lead/belong-to-employee",
  LEAD_DETAIL: "/lead/{id}",
  CREATE_LEAD: "/lead/create-by-employee",
  GET_ADDRESS: "/shipping/admin/:customerId/get-all-shipping",
  CREATE_ADDRESS: "/shipping",
  CHANGE_STATUS: "/user/change-status",
  UPDATE_ACCOUNT: "/user/update-account/{id}",
  CUSTOMER_IDENTITY: "/customer/upload",
  UPDATE_ORDER_ITEM: "/order/update-farm-item",
  RECOVER_ACCOUNT: "/user/recover",
  ASSIGN_CUSTOMER_FARM_ORDER: "/order/assign-customer-farm-order",

  SUB_ACCOUNT_GET_ALL: "/sub-account/list",
  SUB_ACCOUNT_GET_DETAIL: "/sub-account/{id}",
  SUB_ACCOUNT_CREATE: "/sub-account",
  SUB_ACCOUNT_UPDATE: "/sub-account/{id}",
  SUB_ACCOUNT_REVIEW: "/sub-account/{id}/review",
  SUB_ACCOUNT_CHANGE_STATUS: "/sub-account/status",
  SUB_ACCOUNT_PROVISION: "/sub-account/{id}",

  //notification
  NOTIFICATION: "/notification",
  UPDATE_NOTIFICATION: "/notification",
  NOTI_GET_USERS: "/notification/get-users",
  SEND_NOTIFICATION: "/notification/send-notification",

  //employee
  ALL_EMPLOYEE: "/employee",
  SALES_MAN: "/employee/sales-man",

  //sales-org
  GET_SALES_ORG: "/sales-org",
  GET_SALES_ORG_FOR_RECALL: "/sales-org/for-recall",

  //sales-office
  GET_SALES_OFFICE_FARM: "/sales-office/farm",

  //target
  TARGET_GET_OR_CREATE: "/target",
  TARGET_BY_ID: "/target/{id}",

  //objective
  OBJECTIVE_GET_OR_CREATE: "/objective",
  OBJECTIVE_BY_ID: "/objective/{id}",

  //requestType
  REQUEST_TYPE_GET_OR_CREATE: "/request-type",

  //report
  GET_ALL_REPORT: "/report/get-all",
  REPORT_TOP_CUSTOMER: "/report/top-customer",
  REPORT_SALES: "/report/sales-and-quantity",
  REPORT_ITEM_GROUP: "/report/item-group",
  REPORT_DENSITY: "/report/density",
  REPORT_BU: "/report/get-bu",
  DEBT_REPORT: "/report/get-all-debt",
  DEBT_FARM_REPORT: "/report/get-all-farm-debt",
  REPORT_BU_FARM: "/report/get-bu-farm",
  REPORT_LEGAL_FARM: "/report/get-legal",
  REPORT_REVENUE: "/report/get-all-revenue",
  REPORT_AREA: "/report/get-area",

  //permission
  GET_ALL_PERMISSION: "/permission/available",
  CREATE_ROLE: "/role",
  GET_ROLE: "/role",

  //price
  LIST_PRICE: "/item/list-price",
  LIST_PRICE_FARM: "/item/list-price-farm",
  // UPDATE_PRICE: "/item/update-price",
  IMPORT_PRICE: "/item/import-price",

  //shipping
  GET_ALL_SHIPPING: "/shipping/admin",
  SHIPPING: "/shipping",

  //export-Excel
  EXPORT_PRICE: "/export/price",
  EXPORT_CUSTOMER: "/export/customer-division",
  EXPORT_DEBT: "/export/report-debt",
  EXPORT_DEBT_FARM: "/export/report-farm-debt",
  EXPORT_ORDER: "/export/order",
  EXPORT_REPORT: "/export/report-sales-volumes",
  EXPORT_ITEM: "/export/item",
  EXPORT_EMPLOYEE: "/export/employee-account",
  EXPORT_ACCOUNT: "export/customer-account",
  REFRESH_STATUS: "/export/refresh-status",
  EXPORT_LEAD: "export/lead/{id}",
  EXPORT_SMS: "export/sms",
  EXPORT_NOTIFICATION: "/export/notification",
  EXPORT_ORDER_FARM: "/export/order-farm",
  EXPORT_REPORT_REVENUE: "/export/report-revenue",
  EXPORT_ORDER_PLAN: "/export/order-plan/{planId}",

  //sales-office
  SALES_OFFICE: "sales-office",

  //discount
  BULK_DISCOUNT: "/bulk-discount",
  REPORT_DISCOUNT: "/discount",

  //ERP
  CREATE_ERP_FARM: "/order/create-erp-farm-order",

  //plan
  ORDER_PLAN: "/order-plan/list",
  DETAIL_ORDER_PLAN: "/order-plan/{planId}",
  CREATE_ORDER_PLAN: "/order-plan",
  UPDATE_ORDER_PLAN: "/order-plan/{planId}",
  UPDATE_STATUS_ORDER_PLAN: "/order-plan/{planId}/status",
  CREATE_ORDER_FEED_BY_PLAN: "/order-plan/create-order",
  UPDATE_DETAIL_ORDER_PLAN: "/order-plan/detail/{detailId}",
  UPDATE_STATUS_DETAIL_ORDER_PLAN: "/order-plan/detail/{detailId}/status",

  //news
  NEWS_GET_ALL: "/news/admin",
  NEWS_DETAIL_DELETE_UPDATE: "/news/{newsId}",
  NEWS_CUSTOMER: "/news/actor",
  NEWS_POST: "/news",

  //deposit
  DEPOSIT_GET_ALL_OR_CREATE: "/deposit",
  DEPOSIT_GET_ITEM_GROUP_TYPE: "/deposit/itemGroupType/{id}",
  DEPOSIT_DETAIL_UPDATE_DELETE: "/deposit/{depositId}",
  //sms
  SMS: "/notification/sms",

  // warehouse
  WAREHOUSE_GET_ALL: "/warehouse",
  WAREHOUSE_GET_ALL_PORK: "/warehouse/itemGroupType/:id",
  WAREHOUSE_GET_TRANSFER: "/warehouse/transfer",

  // Vehicle
  GET_ALL_VEHICLE: "/vehicle",
  UPDATE_VEHICLE: "/vehicle/{id}",
  CREATE_VEHICLE: "/vehicle",

  // approval cancellation status
  APPROVAL_STATUS: "/approval-status",
  APPROVAL_PROCESS: "/approval-process",

  // customer manage
  CUSTOMER_MANAGE: "/customer-request",
  CUSTOMER_MANAGE_LIST: "/customer-request/list",
  CUSTOMER_MANAGE_DATA_SOURCE: "/customer-request/data-source",
  CUSTOMER_MANAGE_CHECK_DUPLICATE: "/customer-request/check-available-customer",
  CUSTOMER_MANAGE_DATA_SOURCE_CUSTOMER: "/customer-request/data-source/customer",
  CUSTOMER_MANAGE_CREATE_SHIPPING_ADDRESS: "/customer-request/shipping-address",
  CUSTOMER_MANAGE_EMPLOYEE: "/customer-request/data-source/employee",

  // Contract
  CONTRACT_MANAGE: "/contract",
} as const;
