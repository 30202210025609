import GFLabel from "@Components/ui/GFLabel";
import { Box, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSwitch = styled(Switch)(({ theme, color, size, disabled }) => ({
  "&.MuiSwitch-root": {
    padding: 0,
    width: "5.2rem",
    height: "3.2rem",
    flexShrink: 0,
    ...(size === "small" && {
      width: "4rem",
      height: "2.4rem",
    }),
    "& .MuiSwitch-switchBase": {
      padding: 0,
    },
    "& .MuiSwitch-thumb": {
      marginTop: "0.2rem",
      marginLeft: "0.2rem",
      height: "2.8rem",
      width: "2.8rem",
      backgroundColor: "white",
      border: "1px solid transparent",
      ...(size === "small" && {
        height: "2rem",
        width: "2rem",
      }),
    },
    "&  .MuiSwitch-track": {
      height: "3.2rem",
      borderRadius: "9999px",
      backgroundColor: "var(--primary-gray-2)",
      ...(size === "small" && {
        height: "2.4rem",
      }),
    },
    "& .Mui-checked ~ .MuiSwitch-track": {
      backgroundColor: "var(--secondary-green)",
      opacity: 1,
    },

    "& .Mui-checked.MuiSwitch-switchBase": {
      transform: "translateX(1.6rem)",
    },
    "& .Mui-disabled": {
      "& .MuiSwitch-thumb": {
        backgroundColor: "var(--disabled-color)",
      },
      "& ~ .MuiSwitch-track": {
        cursor: "not-allowed",
        backgroundColor: "var(--primary-gray-2) !important",
      },
    },
  },
}));

const StyledGFSwitchWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: 'fit-content',
  gap: "1.6rem",
});

type GFSwitchProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  size?: "small" | "large";
};

export default function GFSwitch({
  value,
  label,
  required,
  disabled,
  size,
  onChange,
}: GFSwitchProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) {
      onChange(checked);
    }
  };

  return (
    <StyledGFSwitchWrapper>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <StyledSwitch
        checked={value}
        onChange={handleChange}
        disabled={disabled}
        size={size as any}
      />
    </StyledGFSwitchWrapper>
  );
}
