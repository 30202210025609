import accountImage from "@Assets/images/avt.png";
import Img from "@Assets/images/history.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { AccountCustomerPermission, LogType, SourceType } from "@Models";
import { AuthService, CustomerService } from "@Services";
import { PermissionUtil, checkLinkImage } from "@Utils";
import {
  CheckRounded as CheckRoundedIcon,
  PriorityHigh as PriorityHighIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import UpdateAccount from "../Modal/UpdateAccount";
import "./CustomerAccountDetail.scss";

const CustomerAccountDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [authAccount, setAuthAccount] = useState<any>(null);
  const mounted = useRef(false);
  const { accountId }: { accountId: string } = useParams();
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [noti, setNoti] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [openUpdateAccount, setOpenUpdateAccount] = useState<boolean>(false);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  React.useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const [reCallAPI, setRecallAPI] = useState<boolean>(false);

  const getAccountDetail: () => Promise<void> = async () => {
    try {
      const response = await CustomerService.getAccountById(accountId);
      if (mounted.current) {
        setAuthAccount(() => response.data);
        setRecallAPI(false);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getAccountDetail();
    // eslint-disable-next-line
  }, [reCallAPI === true]);

  const accountImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = accountImage;
    event.currentTarget.className = "error";
  };

  const grantPassword = () => {
    AuthService.grantNewPassword(accountId)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Cấp mật khẩu thành công", {
            variant: "success",
          });
          window.location.reload();
        } else {
          // do something
        }
      })
      .catch((err) => {
        enqueueSnackbar("Cấp mật khẩu thất bại", {
          variant: "error",
        });
      });
  };

  const changeStatus = () => {
    CustomerService.changeStatus({
      accountId: parseInt(accountId),
      status: isActive,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          if (notiStatus.status === true) {
            enqueueSnackbar("Kích hoạt tài khoản thành công", {
              variant: "success",
            });
            setRecallAPI(true);
          } else {
            enqueueSnackbar("Vô hiệu tài khoản thành công", {
              variant: "success",
            });
            setRecallAPI(true);
          }
        } else {
          // do something
        }
      })
      .catch((err) => {
        setIsActive(!isActive);
        if (isActive === false) {
          enqueueSnackbar("Kích hoạt tài khoản thất bại", {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Vô hiệu tài khoản thất bại", {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    if (authAccount && authAccount.isActive === false) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    // eslint-disable-next-line
  }, [authAccount]);

  const checkPermissionCusAccountUpdate = PermissionUtil.check(
    profile?.role?.permissions,
    AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_UPDATE,
    true
  );

  const checkPermissionCusAccountChangeStatus = PermissionUtil.check(
    profile?.role?.permissions,
    AccountCustomerPermission.MANAGE_CUSTOMER_ACCOUNT_CHANGE_STATUS,
    true
  );

  const checkPermissionRecoverAccount = profile?.role?.position.includes("Admin");

  const recoverAccount = async (id: number) => {
    try {
      const res = await CustomerService.recoverAccount({ id: id });
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Khôi phục tài khoản thành công", {
          variant: "success",
          preventDuplicate: true,
        });
        setTimeout(() => getAccountDetail(), 200);
      }
    } catch (error) {
      enqueueSnackbar("Khôi phục tài khoản thất bại", {
        variant: "error",
        preventDuplicate: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <Dialog fullScreen open={closeForm}>
        <div className="cus-account-detail">
          <SimpleModalHeader
            title={"Chi tiết tài khoản khách hàng"}
            onClose={() => {
              setCloseForm(false);
              history.push(APP_ROUTES.ACCOUNT + "-account");
            }}
          />
          <div className="content">
            {!!authAccount ? (
              <Grid container>
                <Grid item xs={3} className="left">
                  <div className="image">
                    <img
                      src={checkLinkImage(authAccount?.avatar) || accountImage}
                      alt="img"
                      onError={accountImageOnErrorHandler}
                    />
                  </div>
                  <div className="title">{authAccount.nickname || TEXT_HOLDER}</div>
                  {authAccount && (
                    <div className="status">
                      {authAccount.cancelled ? (
                        <span style={{ color: "#B8292F" }}>&#9679;&ensp;Đã hủy</span>
                      ) : authAccount.isActive === false ? (
                        <span style={{ color: "#E93C3C" }}>&#9679;&ensp;{t("DISABLE")}</span>
                      ) : (
                        <span style={{ color: "#1AB759" }}>&#9679;&ensp;Đang kích hoạt</span>
                      )}
                    </div>
                  )}
                  {authAccount?.cancelled ? (
                    <div
                      className="btn-confirm-style"
                      onClick={() => {
                        if (checkPermissionRecoverAccount) {
                          recoverAccount(+accountId);
                        } else {
                          enqueueSnackbar(t("ACCESS_DENIED"), {
                            variant: "error",
                            preventDuplicate: true,
                          });
                        }
                      }}>
                      Khôi phục tài khoản
                    </div>
                  ) : (
                    <>
                      <div
                        className="btn-confirm-style"
                        onClick={() => {
                          checkPermissionCusAccountUpdate
                            ? setOpenUpdateAccount(true)
                            : enqueueSnackbar(t("ACCESS_DENIED"), {
                                variant: "error",
                                preventDuplicate: true,
                              });
                        }}>
                        Chỉnh sửa
                      </div>
                      <div className="btn-setpw" onClick={() => setNoti(true)}>
                        <div className="text">Cấp lại mật khẩu</div>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid item xs={8} className="center">
                  <div className="box">
                    <div className="box-header">
                      <div className="title">{t("ACCOUNT_INFO")}</div>
                      <div>
                        <DefaultSwitch
                          checked={authAccount.cancelled ? false : isActive}
                          disabled={authAccount.cancelled}
                          onChange={() => {
                            if (!checkPermissionCusAccountChangeStatus) {
                              enqueueSnackbar(t("ACCESS_DENIED"), {
                                variant: "error",
                                preventDuplicate: true,
                              });
                              return;
                            }
                            setIsActive(!isActive);
                            setNotiStatus({
                              visible: true,
                              status: isActive === false ? true : false,
                            });
                          }}
                        />
                        {authAccount.cancelled ? (
                          <span style={{ color: "#838283" }}>{t("DISABLE")}</span>
                        ) : isActive === false ? (
                          <span style={{ color: "#838283" }}>{t("DISABLE")}</span>
                        ) : (
                          <span style={{ color: "#41AD49" }}>{t("ACTIVATE")}</span>
                        )}
                      </div>
                    </div>
                    <Grid container>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">{t("DISPLAY_NAME")}</div>
                          {authAccount.nickname || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("PHONE_NUMBER")}</div>
                          {authAccount.phoneNumber || TEXT_HOLDER}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">Email</div>
                          {authAccount.email || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("OTP_VERIFICATION")}</div>
                          {!!authAccount.requireOTP ? "Có" : "Không"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">Mã đăng nhập</div>
                          {authAccount.code || TEXT_HOLDER}
                        </div>
                        <div>
                          <div className="label">{t("POLICY")}</div>
                          {authAccount.policyAccepted ? t("CONFIRMED") : t("NOT_CONFIRM")}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <div className="label">Vai trò</div>
                          {authAccount?.role?.position || TEXT_HOLDER}
                        </div>
                      </Grid>
                    </Grid>

                    {authAccount.customers.length > 0 && (
                      <div>
                        <div className="header">
                          <div className="source">{t("ACCESS_SCOPE")}</div>
                          <div className="cus">{t("CUSTOMER")}</div>
                          <div className="access">{t("ACCESS_GRANT")}</div>
                        </div>
                        <div className="table">
                          {authAccount.customers.map((cus: any, idx: number) => {
                            return (
                              <div className="table-content" key={idx}>
                                <div className="col source">
                                  <div>
                                    {(() => {
                                      if (cus.source === SourceType.FEED) {
                                        return t("FEED");
                                      } else if (cus.source === SourceType.FARM) {
                                        return t("FARM");
                                      } else if (cus.source === SourceType.VET) {
                                        return t("VET");
                                      } else if (cus.source === SourceType.LAB) {
                                        return t("LAB");
                                      } else {
                                        return t("CCU");
                                      }
                                    })()}
                                  </div>
                                </div>
                                <div className="col cus">
                                  <div className="cus-info">
                                    {/* <div className="cus-info__img">
                                      <img
                                        src={BASE_LINK_IMAGE + cus.avatar || accountImage}
                                        alt="avatar"
                                        onError={accountImageOnErrorHandler}
                                      />
                                    </div> */}
                                    <div>
                                      <div className="cus-info__name">
                                        {cus.fullName || TEXT_HOLDER} - {cus.code || TEXT_HOLDER}
                                      </div>
                                      <div className="cus-info__phone">
                                        {cus.phoneNumber || TEXT_HOLDER} -{" "}
                                        {cus.email || TEXT_HOLDER}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col access">
                                  <div
                                    className="ic-green"
                                    style={{ backgroundColor: cus.isActive ? "" : "#efefef" }}>
                                    <CheckRoundedIcon
                                      style={{
                                        fontSize: "1.7rem",
                                        color: "#fff",
                                        background: cus.isActive ? "" : "#efefef",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "4rem" }}>
                    <div className="box">
                      <div className="title"> {t("customer:ACCESS_HISTORY")}</div>
                      <Divider style={{ margin: "1.6rem 0" }} />
                      {authAccount.accessLogs.length > 0 ? (
                        <>
                          <div className="label">
                            <Grid container>
                              <Grid item xs={3}>
                                {t("TIME")}
                              </Grid>
                              <Grid item xs={3}>
                                IP
                              </Grid>
                              <Grid item xs={3}>
                                {t("STATUS")}
                              </Grid>
                              <Grid item xs={3}>
                                {t("customer:DEVICE")}
                              </Grid>
                            </Grid>
                          </div>
                          {authAccount.accessLogs.map((u: any, idx: number) => {
                            return (
                              <div style={{ maxHeight: "54rem", overflow: "auto" }} key={idx}>
                                <div className="item">
                                  <Grid container>
                                    <Grid item xs={3}>
                                      {moment(new Date(u.createdAt)).format("DD/MM/YYYY")} lúc{" "}
                                      {(new Date(u.createdAt).toString() as any).substr(16, 5)}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.ip || TEXT_HOLDER}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.status
                                        ? u.type === LogType.Login
                                          ? "Đăng nhập thành công"
                                          : "Đăng ký thành công"
                                        : "Thất bại"}
                                    </Grid>
                                    <Grid item xs={3}>
                                      {u.deviceType || TEXT_HOLDER}
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <EmptyList img={Img} title={t("customer:NO_ACCESS_HISTORY")} />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <Loading style={{ margin: "2rem 0", height: "70vh" }} />
            )}
          </div>
        </div>
      </Dialog>

      <Dialog fullScreen open={openUpdateAccount}>
        <UpdateAccount closeForm={() => setOpenUpdateAccount(false)} infoAccount={authAccount} />
      </Dialog>

      <Notification
        visible={noti}
        title={"Cấp lại mật khẩu"}
        subTitle={"Bạn có chắc muốn cấp lại mật khẩu cho tài khoản này?"}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setNoti(false)}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={grantPassword}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setNoti(false);
        }}
      />

      {/**
       * chỉ hiện inactive popup khi active của dòng đó là true
       */}
      {notiStatus && (
        <Notification
          visible={notiStatus.visible}
          title={notiStatus.status ? "Kích hoạt tài khoản" : "Vô hiệu hóa tài khoản"}
          subTitle={
            notiStatus.status ? (
              <span>
                Tài khoản đang vô hiệu hóa
                <br />
                Bạn có chắc muốn kích hoạt tài khoản này ?
              </span>
            ) : (
              <span>
                Tài khoản đang hoạt động
                <br />
                Bạn có chắc muốn vô hiệu hóa tài khoản này ?
              </span>
            )
          }
          icon={
            <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                  notiStatus.status ? setIsActive(false) : setIsActive(true);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  changeStatus();
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            notiStatus.status
              ? setNotiStatus({ visible: false, status: true })
              : setNotiStatus({ visible: false, status: false });
            notiStatus.status ? setIsActive(false) : setIsActive(true);
          }}
        />
      )}
    </>
  );
};

export default CustomerAccountDetail;
