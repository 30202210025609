/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import { ChildOrderIcon } from "@Components/Icons/ChildOrderIcon";
import { OrderGroupIcon } from "@Components/Icons/OrderGroupIcon";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import RangePicker from "@Components/RangePicker";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import {
  APP_ROUTES,
  BASE_LINK_IMAGE,
  NavHeaderOrder,
  NavHeaderOrderFarm,
  NavHeaderOrderLab,
  OrderStatusERP,
  StatusOrder,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  TIME,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import useDateRange from "@Hooks/useDateRange";
import {
  Action,
  FetchAction,
  ItemGroupType,
  ItemPermission,
  OrderOutputModel,
  OrderPermission,
  OrderStatus,
  OrderStatusFarmDisplay,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { ExportService, OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import {
  Collapse,
  Dialog,
  FormControlLabel,
  FormHelperText,
  Grid,
  OutlinedInput,
} from "@mui/material";
import { InputAdornment, TextField } from "@material-ui/core";
import { Autorenew as AutorenewIcon, Search as SearchIcon } from "@mui/icons-material";
import { Checkbox, Chip, MenuItem, Select, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import _, { get, uniqueId } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router";
// import { DEFAULT_SIZE } from "../Authorize/Role";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.scss";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Divider from "@mui/material/Divider";
import { ITruckLoadModel } from "@Models/truckload.model";
import { VehicleService } from "@Services/vehicle.service";
import { IDriver, IVehicleData } from "@Models/veihicle.model";
import {
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import { ItemSection } from "@Components/Common";
import { GFCheckbox, GFTypography } from "@Components/ui";
type P_Props = {
  data: any;
  setOpenTruckLoad: any;
};

interface FormValues {
  vehicles: {
    id?: any;
    vehicleType: string;
    vehicleInfo: string;
    nameOfDriver: string;
    phoneNumberOfDriver: string;
    escortStaff: string;
    shippingCode: string;
    note: string;
    vehicleCapacity: number;
  }[];
}

function reducer(state: PaginateFetchReducer<OrderOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

function reducerVehicle(state: PaginateFetchReducer<IVehicleData>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const ModalLessContainer: React.FC<P_Props> = ({ data, setOpenTruckLoad }) => {
  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      vehicles: [
        {
          id: uniqueId(),
          vehicleType: "",
          vehicleInfo: "",
          nameOfDriver: "",
          phoneNumberOfDriver: "",
          escortStaff: "",
          shippingCode: "",
          note: "",
          vehicleCapacity: 0,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "vehicles",
  });
  const [inputDriver, setInputDriver] = useState({});
  const { t } = useTranslation(["translation", "order"]);
  const [listCombine, setListCombine] = useState<any>([]);
  const [moreInfo, setMoreInfo] = useState<string[]>([]);
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });
  const [listDriver, setListDriver] = useState({});

  const checkExportRef = useRef<any>(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleFloatingBtn, setVisibleFloatingBtn] = useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>("all");
  const [selectSubStatus, setSelectSubStatus] = useState<string>("all");
  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [checkedSource, setCheckedSource] = useState<string[]>([]);
  const mounted = useRef(false);
  const location = useLocation<any>();
  const [getOrder, setGetOrder] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [filterOrder, setFilterOrder] = useState<"G" | "D">("G");
  const searchQuery = new URLSearchParams(location.search);

  const toggleFloatingBtn = () => {
    setVisibleFloatingBtn(!visibleFloatingBtn);
  };

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  const [stateVehicle, dispatchVehicle] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  const fetchDataVehicle = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatchVehicle({ type: Action.FETCH, payload: {} });
    try {
      const result = await VehicleService.getAllVehicle(query);
      if (isReset) {
        dispatchVehicle({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current) {
        dispatchVehicle({
          type: Action.SUCCESS,
          payload: result.data,
        });
      }
    } catch (err) {
      if (mounted)
        dispatchVehicle({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    setListCombine([]);
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await VehicleService.getListCombineOrder(query);
      if (isReset) {
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current) {
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
      }
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    setGetOrder(true);
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }, [location, profile]);

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  const onSearch = _.debounce((value) => {
    fetchData({
      page: 1,
      limit: 50,
      from: range.startTime.format("YYYY-MM-DD"),
      to: range.endTime.format("YYYY-MM-DD"),
      orderId: data.order.id,
    });
  }, 500);

  const recallOrderList = () => {
    OrderService.recallOrderList({
      orderGroupIds: checkedSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res.data.message === "ONE PART") {
            enqueueSnackbar(t("order:PARTIAL_ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else if (res.data.message === "SUCCESS") {
            enqueueSnackbar(t("order:ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
              variant: "error",
            });
          }
          setCheckedSource([]);
          setSynchronized(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    location.state?.status !== undefined
      ? setSelectTab(location.state?.status)
      : setSelectTab("all");
  }, []);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  //permission
  const checkPermissionOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_RECALL,
    true
  );

  const checkPermissionOrderDetail = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_VIEWDETAILS,
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_EXPORT,
    true
  );

  const checkPermissionOrderCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );
  const styles = {
    resize: {
      fontSize: 50,
    },
  };

  const handleListDriver = (vehicleType: any) => {
    if (vehicleType) {
      const findListDrivers: IVehicleData = stateVehicle.data.find((item: any) => {
        return String(item.id) === String(vehicleType);
      });
      if (findListDrivers) {
        return findListDrivers.drivers;
      }
      return [];
    } else {
      return [];
    }
  };

  const onSubmit: SubmitHandler<any> = async (dataVehicles: any) => {
    let listOrderId = [...listCombine, data.order.id];
    let vehicleInfo = [...dataVehicles?.vehicles];

    // Tính tổng số lượng vận chuyển
    let totalCapcacity = 0;
    [...dataVehicles?.vehicles].map((item, index) => {
      totalCapcacity += Number(get(item, "vehicleCapacity", 0));
    });

    // Lấy thông tin các đơn hàng được chọn
    const listOrderChoose = state.data.filter((item: any) => {
      return listCombine.includes(item.id);
    });

    // Lấy tổng quantity
    let totalQuantity = 0;
    listOrderChoose.map((item: any) => {
      const quantity = Number(get(item, "quantity", 0));
      totalQuantity += quantity;
    });

    if (totalQuantity !== totalCapcacity) {
      enqueueSnackbar("Số lượng vận chuyển phải bằng số lượng đơn hàng", {
        variant: "error",
      });
      return;
    }

    vehicleInfo = vehicleInfo.map((item) => {
      const findObject = stateVehicle.data.find((ele: any) => {
        return ele.id === item.vehicleType;
      });
      if (findObject) {
        item.vehicleType = findObject.vehicleType;
      }
      // Ép vehicle capacity về kiểu Number
      return {
        ...item,
        vehicleCapacity: Number(item?.vehicleCapacity),
      };
    });

    const dataUpdateVehicles: ITruckLoadModel = {
      type: "COMBINE",
      orderIds: listOrderId,
      customerId: 0,
      vehicles: vehicleInfo,
    };

    try {
      const res = await VehicleService.updateVehicleOrder(dataUpdateVehicles);
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Điều phối xe thành công", {
          variant: "success",
        });
        setOpenTruckLoad(false);
        window.location.reload();
      }
    } catch {
      enqueueSnackbar("Điều phối xe thất bại", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (data.open) {
      fetchData(
        {
          page: 1,
          limit: 50,
          from: range.startTime.format("YYYY-MM-DD"),
          to: range.endTime.format("YYYY-MM-DD"),
          orderId: data.order.id,
        },
        true
      );
    }
  }, [data.open, range.startTime.toISOString(), range.endTime.toISOString()]);

  useEffect(() => {
    fetchDataVehicle(
      {
        page: 1,
        limit: 9999,
        getAll: true,
      },
      true
    );
  }, []);

  return (
    <Dialog fullScreen open={true} className="truckload-modal">
      <SimpleModalHeader
        title={"Ghép xe"}
        onClose={() => {
          setOpenTruckLoad(false);
        }}
      />
      <div className="less-container">
        <div className="less-container-content">
          <GFTypography variant="h5">Chọn đơn</GFTypography>
          <div className="less-container__header__search">
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <TextField
                placeholder={t("SEARCH_CUSTOMER_CODE_NAME_PHONE")}
                variant="outlined"
                className="search-style"
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" className="icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <RangePicker
                startDate={
                  location.state?.startTime !== undefined
                    ? moment(location.state?.startTime)
                    : range.startTime
                }
                endDate={
                  location.state?.endTime !== undefined
                    ? moment(location.state?.endTime)
                    : range.endTime
                }
                onChangeDate={(val) => {
                  if (val && val.length) {
                    setRange(
                      moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                      moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className={"less-container__list__truck_load"}>
            <div className="header">
              <div className="truck-load-name">Con giống </div>
              <div className="truck-load-amount">Số lượng</div>
              <div className="truck-load-total-weight">Tổng trọng lượng</div>
              <div className="truck-load-day">Ngày giao</div>
              <div className="truck-load-address">Địa chỉ giao hàng</div>
            </div>
            <div className="less-container__list__body">
              {state.data &&
                state.data.map((ele: any) => {
                  return (
                    <div className="truck-load-item">
                      <GFCheckbox
                        size="small"
                        onChange={(e) => {
                          let cloneListCombine = [...listCombine];
                          if (listCombine.includes(ele?.id)) {
                            cloneListCombine = cloneListCombine.filter(
                              (item, index) => item !== ele.id
                            );
                            setListCombine(cloneListCombine);
                          } else {
                            cloneListCombine.push(ele?.id);
                            setListCombine(cloneListCombine);
                          }
                        }}
                      />
                      <div className="truck-load-name">
                        <div className="item-name">
                          <img
                            src={renderImageProNull("farm")}
                            // alt={itemOrderLines.item.shortName}
                            // onError={renderErrorImageItem("farm")}
                          />
                          <div className="name">
                            <div>{ele.itemName}</div>
                            <div className="code">{ele.itemName}</div>
                          </div>
                        </div>
                      </div>
                      <div className="truck-load-amount">
                        {ele?.quantity ? parseFloat(ele?.quantity) : TEXT_HOLDER}
                      </div>
                      <div className="truck-load-total-weight">
                        {currencyFormat(+ele.dimension)}&ensp;kg
                      </div>
                      <div className="truck-load-day">{ele.requiredDate}</div>
                      {/* <div className="phone">Số điện thoại</div> */}
                      <div className="truck-load-address">{ele.address}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <GFTypography variant="h5">Thông tin xe</GFTypography>
          <form onSubmit={handleSubmit(onSubmit)} id="hook-form">
            {fields.map((item: any, index: number) => {
              return (
                <ItemSection>
                  <Grid container className="truck-item" spacing={2} key={item.id}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={3}>
                          <div className="label">
                            Loại xe <span>*</span>
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleType`}
                              control={control}
                              defaultValue={item.vehicleType}
                              rules={{
                                validate: {
                                  required: (value) => {
                                    if (!value) {
                                      return "Trường thông tin không được để trống";
                                    }
                                  },
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <div
                                    style={{
                                      width: "100%",
                                    }}>
                                    <Select
                                      {...field}
                                      fullWidth
                                      input={
                                        <OutlinedInput
                                          className="form-text-field"
                                          color="success"
                                        />
                                      }
                                      placeholder="Chọn mã vận chuyển"
                                      displayEmpty
                                      className="form-select-field"
                                      MenuProps={{
                                        className: "select-menu-list",
                                        style: { maxHeight: "40rem" },
                                      }}
                                      renderValue={(selected) => {
                                        if (!selected) {
                                          return (
                                            <div
                                              style={{
                                                color: "gray",
                                                fontWeight: 200,
                                              }}>
                                              Chọn loại xe
                                            </div>
                                          );
                                        }
                                        // Tìm object tương ứng với id được chọn để lấy vehicleType
                                        const selectedVehicle = stateVehicle.data.find(
                                          (item: IVehicleData) => item.id === Number(selected)
                                        );
                                        return selectedVehicle ? selectedVehicle.vehicleType : "";
                                      }}
                                      error={!!error}
                                      onChange={(e) => {
                                        if (e.target.value) {
                                          const object = stateVehicle.data.find(
                                            (item: IVehicleData) =>
                                              item.id == Number(e.target.value)
                                          );
                                          if (object) {
                                            let driverIsInput = get(inputDriver, `${index}`, false);
                                            setValue(`vehicles.${index}`, {
                                              vehicleType: e.target.value,
                                              vehicleInfo: object.vehicleInfo,
                                              nameOfDriver: !driverIsInput
                                                ? object.drivers[0].name
                                                : object.drivers[0].name,
                                              phoneNumberOfDriver: "",
                                              escortStaff: "",
                                              shippingCode: "",
                                              note: "",
                                              vehicleCapacity: 0,
                                            });
                                            let cloneListDriver = {
                                              ...listDriver,
                                              [index]: handleListDriver(e.target.value),
                                            };
                                            setListDriver(cloneListDriver);
                                          }
                                        }
                                      }}>
                                      {stateVehicle.data &&
                                        stateVehicle.data.map(
                                          (value: IVehicleData, index: number) => {
                                            return (
                                              <MenuItem key={value.id} value={value.id}>
                                                {value.vehicleType}
                                              </MenuItem>
                                            );
                                          }
                                        )}
                                    </Select>
                                    {error && (
                                      <FormHelperText error>{error.message}</FormHelperText>
                                    )}{" "}
                                    {/* Hiển thị thông báo lỗi */}
                                  </div>
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Biển số xe</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleInfo`}
                              control={control}
                              defaultValue={item.vehicleInfo}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    placeholder="Nhập biển số xe"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    InputProps={{
                                      readOnly: true, // Set the TextField to read-only
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
                            <div
                              className="label"
                              style={{
                                marginBottom: 0,
                                marginRight: 16,
                              }}>
                              Tên tài xế
                            </div>
                            <FormControlLabel
                              sx={{
                                height: 16,
                              }}
                              control={
                                <Checkbox
                                  // name="SomeName"
                                  value={get(inputDriver, `${index}`, false)}
                                  onChange={(e) => {
                                    let cloneInputDriver = {
                                      ...inputDriver,
                                      [index]: e.target.checked,
                                    };
                                    setInputDriver(cloneInputDriver);
                                  }}
                                />
                              }
                              label={<span style={{ fontSize: "1.6rem" }}>Tự nhập tên tài xế</span>}
                            />
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.nameOfDriver`}
                              control={control}
                              defaultValue={item.nameOfDriver}
                              render={({ field }) => {
                                return get(inputDriver, `${index}`, false) ? (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    placeholder="Nhập tên tài xế"
                                    inputProps={{
                                      className: "input",
                                    }}
                                  />
                                ) : (
                                  <Select
                                    {...field}
                                    fullWidth
                                    key={watch(`vehicles.${index}.vehicleType`)}
                                    input={
                                      <OutlinedInput className="form-text-field" color="success" />
                                    }
                                    renderValue={(selected) => {
                                      if (!selected) {
                                        return (
                                          <div
                                            style={{
                                              color: "gray",
                                              fontWeight: 200,
                                            }}>
                                            Chọn tài xế
                                          </div>
                                        );
                                      }
                                      return selected;
                                    }}
                                    placeholder="Chọn mã vận chuyển"
                                    displayEmpty
                                    className="form-select-field"
                                    MenuProps={{
                                      className: "select-menu-list",
                                      style: { maxHeight: "40rem" },
                                    }}
                                    // error={!!error}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setValue(`vehicles.${index}.nameOfDriver`, e.target.value);
                                      }
                                    }}>
                                    {get(listDriver, `${index}`, []).map(
                                      (value: IDriver, index: number) => {
                                        return (
                                          <MenuItem key={value.name} value={value.name}>
                                            {value.name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Số điện thoại</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.phoneNumberOfDriver`}
                              control={control}
                              defaultValue={item.phoneNumberOfDriver}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    placeholder="Nhập số điện thoại"
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Nhân viên áp tải</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.escortStaff`}
                              control={control}
                              defaultValue={item.escortStaff}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập nhân viên áp tải"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Mã chuyến xe</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.shippingCode`}
                              control={control}
                              defaultValue={item.shippingCode}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập mã"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">
                            Số lượng vận chuyển <span>*</span>
                          </div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.vehicleCapacity`}
                              control={control}
                              defaultValue={item.vehicleCapacity}
                              rules={{
                                validate: {
                                  required: (value) => {
                                    if (!value) {
                                      return "Trường thông tin không được để trống";
                                    }
                                  },
                                },
                              }}
                              render={({ field, fieldState: { error } }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập số lượng vận chuyển"
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    onKeyPress={(e) => {
                                      // Chỉ cho phép các phím số từ 0 đến 9
                                      if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="label">Ghi chú</div>
                          <div className="form-item">
                            <Controller
                              name={`vehicles.${index}.note`}
                              control={control}
                              defaultValue={item.note}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    style={{ width: "100%", fontSize: "1.6rem" }}
                                    variant="outlined"
                                    id="standard-basic"
                                    className="form-text-field"
                                    inputProps={{
                                      className: "input",
                                    }}
                                    placeholder="Nhập ghi chú"
                                  />
                                );
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={1}>
                      <div
                        onClick={() => {
                          remove(index);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          marginTop: "15px",
                        }}>
                        <DeleteOutlineIcon
                          style={{
                            fontSize: "30px",
                            marginLeft: "3rem",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ItemSection>
              );
            })}
          </form>
          <div
            style={{ color: "#41ad49", cursor: "pointer" }}
            onClick={() =>
              append({
                id: uniqueId(),
                vehicleType: "",
                vehicleInfo: "",
                nameOfDriver: "",
                phoneNumberOfDriver: "",
                escortStaff: "",
                shippingCode: "",
                note: "",
                vehicleCapacity: 0,
              })
            }>
            + Thêm xe
          </div>
        </div>
        <NotiExportExcel
          notiExport={notiExport}
          title={t("ORDER")}
          closeForm={() => {
            setNotiExport(false);
            clearInterval(checkExportRef.current);
          }}
          success={success?.isExport === false ? success?.fileName : undefined}
        />
      </div>
      <div className="truckload-footer">
        <button
          // type={checkOrderQuantity ? "button" : "submit"}
          className="btn-cancel-style"
          onClick={() => {
            setOpenTruckLoad({ order: null, type: "order", open: false });
          }}>
          Huỷ
        </button>
        <button
          type="submit"
          form="hook-form"
          className="btn-confirm-style"
          style={{ marginLeft: "2.4rem" }}
          onClick={() => {
            handleSubmit((d) => console.log(d));
          }}>
          Ghép xe
        </button>
      </div>
    </Dialog>
  );
};

export default ModalLessContainer;
