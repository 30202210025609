import GFTypography from "@Components/ui/GFTypography";
import { Tooltip } from "@mui/material";
import React, { PropsWithChildren } from "react";
import "./style.scss";

type GFTooltipProps = PropsWithChildren<{
  title: React.ReactNode | string;
  fontWeight?: 400 | 500 | 600 | 700 | 800 | 900;
  propsStyle?: React.CSSProperties;
}>;

const style = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export default function GFTooltip({
  title,
  children,
  fontWeight,
  propsStyle = {},
}: GFTooltipProps) {
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      className="gf-tooltip"
      placement="top-start"
      classes={{ popper: "gf-tooltip__popper" }}
      title={<GFTypography variant="caption">{title}</GFTypography>}>
      {typeof children === "string" ? (
        <GFTypography style={{ ...style, ...propsStyle }} fontWeight={fontWeight}>
          {children}
        </GFTypography>
      ) : (
        React.cloneElement(children as React.ReactElement, {
          style,
        })
      )}
    </Tooltip>
  );
}
