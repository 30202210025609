import GFModal, { GFModalProps } from "@Components/ui/GFModal";
import GFTypography from "@Components/ui/GFTypography";
import { PriorityHighRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBody = styled(Box)<{ center?: boolean }>(({ center }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: center ? "center" : "flex-start",
  gap: "2rem",
  textAlign: center ? "center" : "left",
}));

const StyledIconWarning = styled(PriorityHighRounded)({
  "&.MuiSvgIcon-root": {
    width: "6rem",
    height: "6rem",
    borderRadius: "50%",
    color: "#fff",
    background: "var(--warning-color)",
  },
});

const createStyledIcon = (type: ModalType) => {
  const backgroundColor = `--${type}-color`;
  return styled(PriorityHighRounded)({
    "&.MuiSvgIcon-root": {
      width: "6rem",
      height: "6rem",
      borderRadius: "50%",
      color: "#fff",
      background: `var(${backgroundColor})`,
    },
  });
};
type ModalType = "warning" | "success" | "error" | "info" | "default";

type GFConfirmModalProps = GFModalProps & {
  textContent?: string;
  type?: ModalType;
};

export default function GFConfirmModal({
  textContent,
  title,
  children,
  showHeaderTitle = false,
  type = "default",
  ...props
}: GFConfirmModalProps) {
  const Icon = createStyledIcon(type);

  return (
    <GFModal
      showHeaderTitle={showHeaderTitle}
      actionsCenter
      width={"50rem"}
      title={title}
      {...props}>
      <StyledBody center>
        {type !== "default" && <Icon />}
        {!showHeaderTitle && <GFTypography variant="h5">{title}</GFTypography>}
        <GFTypography variant="body1">{textContent}</GFTypography>
        {children}
      </StyledBody>
    </GFModal>
  );
}
