/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { Action, FetchAction, ItemPermission, ReportDiscountOutputModel } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { DiscountService } from "@Services";
import { currencyFormat, PermissionUtil } from "@Utils";
import { Search as SearchIcon } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<ReportDiscountOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const Discount: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  // const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  // const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  // const [time, setTime] = useState<string>(
  //   ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + new Date().getFullYear()
  // );
  // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  // const open = Boolean(anchorEl);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await DiscountService.getDiscountReport(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        searchString: searchValue,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  return (
    <div className="discount">
      <div className="discount-content">
        <div className="action">
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Nhập mã, tên khách hàng..."
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            {/* <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button> */}
          </div>
        </div>

        <div className="list">
          <div className="list-content">
            <div className="list-header">
              <div className="customer">{t("CUSTOMER")}</div>
              <div className="bu">{t("order:BUSINESS_UNIT")}</div>
              <div className="division">{t("PRODUCT_CATEGORY")}</div>
              <div className="date">Thời gian cập nhập</div>
              <div className="dis">{t("order:DISCOUNT")}</div>
            </div>
            {state.loading || (state.fetching && searchValue) ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : (
              <>
                {state.data ? (
                  state.data.length > 0 ? (
                    <InfiniteScroll
                      useWindow={false}
                      pageStart={0}
                      initialLoad={false}
                      hasMore={hasMore}
                      loadMore={() =>
                        fetchData({
                          limit: 50,
                          page: state.page + 1,
                          searchString: searchValue,
                        })
                      }>
                      {state.data.map((item: ReportDiscountOutputModel, index: number) => {
                        return (
                          <div key={index} className="item">
                            <div className="customer">
                              {item?.customer?.fullName || TEXT_HOLDER}
                              <div>{item?.customer?.code || TEXT_HOLDER}</div>
                            </div>
                            <div className="bu">{item?.salesOrg.description || TEXT_HOLDER}</div>
                            <div className="division">
                              {item?.division?.description || TEXT_HOLDER}
                            </div>
                            <div className="date">
                              {moment(item?.updatedAt).format("DD/MM/YYYY - HH:mm")}
                            </div>
                            <div className="dis">
                              {checkPermissionViewPrice
                                ? currencyFormat(+item.value)
                                : TEXT_ASTERISK}{" "}
                              đ
                            </div>
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  ) : visibleSearch === true ? (
                    <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                  ) : (
                    <EmptyList img={Img} title={t("EMPTY_LIST")} />
                  )
                ) : (
                  <EmptyList img={Img} title={t("EMPTY_LIST")} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any) => {
                  return (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Year.map((y: any) => {
                  return (
                    <MenuItem
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y.year}>
                      {y.year}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div>
            <button className="btn-cancel-style" onClick={handleClose}>
              Huỷ
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(month + "-" + year);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover> */}
    </div>
  );
};

export default Discount;
