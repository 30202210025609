import Img from "@Assets/images/empty.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import { APP_ROUTES, NavHeaderAccount, TEXT_HOLDER } from "@Constants";
import { AccountModel, Action, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService } from "@Services";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Checkbox, Dialog, InputAdornment, Popover, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import CreateSaleAccount from "./Modal/CreateAcc";
import "./SaleAccount.scss";
import { useTranslation } from "react-i18next";

function reducer(state: PaginateFetchReducer<AccountModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const SaleAccount: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  //   const [range, setRange] = useDateRange();
  const history = useHistory();
  const mounted = useRef(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<any>();
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [cancel, setCancel] = useState<boolean>(false);
  //   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // const handleOpenFieldFilter = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorFilterEl(event.currentTarget);
  // };

  const handleCloseFieldFilter = () => {
    setAnchorFilterEl(null);
  };
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllAccount(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        searchString: searchValue,
        isActive: cancel ? undefined : selectTab,
        isCancel: cancel ? cancel : undefined,
        isEmployee: true,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectTab]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  //submit for filter
  const submitForm = () => {};

  return (
    <div className="sale-account">
      <div className="sale-account-content">
        <div className="action">
          <div className="sale-account__nav-header">
            {NavHeaderAccount.map((o, idx: number) => {
              return (
                <div
                  key={o.id + idx}
                  className={
                    cancel
                      ? o.value === "cancelled"
                        ? "tab tab-active"
                        : "tab tab-hover"
                      : o.value === selectTab
                      ? "tab tab-active"
                      : "tab tab-hover"
                  }
                  onClick={() => {
                    setSelectTab(o.value);
                    if (o.title === "cancelled") {
                      setCancel(true);
                    } else {
                      setCancel(false);
                    }
                  }}>
                  {t(o.label)} <span>{state[`${o.title}`]}</span>
                </div>
              );
            })}
          </div>
          <TextField
            placeholder={t("ENTER_SEARCH")}
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          {/* <div className="filter-ic" onClick={handleOpenFieldFilter}>
          <TuneRoundedIcon />
          Lọc
        </div> */}
        </div>
        {state.loading || (state.fetching && (searchValue || selectTab)) ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {state.data ? (
              state.data.length > 0 ? (
                <div className="list">
                  <div className="list-header">
                    <div className="act">{t("ACCOUNT_NAME")}</div>
                    <div className="role">{t("ROLE")}</div>
                    <div className="pass">{t("LOGIN_CODE")}</div>
                    <div className="email">{t("EMAIL")}</div>
                    <div className="phone">{t("LOGIN_PHONE")}</div>
                    <div className="status">{t("STATUS")}</div>
                  </div>
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        searchString: searchValue,
                        isActive: selectTab,
                        isEmployee: true,
                      })
                    }>
                    {state.data.map((act: AccountModel, index: number) => {
                      return (
                        <div
                          className="item"
                          key={index}
                          onClick={() => {
                            history.push(
                              APP_ROUTES.AUTHORIZE_ACCOUNT_DETAIL.replace(":accountId", act.id)
                            );
                          }}>
                          <div className="act">{act.nickname}</div>
                          <div className="role">{act?.role?.position || TEXT_HOLDER}</div>
                          <div className="pass">{act.code}</div>
                          <div className="email">{act.email}</div>
                          <div className="phone">{act.phoneNumber}</div>
                          <div className="status">
                            {act.cancelled ? (
                              <span style={{ color: "#B8292F" }}>
                                &#9679;&ensp;{t("CANCELLED")}
                              </span>
                            ) : act.isActive === false ? (
                              <span style={{ color: "#E93C3C" }}>&#9679;&ensp;{t("DISABLE")}</span>
                            ) : (
                              <span style={{ color: "#41AD49" }}>&#9679;&ensp;{t("ACTIVE")}</span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>
      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          setOpenAccountModal(true);
        }}
        btns={[]}
      />
      <Dialog fullScreen open={openAccountModal}>
        <CreateSaleAccount closeForm={() => setOpenAccountModal(false)} />
      </Dialog>

      {/* Popup filter */}

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={handleCloseFieldFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Form onSubmit={submitForm}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="filter-listing">
                  <div className="title">Lọc</div>
                  <div onClick={handleCloseFieldFilter}>
                    <CloseIcon
                      style={{
                        fontSize: "2.2rem",
                        position: "absolute",
                        top: "3.3rem",
                        right: "4.7rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="label">Mảng</div>
                  <div className="list-filter">
                    <div className="source">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      {t("ALL")}
                    </div>
                    <div className="source">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      {t("FEED")}
                    </div>
                    <div className="source">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      {t("FARM")}
                    </div>
                    <div className="source">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      {t("SUPPLY_CHAIN")}
                    </div>
                  </div>
                  <div className="label">Vai trò</div>
                  <div className="list-filter">
                    <div className="role">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      Nhân viên
                    </div>
                    <div className="role">
                      <Checkbox
                        className="app-check-box"
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                        checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                      />
                      Quản lý
                    </div>
                  </div>

                  <div className="btn-group">
                    <div
                      className="delete"
                      onClick={() => {
                        // setChecked([]);
                      }}>
                      Xoá lọc
                    </div>
                    <button className="confirm" onClick={handleCloseFieldFilter}>
                      Áp dụng
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </Popover>
    </div>
  );
};

export default SaleAccount;
