import { SourceType } from "./other.model";
import { BaseEntity, Nullable } from "./shared";

export type CustomerOutputModel = {
  id: string;
  code: string;
  email: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  idCard: string | null;
  source: string;
  idIssueDate: string | null;
  idIssuePlace: string | null;
  idCardFront: string | null;
  idCardBack: string | null;
  signature: string | null;
  businessRegistration: string | null;
  company: string | null;
  contactName: string;
  address: string;
  payTerm: string | null;
  createdAt: string;
  updatedAt: string;
  account: {
    id: number;
    avatar: string | null;
    idCardFront: string | null;
    idCardBack: string | null;
    signature: string | null;
  };
  customerDivisions: {
    employees: { employee: { fullName: string }; position: string }[];
    salesOrg: CustomerGroupOutputModel;
    distributionChannel: CustomerGroupOutputModel;
    division: { description: string; id: string };
    customerGroup: { description: string };
    salesOffices: SalesOfficeAccount[];
  }[];
  legals: CustomerGroupOutputModel[];

  camps: {
    id: number;
    code: string;
    name: string;
    source: SourceType;
    createdAt: string;
    updatedAt: string;
  }[];
};

export type AuthCustomerProfileOutputModel = BaseEntity & {
  id: number;
  isAdmin: boolean;
  email: Nullable<string>;
  avatar: Nullable<string>;
  phoneNumber: Nullable<string>;
  isActive: boolean;
  code: string | null;
  fullName: string | null;
  deviceIds: string | null;
  role: string | null;
  permissions: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  position: string | null;
  source: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  company: string | null;
  account: {
    id: number;
    email: Nullable<string>;
    avatar: any;
    phoneNumber: Nullable<string>;
    isActive: boolean;
    code: string;
    password: string;
    deviceIds: [string | null];
    permissions: Nullable<string>;
    isEmployee: true;
    idCardFront: Nullable<string>;
    idCardBack: Nullable<string>;
    signature: Nullable<string>;
    createdAt: Nullable<string>;
    updatedAt: Nullable<string>;
    cancelled: boolean;
    policyAccepted: boolean;
  };
  customerDivisionEmployee: Nullable<string>;
};

export type CustomerGroupOutputModel = {
  id: number;
  code: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  source: SourceType;
  name: string;
};

export type CustomerModel = BaseEntity & {
  id: number;
  email: Nullable<string>;
  code: string;
  phoneNumber: Nullable<string>;
  isActive: boolean;
  fullName: string;
  idCard: string;
  customers: any;
  source: string;
  idIssueDate: string;
  idIssuePlace: string;
  businessRegistration: Nullable<string>;
  company: Nullable<string>;
  contactName: Nullable<string>;
  address: string;
  payTerm: Nullable<string>;
  createdAt: string;
  updatedAt: string;
};

//account
export type AccountModel = {
  email: string;
  id: string;
  phoneNumber: string;
  nickname: string | null;
  isActive: boolean;
  cancelled: boolean;
  code: string;
  avatar: null;
  requireOTP: boolean;
  accessLogs: DeviceTypeAccount[];
  customers: CustomerAccount[];
  employees: any[];
  role: RoleAccount;
  policyAccepted?: boolean;
};

export type AccessLogAccount = {
  id: number;
  ip: DeviceTypeAccount | null;
  status: boolean;
  DeviceTypeAccount: DeviceTypeAccount | null;
  type: Type;
  actor: number;
  metadata: DeviceTypeAccount | null;
  createdAt: string;
  updatedAt: string;
};

export type itemGroupTypeFarm = {
  id: number;
  code: string;
  name: string;
  otherName: string;
  source: string;
  image: string;
  codePoint: string | null;
  createdAt: string;
  updatedAt: string;
};

export enum DeviceTypeAccount {
  String = "string",
}

export enum Type {
  Login = "login",
  SignUp = "sign-up",
}

export type CustomerAccount = {
  id: number;
  email: null | string;
  code: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  idCard: string;
  source: string;
  idIssueDate: string;
  idIssuePlace: string;
  businessRegistration: string;
  company: null | string;
  contactName: string;
  address: string;
  payTerm: string;
  createdAt: string;
  updatedAt: string;
  camps: CampsAccount[];
  customerDivisions: CustomerDivisionAccount[];
};

export type RoleAccount = {
  id: number;
  position: string;
  permissions: string[];
  description: string;
  fixed: boolean;
  roleType: string;
  createdAt: string;
  updatedAt: string;
};
export type CampsAccount = {
  id: number;
  code: string;
  name: string;
  phoneNumber: null;
  idCard: null;
  idIssueDate: null;
  idIssuePlace: null;
  businessRegistration: null;
  company: null;
  contactName: null;
  address: null;
  payTerm: null;
  source: string;
  createdAt: string;
  updatedAt: string;
};

export type CustomerDivisionAccount = {
  id: number;
  code: null;
  description: null;
  deliveryPlant: null;
  createdAt: Date;
  updatedAt: Date;
  salesOffices: SalesOfficeAccount[];
};

export type SalesOfficeAccount = {
  id: number;
  code: number;
  source: string;
  description: string;
  slugSearch: string;
  createdAt: Date;
  updatedAt: Date;
};

export type LeadModal = {
  id: number;
  email: string;
  phoneNumber: string;
  isActive: boolean;
  fullName: string;
  address: string | null;
  sources: string[];
  code: string;
  creator: { name: string | null; code: string | null };
  customer: { fullName: string | null; code: string | null };
  salesOrg: {
    id: number;
    code: string | null;
    description: string | null;
    source: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  };
  taxId: string | null;
  idCard: string | null;
  idCardFront: string;
  idCardBack: string;
  idIssueDate: string | null;
  idIssuePlace: string | null;
  province: string | null;
  district: string | null;
  ward: string | null;
  note: string | null;
  company: string | null;
  status: string | null;
  createdAt: string;
  updatedAt: string;
};

export type SubAccountModel = {
  id: number;
  nickname: string;
  phoneNumber: string;
  email: string;
  requireOTP: boolean;
  code: string;
  createdAt: Date;
  camps: null;
  role: RoleAccount;
  saleOffices: null;
  status: string;
  accessLogs: null;
  avatar: null;
  cancelled: null;
  deviceIds: null;
  employee: any[];
  idCardBack: null;
  idCardFront: null;
  isActive: boolean;
  isEmployee: boolean;
  notification: number;
  customers: any[];
  parentAccount: ParentAccount;
  policyAccepted?: boolean;
};

export type ParentAccount = {
  id: number;
  nickname: string;
  code: string;
};

export type SubAccountDetailModel = {
  id: number;
  nickname: string;
  phoneNumber: string;
  email: string;
  requireOTP: boolean;
  code: string;
  createdAt: string;
  camps: CampsAccount[];
  role: RoleAccount;
  saleOffices: SalesOfficeAccount[];
  status: string;
  accessLogs: any;
  avatar: any;
  cancelled: any;
  deviceIds: any;
  employee: any[];
  idCardBack: any;
  idCardFront: any;
  isActive: boolean;
  isEmployee: boolean;
  notification: number;
  customers: CustomerAccount[];
  parentAccount: {
    id: number;
    nickname: string;
    code: string;
  };
  policyAccepted: boolean;
  permissionNote: string;
};

//input
export type SubAccountInputModel = {
  customerAccountId: number;
  isDraft: boolean;
  requestEmail: string;
  requestPhoneNumber: string;
  requestFullName: string;
  requestSaleOfficeIds: number[];
  requestRoleId: number;
  requestCamps: {
    source: string;
    campId: number | null;
    isActive?: boolean;
  }[];
  code?: string;
  status?: string;
  permissionNote: string;
};

export enum LogType {
  Login = "login",
  SignUp = "sign-up",
}
