import { IconBase, IconComponent } from "@Core/Icons";

export const UserIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M8.16732 11.0846C9.45598 11.0846 10.5007 10.04 10.5007 8.7513C10.5007 7.46264 9.45598 6.41797 8.16732 6.41797C6.87865 6.41797 5.83398 7.46264 5.83398 8.7513C5.83398 10.04 6.87865 11.0846 8.16732 11.0846Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5006 11.668C19.4336 11.668 21.0006 10.101 21.0006 8.16797C21.0006 6.23497 19.4336 4.66797 17.5006 4.66797C15.5676 4.66797 14.0006 6.23497 14.0006 8.16797C14.0006 10.101 15.5676 11.668 17.5006 11.668Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75065 14.6318C7.7865 14.5092 6.80726 14.6334 5.90324 14.9928C4.99923 15.3522 4.19954 15.9352 3.57795 16.6882C2.95635 17.4412 2.53286 18.3398 2.34653 19.3012C2.32399 19.44 2.33189 19.5821 2.36967 19.7175C2.40745 19.8528 2.4742 19.9782 2.56522 20.0848C2.65624 20.1913 2.76931 20.2765 2.89649 20.3343C3.02367 20.392 3.16187 20.421 3.30136 20.4192H6.74486"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5007 15.5195C15.5182 15.5196 13.6021 16.1973 12.1038 17.4284C10.6055 18.6594 9.62531 20.3613 9.34299 22.222C9.32355 22.3609 9.33567 22.5022 9.37853 22.6363C9.4214 22.7703 9.49402 22.8942 9.59151 22.9994C9.68901 23.1047 9.80912 23.189 9.94379 23.2466C10.0785 23.3042 10.2246 23.3338 10.3723 23.3335H24.6291C24.7767 23.3338 24.9228 23.3042 25.0574 23.2467C25.192 23.1891 25.3121 23.1049 25.4095 22.9997C25.507 22.8945 25.5796 22.7708 25.6226 22.6368C25.6655 22.5028 25.6777 22.3617 25.6584 22.2228C25.3762 20.362 24.3962 18.6599 22.8978 17.4286C21.3995 16.1974 19.4833 15.5196 17.5007 15.5195Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
