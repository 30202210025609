/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty.png";
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { DebtHeader, Month, sumRange, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
  Action,
  DebtOutputModel,
  DivisionModal,
  FetchAction,
  ItemPermission,
  ReportPermission,
  SalesOrgModal,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import { CustomerService, ExportService, ProductService, SalesOrgService } from "@Services";
import { currencyFormat, PermissionUtil, YearList } from "@Utils";
import _ from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<DebtOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const DebtReport: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const checkExportRef = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const mounted = useRef(false);
  const [month, setMonth] = useState<string>(("0" + (new Date().getMonth() + 1)).slice(-2));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [time, setTime] = useState<string>(
    new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2)
  );
  const [salesOrg, setSalesOrg] = useState<SalesOrgModal[]>([]);
  const [division, setDivision] = useState<DivisionModal[]>([]);
  const [checkedDivision, setCheckedDivision] = useState<any>([]);
  const [checkedSalesOrg, setCheckedSalesOrg] = useState<any>([]);
  const [checkedSalesOrgDup, setCheckedSalesOrgDup] = useState<any>([]);
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [notiExport, setNotiExport] = useState<boolean>(false);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //get sales org
  useEffect(() => {
    const fetchData = async () => {
      SalesOrgService.getAllSalesOrg({
        sources: sourceType,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setSalesOrg(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [sourceType]);

  //get division
  useEffect(() => {
    const fetchData = async () => {
      ProductService.getDivisionBySource({
        source: sourceType,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setDivision(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [sourceType]);

  const dataSalesOrg = [...checkedSalesOrg];
  const dataDivision = [...checkedDivision];

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getAllDebtReport(query);
      if (isReset) {
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    sourceType &&
      fetchData(
        {
          page: 1,
          limit: 50,
          source: sourceType,
          period: time,
          searchString: searchValue,
          salesOrgIds: dataSalesOrg.length > 0 ? dataSalesOrg : undefined,
          divisionIds: dataDivision.length > 0 ? dataDivision : undefined,
        },
        true
      );

    setCheckedSalesOrgDup(dataSalesOrg);

    // eslint-disable-next-line
  }, [sourceType, searchValue, time, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 1000);

  //submit for filter
  const submitForm = () => {
    setCheckedSalesOrgDup(dataSalesOrg);
    fetchData(
      {
        page: 1,
        limit: 50,
        source: sourceType,
        period: time,
        searchString: searchValue,
        ...(sourceType !== SourceType.VET && {
          salesOrgIds: dataSalesOrg.length > 0 ? dataSalesOrg : undefined,
          divisionIds: dataDivision.length > 0 ? dataDivision : undefined,
        }),
      },
      true
    );
  };

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "debt",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, time, state.loading, success?.isExport === !isExport]);

  const exportDebt = async () => {
    ExportService.getExportDebt({
      period: time,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [ReportPermission.MANAGE_REPORT_DEBT_EXPORT],
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <div className="debt-listing">
      <div className="content">
        <div className="header">
          <div className="left">
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className="search-style"
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" className="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tháng {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>
            <div
              className="filter-ic"
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                setAnchorFilterEl(event.currentTarget)
              }>
              <TuneRoundedIcon />
              Chọn lọc
            </div>
            {!isExport && checkPermissionExportExcel && (
              <button
                className="btn-excel"
                onClick={() => {
                  exportDebt();
                  setNotiExport(true);
                  setSuccess(undefined);
                }}>
                <img src={Excel} alt="icon-excel" />
                {t("EXPORT_DATA")}
              </button>
            )}
          </div>
          {/* <div className="right">
            <div className="total-prod">
              {state?.totalSurplus ? currencyFormat(state.totalSurplus) : TEXT_HOLDER} đ
              <div className="text">Tổng số dư khách hàng</div>
            </div>
            <div className="total-value">
              {state?.totalOutOfDate ? currencyFormat(state.totalOutOfDate) : TEXT_HOLDER} đ
              <div className="text">Tổng công nợ quá hạn</div>
            </div>
          </div> */}
        </div>
        {state.loading || (state.fetching && (dataSalesOrg || dataDivision)) ? (
          <Loading style={{ margin: "2rem 0", height: "60vh" }} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {state.data ? (
              state.data.length > 0 ? (
                <div className="list">
                  <div className="list-header">
                    {DebtHeader.map((lb: any, idx) => {
                      return (
                        <div className="label" key={idx}>
                          {lb.label}
                        </div>
                      );
                    })}
                  </div>
                  <InfiniteScroll
                    useWindow={false}
                    pageStart={0}
                    initialLoad={false}
                    hasMore={hasMore}
                    loadMore={() =>
                      fetchData({
                        limit: 50,
                        page: state.page + 1,
                        period: time,
                        source: sourceType,
                        searchString: searchValue,
                        salesOrgIds: dataSalesOrg.length > 0 ? dataSalesOrg : undefined,
                        divisionIds: dataDivision.length > 0 ? dataDivision : undefined,
                      })
                    }>
                    {state.data.map((debt: any) => {
                      return debt.periodReportDebts.map((per: any, perIndex: number) => {
                        return (
                          <div className="item" key={perIndex}>
                            <div className="item-list">
                              <div className="label">
                                {per.salesOrg?.description || TEXT_HOLDER}
                              </div>
                              <div className="label">
                                {per.division?.description || TEXT_HOLDER}
                              </div>
                              <div className="label">{debt.customer.fullName}</div>
                              <div className="label">{debt.customer.code}</div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? per.limit
                                    ? currencyFormat(parseFloat(per.limit))
                                    : TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &#160;đ
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? per.surplus
                                    ? currencyFormat(parseFloat(per.surplus))
                                    : TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &#160;đ
                              </div>
                              <div className="label">
                                {checkPermissionViewPrice
                                  ? per.outOfDate
                                    ? currencyFormat(parseFloat(per.outOfDate))
                                    : TEXT_HOLDER
                                  : TEXT_ASTERISK}
                                &#160;đ
                              </div>
                              {sumRange.map((sum, idx) => {
                                return (
                                  <div key={idx} className="label">
                                    {checkPermissionViewPrice
                                      ? per[sum.label]
                                        ? currencyFormat(per[sum.label])
                                        : TEXT_HOLDER
                                      : TEXT_ASTERISK}
                                    &#160;đ
                                  </div>
                                );
                              })}
                              <div className="label">{per.salesMan.fullName}</div>
                              <div className="label">{per.salesAss.fullName}</div>
                              <div className="label">{per.salesSup.fullName}</div>
                              <div className="label">{per.salesRef.fullName}</div>
                            </div>
                          </div>
                        );
                      });
                    })}
                  </InfiniteScroll>
                </div>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
              ) : (
                <EmptyList img={Img} title={t("EMPTY_LIST")} />
              )
            ) : (
              <EmptyList img={Img} title={t("EMPTY_LIST")} />
            )}
          </>
        )}
      </div>
      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
          setCheckedSalesOrg(checkedSalesOrgDup);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Form onSubmit={submitForm}>
          {({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="filter-listing">
                  <div className="title">Lọc</div>
                  <div
                    onClick={() => {
                      setAnchorFilterEl(null);
                      setCheckedSalesOrg(checkedSalesOrgDup);
                    }}>
                    <CloseIcon
                      style={{
                        fontSize: "2.2rem",
                        position: "absolute",
                        top: "3.3rem",
                        right: "4.7rem",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <div className="label">{t("BUSINESS_ORGANIZATION")}</div>
                  <Checkbox
                    className="app-check-box"
                    checked={salesOrg && dataSalesOrg.length === salesOrg.length}
                    onChange={(e, value) => {
                      if (value === true) {
                        dataSalesOrg.push(...(salesOrg.map((a: any) => a.id) || []));
                        Array.from(new Set(dataSalesOrg));
                        setCheckedSalesOrg(Array.from(new Set(dataSalesOrg)));
                      } else {
                        setCheckedSalesOrg([]);
                      }
                    }}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        style={{
                          fontSize: "2.2rem",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon
                        style={{
                          fontSize: "2.2rem",
                        }}
                      />
                    }
                  />
                  <span className="text">{t("ALL")}</span>
                  {salesOrg.map((s: any, saleIndex: number) => {
                    return (
                      <div className="list-filter" key={saleIndex}>
                        <Checkbox
                          className="app-check-box"
                          value={s.id}
                          checked={dataSalesOrg.includes(s.id)}
                          onChange={(e, value) => {
                            if (value === true) {
                              dataSalesOrg.push(s.id);
                              setCheckedSalesOrg(dataSalesOrg);
                            } else {
                              setCheckedSalesOrg(
                                dataSalesOrg.filter((id) => {
                                  return id !== s.id;
                                })
                              );
                            }
                          }}
                          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "2.2rem" }} />}
                          checkedIcon={<CheckBoxIcon style={{ fontSize: "2.2rem" }} />}
                        />
                        <div className="text">{s?.description}</div>
                      </div>
                    );
                  })}
                  <div className="label">{t("PRODUCT_CATEGORY")}</div>
                  <Checkbox
                    className="app-check-box"
                    checked={division && dataDivision.length === division.length}
                    onChange={(e, value) => {
                      if (value === true) {
                        dataDivision.push(...(division.map((a: any) => a.id) || []));
                        Array.from(new Set(dataDivision));
                        setCheckedDivision(Array.from(new Set(dataDivision)));
                      } else {
                        setCheckedDivision([]);
                      }
                    }}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        style={{
                          fontSize: "2.2rem",
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon
                        style={{
                          fontSize: "2.2rem",
                        }}
                      />
                    }
                  />
                  <span className="text">{t("ALL")}</span>
                  {division.map((d: any, diviIndex: number) => {
                    return (
                      <div className="list-filter" key={diviIndex}>
                        <Checkbox
                          className="app-check-box"
                          value={d.id}
                          checked={dataDivision.includes(d.id)}
                          onChange={(e, value) => {
                            if (value === true) {
                              dataDivision.push(d.id);
                              setCheckedDivision(dataDivision);
                            } else {
                              setCheckedDivision(
                                dataDivision.filter((id) => {
                                  return id !== d.id;
                                })
                              );
                            }
                          }}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{
                                fontSize: "2.2rem",
                                //
                              }}
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon
                              style={{
                                fontSize: "2.2rem",
                                //
                              }}
                            />
                          }
                        />
                        <div className="text">{d?.description}</div>
                      </div>
                    );
                  })}
                  <div className="btn-group">
                    <div
                      className="delete"
                      onClick={() => {
                        setCheckedSalesOrg([]);
                        setCheckedDivision([]);
                      }}>
                      Xoá lọc
                    </div>
                    <button
                      className="confirm"
                      onClick={() => {
                        setAnchorFilterEl(null);
                        setCheckedSalesOrgDup(dataSalesOrg);
                      }}>
                      Áp dụng
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </Popover>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {Month.map((d: any, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={d.month}>
                      {d.month}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, yearIdx: any) => {
                  return (
                    <MenuItem
                      key={yearIdx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime(year + "-" + month);
                handleClose();
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>

      <NotiExportExcel
        notiExport={notiExport}
        title="Báo cáo công nợ"
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default DebtReport;
