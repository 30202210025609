import { RequestUtil } from "@Utils";

export const FeedBackService = {
  createFeedBack: (orderId: string, feedbackId: string, data: { description: string }) => {
    const url = `order/${orderId}/feedback/${feedbackId}/replies`;
    return RequestUtil.post({ url, data });
  },

  updateFeedBack: (
    orderId: string,
    feedbackId: string,
    replyId: string,
    data: { description: string; isDelete?: string }
  ) => {
    const url = `order/${orderId}/feedback/${feedbackId}/replies/${replyId}`;
    return RequestUtil.put({ url, data });
  },
};
