import Avata from "@Assets/images/avt.png";
import { DebtIcon } from "@Components/Icons/DebtIcon";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { CustomerOutputModel, CustomerPermission, DebtOutputModel, ItemPermission } from "@Models";
import { Nullable } from "@Models/shared";
import { CustomerService } from "@Services";
import { PermissionUtil, checkLinkImage, currencyFormat } from "@Utils";
import {
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  ReceiptLongOutlined as ReceiptLongOutlinedIcon,
} from "@mui/icons-material";
import { Dialog, Divider, Grid } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CustomerDetail.scss";
import CustomerDebtDetail from "./Debt/CustomerDebtDetail";
import CustomerOrderDetail from "./Order/CustomerOrderDetail";
import CustomerProfile from "./Profile/CustomerProfile";
import { useTranslation } from "react-i18next";

type P_Props = {
  className?: string;
  style?: React.CSSProperties;
  activeStep?: number;
  sourceType: string;
};

const NavHeaderCustomer = [
  {
    label: "PROFILE",
    id: "0",
    icon: <PersonOutlineOutlinedIcon style={{ fontSize: "2.2rem", fontWeight: "bold" }} />,
    permission: CustomerPermission.MANAGE_CUS_VIEW_INFORMATION_DETAILS,
  },
  {
    label: "ORDER",
    id: "1",
    icon: <ReceiptLongOutlinedIcon style={{ fontSize: "2.2rem", fontWeight: "bold" }} />,
    permission: CustomerPermission.MANAGE_CUS_ORDER_LIST,
  },
  {
    label: "DEBT",
    id: "2",
    icon: <DebtIcon color="#525560" size={[22, 22]} viewBox={[28, 28]} />,
    permission: CustomerPermission.MANAGE_CUS_DEBT,
  },
  // {
  //   label: "Sản phẩm quan tâm",
  //   id: "",
  //   icon: <FavoriteBorderIcon style={{ fontSize: "2.2rem", fontWeight: "bold" }} />,
  // },
];

const CustomerDetail: React.FC<P_Props> = ({ className, style, sourceType }) => {
  const { t } = useTranslation(["translation", "customer"]);
  const location = useLocation<any>();
  const history = useHistory();
  const mounted = useRef(false);
  const { customerId }: { customerId: string } = useParams();
  const classes = clsx({
    "nav-header-list": true,
    [className || ""]: Boolean(className),
  });
  const [customer, setCustomer] = useState<Nullable<CustomerOutputModel>>(null);
  const [header, setHeader] = useState<string>("0");
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [debt, setDebt] = useState<DebtOutputModel | null>(null);

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const getCustomerById: () => Promise<void> = async () => {
    try {
      const response = await CustomerService.getCustomerById(customerId);
      if (mounted.current) {
        setCustomer(() => response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDebtCustomerById: () => Promise<void> = async () => {
    try {
      const response = await CustomerService.getDebtCustomerById({
        page: 1,
        limit: 500,
        source: sourceType,
        customerId: customerId,
      });
      if (mounted.current) {
        setDebt(() => response.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    getCustomerById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDebtCustomerById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customer]);

  const avtImageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = Avata;
    event.currentTarget.className = "error";
  };

  //set lại giá trị đầu tiên và set lại state khi filter lịch và qua trang chi tiết
  useEffect(() => {
    !!location.state?.header
      ? setHeader(location.state?.header)
      : setHeader(
          NavHeaderCustomer.filter(
            (header) => profile?.role?.permissions.includes(header.permission) === true
          )[0]?.id
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermissionOrderDetail = PermissionUtil.check(
    profile?.role?.permissions,
    CustomerPermission.MANAGE_CUS_VIEW_INFORMATION_DETAILS,
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={closeForm}>
      <div className="customer-detail">
        <SimpleModalHeader
          title={t("customer:CUSTOMER_DETAIL")}
          onClose={() => {
            setCloseForm(false);
            history.push(APP_ROUTES.CUSTOMER + "-" + sourceType);
          }}
        />
        <div className="nav-header">
          <Grid container className="header">
            <Grid item container xs={4} alignItems="center" className="left">
              <img
                src={checkLinkImage(customer?.account?.avatar as any) || Avata}
                alt="Avata"
                onError={avtImageOnErrorHandler}
              />
              <div>
                <div className="name">
                  {(checkPermissionOrderDetail
                    ? customer?.fullName + " - " + (customer?.code || TEXT_HOLDER)
                    : location.state.fullName) || TEXT_HOLDER}
                </div>
                {(checkPermissionOrderDetail
                  ? customer?.phoneNumber
                  : location.state.phoneNumber) || TEXT_HOLDER}
              </div>
            </Grid>
            <Grid item container xs={4} className="center">
              {/* <Grid item xs={3}>
                {TEXT_HOLDER}
                <div className="label">Đang xác nhận</div>
              </Grid>
              <Grid item xs={3}>
                {TEXT_HOLDER}
                <div className="label">Chưa hoàn thành</div>
              </Grid>
              <Grid item xs={3}>
                {TEXT_HOLDER}
                <div className="label"> Hoàn thành</div>
              </Grid>
              <Grid item xs={3}>
                {TEXT_HOLDER}
                <div className="label">Đã Hủy</div>
              </Grid> */}
            </Grid>
            <Grid item container xs={4} justifyContent="space-between">
              <Divider orientation="vertical" />
              <div className="right">
                <div style={{ display: "flex" }}>
                  <div>
                    {checkPermissionViewPrice
                      ? debt?.customer?.totalDebt
                        ? currencyFormat(+debt?.customer.totalDebt)
                        : TEXT_HOLDER
                      : TEXT_ASTERISK}{" "}
                    đ<div className="label">{t("DEBT")}</div>
                  </div>
                  <div className="text-color-red">
                    {checkPermissionViewPrice
                      ? debt?.customer?.totalOverdueDebt
                        ? currencyFormat(+debt?.customer.totalOverdueDebt)
                        : TEXT_HOLDER
                      : TEXT_ASTERISK}{" "}
                    đ<div className="label">{t("customer:OVERDUE_DEBT")}</div>
                  </div>
                </div>
                <div style={{ textAlign: "end", fontSize: "1rem", color: "#5A5959" }}>
                  {t("customer:LAST_UPDATED")}{" "}
                  {debt?.customer?.lastUpdated
                    ? moment(debt?.customer?.lastUpdated).format("HH:mm - DD/MM/YYYY")
                    : TEXT_HOLDER}
                </div>
              </div>
            </Grid>
          </Grid>
          <div className={classes} style={style}>
            {NavHeaderCustomer.map((step) => {
              const isActive = header === step.id;
              if (!profile?.role?.permissions.includes(step.permission)) return null;
              return (
                <div
                  className={isActive ? "tab-green-style" : "item"}
                  key={step.id}
                  onClick={() => {
                    setHeader(step.id);
                    //history.push(step.path.replace(":customerId", customerId));
                  }}>
                  {step.icon}
                  <span style={{ marginLeft: "0.8rem" }}>{t(step.label)}</span>
                </div>
              );
            })}
          </div>
        </div>
        {header === "0" && <CustomerProfile />}
        {header === "1" && (
          <CustomerOrderDetail
            source={location?.state?.source !== undefined ? location?.state?.source : sourceType}
          />
        )}
        {header === "2" && <CustomerDebtDetail source={sourceType} />}
      </div>
    </Dialog>
  );
};

export default CustomerDetail;
