import React from "react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import HomePage from "@Assets/images/mock/home-page.jpg";
// import HomePage2 from "@Assets/images/mock/home-page-2.jpg";
import HomePage3 from "@Assets/images/mock/banner_8_12_23.png";
import "./index.scss";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

type P_Props = {};

const Home: React.FC<P_Props> = () => {
  return (
    <div className="homepage">
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}>
        <SwiperSlide>
          <div className="homepage_container">
            <img src={HomePage3} alt="hb" />
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="homepage_container">
            <img src={HomePage2} alt="hb2" />
          </div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default Home;
