/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import FloatingBtn from "@Components/FloatingBtn";
import { ChildOrderIcon } from "@Components/Icons/ChildOrderIcon";
import { OrderGroupIcon } from "@Components/Icons/OrderGroupIcon";
import { WeightIcon } from "@Components/Icons/WeightIcon";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import RangePicker from "@Components/RangePicker";
import {
  APP_ROUTES,
  NavHeaderOrder,
  NavHeaderOrderFarm,
  NavHeaderOrderLab,
  OrderStatusERP,
  StatusOrder,
  TEXT_ASTERISK,
  TEXT_HOLDER,
  TIME,
} from "@Constants";
import BoarSemen from "@Containers/Order/components/BoarSemen";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import useDateRange from "@Hooks/useDateRange";
import useGetMe from "@Hooks/useGetMe";
import {
  Action,
  FetchAction,
  ItemGroupType,
  ItemPermission,
  OrderOutputModel,
  OrderPermission,
  OrderStatus,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { ExportService, OrderService } from "@Services";
import {
  PermissionUtil,
  StatusUIUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
import { InputAdornment, TextField } from "@material-ui/core";
import { Autorenew as AutorenewIcon, Search as SearchIcon } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Checkbox, Chip, Collapse, Grid, MenuItem, Select, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import _, { get } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router";
import { FarmType, PigTypeCode } from "src/types";
import { DEFAULT_SIZE } from "../Authorize/Role";
import "./index.scss";

type P_Props = {
  sourceType: string;
};

function reducer(state: PaginateFetchReducer<OrderOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const OrderInternalListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "order"]);
  const [moreInfo, setMoreInfo] = useState<string[]>([]);
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "#312F30",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });
  const checkExportRef = useRef<any>(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleFloatingBtn, setVisibleFloatingBtn] = useState<boolean>(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<string>("all");
  const [selectSubStatus, setSelectSubStatus] = useState<string>("all");
  const [synchronized, setSynchronized] = useState<boolean>(false);
  const [checkedSource, setCheckedSource] = useState<string[]>([]);
  const mounted = useRef(false);
  const location = useLocation<any>();
  const [getOrder, setGetOrder] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const [filterOrder, setFilterOrder] = useState<"G" | "D">("G");
  const searchQuery = new URLSearchParams(location.search);

  const toggleFloatingBtn = () => {
    setVisibleFloatingBtn(!visibleFloatingBtn);
  };

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    count: {},
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Cheat
  useEffect(() => {
    if (!searchQuery.get("itemGroupTypeId") && sourceType === "farm") {
      history.push(`${location.pathname}?itemGroupTypeId=8`);
    }
  }, [searchQuery]);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await OrderService.filterOrders({ ...query, groupType: "FMS_ORDER" });
      if (isReset) {
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      } else if (mounted.current) {
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
      }
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    setGetOrder(true);
    if (location.state?.startTime !== undefined) {
      setRange(moment(location.state?.startTime), moment(location.state?.endTime));
    } else {
      setRange(
        moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
        moment().set({ hours: 23, minutes: 59, seconds: 59 })
      );
    }
  }, [location, profile]);

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    getOrder &&
      range.startTime.toISOString() !== null &&
      range.endTime.toISOString() !== null &&
      fetchData(
        {
          itemGroupTypeId: searchQuery.get("itemGroupTypeId"),
          page: 1,
          limit: 50,
          from: range.startTime.toISOString(),
          to: range.endTime.toISOString(),
          strSearch: searchValue,
          source: sourceType,
          status: selectTab,
          subStatus: selectSubStatus,
          view: filterOrder === "G" ? "group" : "allocate",
        },
        true
      );
  }, [
    range.startTime.toISOString(),
    range.endTime.toISOString(),
    searchValue,
    sourceType,
    selectTab,
    selectSubStatus,
    success?.isExport === !isExport,
    synchronized,
    filterOrder,
  ]);

  const getGroupType = () => {
    const pathNameFull = location.pathname;
    switch (pathNameFull) {
      case APP_ROUTES.ORDER_FARM:
        return ItemGroupType.BREEDING_PIGS;
      case APP_ROUTES.ORDER_FARM_BOAR_SEMEN:
        return ItemGroupType.BOAR_SEMEN;
      case APP_ROUTES.ORDER_FARM_MARKET_PIGS:
        return ItemGroupType.MARKET_PIGS;
      case APP_ROUTES.ORDER_FARM_CULLED_PIGS:
        return ItemGroupType.CULLED_PIGS;
      case APP_ROUTES.ORDER_FARM_INTERNAL_PIGS:
        return ItemGroupType.INTERNAL_PIGS;
    }
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const checkSource = sourceType === SourceType.FARM;
  const checkSourceLab = sourceType === SourceType.LAB;

  const recallOrderList = () => {
    OrderService.recallOrderList({
      orderGroupIds: checkedSource,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res.data.message === "ONE PART") {
            enqueueSnackbar(t("order:PARTIAL_ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else if (res.data.message === "SUCCESS") {
            enqueueSnackbar(t("order:ERP_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
              variant: "error",
            });
          }
          setCheckedSource([]);
          setSynchronized(false);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    location.state?.status !== undefined
      ? setSelectTab(location.state?.status)
      : setSelectTab("all");
  }, []);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "order",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const { comparePigType } = useGetMe();

  const currentPigTypeId = searchQuery.get("itemGroupTypeId");

  const isBoarSemen = comparePigType(currentPigTypeId, PigTypeCode.BoarSemen);
  const handleToCreate = () => {
    if (isBoarSemen) {
      history.push(
        `${APP_ROUTES.CREATE_BOAR_SEMEN_ORDER}?itemGroupType=${currentPigTypeId}&type=${FarmType.Internal}`
      );
      return;
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
  }, [sourceType, selectSubStatus, state.loading, success?.isExport === !isExport]);

  const exportOrder = async () => {
    ExportService.exportOrder({
      // from:
      //   moment(range.startTime).diff(range.endTime, "days") > -31
      //     ? range.startTime.toISOString()
      //     : moment(range.endTime).subtract(31, "days").toISOString(),
      from: range.startTime.toISOString(),
      to: range.endTime.toISOString(),
      source: sourceType,
      strSearch: searchValue,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
          // do something
        }
      })
      .catch((err) => {});
  };

  const exportOrderFarm = async () => {
    try {
      const res = await ExportService.exportOrderFarm({
        from:
          moment(range.startTime).diff(range.endTime, "days") > -31
            ? range.startTime.toISOString()
            : moment(range.endTime).subtract(31, "days").toISOString(),
        to: range.endTime.toISOString(),
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setIsExport(res.data.isExportFarm);
        checkExportRef.current = setInterval(() => {
          onCheckExport();
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //permission
  const checkPermissionOrder = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_RECALL,
    true
  );

  const checkPermissionOrderDetail = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_VIEWDETAILS,
    true
  );

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_EXPORT,
    true
  );

  const checkPermissionOrderCreate = PermissionUtil.check(
    profile?.role?.permissions,
    OrderPermission.MANAGE_ORDER_ADD,
    true
  );

  const handleLoadMore = () => {
    fetchData({
      limit: 50,
      page: state.page + 1,
      from: range.startTime.toISOString(),
      to: range.endTime.toISOString(),
      strSearch: searchValue,
      source: sourceType,
      status: selectTab,
      subStatus: selectSubStatus,
      view: filterOrder === "G" ? "group" : "allocate",
      itemGroupTypeId: sourceType === "farm" ? searchQuery.get("itemGroupTypeId") : null,
    });
  };

  return (
    <>
      {state.fetching && (
        <div
          style={{
            position: "absolute",
            width: "calc(100vw - 100px)",
            height: "100vh",
            opacity: 0,
            top: 0,
          }}></div>
      )}
      <div className="marketing-employee">
        <div className="marketing-employee-content">
          <div className="marketing-employee__header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                placeholder={t("SEARCH_CUSTOMER_CODE_NAME_PHONE")}
                variant="outlined"
                className="search-style"
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" className="icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <RangePicker
                startDate={
                  location.state?.startTime !== undefined
                    ? moment(location.state?.startTime)
                    : range.startTime
                }
                endDate={
                  location.state?.endTime !== undefined
                    ? moment(location.state?.endTime)
                    : range.endTime
                }
                onChangeDate={(val) => {
                  if (val && val.length) {
                    setRange(
                      moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                      moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                    );
                  }
                }}
              />

              {checkSource && (
                <>
                  <div
                    className={filterOrder === "G" ? "btn-white" : "btn-white btn-white__hide"}
                    style={{ marginLeft: "1.6rem" }}
                    onClick={() => setFilterOrder("G")}>
                    <OrderGroupIcon size={[20, 12]} viewBox={[20, 12]} color={"#000"} />
                  </div>
                  <div
                    className={filterOrder === "D" ? "btn-white" : "btn-white btn-white__hide"}
                    onClick={() => setFilterOrder("D")}>
                    <ChildOrderIcon size={[20, 12]} viewBox={[20, 12]} color={"#000"} />
                  </div>
                </>
              )}
            </div>

            <div style={{ display: "flex" }}>
              {sourceType === SourceType.FEED && checkPermissionOrder === true && (
                <>
                  {synchronized === false ? (
                    <button
                      className="btn-confirm-style"
                      onClick={() => {
                        setSynchronized(true);
                      }}>
                      <AutorenewIcon
                        style={{
                          fontSize: "3rem",
                          transform: "rotate(45deg)",
                          cursor: "pointer",
                          marginRight: "1.2rem",
                        }}
                      />
                      {t("order:ERP_SYNC")}
                    </button>
                  ) : (
                    <div className="synchronized">
                      <button
                        className="btn-confirm-style btn-cnc btn__text-cancel"
                        onClick={() => {
                          setSynchronized(false);
                        }}>
                        {t("CANCEL")}
                      </button>
                      <button
                        className="btn-confirm-style"
                        onClick={() => {
                          recallOrderList();
                        }}>
                        {t("order:CONFIRM_SYNC")}
                      </button>
                    </div>
                  )}
                </>
              )}
              {!isExport && checkPermissionExportExcel && synchronized === false && (
                <button
                  className="btn-excel"
                  onClick={() => {
                    sourceType === SourceType.FARM ? exportOrderFarm() : exportOrder();
                    setNotiExport(true);
                    setSuccess(undefined);
                  }}>
                  <img src={Excel} alt="icon-excel" />
                  {t("EXPORT_DATA")}
                </button>
              )}
            </div>
          </div>
          <div className="marketing-employee__nav-header">
            {(checkSource
              ? NavHeaderOrderFarm
              : checkSourceLab
              ? NavHeaderOrderLab
              : NavHeaderOrder
            ).map((o) => {
              return (
                <div
                  key={o.id}
                  className={
                    o.value === selectTab ? "tab tab-active btn--shiny" : `tab ${o.value} tab-hover`
                  }
                  onClick={() => {
                    setSelectTab(o.value);
                  }}>
                  {t(o.label)} <span>{state.count[`${o.value}`]}</span>
                </div>
              );
            })}
          </div>
          {!isBoarSemen && (
            <div
              className={
                !checkSource ? "marketing-employee__list" : "marketing-employee__list__farm"
              }>
              {!checkSource &&
                (checkSourceLab ? (
                  <div className="header">
                    <div className="id"> {t("ID_NO")}</div>
                    <div className="name"> {t("FARM_NAME")}</div>
                    <div className="orderTime"> {t("BOOKING_DATE")}</div>
                    <div className="setTime"> {t("APPOINTMENT_DATE")}</div>
                    <div className="act"> {t("order:ORDER_PLACER")}</div>
                    <div className="status">
                      {selectTab === OrderStatus.CREATED ||
                      selectTab === OrderStatus.VERIFIED ||
                      selectTab === "all" ? (
                        <Select
                          variant="standard"
                          disableUnderline
                          renderValue={() => <span> {t("STATUS")}</span>}
                          displayEmpty
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list select-menu-status",
                            anchorOrigin: {
                              vertical: 20,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {StatusOrder.map((s, idx: number) => {
                            return (
                              <MenuItem
                                key={idx}
                                onClick={() => {
                                  setSelectSubStatus(s.value);
                                }}>
                                {t(s.label)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        t("STATUS")
                      )}
                    </div>
                    <div className="request"> {t("REQUEST_CREATOR")}</div>
                    <div className="method"> {t("order:TEST_METHOD")}</div>
                    <div className="samples"> {t("order:TOTAL_SAMPLES")}</div>

                    <div className="total">{t("TOTAL")}</div>
                  </div>
                ) : (
                  <div className="header">
                    <div className="id"> {t("order:ORDER_ID")}</div>
                    <div className="ordGF"> {t("order:REFERENCE_CODE")}</div>
                    <div className="customer"> {t("CUSTOMER")}</div>
                    <div className="phone"> {t("PHONE_NUMBER")}</div>
                    <div className="act"> {t("order:ORDER_PLACER")}</div>
                    <div className="setTime"> {t("order:ORDER_TIME")}</div>
                    <div className="orderTime"> {t("order:DELIVERY_DATE")}</div>
                    <div className="status">
                      {selectTab === OrderStatus.CREATED ||
                      selectTab === OrderStatus.VERIFIED ||
                      selectTab === "all" ? (
                        <Select
                          variant="standard"
                          disableUnderline
                          renderValue={() => <span> {t("STATUS")}</span>}
                          displayEmpty
                          inputProps={{ readOnly: false }}
                          MenuProps={{
                            className: "select-menu-list select-menu-status",
                            anchorOrigin: {
                              vertical: 20,
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }}>
                          {StatusOrder.map((s, idx: number) => {
                            return (
                              <MenuItem
                                key={idx}
                                onClick={() => {
                                  setSelectSubStatus(s.value);
                                }}>
                                {t(s.label)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      ) : (
                        t("STATUS")
                      )}
                    </div>
                    <div className="total">{t("TOTAL")}</div>
                    {synchronized === true && (
                      <div className="check">
                        <Checkbox
                          checked={checkedSource.length > 0 ? true : false}
                          value={checkedSource}
                          onChange={() => {
                            checkedSource.length > 0
                              ? setCheckedSource([])
                              : setCheckedSource(
                                  state.data
                                    ?.filter(
                                      (f: any) =>
                                        (f.subStatus === OrderStatusERP.NOT_CREATE_ERP ||
                                          f.subStatus === OrderStatusERP.NOT_UPDATED_ERP) &&
                                        f.status !== OrderStatus.CANCELLED
                                    )
                                    .map((da: any) => {
                                      return da.id.toString();
                                    })
                                );
                          }}
                          style={{ transform: "scale(1.5)" }}
                        />
                      </div>
                    )}
                  </div>
                ))}

              {checkSource && (
                <div className="header">
                  <div className="id">{t("order:ORDER_ID")} </div>
                  <div className="status">{t("STATUS")}</div>
                  <div className="ordGF">
                    {filterOrder === "G" ? t("order:ORDER_PLACER") : t("order:REFERENCE_CODE")}
                  </div>
                  <div className="customer">{t("CUSTOMER")}</div>
                  {/* <div className="phone">Số điện thoại</div> */}
                  <div className="sale-org">{t("order:BUSINESS_UNIT")}</div>
                  <div className="setTime">{t("order:ORDER_TIME")}</div>
                  <div className="orderTime">{t("order:DELIVERY_DATE")}</div>
                  <div className="total">Số tiền cần cọc</div>
                  <div className="product">Sản phẩm</div>
                </div>
              )}

              {state.loading ||
              (state.fetching && searchValue) ||
              (state.fetching && checkSource && filterOrder) ? (
                <Loading style={{ margin: "2rem 0", height: "60vh" }} />
              ) : (
                <>
                  {state.data ? (
                    state.data?.length > 0 ? (
                      <InfiniteScroll
                        useWindow={false}
                        pageStart={0}
                        initialLoad={false}
                        hasMore={hasMore}
                        loadMore={() =>
                          fetchData({
                            limit: 50,
                            page: state.page + 1,
                            from: range.startTime.toISOString(),
                            to: range.endTime.toISOString(),
                            strSearch: searchValue,
                            source: sourceType,
                            status: selectTab,
                            subStatus: selectSubStatus,
                            view: filterOrder === "G" ? "group" : "allocate",
                            itemGroupTypeId:
                              sourceType === "farm" ? searchQuery.get("itemGroupTypeId") : null,
                          })
                        }>
                        {!checkSource &&
                          (checkSourceLab
                            ? state.data.map((u: OrderOutputModel, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      if (sourceType === SourceType.LAB) {
                                        checkPermissionOrderDetail
                                          ? history.push({
                                              pathname: APP_ROUTES.ORDER_DETAIL_LAB.replace(
                                                ":orderId",
                                                u.id.toString()
                                              ),
                                              state: {
                                                startTime: range.startTime.toISOString(),
                                                endTime: range.endTime.toISOString(),
                                                status: selectTab,
                                              },
                                            })
                                          : enqueueSnackbar("Bạn không có quyền truy cập", {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      } else {
                                        checkPermissionOrderDetail
                                          ? history.push({
                                              pathname: APP_ROUTES.ORDER_DETAIL_FEED.replace(
                                                ":orderId",
                                                u.id.toString()
                                              ),
                                              state: {
                                                startTime: range.startTime.toISOString(),
                                                endTime: range.endTime.toISOString(),
                                                status: selectTab,
                                              },
                                            })
                                          : enqueueSnackbar("Bạn không có quyền truy cập", {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }
                                    }}
                                    className={`item ${u.status}`}>
                                    <div className={`id id-${u.status}`}>{u?.code}</div>
                                    <div className="name">
                                      {u.orders[0]?.camp?.name || TEXT_HOLDER}
                                    </div>
                                    <div className="orderTime">
                                      {moment(u.createdAt).format(TIME.DAY_MONTH_YEAR)}
                                    </div>
                                    <div className="setTime">
                                      {moment(u.orders[0]?.requiredDate).format(
                                        TIME.DAY_MONTH_YEAR
                                      )}
                                    </div>
                                    <div className="act"> {u.createdBy?.name || TEXT_HOLDER}</div>

                                    <div className="status">
                                      {StatusUIUtil.renderCircleStatus(
                                        selectTab,
                                        u?.status,
                                        sourceType,
                                        u.subStatus
                                      )}
                                    </div>
                                    <div className="request">
                                      <div className="info__img">
                                        <img
                                          alt="employee avatar"
                                          src={
                                            checkLinkImage(
                                              u.orders[0]?.customer?.account?.avatar as any
                                            ) || renderImageProNull()
                                          }
                                          onError={renderErrorImageItem()}
                                        />
                                      </div>
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                        <ThemeProvider theme={theme}>
                                          <Tooltip
                                            title={
                                              <span>
                                                {!!u.orders[0]?.customer
                                                  ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                  : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                              </span>
                                            }>
                                            <span>
                                              {!!u.orders[0]?.customer
                                                ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                            </span>
                                          </Tooltip>
                                        </ThemeProvider>

                                        <span style={{ color: "#838283", paddingTop: "0.5rem" }}>
                                          {!!u.orders[0]?.customer
                                            ? u.orders[0]?.customer?.code || TEXT_HOLDER
                                            : u.orders[0]?.lead?.code || TEXT_HOLDER}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="method">
                                      {u.orders?.map((i) => i.itemGroup?.name).join(", ") ||
                                        TEXT_HOLDER}
                                    </div>
                                    <div className="samples">{u.totalSample || TEXT_HOLDER}</div>

                                    <div className="total">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(u.totalAmount))
                                        : TEXT_ASTERISK}{" "}
                                      đ
                                      {/* <span
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                      marginTop: "1rem",
                                    }}>
                                    <WeightIcon
                                      color={"#272B2F"}
                                      size={DEFAULT_SIZE}
                                      viewBox={[22, 18]}
                                    />
                                    {currencyFormat(
                                      u?.orders.reduce((sum: any, item: any) => {
                                        const a = item.orderlines.reduce((sum: any, line: any) => {
                                          sum += +line.dimension;
                                          return sum;
                                        }, 0);
                                        return (sum += a);
                                      }, 0)
                                    )}{" "}
                                    kg
                                  </span> */}
                                    </div>
                                  </div>
                                );
                              })
                            : state.data.map((u: OrderOutputModel, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      if (
                                        synchronized === false &&
                                        u.status === OrderStatus.DRAFT &&
                                        sourceType === SourceType.FEED
                                      ) {
                                        history.push({
                                          pathname: APP_ROUTES.UPDATE_ORDER_DRAFT.replace(
                                            ":orderId",
                                            u.id.toString()
                                          ),
                                          state: {
                                            startTime: range.startTime.toISOString(),
                                            endTime: range.endTime.toISOString(),
                                            status: selectTab,
                                          },
                                        });
                                      } else if (synchronized === true) {
                                      } else if (sourceType === SourceType.VET) {
                                        checkPermissionOrderDetail
                                          ? history.push({
                                              pathname: APP_ROUTES.ORDER_DETAIL_VET.replace(
                                                ":orderId",
                                                u.id.toString()
                                              ),
                                              state: {
                                                startTime: range.startTime.toISOString(),
                                                endTime: range.endTime.toISOString(),
                                                status: selectTab,
                                              },
                                            })
                                          : enqueueSnackbar("Bạn không có quyền truy cập", {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      } else if (sourceType === SourceType.LAB) {
                                        checkPermissionOrderDetail
                                          ? history.push({
                                              pathname: APP_ROUTES.ORDER_DETAIL_LAB.replace(
                                                ":orderId",
                                                u.id.toString()
                                              ),
                                              state: {
                                                startTime: range.startTime.toISOString(),
                                                endTime: range.endTime.toISOString(),
                                                status: selectTab,
                                              },
                                            })
                                          : enqueueSnackbar("Bạn không có quyền truy cập", {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      } else {
                                        checkPermissionOrderDetail
                                          ? history.push({
                                              pathname: APP_ROUTES.ORDER_DETAIL_FEED.replace(
                                                ":orderId",
                                                u.id.toString()
                                              ),
                                              state: {
                                                startTime: range.startTime.toISOString(),
                                                endTime: range.endTime.toISOString(),
                                                status: selectTab,
                                              },
                                            })
                                          : enqueueSnackbar("Bạn không có quyền truy cập", {
                                              preventDuplicate: true,
                                              variant: "error",
                                            });
                                      }
                                    }}
                                    className={`item ${u.status}`}>
                                    <div className={`id id-${u.status}`}>{u?.code}</div>
                                    <div className="ordGF">
                                      {u.orders.map((os, index: number) => {
                                        return (
                                          <span key={index}>
                                            {os.orderGFId || TEXT_HOLDER}
                                            <span className="line"> | </span>
                                          </span>
                                        );
                                      })}
                                    </div>
                                    <div className="customer">
                                      <div className="info__img">
                                        <img
                                          alt="employee avatar"
                                          src={
                                            checkLinkImage(
                                              u.orders[0]?.customer?.account?.avatar as any
                                            ) || renderImageProNull()
                                          }
                                          onError={renderErrorImageItem()}
                                        />
                                      </div>
                                      <div style={{ display: "flex", flexDirection: "column" }}>
                                        <ThemeProvider theme={theme}>
                                          <Tooltip
                                            title={
                                              <span>
                                                {!!u.orders[0]?.customer
                                                  ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                  : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                              </span>
                                            }>
                                            <span>
                                              {!!u.orders[0]?.customer
                                                ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                            </span>
                                          </Tooltip>
                                        </ThemeProvider>

                                        <span style={{ color: "#838283", paddingTop: "0.5rem" }}>
                                          {!!u.orders[0]?.customer
                                            ? u.orders[0]?.customer?.code || TEXT_HOLDER
                                            : u.orders[0]?.lead?.code || TEXT_HOLDER}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="phone">
                                      {!!u.orders[0]?.customer
                                        ? u.orders[0]?.customer?.phoneNumber || TEXT_HOLDER
                                        : u.orders[0]?.lead?.phoneNumber || TEXT_HOLDER}
                                    </div>
                                    <div className="act"> {u.createdBy?.name || TEXT_HOLDER}</div>
                                    <div className="setTime">
                                      {moment(u.createdAt).format(TIME.DAY_MONTH_YEAR)}
                                    </div>
                                    <div className="orderTime">
                                      {moment(u.orders[0].requiredDate).format(TIME.DAY_MONTH_YEAR)}
                                    </div>
                                    <div
                                      className="status"
                                      style={{ display: "flex", alignItems: "flex-end" }}>
                                      {StatusUIUtil.renderCircleStatus(
                                        selectTab,
                                        u?.status,
                                        sourceType,
                                        u.subStatus
                                      )}
                                      {sourceType === SourceType.FEED &&
                                        u?.status === OrderStatus.COMPLETED && (
                                          <Chip
                                            label="Phản hồi"
                                            style={{ background: "#F6CD00" }}
                                            sx={{
                                              height: 16,
                                              marginLeft: 1,
                                              "& .MuiChip-label": {
                                                fontSize: 11,
                                                color: "#fff",
                                                fontWeight: 700,
                                                fontFamily: "SVN-Gotham",
                                                marginBottom: 0.4,
                                              },
                                            }}
                                          />
                                        )}
                                    </div>
                                    <div className="total">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(parseInt(u.totalAmount))
                                        : TEXT_ASTERISK}{" "}
                                      đ
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          marginTop: "1rem",
                                        }}>
                                        <WeightIcon
                                          color={"#272B2F"}
                                          size={DEFAULT_SIZE}
                                          viewBox={[22, 18]}
                                        />
                                        {currencyFormat(
                                          u?.orders.reduce((sum: any, item: any) => {
                                            const a = item.orderlines.reduce(
                                              (sum: any, line: any) => {
                                                sum += +line.dimension;
                                                return sum;
                                              },
                                              0
                                            );
                                            return (sum += a);
                                          }, 0)
                                        )}{" "}
                                        kg
                                      </span>
                                    </div>
                                    {synchronized === true &&
                                      (u.subStatus === OrderStatusERP.NOT_CREATE_ERP ||
                                        u.subStatus === OrderStatusERP.NOT_UPDATED_ERP) &&
                                      u.status !== OrderStatus.CANCELLED && (
                                        <div className="check">
                                          <Checkbox
                                            checked={checkedSource.some(
                                              (e) => e === u.id.toString()
                                            )}
                                            value={u.id}
                                            onChange={() => {
                                              checkedSource.some((e) => e === u.id.toString())
                                                ? setCheckedSource(
                                                    checkedSource
                                                      .filter((e) => e !== u.id.toString())
                                                      .map((item: any) => {
                                                        return item;
                                                      })
                                                  )
                                                : setCheckedSource([
                                                    ...checkedSource,
                                                    u.id.toString(),
                                                  ]);
                                            }}
                                            style={{ transform: "scale(1.5)" }}
                                          />
                                        </div>
                                      )}
                                  </div>
                                );
                              }))}
                        {/* Trang trại & con giống */}
                        {checkSource &&
                          state.data.map((u: OrderOutputModel, index: number) => {
                            if (filterOrder === "G") {
                              return (
                                <div>
                                  <div key={index} className={`item ${u.status}`}>
                                    <div
                                      className="load-more-icons"
                                      onClick={() => {
                                        // Trường hợp đã open moreInfo rồi
                                        if (moreInfo?.includes(u.code)) {
                                          const cloneMoreInfo = moreInfo.filter(
                                            (item: string, index: number) => item !== u.code
                                          );
                                          setMoreInfo(cloneMoreInfo);
                                        } else {
                                          const cloneMoreInfo = [...moreInfo];
                                          cloneMoreInfo.push(u.code);
                                          setMoreInfo(cloneMoreInfo);
                                        }
                                      }}>
                                      <ArrowDropDownIcon fontSize="large" />
                                      <div className={`id id-${u.status}`}>{u?.code}</div>
                                    </div>
                                    <div
                                      className="item-child"
                                      onClick={() => {
                                        if (
                                          synchronized === false &&
                                          u.status === OrderStatus.DRAFT &&
                                          sourceType === SourceType.FARM
                                        ) {
                                          history.push({
                                            pathname: APP_ROUTES.UPDATE_ORDER_FARM.replace(
                                              ":orderId",
                                              u.id.toString()
                                            ),
                                            state: {
                                              type: "DRAFT",
                                            },
                                            search: `?itemGroupTypeId=${searchQuery.get(
                                              "itemGroupTypeId"
                                            )}`,
                                          });
                                        } else {
                                          checkPermissionOrderDetail
                                            ? history.push({
                                                pathname: APP_ROUTES.ORDER_INTERNAL_DETAIL.replace(
                                                  ":orderId",
                                                  u.id.toString()
                                                ),
                                                state: {
                                                  startTime: range.startTime.toISOString(),
                                                  endTime: range.endTime.toISOString(),
                                                  status: selectTab,
                                                },
                                                search: `?itemGroupTypeId=${searchQuery.get(
                                                  "itemGroupTypeId"
                                                )}`,
                                              })
                                            : enqueueSnackbar("Bạn không có quyền truy cập", {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }
                                      }}>
                                      <div className={`status`}>
                                        <Tooltip
                                          title={StatusUIUtil.renderCircleStatusNew(
                                            selectTab,
                                            u?.status,
                                            u?.subStatus
                                          )}>
                                          {StatusUIUtil.renderCircleStatusNew(
                                            selectTab,
                                            u?.status,
                                            u?.subStatus
                                          )}
                                        </Tooltip>
                                      </div>
                                      <div className="ordGF">
                                        {filterOrder === "G" ? (
                                          <ThemeProvider theme={theme}>
                                            <Tooltip
                                              title={
                                                <span>
                                                  {u?.createdBy
                                                    ? u.createdBy?.fullName
                                                    : TEXT_HOLDER}{" "}
                                                  -{" "}
                                                  {u?.createdBy
                                                    ? u.createdBy?.account?.code
                                                    : TEXT_HOLDER}
                                                </span>
                                              }>
                                              <span>
                                                {u?.createdBy ? u.createdBy?.fullName : TEXT_HOLDER}{" "}
                                                -{" "}
                                                {u?.createdBy
                                                  ? u.createdBy?.account?.code
                                                  : TEXT_HOLDER}
                                              </span>
                                            </Tooltip>
                                          </ThemeProvider>
                                        ) : (
                                          u.orders.map((os, index: number) => {
                                            return (
                                              <span key={index}>
                                                {os.orderGFId || TEXT_HOLDER}
                                                <span className="line"> | </span>
                                              </span>
                                            );
                                          })
                                        )}
                                      </div>
                                      <div className="customer">
                                        <div className="info__img">
                                          <img
                                            alt="employee avatar"
                                            src={
                                              checkLinkImage(
                                                u.orders[0]?.customer?.account?.avatar as any
                                              ) || renderImageProNull()
                                            }
                                            onError={renderErrorImageItem()}
                                          />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                          <ThemeProvider theme={theme}>
                                            <Tooltip
                                              title={
                                                <span>
                                                  {!!u.orders[0]?.customer
                                                    ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                    : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                                </span>
                                              }>
                                              <span>
                                                {!!u.orders[0]?.customer
                                                  ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                  : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                              </span>
                                            </Tooltip>
                                          </ThemeProvider>
                                          <span style={{ color: "#838283", paddingTop: "0.5rem" }}>
                                            {u.orders[0]?.customer
                                              ? u.orders[0]?.customer?.code || TEXT_HOLDER
                                              : u.orders[0]?.lead?.code || TEXT_HOLDER}
                                          </span>
                                        </div>
                                      </div>
                                      {/* <div className="phone">
                                {u.orders[0]?.customer !== null
                                  ? u.orders[0]?.customer?.phoneNumber || TEXT_HOLDER
                                  : u.orders[0]?.lead?.phoneNumber || TEXT_HOLDER}
                              </div> */}
                                      <div className="sale-org">
                                        {u.orders[0]?.salesOrg?.description || TEXT_HOLDER}
                                      </div>
                                      <div className="setTime">
                                        {moment(u.createdAt).format(TIME.DAY_MONTH_YEAR)}
                                      </div>
                                      <div className="orderTime">
                                        {moment(u.orders[0]?.requiredDate).format(
                                          TIME.DAY_MONTH_YEAR
                                        )}
                                      </div>
                                      <div className="total">
                                        {currencyFormat(get(u, "depositAmount[0]", 0))}đ
                                      </div>
                                      <div className="product">
                                        {get(u, "orders[0].orderlines[0].item.name", "")}
                                      </div>
                                    </div>
                                  </div>
                                  <Collapse in={moreInfo.includes(u.code)}>
                                    <div className="more-info-collapse">
                                      <div className="more-info-content">
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <div className="header-more-info">
                                              <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                  Sản phẩm
                                                </Grid>
                                                <Grid item xs={4}>
                                                  Địa chỉ
                                                </Grid>
                                                <Grid item xs={2}>
                                                  Ngày giao hàng
                                                </Grid>
                                                <Grid item xs={1}>
                                                  Số lượng
                                                </Grid>
                                                <Grid item xs={2}>
                                                  Trạng thái
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </Grid>
                                          {u.orders.map((item, index) => {
                                            return (
                                              <Grid item xs={12}>
                                                <div>
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                      <Tooltip
                                                        title={item?.orderlines[0]?.itemName}
                                                        placement="top-start">
                                                        <div className="product-info">
                                                          <div className="item-more-info">
                                                            {item?.orderlines[0]?.itemName}
                                                          </div>
                                                          <div className="product-code">
                                                            {item?.orderlines[0]?.item?.code}
                                                          </div>
                                                        </div>
                                                      </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                      <Tooltip
                                                        title={item.address}
                                                        placement="top-start">
                                                        <div className="item-more-info">
                                                          {item.address}
                                                        </div>
                                                      </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      <Tooltip
                                                        title={moment(item.requiredDate).format(
                                                          TIME.DAY_MONTH_YEAR
                                                        )}
                                                        placement="top-start">
                                                        <div className="item-more-info">
                                                          {moment(item?.requiredDate).format(
                                                            TIME.DAY_MONTH_YEAR
                                                          )}
                                                        </div>
                                                      </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                      <Tooltip
                                                        title={Number(
                                                          item?.orderlines[0]?.orderQuantity
                                                        )}
                                                        placement="top-start">
                                                        <div className="item-more-info">
                                                          {Number(
                                                            item?.orderlines[0]?.orderQuantity
                                                          )}
                                                        </div>
                                                      </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      {StatusUIUtil.renderCircleStatusNew(
                                                        selectTab,
                                                        item?.status,
                                                        item?.farmSubStatus
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              </Grid>
                                            );
                                          })}
                                        </Grid>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              );
                            } else {
                              return u?.orders.map((orders) => {
                                return orders.childOrders.map((c: any, idx: number) => {
                                  return (
                                    <div
                                      key={idx}
                                      onClick={() => {
                                        if (
                                          synchronized === false &&
                                          u.status === OrderStatus.DRAFT &&
                                          sourceType === SourceType.FARM
                                        ) {
                                          history.push({
                                            pathname: APP_ROUTES.UPDATE_ORDER_FARM.replace(
                                              ":orderId",
                                              u.id.toString()
                                            ),
                                            state: {
                                              type: "DRAFT",
                                            },
                                          });
                                        } else {
                                          checkPermissionOrderDetail
                                            ? history.push({
                                                pathname: APP_ROUTES.ORDER_DETAIL_FARM.replace(
                                                  ":orderId",
                                                  u.id.toString()
                                                ),
                                                state: {
                                                  startTime: range.startTime.toISOString(),
                                                  endTime: range.endTime.toISOString(),
                                                  status: selectTab,
                                                  orderId: orders?.id,
                                                  childOrderId: c?.id,
                                                },
                                              })
                                            : enqueueSnackbar("Bạn không có quyền truy cập", {
                                                preventDuplicate: true,
                                                variant: "error",
                                              });
                                        }
                                      }}
                                      className={`item ${c?.status}`}>
                                      <div className={`id id-${c?.status}`}>{u?.code}</div>
                                      <div className="status">
                                        {StatusUIUtil.renderCircleStatus(selectTab, c?.status)}
                                      </div>
                                      <div className="ordGF">
                                        <span>{c.orderGFId || TEXT_HOLDER}</span>
                                      </div>
                                      <div className="customer">
                                        <div className="info__img">
                                          <img
                                            alt="employee avatar"
                                            src={
                                              checkLinkImage(
                                                u.orders[0]?.customer?.account?.avatar as any
                                              ) || renderImageProNull()
                                            }
                                            onError={renderErrorImageItem()}
                                          />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                          <ThemeProvider theme={theme}>
                                            <Tooltip
                                              title={
                                                <span>
                                                  {!!u.orders[0]?.customer
                                                    ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                    : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                                </span>
                                              }>
                                              <span>
                                                {!!u.orders[0]?.customer
                                                  ? u.orders[0]?.customer?.fullName || TEXT_HOLDER
                                                  : u.orders[0]?.lead?.fullName || TEXT_HOLDER}
                                              </span>
                                            </Tooltip>
                                          </ThemeProvider>
                                          <span style={{ color: "#838283", paddingTop: "0.5rem" }}>
                                            {!!u.orders[0]?.customer
                                              ? u.orders[0]?.customer?.code || TEXT_HOLDER
                                              : u.orders[0]?.lead?.code || TEXT_HOLDER}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="phone">
                                        {!!u.orders[0]?.customer
                                          ? u.orders[0]?.customer?.phoneNumber || TEXT_HOLDER
                                          : u.orders[0]?.lead?.phoneNumber || TEXT_HOLDER}
                                      </div>
                                      <div className="sale-org">
                                        {u.orders[0]?.salesOrg?.description || TEXT_HOLDER}
                                      </div>
                                      <div className="setTime">
                                        {moment(c?.createdAt).format(TIME.DAY_MONTH_YEAR)}
                                      </div>
                                      <div className="orderTime">
                                        {moment(c?.requiredDate).format(TIME.DAY_MONTH_YEAR)}
                                      </div>
                                      <div className="total">
                                        {checkPermissionViewPrice
                                          ? currencyFormat(parseInt(c?.orderAmount))
                                          : TEXT_ASTERISK}{" "}
                                        đ
                                      </div>
                                    </div>
                                  );
                                });
                              });
                            }
                          })}
                      </InfiniteScroll>
                    ) : visibleSearch === true ? (
                      <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                    ) : (
                      <EmptyList
                        img={Ord}
                        title={t("order:NO_ORDER_PLACED")}
                        text={t("order:PLACE_ORDER_NOW")}
                      />
                    )
                  ) : (
                    <EmptyList
                      img={Ord}
                      title={t("order:NO_ORDER_PLACED")}
                      text={t("order:PLACE_ORDER_NOW")}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {isBoarSemen && (
            <BoarSemen
              range={range}
              selectTab={selectTab}
              sourceType={sourceType as SourceType}
              synchronized={synchronized}
              data={state.data}
              itemGroupTypeId={searchQuery.get("itemGroupTypeId")}
              onLoadMore={handleLoadMore}
              hasMore={hasMore}
              loading={state.loading || state.fetching}
              type={FarmType.Internal}
            />
          )}
        </div>

        {profile?.role !== null && isBoarSemen && (
          <FloatingBtn
            visible={false}
            onClickPrimary={() => {
              toggleFloatingBtn();
              checkPermissionOrderCreate
                ? handleToCreate()
                : enqueueSnackbar("Bạn không có quyền truy cập", {
                    preventDuplicate: true,
                    variant: "error",
                  });
            }}
            btns={[]}
          />
        )}
        {/* {
          profile?.role !== null ? (
            <FloatingBtn
              visible={false}
              onClickPrimary={() => {
                toggleFloatingBtn();
                checkPermissionOrderCreate
                  ? handleToCreate()
                  : enqueueSnackbar("Bạn không có quyền truy cập", {
                    preventDuplicate: true,
                    variant: "error",
                  });
              }}
              btns={[]}
            />
          ) : (
            <FloatingBtn
              visible={false}
              onClickPrimary={() => {
                handleToCreate();
              }}
              btns={[]}
            />
          )
        } */}

        <NotiExportExcel
          notiExport={notiExport}
          title={t("ORDER")}
          closeForm={() => {
            setNotiExport(false);
            clearInterval(checkExportRef.current);
          }}
          success={success?.isExport === false ? success?.fileName : undefined}
        />
      </div>
    </>
  );
};

export default OrderInternalListing;
