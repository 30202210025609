/* eslint-disable react-hooks/exhaustive-deps */
import Ord from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import { Calendar1Icon } from "@Components/Icons/Calendar1";
import Loading from "@Components/Loading";
import { useAppDispatch } from "@Core/store/hook";
import { loadProfile } from "@Core/store/profile";
import { Action, DepositOutputModel, FetchAction } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { IWareHouse } from "@Models/warehouse.model";
import { ArrowDropDown as ArrowDropDownIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import { WarehouseService } from "@Services/warehouse.service";
import { YearList } from "@Utils";
import { getWeek } from "date-fns";
import _, { get } from "lodash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CTable from "../components";
import { Align, IColumn } from "../components/const";
import useGetMe from "@Hooks/useGetMe";
import { PigTypeCode } from "src/types";

function reducer(state: PaginateFetchReducer<DepositOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: state.data?.concat(action.payload.data) || [],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const Pork: React.FC = () => {
  const currentDate = new Date();
  const weekNumber = getWeek(currentDate, {
    weekStartsOn: 0,
  });
  const [month, setMonth] = useState<string>(String(weekNumber + 1));
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const { t } = useTranslation(["translation", "product"]);
  const mounted = useRef(false);
  // const [range, setRange] = useDateRange();
  const [searchValue, setSearchValue] = useState<string>("");
  // eslint-disable-next-line
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [time, setTime] = useState({
    month: month,
    year: year,
  });
  const { getPigTypeIdByCode } = useGetMe();
  const itemGroupTypeId = getPigTypeIdByCode(PigTypeCode.Fork)

  // const handleData = (data: any, page: number) => {
  //   const duplicatedArray = Array.from({ length: page }, () => data).flat();
  //   console.log(duplicatedArray);
  //   return duplicatedArray;
  // }

  const dispatchProfile = useAppDispatch();
  // const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
    totalPages: 0,
    limit: 50,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
    columns: [],
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await WarehouseService.getAllPork(query, itemGroupTypeId);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const handlePagination = () => {
    fetchData({
      week: month,
      year: year,
      // weekNumber: 2,
      page: state.page + 1,
      limit: 1,
      searchString: searchValue,
    });
  };

  useEffect(() => {
     if (itemGroupTypeId) {
      fetchData(
        {
          week: month,
          year: year,
          // weekNumber: 2,
          page: 1,
          limit: 50,
          searchString: searchValue,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, time, itemGroupTypeId]);

  const hasMore = state.data.length < state.total && state.page < state.totalPages;
  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  // useEffect(() => {
  //   setRefresh(true);
  //   if (location.state?.startTime !== undefined) {
  //     setRange(moment(location.state?.startTime), moment(location.state?.endTime));
  //   } else {
  //     setRange(
  //       moment().subtract(7, "days").set({ hours: 0, minutes: 0, seconds: 0 }),
  //       moment().set({ hours: 23, minutes: 59, seconds: 59 })
  //     );
  //   }
  // }, [location, profile]);

  const columns: IColumn[] = [
    {
      key: "sitename",
      index: "sitename",
      label: "Tên trại",
      sticky: true,
      render: (record: any) => {
        return (
          <Tooltip
            title={`${get(record, "site.code", "")} - ${get(record, "site.name", "")}`}
            placement="top-start">
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {get(record, "site.code", "")} - {get(record, "site.name", "")}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: "itemCode",
      index: "itemCode",
      label: "Mã sản phẩm",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "item.code", "")} placement="top-start">
            <div>{get(record, "item.code", "")}</div>
          </Tooltip>
        );
      },
    },
    {
      key: "itemName",
      index: "itemName",
      label: "Tên sản phẩm",
      sticky: true,
      render: (record: IWareHouse) => {
        return (
          <Tooltip title={get(record, "item.name", "")} placement="top-start">
            <div
              style={{
                width: "80%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}>
              {get(record, "item.name", "")}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: "inventory",
      index: "inventory",
      label: `Tồn kho theo tuần`,
      width: 80,
      children: [
        {
          key: `Tuần ${month}`,
          index: "inventoryNumber",
          label: `Tuần ${month}`,
          width: 80,
          align: Align.right,
          render: (record: any) => {
            console.log(get(record, "weeklyStocks.0.inventory", ""), "get123");
            return (
              <Tooltip title={get(record, "weeklyStocks.0.inventory", "")} placement="top-start">
                <div>{get(record, "weeklyStocks.0.inventory", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },

    {
      key: "reserved",
      index: "reserved",
      label: "Đã giữ theo tuần",
      width: 80,
      children: [
        {
          key: `Tuần ${month}`,
          index: "reservedNumber",
          label: `Tuần ${month}`,
          width: 80,
          align: Align.right,
          render: (record: any) => {
            return (
              <Tooltip title={get(record, "weeklyStocks.0.reservedNum", "")} placement="top-start">
                <div>{get(record, "weeklyStocks.0.reservedNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "shippedNum",
      index: "shippedNum",
      label: "Đã giao theo tuần",
      width: 80,
      children: [
        {
          key: `shippedNum`,
          index: "shippedNum",
          label: `Tuần ${month}`,
          width: 80,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip title={get(record, "weeklyStocks.0.shippedNum", "")} placement="top-start">
                <div>{get(record, "weeklyStocks.0.shippedNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "availableNumber",
      index: "availableNumber",
      label: "Khả dụng theo tuần",
      width: 80,
      children: [
        {
          key: "availableNum",
          index: "availableNum",
          label: `Tuần ${month}`,
          width: 80,
          align: Align.right,
          render: (record) => {
            return (
              <Tooltip title={get(record, "weeklyStocks.0.availableNum", "")} placement="top-start">
                <div>{get(record, "weeklyStocks.0.availableNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },

    {
      key: "transferredNumber",
      index: "transferredNumber",
      label: "Transfer",
      width: 80,
      children: [
        {
          key: "transferredNum",
          index: "transferredNum",
          label: `Tuần ${month}`,
          width: 80,
          align: Align.right,
          render: (record) => {
            return (
              <Tooltip
                title={get(record, "weeklyStocks.0.transferredNum", "")}
                placement="top-start">
                <div>{get(record, "weeklyStocks.0.transferredNum", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      key: "stock",
      index: "stock",
      label: "Khả dụng sau transfer",
      width: 90,
      children: [
        {
          key: "stock",
          index: "stock",
          label: `Tuần ${month}`,
          width: 90,
          align: Align.right,
          render: (record: IWareHouse) => {
            return (
              <Tooltip title={get(record, "weeklyStocks.0.stock", "")} placement="top-start">
                <div>{get(record, "weeklyStocks.0.stock", "")}</div>
              </Tooltip>
            );
          },
        },
      ],
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="warehouse-content pork">
        <div className="action">
          <div style={{ display: "flex" }}>
            <TextField
              placeholder={t("ENTER_SEARCH")}
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button onClick={handleClick} className="btn-white">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Calendar1Icon color="#272b2f" size={[20, 20]} viewBox={[20, 20]} />
                &emsp;Tuần {month}/{year}
              </div>
              <ArrowDropDownIcon />
            </button>
          </div>
        </div>
        <div className="list" style={{ flex: 1 }}>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : state.data && state.data.length > 0 ? (
            <CTable
              columns={columns}
              data={state?.data ?? []}
              hasMore={hasMore}
              handlePagination={handlePagination}
            />
          ) : (
            <EmptyList
              img={Ord}
              title={t("order:NO_ORDER_PLACED")}
              text={t("order:PLACE_ORDER_NOW")}
            />
          )}
          {/* {state.data && state.data.length > 0 ? (
              <CTable columns={columns} data={state?.data ?? []} />
            ) : (
              <EmptyList
                img={Ord}
                title={t("order:NO_ORDER_PLACED")}
                text={t("order:PLACE_ORDER_NOW")}
              />
            )} */}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="popover-date">
          <Grid container spacing={1} style={{ paddingBottom: 17 }} className="popover-date-select">
            <Grid item xs={6}>
              <Select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {(() => {
                  const menuItems = [];
                  for (let i = 1; i <= 53; i++) {
                    menuItems.push(
                      <MenuItem
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        value={i}>
                        {i}
                      </MenuItem>
                    );
                  }
                  return menuItems;
                })()}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                input={<OutlinedInput />}
                fullWidth
                color="success"
                MenuProps={{
                  className: "select-menu-list popover-date-menu-list",
                  anchorOrigin: {
                    vertical: 40,
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}>
                {YearList().map((y: any, idx: number) => {
                  return (
                    <MenuItem
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                      value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <div style={{ display: "flex" }}>
            <button className="btn-cancel-style" onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                setTime({
                  month: month,
                  year: year,
                });
                handleClose();
                // setDetailLetter(null);
                // dispatch({
                //   type: Action.RESET,
                //   payload: result.data,
                // });
              }}>
              {t("CONFIRM")}
            </button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default React.memo(Pork);
