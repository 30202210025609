import SelectInput from "@Components/SelectInput";
import ViewUser from "@Components/ViewUser";
import { CustomerOutputModel } from "@Models";
import { CustomerService } from "@Services";
import { checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import { PersonAddAlt1 as AddCustomerIcon, Clear as ClearIcon } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

type P_Props = {
  onChange: (info: any) => void;
};

const CustomerSelect: React.FC<P_Props> = (props) => {
  const { onChange } = props;
  const { t } = useTranslation(["translation", "order"]);
  const [visibleAddCustomer, setVisibleAddCustomer] = useState<boolean>(false);
  const [info, setInfo] = useState<any>(null);

  return (
    <div className="customer-select">
      {!info ? (
        <SelectInput<CustomerOutputModel>
          getSource={"feed"}
          onChange={(value) => {
            setInfo(value);
            onChange(value);
          }}
          onClick={() => {
            setVisibleAddCustomer(true);
          }}
          className={!visibleAddCustomer ? "customer-textfield" : "active"}
          placeholder={
            !visibleAddCustomer
              ? t("SELECT_BY_NAME", { NAME: t("CUSTOMER") })
              : t("SEARCH_BY_NAME_CUSTOMER_CODE")
          }
          getOptionLabel={(opt) => opt.fullName}
          renderOption={(p) => (
            <>
              <img
                src={checkLinkImage(p.account?.avatar as any) || renderImageProNull()}
                alt=""
                style={{
                  width: "4.8rem",
                  height: "4.8rem",
                  borderRadius: "50%",
                  marginRight: "1rem",
                }}
                onError={renderErrorImageItem()}
              />
              <div>
                <span style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                  {p.fullName} - {p.code}
                </span>
                <br />
                <span style={{ fontSize: "1.4rem", opacity: "0.4" }}>{p.phoneNumber}</span>
              </div>
            </>
          )}
          actionConfig={{
            action: CustomerService.getAllCustomer,
          }}
          dropdownBoxMaxHeight={400}
          icon={<AddCustomerIcon />}
        />
      ) : (
        <div className="customer-select__view">
          <ViewUser
            name={info?.fullName + " - " + info?.code}
            avatar={checkLinkImage(info?.account?.avatar as string) || renderImageProNull()}
            id={info?.phoneNumber}
          />
          <ClearIcon
            className="clear-icon"
            onClick={() => {
              setVisibleAddCustomer(false);
              setInfo(null);
              onChange(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerSelect;
