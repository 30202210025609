/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import { EditIcon } from "@Components/Icons/EditIcon";
import Notification from "@Components/Notification";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useClickOutside } from "@Hooks";
import {
  DetailItemPlanOutputModel,
  ItemsPlanOutputModel,
  PlanDetailStatus,
  PlanStatus,
} from "@Models";
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  CheckRounded as CheckRoundedIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { PlanService } from "@Services/plan.service";
import { dateFormat, getFirstNumberOfStr, StatusUIUtil } from "@Utils";
import clsx from "clsx";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import OnlyOrder from "../OnlyOrder";
import OrderPlan from "../OrderPlan";
import "./index.scss";

type P_Props = {
  weekList: any[];
  checkWeekUpdate: boolean;
  customerId?: number;
  customerIdDetail?: number;
  onChange?: (details: any) => void;
  dataDetails?: DetailItemPlanOutputModel[];
  onVisible?: () => void;
  statusAll?: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export type OrderItem = {
  quantity: number;
  itemId: number;
  divisionId?: number;
  unitPrice?: string;
  saleUnit?: string;
  baseUoM?: string;
  dimension?: string;
  subTotal?: number;
  name?: string;
};

const TabCustom: React.FC<P_Props> = (props) => {
  const {
    weekList,
    checkWeekUpdate,
    customerId,
    customerIdDetail,
    onChange,
    dataDetails,
    onVisible,
    statusAll,
  } = props;
  const [value, setValue] = React.useState(0);
  const [list, setList] = useState<any>([]);
  const [visibleChange, setVisibleChange] = useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { planId }: { planId: string } = useParams();
  const [notiCreOrder, setNotiCreOrder] = useState<number | null>(null);
  const [dataOrd, setDataOrd] = useState<any>(null);
  const [orderUpdate, setOrderUpdate] = useState<any>(null);
  const [openStatus, setOpenStatus] = useState<any>(null);

  const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const onAddOrder = (key: number, addOrder?: any, addNew: boolean = false) => {
    const idx = list.findIndex((item: any) => item.key === key);

    let data = [...list];

    if (idx !== -1) {
      if (addNew) {
        return;
      }
      //update

      data[idx] = {
        ...data[idx],
        details: [...data[idx].details, addOrder],
      };
    } else {
      // thêm mới
      if (!addOrder) {
        return;
      }
      data.push({ key, details: addOrder });
    }

    setList(data);
  };

  const onUpdateOrder = (key: number, index: number, event: any) => {
    const idx = list.findIndex((item: any) => item.key === key);

    const values = [...list[idx]?.details];

    values[index].items = event;
  };

  const handleRemoveOrder = (key: number, index: number) => {
    const idx = list.findIndex((item: any) => item.key === key);

    let data = [...list];

    let values = [...list[idx]?.details];

    values.splice(index, 1);

    data[idx] = {
      ...data[idx],
      details: values,
    };

    setList(data);
  };

  let listPros =
    list
      ?.map((d: any) => d?.details)
      ?.flat()
      ?.map((d: any) => d?.items)
      ?.flat() || [];

  let total = listPros?.reduce((sum: any, item: ItemsPlanOutputModel) => {
    return sum + +item.quantity;
  }, 0);

  useEffect(() => {
    if (onChange) onChange(list);
  }, [list, visibleChange]);

  useEffect(() => {
    if (customerId && dataDetails) {
      setList(dataDetails);
    }
  }, [customerId]);

  const onCreateOrderFeedByPlan = (orderPlanDetailId: number) => {
    PlanService.createOrderByPlan({
      orderPlanId: +planId,
      orderPlanDetailId: orderPlanDetailId,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setNotiCreOrder(null);
          if (res.data?.[0]?.data) {
            setDataOrd(res.data?.[0]?.data);
          } else {
            enqueueSnackbar("Tạo đơn hàng thành công", {
              variant: "success",
            });
            history.push(APP_ROUTES.ORDER_FEED);
          }
        } else {
          enqueueSnackbar("Tạo đơn hàng thất bại", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Tạo đơn hàng thất bại", {
          variant: "error",
        });
      });
  };

  const changeStatus = (detailId: string, status: PlanDetailStatus) => {
    PlanService.updatePlanDetailStatus(detailId, {
      status: status,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar("Cập nhật trạng thái thành công", {
            variant: "success",
          });
          onVisible && onVisible();
          setOpenStatus(null);
        } else {
          enqueueSnackbar("Cập nhật trạng thái thất bại", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar("Cập nhật trạng thái thất bại", {
          variant: "error",
        });
      });
  };

  const ref: any = useRef(null);

  useClickOutside(ref, () => {
    setOpenStatus(null);
  });

  return (
    <Box sx={{ width: "100%" }} className="tab-custom">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0A6836",
            },
          }}
          variant="fullWidth">
          {weekList?.map((item, key: number) => {
            return (
              <Tab
                key={key}
                value={key}
                {...a11yProps(key)}
                label={
                  <span
                    className={clsx("plan-create__tab", {
                      "plan-create__tab__active": value === key,
                    })}>
                    {dateFormat(item)}
                  </span>
                }
              />
            );
          })}
        </Tabs>
      </Box>
      {weekList?.map((week, key: number) => {
        const listUpdate = list?.find((f: any) => f.key === value)?.details;

        const dataList = dataDetails?.filter(
          (f) => f.date === dateFormat(week, false, "YYYY-MM-DD")
        );

        return (
          <CustomTabPanel key={key} value={value} index={key}>
            {customerId ? (
              <>
                {listUpdate
                  ?.sort((a: any, b: any) => a.id - b.id)
                  ?.map((item: any, index: number) => {
                    return (
                      <OrderPlan
                        orderLabel={index + 1}
                        customerId={customerId}
                        data={item}
                        onChange={(data: any) => {
                          onUpdateOrder(value, index, data);
                          setVisibleChange(!visibleChange);
                        }}
                        onRemoveOrder={() => {
                          handleRemoveOrder(value, index);
                          setVisibleChange(!visibleChange);
                        }}
                        checkWeekUpdate={checkWeekUpdate}
                      />
                    );
                  })}

                {checkWeekUpdate && (
                  <div
                    onClick={() => {
                      const keyValue = list?.find((f: any) => f.key === value)?.details?.length;
                      if (list?.length > 0 && list?.map((m: any) => m.key)?.includes(key)) {
                        onAddOrder(value, {
                          date: dateFormat(week),
                          id: keyValue + 1,
                          items: [{ itemId: 0, quantity: 1 } as OrderItem],
                        });
                      } else {
                        onAddOrder(
                          value,
                          [
                            {
                              date: dateFormat(week),
                              id: 1,
                              items: [{ itemId: 0, quantity: 1 } as OrderItem],
                            },
                          ],
                          true
                        );
                      }
                    }}
                    className="btn-border-confirm-style"
                    style={{ width: "fit-content" }}>
                    <AddIcon style={{ fontSize: "1.8rem", marginRight: "1rem" }} /> Thêm kế hoạch
                    đơn hàng
                  </div>
                )}

                {list?.length > 0 && (
                  <>
                    <Divider style={{ margin: "2.4rem 0" }} />
                    <div className="plan-create__group">
                      <div>Tổng sản lượng đặt theo tuần</div>
                      <div className="plan-create__group__total">
                        {total}
                        &#160;kg
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              dataList
                ?.sort((a, b) => getFirstNumberOfStr(a.label) - getFirstNumberOfStr(b.label))
                ?.map((item) => {
                  return (
                    <Accordion key={item?.id} className="tab-custom__accordion" defaultExpanded>
                      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
                        <span>
                          {item.label}
                          {!!item.orderGroup
                            ? ` - Mã đơn hàng tham chiếu: ${item.orderGroup?.code}`
                            : ""}
                          <ArrowDropDownIcon className="custom-arrow" />
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {statusAll === PlanStatus.CONFIRMED &&
                            item.status === PlanDetailStatus.APPROVED && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#0A6836",
                                    padding: "1rem",
                                    border: "1px solid",
                                    height: "32px",
                                    opacity: item.orderGroup ? 0.5 : 1,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!item.orderGroup) setNotiCreOrder(item.id);
                                  }}>
                                  <AddIcon
                                    style={{ fontSize: "2rem", marginRight: 5, marginTop: 3 }}
                                  />
                                  Tạo đơn hàng
                                </div>
                                <div className="tab-custom__accordion__linedashed"></div>
                              </div>
                            )}
                          {statusAll === PlanStatus.CHANGING &&
                            item.status === PlanDetailStatus.CHANGING &&
                            (new Date(item.date).getTime() < new Date().getTime() ? (
                              <div className="col-text">
                                Bạn không được chỉnh sửa kế hoạch đặt hàng trong quá khứ
                              </div>
                            ) : (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOrderUpdate(item);
                                }}>
                                <EditIcon color={"#061938"} size={[22, 22]} viewBox={[16, 16]} />
                              </div>
                            ))}
                          {statusAll !== PlanStatus.CHANGING && (
                            <div style={{ position: "relative", display: "flex" }}>
                              <div style={{ marginRight: 2 }}>
                                {StatusUIUtil.renderBgStatusPlanDetail(item.status, true)}
                              </div>
                              {/* {statusAll === PlanStatus.CONFIRMED &&
                                item.status === PlanDetailStatus.APPROVED && (
                                  <div>
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenStatus(item);
                                      }}>
                                      {StatusUIUtil.renderBgStatusPlanDetail(item.status)}
                                    </div>
                                    {openStatus !== null && openStatus?.id === item.id && (
                                      <div className="tab-custom__accordion__menu" ref={ref}>
                                        <div
                                          className="tab-custom__accordion__menu__item"
                                          onClick={() =>
                                            changeStatus(
                                              item.id?.toString(),
                                              PlanDetailStatus.APPROVED
                                            )
                                          }>
                                          {StatusUIUtil.renderCircleStatusPlanDetail(
                                            PlanDetailStatus.APPROVED
                                          )}
                                        </div>
                                        <div
                                          className="tab-custom__accordion__menu__item"
                                          onClick={() =>
                                            changeStatus(
                                              item.id?.toString(),
                                              PlanDetailStatus.CHANGING
                                            )
                                          }>
                                          {StatusUIUtil.renderCircleStatusPlanDetail(
                                            PlanDetailStatus.CHANGING
                                          )}
                                        </div>
                                        <div
                                          className="tab-custom__accordion__menu__item"
                                          onClick={() =>
                                            changeStatus(
                                              item.id?.toString(),
                                              PlanDetailStatus.REJECTED
                                            )
                                          }>
                                          {StatusUIUtil.renderCircleStatusPlanDetail(
                                            PlanDetailStatus.REJECTED
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )} */}
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} className="tab-custom__accordion__label">
                          <Grid item xs={6} style={{ marginBottom: 12 }}>
                            Sản phẩm
                          </Grid>
                          <Grid item xs={6} style={{ marginBottom: 12 }}>
                            Sản lượng
                          </Grid>
                        </Grid>
                        {item.items?.map((product: ItemsPlanOutputModel, index: number) => {
                          return (
                            <Grid
                              container
                              key={index}
                              spacing={3}
                              className="tab-custom__accordion__line">
                              <Grid item xs={6} style={{ marginBottom: 12 }}>
                                {`${product?.item?.code} - ${product?.item?.name}` || TEXT_HOLDER}
                              </Grid>
                              <Grid item xs={6} style={{ marginBottom: 12 }}>
                                {+product?.quantity || TEXT_HOLDER}
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Divider />
                        <div className="tab-custom__accordion__totalRow">
                          <div>Tổng sản lượng theo ngày</div>
                          <div className="total">
                            {item?.items?.reduce((sum: any, item: any) => {
                              return sum + +item.quantity;
                            }, 0)}
                            &#160;kg
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
            )}
          </CustomTabPanel>
        );
      })}

      <Notification
        visible={notiCreOrder !== null}
        title="Tạo đơn hàng"
        subTitle={<span>Bạn muốn tạo đơn hàng từ kế hoạch?</span>}
        icon={
          <div className={`notification-icon-box yellow`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setNotiCreOrder(null);
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                if (notiCreOrder) onCreateOrderFeedByPlan(notiCreOrder);
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setNotiCreOrder(null);
        }}
      />

      <Notification
        visible={dataOrd !== null}
        icon={
          <div className={`notification-icon-box green`}>
            <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        title="Bạn đã đặt hàng thành công!"
        subTitle={
          <span>
            <br />
            Đơn hàng <span style={{ color: "#41AD49", fontWeight: 700 }}>#{dataOrd?.code}</span> của
            bạn dự kiến sẽ được giao vào{" "}
            {moment(dataOrd?.orders?.[0]?.requiredDate).format("\\ngà\\y DD t\\háng MM nă\\m YYYY")}
            <br />
            <br />
            Thông tin chi tiết về đơn hàng đã được gửi về email Nếu không tìm thấy, xin vui lòng
            kiểm tra hộp thư spam của bạn.
          </span>
        }
        actions={
          <button
            className="btn-confirm-style"
            onClick={() => {
              history.push(APP_ROUTES.ORDER_DETAIL_FEED.replace(":orderId", dataOrd?.id));
            }}>
            Chi tiết đơn hàng
          </button>
        }
        onClose={() => setDataOrd(null)}
      />

      {orderUpdate !== null && customerIdDetail && onVisible && (
        <OnlyOrder
          detail={orderUpdate}
          onClose={() => setOrderUpdate(null)}
          customerId={customerIdDetail}
          onVisible={onVisible}
        />
      )}
    </Box>
  );
};

export default TabCustom;
