import urlSound from "@Assets/audio/gf-noti.mp3";
import NewOrder from "@Assets/images/new-order.png";
import Loading from "@Components/Loading";
import ResultOrder from "@Components/ResultOrder";
import { GFButton, GFTypography } from "@Components/ui";
import { GFBackdrop } from "@Components/ui/GFCommon";
import GFPageFooter from "@Components/ui/GFPageFooter";
import GFPageHeader from "@Components/ui/GFPageHeader";
import { APP_ROUTES } from "@Constants";
import OrderBoarSemenBody from "@Containers/Order/components/BoarSemen/OrderBoarSemen/OrderBoarSemenBody";
import OrderBoarSemenHeader from "@Containers/Order/components/BoarSemen/OrderBoarSemen/OrderBoarSemenHeader";
import OrderBoarSemenProductSearch from "@Containers/Order/components/BoarSemen/OrderBoarSemen/OrderBoarSemenProductSearch";
import { zodResolver } from "@hookform/resolvers/zod";
import useGetMe from "@Hooks/useGetMe";
import { CustomerOutputModel, SourceType } from "@Models";
import { Box, Dialog, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomerService, OrderService } from "@Services";
import { toServerDate } from "@Utils";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { GetAllBoarSemenCustomerPayload } from "src/dtos/customer.dto";
import { CreateOrderPayload, createOrderSchema } from "src/schemas/order.schema";
import { DivisionCode, FarmType, PigTypeCode } from "src/types";

const ContentWrapper = styled(Grid)({
  height: "calc(100vh - 6.4rem)",
  overflow: "hidden",
});

type OrderBoarSemenProps = {
  type: FarmType;
};

const OrderBoarSemen = ({ type }: OrderBoarSemenProps) => {
  const { t } = useTranslation(["translation", "order"]);
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);
  const [notiOrder, setNotiOrder] = useState({ visible: false, newOrder: false });
  const [loading, setLoading] = useState(false);
  const [dataOrd, setDataOrd] = useState<any>({});
  const { getDivisionIdByCode, getPigTypeIdByCode, profile } = useGetMe();
  const pigTypeId = getPigTypeIdByCode(PigTypeCode.BoarSemen);
  const divisionId = getDivisionIdByCode(SourceType.FARM, DivisionCode.BoarSemen);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerOutputModel | null>(null);
  const [customerFeeds, setCustomerFeeds] = useState<any[]>([]);
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateOrderPayload>({
    resolver: zodResolver(createOrderSchema),
    mode: "onChange",
    defaultValues: {},
  });
  
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search);

  const handleRemoveProduct = (index: number) => {
    const currentFields = getValues("orderDetails");
    const updatedFields = currentFields.filter((_, i) => i !== index);
    setValue("orderDetails", updatedFields);
  };

  const onSubmit = async (data: CreateOrderPayload) => {
    const clonedData = _.cloneDeep(data);
    const minRequiredDate = _.minBy(
      clonedData?.orderDetails,
      (item) => item.requiredDate
    )?.requiredDate;

    const payload: CreateOrderPayload = {
      ...clonedData,
      currency: "cash",
      source: SourceType.FARM,
      totalDiscount: 0,
      vat: 0,
      isInternal: type === FarmType.Internal,
      orderAmount: type === FarmType.Internal ? 0 : Number(totalPrice),
      address: selectedCustomer?.address || "",
      requiredDate: toServerDate(minRequiredDate || new Date()),
      orderDetails: clonedData.orderDetails.map((item) => {
        const { item: _, ...rest } = item;

        return {
          ...rest,
          requiredDate: toServerDate(item.requiredDate),
          subTotal: Number(item.unitPrice) * Number(item.quantity),
        };
      }),
    };

    try {
      const response = await OrderService.createOrder(payload as any);
      setNotiOrder({ visible: true, newOrder: true });
      setDataOrd(response.data?.data);
      setPlaying(true);
    } catch (error) {
      enqueueSnackbar("Tạo đơn hàng thất bại", { variant: "error" });
    }
  };

  const handleCreateOrder = async (isDraft: boolean) => {
    setValue("isDraft", isDraft);
    setValue("creatorId", profile?.id as any);
    setValue("itemGroupTypeId", pigTypeId as any);
    setValue("customerId", selectedCustomer?.id as any);

    if (orders?.length === 0) {
      enqueueSnackbar("Vui lòng chọn sản phẩm", { variant: "error" });
      return;
    }

    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      // if (!isDraft) {
      //   try {
      //     const debtData = await OrderService.getDebtCustomer(customerId);
      //     if (_.get(debtData, "data.debt", 0) >= 1000) {
      //       enqueueSnackbar(
      //         "Khách hàng vượt quá công nợ cho phép đặt hàng, vui lòng kiểm tra lại",
      //         {
      //           variant: "error",
      //         }
      //       );
      //       setLoading(false);

      //       return;
      //     }
      //   } catch (error) {
      //     setLoading(false);
      //   }
      // }

      await handleSubmit(onSubmit)();
      setLoading(false);
    } else {
      enqueueSnackbar("Dữ liệu không hợp lệ", { variant: "error" });
    }
  };

  const orders = watch("orderDetails");

  const totalPrice = orders?.reduce((sum, val) => {
    return sum + Number(val.quantity) * Number(val.unitPrice);
  }, 0);

  const handleClose = () => {
    let url = `${APP_ROUTES.ORDER_FARM}?itemGroupTypeId=${searchQuery.get("itemGroupType")}`;

    if (type === FarmType.Internal) {
      url = `${APP_ROUTES.ORDER_INTERNAL}-farm?itemGroupTypeId=${searchQuery.get("itemGroupType")}`;
    }

    history.push(url);
  };

  const handleChangeCustomerSaleOrg = async (customer: any) => {
    const salesOrgCode = (customer?.code || customer?.sapCustomer || "").slice(-4);
    setSelectedCustomer(customer);

    if (salesOrgCode) {
      try {
        const payload: GetAllBoarSemenCustomerPayload & {
          salesOrgCode: string;
        } = {
          limit: 99999,
          page: 1,
          source: SourceType.FEED,
          hasCusDiv: true,
          salesOrgCode,
        };
        const response = await CustomerService.getAllBoarSemenCustomer(payload);
        setCustomerFeeds(response.data?.data || []);
      } catch (error) {
        enqueueSnackbar("Lấy thông tin khách hàng thất bại", { variant: "error" });
      }
    } else {
      setCustomerFeeds([]);
    }
  };
  return (
    <Dialog fullScreen open={true}>
      <GFPageHeader title="Tạo đơn hàng - Tinh heo" onClose={handleClose} variant="contained" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentWrapper container>
          <Grid item xs={9} border="1px solid var(--border-color)">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "calc(100vh - 64px)",
              }}>
              <OrderBoarSemenHeader
                control={control}
                setValue={setValue}
                customer={selectedCustomer}
                onSelectCustomer={handleChangeCustomerSaleOrg}
                sourceType={SourceType.FARM}
                type={type}
              />
              <OrderBoarSemenBody
                control={control}
                onRemoveProduct={handleRemoveProduct}
                onSetValue={setValue}
                customerFeeds={customerFeeds}
                data={orders || []}
                type={type}
              />
              <Box mt="auto">
                <GFPageFooter>
                  <GFButton
                    variant="outlined"
                    type="button"
                    onClick={() => handleCreateOrder(true)}>
                    Lưu giỏ hàng
                  </GFButton>
                  <GFButton
                    variant="contained"
                    type="button"
                    minWidth={15}
                    onClick={() => handleCreateOrder(false)}>
                    Đặt hàng
                  </GFButton>
                </GFPageFooter>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            {selectedCustomer?.id ? (
              <OrderBoarSemenProductSearch
                control={control}
                customerId={selectedCustomer.id.toString()}
                divisionId={divisionId}
                sourceType={SourceType.FARM}
                itemGroupId={pigTypeId?.toString()}
              />
            ) : (
              <Box display="flex" flexDirection="column" rowGap={2} alignItems="center" mt={10}>
                <img src={NewOrder} alt="new-order" />
                <GFTypography variant="body1" fontWeight={600} color="var(--gray)">
                  {t("order:ENTER_CUSTOMER_INFORMATION")}
                </GFTypography>
                <GFTypography fontWeight={600} color="var(--gray)">
                  {t("order:START_CREATING_ORDER")}
                </GFTypography>
              </Box>
            )}
          </Grid>
        </ContentWrapper>
      </form>

      {loading && (
        <GFBackdrop>
          <Loading />
        </GFBackdrop>
      )}

      <ResultOrder
        sourceType="farm"
        visible={notiOrder.visible}
        newOrder={notiOrder.newOrder}
        dataOrd={dataOrd}
        setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
        onClose={handleClose}
        type={type}
      />
    </Dialog>
  );
};
export default OrderBoarSemen;
