/* eslint-disable array-callback-return */
import { APP_ROUTES, SubTab } from "@Constants";
import { ReportPermission, SourceType } from "@Models";
import { CustomerService } from "@Services";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
// import { APP_ROUTES } from "@Constants";
import { useTranslation } from "react-i18next";
import { ModuleItem } from "./routes";
import "./SubNavbar.scss";

export type Props = {
  routes?: ModuleItem[];
  source?: string;
};

const SubNavbar: React.FC<Props> = (props) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const location = useLocation();
  const [profile, setProfile] = useState<any>(null);
  // const isActiveRoute = (pathname: string): boolean => {
  //   return location.pathname === pathname;
  // };

  useEffect(() => {
    const fetchData = async () => {
      await CustomerService.getAuthProfile()
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            setProfile(res.data);
          }
        })
        .catch((err) => {});
    };
    fetchData();
  }, []);

  const checkURL = SubTab.filter(
    (s) =>
      profile?.role?.dataAccessPermissions?.some((d: any) => d.source === s.source) ||
      profile?.employee?.some((d: any) => d.source === s.source)
  ).filter((s) => profile?.role?.permissions.includes(s.permission));

  const checkPermissionDashBoard = SubTab.some((s) =>
    profile?.role?.permissions.includes(s.permission)
  );

  useEffect(() => {
    if (checkPermissionDashBoard && checkURL) {
      history.push(checkURL[0].path + "-" + checkURL[0].source);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === APP_ROUTES.DASHBOARD + "-" + checkURL?.[0]?.source]);

  if (props.routes?.length === 0) return <div></div>;

  const getStringAfterHyphen = (str: string) => {
    const parts = str.lastIndexOf("-");
    if (parts !== -1) {
      return str.substring(parts + 1);
    } else {
      return null;
    }
  };

  return (
    <div className="wrapper">
      <div className={"sub-route-menu"}>
        <div style={{ display: "flex" }}>
          {location.pathname.substring(0, 10) !== APP_ROUTES.DASHBOARD &&
            props.routes?.map(({ title, defaultIcon, activeIcon, path, source, withoutSuffix }) => {
              // const isActive = isActiveRoute(path);
              let isActive = path + "-" + source === location.pathname;

              if (withoutSuffix) {
                isActive = path === location.pathname;
              }

              if (
                location.pathname.includes(APP_ROUTES.ORDER_FARM) &&
                path + "-" + source === APP_ROUTES.ORDER_FARM
              ) {
                isActive = true;
              }
              // const classes = clsx(
              //   "nav-item",
              //   path + "-" + source === location.pathname && "tab-green-style btn--shiny"
              // );
              const checkSourceType =
                !!source && Object.keys(SourceType).includes(source.toUpperCase());

              if (checkSourceType) {
                if (profile?.role?.dataAccessPermissions?.some((d: any) => d.source === source)) {
                  return (
                    <Link key={title} to={withoutSuffix ? path : path + "-" + source}>
                      <div
                        className={
                          isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                        }>
                        <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                        <span className="title">{t(title)}</span>
                      </div>
                    </Link>
                  );
                }
              } else {
                return (
                  <Link key={title} to={withoutSuffix ? path : path + "-" + source}>
                    <div
                      className={
                        isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                      }>
                      <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                      <span className="title">{t(title)}</span>
                    </div>
                  </Link>
                );
              }
            })}
          {location.pathname.includes(APP_ROUTES.WAREHOUSE) &&
            props.routes?.map(({ title, defaultIcon, activeIcon, path, source, withoutSuffix }) => {
              // const isActive = isActiveRoute(path);
              let isActive = path + "-" + source === location.pathname;
              if (
                location.pathname.includes(APP_ROUTES.ORDER_FARM) &&
                path + "-" + source === APP_ROUTES.ORDER_FARM
              ) {
                isActive = true;
              }
              // const classes = clsx(
              //   "nav-item",
              //   path + "-" + source === location.pathname && "tab-green-style btn--shiny"
              // );
              const checkSourceType =
                !!source && Object.keys(SourceType).includes(source.toUpperCase());

              if (checkSourceType) {
                if (profile?.role?.dataAccessPermissions?.some((d: any) => d.source === source)) {
                  return (
                    <Link key={title} to={path + "-" + source}>
                      <div
                        className={
                          isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                        }>
                        <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                        <span className="title">{t(title)}</span>
                      </div>
                    </Link>
                  );
                }
              } else {
                return (
                  <Link key={title} to={withoutSuffix ? path : path + "-" + source}>
                    <div
                      className={
                        isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                      }>
                      <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                      <span className="title">{t(title)}</span>
                    </div>
                  </Link>
                );
              }
            })}
          {location.pathname.substring(0, 10) === APP_ROUTES.DASHBOARD &&
            props.routes?.map(({ title, defaultIcon, activeIcon, path, source }) => {
              // const isActive = isActiveRoute(path);
              // const isActive = source === location.pathname.slice(-4);
              const isActive = source === getStringAfterHyphen(location.pathname);
              // const classes = clsx(
              //   "nav-item",
              //   source === location.pathname.slice(-4) && "tab-green-style btn--shiny"
              // );

              const linkMock = (paths: any, sources: any) => {
                const fullPath = `${paths}-${sources}`;

                switch (fullPath) {
                  case "/dashboard-farm":
                    if (profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_DEBT)) {
                      if (
                        profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_REVENUE)
                      ) {
                        return "/dashboard/report-revenue-farm";
                      } else {
                        return "/dashboard/debt-farm";
                      }
                    }
                    return APP_ROUTES.ACCESS_DENIED;

                  case "/dashboard-vet":
                    if (profile?.role?.permissions.includes(ReportPermission.MANAGE_REPORT_DEBT)) {
                      return "/dashboard/debt-vet";
                    }
                    return APP_ROUTES.ACCESS_DENIED;

                  default:
                    return fullPath;
                }
              };

              if (profile?.role?.dataAccessPermissions?.some((d: any) => d.source === source)) {
                return (
                  <Link key={title} to={linkMock(path, source)}>
                    <div
                      className={
                        isActive ? "tab-green-style btn--shiny" : "nav-item nav-item-hover"
                      }>
                      <div className="icon">{isActive ? activeIcon : defaultIcon}</div>
                      <span className="title">{t(title)}</span>
                    </div>
                  </Link>
                );
              }
            })}
        </div>
        {/* {(location.pathname === APP_ROUTES.DASHBOARD + "-feed" ||
          location.pathname === APP_ROUTES.DASHBOARD + "-farm") && ( */}

        {location.pathname.substring(0, 10) === APP_ROUTES.DASHBOARD && (
          <div className="sub-tab-route-menu">
            {(() => {
              let checkSubtab = checkURL
                .filter((f) => {
                  if (f.source !== SourceType.FEED && f.source !== SourceType.FARM) {
                    return f.source === location.pathname.slice(-3);
                  }
                  return f.source === location.pathname.slice(-4);
                })
                .map(({ label, path, source, permission }) => {
                  const classes = clsx(
                    "nav-item",
                    path + "-" + source === location.pathname && "tab-style"
                  );

                  if (profile?.role?.permissions.includes(permission)) {
                    return (
                      <Link key={label} to={path + "-" + source}>
                        <div className={classes} style={{ flexDirection: "column" }}>
                          <span className="title">{label}</span>
                          {path + "-" + source === location.pathname && (
                            <hr
                              style={{
                                width: "10rem",
                                height: "0.3rem",
                                background: "#41AD49",
                                border: 0,
                                position: "absolute",
                                bottom: 0,
                              }}
                            />
                          )}
                        </div>
                      </Link>
                    );
                  }
                });
              checkSubtab = _.compact(checkSubtab);

              return checkSubtab?.length > 0 && checkSubtab;
            })()}
          </div>
        )}

        {(location.pathname.includes(APP_ROUTES.ORDER_FARM) ||
          location.pathname.includes("order-internal-farm")) && (
          <div className="sub-tab-route-menu">
            {(() => {
              if (profile) {
                let cloneItemGroupTypeFarm = [...profile?.itemGroupTypeFarm];
                cloneItemGroupTypeFarm.sort((a, b) => {
                  return a.id - b.id;
                });
                const tab = location.pathname.includes("order-internal-farm")
                  ? "order-internal-farm"
                  : "order-farm";
                const query = new URLSearchParams(location.search);
                let checkSubtab = cloneItemGroupTypeFarm.map((item: any, index: any) => {
                  const isActive = Number(query.get("itemGroupTypeId")) === item.id;

                  const classes = clsx("nav-item", isActive && "tab-style");

                  return (
                    <Link key={item?.id} to={`/${tab}?itemGroupTypeId=${item?.id}`}>
                      <div className={classes} style={{ flexDirection: "column" }}>
                        <span className="title">{item?.name}</span>
                        {isActive && (
                          <hr
                            style={{
                              width: "10rem",
                              height: "0.3rem",
                              background: "#41AD49",
                              border: 0,
                              position: "absolute",
                              bottom: 0,
                            }}
                          />
                        )}
                      </div>
                    </Link>
                  );
                  // }
                });
                checkSubtab = _.compact(checkSubtab);

                return checkSubtab?.length > 0 && checkSubtab;
              }
            })()}
          </div>
        )}

        {/* {location.pathname.includes(APP_ROUTES.WAREHOUSE) && (
          <div className="sub-tab-route-menu">
            {(() => {
              if (profile) {
                let cloneItemGroupTypeFarm = [...profile?.itemGroupTypeFarm];
                cloneItemGroupTypeFarm.sort((a, b) => {
                  return a.id - b.id;
                })
                const query = new URLSearchParams(location.search);
                console.log(location.search);
                console.log(location.search);
                let checkSubtab = cloneItemGroupTypeFarm.map((item: any, index: any) => {
                  const isActive = Number(query.get("itemGroupTypeId")) === item.id;

                  const classes = clsx("nav-item", isActive && "tab-style");

                  return (
                    <Link key={item?.id} to={`/order-farm?itemGroupTypeId=${item?.id}`}>
                      <div className={classes} style={{ flexDirection: "column" }}>
                        <span className="title">{item?.name}</span>
                        {isActive && (
                          <hr
                            style={{
                              width: "10rem",
                              height: "0.3rem",
                              background: "#41AD49",
                              border: 0,
                              position: "absolute",
                              bottom: 0,
                            }}
                          />
                        )}
                      </div>
                    </Link>
                  );
                  // }
                });
                checkSubtab = _.compact(checkSubtab);

                return checkSubtab?.length > 0 && checkSubtab;
              }
            })()}

          </div>
   )} */}
      </div>
    </div>
  );
};

export default SubNavbar;
