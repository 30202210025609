import React, { useCallback, useEffect, useRef } from "react";
import "./index.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Align, Font, IColumn } from "./const";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
interface ITable {
  columns: IColumn[];
  data: any;
  hasMore: boolean;
  handlePagination: () => void;
}

const isLastSticky = (columns: IColumn[], index: number) => {
  return columns[index]?.sticky && columns.length - 1 > index && !columns[index + 1]?.sticky;
};

const customTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
        },
      },
    },
  },
});

function CTable({ columns, data, hasMore, handlePagination }: ITable) {
  const randomKey = useRef(uuidv4());
  const generateUniqueKey = (suffix: string | number) => `${randomKey.current}-${suffix}`;

  const TableCellCustom = (props: any) => {
    return <TableCell {...props} onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler} />;
  };

  const getChildren = (columns: IColumn[], isHasParent: boolean): any => {
    return columns.map((item: IColumn, index: number) => {
      if (!item.children) {
        if (isHasParent) {
          return (
            <TableCell
              key={generateUniqueKey(index)}
              style={{
                minWidth: item.width || 200,
                maxWidth: item.width || 200,
              }}
              className={`column-custom column-custom-style`}>
              {item.label}
            </TableCell>
          );
        }
      } else {
        return getChildren(item.children, true);
      }
    });
  };

  const handleData = (
    columns: IColumn[],
    record: any,
    parentKey: string,
    indexRow: number
  ): any => {
    return columns.map((column: IColumn, index) => {
      // Trường hợp parent có child => chạy vào child lấy data ra
      if (column.children) {
        return handleData(column.children, record, column.key, indexRow);
      } else {
        const lastSticky = isLastSticky(columns, index);
        return handleCustomRender(
          record,
          column.render,
          column.width,
          column?.sticky,
          index,
          indexRow,
          lastSticky,
          column.align,
          column.font
        );
      }
    });
  };

  const handleCustomRender = (
    record: any,
    render?: any,
    width?: number | string,
    sticky?: boolean,
    index?: number,
    indexRow?: number,
    lastSticky?: boolean,
    align?: Align,
    font?: Font
  ) => {
    return (
      <TableCellCustom
        key={uuidv4()}
        className={`column-custom-style ${align ?? ""} ${font ?? ""} ${
          sticky ? "column-sticky" : ""
        } ${lastSticky ? "last-sticky" : ""}`}
        style={{
          minWidth: width || 200,
          maxWidth: width || 200,
          left: sticky ? (index ?? 0) * 200 : "",
        }}>
        {render(record)}
      </TableCellCustom>
    );
  };

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const mouseMoveHandler = function (e: any) {
    const ele = document.querySelector(".table-custom");
    if (ele) {
      const dx = e.clientX - pos.x;
      ele.scrollLeft = pos.left - dx;
    }
  };

  const mouseDownHandler = function (e: any) {
    const ele = document.querySelector(".table-custom");
    if (ele) {
      pos = {
        left: ele.scrollLeft,
        top: ele.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    }
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);
  };

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        if (hasMore) {
          handlePagination();
        }
      }
    },
    [hasMore, handlePagination]
  );

  const observerRef = useRef(null);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (observerRef.current) observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [handleObserver]);

  return (
    <ThemeProvider theme={customTheme}>
      <TableContainer
        component={Paper}
        className="table-custom"
        style={{ maxWidth: "100%", overflow: "auto", fontSize: "1.8rem" }}>
        <Table stickyHeader style={{ tableLayout: "auto" }}>
          <TableHead className="table-header-custom">
            <TableRow>
              {columns.map((item, index) => {
                const lastSticky = isLastSticky(columns, index);
                if (!item?.children) {
                  return (
                    <TableCell
                      className={`column-custom column-header ${
                        item?.sticky ? "header-sticky" : ""
                      } ${lastSticky ? "last-sticky" : ""}`}
                      rowSpan={2}
                      key={uuidv4()}
                      style={{
                        minWidth: item?.width || 200,
                        maxWidth: item?.width || 200,
                        left: item?.sticky ? index * 200 : "",
                      }}>
                      <Tooltip title={item.label}>
                        <span>{item.label}</span>
                      </Tooltip>
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={uuidv4()}
                      colSpan={item.children.length}
                      className="header-custom-child column-custom-style"
                      style={{
                        minWidth: item?.width ?? 'auto',
                        maxWidth: item?.width ?? 'auto',
                      }}>
                      <Tooltip title={item.label}>
                        <span>{item.label}</span>
                      </Tooltip>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
            <TableRow className="header-child">{getChildren(columns, false)}</TableRow>
          </TableHead>
          <TableBody
            style={{
              fontWeight: 450,
            }}>
            {data.map((item: any, index: number) => {
              if (data.length !== index + 1) {
                return <TableRow key={uuidv4()}>{handleData(columns, item, "", index)}</TableRow>;
              } else {
                return (
                  <TableRow key={uuidv4()} ref={observerRef}>
                    {handleData(columns, item, "", index)}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}

// Do react.memo chỉ shallow comparison nên sẽ bị chậm khi re-render
const areEqual = (prevProps: any, nextProps: any) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default React.memo(CTable, areEqual);
