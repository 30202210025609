/* eslint-disable */
import CompareOrderFarm from "@Components/CompareOrderFarm";
import DO from "@Components/DO";
import { CampCoordinatorIcon } from "@Components/Icons/CampCoordinatorIcon";
import { CancelIcon } from "@Components/Icons/CancelIcon";
import { CloseOrderIcon } from "@Components/Icons/CloseOrderIcon";
import { DoIcon } from "@Components/Icons/DoIcon";
import { OptionIcon } from "@Components/Icons/OptionIcon";
import { SearchDetailIcon } from "@Components/Icons/SearchDetailIcon";
import { SingleCupIcon } from "@Components/Icons/SingleCupIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SelectInput from "@Components/SelectInput";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
    APP_ROUTES,
    OrderStatusERP,
    ReasonCloseOrder,
    ReasonStatus,
    TEXT_ASTERISK,
    TEXT_HOLDER,
    UpdateHistory,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import {
    ButtonType,
    CancelOrCompleteOrder,
    CustomerOutputModel,
    FARM_ORDER_SUB_STATUS,
    ItemPermission,
    OrderPermission,
    OrderStatus,
    OrderStatusFarm,
    Reason,
    indexOfStatus,
    indexOfSubStatus,
} from "@Models";
import { Nullable } from "@Models/shared";
import { CustomerService, OrderService } from "@Services";
import {
    PermissionUtil,
    StatusUIUtil,
    checkButtonDisplayWithChildOrders,
    checkButtonDisplayWithStatus,
    checkLinkImage,
    checkStatusGreater,
    currencyFormat,
    formatAmount,
    renderErrorImageItem,
    renderImageProNull,
} from "@Utils";
import {
    PersonAddAlt1 as AddCustomerIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Autorenew as AutorenewIcon,
    Clear as ClearIcon,
    ExpandMore as ExpandMoreIcon,
    PriorityHigh as PriorityHighIcon,
    PriorityHighRounded as PriorityHighRoundedIcon,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DetachOrder from "../Farm/DetachOrder/DetachOrder";
import DetachSiteOrder from "../Farm/DetachSiteOrder";
import SiteOrder from "../Farm/SiteOrder/SiteOrder";
import "./OrderDetailFarm.scss";
import { useTranslation } from "react-i18next";
import TruckLoadModal from "../Farm/TruckLoad";
import ModalDeliveryFee from "../Farm/DeliveryFee/ModalDeliveryFee";
import ModalLessContainer from "../Farm/LessContainerLoad";
import { get, isArray } from "lodash";
import VehicleInfo from "./components/VehicleInfo";
import { IVehicleInfo } from "@Models/veihicle.model";
import ModalEditDeliveryFee from "../Farm/DeliveryFee/ModalEditDeliveryFee";

const OrderDetailFarmPork: React.FC = () => {
    console.log(123);
    const { t } = useTranslation(["translation", "order"]);
    const theme = createTheme({
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "1.4rem",
                        lineHeight: "2.2rem",
                        color: "#FFFFFF",
                        backgroundColor: "#161315",
                        fontFamily: "SVN-Gotham",
                        fontWeight: 700,
                        border: "0.1rem solid #161315",
                    },
                },
            },
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const location = useLocation<any>();
    const mounted = useRef(false);
    const { orderId }: { orderId: string } = useParams();
    const [order, setOrder] = useState<Nullable<any>>(null);
    const [openListDO, setOpenListDO] = useState<number>(0);
    const [orderDO, setOrderDO] = useState(null);
    const [open, setOpen] = useState<string | false>(false);
    const dispatchProfile = useAppDispatch();
    const { data: profile } = useAppSelector(LoadMe);
    const [openTruckLoad, setOpenTruckLoad] = useState({ order: null, type: "order", open: false });
    const [openDeliveryFee, setOpenDeliveryFee] = useState({ order: null, open: false, isEdit: false });
    const [openEditDeliveryFee, setOpenEditDeliveryFee] = useState({ order: null, open: false, isEdit: false });
    const [openModalLessContainer, setOpenModalLessContainer] = useState({ order: null, type: "order", open: false });

    useEffect(() => {
        dispatchProfile(loadProfile());
    }, [dispatchProfile]);

    const handleChangeOpen = (open: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setOpen(isExpanded ? open : false);
    };

    const [openDetachOrder, setOpenDetachOrder] = useState<boolean>(false);
    const [detachOrder, setDetachOrder] = useState<{
        orderId: number;
        orderLines: any;
        childOrders: any;
        remainQuan: number;
        remainDim: number;
        address: string;
        note: string;
        requiredDate: string;
    }>();
    const [siteOrder, setSiteOrder] = useState<{ order: any; data: any; orderline: any }>({
        order: undefined,
        data: {},
        orderline: undefined,
    });
    const [detachSiteOrder, setDetachSiteOrder] = useState<{ order: any; data: any }>({
        order: undefined,
        data: {},
    });
    const [visibleAddCustomer, setVisibleAddCustomer] = useState<boolean>(false);
    const [openChangeCus, setOpenChangeCus] = useState<boolean>(false);
    const [customerValue, setCustomerValue] = useState<any>(undefined);
    const [notiSiteCheckLead, setNotiSiteCheckLead] = useState<boolean>(false);
    const [compareDo, setCompareDo] = useState<boolean>(false);
    const [submitCancel, setSubmitCancel] = useState<string>("");
    const [showCancel, setShowCancel] = useState<{
        title: string;
        type: string;
    }>({
        title: "",
        type: "",
    });
    const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
    const [reason, setReason] = useState<string>("");
    const [childOrdersId, setChildOrdersId] = useState<number>(0);
    const [DOrderId, setDOrderId] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [border, setBorder] = useState<boolean>(true);
    const [openNotiAssignCustomerToOrder, setOpenNotiAssignCustomer] = useState<boolean>(false);

    const fetchData: () => Promise<void> = async () => {
        try {
            const result = await OrderService.getOrderById(+orderId);
            if (mounted.current) {
                setOrder(result.data.data);
            }
        } catch (err: any) {
            console.log(err);
            if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
        }
    };

    const childOrderCheck =
        order &&
        order?.orders
            .find((orders: any) => orders?.id === location?.state?.orderId)
            ?.childOrders.find((child: any) => child?.id === location?.state?.childOrderId);

    const scrollToMyRef = () =>
        (
            document.getElementById(location?.state?.childOrderId?.toString()) as HTMLElement
        ).scrollIntoView({
            behavior: "smooth",
        });

    useEffect(() => {
        if (childOrderCheck?.id === location?.state?.childOrderId) {
            setBorder(true);
        }
        if (document.getElementById(location?.state?.childOrderId?.toString())) {
            scrollToMyRef();
        }
        setTimeout(() => setBorder(false), 3000);
    }, [order]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, [order]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (location?.state?.orderId && location?.state?.childOrderId) {
            setOpen(location?.state?.orderId.toString());
        } else {
            order?.orders.length === 1 &&
                order?.orders[0].childOrders.length > 0 &&
                setOpen(order.orders[0].id.toString());
        }
    }, [order, location?.state?.childOrderId, order?.orders[0].childOrders.length > 0]);

    const gridSize: any =
        12 /
        (open
            ? order?.orders
                .filter((f: any) => f.id.toString() === open)?.[0]
                ?.childOrders.filter((child: any) => child.status !== OrderStatus.CANCELLED)?.length + 2
            : order?.orders?.length > 1
                ? order?.orders?.length + 2
                : order?.orders?.length + 1);

    const checkLead = order?.orders?.[0].lead !== null;

    let remainQuanity = 0;
    let remainDimen = 0;

    const checkSite = order?.orders.some((o: any) => o.site !== null);

    //func Hủy/Đóng đơn hàng và đơn điều phối
    // nếu type === "O" => hủy/đóng đơn hàng
    // type === "D" => hủy/đóng đơn điều phối
    // type === "G" => hủy/đóng đơn tổng
    const cancelOrder = async (
        id: number,
        type: string,
        isCancel: boolean,
        reasonStatus?: string,
        reason?: string
    ) => {
        if (reasonStatus === Reason.OTHER && reason === "") {
            enqueueSnackbar("Yêu cầu nhập lý do", {
                preventDuplicate: true,
                variant: "error",
            });
            setSubmitCancel("");
            return;
        }
        try {
            const res = await OrderService.cancelOrderFarm({ id, type, isCancel, reasonStatus, reason });
            if ((res && res.status === 200) || res.status === 201) {
                if (type === "O") {
                    if (isCancel) {
                        enqueueSnackbar(t("order:ORDER_CANCELLED_SUCCESSFULLY"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    } else {
                        enqueueSnackbar(t("order:ORDER_CLOSED_SUCCESSFULLY"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    }
                } else if (type === "G") {
                    if (isCancel) {
                        enqueueSnackbar(t("order:TOTAL_ORDER_CANCELLED_SUCCESSFULLY"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    } else {
                        enqueueSnackbar(t("order:TOTAL_ORDER_CLOSED_SUCCESSFULLY"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    }
                } else {
                    if (isCancel) {
                        enqueueSnackbar(t("order:DISPATCH_ORDER_CANCELLED_SUCCESSFUL"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    } else {
                        enqueueSnackbar(t("order:DISPATCH_ORDER_CLOSED_SUCCESSFULLY"), {
                            variant: "success",
                            preventDuplicate: true,
                        });
                    }
                }
                setCancelValue(Reason.NOT_RESPONSE);
                setSubmitCancel("");
                setShowCancel({ title: "", type: "" });
            }
            await fetchData();
        } catch (error) {
            enqueueSnackbar(t("order:CANCELLATION_FAILED"), {
                variant: "error",
                preventDuplicate: true,
            });
        }
    };

    const checkPermissionCancelOrderGroup = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_GROUP_CANCEL,
        true
    );

    const checkPermissionCancelOrder = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_CANCEL,
        true
    );

    const checkPermissionCancelChildOrder = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_ALLOCATE_CANCEL,
        true
    );

    const checkPermissionCompleteOrderGroup = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_GROUP_COMPLETE,
        true
    );

    const checkPermissionCompleteOrder = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_COMPLETE,
        true
    );

    const checkPermissionCompleteChildOrder = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_ALLOCATE_COMPLETE,
        true
    );

    const checkPermissionAllocate = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_ALLOCATE,
        true
    );

    const checkPermissionSplit = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_SPLIT,
        true
    );
    const checkPermissionViewDetail = PermissionUtil.check(
        profile?.role?.permissions,
        OrderPermission.MANAGE_ORDER_VIEWDETAILS,
        true
    );

    const checkPermissionViewPrice = PermissionUtil.check(
        profile?.role?.permissions,
        ItemPermission.MANAGE_ITEM_VIEW_PRICE,
        true
    );

    const handleAllocate = async () => {
        try {
            setLoading(true)
            const res = await OrderService.allocateOrderFarm({
                orderGroupId: Number(orderId),
                source: "farm"
            })
            setLoading(false);
            if (res.status === 200 || res.status === 201) {
                enqueueSnackbar(t("order:ALLOCATE_ORDERS_SUCCESSFULL"), {
                    variant: "success",
                });
            }
            else {
                enqueueSnackbar(t("order:ALLOCATE_ORDERS_FAILED"), {
                    variant: "error",
                });
            }
            await fetchData();
        }
        catch (error) {
            setLoading(false);
            enqueueSnackbar(t("order:ALLOCATE_ORDERS_FAILED"), {
                variant: "error",
            })
        }
    }

    const createERPFarm = async (orderGroupId: number, orderChildId: number) => {
        try {
            const res = await OrderService.createERPFarm({
                orderGroupId: orderGroupId,
                orderId: orderChildId,
            });
            if (res.status === 200 || res.status === 201) {
                setLoading(false);
                if (res.data.code === 424) {
                    enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
                        variant: "error",
                    });
                } else {
                    enqueueSnackbar(t("order:ERP_UPDATE_SUCCESSFUL"), {
                        variant: "success",
                    });
                }
                await fetchData();
            } else {
                setLoading(false);
                enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
                    variant: "error",
                });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(t("order:ERP_UPDATE_FAILED"), {
                variant: "error",
            });
        }
    };

    const onAssignCustomerForLead = async (
        orderGroupId: number,
        fetchAll: boolean,
        leadId: number,
        customerId: number
    ) => {
        try {
            const res = await CustomerService.assignCustomerFarmOrder({
                orderGroupId: orderGroupId,
                fetchAll: fetchAll,
                customerId: customerId,
                leadId: leadId,
            });
            setOpenNotiAssignCustomer(false);
            if (res.status === 200 || res.status === 201)
                enqueueSnackbar(t("order:ORDER_ALLOCATION_SUCCESSFUL"), {
                    preventDuplicate: true,
                    variant: "success",
                });
            setOpenChangeCus(false);

            await fetchData();
        } catch (error) {
            setOpenNotiAssignCustomer(false);
            enqueueSnackbar(t("order:ORDER_ALLOCATION_FAILED"), {
                preventDuplicate: true,
                variant: "error",
            });
            console.log(error);
        }
    };

    // const checkIsDisplayVehicleButton = () => {
    //   // Nếu đã có thông tin order thì không hiển thị
    //   let isDisplay = false;
    //   order.orders.map((item: any, index: number) => {
    //     if (item.orderShippingVehicles.length === 0) {
    //       isDisplay = true;
    //     }
    //   })
    //   return isDisplay;
    // }
    console.log("re-render");

    const checkIsDisplayShippingFeeButton = () => {
        let isDisplay = false;
        order.orders.map((item: any, index: number) => {
            if (item.orderShippingFeeDetail) {
                isDisplay = true;
            }
        })
        return isDisplay;
    }

    const confirmOrder = async () => {
        try {
            setLoading(true);
            const res = await OrderService.confirmOrder({
                id: +orderId,
                type: "G",
                isCancel: false,
            })
            if (res.status === 200 || res.status === 201) {
                enqueueSnackbar("Xác nhận đơn hàng thành công", {
                    preventDuplicate: true,
                    variant: "success",
                });
                fetchData();
            }
        } catch (error) {
            enqueueSnackbar("Xác nhận đơn hàng thất bại", {
                preventDuplicate: true,
                variant: "error",
            });
        }
        finally {
            setLoading(false);
        }
    }

    const buttonVehicleOrderGroup = () => {
        // Đối với nút điều phối xe ở đơn tổng cần thoả điều kiện: 
        // + Trạng thái đơn hàng phải là confirmed
        // + Trạng thái subStatus phải > confirmedAwaitingDebtApproval
        // + Các đơn con không có tách đơn
        let isDisplay = false;
        if (order.status === OrderStatusFarm.CONFIRMED) {
            const orderSubStatus = order.subStatus;
            if (
                orderSubStatus === FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP
            ) {
                isDisplay = true;
            }
        }
        order.orders.map((orderlines: any) => {
            if (orderlines.childOrders.length != 0) {
                isDisplay = false;
            }
        })
        return isDisplay;
    }

    const getDimensionOfOrder = (dimensionToShow: string, listDimension: any) => {
        if (isArray(listDimension)) {
            const listDimensionToShow = listDimension.filter((item, index) => {
                if (item.includes(String(dimensionToShow))) {
                    return true;
                }
                else {
                    return false;
                }
            })
            if (listDimensionToShow.length > 0) {
                let dimensionList = [...listDimensionToShow[0]];
                if (dimensionList[1] === "null") {
                    return "trên " + dimensionList[0];
                } else if (dimensionList[0] === "null") {
                    return "dưới " + dimensionList[1];
                } else {
                    return dimensionList[0] + " - " + dimensionList[1];
                }
            }
        }
        else {
            return `${listDimension}`;
        }

    }


    function capitalizeFirstLetter(str: string) {
        if (!str) return str; // Kiểm tra chuỗi trống
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return (
        <Dialog fullScreen open={true}>
            <div className="order-farm-detail">
                <SimpleModalHeader
                    title={`${t("order:ORDER_DETAIL")} - ${capitalizeFirstLetter(get(order, "itemGroupType.name", "").toLowerCase())}`}
                    onClose={() => {
                        history.push({
                            pathname: `${APP_ROUTES.ORDER_FARM}`,
                            state: {
                                startTime: location.state?.startTime,
                                endTime: location.state?.endTime,
                                status: location.state?.status,
                            },
                            search: `?itemGroupTypeId=${get(order, "itemGroupType.id", "8")}`,
                        });
                    }}
                />
                <div className="order-farm-detail-container">
                    {!!order ? (
                        <>
                            <div className="content">
                                <div>
                                    <Grid container className="header">
                                        <Grid item xs={4} className="left">
                                            {openChangeCus && customerValue === undefined ? (
                                                <SelectInput<CustomerOutputModel>
                                                    getSource={"farm"}
                                                    onChange={(value) => {
                                                        setCustomerValue(value);
                                                    }}
                                                    onClick={() => {
                                                        setVisibleAddCustomer(true);
                                                    }}
                                                    className={!visibleAddCustomer ? "customer-textfield" : "active"}
                                                    placeholder={
                                                        !visibleAddCustomer
                                                            ? t("SELECT_BY_NAME", { NAME: t("CUSTOMER") })
                                                            : t("SEARCH_BY_NAME_CUSTOMER_CODE")
                                                    }
                                                    getOptionLabel={(opt) => opt.fullName}
                                                    renderOption={(p) => (
                                                        <>
                                                            <img
                                                                src={
                                                                    checkLinkImage(p.account?.avatar as any) || renderImageProNull()
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: "4.8rem",
                                                                    height: "4.8rem",
                                                                    borderRadius: "50%",
                                                                    marginRight: "1rem",
                                                                }}
                                                                onError={renderErrorImageItem()}
                                                            />
                                                            <div>
                                                                <span style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                                                                    {p.fullName} - {p.code}
                                                                </span>
                                                                <br />
                                                                <span style={{ fontSize: "1.4rem", opacity: "0.4" }}>
                                                                    {p.phoneNumber}
                                                                </span>
                                                            </div>
                                                        </>
                                                    )}
                                                    actionConfig={{
                                                        action: CustomerService.getAllCustomer,
                                                    }}
                                                    dropdownBoxMaxHeight="45rem"
                                                    icon={<AddCustomerIcon />}
                                                />
                                            ) : (
                                                <>
                                                    <img
                                                        src={
                                                            checkLinkImage(
                                                                customerValue === undefined
                                                                    ? order.orders[0].customer?.account?.avatar
                                                                    : customerValue?.account?.avatar
                                                            ) || renderImageProNull()
                                                        }
                                                        alt="Avata"
                                                        onError={renderErrorImageItem()}
                                                    />
                                                    <div>
                                                        <div className="name">
                                                            {customerValue === undefined ? (
                                                                <>
                                                                    {order.orders[0].customer !== null
                                                                        ? order.orders[0].customer.fullName || TEXT_HOLDER
                                                                        : order.orders[0].lead.fullName || TEXT_HOLDER}
                                                                    &#160;-&#160;
                                                                    {order.orders[0].customer !== null
                                                                        ? order.orders[0].customer.code || TEXT_HOLDER
                                                                        : order.orders[0].lead.code || TEXT_HOLDER}
                                                                    {order.orders[0].customer === null && (
                                                                        <span
                                                                            style={{
                                                                                color: "#838283",
                                                                                fontWeight: 400,
                                                                                fontSize: "1.4rem",
                                                                                marginLeft: "1rem",
                                                                            }}>
                                                                            <span>&#9679;&ensp;</span> {t("order:POTENTIAL")}
                                                                        </span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {customerValue?.fullName} - {customerValue?.code}
                                                                </>
                                                            )}
                                                        </div>
                                                        {customerValue === undefined
                                                            ? order.orders[0].customer !== null
                                                                ? order.orders[0].customer.phoneNumber || TEXT_HOLDER
                                                                : order.orders[0].lead.phoneNumber || TEXT_HOLDER
                                                            : customerValue?.phoneNumber}
                                                    </div>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            className="col"
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                paddingLeft: "2rem",
                                                alignItems: "center",
                                            }}>
                                            <div>
                                                {order.orders.map((orders: any, index: number) => {
                                                    const check = Array.from(
                                                        new Set(
                                                            order.orders.map((orders: any) => {
                                                                return orders.salesOrg.description;
                                                            })
                                                        )
                                                    );
                                                    return (
                                                        <Grid item xs={12} key={index}>
                                                            <div className="label">
                                                                {check.length > 1
                                                                    ? t("order:BUSINESS_UNIT") + " " + (index + 1)
                                                                    : check[index] !== undefined
                                                                        ? t("order:BUSINESS_UNIT")
                                                                        : ""}
                                                            </div>
                                                            {/* {orders.division.description} */}
                                                            {check[index]}
                                                        </Grid>
                                                    );
                                                })}
                                            </div>
                                            {order.status !== OrderStatus.COMPLETED && (
                                                <div className="col-text">{t("order:PIG_PRICE_TIME_OF_DELIVERY")}</div>
                                            )}
                                            <div>
                                                <div className="label">
                                                    Phương thức cọc
                                                </div>
                                                <div>
                                                    {get(order, "deposit.rate1", "0")}%
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} justifyContent="flex-end" display="flex">
                                            {openChangeCus ? (
                                                <>
                                                    <button
                                                        className="btn-cancel-style"
                                                        onClick={() => {
                                                            setOpenChangeCus(false);
                                                            setCustomerValue(undefined);
                                                        }}>
                                                        {t("CANCEL")}
                                                    </button>
                                                    <button
                                                        className="btn-confirm-style"
                                                        onClick={() => {
                                                            setOpenNotiAssignCustomer(true);
                                                        }}>
                                                        {t("CONFIRM")}
                                                    </button>
                                                </>
                                            ) : (
                                                (order.status !== OrderStatus.CANCELLED &&
                                                    order.status !== OrderStatus.COMPLETED &&
                                                    order.status !== OrderStatusFarm.HALF_COMPLETED &&
                                                    order.status !== OrderStatusFarm.VERIFIED) ?
                                                    (
                                                        <Select
                                                            variant="outlined"
                                                            className="btn-green-style"
                                                            renderValue={() => <span>{t("order:OPTIONS")}</span>}
                                                            displayEmpty
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <OptionIcon color={"#fff"} size={[22, 22]} viewBox={[21, 21]} />
                                                                </InputAdornment>
                                                            }
                                                            inputProps={{ readOnly: false }}
                                                            MenuProps={{
                                                                className: "select-menu-list",
                                                                anchorOrigin: {
                                                                    vertical: 60,
                                                                    horizontal: "left",
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "left",
                                                                },
                                                            }}>
                                                            {
                                                                order?.status === OrderStatus.CREATED && (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            handleAllocate();
                                                                            // setOpenChangeCus(true);
                                                                        }}>
                                                                        {t("order:ALLOCATE_ORDERS")}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                            {order.orders.some((o: any) => o.status === OrderStatus.CREATED) && (
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        if (order !== null && !checkSite) {
                                                                            // history.push(
                                                                            //   APP_ROUTES.UPDATE_ORDER_FARM.replace(":orderId", orderId)
                                                                            // );
                                                                            history.push({
                                                                                pathname: APP_ROUTES.UPDATE_ORDER_FARM.replace(
                                                                                    ":orderId",
                                                                                    orderId
                                                                                ),
                                                                                state: {
                                                                                    type: "UPDATE",
                                                                                },
                                                                            });
                                                                        } else {
                                                                            enqueueSnackbar(t("order:ORDER_CANNOT_EDITED"), {
                                                                                preventDuplicate: true,
                                                                                variant: "error",
                                                                            });
                                                                        }
                                                                    }}>
                                                                    {t("order:EDIT_ORDER")}
                                                                </MenuItem>
                                                            )}
                                                            {checkLead && (
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setOpenChangeCus(true);
                                                                    }}>
                                                                    {t("order:ALLOCATE_CUSTOMERS")}
                                                                </MenuItem>
                                                            )}
                                                            {
                                                                order?.status === OrderStatus.HALF_COMPLETED ||
                                                                    order?.status === OrderStatus.VERIFIED
                                                                    ? checkPermissionCompleteOrderGroup && (
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                setShowCancel({
                                                                                    title: t("order:CLOSE_TOTAL_ORDER"),
                                                                                    type: "complete",
                                                                                });
                                                                            }}>
                                                                            {t("order:CLOSE_ORDER")}
                                                                        </MenuItem>
                                                                    )
                                                                    :
                                                                    (order?.status === OrderStatusFarm.DRAFT ||
                                                                        order?.status === OrderStatusFarm.CREATED ||
                                                                        (order?.status === OrderStatusFarm.CONFIRMED &&
                                                                            (indexOfSubStatus.findIndex((item, index) => item === order?.subStatus) < indexOfSubStatus.findIndex((item, index) => item === "firstDepositMade")))) &&
                                                                    order?.status !== OrderStatus.COMPLETED &&
                                                                    checkPermissionCancelOrderGroup && (
                                                                        <MenuItem
                                                                            onClick={() => {
                                                                                if (
                                                                                    order?.status === OrderStatus.CREATED ||
                                                                                    order?.status === OrderStatus.CONFIRMED
                                                                                ) {
                                                                                    // cancelOrder(order?.id, "G", true);
                                                                                    setShowCancel({
                                                                                        title: t("order:CANCEL_TOTAL_ORDER"),
                                                                                        type: "cancel",
                                                                                    });
                                                                                }
                                                                            }}>
                                                                            {t("order:CANCEL_ORDER")}
                                                                        </MenuItem>
                                                                    )}
                                                            {
                                                                buttonVehicleOrderGroup() &&
                                                                (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setOpenTruckLoad({ order: order, type: "order", open: true })
                                                                        }}>
                                                                        Điều phối xe
                                                                    </MenuItem>
                                                                )
                                                            }
                                                            {
                                                                // VEHICLE_NOT_ASSIGNED => hiển thị nút thêm chi phí vận chuyển => Sau khi thêm chi phí vận chuyển xong
                                                                (order.subStatus === FARM_ORDER_SUB_STATUS.VEHICLE_NOT_ASSIGNED) &&
                                                                (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setOpenDeliveryFee({ open: true, order, isEdit: false });
                                                                        }}>
                                                                        Thêm chi phí vận chuyển
                                                                    </MenuItem>
                                                                )

                                                            }
                                                            {
                                                                order.subStatus === FARM_ORDER_SUB_STATUS.VEHICLE_ASSIGNED_NOT_SYNCED_WITH_ERP && (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setOpenEditDeliveryFee({ open: true, order, isEdit: true });
                                                                        }}>
                                                                        Chỉnh sửa chi phí vận chuyển
                                                                    </MenuItem>
                                                                )

                                                            }
                                                        </Select>
                                                    ) : (order.status === OrderStatusFarm.HALF_COMPLETED ||
                                                        order.status === OrderStatusFarm.VERIFIED) && (
                                                        <button
                                                            type="button"
                                                            className="btn-confirm-erp"
                                                            style={{
                                                                backgroundColor: "green",
                                                                color: "white",
                                                                border: "none",
                                                                fontWeight: 700,
                                                                borderRadius: 8,
                                                                padding: 8,
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                confirmOrder();
                                                            }}
                                                        >
                                                            Xác nhận đơn hàng
                                                        </button>
                                                    )
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>

                                <Grid container className="order-farm-detail-info">
                                    <Grid item xs={4}>
                                        <div className="label">{t("order:ORDER_ID")}</div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            #{order.code}
                                            {checkPermissionViewDetail && (
                                                <ThemeProvider theme={theme}>
                                                    <Tooltip title={t("order:COMPARE_VALUE")}>
                                                        <div
                                                            onClick={() => setCompareDo(true)}
                                                            style={{
                                                                cursor: "pointer",
                                                                margin: "0.4rem 2rem",
                                                            }}>
                                                            <SearchDetailIcon
                                                                color={"#0A6836"}
                                                                size={[25, 27]}
                                                                viewBox={[20, 20]}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                </ThemeProvider>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="label">{t("order:ORDER_TIME")}</div>
                                        {moment(order.createdAt).format("YYYY-MM-DD - HH:mm")}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="label">{t("order:ORDER_PLACER")}</div>
                                        {order.createdBy?.account?.code && order.createdBy?.account?.code + " - "}
                                        {order.createdBy?.fullName}
                                    </Grid>
                                    <Grid item xs={2} className="order-farm-detail-info-status">
                                        <div className="label">{t("STATUS")}</div>
                                        {/* BA confirm trạng thái của đơn hàng sẽ là trạng thái nhỏ nhất của các đơn con */}
                                        {StatusUIUtil.renderBgStatusFarmNew(order.status, "farm", order.subStatus)}
                                    </Grid>
                                </Grid>

                                <div className={"list list-farm"}>
                                    {order.orders.map((g: any, idx: number) => {
                                        return (
                                            <Accordion
                                                key={idx}
                                                expanded={open === g.id.toString()}
                                                className="list-child"
                                                onClick={() => handleChangeOpen(open as any)}
                                                style={{
                                                    fontFamily: "SVN-Gotham",
                                                }}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            style={{
                                                                color: "#0A6836",
                                                                fontSize: "2.2rem",
                                                            }}
                                                        />
                                                    }
                                                    className="title"
                                                    style={{
                                                        fontFamily: "SVN-Gotham",
                                                    }}>
                                                    <div className="order-farm-detail-header">
                                                        <div
                                                            className="detail"
                                                            onClick={() => {
                                                                open === g.id.toString()
                                                                    ? setOpen(false)
                                                                    : setOpen(g.id.toString());
                                                            }}>
                                                            {open === g.id.toString() ? "Thu gọn" : t("DETAIL")}
                                                        </div>
                                                        <div className="name">
                                                            Sản phẩm ({g.orderlines.length})
                                                        </div>
                                                        <div className="quan">{t("QUANTITY")}</div>
                                                        <div className="dimension">Khung trọng lượng</div>
                                                        <div className="weight">{t("TOTAL_WEIGHT")}</div>
                                                        <div className="price">{t("UNIT_PRICE")}</div>
                                                        <div className="overshoot">Phí giống</div>
                                                        <div className="overshoot">Phần vượt</div>
                                                        <div className="total">{t("TOTAL_PRICE")}</div>
                                                        {/* Hiển thị trạng thái của order */}
                                                        <div className="chip">
                                                            {StatusUIUtil.renderBgStatusFarmNew(g.status, "farm", g?.subStatus)}
                                                        </div>
                                                    </div>
                                                    {g.orderlines.map((o: any, idx: number) => {
                                                        return (
                                                            <div key={idx}>
                                                                <div style={{ background: "#f7f7f7" }} className="item">
                                                                    <div className="detail">
                                                                        <img
                                                                            src={
                                                                                checkLinkImage(o.item.images?.picture) ||
                                                                                renderImageProNull(order?.orderType)
                                                                            }
                                                                            alt={o.item.shortName}
                                                                            onError={renderErrorImageItem(order?.orderType)}
                                                                        />
                                                                    </div>
                                                                    <div className="name">
                                                                        <div>
                                                                            {o.item.name}
                                                                            {/* &ensp;&#8226;&ensp;
                                      {Array.isArray(o?.item.dimension)
                                        ? o.item.dimension
                                            .filter((f: any) => {
                                              return +f[0] === +o.dimensionToShow;
                                            })
                                            .map((d: any) => {
                                              return (
                                                <span>{d[1] === null ? "Trên " + d[0] : d[1]}</span>
                                              );
                                            })
                                        : o.item.dimension}
                                      &ensp;{o.baseUoM} */}
                                                                            <div className="code">{o.item.code}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="quan">
                                                                        {o.orderQuantity ? parseFloat(o.orderQuantity) : TEXT_HOLDER}
                                                                    </div>
                                                                    <div className="dimension">
                                                                        {getDimensionOfOrder(o.dimensionToShow, o.item.dimension)}
                                                                    </div>

                                                                    <div className="weight">
                                                                        {currencyFormat(+o.dimension)}&ensp;kg
                                                                        {/* {o.baseUoM} */}
                                                                    </div>
                                                                    <div className="price">
                                                                        {checkPermissionViewPrice
                                                                            ? currencyFormat(o.price)
                                                                            : TEXT_ASTERISK}
                                                                        &ensp;đ/
                                                                        <span style={{ color: "#41ad49" }}>{o.item.baseUoM}</span>
                                                                    </div>
                                                                    {/* Phí giống */}
                                                                    <div className="overshoot">
                                                                        {(() => {
                                                                            // Phí giống chỉ có giá trị khi priceType = fixed
                                                                            {/* Nếu priceType = increment thì bên cột Phần vượt sẽ = additionValue và Phí giống = 0 */ }
                                                                            // Nếu priceType = fixed thì bên cột Phần vượt sẽ = 0 và Phí giống = additionValue
                                                                            // nếu priceType = bs thì cả 2 cột phần vượt và phí giống đều = 0 
                                                                            switch (o.prices?.priceType) {
                                                                                case "fixed":
                                                                                    return (
                                                                                        <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(o?.attachValue)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {o.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    );
                                                                                default:
                                                                                    return (
                                                                                        <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(0)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {o.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    );
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                    {/* Phần vượt */}
                                                                    <div className="overshoot">
                                                                        {(() => {
                                                                            // Phần vượt chỉ có giá trị khi priceType = increment
                                                                            switch (o.prices?.priceType) {
                                                                                case "increment":
                                                                                    return (
                                                                                        <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(o?.attachValue)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {o.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    );
                                                                                default:
                                                                                    return (
                                                                                        <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(0)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {o.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    );
                                                                            }
                                                                        })()}
                                                                    </div>
                                                                    <div className="total">
                                                                        {checkPermissionViewPrice
                                                                            ? currencyFormat(o.subTotal)
                                                                            : TEXT_ASTERISK}
                                                                        &ensp;đ
                                                                    </div>
                                                                    {/* Hiển thị menu item */}
                                                                    {/* Menu Item này sẽ tổng cộng có 5 button:
                                      + Tách đơn hàng
                                      + Điều phối trại
                                      + Huỷ đơn hàng
                                      + Điều phối xe
                                      + Ghép xe
                                      + 
                                  */}
                                                                    <div className="chip">
                                                                        {g.status !== OrderStatus.CANCELLED &&
                                                                            (
                                                                                <Select
                                                                                    variant="outlined"
                                                                                    className="btn-green-style"
                                                                                    renderValue={() => (
                                                                                        <span className="spanText">{t("order:OPTIONS")}</span>
                                                                                    )}
                                                                                    displayEmpty
                                                                                    startAdornment={
                                                                                        <InputAdornment position="start">
                                                                                            <OptionIcon
                                                                                                color={"#312F30"}
                                                                                                size={[16, 16]}
                                                                                                viewBox={[21, 21]}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    }
                                                                                    inputProps={{ readOnly: false }}
                                                                                    MenuProps={{
                                                                                        className: "select-menu-list select-menu-list-farm",
                                                                                    }}>
                                                                                    {/* Nút tách đơn này sẽ không hiển thị */}
                                                                                    {g?.canSeparate && checkPermissionSplit &&
                                                                                        checkButtonDisplayWithStatus(g.status, ButtonType.SPLIT_ORDER, g?.farmSubStatus)
                                                                                        &&
                                                                                        // Giữ lại logic cũ. Và cũng k được tách đơn hàng khi đã đồng bộ về erp rồi
                                                                                        (
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    const child = g.childOrders.filter(
                                                                                                        (child: any) =>
                                                                                                            child.status !== OrderStatus.CANCELLED
                                                                                                    );
                                                                                                    const sumChildOrder = child.reduce(
                                                                                                        (sum: number, item: any) => {
                                                                                                            for (
                                                                                                                let index = 0;
                                                                                                                index < item.orderlines.length;
                                                                                                                index++
                                                                                                            )
                                                                                                                return (
                                                                                                                    sum + +item.orderlines[index].orderQuantity
                                                                                                                );
                                                                                                        },
                                                                                                        0
                                                                                                    );
                                                                                                    remainQuanity = o.orderQuantity - sumChildOrder;
                                                                                                    remainDimen =
                                                                                                        remainQuanity > 0
                                                                                                            ? (+o.dimension / +o.orderQuantity) *
                                                                                                            remainQuanity
                                                                                                            : Array.isArray(o.item.dimension)
                                                                                                                ? +o.dimension / +o.orderQuantity
                                                                                                                : o.item.dimension;

                                                                                                    setDetachOrder({
                                                                                                        orderId: g.id,
                                                                                                        orderLines: o,
                                                                                                        childOrders: g.childOrders,
                                                                                                        remainQuan: remainQuanity,
                                                                                                        remainDim: remainDimen,
                                                                                                        address: g.address,
                                                                                                        note: o.note,
                                                                                                        requiredDate: g.requiredDate,
                                                                                                    });

                                                                                                    setOpenDetachOrder(true);
                                                                                                }}>
                                                                                                <SingleCupIcon
                                                                                                    color={"#5A5959"}
                                                                                                    size={[30, 30]}
                                                                                                    viewBox={[26, 26]}
                                                                                                />
                                                                                                {t("order:SPLIT_ORDER")}
                                                                                            </MenuItem>
                                                                                        )}
                                                                                    {/* Ba Confirm nếu có childOrders thì sẽ không có nút điều phối trại này nữa */}
                                                                                    {/* Đối với nút điều phối trại theo logic cũ thì lúc nào cũng hiển thị */}
                                                                                    {
                                                                                        checkButtonDisplayWithStatus(g.status, ButtonType.SITE_ORDER, g?.farmSubStatus) &&
                                                                                        checkButtonDisplayWithChildOrders(g, "orderlines") &&
                                                                                        checkPermissionAllocate &&
                                                                                        checkPermissionSplit &&
                                                                                        +g.orderlines?.[0]?.orderQuantity * 1.1 >
                                                                                        g?.childOrders
                                                                                            ?.filter(
                                                                                                (s: any) => s.status !== OrderStatus.CANCELLED
                                                                                            )
                                                                                            ?.reduce((sum: number, item: any) => {
                                                                                                sum += +item.orderlines?.[0]?.orderQuantity;
                                                                                                return sum;
                                                                                            }, 0) && (
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    checkLead
                                                                                                        ? setNotiSiteCheckLead(true)
                                                                                                        : setDetachSiteOrder({
                                                                                                            order: order,
                                                                                                            data: g,
                                                                                                        });
                                                                                                }}>
                                                                                                <CampCoordinatorIcon
                                                                                                    color={"#5A5959"}
                                                                                                    size={[30, 30]}
                                                                                                    viewBox={[26, 26]}
                                                                                                />
                                                                                                {t("order:CAMP_COORDINATION")}
                                                                                            </MenuItem>
                                                                                        )}
                                                                                    {/* Nút huỷ đơn hàng giữ nguyên logic cũ */}
                                                                                    {
                                                                                        // g?.status === OrderStatusFarm.CREATED || g?.status === OrderStatusFarm.DRAFT ||
                                                                                        // (g?.status === OrderStatusFarm.CONFIRMED &&
                                                                                        //   (indexOfSubStatus.findIndex((item, index) => item === g?.farmSubStatus) < indexOfSubStatus.findIndex((item, index) => item === "firstDepositMade")))) &&
                                                                                        // g?.status === OrderStatus.HALF_COMPLETED ||
                                                                                        // g?.status === OrderStatus.VERIFIED
                                                                                        checkButtonDisplayWithStatus(g?.status, ButtonType.CLOSE_ORDER, g?.farmSubStatus)
                                                                                            ? checkPermissionCompleteOrder && (
                                                                                                <MenuItem
                                                                                                    onClick={() => {
                                                                                                        setShowCancel({
                                                                                                            title: CancelOrCompleteOrder.COMPLETE,
                                                                                                            type: "complete",
                                                                                                        });
                                                                                                        setChildOrdersId(g?.id);
                                                                                                    }}>
                                                                                                    <CloseOrderIcon
                                                                                                        color={"#5A5959"}
                                                                                                        size={[24, 24]}
                                                                                                        viewBox={[18, 16]}
                                                                                                    />
                                                                                                    {t("order:CLOSE_ORDER")}
                                                                                                </MenuItem>
                                                                                            )
                                                                                            : g?.status !== OrderStatus.COMPLETED &&
                                                                                            checkPermissionCancelOrder && (
                                                                                                <MenuItem
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            g?.status === OrderStatus.CREATED ||
                                                                                                            g?.status === OrderStatus.CONFIRMED
                                                                                                        ) {
                                                                                                            setShowCancel({
                                                                                                                title: CancelOrCompleteOrder.CANCEL,
                                                                                                                type: "cancel",
                                                                                                            });
                                                                                                            setChildOrdersId(g?.id);
                                                                                                        }
                                                                                                    }}>
                                                                                                    <CancelIcon
                                                                                                        color={"#5A5959"}
                                                                                                        size={[30, 30]}
                                                                                                        viewBox={[24, 24]}
                                                                                                    />
                                                                                                    {t("order:CANCEL_ORDER")}
                                                                                                </MenuItem>
                                                                                            )}
                                                                                    {/* Điều phối xe khi đơn hàng đang ở trạng thái confirm và không có tách đơn 
                                            => nếu có tách đơn sẽ điều phối từng đơn */}
                                                                                    {g?.status === OrderStatusFarm.CONFIRMED &&
                                                                                        g?.childOrders.length === 0 &&
                                                                                        g?.farmSubStatus === FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP
                                                                                        && (
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    setOpenTruckLoad({ order: g, type: "orderlines", open: true });
                                                                                                }}
                                                                                            >
                                                                                                <SingleCupIcon
                                                                                                    color={"#5A5959"}
                                                                                                    size={[30, 30]}
                                                                                                    viewBox={[24, 24]}
                                                                                                />
                                                                                                Điều phối xe
                                                                                            </MenuItem>
                                                                                        )}
                                                                                    {g?.status === OrderStatusFarm.CONFIRMED &&
                                                                                        g?.childOrders.length === 0 &&
                                                                                        g?.farmSubStatus === FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP
                                                                                        && (
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    // Button ghép trên từng đơn cha
                                                                                                    // Đơn hàng không tách đơn
                                                                                                    setOpenModalLessContainer({ order: g, type: "orderlines", open: true });
                                                                                                }}
                                                                                            >
                                                                                                <SingleCupIcon
                                                                                                    color={"#5A5959"}
                                                                                                    size={[30, 30]}
                                                                                                    viewBox={[24, 24]}
                                                                                                />
                                                                                                Ghép xe
                                                                                            </MenuItem>
                                                                                        )}
                                                                                    {
                                                                                        checkButtonDisplayWithStatus(g.status, ButtonType.DELIVERY_NOTE, null)
                                                                                        && checkButtonDisplayWithChildOrders(g, "orderlines")
                                                                                        && (
                                                                                            <MenuItem
                                                                                                //  className="btn-camp"
                                                                                                onClick={() => {
                                                                                                    setOpenListDO(g.id);
                                                                                                    setOrderDO(g);
                                                                                                }}>
                                                                                                <DoIcon
                                                                                                    color={"#5A5959"}
                                                                                                    size={[22, 20]}
                                                                                                    viewBox={[18, 18]}
                                                                                                />
                                                                                                {t("order:DELIVERY_NOTE")}
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }
                                                                                </Select>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                {/* Thông tin điều phối xe */}
                                                                {open !== g.id.toString() && (
                                                                    <>
                                                                        <div className="ship" style={{ background: "#f7f7f7" }}>
                                                                            <div className="detail"></div>
                                                                            <div className="name">
                                                                                <div className="label">{t("order:REFERENCE")}</div>
                                                                                <div
                                                                                    // onClick={() => {
                                                                                    //   o.orderGFId && setOpenListDO(o.id);
                                                                                    // }}
                                                                                    style={{ color: "#41ad49" }}>
                                                                                    {g.orderGFId || TEXT_HOLDER}
                                                                                </div>
                                                                            </div>
                                                                            <div className="name">
                                                                                <div className="label">{t("order:DELIVERY_DATE")}</div>
                                                                                {g.requiredDate}
                                                                            </div>
                                                                            <div className="name">
                                                                                <div className="label">{"Giờ giao hàng"}</div>
                                                                                {g?.requiredTime?.delieryTime}
                                                                            </div>
                                                                            <div className="name">
                                                                                <div className="label">{t("FARM")}</div>
                                                                                {g?.site?.name || TEXT_HOLDER}
                                                                            </div>
                                                                            <div className="price">
                                                                                <div className="label">{t("NOTES")}</div>
                                                                                <span
                                                                                    style={{
                                                                                        color:
                                                                                            o.note !== "" && o.note !== null && o.note !== undefined
                                                                                                ? "#B8292F"
                                                                                                : "#5A5959",
                                                                                    }}>
                                                                                    {o.note || TEXT_HOLDER}
                                                                                </span>
                                                                            </div>
                                                                            <div className="unit">
                                                                                <div className="label">{"Địa chỉ"}</div>
                                                                                {g.address || TEXT_HOLDER}
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            // Thông tin xe orderGroup
                                                                            g.orderShippingVehicles.map((vehicleInfo: IVehicleInfo) =>
                                                                                <VehicleInfo data={vehicleInfo} />
                                                                            )
                                                                        }
                                                                        {
                                                                            // g.status === OrderStatusFarm.CONFIRMED && checkStatusGreater(g.subStatus, "subStatus", FARM_ORDER_SUB_STATUS.VEHICLE_NOT_ASSIGNED) &&
                                                                            g?.orderShippingFeeDetail?.value &&
                                                                            <div className="ship" style={{ background: "#f7f7f7" }}>
                                                                                <div className="detail"></div>
                                                                                <div className="name">
                                                                                    <div className="label">Chi phí vận chuyển</div>
                                                                                    {formatAmount(g?.orderShippingFeeDetail?.value)} đ
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        fontFamily: "SVN-Gotham",
                                                    }}>
                                                    {g.childOrders.map((o: any, idx: number) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                id={o?.id.toString()}
                                                                style={{
                                                                    background: "rgba(247, 247, 247, 0.4)",
                                                                    border: border
                                                                        ? location?.state?.childOrderId === o?.id
                                                                            ? "1px solid rgb(65, 173, 73)"
                                                                            : undefined
                                                                        : undefined,
                                                                    borderBottom: border
                                                                        ? location?.state?.childOrderId === o?.id
                                                                            ? "1px solid rgb(65, 173, 73)"
                                                                            : undefined
                                                                        : "1px solid #D6D5D6",
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        padding: "2.3rem 2rem 0 16rem",
                                                                    }}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                        }}>
                                                                        {
                                                                            checkButtonDisplayWithStatus(o.status, ButtonType.SITE_ORDER, o?.farmSubStatus) &&
                                                                            checkPermissionAllocate && (
                                                                                <button
                                                                                    disabled={o?.canCoordinate === false}
                                                                                    className="btn-camp"
                                                                                    onClick={() => {
                                                                                        checkLead
                                                                                            ? setNotiSiteCheckLead(true)
                                                                                            : setSiteOrder({
                                                                                                order: order,
                                                                                                data: o,
                                                                                                orderline: g,
                                                                                            });
                                                                                    }}>
                                                                                    <CampCoordinatorIcon
                                                                                        color={"#5A5959"}
                                                                                        size={[30, 30]}
                                                                                        viewBox={[26, 26]}
                                                                                    />
                                                                                    {t("order:CAMP_COORDINATION")}
                                                                                </button>
                                                                            )}
                                                                        {o.farmSubStatus === FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP && (
                                                                            <button
                                                                                className="btn-camp"
                                                                                onClick={() => {
                                                                                    console.log(o);
                                                                                    setOpenTruckLoad({ order: o, type: "childOrders", open: true })
                                                                                }}>
                                                                                <DoIcon
                                                                                    color={"#5A5959"}
                                                                                    size={[22, 20]}
                                                                                    viewBox={[18, 18]}
                                                                                />
                                                                                Điều phối xe
                                                                            </button>
                                                                        )}
                                                                        {(o.farmSubStatus === FARM_ORDER_SUB_STATUS.CONFIRMED_NOT_SYNCED_WITH_ERP) && (
                                                                            <button
                                                                                className="btn-camp"
                                                                                onClick={() => {
                                                                                    console.log(o);
                                                                                    setOpenModalLessContainer({ order: o, type: "childOrders", open: true });
                                                                                }}>
                                                                                <DoIcon
                                                                                    color={"#5A5959"}
                                                                                    size={[22, 20]}
                                                                                    viewBox={[18, 18]}
                                                                                />
                                                                                Ghép xe
                                                                            </button>
                                                                        )}
                                                                        {

                                                                            // (o?.status === OrderStatus.HALF_COMPLETED ||
                                                                            //   o?.status === OrderStatus.COMPLETED)
                                                                            checkButtonDisplayWithStatus(o.status, ButtonType.DELIVERY_NOTE, null)
                                                                            && (
                                                                                <button
                                                                                    className="btn-camp"
                                                                                    onClick={() => {
                                                                                        o.orderGFId && setOpenListDO(o.id);
                                                                                        setOrderDO(o);
                                                                                    }}>
                                                                                    <DoIcon
                                                                                        color={"#5A5959"}
                                                                                        size={[22, 20]}
                                                                                        viewBox={[18, 18]}
                                                                                    />
                                                                                    {t("order:DELIVERY_NOTE")}
                                                                                </button>
                                                                            )
                                                                        }

                                                                        {
                                                                            // o?.status === OrderStatus.HALF_COMPLETED ||
                                                                            //   o?.status === OrderStatus.VERIFIED
                                                                            //   ? o.status !== OrderStatus.CANCELLED &&
                                                                            checkButtonDisplayWithStatus(o.status, ButtonType.CLOSE_ORDER, o?.farmSubStatus) ?
                                                                                checkPermissionCompleteChildOrder && (
                                                                                    <button
                                                                                        className="btn-camp"
                                                                                        onClick={() => {
                                                                                            // cancelOrder(o?.id, "D", false);
                                                                                            setShowCancel({
                                                                                                title: t("order:CLOSE_DISTRIBUTION_ORDER"),
                                                                                                type: "complete",
                                                                                            });
                                                                                            setDOrderId(o?.id);
                                                                                        }}>
                                                                                        <CloseOrderIcon
                                                                                            color={"#5A5959"}
                                                                                            size={[24, 24]}
                                                                                            viewBox={[18, 16]}
                                                                                        />
                                                                                        {t("order:CLOSE_ORDER")}
                                                                                    </button>
                                                                                )
                                                                                : o.status !== OrderStatus.CANCELLED &&
                                                                                o?.status !== OrderStatus.COMPLETED &&
                                                                                checkPermissionCancelChildOrder && (
                                                                                    <button
                                                                                        className="btn-camp"
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                o?.status !== OrderStatus.CANCELLED &&
                                                                                                o?.status !== OrderStatus.COMPLETED
                                                                                            ) {
                                                                                                setShowCancel({
                                                                                                    title: t("order:CANCEL_DISTRIBUTION_ORDER"),
                                                                                                    type: "cancel",
                                                                                                });
                                                                                                setDOrderId(o?.id);
                                                                                            }
                                                                                        }}>
                                                                                        <CancelIcon
                                                                                            color={"#5A5959"}
                                                                                            size={[30, 30]}
                                                                                            viewBox={[24, 24]}
                                                                                        />
                                                                                        {t("order:CANCEL_ORDER")}
                                                                                    </button>
                                                                                )}
                                                                        {/* {o?.orderGFId !== null && o?.site !== null && ( */}

                                                                        {/* <div className="sub-status">
                                      {(() => {
                                        switch (o.subStatus) {
                                          case OrderStatusERP.NOT_CREATE_ERP:
                                            return o.status === OrderStatus.CANCELLED &&
                                              o.orderGFId === null ? (
                                              <></>
                                            ) : (
                                              <div
                                                style={{
                                                  color: "#0E76D9",
                                                  background: "#F3FCFE",
                                                }}>
                                                {t("ERP_NOT_CREATED")}
                                                <>
                                                  &ensp;|&ensp;
                                                  <AutorenewIcon
                                                    onClick={() => {
                                                      setLoading(true);
                                                      createERPFarm(order?.id, o?.id);
                                                    }}
                                                    style={{
                                                      fontSize: "2rem",
                                                      transform: "rotate(45deg)",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              </div>
                                            );
                                          case OrderStatusERP.NOT_UPDATED_ERP:
                                            return (
                                              <div
                                                style={{
                                                  color: "#E89806",
                                                  background: "#FFFBEE",
                                                }}>
                                                {t("ERP_NOT_UPDATED")}
                                                <>
                                                  &ensp;|&ensp;
                                                  <AutorenewIcon
                                                    onClick={() => {
                                                      setLoading(true);
                                                      createERPFarm(order?.id, o?.id);
                                                    }}
                                                    style={{
                                                      fontSize: "2rem",
                                                      transform: "rotate(45deg)",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              </div>
                                            );
                                          case "notCancelledERP":
                                            return (
                                              <div
                                                style={{
                                                  color: "#B8292F",
                                                  background: "#FFEFEF",
                                                }}>
                                                {t("order:ERP_NOT_CANCEL")}
                                                <>
                                                  &ensp;|&ensp;
                                                  <AutorenewIcon
                                                    onClick={() => {
                                                      setLoading(true);
                                                      createERPFarm(order?.id, o?.id);
                                                    }}
                                                    style={{
                                                      fontSize: "2rem",
                                                      transform: "rotate(45deg)",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </>
                                              </div>
                                            );
                                          default:
                                            return;
                                        }
                                      })()}
                                    </div> */}

                                                                        {/* )} */}
                                                                    </div>
                                                                    {/* Hiển thị trạng thái của order con : childOrder*/}
                                                                    <div className="chip">
                                                                        {StatusUIUtil.renderBgStatusFarmNew(o.status, "farm", o.farmSubStatus)}
                                                                    </div>
                                                                </div>
                                                                {o.orderlines.map((orderlines: any, idx: number) => {
                                                                    return (
                                                                        <div className="item" key={idx}>
                                                                            <div className="detail"></div>
                                                                            <div className="site">
                                                                                <div>
                                                                                    {orderlines.itemName}
                                                                                    {/* &ensp;&#8226;&ensp;
                                          {Array.isArray(orderlines?.item.dimension)
                                            ? orderlines.item.dimension
                                                .filter((f: any) => {
                                                  return +f[0] === +orderlines.dimensionToShow;
                                                })
                                                .map((d: any) => {
                                                  return (
                                                    <span>
                                                      {d[1] === null ? "Trên " + d[0] : d[1]}
                                                    </span>
                                                  );
                                                })
                                            : orderlines.item.dimension}
                                          &ensp;{orderlines.baseUoM} */}
                                                                                </div>
                                                                                <div className="alter-code">
                                                                                    {"Mã ERP: " +
                                                                                        (orderlines.alterItem?.code
                                                                                            ? orderlines.alterItem?.code
                                                                                            : TEXT_HOLDER)}
                                                                                </div>
                                                                            </div>
                                                                            <div className="quan">
                                                                                {orderlines.orderQuantity
                                                                                    ? parseFloat(orderlines.orderQuantity)
                                                                                    : TEXT_HOLDER}
                                                                            </div>
                                                                            <div className="dimension">
                                                                                {getDimensionOfOrder(orderlines.dimensionToShow, orderlines.item.dimension)}
                                                                            </div>
                                                                            <div className="weight">
                                                                                {currencyFormat(+orderlines.dimension)}&ensp;kg
                                                                                {/* {orderlines.baseUoM} */}
                                                                            </div>
                                                                            <div className="price">
                                                                                {(checkPermissionViewPrice
                                                                                    ? currencyFormat(orderlines.price)
                                                                                    : TEXT_ASTERISK) || TEXT_HOLDER}
                                                                                &ensp;đ/
                                                                                <span style={{ color: "#41ad49" }}>
                                                                                    {orderlines.baseUoM || TEXT_HOLDER}
                                                                                </span>
                                                                            </div>
                                                                            {/* Phí giống */}
                                                                            <div className="unit">
                                                                                {(() => {
                                                                                    if (orderlines?.priceType === "fixed") {
                                                                                        return <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(orderlines?.attachValue)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {orderlines.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    }
                                                                                    else {
                                                                                        return <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(0)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {orderlines.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                            {/* Phần vượt */}
                                                                            <div className="unit">
                                                                                {(() => {
                                                                                    if (orderlines?.priceType === "increment") {
                                                                                        return <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(orderlines?.attachValue)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {orderlines.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    }
                                                                                    else {
                                                                                        return <span>
                                                                                            {(checkPermissionViewPrice
                                                                                                ? currencyFormat(0)
                                                                                                : TEXT_ASTERISK) + " đ/"}
                                                                                            <span style={{ color: "#41ad49" }}>
                                                                                                {orderlines.baseUoM}
                                                                                            </span>
                                                                                        </span>
                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                            <div className="total">
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(orderlines.subTotal)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                            <div className="chip">
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}

                                                                <div className="ship ship-child">
                                                                    <div className="detail"></div>
                                                                    <div className="name">
                                                                        <div className="label">{t("order:REFERENCE")}</div>
                                                                        <div
                                                                            // onClick={() => {
                                                                            //   o.orderGFId && setOpenListDO(o.id);
                                                                            // }}
                                                                            style={{ color: "#41ad49" }}>
                                                                            {o.orderGFId || TEXT_HOLDER}
                                                                        </div>
                                                                    </div>
                                                                    <div className="price">
                                                                        <div className="label">{t("order:DELIVERY_DATE")}</div>
                                                                        {o.requiredDate}
                                                                    </div>
                                                                    <div className="weight">
                                                                        <div className="label">{t("order:DISTRIBUTOR")}</div>
                                                                        {o.allocator?.nickname
                                                                            ? o.allocator?.nickname
                                                                            : o.allocator?.code || TEXT_HOLDER}
                                                                    </div>
                                                                    <div className="total">
                                                                        <div className="label">{t("FARM")}</div>
                                                                        {o.site?.name || TEXT_HOLDER}
                                                                    </div>
                                                                </div>
                                                                <div className="ship ship-child ship-child-2">
                                                                    <div className="detail"></div>
                                                                    <div className="name">
                                                                        <div className="label">{t("NOTES")}</div>
                                                                        <span
                                                                            style={{
                                                                                color:
                                                                                    o.note !== "" && o.note !== null && o.note !== undefined
                                                                                        ? "#B8292F"
                                                                                        : "#5A5959",
                                                                            }}>
                                                                            {o.note || TEXT_HOLDER}
                                                                        </span>
                                                                    </div>
                                                                    <div className="weight">
                                                                        <div className="label">Địa chỉ</div>
                                                                        {o.address || TEXT_HOLDER}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="footer">
                                <div
                                    className="total-gr"
                                    style={{
                                        width: (
                                            open
                                                ? order?.orders.filter((f: any) => f.id.toString() === open)?.[0]
                                                    ?.childOrders?.length > 1
                                                : order?.orders?.length > 1
                                        )
                                            ? "60vw"
                                            : "35vw",
                                    }}>
                                    <Grid container>
                                        <Grid item xs={gridSize}>
                                            {open
                                                ? order?.orders.filter((f: any) => f.id.toString() === open)?.[0]
                                                    ?.childOrders?.length > 0 && (
                                                    <div className="right-label lb" style={{ color: "transparent" }}>
                                                        {TEXT_HOLDER}
                                                    </div>
                                                )
                                                : order?.orders?.length > 1 && (
                                                    <div className="right-label lb" style={{ color: "transparent" }}>
                                                        {TEXT_HOLDER}
                                                    </div>
                                                )}
                                        </Grid>
                                        {!open &&
                                            order?.orders?.length > 1 &&
                                            order?.orders.map((o: any, idx: number) => {
                                                return (
                                                    <Grid item xs={gridSize} className="right" key={idx}>
                                                        <div className="right-label lb">
                                                            {o.division.description === "DEFAULT FARM DIVISION" &&
                                                                t("order:BREED")}
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        {!open && (
                                            <Grid item xs={gridSize} className="right">
                                                {order?.orders?.length > 1 && (
                                                    <div className="right-label lb">{t("order:TOTAL_ORDER")}</div>
                                                )}
                                            </Grid>
                                        )}
                                        {order?.orders
                                            .filter((f: any) => f.id.toString() === open)
                                            .map((o: any, index: number) => {
                                                return o.childOrders
                                                    .filter((child: any) => child.status !== OrderStatus.CANCELLED)
                                                    .map((child: any, idx: number) => {
                                                        return (
                                                            <Grid item xs={gridSize} className="right" key={index + idx}>
                                                                <div className="right-label lb" style={{ color: "#41AD49" }}>
                                                                    {child.orderGFId || TEXT_HOLDER}
                                                                </div>
                                                            </Grid>
                                                        );
                                                    });
                                            })}
                                        {order?.orders.filter((f: any) => f.id.toString() === open)?.[0]?.childOrders
                                            ?.length > 0 && (
                                                <Grid item xs={gridSize} className="right">
                                                    <div className="right-label lb">{t("order:TOTAL_DISTRIBUTION")}</div>
                                                </Grid>
                                            )}
                                        <div className="total-accordion">
                                            <Accordion
                                                style={{
                                                    fontFamily: "SVN-Gotham",
                                                }}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ArrowDropDownIcon style={{ color: "#272B2F", fontSize: "2.5rem" }} />
                                                    }
                                                    style={{
                                                        fontFamily: "SVN-Gotham",
                                                    }}>
                                                    {!open && (
                                                        <Grid container>
                                                            <Grid item xs={gridSize}>
                                                                <div className="text-bold">{t("TOTAL")}</div>
                                                            </Grid>
                                                            {order?.orders?.length > 1 &&
                                                                order?.orders.map((o: any, idx: number) => {
                                                                    return (
                                                                        <Grid item xs={gridSize} className="right" key={idx}>
                                                                            <div className="text-bold" style={{ color: "#0A6836" }}>
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(+o.totalPay)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            <Grid item xs={gridSize} className="right">
                                                                <div className="text-bold" style={{ color: "#0A6836" }}>
                                                                    {checkPermissionViewPrice
                                                                        ? currencyFormat(+order.totalAmount)
                                                                        : TEXT_ASTERISK}
                                                                    &ensp;đ
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {order?.orders
                                                        .filter((f: any) => f.id.toString() === open)
                                                        .map((o: any, idx: number) => {
                                                            return (
                                                                <Grid container key={idx}>
                                                                    <Grid item xs={gridSize}>
                                                                        <div className="text-bold">{t("TOTAL")}</div>
                                                                    </Grid>
                                                                    {o.childOrders
                                                                        .filter((child: any) => child.status !== OrderStatus.CANCELLED)
                                                                        .map((child: any, index: number) => {
                                                                            return (
                                                                                <Grid item xs={gridSize} className="right" key={index}>
                                                                                    <div className="text-bold" style={{ color: "#0A6836" }}>
                                                                                        {checkPermissionViewPrice
                                                                                            ? currencyFormat(+child.totalPay)
                                                                                            : TEXT_ASTERISK}
                                                                                        &ensp;đ
                                                                                    </div>
                                                                                </Grid>
                                                                            );
                                                                        })}
                                                                    <Grid item xs={gridSize} className="right">
                                                                        <div className="text-bold" style={{ color: "#0A6836" }}>
                                                                            {checkPermissionViewPrice
                                                                                ? o.childOrders.length === 0
                                                                                    ? currencyFormat(+o.totalPay)
                                                                                    : currencyFormat(
                                                                                        o.childOrders
                                                                                            .filter(
                                                                                                (child: any) =>
                                                                                                    child.status !== OrderStatus.CANCELLED
                                                                                            )
                                                                                            .reduce((sum: any, val: any) => {
                                                                                                return (sum += +val.totalPay);
                                                                                            }, 0)
                                                                                    )
                                                                                : TEXT_ASTERISK}
                                                                            &ensp;đ
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        fontFamily: "SVN-Gotham",
                                                        padding: 0,
                                                    }}>
                                                    <Grid container>
                                                        <Grid item xs={gridSize}>
                                                            <div className="lb">{t("order:DISCOUNT")}</div>
                                                            <div className="lb">{t("PROMOTION")}</div>
                                                            <div className="lb">{t("WITHHOLDING_PIP")}</div>
                                                            <div>{t("VAT")}</div>
                                                        </Grid>
                                                        {!open &&
                                                            order?.orders?.length > 1 &&
                                                            order?.orders.map((o: any, idx: number) => {
                                                                return (
                                                                    <Grid item xs={gridSize} className="right" key={idx}>
                                                                        <div className="lb">
                                                                            {checkPermissionViewPrice
                                                                                ? currencyFormat(parseInt(o.preDiscount))
                                                                                : TEXT_ASTERISK}
                                                                            &ensp;đ
                                                                        </div>
                                                                        <div className="lb">
                                                                            {checkPermissionViewPrice
                                                                                ? currencyFormat(parseInt(o.commerceDiscount))
                                                                                : TEXT_ASTERISK}
                                                                            &ensp;đ
                                                                        </div>
                                                                        <div className="lb">
                                                                            {checkPermissionViewPrice
                                                                                ? currencyFormat(parseInt(o.personalVat))
                                                                                : TEXT_ASTERISK}
                                                                            &ensp;đ
                                                                        </div>
                                                                        <div>
                                                                            {checkPermissionViewPrice
                                                                                ? currencyFormat(parseInt(o.vat))
                                                                                : TEXT_ASTERISK}
                                                                            &ensp;đ
                                                                        </div>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        {order?.orders
                                                            .filter((f: any) => f.id.toString() === open)
                                                            .map((o: any, index: number) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {o.childOrders
                                                                            .filter(
                                                                                (child: any) => child.status !== OrderStatus.CANCELLED
                                                                            )
                                                                            .map((child: any, idx: number) => {
                                                                                return (
                                                                                    <Grid item xs={gridSize} className="right" key={idx}>
                                                                                        <div className="lb">
                                                                                            {checkPermissionViewPrice
                                                                                                ? currencyFormat(+child.preDiscount)
                                                                                                : TEXT_ASTERISK}
                                                                                            &ensp;đ
                                                                                        </div>
                                                                                        <div className="lb">
                                                                                            {checkPermissionViewPrice
                                                                                                ? currencyFormat(+child.commerceDiscount)
                                                                                                : TEXT_ASTERISK}
                                                                                            &ensp;đ
                                                                                        </div>
                                                                                        <div className="lb">
                                                                                            {checkPermissionViewPrice
                                                                                                ? currencyFormat(+child.personalVat)
                                                                                                : TEXT_ASTERISK}
                                                                                            &ensp;đ
                                                                                        </div>
                                                                                        <div>
                                                                                            {checkPermissionViewPrice
                                                                                                ? currencyFormat(+child.vat)
                                                                                                : TEXT_ASTERISK}
                                                                                            &ensp;đ
                                                                                        </div>
                                                                                    </Grid>
                                                                                );
                                                                            })}
                                                                        <Grid item xs={gridSize} className="right">
                                                                            <div className="lb">
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(+o.preDiscount)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                            <div className="lb">
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(+o.commerceDiscount)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                            <div className="lb">
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(+o.personalVat)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                            <div>
                                                                                {checkPermissionViewPrice
                                                                                    ? currencyFormat(+o.vat)
                                                                                    : TEXT_ASTERISK}
                                                                                &ensp;đ
                                                                            </div>
                                                                        </Grid>
                                                                    </div>
                                                                );
                                                            })}
                                                        {!open && (
                                                            <Grid item xs={gridSize} className="right">
                                                                <div className="lb">
                                                                    {checkPermissionViewPrice
                                                                        ? currencyFormat(parseInt(order.preDiscount))
                                                                        : TEXT_ASTERISK}
                                                                    &ensp;đ
                                                                </div>
                                                                <div className="lb">
                                                                    {checkPermissionViewPrice
                                                                        ? currencyFormat(parseInt(order.commerceDiscount))
                                                                        : TEXT_ASTERISK}
                                                                    &ensp;đ
                                                                </div>
                                                                <div className="lb">
                                                                    {checkPermissionViewPrice
                                                                        ? currencyFormat(parseInt(order.personalVat))
                                                                        : TEXT_ASTERISK}
                                                                    &ensp;đ
                                                                </div>
                                                                <div>
                                                                    {checkPermissionViewPrice
                                                                        ? currencyFormat(parseInt(order.totalVat))
                                                                        : TEXT_ASTERISK}
                                                                    &ensp;đ
                                                                </div>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </Grid>
                                </div>

                                <Accordion className="update-history" disabled>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                                        className="title">
                                        {t("UPDATE_HISTORY")}
                                    </AccordionSummary>
                                    <Divider style={{ marginBottom: "1.2rem" }} />
                                    <AccordionDetails className="label">
                                        <Grid container>
                                            <Grid item xs={3}>
                                                {t("TIME")}
                                            </Grid>
                                            <Grid item xs={3}>
                                                {t("order:ACTION")}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {t("order:IMPLEMENT")}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {t("DETAIL")}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    {UpdateHistory.map((u, index: number) => {
                                        return (
                                            <AccordionDetails key={index + u.id} className="item">
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        {u.time}
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        {u.update}
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {u.name}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {u.update}
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        );
                                    })}
                                </Accordion>
                            </div>
                        </>
                    ) : (
                        <Loading style={{ margin: "2rem 0", height: "80vh" }} />
                    )}
                </div>
            </div>

            {openListDO !== 0 && <DO closeForm={() => setOpenListDO(0)} id={openListDO} orderGroup={order} orderDO={orderDO} />}

            <Dialog fullScreen open={openDetachOrder}>
                <DetachOrder
                    orderGroupId={+orderId}
                    orderId={detachOrder?.orderId!}
                    orderLines={detachOrder?.orderLines!}
                    createBy={
                        order?.orders[0].customer !== null ? order?.orders[0]?.customer : order?.orders[0]?.lead
                    }
                    address={detachOrder?.address}
                    note={detachOrder?.note}
                    remainQuantity={detachOrder?.remainQuan}
                    remainDimention={detachOrder?.remainDim}
                    // childOrders={detachOrder?.childOrders}
                    closeForm={() => setOpenDetachOrder(false)}
                    checkLead={order?.orders[0].customer === null}
                    requiredDate={detachOrder?.requiredDate}
                    childOrders={detachOrder?.childOrders ?? []}
                />
            </Dialog>
            <Dialog fullScreen open={siteOrder?.order !== undefined}>
                <SiteOrder
                    order={siteOrder?.order}
                    closeForm={async () => {
                        setSiteOrder({ order: undefined, data: {}, orderline: undefined });
                        await OrderService.getOrderById(+orderId);
                    }}
                    dataItem={siteOrder.data}
                    orderline={siteOrder?.orderline}
                />
            </Dialog>
            <Notification
                visible={notiSiteCheckLead}
                title={t("order:DISTRIBUTION_CANNOT_CARRIED_OUT")}
                subTitle={t("order:PLEASE_ALLOCATE_CUSTOMERS")}
                icon={
                    <div className={"notification-icon-box yellow"}>
                        <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
                    </div>
                }
                actions={
                    <button
                        className="btn-confirm-style"
                        style={{
                            width: "20.6rem",
                            marginRight: "2rem",
                            borderRadius: "10rem",
                        }}
                        type="submit"
                        onClick={() => {
                            setNotiSiteCheckLead(false);
                        }}>
                        {t("CLOSE")}
                    </button>
                }
                onClose={() => {
                    setNotiSiteCheckLead(false);
                }}
            />
            <Dialog fullScreen open={compareDo}>
                <CompareOrderFarm closeForm={() => setCompareDo(false)} orderId={orderId} />
            </Dialog>
            <Dialog maxWidth="md" open={showCancel.title !== ""}>
                <div className="dialog-cancel">
                    <div className="title">
                        <div>{showCancel.title}</div>
                        <ClearIcon
                            style={{ fontSize: "3rem", cursor: "pointer" }}
                            onClick={() => {
                                setShowCancel({
                                    title: "",
                                    type: "",
                                });
                            }}
                        />
                    </div>
                    <RadioGroup
                        name="controlled-radio-buttons-group"
                        value={cancelValue}
                        onChange={(e) => {
                            setCancelValue(e.target.value);
                        }}>
                        {showCancel.title.includes("Đóng")
                            ? ReasonCloseOrder.map((r) => {
                                return (
                                    <FormControlLabel
                                        key={r.id}
                                        value={r.value}
                                        control={<Radio color="success" />}
                                        label={t(`order:${r.label}`)}
                                        className="text"
                                    />
                                );
                            })
                            : ReasonStatus.map((r) => {
                                return (
                                    <FormControlLabel
                                        key={r.id}
                                        value={r.value}
                                        control={<Radio color="success" />}
                                        label={t(r.label)}
                                        className="text"
                                    />
                                );
                            })}
                        <div className="label">{t("REASON")}</div>
                        <TextField
                            fullWidth
                            placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
                            className="form-textarea-field"
                            multiline
                            rows={3}
                            variant="outlined"
                            color="success"
                            inputProps={{ className: "input" }}
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                        />
                    </RadioGroup>
                    <div className="btn-group">
                        <button
                            className="btn-cancel"
                            onClick={() => {
                                setShowCancel({
                                    title: "",
                                    type: "",
                                });
                            }}>
                            {t("NO")}
                        </button>
                        <button
                            className="btn-confirm-style"
                            style={{ marginLeft: "2.2rem" }}
                            onClick={() => {
                                setSubmitCancel(showCancel.title);
                            }}>
                            {showCancel.title.includes("Đóng") ? t("order:CLOSE_ORDER") : t("order:CANCEL_ORDER")}
                        </button>
                    </div>
                </div>
            </Dialog>
            <Notification
                visible={submitCancel !== ""}
                title={submitCancel}
                subTitle={`${t("order:ARE_YOU_SURE_WANT_NAME", {
                    NAME: `${showCancel.title === CancelOrCompleteOrder.COMPLETE
                        ? t("order:CLOSE_ORDER")
                        : t("order:CANCEL_ORDER")
                        }`,
                })}`}
                icon={
                    <div className={`notification-icon-box yellow`}>
                        <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                    </div>
                }
                actions={
                    <>
                        <button
                            className="btn-confirm-style"
                            style={{
                                background: "#fff",
                                color: "#0A6836",
                                border: "0.1rem solid #0A6836",
                                width: "20.6rem",
                                marginLeft: "2rem",
                            }}
                            onClick={() => {
                                setSubmitCancel("");
                            }}>
                            {t("NO")}
                        </button>
                        <button
                            className="btn-confirm-style"
                            style={{
                                width: "20.6rem",
                                marginLeft: "2rem",
                            }}
                            onClick={() => {
                                //code hoi ngu do bi di xin loi
                                if (showCancel.type === "complete") {
                                    if (showCancel.title === "Đóng đơn hàng") {
                                        cancelOrder(childOrdersId, "O", false, cancelValue, reason);
                                    } else if (showCancel.title === "Đóng đơn điều phối") {
                                        cancelOrder(DOrderId, "D", false, cancelValue, reason);
                                    } else {
                                        cancelOrder(order?.id, "G", false, cancelValue, reason);
                                    }
                                } else {
                                    if (showCancel.title === "Hủy đơn hàng") {
                                        cancelOrder(childOrdersId, "O", true, cancelValue, reason);
                                    } else if (showCancel.title === "Hủy đơn điều phối") {
                                        cancelOrder(DOrderId, "D", true, cancelValue, reason);
                                    } else {
                                        cancelOrder(order?.id, "G", true, cancelValue, reason);
                                    }
                                }
                            }}>
                            {t("CONFIRM")}
                        </button>
                    </>
                }
                onClose={() => {
                    setSubmitCancel("");
                }}
            />
            {loading && (
                <Backdrop sx={{ color: "red", opacity: 0.5, zIndex: 100 }} open={true}>
                    <Loading />
                </Backdrop>
            )}
            <Dialog fullScreen open={detachSiteOrder?.order !== undefined}>
                <DetachSiteOrder
                    order={detachSiteOrder?.order}
                    closeForm={() => setDetachSiteOrder({ order: undefined, data: {} })}
                    dataItem={detachSiteOrder.data}
                />
            </Dialog>
            {openTruckLoad.open && <TruckLoadModal data={openTruckLoad} setOpenTruckLoad={setOpenTruckLoad} />}
            {openModalLessContainer.open && <ModalLessContainer data={openModalLessContainer} setOpenTruckLoad={setOpenModalLessContainer} />}
            {openDeliveryFee.open && <ModalDeliveryFee openDeliveryFee={openDeliveryFee} setOpenDeliveryFee={setOpenDeliveryFee} />}
            {openEditDeliveryFee.open && <ModalEditDeliveryFee openDeliveryFee={openEditDeliveryFee} setOpenDeliveryFee={setOpenEditDeliveryFee} />}
            <Notification
                visible={openNotiAssignCustomerToOrder}
                title={t("order:ALLOCATE_CUSTOMERS")}
                subTitle={
                    <span>
                        {t("order:ORDER_PLACED_FOR_CUSTOMER_BY_QUANTITY", { NAME: order?.orders?.length })}{" "}
                        <br />
                        {order?.orders[0]?.lead?.fullName || TEXT_HOLDER} -{" "}
                        {order?.orders[0]?.lead?.code || TEXT_HOLDER} <br />
                        {t("order:DO_YOU_WANT_ALLOCATE_ALL_THESE_ORDER")}
                    </span>
                }
                icon={
                    <div className={"notification-icon-box yellow"}>
                        <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                    </div>
                }
                actions={
                    <>
                        <button
                            className="btn-confirm-style"
                            style={{
                                background: "#fff",
                                color: "#272B2F",
                                border: "0.1rem solid #272B2F",
                                width: "20.6rem",
                                marginRight: "2rem",
                                borderRadius: "10rem",
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                onAssignCustomerForLead(
                                    order?.id,
                                    false,
                                    order?.orders[0]?.lead.id,
                                    customerValue?.id
                                );
                            }}>
                            {t("NO")}
                        </button>
                        <input
                            className="btn-confirm-style"
                            style={{
                                width: "20.6rem",
                                marginRight: "2rem",
                                borderRadius: "10rem",
                            }}
                            form="site-form"
                            type="submit"
                            aria-label="submit supplier form"
                            value={t("CONFIRM")}
                            onClick={(e) => {
                                e.preventDefault();
                                onAssignCustomerForLead(
                                    order?.id,
                                    true,
                                    order?.orders[0]?.lead.id,
                                    customerValue?.id
                                );
                            }}
                        />
                    </>
                }
                onClose={() => {
                    setOpenNotiAssignCustomer(false);
                }}
            />
        </Dialog>
    );
};

export default OrderDetailFarmPork;
