import GFTypography from "@Components/ui/GFTypography";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const GFSearchWithInfiniteScrollInput = styled(Box)(({ width }) => ({
  position: "relative",
  width: width ?? ("100%" as any),
  zIndex: 101,
  "& .gf-search-with-infinite-scroll__input": {
    "& input": {
      "&::placeholder": {
        fontWeight: 400,
      },
    },
    "& .MuiInputBase-root:not(.Mui-focused)": {
      backgroundColor: "var(--hover-green)",
      "& .MuiSvgIcon-root": {
        color: "var(--primary-green)",
      },
      "& input": {
        color: "var(--primary-green)",

        "&::placeholder": {
          fontWeight: 400,
          color: "var(--primary-green)",
          opacity: 1,
        },
      },
      "& fieldset": {
        borderColor: "var(--primary-green) !important",
      },
    },
  },
}));

export const GFSearchWithInfiniteScrollPopover = styled(Box)(({ maxHeight }) => ({
  position: "absolute",
  top: "calc(100% + 0.4rem)",
  left: 0,
  width: "100%",
  zIndex: 100,
  backgroundColor: "white",
  border: "1px solid var(--border-color)",
  maxHeight: maxHeight ?? (250 as any),
  overflowY: "auto",
  borderRadius: "0.5rem",
  boxShadow: "0px 0px 2px rbga(0,0,0,0.1)",
}));

export const GFSearchWithInfiniteScrollItem = styled(Box)(({}) => ({
  padding: "0.8rem 1rem",
  cursor: "pointer",
  "&:hover": {
    background: "var(--hover-green)",
  },
}));

export const GFSearchWithInfiniteScrollLoading = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "center",
  padding: "1rem",
  "& .MuiCircularProgress-root": {
    color: "var(--primary-green)",
  },
}));

export const GFSearchWithInfiniteScrollNoData = styled(GFTypography)({
  display: "flex",
  justifyContent: "center",
  padding: "1rem",
});
