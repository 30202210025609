import { IconBase, IconComponent } from "@Core/Icons";

export const OptionIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 5.46154C1.5 3.54978 3.04979 2 4.96154 2H6.19231C8.10407 2 9.65386 3.54978 9.65386 5.46154V6.69231C9.65386 8.60406 8.10407 10.1538 6.19232 10.1538H4.96154C3.04979 10.1538 1.5 8.60406 1.5 6.69231V5.46154ZM4.96154 4C4.15435 4 3.5 4.65436 3.5 5.46154V6.69231C3.5 7.49949 4.15435 8.15385 4.96154 8.15385H6.19232C6.9995 8.15385 7.65386 7.49949 7.65386 6.69231V5.46154C7.65386 4.65436 6.9995 4 6.19231 4H4.96154ZM11.3461 5.46154C11.3461 3.54978 12.8959 2 14.8077 2H16.0385C17.9502 2 19.5 3.54978 19.5 5.46154V6.69231C19.5 8.60406 17.9502 10.1538 16.0385 10.1538H14.8077C12.8959 10.1538 11.3461 8.60406 11.3461 6.69231V5.46154ZM14.8077 4C14.0005 4 13.3461 4.65436 13.3461 5.46154V6.69231C13.3461 7.49949 14.0005 8.15385 14.8077 8.15385H16.0385C16.8456 8.15385 17.5 7.49949 17.5 6.69231V5.46154C17.5 4.65436 16.8456 4 16.0385 4H14.8077ZM1.5 15.3077C1.5 13.3959 3.04979 11.8462 4.96154 11.8462H6.19231C8.10407 11.8462 9.65386 13.3959 9.65386 15.3077V16.5385C9.65386 18.4502 8.10407 20 6.19232 20H4.96154C3.04979 20 1.5 18.4502 1.5 16.5385V15.3077ZM4.96154 13.8462C4.15435 13.8462 3.5 14.5005 3.5 15.3077V16.5385C3.5 17.3456 4.15435 18 4.96154 18H6.19232C6.9995 18 7.65386 17.3456 7.65386 16.5385V15.3077C7.65386 14.5005 6.99951 13.8462 6.19231 13.8462H4.96154ZM11.3461 15.3077C11.3461 13.3959 12.8959 11.8462 14.8077 11.8462H16.0385C17.9502 11.8462 19.5 13.3959 19.5 15.3077V16.5385C19.5 18.4502 17.9502 20 16.0385 20H14.8077C12.8959 20 11.3461 18.4502 11.3461 16.5385V15.3077ZM14.8077 13.8462C14.0005 13.8462 13.3461 14.5005 13.3461 15.3077V16.5385C13.3461 17.3456 14.0005 18 14.8077 18H16.0385C16.8456 18 17.5 17.3456 17.5 16.5385V15.3077C17.5 14.5005 16.8456 13.8462 16.0385 13.8462H14.8077Z"
        fill={props.color}
      />
    </IconBase>
  );
};
