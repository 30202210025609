import { GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { GFDataTableConfigs } from "@Components/ui/GFDataTable/GFDataTable.config";
import GFDropdown from "@Components/ui/GFDropdown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

type GFDataTableHeaderCellProps = {
  column: GFDataTableColumn;
};

export default function GFDataTableHeaderCell({ column }: GFDataTableHeaderCellProps) {
  const { t } = useTranslation("translation");

  const handleFilter = (dataIndex: string, filterValue: any) => {
    if (column.onFilter) {
      column.onFilter(dataIndex, filterValue);
    }
  };

  return (
    <div
      style={{
        ...(column.autoWidth
          ? { flex: 1 }
          : { width: column.width ?? GFDataTableConfigs.DefaultWidth }),
        minWidth: column.width ?? GFDataTableConfigs.DefaultWidth,
      }}
      className={`gf-datatable__cell gf-datatable__header__cell ${column.align ?? "left"}`}>
      {column.title && <GFTypography fontWeight={600}>{t(column.title)}</GFTypography>}
      {column.filterOption && (
        <GFDropdown
          trigger={
            <IconButton className="gf-datatable__header__icon">
              <FilterAltOutlinedIcon />
            </IconButton>
          }
          onItemClick={(value) => handleFilter(column.dataIndex as string, value)}>
          {column.filterOption.map((option, index) => {
            return (
              <div key={index} className="gf-datatable__filter__option" {...option}>
                {t(option.label)}
              </div>
            );
          })}
        </GFDropdown>
      )}
    </div>
  );
}
