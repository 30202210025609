import { TEXT_HOLDER } from "@Constants";
import { SalesOfficeModal } from "@Models/sales_office.model";
import { Avatar } from "@mui/material";
import { stringAvatar } from "@Utils";
import React from "react";

type PriceSidebarProps = {
  title: string;
  items: SalesOfficeModal[];
  activeId?: string;
  onClick: (id: string) => void;
  isFetching?: boolean;
};

export default function PriceSidebar({
  items,
  activeId,
  title,
  isFetching,
  onClick,
}: PriceSidebarProps) {
  const handleClick = (id: string) => {
    if (isFetching) return;
    onClick(id);
  };

  return (
    <div className="sales-org">
      <div className="sales-org-header">
        <div className="title">{title}</div>
      </div>
      <div className="sales-org-body">
        {items?.length > 0 &&
          items.map((s) => {
            return (
              <div
                className={activeId === s.id.toString() ? "active item" : "item"}
                key={s.id}
                onClick={() => {
                  handleClick(s.id.toString());
                }}>
                <Avatar alt={s.description} src="">
                  <Avatar {...stringAvatar(s.description)} />
                </Avatar>
                <div className="name">{s.description || TEXT_HOLDER}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
