import { APPROVE_BUSINESS_TYPE, APPROVE_BY } from "@Constants";
import { SelectOption } from "src/types";

export const businessTypeOptions: SelectOption[] = [
  {
    label: "approvalOrderCancellation.approvalOrderCancellation",
    value: APPROVE_BUSINESS_TYPE.ORDER_CANCELLATION,
  },
];


export const approvalByOptions: SelectOption[] = [
  {
    label: "approvalOrderCancellation.byFormula",
    value: APPROVE_BY.FORMULA,
  },
];


export const getBusinessTypeLabel = (value: number) => {
  const option = businessTypeOptions.find((item) => item.value === value);
  return option ? option.label : "";
}

export const getApprovalByLabel = (value: number) => {
  const option = approvalByOptions.find((item) => item.value === value);
  return option ? option.label : "";
}