import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Control } from "react-hook-form";
import { CreateOrderPayload } from "src/schemas/order.schema";

import OrderBoarSemenItem from "@Containers/Order/components/BoarSemen/OrderBoarSemen/OrderBoarSemenBody/OrderBoarSemenItem";
import { CustomerOutputModel } from "@Models";
import { FarmType } from "src/types";

const OrderBoarSemenBodyWrapper = styled(Box)({
  padding: '24px',
  flex: 1,
  overflow: 'hidden auto',
  "& .MuiInputBase-root": {
    background: "#fff",
  },
  "& .gf-datatable__header": {
    background: "#fff",
    borderBottom: "1px solid var(--border-color)",
  },
  "& .gf-datatable__body__item": {
    background: "var(--table-bg)",
    "&:not(:last-child)": {
      marginBottom: "1.6rem",
    },
  },
});

type OrderBoarSemenBodyProps = {
  control: Control<CreateOrderPayload>;
  onRemoveProduct: (index: number) => void;
  onSetValue: any;
  customerFeeds: CustomerOutputModel[];
  data: any[];
  type?: FarmType;
};

export default function OrderBoarSemenBody({
  control,
  customerFeeds = [],
  data,
  type = FarmType.External,
  onRemoveProduct,
  onSetValue,
}: OrderBoarSemenBodyProps) {

  return (
    <OrderBoarSemenBodyWrapper>
      {data?.length > 0 &&
        data.map((item, rowIndex) => (
          <OrderBoarSemenItem
            control={control}
            rowIndex={rowIndex}
            item={item}
            customerFeeds={customerFeeds}
            onRemoveProduct={onRemoveProduct}
            onSetValue={onSetValue}
          />
        ))}
    </OrderBoarSemenBodyWrapper>
  );
}
