import { GFButton } from "@Components/ui/GFButton";
import GFIconButton from "@Components/ui/GFIconButton";
import GFTypography from "@Components/ui/GFTypography";
import { Close } from "@mui/icons-material";
import { Box, CircularProgress, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { ModalDefaultProps } from "src/types";

const StyledModalContent = styled(Box)<{ width?: string, height?: string }>(({ width, height }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  minWidth: "40rem",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  background: "var(--modal-bg)",
  padding: "3.2rem",
  borderRadius: "0.8rem",
  ...(width && { width }),
  ...(height && { height }),
}));

const StyledModalHeader = styled(Box)<{ showHeaderTitle?: boolean }>(({ showHeaderTitle }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: showHeaderTitle ? "2rem" : "0",
  "& .MuiIconButton-root": {
    padding: 0,
    marginLeft: "auto",
  },
}));

const StyledModalBody = styled(Box)({
  maxHeight: "calc(100vh - 25rem)",
  overflowY: "auto",
  overflowX: "hidden",
  marginBottom: "3rem",
});

export type GFModalProps = ModalDefaultProps & {
  title?: string;
  children?: React.ReactNode;
  confirmBtnText?: string;
  cancelBtnText?: string;
  actions?: React.ReactNode;
  closeClickOutside?: boolean;
  disabledConfirm?: boolean;
  loading?: boolean;
  showHeaderTitle?: boolean;
  actionsCenter?: boolean;
  showIconClose?: boolean;
};

export default function GFModal({
  open = false,
  closeClickOutside = true,
  title,
  children,
  actions,
  width,
  height,
  confirmBtnText = "CONFIRM",
  cancelBtnText = "CANCEL",
  disabledConfirm = false,
  loading = false,
  showHeaderTitle = true,
  actionsCenter = false,
  showIconClose = true,
  onClose,
  onConfirm,
}: GFModalProps) {
  const { t } = useTranslation("translation");

  const handleClose = () => {
    if (closeClickOutside) {
      onClose && onClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledModalContent width={width} height={height}>
        <StyledModalHeader showHeaderTitle={showHeaderTitle}>
          {showHeaderTitle && (
            <GFTypography variant="h5" fontWeight={600}>
              {title}
            </GFTypography>
          )}
          {showIconClose && (
            <GFIconButton size="large" onClick={onClose}>
            <Close />
          </GFIconButton>
          )}
        </StyledModalHeader>
        <StyledModalBody>{children}</StyledModalBody>

        <Box display="flex" justifyContent={actionsCenter ? "center" : "flex-end"} gap={3}>
          {actions || (
            <>
              <GFButton onClick={onClose} variant="outlined" minWidth={15}>
                {t(cancelBtnText)}
              </GFButton>
              <GFButton
                onClick={onConfirm}
                variant="contained"
                disabled={disabledConfirm}
                minWidth={15}>
                {loading && <CircularProgress size={18} />}
                {t(confirmBtnText)}
              </GFButton>
            </>
          )}
        </Box>
      </StyledModalContent>
    </Modal>
  );
}
