import { TEXT_HOLDER } from "@Constants";
import { InfoLabel, OrderDetailItemWrapper } from "@Containers/OrderCombination/styles";
import { OrderOutputModel } from "@Models";
import { Box, Grid } from "@mui/material";
import { toClientDate } from "@Utils";
import _, { isEmpty } from "lodash";
import React from "react";

type OrderDetailExpandedProps = {
  order: OrderOutputModel["orders"]["0"];
  isChild?: boolean;
};

export default function OrderDetailExpanded({ order, isChild }: OrderDetailExpandedProps) {
  if (isEmpty(order)) {
    return null;
  }

  return (
    <Box>
      {order.orderlines?.map((detail, index) => (
        <OrderDetailItemWrapper
          isChild
          key={detail.id}
          sx={{
            borderBottom: "1px solid #E0E0E0",
          }}>
          <div className="expand-icon"> </div>
          <div className="gf-order-detail-item__main">
            <Grid container spacing={3} pb={1} pt={2}>
              <Grid item xs={2}>
                <InfoLabel title="Số SO của SAP" value={TEXT_HOLDER} type="primary" />
              </Grid>
              <Grid item xs={2}>
                <InfoLabel title="Số SO của SPT" value={order.code || TEXT_HOLDER} type="primary" />
              </Grid>
              <Grid item xs={2}>
                <InfoLabel
                  title="Thòi gian giao hàng"
                  value={`${toClientDate(order.requiredDate)} ${_.get(
                    order,
                    "requiredTime.delieryTime",
                    ""
                  )}`}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoLabel
                  title="Trang trại & con giống"
                  value={_.get(order, "camp.name", TEXT_HOLDER)}
                />
              </Grid>
              <Grid item xs={2}>
                <InfoLabel title="Ghi chú" value={_.get(detail, "note") || TEXT_HOLDER} />
              </Grid>
              <Grid item xs={2}>
                <InfoLabel title="Địa chỉ" value={order.address || TEXT_HOLDER} />
              </Grid>
            </Grid>
          </div>
        </OrderDetailItemWrapper>
      ))}
    </Box>
  );
}
