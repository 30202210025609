/* eslint-disable react-hooks/exhaustive-deps */
import Excel from "@Assets/images/icons/excel.png";
import Search from "@Assets/images/icons/img-search.png";
import Img from "@Assets/images/ordnull.png";
import EmptyList from "@Components/EmptyList";
import {
  Action,
  DivisionOutputModel,
  FetchAction,
  ItemPermission,
  ProductOutputModel,
  SourceType,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { ExportService, ProductService } from "@Services";
import { PermissionUtil, checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import { MoreHoriz as MoreHorizIcon, Search as SearchIcon } from "@mui/icons-material";
import { Avatar, Divider, InputAdornment, MenuItem, Popover, TextField } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router";
// import FloatingBtn from "@Components/FloatingBtn";
import Loading from "@Components/Loading";
import NotiExportExcel from "@Components/NotiExportExcel";
import { APP_ROUTES, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import "./index.scss";
import { useTranslation } from "react-i18next";

type P_Props = {
  sourceType: string;
};

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: "#41AD49",
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

function reducers(state: PaginateFetchReducer<DivisionOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

const ProductListing: React.FC<P_Props> = ({ sourceType }) => {
  const { t } = useTranslation(["translation", "product"]);
  const checkExportRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>("");
  const mounted = useRef(false);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [divisionValue, setDivisionValue] = useState<string>("");
  const [categoryPopup, setCategoryPopup] = React.useState<{
    anchorEl: HTMLButtonElement | null;
    division: DivisionOutputModel | null;
  }>({ anchorEl: null, division: null });
  // const openCategoryPopup = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  //   division: DivisionOutputModel
  // ) => {
  //   setCategoryPopup({ anchorEl: event.currentTarget, division });
  // };
  const closeCategoryPopup = () => {
    setCategoryPopup({ anchorEl: null, division: null });
  };
  // const [openProductModal, setOpenProductModal] = useState<boolean>(false);
  const [notiExport, setNotiExport] = useState<boolean>(false);
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  const [division, dis] = useReducer(reducers, {
    data: [],
    fetching: false,
    errorMessage: "",
  });

  // const customerId = localStorage.getItem("customerId");

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchDataDivision = async (query: any) => {
    try {
      const result = await ProductService.getDivision(query);
      if (mounted.current) {
        dis({
          type: Action.SUCCESS,
          payload: result.data.data,
        });
      }
    } catch (err) {
      if (mounted)
        dis({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await ProductService.getListProductByAdmin(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const checkSource =
    sourceType === SourceType.FEED ||
    sourceType === SourceType.VET ||
    sourceType === SourceType.LAB;

  useEffect(() => {
    fetchDataDivision({ source: sourceType });
  }, [sourceType]);

  const [isExport, setIsExport] = useState<any>();
  const [success, setSuccess] = useState<any>();

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 50,
        source: sourceType,
        searchValue,
        divisionId:
          checkSource && divisionValue && !isNaN(+divisionValue) ? +divisionValue : undefined,
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, searchValue, divisionValue, success?.isExport === !isExport]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 500);

  const checkPermissionProductDetail = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEWDETAILS,
    true
  );

  const onCheckExport = async () => {
    try {
      const response = await ExportService.getRefreshStatus({
        type: "item",
        source: sourceType,
      });
      if (mounted.current) {
        setSuccess(() => response.data);
        if (response.data.isExport === false) {
          setIsExport(false);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsExport(state?.isExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceType, divisionValue, state.loading, success?.isExport === !isExport]);

  const exportItem = async () => {
    ExportService.getExportItem({
      source: sourceType,
      divisionId: divisionValue && !isNaN(+divisionValue) ? divisionValue : undefined,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setIsExport(res.data.isExport);
          checkExportRef.current = setInterval(() => {
            onCheckExport();
          }, 5000);
        } else {
        }
      })
      .catch((err) => {});
  };

  const checkPermissionExportExcel = PermissionUtil.check(
    profile?.role?.permissions,
    [ItemPermission.MANAGE_PRICE_TABLE_EXPORT],
    true
  );

  return (
    <div className="product-listing">
      <div className="product-listing-content">
        {checkSource && (
          <div className="category">
            <div className="category-header">
              <div className="title">{t("PRODUCT_CATEGORY")}</div>
            </div>
            <div
              className={`category-item ${!divisionValue ? "active" : ""}`}
              onClick={() => {
                setDivisionValue("");
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar alt="All" src="" style={{ background: "#41AD49" }}>
                  T
                </Avatar>
                <div className="category-item-name">{t("ALL")}</div>
              </div>
              <MoreHorizIcon fontSize="large" className="choose" />
            </div>
            <Divider style={{ width: "100%", margin: "1.6rem 0" }} />
            <div className="category-body">
              {division.data && (
                <>
                  {division.data.map((cate: DivisionOutputModel, idx: number) => {
                    const isActive = cate.id === divisionValue;
                    return (
                      <div
                        key={cate.code + idx}
                        className={`category-item ${isActive ? "active" : ""}`}
                        onClick={() => setDivisionValue(cate.id)}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {cate.images.length > 0 ? (
                            <div>
                              {cate.images.map((item: any, index: number) => {
                                return (
                                  <img
                                    key={index}
                                    src={checkLinkImage(item.picture)}
                                    alt={"avt"}
                                    width="36px"
                                    height="36px"
                                  />
                                );
                              })}
                            </div>
                          ) : (
                            <Avatar alt={cate.description} src={cate.description}>
                              <Avatar {...stringAvatar(cate.description)} />
                            </Avatar>
                          )}
                          <div className="category-item-name">{cate.description}</div>
                        </div>
                        <div
                          onClick={(e: any) => {
                            // openCategoryPopup(e, cate as DivisionOutputModel);
                          }}>
                          <MoreHorizIcon fontSize="large" className="choose" />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
        <div className="content">
          <div className="header">
            <div className="title">
              {t("ALL")} ({state.totalRecords})
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder={t("ENTER_SEARCH")}
                variant="outlined"
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              {!isExport && checkPermissionExportExcel && (
                <button
                  className="btn-excel"
                  onClick={() => {
                    exportItem();
                    setNotiExport(true);
                    setSuccess(undefined);
                  }}>
                  <img src={Excel} alt="icon-excel" />
                  {t("EXPORT_DATA")}
                </button>
              )}
            </div>
          </div>
          {state.loading || (state.fetching && searchValue) ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {state.data ? (
                state.data.length > 0 ? (
                  <div className="list">
                    <div className="list-header">
                      <div className="id">{t("PRODUCT_CODE")}</div>
                      <div className="product">{t("product:PRODUCT_NAME")}</div>
                      <div className="shortName">{t("product:SHORT_NAME")} </div>
                      <div className="groups">{t("product:PRODUCT_GROUP")}</div>
                      <div className="unit">{t("product:UNIT_MEASUREMENT")}</div>
                    </div>
                    <InfiniteScroll
                      useWindow={false}
                      pageStart={0}
                      initialLoad={false}
                      hasMore={hasMore}
                      loadMore={() =>
                        fetchData({
                          limit: 50,
                          page: state.page + 1,
                          source: sourceType,
                          searchValue,
                          divisionId:
                            divisionValue && !isNaN(+divisionValue) ? +divisionValue : undefined,
                        })
                      }>
                      {state.data.map((pro: any, index: number) => {
                        return (
                          <div
                            key={pro.code + index}
                            className="item"
                            onClick={() => {
                              checkPermissionProductDetail
                                ? history.push(
                                    APP_ROUTES.PRODUCT_DETAIL.replace(":productId", pro.id)
                                  )
                                : enqueueSnackbar(t("ACCESS_DENIED"), {
                                    variant: "error",
                                  });
                            }}
                            style={{
                              borderLeft: `3px solid ${pro?.color ? pro?.color : "transparent"}`,
                            }}>
                            <div className="id">{pro.code}</div>
                            <div className="product" style={{ color: pro?.color }}>
                              <img
                                src={
                                  checkLinkImage(pro.images?.picture) ||
                                  renderImageProNull(sourceType)
                                }
                                alt="Avata"
                                onError={renderErrorImageItem(sourceType)}
                              />
                              {pro.name}
                            </div>
                            <div className="shortName">{pro.shortName || TEXT_HOLDER}</div>
                            <div className="groups">{pro.materialGroupName || TEXT_HOLDER}</div>
                            <div className="unit">{pro.baseUoM}</div>
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                ) : visibleSearch === true ? (
                  <EmptyList img={Search} title={t("NO_RESULT_FOUND")} />
                ) : (
                  <EmptyList img={Img} title={t("product:NO_PRODUCT_AVAILABLE")} />
                )
              ) : (
                <EmptyList img={Img} title={t("product:NO_PRODUCT_AVAILABLE")} />
              )}
            </>
          )}
        </div>
      </div>
      <Popover
        open={Boolean(categoryPopup.anchorEl)}
        anchorEl={categoryPopup.anchorEl}
        onClose={closeCategoryPopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <MenuItem
          style={{ color: "#272B2F", fontSize: "1.6rem" }}
          onClick={() => {
            // setVisibleUploadImage(true);
          }}>
          {t("product:NO_PRODUCT_AVAILABLE")}
        </MenuItem>
      </Popover>
      {/* update image division */}
      {/* <Dialog maxWidth="md" open={visibleUploadImage}>
        <div className="upload-img-devision">
          <div className="title">
            Thay đổi ảnh đại diện
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setVisibleUploadImage(false);
              }}
            />
          </div>
          <div className="content">
            <div className="image">
              {selectImage ? (
                <img src={selectImage} alt={"avt"} width="240px" height="240px" />
              ) : (
                <div className="image-null">
                  <img src={Icon} alt="avt" width="49px" height="49px" />
                  <div className="text">
                    Kéo thả hoặc lựa chọn ảnh
                    <br />
                    Kích thước tối đa dưới 2MB
                  </div>
                </div>
              )}
            </div>
            <div className="list-img">
              <div
                className="item"
                onClick={() => {
                  setSelectImage(IconDivision);
                }}>
                <img src={IconDivision} alt="avt" />
                Mặc định
              </div>
            </div>
          </div>
          <div className="btn-gr">
            <button className="btn-cancel-style btn__text-cancel">Hủy</button>
            <button
              className="btn-confirm-style"
              onClick={() => {
                createImage(selectImage);
              }}>
              Chọn
            </button>
          </div>
        </div>
      </Dialog> */}

      {/* {sourceType === "farm" && (
        <FloatingBtn
          visible={false}
          onClickPrimary={() => {
            history.push(APP_ROUTES.CREATE_PRODUCT);
          }}
          btns={[]}
        />
      )} */}

      <NotiExportExcel
        notiExport={notiExport}
        title={t("PRODUCT")}
        closeForm={() => {
          setNotiExport(false);
          clearInterval(checkExportRef.current);
        }}
        success={success?.isExport === false ? success?.fileName : undefined}
      />
    </div>
  );
};

export default ProductListing;
