import { REQUEST_URL } from "@Constants";
import { DetailItemPlanInputModel, PaginationQueryModel, PlanInputModel } from "@Models";
import { RequestUtil } from "@Utils";

export const PlanService = {
  getAllPlan: (
    query: PaginationQueryModel & { from?: string; to?: string; strSearch?: string; status: string }
  ) => {
    const url = REQUEST_URL.ORDER_PLAN;
    return RequestUtil.get({ url, query: query });
  },

  getPlanById(planId: string) {
    const url = REQUEST_URL.DETAIL_ORDER_PLAN?.replace("{planId}", planId);
    return RequestUtil.get({ url });
  },

  updatePlanStatus: (
    planId: string,
    data: { status: string; reason: string; endReason?: string }
  ) => {
    const url = REQUEST_URL.UPDATE_STATUS_ORDER_PLAN.replace("{planId}", planId);
    return RequestUtil.put({ url, data });
  },

  createPlan: (data: PlanInputModel) => {
    const url = REQUEST_URL.CREATE_ORDER_PLAN;
    return RequestUtil.post({ url, data });
  },

  deletePlan: (planId: string) => {
    const url = REQUEST_URL.DETAIL_ORDER_PLAN?.replace("{planId}", planId);
    return RequestUtil.delete({ url });
  },

  createOrderByPlan: (data: { orderPlanId: number; orderPlanDetailId: number }) => {
    const url = REQUEST_URL.CREATE_ORDER_FEED_BY_PLAN;
    return RequestUtil.post({ url, data });
  },

  updatePlanById: (planId: string, data: PlanInputModel) => {
    const url = REQUEST_URL.UPDATE_ORDER_PLAN.replace("{planId}", planId);
    return RequestUtil.put({ url, data });
  },

  updatePlanDetailById: (detailId: string, data: DetailItemPlanInputModel) => {
    const url = REQUEST_URL.UPDATE_DETAIL_ORDER_PLAN.replace("{detailId}", detailId);
    return RequestUtil.put({ url, data });
  },

  updatePlanDetailStatus: (detailId: string, data: { status: string }) => {
    const url = REQUEST_URL.UPDATE_STATUS_DETAIL_ORDER_PLAN.replace("{detailId}", detailId);
    return RequestUtil.put({ url, data });
  },
};
