/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Clear as ClearIcon,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { AxiosError } from "axios";
import { vi } from "date-fns/locale";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import Ord from "@Assets/images/ordnull.png";
import {
  Action,
  AddressModal,
  FetchAction,
  ItemPermission,
  OrderDetailOutputModel,
  ProductOutputModel,
  Reason,
} from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService, OrderService, ProductService } from "@Services";
import {
  OrderUtil,
  PermissionUtil,
  checkLinkImage,
  currencyFormat,
  renderErrorImageItem,
  renderImageProNull,
} from "@Utils";
// import { DetachIcon } from "@Components/Icons/DetachIcon";
import { EventIcon } from "@Components/Icons/EventIcon";
import { MapIcon } from "@Components/Icons/MapIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import ResultOrder from "@Components/ResultOrder";
import ShippingAddress from "@Components/ShippingAddress";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import {
  APP_ROUTES,
  ConditionQuantity,
  ReasonStatus,
  TEXT_ASTERISK,
  TEXT_HOLDER,
} from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import "../OrderFarmModal.scss";
import { useTranslation } from "react-i18next";

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

type OrderProduct = OrderDetailOutputModel & ProductOutputModel;
type ProductFarmModel = {
  item: OrderProduct;
  quantity: number;
  dimension: number;
  date?: any;
  orderAddress?: string;
  note?: string;
  requiredTime?: string;
};

const OrderDraftFarm: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const [listTimeFromSap, setListTimeFromSap] = useState([]);

  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const location = useLocation<any>();
  const searchQuery = new URLSearchParams(location.search);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<any>();
  console.log(order, "order----------------");
  const submitRef = useRef<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOrder, setNotiOrder] = useState<{
    visible: boolean;
    newOrder: boolean;
  }>({
    visible: false,
    newOrder: false,
  });
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);
  const mounted = useRef(false);
  const [dataOrd, setDataOrd] = useState<any>({});
  const [orderDetail, setOrderDetail] = useState<any>([]);
  const [ordDetailValue, setOrdDetailValue] = useState<any>([]);
  const [submitCancel, setSubmitCancel] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [cancelValue, setCancelValue] = useState<string>(Reason.NOT_RESPONSE);
  const [reason, setReason] = useState<string>("");

  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  const isDraftOrder = location.state?.type === "DRAFT";

  let sub = 0;
  const ordDetail = order?.orders
    .filter((o: any) => o.childOrders.length === 0)
    .map((o: any) => {
      return o.orderlines.map((d: any) => {
        const valPriceDimen = +d.prices.dimension;

        switch (d.prices.priceType) {
          case "bs":
            sub = +d.dimension * d.price;
            break;
          case "fixed":
            sub = (+d.dimension * d.price + +d.prices.additionValue) * +d.orderQuantity;
            break;
          case "increment":
            sub =
              valPriceDimen * d.price * +d.orderQuantity +
              Math.max(0, +d.dimension / +d.orderQuantity - valPriceDimen) *
                +d.orderQuantity *
                +d.prices.additionValue;
            break;
          default:
        }

        return {
          orderId: o.id,
          itemId: d.item?.id,
          divisionId: o.division.id,
          discount: 0,
          quantity: +d.orderQuantity,
          addedProduct: o.orderlines,
          unitPrice: checkPermissionViewPrice ? d.price : -1,
          orderAddress: o.address,
          note: d.note,
          date: o.requiredDate,
          priceType: d.prices.priceType,
          attachValue: +d.prices.additionValue,
          saleUnit: d.item.saleUnit,
          baseUoM: d.item.baseUoM,
          dimensionToShow: d.dimensionToShow,
          value: checkPermissionViewPrice ? d.price : -1,
          dimension: +d.dimension / +d.orderQuantity,
          // subTotal: +d.orderQuantity * d.price,
          subTotal: checkPermissionViewPrice ? sub : -1,
          requiredTime: o?.requiredTime?.key,
        };
      });
    });

  useEffect(() => {
    order && setOrderDetail([].concat(...ordDetail));
    setOrdDetailValue(
      order?.orders.map((o: any) => {
        return o.orderlines.map((d: any) => {
          return d;
        });
      })
    );
  }, [order]);

  console.log(orderDetail);
  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(+orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    profile && orderById();
  }, [profile]);

  //API get prod
  const [states, dispatchs] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  console.log(456);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (states.fetching) return;
    dispatchs({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.getProductByAdminFarm(query);
      const resultTime = await ProductService.getTimeFromSap({
        page: 1,
        limit: 99,
      });
      if (resultTime) {
        setListTimeFromSap(resultTime.data.data);
      }
      if (isReset)
        dispatchs({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatchs({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatchs({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    order &&
      order?.orders[0].customer !== null &&
      fetchData(
        {
          page: 1,
          limit: 20,
          source: "farm",
          searchValue: searchValue,
          customerId: order?.orders[0].customer.id,
          itemGroupId: searchQuery.get("itemGroupTypeId"),
        },
        true
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, order]);

  useEffect(() => {
    order &&
      order?.orders[0].customer === null &&
      fetchData(
        {
          page: 1,
          limit: 20,
          source: "farm",
          searchValue: searchValue,
          leadId: order?.orders[0].lead.id,
          itemGroupId: searchQuery.get("itemGroupTypeId"),
        },
        true
      );
  }, [searchValue, order]);

  const hasMore = states.data?.length < states.totalRecords && states.page < states.totalPages;

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 1000);

  const [proFarm, setProFarm] = useState<ProductFarmModel[]>([]);

  const onUpdateProdVarDimension = (
    index: number,
    variantId: number,
    quantity: number,
    dimen: any,
    addedProduct?: OrderProduct,
    orderDate?: any,
    ordAddress?: any,
    note?: string
  ) => {
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);

    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimen,
        date: orderDate || new Date(),
        orderAddress: ordAddress || "",
        note: note || "",
      };
    } else {
      // thêm mới
      if (!addedProduct) {
        return;
      }

      data.push({
        item: addedProduct,
        quantity,
        dimension: dimen,
        date: orderDate,
        orderAddress: ordAddress,
        note: note,
      });
    }
    data = data.filter((item) => item.quantity >= 1);

    setProFarm(data);
  };

  const onUpdateProdVarQuantityDetail = (
    index: number,
    variantId: number,
    quantity: number,
    addedProduct?: any,
    dimension?: number,
    orderDate?: any,
    ordAddress?: any,
    note?: string,
    total?: number
  ) => {
    const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
    let data = [].concat(...orderDetail) as any;

    // update
    if (quantity === -1) {
      data.splice(idx, 1);
      ordDetailValue.splice(idx, 1);
      setOrdDetailValue(ordDetailValue);
    } else {
      data[index] = {
        ...data[index],
        quantity: quantity || 1,
        dimension: dimension,
        date: orderDate || new Date(),
        orderAddress: ordAddress || "",
        note: note || "",
      };
    }

    setOrderDetail(data);
  };

  // const onDetachOrder = (
  //   variantId: number,
  //   quantity: number,
  //   dimen: number,
  //   addedProduct?: OrderProduct,
  //   orderDate?: any,
  //   ordAddress?: string,
  //   note?: string
  // ) => {
  //   // const idx = proFarm.findIndex((item) => item.item.item.id === variantId);

  //   let data = [...proFarm];

  //   if (!addedProduct) {
  //     return;
  //   }

  //   data.push({
  //     item: addedProduct,
  //     quantity,
  //     dimension: dimen,
  //     date: orderDate,
  //     orderAddress: ordAddress,
  //     note: note,
  //   });
  //   //}
  //   data = data.filter((item) => item.quantity >= 1);

  //   setProFarm(data);
  // };

  // const onDetachOrderDetail = (
  //   variantId: number,
  //   quantity: number,
  //   addedProduct?: any,
  //   dimension?: number,
  //   orderDate?: any,
  //   ordAddress?: any,
  //   note?: string
  // ) => {
  //   const idx = [].concat(...orderDetail).findIndex((item: any) => item.itemId === variantId);
  //   let data = [].concat(...orderDetail) as any;

  //   if (!addedProduct) {
  //     return;
  //   }

  //   data.push({
  //     ...data[idx],
  //     addedProduct: addedProduct,
  //     quantity: quantity,
  //     dimension: dimension,
  //     date: orderDate,
  //     orderAddress: ordAddress,
  //     note: note,
  //   });

  //   setOrderDetail(data);
  // };

  const total = proFarm.reduce((sum, val) => {
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0]
        : val.dimension[1]
      : +val.dimension;

    const valPriceDimen = +val.item.dimension;

    switch (val.item.priceType) {
      case "bs":
        return sum + val.quantity * valDimen * val.item.value;
      case "fixed":
        return (
          sum + (valDimen * val.item.value + parseFloat(val.item.additionValue)) * val.quantity
        );
      default:
        return (
          sum +
          valPriceDimen * val.item.value * val.quantity +
          Math.max(0, valDimen - valPriceDimen) * val.quantity * +val.item.additionValue
        );
    }
  }, 0);

  const totalDetail = orderDetail.reduce((sum: number, val: any) => {
    const valDimen = Array.isArray(val.dimension)
      ? !+val.dimension[1]
        ? val.dimension[0]
        : val.dimension[1]
      : +val.dimension;

    const valPriceDimen = +val.addedProduct?.[0]?.prices?.dimension;

    switch (val.priceType) {
      case "bs":
        return sum + valDimen * +val.value * val.quantity;
      case "fixed":
        return sum + (+valDimen * val.value + val.attachValue) * val.quantity;
      default:
        return (
          sum +
          valPriceDimen * +val.value * val.quantity +
          Math.max(0, valDimen - valPriceDimen) *
            val.quantity *
            +val.addedProduct?.[0]?.prices.additionValue
        );
    }
  }, 0);

  const [checkError, setCheckError] = useState<boolean>(false);
  const checkDate =
    proFarm.filter((item) => item.date === undefined)?.length +
    orderDetail.filter((item: any) => item.date === undefined)?.length;
  const checkAddress =
    proFarm.filter((item) => item.orderAddress === "")?.length +
    orderDetail.filter((item: any) => item.orderAddress === "")?.length;

  const submitForm = async (values: any) => {
    if (checkError) {
      return;
    }
    const validProDim = proFarm.filter((item) => item.quantity);
    if (!validProDim.length) {
      // setNotiOrderNull(true);
    }
    let total = 0;
    let subTotal = 0;

    const orderDetails = validProDim.map((item) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.item.item.dimension;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : 0;
      const itemProduct = { ...item.item.item };
      const unitPrice = Number(item.item.value);
      const grossWeight = Number(
        !itemProduct?.grossWeight || Number(itemProduct?.grossWeight) == 0
          ? valDimention
          : itemProduct?.grossWeight
      );

      const valPriceDimen = +item.item.dimension;

      switch (item.item.priceType) {
        case "bs":
          subTotal = item.quantity * valDimention * item.item.value;
          break;
        case "fixed":
          subTotal =
            valDimention * item.item.value + parseFloat(item.item.additionValue) * item.quantity;
          break;
        case "increment":
          subTotal =
            valPriceDimen * item.item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) * item.quantity * +item.item.additionValue;
          break;
        default:
      }

      total += subTotal;

      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? item.item.value : -1,
        saleUnit: item.item.item.saleUnit,
        attachValue: item.item.additionValue,
        baseUoM: item.item.item.baseUoM,
        dimension: valDimention * item.quantity,
        dimensionToShow: +valDimentionToShow,
        subTotal: checkPermissionViewPrice ? subTotal : -1,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        requiredTime: item.requiredTime,
        note: item.note,
      };
    });

    const validProDetailDim = orderDetail.filter((item: any) => item.quantity);

    let totalDetail = 0;
    let sub = 0;
    const orderDt = validProDetailDim.map((item: any) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0] * item.quantity
          : item.dimension[1] * item.quantity
        : +item.dimension * item.quantity;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : item.dimensionToShow;

      const valPriceDimen = +item.addedProduct?.[0]?.prices?.dimension;

      switch (item.addedProduct[0].prices.priceType) {
        case "bs":
          sub = valDimention * item.value;
          break;
        case "fixed":
          sub = valDimention * item.value + parseFloat(item.attachValue) * item.quantity;
          break;
        case "increment":
          sub =
            valPriceDimen * item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) *
              item.quantity *
              +item.addedProduct?.[0]?.prices.additionValue;
          break;
        default:
      }

      totalDetail += sub;

      return {
        itemId: item.addedProduct[0].item.id,
        divisionId: item.divisionId,
        discount: 0,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? item.value : -1,
        saleUnit: item.addedProduct[0].item.saleUnit,
        attachValue: item.attachValue,
        baseUoM: item.addedProduct[0].item.baseUoM,
        dimension: valDimention,
        dimensionToShow: valDimentionToShow,
        subTotal: checkPermissionViewPrice ? sub : -1,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
        note: item.note,
      };
    });
    console.log(order);
    if (!ordDraft) {
      try {
        const debtData = await OrderService.getDebtCustomer(
          order.orders[0].customer !== null ? order.orders[0].customer.id : undefined
        );
        setLoading(false);
        if (debtData && debtData.data.debt < 1000000) {
          const data: Parameters<typeof OrderService.updateOrderDraft>[0] = {
            // ...values,
            customerId: order.orders[0].customer !== null ? order.orders[0].customer.id : undefined,
            leadId: order.orders[0].customer !== null ? undefined : order.orders[0].lead.id,
            creatorId: order.createdBy.id,
            salesOrgId: order.orders[0].salesOrg.id,
            distributionChannelId:
              order.orders[0].customer !== null ? order.orders[0].distributionChannel.id : 4,
            address: order.orders[0].customer !== null ? order.orders[0].customer.address : "",
            currency: "cash",
            totalDiscount: 0,
            vat: 0,
            orderAmount: checkPermissionViewPrice ? total + totalDetail : -1,
            source: "farm",
            orderDetails: orderDt.concat(orderDetails),
            isDraft: ordDraft,
            draftId: +orderId,
            requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
            itemGroupTypeId: order?.orderType === "farm" ? order?.itemGroupType?.id : null,
            depositId: order?.orderType === "farm" ? order?.deposit?.id : null,
            salesOfficeId: order?.orders[0]?.salesOffice?.id ?? null,
          } as any;

          OrderService.updateOrderDraft(data as any)
            .then((res) => {
              if (res && (res.status === 200 || res.status === 201)) {
                setNotiOrder({ visible: true, newOrder: true });
                setDataOrd(res.data.data.data);
                setPlaying(true);
                setLoading(false);
              } else {
                setLoading(false);
              }
            })
            .catch((err: AxiosError) => {
              setLoading(false);
              if (err.response?.status === 400) {
                enqueueSnackbar("Số lượng không hợp lệ", {
                  variant: "error",
                });
              } else {
                setNotiOrder({ visible: true, newOrder: false });
              }
            });
        } else {
          enqueueSnackbar("Khách hàng vượt quá công nợ cho phép đặt hàng, vui lòng kiểm tra lại", {
            variant: "error",
          });
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      const data: Parameters<typeof OrderService.updateOrderDraft>[0] = {
        // ...values,
        customerId: order.orders[0].customer !== null ? order.orders[0].customer.id : undefined,
        leadId: order.orders[0].customer !== null ? undefined : order.orders[0].lead.id,
        creatorId: order.createdBy.id,
        salesOrgId: order.orders[0].salesOrg.id,
        distributionChannelId:
          order.orders[0].customer !== null ? order.orders[0].distributionChannel.id : 4,
        address: order.orders[0].customer !== null ? order.orders[0].customer.address : "",
        currency: "cash",
        totalDiscount: 0,
        vat: 0,
        orderAmount: checkPermissionViewPrice ? total + totalDetail : -1,
        source: "farm",
        orderDetails: orderDt.concat(orderDetails),
        isDraft: ordDraft,
        draftId: +orderId,
        requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
        itemGroupTypeId: order?.orderType === "farm" ? order?.itemGroupType?.id : null,
        depositId: order?.orderType === "farm" ? order?.deposit?.id : null,
        salesOfficeId: order?.orders[0]?.salesOffice?.id ?? null,
      } as any;

      OrderService.updateOrderDraft(data as any)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            if (ordDraft === true) {
              history.push(APP_ROUTES.ORDER_FARM);
            } else {
              setNotiOrder({ visible: true, newOrder: true });
              setDataOrd(res.data.data.data);
              setPlaying(true);
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((err: AxiosError) => {
          setLoading(false);
          if (err.response?.status === 400) {
            enqueueSnackbar("Số lượng không hợp lệ", {
              variant: "error",
            });
          } else {
            setNotiOrder({ visible: true, newOrder: false });
          }
        });
    }
  };

  const updateRequiredTime = (index: number, variantId: number, idRequiredtime: any) => {
    const idx = proFarm.findIndex((item) => item.item.item.id === variantId);
    let data = [...proFarm];
    if (idx !== -1) {
      data[index] = {
        ...data[index],
        requiredTime: idRequiredtime,
      };
    }
    data = data.filter((item) => item.quantity >= 1);
    setProFarm(data);
  };

  const submitFormUpdateOrder = async (values: any) => {
    if (checkError) {
      return;
    }
    const validProDim = proFarm.filter((item) => item.quantity);
    if (!validProDim.length) {
      // setNotiOrderNull(true);
    }
    let totalUpdateOrder = 0;
    let subTotal = 0;

    const orderDetails = validProDim.map((item) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.item.item.dimension;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : 0;

      const valPriceDimen = +item.item.dimension;

      switch (item.item.priceType) {
        case "bs":
          subTotal = item.quantity * valDimention * item.item.value;
          break;
        case "fixed":
          subTotal =
            (valDimention * item.item.value + parseFloat(item.item.additionValue)) * item.quantity;
          break;
        case "increment":
          subTotal =
            valPriceDimen * item.item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) * item.quantity * +item.item.additionValue;
          break;
        default:
      }

      totalUpdateOrder += subTotal;

      return {
        itemId: item.item.item.id,
        divisionId: item.item.item.division.id,
        discount: 0,
        quantity: item.quantity,
        unitPrice: +item.item.value,
        saleUnit: item.item.item.saleUnit,
        note: item.note,
        attachValue: item.item.additionValue,
        baseUoM: item.item.item.baseUoM,
        dimension: valDimention * item.quantity,
        dimensionToShow: +valDimentionToShow,
        subTotal: subTotal,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
      };
    });

    const validProDetailDim = orderDetail.filter((item: any) => item.quantity);

    let totalDetailUpdateOrder = 0;
    let sub = 0;
    const orderDt = validProDetailDim.map((item: any) => {
      const valDimention = Array.isArray(item.dimension)
        ? !+item.dimension[1]
          ? item.dimension[0]
          : item.dimension[1]
        : +item.dimension;

      const valDimentionToShow = Array.isArray(item.dimension)
        ? !+item.dimension[0]
          ? item.dimension[1]
          : item.dimension[0]
        : item.dimensionToShow;

      const valPriceDimen = +item.addedProduct?.[0]?.prices?.dimension;

      switch (item.addedProduct[0].prices.priceType) {
        case "bs":
          sub = valDimention * item.value * item.quantity;
          break;
        case "fixed":
          sub = (valDimention * item.value + parseFloat(item.attachValue)) * item.quantity;
          break;
        case "increment":
          sub =
            valPriceDimen * item.value * item.quantity +
            Math.max(0, valDimention - valPriceDimen) *
              item.quantity *
              +item.addedProduct?.[0]?.prices.additionValue;
          break;
        default:
      }

      totalDetailUpdateOrder += sub;

      return {
        itemId: item.addedProduct[0].item.id,
        divisionId: item.divisionId,
        discount: 0,
        quantity: item.quantity,
        unitPrice: checkPermissionViewPrice ? +item.value : -1,
        saleUnit: item.addedProduct[0].item.saleUnit,
        attachValue: checkPermissionViewPrice ? item.attachValue : -1,
        baseUoM: item.addedProduct[0].item.baseUoM,
        orderId: item.orderId,
        note: item.note,
        dimension: valDimention * item.quantity,
        dimensionToShow: +valDimentionToShow,
        subTotal: checkPermissionViewPrice ? sub : -1,
        address: item.orderAddress,
        requiredDate: moment(item.date || new Date()).format("YYYY-MM-DD"),
      };
    });

    const data: Parameters<typeof OrderService.updateOrderItem>[0] = {
      requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
      vat: 0,
      orderAmount: checkPermissionViewPrice ? totalUpdateOrder + totalDetailUpdateOrder : -1,
      orderGroupId: +orderId,
      orderDetails: orderDt.concat(orderDetails),
      itemGroupTypeId: order?.orderType === "farm" ? order?.itemGroupType?.id : null,
      depositId: order?.orderType === "farm" ? order?.deposit?.id : null,
    } as any;
    OrderService.updateOrderItem(data as any)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setLoading(false);
          enqueueSnackbar(t("order:ORDER_UPDATE_SUCCESSFUL"), {
            variant: "success",
          });
          history.push(APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", orderId));
        } else {
          setLoading(false);
          enqueueSnackbar(t("order:ORDER_UPDATE_FAILED"), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar(t("order:ORDER_UPDATE_FAILED"), {
          variant: "error",
        });
      });
  };

  const saveAddress = async (val: string) => {
    await CustomerService.createAddress({
      customerId: order?.orders[0].customer.id,
      name: order?.orders[0].customer.fullName,
      phoneNumber: order?.orders[0].customer.phoneNumber,
      address: val,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar(t("order:ADDRESS_CREATION_SUCCESSFUL"), {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(t("order:ADDRESS_CREATION_FAILED"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (!isDraftOrder && order && orderDetail.length < 1 && proFarm.length === 0) {
      setShowCancel(true);
    } else {
      setShowCancel(false);
    }
  }, [orderDetail, proFarm]);

  const cancelOrderFarm = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrder({
        reasonStatus: cancelValue,
        orderGroupId: +orderId,
        reason: reason,
        source: "farm",
      });
      enqueueSnackbar(t("order:ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", orderId));
    } catch (error: any) {
      if (error.response?.status === 403) {
        // setPermission(true);
      } else {
        enqueueSnackbar(t("order:CANCEL_ORDER_FAILED"), {
          variant: "error",
        });
      }
      setSubmitCancel(false);
      // submitRef.current.disabled = false;
    }
  };

  const deleteOrderDraft = async () => {
    try {
      await OrderService.deleteOrderDraft({
        orderGroupId: +orderId,
        source: "farm",
        customerId: order.createdBy.id,
      });
      enqueueSnackbar(t("order:DELETE_CART_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_FARM);
    } catch (error) {
      enqueueSnackbar(t("order:DELETE_CART_FAILED"), {
        variant: "error",
      });
    }
  };

  console.log(order?.deposit);
  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal">
        <SimpleModalHeader
          title={`${t("order:EDIT_ORDER")}
             - ${t("FARM")}`}
          onClose={() => {
            isDraftOrder
              ? history.push(APP_ROUTES.ORDER_FARM)
              : history.push(APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", orderId));
          }}
        />
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form
                onSubmit={async (values: any) => {
                  isDraftOrder ? submitForm(values) : submitFormUpdateOrder(values);
                }}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="header">
                        <div className="left">
                          {order?.orders[0].customer !== null ? (
                            <>
                              <img
                                src={
                                  checkLinkImage(order?.orders[0].customer.account?.avatar) ||
                                  renderImageProNull()
                                }
                                alt="Avata"
                                onError={renderErrorImageItem()}
                              />
                              <div>
                                <div className="name">{order?.orders[0].customer.fullName}</div>
                                {order?.orders[0].customer.phoneNumber}
                              </div>
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  checkLinkImage(order?.orders[0].lead.account?.avatar) ||
                                  renderImageProNull()
                                }
                                alt="Avata"
                                onError={renderErrorImageItem()}
                              />
                              <div>
                                <div className="name">{order?.orders[0].lead.fullName}</div>
                                {order?.orders[0].lead.phoneNumber}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="header-col">
                          <div className="header-label">{t("order:BUSINESS_UNIT")} (BU)</div>
                          {order?.orders[0]?.salesOrg.description || TEXT_HOLDER}
                        </div>
                        <div className="header-col">
                          <div className="header-label">Phương thức cọc</div>
                          <div>
                            {order?.deposit?.code} - {order?.deposit?.rate1}%
                          </div>
                        </div>
                      </div>
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="prod">{t("PRODUCT")}</div>
                          <div className="quan">{t("QUANTITY")}</div>
                          <div className="dimen">{t("WEIGHT_RANGE")}</div>
                          <div className="unit">{t("TOTAL_WEIGHT")}</div>
                          <div className="price">{t("UNIT_PRICE")}</div>
                          <div className="additionValue">{t("order:STOCK_FEE_EXCESS_PORTION")}</div>
                          <div className="pay">{t("TOTAL_PRICE")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {orderDetail &&
                            orderDetail.map((d: any, idx: number) => {
                              const orderDetailRender = orderDetail.reduce(
                                (result: object | null, od: any) => {
                                  const orderL = d.addedProduct.find(
                                    (odl: any) => odl.item.id === d.itemId
                                  );
                                  if (orderL) {
                                    result = orderL;
                                  }
                                  return result;
                                },
                                null
                              );
                              return (
                                <>
                                  <div key={idx} className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(orderDetailRender.item.images?.picture) ||
                                          renderImageProNull(orderDetailRender.item.source)
                                        }
                                        alt="product"
                                        onError={renderErrorImageItem(
                                          orderDetailRender.item.source
                                        )}
                                      />
                                      <div className="name">
                                        {orderDetailRender["item"]["name"]}
                                        <div className="code">
                                          {orderDetailRender["item"]["code"]}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={d.quantity <= 1}
                                        quantity={d.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarQuantityDetail(
                                            idx,
                                            d.itemId,
                                            value,
                                            d.addedProduct,
                                            d.dimension,
                                            d.date,
                                            d.orderAddress,
                                            d.note
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                      {orderDetailRender["item"]["conditionQuantity"] !== null && (
                                        <div
                                          className={
                                            OrderUtil.checkQuantityOrderFarm(
                                              d.quantity,
                                              orderDetailRender["item"]["conditionQuantity"] as any
                                            )
                                              ? "err-quantity"
                                              : "err-quantity err"
                                          }>
                                          {t("order:REQUIRED_QUANTITY")}&nbsp;
                                          <span style={{ textTransform: "lowercase" }}>
                                            {
                                              ConditionQuantity.find(
                                                (f: any) =>
                                                  orderDetailRender["item"][
                                                    "conditionQuantity"
                                                  ]?.replace(/\d/g, "") === f.value
                                              )?.title
                                            }
                                          </span>
                                          &nbsp;
                                          {orderDetailRender["item"]["conditionQuantity"]?.match(
                                            /[0-9]+/g
                                          )?.[1]
                                            ? orderDetailRender["item"]["conditionQuantity"]?.match(
                                                /[0-9]+/g
                                              )?.[0] +
                                              "-" +
                                              orderDetailRender["item"]["conditionQuantity"]?.match(
                                                /[0-9]+/g
                                              )?.[1]
                                            : orderDetailRender["item"]["conditionQuantity"]?.match(
                                                /[0-9]+/g
                                              )?.[0]}
                                        </div>
                                      )}
                                    </div>
                                    {Array.isArray(orderDetailRender["item"]["dimension"]) ===
                                    true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(orderDetailRender["item"]["dimension"]) && (
                                          <>
                                            <Select
                                              defaultValue={orderDetailRender.item.dimension
                                                .find((f: any) =>
                                                  f[0] !== "null"
                                                    ? +f[1] === +d.dimensionToShow
                                                    : +f[0] === +d.dimensionToShow
                                                )
                                                ?.toString()}
                                              onChange={(e) => {
                                                e.target.value.split(",");
                                                onUpdateProdVarQuantityDetail(
                                                  idx,
                                                  d.itemId,
                                                  d.quantity,
                                                  d.addedProduct,
                                                  e.target.value.split(","),
                                                  d.requiredDate,
                                                  d.orderAddress,
                                                  d.note
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {orderDetailRender["item"]["dimension"].map(
                                                (d, idx: number) => {
                                                  return (
                                                    <MenuItem
                                                      key={idx}
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "flex-start",
                                                      }}
                                                      value={d.toString()}>
                                                      {(() => {
                                                        if (d[1] === "null") {
                                                          return "trên " + d[0];
                                                        } else if (d[0] === "null") {
                                                          return "dưới " + d[1];
                                                        } else {
                                                          return d[0] + " - " + d[1];
                                                        }
                                                      })()}
                                                      &#160;kg
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                            {/* {orderDetailRender["item"]["conditionQuantity"] !==
                                              null && (
                                              <div
                                                className={
                                                  OrderUtil.checkQuantityOrderFarm(
                                                    d.quantity,
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ] as any
                                                  )
                                                    ? "err-quantity"
                                                    : "err-quantity err"
                                                }>
                                                {t("order:REQUIRED_QUANTITY")}&nbsp;
                                                <span style={{ textTransform: "lowercase" }}>
                                                  {
                                                    ConditionQuantity.find(
                                                      (f: any) =>
                                                        orderDetailRender["item"][
                                                          "conditionQuantity"
                                                        ]?.replace(/\d/g, "") === f.value
                                                    )?.title
                                                  }
                                                </span>
                                                &nbsp;
                                                {orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[1]
                                                  ? orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[0] +
                                                    "-" +
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[1]
                                                  : orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.match(/[0-9]+/g)?.[0]}
                                                </div>
                                              )} */}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {+orderDetailRender["item"]["dimension"]}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{orderDetailRender["item"]["baseUoM"]}
                                        </span>
                                        {/* {orderDetailRender["item"]["conditionQuantity"] !==
                                          null && (
                                          <div
                                            className={
                                              OrderUtil.checkQuantityOrderFarm(
                                                d.quantity,
                                                orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ] as any
                                              )
                                                ? "err-quantity"
                                                : "err-quantity err"
                                            }>
                                            {t("order:REQUIRED_QUANTITY")}&nbsp;
                                            <span style={{ textTransform: "lowercase" }}>
                                              {
                                                ConditionQuantity.find(
                                                  (f: any) =>
                                                    orderDetailRender["item"][
                                                      "conditionQuantity"
                                                    ]?.replace(/\d/g, "") === f.value
                                                )?.title
                                              }
                                            </span>
                                            &nbsp;
                                            {orderDetailRender["item"]["conditionQuantity"]?.match(
                                              /[0-9]+/g
                                            )?.[1]
                                              ? orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[0] +
                                                "-" +
                                                orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[1]
                                              : orderDetailRender["item"][
                                                  "conditionQuantity"
                                                ]?.match(/[0-9]+/g)?.[0]}
                                            </div>
                                          )} */}
                                      </div>
                                    )}
                                    <div className="unit unit-farm">
                                      {Array.isArray(d.dimension)
                                        ? !+d.dimension[1]
                                          ? currencyFormat(d.dimension[0] * d.quantity)
                                          : currencyFormat(d.dimension[1] * d.quantity)
                                        : currencyFormat(+d.dimension * d.quantity)}

                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {orderDetailRender["item"]["baseUoM"]} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(orderDetailRender["price"])
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{d.baseUoM}</span>
                                    </div>
                                    <div className="additionValue">
                                      {(() => {
                                        if (+orderDetailRender.prices?.additionValue) {
                                          if (orderDetailRender.prices?.priceType === "increment") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      orderDetailRender.prices?.additionValue
                                                    )
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {orderDetailRender.baseUoM}
                                                </span>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      orderDetailRender.prices?.additionValue
                                                    )
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {orderDetailRender.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          }
                                        } else return TEXT_HOLDER;
                                      })()}
                                    </div>
                                    <div className="pay">
                                      {(() => {
                                        const valDimen = Array.isArray(d.dimension)
                                          ? !+d.dimension[1]
                                            ? d.dimension[0]
                                            : d.dimension[1]
                                          : d.dimension;

                                        const valPriceDimen =
                                          +d.addedProduct?.[0]?.prices.dimension;

                                        switch (orderDetailRender["prices"]["priceType"]) {
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valDimen *
                                                        +orderDetailRender["price"] *
                                                        d.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      (valDimen * +orderDetailRender["price"] +
                                                        parseFloat(
                                                          orderDetailRender["prices"][
                                                            "additionValue"
                                                          ]
                                                        )) *
                                                        d.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          default:
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valPriceDimen *
                                                        +orderDetailRender["price"] *
                                                        d.quantity +
                                                        Math.max(0, valDimen - valPriceDimen) *
                                                          d.quantity *
                                                          +d.addedProduct?.[0]?.prices.additionValue
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        if (orderDetail.length < 2 && proFarm.length < 1) {
                                          setShowCancel(true);
                                        } else {
                                          onUpdateProdVarQuantityDetail(idx, d.itemId, -1);
                                        }
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("order:SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                className="form-text-field date"
                                                value={d.date || new Date()}
                                                onChange={(e) => {
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    e,
                                                    d.orderAddress,
                                                    d.note
                                                  );
                                                  input.onChange(e);
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          d.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("order:DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>
                                    <div className="product-order-shipping">
                                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <Field name={`requiredTime${idx}`}>
                                          {({ input, meta, ...rest }) => {
                                            return (
                                              <>
                                                <Select
                                                  onChange={(e) => {
                                                    input.onChange(e.target.value);
                                                    updateRequiredTime(
                                                      idx,
                                                      d.item.item.id,
                                                      e.target.value
                                                    );
                                                  }}
                                                  value={d.requiredTime}
                                                  displayEmpty
                                                  style={{
                                                    height: 29,
                                                    width: "14vw",
                                                    padding: "0px 10px",
                                                  }}
                                                  className="form-select-field"
                                                  MenuProps={{
                                                    className: "select-menu-list",
                                                    style: { maxHeight: "40rem" },
                                                  }}
                                                  renderValue={(selected) => {
                                                    if (!selected) {
                                                      return (
                                                        <div
                                                          style={{
                                                            color: "#a7a7a7",
                                                            // opacity: 1,
                                                            fontWeight: 600,
                                                            fontSize: 10,
                                                          }}>
                                                          Giờ giao hàng
                                                        </div>
                                                      );
                                                    }
                                                    const findObject: any = listTimeFromSap.find(
                                                      (item: any, index) => item.key === selected
                                                    );
                                                    if (findObject) {
                                                      return findObject.delieryTime;
                                                    }
                                                  }}>
                                                  {listTimeFromSap.map((time: any) => (
                                                    <MenuItem key={time.key} value={time.key}>
                                                      {time.delieryTime}
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                                {meta.touched && meta.error && (
                                                  <div
                                                    style={{
                                                      color: "#e53935",
                                                      fontSize: "1.6rem",
                                                    }}>
                                                    {meta.error}
                                                  </div>
                                                )}
                                              </>
                                            );
                                          }}
                                        </Field>
                                      </FormControl>
                                    </div>
                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{
                                              className: "input",
                                              maxLength: 300,
                                            }}
                                            variant="standard"
                                            value={d.note}
                                            onChange={(e) => {
                                              onUpdateProdVarQuantityDetail(
                                                idx,
                                                d.itemId,
                                                d.quantity,
                                                d.addedProduct,
                                                d.dimension,
                                                d.date,
                                                d.orderAddress,
                                                e.target.value
                                              );
                                              input.onChange(e);
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            {order?.orders[0].customer !== null ? (
                                              <ShippingAddress<AddressModal>
                                                value={d.orderAddress}
                                                customerId={
                                                  order?.orders[0].customer !== null
                                                    ? order?.orders[0].customer.id
                                                    : order?.orders[0].lead.id
                                                }
                                                handleSaveAddress={saveAddress}
                                                className={"form-text-field addr"}
                                                placeholder={t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                getOptionLabel={(opt) =>
                                                  typeof opt === "string"
                                                    ? opt
                                                    : opt.address || (opt.inputValue as any)
                                                }
                                                onChange={(value) => {
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    d.date,
                                                    value?.address || value?.inputValue,
                                                    d.note
                                                  );
                                                }}
                                                renderOption={(p) => (
                                                  <div
                                                    className="item-addr"
                                                    style={{
                                                      fontSize: "1.6rem",
                                                      fontFamily: "SVN-Gotham",
                                                      color: p.title ? "#41AD49" : "",
                                                      borderBottom: "0.1rem solid #EFEFEF",
                                                      padding: "1rem 2rem",
                                                      width: "100%",
                                                    }}>
                                                    {p.title ? p.title : p.address}
                                                  </div>
                                                )}
                                                actionConfig={{
                                                  action: CustomerService.getAddress,
                                                }}
                                                dropdownBoxMaxHeight="24rem"
                                              />
                                            ) : (
                                              <TextField
                                                {...input}
                                                {...rest}
                                                fullWidth
                                                placeholder={t("INPUT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                className="form-text-field addr form-text-field-addr"
                                                inputProps={{ className: "input" }}
                                                variant="standard"
                                                value={d.orderAddress}
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  onUpdateProdVarQuantityDetail(
                                                    idx,
                                                    d.itemId,
                                                    d.quantity,
                                                    d.addedProduct,
                                                    d.dimension,
                                                    d.requiredDate,
                                                    e.target.value,
                                                    d.note
                                                  );
                                                }}
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                                onKeyDown={(event) => {
                                                  if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                autoComplete="off"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <MapIcon
                                                        color="#41AD49"
                                                        size={[22, 22]}
                                                        viewBox={[10, 20]}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                d.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>

                                  {/* <div className="detach-more">
                                    <DetachIcon
                                      color="#41AD49"
                                      size={[21, 20]}
                                      viewBox={[20, 12]}
                                    />
                                    <div
                                      className="text"
                                      onClick={() => {
                                        onDetachOrderDetail(
                                          orderDetailRender["item"]["id"],
                                          1,
                                          d.addedProduct,
                                          d.dimension,
                                          new Date(d.requiredDate),
                                          d.orderAddress
                                        );
                                      }}>
                                      Tách dòng
                                    </div>
                                  </div> */}
                                </>
                              );
                            })}
                          {!!proFarm.length &&
                            proFarm.map((val: ProductFarmModel, index: number) => {
                              return (
                                <div key={index}>
                                  <div className="product-order-card">
                                    <div className="prod">
                                      <img
                                        src={
                                          checkLinkImage(val.item.item.images?.picture as any) ||
                                          renderImageProNull(val.item.item.source)
                                        }
                                        alt="product"
                                        onError={renderErrorImageItem(val.item.item.source)}
                                      />
                                      <div className="name">
                                        {val.item.item.name}
                                        <div className="code">{val.item.item.code}</div>
                                      </div>
                                    </div>
                                    <div className="quan">
                                      <QuantityBtnGroup
                                        disableMinusBtn={val.quantity <= 1}
                                        quantity={val.quantity}
                                        onChangeQuantity={(value) => {
                                          onUpdateProdVarDimension(
                                            index,
                                            val.item.item.id,
                                            value,
                                            val.dimension,
                                            val.item,
                                            val.date,
                                            val.orderAddress,
                                            val.note
                                            //val.dimension
                                          );
                                        }}
                                        style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                      />
                                    </div>

                                    {Array.isArray(val.item.item.dimension) === true ? (
                                      <div className="dimen-cond">
                                        {Array.isArray(val.item.item.dimension) && (
                                          <>
                                            <Select
                                              defaultValue={val.item.item.dimension[0]?.toString()}
                                              onChange={(e) => {
                                                onUpdateProdVarDimension(
                                                  index,
                                                  val.item.item.id,
                                                  val.quantity,
                                                  e.target.value.split(","),
                                                  val.item,
                                                  val.date,
                                                  val.orderAddress
                                                );
                                              }}
                                              displayEmpty
                                              variant="standard"
                                              color="success"
                                              fullWidth
                                              MenuProps={{
                                                className: "select-menu-list item-weight",
                                                anchorOrigin: {
                                                  vertical: 30,
                                                  horizontal: "left",
                                                },
                                                transformOrigin: {
                                                  vertical: "top",
                                                  horizontal: "left",
                                                },
                                              }}>
                                              {val.item.item.dimension.map((d, idx: number) => {
                                                return (
                                                  <MenuItem
                                                    key={idx}
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      alignItems: "flex-start",
                                                    }}
                                                    value={d.toString()}>
                                                    {(() => {
                                                      if (d[1] === "null") {
                                                        return "trên " + d[0];
                                                      } else if (d[0] === "null") {
                                                        return "dưới " + d[1];
                                                      } else {
                                                        return d[0] + " - " + d[1];
                                                      }
                                                    })()}
                                                    &#160;kg
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                            {val.item.item.conditionQuantity !== null && (
                                              <div
                                                className={
                                                  OrderUtil.checkQuantityOrderFarm(
                                                    val.quantity,
                                                    val.item.item.conditionQuantity as any
                                                  )
                                                    ? "err-quantity"
                                                    : "err-quantity err"
                                                }>
                                                {t("order:REQUIRED_QUANTITY")}&nbsp;
                                                <span style={{ textTransform: "lowercase" }}>
                                                  {
                                                    ConditionQuantity.find(
                                                      (f: any) =>
                                                        val.item.item.conditionQuantity?.replace(
                                                          /\d/g,
                                                          ""
                                                        ) === f.value
                                                    )?.title
                                                  }
                                                </span>
                                                &nbsp;
                                                {val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                                  ? val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0] +
                                                    "-" +
                                                    val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[1]
                                                  : val.item.item.conditionQuantity?.match(
                                                      /[0-9]+/g
                                                    )?.[0]}{" "}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="dimen">
                                        {val.item.item.dimension}
                                        <span style={{ color: "#41AD49" }}>
                                          &#160;{val.item.item.baseUoM}
                                        </span>
                                        {val.item.item.conditionQuantity !== null && (
                                          <div
                                            className={
                                              OrderUtil.checkQuantityOrderFarm(
                                                val.quantity,
                                                val.item.item.conditionQuantity as any
                                              )
                                                ? "err-quantity"
                                                : "err-quantity err"
                                            }>
                                            {t("order:REQUIRED_QUANTITY")}&nbsp;
                                            <span style={{ textTransform: "lowercase" }}>
                                              {
                                                ConditionQuantity.find(
                                                  (f: any) =>
                                                    val.item.item.conditionQuantity?.replace(
                                                      /\d/g,
                                                      ""
                                                    ) === f.value
                                                )?.title
                                              }
                                            </span>
                                            &nbsp;
                                            {val.item.item.conditionQuantity?.match(/[0-9]+/g)?.[1]
                                              ? val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0] +
                                                "-" +
                                                val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[1]
                                              : val.item.item.conditionQuantity?.match(
                                                  /[0-9]+/g
                                                )?.[0]}{" "}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className="unit unit-farm">
                                      {Array.isArray(val.dimension)
                                        ? !+val.dimension[1]
                                          ? currencyFormat(val.dimension[0] * val.quantity)
                                          : currencyFormat(val.dimension[1] * val.quantity)
                                        : currencyFormat(+val.dimension * val.quantity)}
                                      <span style={{ color: "#41AD49" }}>
                                        &#160;kg
                                        {/* {val.item.item.baseUoM} */}
                                      </span>
                                    </div>
                                    <div className="price text">
                                      {checkPermissionViewPrice
                                        ? currencyFormat(val.item.value)
                                        : TEXT_ASTERISK}{" "}
                                      đ/
                                      <span>{val.item.baseUoM}</span>
                                    </div>
                                    <div className="additionValue">
                                      {(() => {
                                        if (+val.item.additionValue) {
                                          if (val.item.priceType === "increment") {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.baseUoM}
                                                </span>
                                              </span>
                                            );
                                          } else {
                                            return (
                                              <span>
                                                {(checkPermissionViewPrice
                                                  ? currencyFormat(+val.item.additionValue)
                                                  : TEXT_ASTERISK) + " đ/"}
                                                <span style={{ color: "#41ad49" }}>
                                                  {val.item.item.saleUnit}
                                                </span>
                                              </span>
                                            );
                                          }
                                        } else return TEXT_HOLDER;
                                      })()}
                                    </div>

                                    <div className="pay">
                                      {(() => {
                                        const valDimen = Array.isArray(val.dimension)
                                          ? !+val.dimension[1]
                                            ? val.dimension[0]
                                            : val.dimension[1]
                                          : +val.dimension;

                                        const valPriceDimen = +val.item.dimension;

                                        switch (val.item.priceType) {
                                          case "bs":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      val.quantity * valDimen * val.item.value
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          case "fixed":
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      (valDimen * val.item.value +
                                                        parseFloat(val.item.additionValue)) *
                                                        val.quantity
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                          default:
                                            return (
                                              <>
                                                {checkPermissionViewPrice
                                                  ? currencyFormat(
                                                      valPriceDimen *
                                                        val.item.value *
                                                        val.quantity +
                                                        Math.max(0, valDimen - valPriceDimen) *
                                                          val.quantity *
                                                          parseFloat(val.item.additionValue)
                                                    )
                                                  : TEXT_ASTERISK}
                                              </>
                                            );
                                        }
                                      })()}
                                      &ensp;đ
                                    </div>
                                    <div
                                      className="ic-del"
                                      onClick={() => {
                                        if (orderDetail.length < 1 && proFarm.length < 2) {
                                          setShowCancel(true);
                                        } else {
                                          onUpdateProdVarDimension(
                                            index,
                                            val.item.item.id,
                                            -1,
                                            val.dimension
                                          );
                                        }
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          marginLeft: "3rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="product-order-shipping">
                                    <div className="date">
                                      <Field name="requiredDate">
                                        {({ input, meta, ...rest }) => {
                                          return (
                                            <MuiPickersUtilsProvider
                                              locale={vi}
                                              utils={DateFnsUtils}>
                                              <KeyboardDatePicker
                                                okLabel={t("order:SELECT")}
                                                cancelLabel={t("CANCEL")}
                                                fullWidth
                                                disableToolbar
                                                disablePast
                                                inputVariant="standard"
                                                format="dd-MM-yyyy"
                                                className="form-text-field date"
                                                value={val.date || new Date()}
                                                onChange={(e) => {
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    e,
                                                    val.orderAddress,
                                                    val.note
                                                  );
                                                  input.onChange(e);
                                                }}
                                                InputProps={{ readOnly: true }}
                                                inputProps={{ className: "input" }}
                                                keyboardIcon={
                                                  <EventIcon
                                                    color="#41AD49"
                                                    size={[22, 22]}
                                                    viewBox={[22, 22]}
                                                  />
                                                }
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                              />
                                            </MuiPickersUtilsProvider>
                                          );
                                        }}
                                      </Field>
                                      <div
                                        style={{
                                          color: "#e53935",
                                          fontSize: "1.4rem",
                                        }}>
                                        {checkError &&
                                          val.date === undefined &&
                                          t("SELECT_BY_NAME", {
                                            NAME: t("order:DELIVERY_DATE"),
                                          })}
                                      </div>
                                    </div>
                                    <Field name="note">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <TextField
                                            {...input}
                                            {...rest}
                                            fullWidth
                                            placeholder={t("NOTES")}
                                            className="form-text-field form-text-field-note"
                                            inputProps={{ className: "input" }}
                                            variant="standard"
                                            value={val.note}
                                            onChange={(e) => {
                                              onUpdateProdVarDimension(
                                                index,
                                                val.item.item.id,
                                                val.quantity,
                                                val.dimension,
                                                val.item,
                                                e,
                                                val.orderAddress,
                                                e.target.value
                                              );
                                              input.onChange(e);
                                            }}
                                            helperText={meta.touched ? meta.error : ""}
                                            error={meta.error && meta.touched}
                                            onKeyDown={(event) => {
                                              if (event.key === "Enter") {
                                                event.preventDefault();
                                                return false;
                                              }
                                            }}
                                          />
                                        );
                                      }}
                                    </Field>
                                    <Field name="address">
                                      {({ input, meta, ...rest }) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              width: "35%",
                                            }}>
                                            {order?.orders[0].customer !== null ? (
                                              <ShippingAddress<AddressModal>
                                                customerId={
                                                  order?.orders[0].customer !== null
                                                    ? order?.orders[0].customer.id
                                                    : order?.orders[0].lead.id
                                                }
                                                handleSaveAddress={saveAddress}
                                                className={"form-text-field addr"}
                                                placeholder={t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                getOptionLabel={(opt) =>
                                                  opt.address || (opt.inputValue as any)
                                                }
                                                onChange={(value) => {
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    value?.address || value?.inputValue,
                                                    val.note
                                                  );
                                                }}
                                                renderOption={(p) => (
                                                  <div
                                                    className="item-addr"
                                                    style={{
                                                      fontSize: "1.6rem",
                                                      fontFamily: "SVN-Gotham",
                                                      color: p.title ? "#41AD49" : "",
                                                      borderBottom: "0.1rem solid #EFEFEF",
                                                      padding: "1rem 2rem",
                                                      width: "100%",
                                                    }}>
                                                    {p.title ? p.title : p.address}
                                                  </div>
                                                )}
                                                actionConfig={{
                                                  action: CustomerService.getAddress,
                                                }}
                                                dropdownBoxMaxHeight="24rem"
                                              />
                                            ) : (
                                              <TextField
                                                {...input}
                                                {...rest}
                                                fullWidth
                                                placeholder={t("INPUT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                                className="form-text-field addr form-text-field-addr"
                                                inputProps={{ className: "input" }}
                                                variant="standard"
                                                value={val.orderAddress}
                                                onChange={(e) => {
                                                  input.onChange(e.target.value);
                                                  onUpdateProdVarDimension(
                                                    index,
                                                    val.item.item.id,
                                                    val.quantity,
                                                    val.dimension,
                                                    val.item,
                                                    val.date,
                                                    e.target.value,
                                                    val.note
                                                  );
                                                }}
                                                helperText={meta.touched ? meta.error : ""}
                                                error={meta.error && meta.touched}
                                                onKeyDown={(event) => {
                                                  if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    return false;
                                                  }
                                                }}
                                                autoComplete="off"
                                                InputProps={{
                                                  startAdornment: (
                                                    <InputAdornment position="start">
                                                      <MapIcon
                                                        color="#41AD49"
                                                        size={[22, 22]}
                                                        viewBox={[10, 20]}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                color: "#e53935",
                                                fontSize: "1.4rem",
                                                marginLeft: "5rem",
                                              }}>
                                              {checkError &&
                                                val.orderAddress === "" &&
                                                t("SELECT_BY_NAME", {
                                                  NAME: t("order:DELIVERY_ADDRESS"),
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </div>
                                  {/* <div className="detach-more">
                                    <DetachIcon
                                      color="#41AD49"
                                      size={[21, 20]}
                                      viewBox={[20, 12]}
                                    />
                                    <div
                                      className="text"
                                      onClick={() =>
                                        onDetachOrder(val.item.item.id, 1, val.dimension, val.item)
                                      }>
                                      Tách dòng
                                    </div>
                                  </div> */}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid container justifyContent="space-between">
                            <Grid item xs={7} className="info-order"></Grid>
                            <Grid item xs={4} className="total">
                              <div className="row">
                                {t("order:ESTIMATED")}
                                <div className="price">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(total + totalDetail)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                              <div className="row">
                                {t("order:SHIPPING_FEE")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TAX")}
                                <div className="price">0 đ</div>
                              </div>
                              <div className="row">
                                {t("TOTAL")}
                                <div className="price price-color">
                                  {checkPermissionViewPrice
                                    ? currencyFormat(total + totalDetail)
                                    : TEXT_ASTERISK}{" "}
                                  đ
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div
                          className={orderDetail?.length || proFarm.length ? "show" : "hide"}
                          onClick={() => {
                            // !proFarm.length && setNotiOrderNull(false);
                          }}>
                          <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => {
                              isDraftOrder
                                ? setNotiOrderCancel(true)
                                : history.push(
                                    APP_ROUTES.ORDER_DETAIL_FARM.replace(":orderId", orderId)
                                  );
                            }}>
                            {t("CANCEL")}
                          </div>
                          {isDraftOrder && (
                            <button
                              disabled={!orderDetail?.length && !proFarm.length}
                              onClick={() => {
                                if (checkDate > 0 || checkAddress > 0) {
                                  setCheckError(true);
                                } else {
                                  setCheckError(false);
                                  setOrdDraft(true);
                                  setLoading(true);
                                }
                              }}
                              type="submit"
                              className="btn-confirm-style btn-border"
                              style={{ marginLeft: "4rem" }}>
                              {t("order:SAVE_CART")}
                            </button>
                          )}
                          <button
                            disabled={!orderDetail?.length && !proFarm.length}
                            onClick={() => {
                              if (checkDate > 0 || checkAddress > 0) {
                                setCheckError(true);
                              } else {
                                setCheckError(false);
                                setLoading(true);
                              }
                            }}
                            type="submit"
                            className="btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {isDraftOrder ? t("order:PLACE_ORDER") : t("UPDATE")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              <>
                <TextField
                  variant="outlined"
                  color="success"
                  placeholder={t("SEARCH_BY_NAME_PRODUCT_CODE")}
                  className={"search-border-style"}
                  inputProps={{ className: "input" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="large" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                />
                <div className="col-text">{t("order:PIG_PRICE_TEMPORARY")}</div>
                <div className="product-list">
                  {states.fetching && searchValue ? (
                    <Loading />
                  ) : (
                    states.data?.length > 0 && (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={() =>
                          fetchData({
                            page: states.page + 1,
                            limit: 20,
                            source: "farm",
                            searchValue,
                          })
                        }
                        hasMore={hasMore}
                        useWindow={false}>
                        {states.data?.map((pro: any, index: number) => {
                          const act = proFarm.find((item) => item.item.item.id === pro.item.id);
                          const actOrd = []
                            .concat(...orderDetail)
                            .find((item: any) => item.itemId === pro.item.id);

                          const checkDimen = Array.isArray(pro.item.dimension);
                          return (
                            <ProductItem
                              class={act || actOrd}
                              key={pro.item.code + index}
                              onClick={() => {
                                if (act || actOrd) {
                                  return;
                                }
                                onUpdateProdVarDimension(
                                  index,
                                  pro.item.id,
                                  1,
                                  checkDimen ? pro.item.dimension[0] : pro.item.dimension,
                                  pro
                                );
                              }}
                              image={pro.item.images?.picture}
                              shortName={pro.item.name}
                              name={pro.item.code}
                              value={
                                checkPermissionViewPrice ? currencyFormat(pro.value) : TEXT_ASTERISK
                              }
                              baseUoM={pro.baseUoM}
                              farmBaseUoM={pro.item.baseUoM}
                              farmSaleUnit={pro.item.saleUnit}
                              source="farm"
                              additionalValue={currencyFormat(pro.additionValue)}
                              interestedItem={pro?.item?.interestedItem}
                            />
                          );
                        })}
                      </InfiniteScroll>
                    )
                  )}
                </div>
              </>
            </Grid>
          </Grid>
        </div>

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <ResultOrder
          sourceType="farm"
          visible={notiOrder.visible}
          newOrder={notiOrder.newOrder}
          dataOrd={dataOrd}
          setNotiOrder={() => setNotiOrder({ visible: false, newOrder: false })}
        />

        <Notification
          visible={
            isDraftOrder && order && orderDetail.length < 1 && proFarm.length === 0
              ? true
              : notiOrderCancel
          }
          title={t("order:DELETE_CART")}
          subTitle={
            <span
              dangerouslySetInnerHTML={{ __html: t("order:ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }}
            />
          }
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  history.push(APP_ROUTES.ORDER_FARM);
                }}>
                {t("CANCEL")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                ref={submitRef}
                onClick={() => {
                  deleteOrderDraft();
                }}>
                {t("AGREE")}
              </button>
            </>
          }
          onClose={() => {
            // setNotiOrderCancel(false);
            history.push(APP_ROUTES.ORDER_FARM);
          }}
        />
      </div>

      <Dialog maxWidth="md" open={submitCancel}>
        <div className="dialog-cancel">
          <div className="title">
            <div>{t("order:CANCEL_ORDER")}</div>
            <ClearIcon
              style={{ fontSize: "3rem", cursor: "pointer" }}
              onClick={() => {
                setSubmitCancel(false);
              }}
            />
          </div>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={cancelValue}
            onChange={(e) => {
              setCancelValue(e.target.value);
            }}>
            {ReasonStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  value={r.value}
                  control={<Radio color="success" />}
                  label={t(r.label)}
                  className="text"
                />
              );
            })}
            <div className="label">{t("REASON")}</div>
            <TextField
              fullWidth
              placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
              className="form-textarea-field"
              multiline
              rows={3}
              variant="outlined"
              color="success"
              inputProps={{ className: "input" }}
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </RadioGroup>
          <div className="btn-group">
            <button
              className="btn-cancel"
              onClick={() => {
                setSubmitCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{ marginLeft: "2.2rem" }}
              onClick={() => {
                if (profile?.id === order?.createdBy?.id) {
                  cancelOrderFarm();
                } else {
                  enqueueSnackbar("Bạn không thể hủy đơn hàng", {
                    variant: "warning",
                    preventDuplicate: true,
                  });
                }
              }}>
              {t("order:CANCEL_ORDER")}
            </button>
          </div>
        </div>
      </Dialog>

      <Notification
        visible={showCancel}
        title={t("order:CANCEL_ORDER")}
        subTitle={
          <span>{t("order:ARE_YOU_SURE_YOU_WANT_CANCEL_ORDER_BY_NAME", { NAME: "" })}</span>
        }
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setShowCancel(false);
              }}>
              {t("NO")}
            </button>
            <button
              ref={submitRef}
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginLeft: "2rem",
              }}
              onClick={() => {
                setShowCancel(false);
                setSubmitCancel(true);
              }}>
              {t("CONFIRM")}
            </button>
          </>
        }
        onClose={() => {
          setShowCancel(false);
        }}
      />
    </Dialog>
  );
};
export default OrderDraftFarm;
