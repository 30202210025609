import { IconBase, IconComponent } from "@Core/Icons";

export const SignUpIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M13.7376 25.9957C13.6905 26.0014 13.6428 26.0014 13.5956 25.9957C13.5484 26.0014 13.5008 26.0014 13.4536 25.9957C13.3763 25.9772 13.3036 25.9433 13.2397 25.896C13.1758 25.8487 13.1221 25.7891 13.0818 25.7206C13.0415 25.6522 13.0154 25.5763 13.005 25.4975C12.9947 25.4187 13.0003 25.3387 13.0216 25.2621L13.6134 22.8958C13.6416 22.7908 13.6967 22.695 13.7731 22.6177L21.0461 15.347C21.156 15.237 21.2865 15.1497 21.4302 15.0902C21.5739 15.0306 21.7279 15 21.8835 15C22.039 15 22.193 15.0306 22.3367 15.0902C22.4804 15.1497 22.6109 15.237 22.7208 15.347L23.6558 16.2877C23.8763 16.5093 24 16.8092 24 17.1218C24 17.4344 23.8763 17.7343 23.6558 17.9559L16.3829 25.2503C16.3059 25.326 16.2098 25.3792 16.1048 25.4041L13.7376 25.9957Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.5C6 5.22386 6.22386 5 6.5 5H15.5C15.7761 5 16 5.22386 16 5.5V13.4524C16 13.7285 16.2239 13.9524 16.5 13.9524H17.5C17.7761 13.9524 18 13.7285 18 13.4524V5V3.5C18 3.22386 17.7761 3 17.5 3H16H6H4.5C4.22386 3 4 3.22386 4 3.5V5V15V16.5C4 16.7761 4.22386 17 4.5 17H6H12.9464C13.2226 17 13.4464 16.7761 13.4464 16.5V15.5C13.4464 15.2239 13.2226 15 12.9464 15H6.5C6.22386 15 6 14.7761 6 14.5V5.5Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5.5C6 5.22386 6.22386 5 6.5 5H15.5C15.7761 5 16 5.22386 16 5.5V16.4643C16 16.7404 16.2239 16.9643 16.5 16.9643H17.5C17.7761 16.9643 18 16.7404 18 16.4643V5V3.5C18 3.22386 17.7761 3 17.5 3H16H6H4.5C4.22386 3 4 3.22386 4 3.5V5V20V21.5C4 21.7761 4.22386 22 4.5 22H6H11.4405C11.7166 22 11.9405 21.7761 11.9405 21.5V20.5C11.9405 20.2239 11.7166 20 11.4405 20H6.5C6.22386 20 6 19.7761 6 19.5V5.5Z"
        fill={props.color}
      />
    </IconBase>
  );
};
