import {
  OrderItemDetailValue,
  OrderItemDetailWrapper,
} from "@Containers/Order/components/OrderAccordionDetails/styles";
import { OrderOutputModel } from "@Models";
import { StatusUIUtil, toClientDate } from "@Utils";

type OrderAccordionDetailsItemProps = OrderOutputModel["orders"]["0"];

function OrderAccordionDetailsItem({
  orderlines,
  address,
  requiredDate,
  status,
  subStatus,
}: OrderAccordionDetailsItemProps) {
  const { orderQuantity, itemName, item } = orderlines?.[0] || {};
  return (
    <OrderItemDetailWrapper>
      <div>
        <OrderItemDetailValue>{itemName}</OrderItemDetailValue>
        <OrderItemDetailValue color="var(--text-sub)">{item?.code}</OrderItemDetailValue>
      </div>
      <OrderItemDetailValue>{address}</OrderItemDetailValue>
      <OrderItemDetailValue>{toClientDate(requiredDate)}</OrderItemDetailValue>
      <OrderItemDetailValue>{Number(orderQuantity)}</OrderItemDetailValue>
      <div>{StatusUIUtil.renderCircleStatusNew(null, status, subStatus)}</div>
    </OrderItemDetailWrapper>
  );
}

export default OrderAccordionDetailsItem;
