import React from "react";
import clsx from "clsx";
import "./index.scss";
import { Dialog, TextField } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type P_Props = {
  visible: boolean;
  icon: React.ReactNode;
  title: string;
  subTitle: string | React.ReactNode;
  actions: React.ReactNode;
  onClose: () => void;
  className?: string;
  style?: React.CSSProperties;
  isCoorinate?: boolean;
  onTextChange?: (e: any) => void;
};

const Notification: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["translation", "order"]);
  const classes = clsx({
    "notification-comp": true,
    [props.className || ""]: Boolean(props.className),
  });

  return (
    <Dialog fullWidth maxWidth="sm" className={classes} open={props.visible}>
      <ClearIcon
        style={{
          fontSize: "3rem",
          cursor: "pointer",
          margin: "2rem 2rem",
          position: "absolute",
          top: "1.6rem",
          right: "1.6rem",
        }}
        onClick={() => props.onClose()}
      />
      <div className="notification-comp__inner" style={props.style}>
        <div className="notification-comp__inner__icon">{props.icon}</div>
        <div className="notification-comp__inner__title">{props.title}</div>
        <div className="notification-comp__inner__sub-title">{props.subTitle}</div>
        {props.isCoorinate && (
          <div style={{ width: "100%" }}>
            <div className="notification-comp__inner__coordinate-title">{t("REASON")}</div>
            <div className="notification-comp__inner__coordinate">
              <TextField
                color="success"
                multiline
                rows={3}
                onChange={props.onTextChange}
                variant="outlined"
                placeholder={t("INPUT_BY_NAME", { NAME: t("REASON") })}
                className={"form-textarea-field"}
                inputProps={{ className: "input" }}
              />
            </div>
          </div>
        )}
        <div className="notification-comp__inner__actions">{props.actions}</div>
      </div>
    </Dialog>
  );
};

export default Notification;
