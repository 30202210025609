// import {
//   CategoryOutputModel,
//   FetchingDataModel,
//   LanguageOutputModel,
//   UserGender,
// } from ".";
// import { MAIN_MODULES } from "../constants";
// import { RootReducer } from "../store/reducers";
// import { Nullable } from "./shared";

export enum AppNotifications {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export type NotiModel = {
  type: AppNotifications;
  message: string;
};

export type AppNotiModel = {
  id: string;
} & NotiModel;

// export type AppRouteModel = {
//   sidebarTitle: string;
//   appbarTitle: string;
//   path: string;
//   withSubPaths: string[];
//   icons: { active: any; inactive: any };
//   renderComponent: () => any;
//   module: keyof typeof MAIN_MODULES;
//   common?: boolean;
// };
// export type AppRouteMenuModel = {
//   title: string;
//   path: string;
//   images?: { active: any; inactive: any };
//   icons: { active: any; inactive: any };
//   renderComponent: () => any;
// };

// export type RootState = ReturnType<typeof RootReducer>;

export declare module AppState {
  // export type AllCategoryListState = FetchingDataModel<CategoryOutputModel[]>;
  export type AppNotifications = {
    list: AppNotiModel[];
  };

  export type Authentication = {
    loading: boolean;
    data: {
      token: string;
      refreshToken: string | null;
      moduleFeatures: any[];
    } | null;
  };

  export type Profile = {
    loading: boolean;
    data: {
      id: string;
      email: string;
      fullName: string;
      phoneNumber: string;
      code: string | null;
      // googleId: null;
      // facebookId: null;
      // shippingDefaultId: null;
    } | null;
  };

  // export type AllLanguageListState = FetchingDataModel<LanguageOutputModel[]>;
}
