import { Remove as RemoveIcon } from "@mui/icons-material";
import { Moment } from "moment";
import "moment/locale/vi";
import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Calendar1Icon } from "../Icons/Calendar1";
import "./index.scss";

type P_Props = {
  startDate: any;
  endDate: any;
  onChangeDate: (value: any[]) => void;
  font?: string;
  borderRadius?: any;
  color?: string;
};

const RangePicker: React.FC<P_Props> = ({
  startDate,
  endDate,
  onChangeDate,
  borderRadius,
  font,
  color,
}) => {
  const [value, setValue] = useState<{
    startDate: Moment | null;
    endDate: Moment | null;
  }>({
    startDate: startDate,
    endDate: endDate || null,
  });

  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }: any) => {
    if (newStartDate && focusedInput === "startDate") {
      setValue(() => ({
        startDate: newStartDate,
        endDate: null,
      }));
      onChangeDate([newStartDate, null]);
    }
    setValue(() => ({
      startDate: newStartDate,
      endDate: newEndDate,
    }));
    onChangeDate([newStartDate, newEndDate]);
  };

  const handleFocusChange = (newFocus: any) => {
    // Manage focus state
    if (newFocus === "startDate") {
      setValue(() => ({
        startDate: value.startDate,
        endDate: null,
      }));
      onChangeDate([value.startDate, null]);
    }
    setFocusedInput(newFocus);
  };

  return (
    <div
      style={{
        fontFamily: font ? font : "SVN-Gotham",
        color: color ? color : "#44444f",
      }}
      className={`date-range  ${borderRadius ? "borderDateRange" : ""} `}>
      <DateRangePicker
        customInputIcon={<Calendar1Icon color="#ffffff" size={[20, 20]} viewBox={[20, 20]} />}
        startDate={value.startDate}
        startDateId="startDate"
        endDate={value.endDate}
        isOutsideRange={() => false}
        minimumNights={0}
        endDateId="endDate"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        // onFocusChange={(focusedInput: any) => setFocusedInput(focusedInput)}
        onFocusChange={handleFocusChange}
        displayFormat="ll"
        customArrowIcon={<RemoveIcon />}
      />
    </div>
  );
};

export default React.memo(RangePicker);
