import { IconBase, IconComponent } from "@Core/Icons";

export const EmployeeIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M12.9997 9.74935C15.0938 9.74935 16.7913 8.05176 16.7913 5.95768C16.7913 3.8636 15.0938 2.16602 12.9997 2.16602C10.9056 2.16602 9.20801 3.8636 9.20801 5.95768C9.20801 8.05176 10.9056 9.74935 12.9997 9.74935Z"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M4.33333 24.9154C3.46667 24.9154 3.25 24.1931 3.25 23.832C3.25 23.2904 3.3795 21.8009 3.79167 20.582C4.32108 19.0164 4.8526 18.0035 5.94343 16.9377C6.8701 16.0324 7.97021 15.3142 9.18096 14.8242C10.3917 14.3342 11.6894 14.082 12.9999 14.082C15.6466 14.082 18.1849 15.1093 20.0563 16.9377C21.1472 18.0035 21.6789 19.0163 22.2083 20.582C22.6205 21.8009 22.75 23.2904 22.75 23.832C22.75 24.1931 22.5333 24.9154 21.6667 24.9154"
        stroke={props.color}
        strokeWidth="2"
      />
      <path
        d="M13.5418 15.7077L14.6868 14.181C14.8846 13.9173 14.6964 13.541 14.3668 13.541H11.6335C11.3039 13.541 11.1157 13.9173 11.3135 14.181L12.4585 15.7077M13.5418 15.7077H12.4585M13.5418 15.7077L15.1668 22.2077L13.0002 24.3743L10.8335 22.2077L12.4585 15.7077"
        stroke={props.color}
        strokeWidth="2"
      />
    </IconBase>
  );
};
