import GFLabel from "@Components/ui/GFLabel";
import { TextField, TextFieldProps } from "@mui/material";

import { styled } from "@mui/material/styles";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }

  interface TextFieldPropsVariantOverrides {
    dashed: true;
  }
}

const StyledTextField = styled(TextField)(({ theme, color, variant, size }) => ({
  "& .MuiInputBase-root": {
    padding: "0 !important",
    height: "4.8rem",
    ...(size === "small" && {
      height: "3.6rem",
    }),
    ...(variant === "filled" && {
      backgroundColor: "white",
      "&.MuiFilledInput-root::after": {
        borderColor: "var(--primary-green) !important",
      },
    }),
    ...(variant === "outlined" && {
      backgroundColor: "white",
      "& fieldset": {
        border: "none",
      },
    }),
    "& .MuiAutocomplete-endAdornment": {
      top: "50% !important",
    },
    "& .MuiInputAdornment-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "1.6rem",
      fontSize: "2.4rem",
    },

    "& .MuiSvgIcon-root": {
      fontSize: "2.4rem",
    },
    "&.MuiInputBase-adornedStart .MuiSvgIcon-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2.4rem",
      fontSize: "2.4rem",
      marginLeft: "1.6rem",
    },

    "& .MuiInputBase-input": {
      height: "4.8rem",
      textTransform: "none",
      borderRadius: 4,
      color: "var(--text-foreground)",
      fontSize: "1.6rem",
      padding: "0 !important",
      lineHeight: 1,
      fontWeight: 700,
      paddingInline: "1.6rem !important",

      // --- SIZES ---
      ...(size === "small" && {
        fontSize: "1.4rem",
        height: "3.6rem",
        paddingInline: "0.8rem !important",

        "& .MuiSvgIcon-root": {
          fontSize: "1.6rem",
        },
      }),
      ...(size === "large" && {
        fontSize: "1.8rem",
        height: "5.6rem",
        paddingInline: "2rem !important",
        "& .MuiSvgIcon-root": {
          fontSize: "2.4rem",
        },
      }),
    },

    "&.Mui-disabled": {
      backgroundColor: "var(--disabled-color)",
    },

    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-green)",
    },
    "&.Mui-error fieldset": {
      borderColor: "var(--destructive-color)",
    },
  },
}));

type GFTextFieldProps = TextFieldProps & {
  required?: boolean;
};

export default function GFTextField({ label, error, required, ...props }: GFTextFieldProps) {
  return (
    <div style={{ width: props.fullWidth ? "100%" : "auto" }}>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <StyledTextField {...props} error={error ? true : false} helperText={error ? error : ""} />
    </div>
  );
}
