import OrderAccordionDetailsItem from "@Containers/Order/components/OrderAccordionDetails/OrderAccordionDetailsItem";
import {
  OrderItemDetailTitle,
  OrderItemDetailWrapper,
} from "@Containers/Order/components/OrderAccordionDetails/styles";
import { OrderOutputModel } from "@Models";
import { Box } from "@mui/material";

type OrderAccordionDetailsProps = {
  order: OrderOutputModel;
};

export default function OrderAccordionDetails({ order }: OrderAccordionDetailsProps) {
  return (
    <Box>
      <OrderItemDetailWrapper>
        <OrderItemDetailTitle>Sản phẩm</OrderItemDetailTitle>
        <OrderItemDetailTitle>Địa chỉ</OrderItemDetailTitle>
        <OrderItemDetailTitle>Ngày giao hàng</OrderItemDetailTitle>
        <OrderItemDetailTitle>Số lượng</OrderItemDetailTitle>
        <OrderItemDetailTitle>Trạng thái</OrderItemDetailTitle>
      </OrderItemDetailWrapper>

      {order.orders.length > 0 &&
        order.orders.map((order, index) => <OrderAccordionDetailsItem key={index} {...order} />)}
    </Box>
  );
}
