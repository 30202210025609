import { Divider } from "@mui/material";
import { checkLinkImage, renderErrorImageItem, renderImageProNull } from "@Utils";
import "./index.scss";
import { InterestedIcon1 } from "../Icons/InterestedIcon1";
import { InterestedIcon2 } from "../Icons/InterestedIcon2";
import { InterestedIcon3 } from "../Icons/InterestedIcon3";
import { InterestedIcon4 } from "../Icons/InterestedIcon4";
import { useTranslation } from "react-i18next";

export type P_Props = {
  class: any;
  key: any;
  onClick: () => void;
  image: any;
  shortName: any;
  name: any;
  value: any;
  baseUoM: any;
  source: string;
  additionalValue?: string;
  priceType?: string;
  farmBaseUoM?: string;
  farmSaleUnit?: string;
  interestedItem: any;
  disableImg?: boolean;
  color?: string;
};

const ProductItem: React.FC<P_Props> = (props) => {
  const { t } = useTranslation(["order"]);

  return (
    <div className="product-item" key={props.key}>
      {!props.disableImg && (
        <div className="icon-interested">
          {(() => {
            if (props.interestedItem) {
              if (props.interestedItem.stared === false && props.interestedItem.bought > 0) {
                return <InterestedIcon1 color="#41AD49" size={[24, 24]} viewBox={[36, 36]} />;
              } else if (props.interestedItem.stared === true && props.interestedItem.bought > 0) {
                return <InterestedIcon2 color="#41AD49" size={[24, 24]} viewBox={[36, 36]} />;
              } else if (
                props.interestedItem.stared === false &&
                props.interestedItem.bought === 0
              ) {
                return (
                  <div className="icon-star">
                    <InterestedIcon4 color="#41AD49" size={[13, 13]} viewBox={[15, 14]} />
                  </div>
                );
              } else if (
                props.interestedItem.stared === true &&
                props.interestedItem.bought === 0
              ) {
                return (
                  <div className="icon-star">
                    <InterestedIcon3 color="#41AD49" size={[13, 13]} viewBox={[15, 14]} />
                  </div>
                );
              }
            }
          })()}
        </div>
      )}
      <div
        className={props.class ? "item active" : "item"}
        style={{
          borderLeft: `3px solid ${props.color ? props.color : "transparent"}`,
        }}
        onClick={() => {
          props.onClick();
        }}>
        {!props.disableImg && (
          <img
            src={checkLinkImage(props.image) || renderImageProNull(props.source)}
            alt={props.shortName}
            onError={renderErrorImageItem(props.source)}
          />
        )}
        <div>
          <div className="name" style={{ color: props?.color }}>
            {props.shortName}
          </div>
          <div className="text">{props.name}</div>
          <div className="price">
            {props.value} đ/<span>{props.baseUoM}</span>
          </div>
          {props.source === "farm" && (
            <div className="additionalValue">
              {t("order:STOCK_FEE_EXCESS_PORTION")} + <span>{props.additionalValue}</span> đ/
              {(() => {
                if (props.priceType === "increment") {
                  return (
                    <span
                      style={{
                        color: "#41AD49",
                        textTransform: "lowercase",
                      }}>
                      {props.farmBaseUoM}
                    </span>
                  );
                }
                return (
                  <span
                    style={{
                      color: "#41AD49",
                      textTransform: "lowercase",
                    }}>
                    {props.farmSaleUnit}
                  </span>
                );
              })()}
            </div>
          )}
        </div>
      </div>
      <Divider style={{ marginRight: "3rem", marginLeft: "3rem" }} />
    </div>
  );
};

export default ProductItem;
