import { CONTRACT_PARTY, CONTRACT_TERM, CONTRACT_TYPE } from "@Constants";
import { SelectOption } from "src/types";

  export const contractPartyOptions: SelectOption[] = [
    {
      label: "contract.twoParty",
      value: CONTRACT_PARTY.TWO_PARTY,
    },
    {
      label: "contract.threeParty",
      value: CONTRACT_PARTY.THREE_PARTY,
    },
  ];
  
  export const contractTermOptions: SelectOption[] = [
    {
      label: "contract.fixedTerm",
      value: CONTRACT_TERM.FIXED_TERM,
    },
    {
      label: "contract.unlimitedTerm",
      value: CONTRACT_TERM.UNLIMITED_TERM,
    },
  ];
  
  export const contractTypeOptions: SelectOption[] = [
    {
      label: "contract.principle",
      value: CONTRACT_TYPE.PRINCIPLE,
    },
  ];

  export const getContractTypeLabel = (value: string) => {
    const option = contractTypeOptions.find((item) => item.value === value);
    return option ? option.label : "";
  }

  export const getContractTermLabel = (value: string) => {
    const option = contractTermOptions.find((item) => item.value === value);
    return option ? option.label : "";
  }

  export const getContractPartyLabel = (value: string) => {
    const option = contractPartyOptions.find((item) => item.value === value);
    return option ? option.label : "";
  }
  
  
