/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Img from "@Assets/images/empty-debt.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import { amtRange, APP_ROUTES, dept_headers, sumAmt, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { Action, DebtOutputModel, FetchAction, ItemPermission } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { CustomerService } from "@Services";
import { currencyFormat, PermissionUtil } from "@Utils";
import { Search as SearchIcon } from "@material-ui/icons";
import { InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import "./CustomerDebtDetail.scss";

type P_Props = {
  source: any;
};

function reducer(state: PaginateFetchReducer<DebtOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };
    default:
      return { ...state };
  }
}

const CustomerDebtDetail: React.FC<P_Props> = ({ source }) => {
  const { t } = useTranslation(["translation", "customer"]);
  const history = useHistory();
  const mounted = useRef(false);
  const { customerId }: { customerId: string } = useParams();
  const [searchValue, setSearchValue] = useState<string>("");
  // const [range, setRange] = useDateRange();
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await CustomerService.getDebtCustomerById(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err: any) {
      console.log(err);
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 500,
        // from: range.startTime.toISOString(),
        // to: range.endTime.toISOString(),
        searchValue: searchValue,
        customerId: customerId,
        source: source,
      },
      true
    );
  }, [
    searchValue,
    // , range.startTime.toISOString(), range.endTime.toISOString()
  ]);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  return (
    <div className="customer-debt-detail">
      <div className="header">
        <TextField
          placeholder={t("ENTER_SEARCH")}
          variant="outlined"
          className="search-style"
          inputProps={{ className: "input" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className="icon" />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            onSearch(e.target.value);
          }}
        />
        {/* <RangePicker
              startDate={range.startTime}
              endDate={range.endTime}
              onChangeDate={(val) => {
                if (val && val.length) {
                  setRange(
                    moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
                    moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 })
                  );
                }
              }}
            /> */}
      </div>

      {state.data.length > 0 ? (
        <div className="list">
          <div className="list-group">
            <div className="list-header">
              {dept_headers.map((item: any, idx: number) => {
                return (
                  <div className="label" key={idx}>
                    {item.label}
                  </div>
                );
              })}
            </div>
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              initialLoad={false}
              hasMore={hasMore}
              loadMore={() =>
                fetchData({
                  limit: 10,
                  page: state.page + 1,
                  searchValue,
                  customerId: customerId,
                  source: source,
                })
              }>
              {state.fetching && searchValue && <Loading />}

              {state.data.map((debt: any) => {
                return (
                  <div className="item" key={debt.id}>
                    <div className="label">{debt.refDocNo}</div>
                    <div className="label">{moment(debt.pstngDate).format("DD/MM/YYYY")}</div>
                    <div className="label">
                      {checkPermissionViewPrice ? currencyFormat(debt.amtDoccur) : TEXT_ASTERISK} đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice ? currencyFormat(debt.amtNotDue) : TEXT_ASTERISK} đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice ? currencyFormat(debt.amtDue) : TEXT_ASTERISK} đ
                    </div>
                    <div className="label">
                      {checkPermissionViewPrice ? currencyFormat(debt.amtCurr) : TEXT_ASTERISK} đ
                    </div>
                    {amtRange.map((amt, amtIndex: number) => {
                      return (
                        <div className="label" key={amtIndex}>
                          {checkPermissionViewPrice
                            ? currencyFormat(debt[amt.label])
                            : TEXT_ASTERISK}{" "}
                          đ
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </InfiniteScroll>
            <div className="item item-total">
              <div className="label">{t("customer:TOTAL_INVOICE")}</div>
              <div className="label">{TEXT_HOLDER}</div>
              <div className="label">
                {checkPermissionViewPrice ? currencyFormat(state.sum.sumAmtDoccur) : TEXT_ASTERISK}{" "}
                đ
              </div>
              <div className="label">
                {checkPermissionViewPrice ? currencyFormat(state.sum.sumAmtNotDue) : TEXT_ASTERISK}{" "}
                đ
              </div>
              <div className="label">
                {checkPermissionViewPrice ? currencyFormat(state.sum.sumAmtDue) : TEXT_ASTERISK} đ
              </div>
              <div className="label">
                {checkPermissionViewPrice ? currencyFormat(state.sum.sumAmtCurr) : TEXT_ASTERISK} đ
              </div>
              {sumAmt.map((amt, amtIndex: number) => {
                return (
                  <div className="label" key={amtIndex}>
                    {checkPermissionViewPrice
                      ? currencyFormat(state.sum[amt.label])
                      : TEXT_ASTERISK}{" "}
                    đ
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <EmptyList img={Img} title={t("EMPTY_LIST")} />
      )}
    </div>
  );
};

export default CustomerDebtDetail;
