/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
import Loading from "@Components/Loading";
import { APP_ROUTES, AccessHistory, TEXT_HOLDER, UpdateHistory } from "@Constants";
import { CustomerOutputModel, SourceType } from "@Models";
import { Nullable } from "@Models/shared";
import { CustomerService } from "@Services";
import { checkLinkImage } from "@Utils";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Divider,
  Grid,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import "./CustomerProfile.scss";

const CustomerProfile: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const [visibleIDCard, setVisibleIDCard] = useState<boolean>(false);
  const [visibleSignature, setVisibleSignature] = useState<boolean>(false);
  const [visibleHistory, setVisibleHistory] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Nullable<CustomerOutputModel>>(null);
  const mounted = useRef(false);
  const { customerId }: { customerId: string } = useParams();
  const history = useHistory();

  const getCustomerById: () => Promise<void> = async () => {
    try {
      const response = await CustomerService.getCustomerById(customerId);
      if (mounted.current) {
        setCustomer(() => response.data);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCustomerById();
  }, []);

  return (
    <div className="customer-profile">
      {!!customer ? (
        <>
          <div className="body">
            <Grid container>
              <Grid item xs={9} className="left">
                <div className="box">
                  <div className="title">{t("GENERAL_INFORMATION")}</div>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <div className="label"> {t("customer:CUSTOMER_CODE")}</div>
                      {customer.code}
                      <br /> <br />
                      <div className="label"> {t("customer:ADDRESS")}</div>
                      {customer.address}
                    </Grid>
                    <Grid item xs={3}>
                      <div className="label">{t("customer:CONTACT_PERSON")} </div>
                      {TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> {t("TIN")}</div>
                      {TEXT_HOLDER}
                      <br /> <br />
                    </Grid>
                    <Grid item xs={5}>
                      <div className="label"> {t("EMAIL")}</div>
                      {customer.email || TEXT_HOLDER}
                      <br /> <br />
                      <div className="label"> {t("COMPANY")}</div>
                      {customer.company || TEXT_HOLDER}
                    </Grid>
                  </Grid>
                </div>
                {customer.customerDivisions?.map((dis: any) => {
                  return (
                    <>
                      {dis?.salesOffices?.length > 0 &&
                        dis?.salesOffices?.map((office: any, officeIndex: number) => {
                          return (
                            <div className="box" style={{ marginTop: "1rem" }} key={officeIndex}>
                              <div className="title">{t("customer:BUSINESS_REGION")}</div>
                              <Grid container>
                                <Grid item xs={4}>
                                  <div className="label"> {t("customer:SALE_DIRECTOR")}</div>
                                  {dis.employees.find((p: any) => p.position === "Sales Man.")
                                    ?.employee?.fullName || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:SALE_REPRESENTATIVE")}</div>
                                  {dis.employees.find((p: any) => p.position === "Sales Ref.")
                                    ?.employee?.fullName || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:SALE_ORGANIZATION")}</div>
                                  {dis.salesOrg?.description}
                                </Grid>
                                <Grid item xs={4}>
                                  <div className="label"> {t("customer:DEPARTMENT_HEAD")}</div>
                                  {dis.employees.find((p: any) => p.position === "Assist Sales Man")
                                    ?.employee?.fullName || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:SALE_CHANEL")}</div>
                                  {dis.distributionChannel?.description || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:CUSTOMER_GROUP")}</div>
                                  {dis.customerGroup?.description || TEXT_HOLDER}
                                </Grid>
                                <Grid item xs={4}>
                                  <div className="label"> {t("customer:SALE_SUPERVISOR")}</div>
                                  {dis.employees.find((p: any) => p.position === "Sales Sup.")
                                    ?.employee?.fullName || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:INDUSTRY")}</div>
                                  {dis.division?.description || TEXT_HOLDER}
                                  <br /> <br />
                                  <div className="label"> {t("customer:SALE_LOCATION")}</div>
                                  {office?.description || TEXT_HOLDER}
                                </Grid>
                              </Grid>
                            </div>
                          );
                        })}
                      {dis?.salesOffices?.length === 0 && (
                        <div className="box" style={{ marginTop: "1rem" }}>
                          <div className="title">{t("customer:BUSINESS_REGION")}</div>
                          <Grid container>
                            <Grid item xs={4}>
                              <div className="label"> {t("customer:SALE_DIRECTOR")}</div>
                              {dis.employees.find((p: any) => p.position === "Sales Man.")?.employee
                                ?.fullName || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label"> {t("customer:SALE_REPRESENTATIVE")}</div>
                              {dis.employees.find((p: any) => p.position === "Sales Ref.")?.employee
                                ?.fullName || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label"> {t("customer:SALE_ORGANIZATION")}</div>
                              {dis.salesOrg?.description}
                            </Grid>
                            <Grid item xs={4}>
                              <div className="label"> {t("customer:DEPARTMENT_HEAD")}</div>
                              {dis.employees.find((p: any) => p.position === "Assist Sales Man")
                                ?.employee?.fullName || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label">{t("customer:SALE_CHANEL")}</div>
                              {dis.distributionChannel?.description || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label">{t("customer:CUSTOMER_GROUP")}</div>
                              {dis.customerGroup?.description || TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={4}>
                              <div className="label"> {t("customer:SALE_SUPERVISOR")}</div>
                              {dis.employees.find((p: any) => p.position === "Sales Sup.")?.employee
                                ?.fullName || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label"> {t("customer:INDUSTRY")}</div>
                              {dis.division?.description || TEXT_HOLDER}
                              <br /> <br />
                              <div className="label">{t("customer:SALE_LOCATION")}</div>
                              {TEXT_HOLDER}
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </>
                  );
                })}

                <Accordion disabled className="update-history">
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon style={{ color: "#272B2F" }} />}
                    className="title">
                    {t("UPDATE_HISTORY")}
                  </AccordionSummary>
                  <Divider style={{ marginBottom: "1.6rem" }} />
                  <AccordionDetails className="label">
                    <Grid container>
                      <Grid item xs={4}>
                        {t("TIME")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("customer:EXECUTOR")}
                      </Grid>
                      <Grid item xs={4}>
                        {t("UPDATE")}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  {UpdateHistory.map((u) => {
                    return (
                      <AccordionDetails key={u.id} className="item">
                        <Grid container>
                          <Grid item xs={4}>
                            {u.time}
                          </Grid>
                          <Grid item xs={4}>
                            {u.name}
                          </Grid>
                          <Grid item xs={4}>
                            {u.update}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    );
                  })}
                </Accordion>
              </Grid>
              <Grid item xs={3} className="right" style={{ paddingLeft: "1.2rem" }}>
                <div className="box">
                  <div>
                    <div className="title">{t("customer:PERSONAL_INFORMATION")}</div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>
                        <div className="label">CMND/CCCD</div>
                        {customer.idCard || TEXT_HOLDER}
                      </div>
                      <div>
                        <div className="label">{t("customer:ISSUE_DATE")}</div>
                        {customer.idIssueDate
                          ? moment(customer.idIssueDate).format("DD/MM/YYYY")
                          : TEXT_HOLDER}
                      </div>
                    </div>
                    <br />
                    <div className="label">{t("customer:PLACE_ISSUE")}</div>
                    {customer.idIssuePlace || TEXT_HOLDER}
                    <div
                      className="text-color"
                      onClick={() => {
                        setVisibleIDCard(true);
                      }}>
                      {t("customer:ID_IMAGE")} <LaunchIcon style={{ marginLeft: "0.8rem" }} />
                    </div>
                    <div
                      className="text-color"
                      onClick={() => {
                        setVisibleSignature(true);
                      }}>
                      {t("customer:SIGNATURE_SAMPLE")}
                      <LaunchIcon style={{ marginLeft: "0.8rem" }} />
                    </div>
                  </div>
                </div>
                {customer.legals?.length > 0 && (
                  <div className="box">
                    <div className="title">{t("DATABASE")}</div>
                    {customer.legals.map((l, index: number) => {
                      return (
                        <div key={index} style={{ marginBottom: "0.4rem" }}>
                          {l.description}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="box">
                  <div className="title">{t("customer:SEGMENT")}</div>
                  <div className="label">{t("customer:PRODUCT_INDUSTRY")}</div>
                  {(() => {
                    switch (customer.source) {
                      case SourceType.FEED:
                        return t("FEED");
                      case SourceType.FARM:
                        return t("FARM");
                      case SourceType.VET:
                        return t("VET");
                      case SourceType.LAB:
                        return t("LAB");
                      default:
                        return t("CCU");
                    }
                  })()}
                  <br />
                  <br />
                  <div className="label">{t("customer:INTERESTED_PRODUCT_GROUP")}</div>
                  <div className="chip-list">
                    {TEXT_HOLDER}
                    {/* <div className="chip"></div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <Dialog fullWidth maxWidth="md" open={visibleIDCard}>
            <div className="card-group">
              <div className="title">
                {t("customer:ID_IMAGE")}
                <ClearIcon
                  style={{ fontSize: "3rem", cursor: "pointer" }}
                  onClick={() => {
                    setVisibleIDCard(false);
                  }}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <div className="card">
                    {customer.account?.idCardFront ? (
                      <img src={checkLinkImage(customer.account?.idCardFront) || ""} alt="card1" />
                    ) : (
                      <>
                        {/* <img src={Icon} alt="card1" className="icon" /> */}
                        {/* <div className="text">
                          Kéo thả hoặc lựa chọn ảnh
                          <br />
                          Kích thước tối đa dưới 2MB
                        </div> */}
                        <div className="text"> {t("customer:NO_IMAGE")}</div>
                      </>
                    )}
                  </div>
                  <div>{t("customer:FRONT_SIDE")}</div>
                </div>
                <div>
                  <div className="card">
                    {customer.account?.idCardFront ? (
                      <img src={checkLinkImage(customer.account?.idCardFront) || ""} alt="card2" />
                    ) : (
                      <>
                        {/* <img src={Icon} alt="card2" className="icon" />
                        <div className="text">
                          Kéo thả hoặc lựa chọn ảnh
                          <br />
                          Kích thước tối đa dưới 2MB
                        </div> */}
                        <div className="text">{t("customer:NO_IMAGE")}</div>
                      </>
                    )}
                  </div>
                  <div>{t("customer:BACK_SIDE")}</div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog fullWidth maxWidth="md" open={visibleSignature}>
            <div className="signature">
              <div className="title">
                {t("customer:SIGNATURE_SAMPLE")}
                <ClearIcon
                  style={{ fontSize: "3rem", cursor: "pointer" }}
                  onClick={() => {
                    setVisibleSignature(false);
                  }}
                />
              </div>
              <div className="card">
                {customer.account?.signature ? (
                  <img src={checkLinkImage(customer.account?.signature) || ""} alt="card2" />
                ) : (
                  <>
                    {/* <img src={Icon} alt="card2" className="icon" />
                    <div className="text">
                      Kéo thả hoặc lựa chọn ảnh
                      <br />
                      Kích thước tối đa dưới 2MB
                    </div> */}
                    <div className="text">{t("customer:NO_IMAGE")}</div>
                  </>
                )}
              </div>
            </div>
          </Dialog>
          <Dialog fullWidth maxWidth="md" open={visibleHistory}>
            <div className="history">
              <div className="title">
                {t("customer:ACCESS_HISTORY")}
                <ClearIcon
                  style={{ fontSize: "3rem", cursor: "pointer" }}
                  onClick={() => {
                    setVisibleHistory(false);
                  }}
                />
              </div>
              <Divider />
              <Grid container className="header">
                <Grid item xs={4}>
                  {t("TIME")}
                </Grid>
                <Grid item xs={3}>
                  IP
                </Grid>
                <Grid item xs={3}>
                  {t("STATUS")}
                </Grid>
                <Grid item xs={2}>
                  {t("customer:DEVICE")}
                </Grid>
              </Grid>
              <div className="list">
                {AccessHistory.map((h) => {
                  return (
                    <Grid container className="item" key={h.id}>
                      <Grid item xs={4}>
                        {h.time}
                      </Grid>
                      <Grid item xs={3}>
                        {h.ip}
                      </Grid>
                      <Grid item xs={3}>
                        {h.status}
                      </Grid>
                      <Grid item xs={2}>
                        {h.unit}
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Dialog>
        </>
      ) : (
        <Loading style={{ margin: "2rem" }} />
      )}
    </div>
  );
};

export default CustomerProfile;
