/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import Decrease from "@Assets/images/icons/ic_decrease.png";
import Increase from "@Assets/images/icons/ic_increase.png";
import { TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import { useAppDispatch, useAppSelector } from "@Core/store/hook";
import { LoadMe, loadProfile } from "@Core/store/profile";
import { ItemPermission } from "@Models";
import { Grid } from "@mui/material";
import { OrderService } from "@Services";
import { currencyFormat, PermissionUtil, StatusUIUtil } from "@Utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Tree from "react-d3-tree";
import { useTranslation } from "react-i18next";
import SimpleModalHeader from "../SimpleModalHeader";
import "./index.scss";

type P_Props = { closeForm: () => void; orderId: any };

const CompareOrderFarm: React.FC<P_Props> = ({ closeForm, orderId }) => {
  const { t } = useTranslation(["translation", "order"]);
  const mounted = useRef(false);
  const [compare, setCompare] = useState<any>({});
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [compare]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await OrderService.getOrderCompareById(+orderId);
      if (mounted.current) {
        setCompare(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const child =
    compare &&
    compare.orders?.map((m: any) => {
      return {
        name: m.code,
        value: {
          totalQuantity: +m.totalQuantity,
          orderAmount: +m.orderAmount,
          compareQuantity: m?.compareQuantity,
          comparePrice: m?.comparePrice,
          childs: m.childOrders.reduce((sum: number, item: any) => {
            sum += +item.totalQuantity;
            return sum;
          }, 0),
          delivery:
            m.childOrders
              .map((c: any, index: number) => {
                return c.deliveryOrders.reduce((sum: number, item: any) => {
                  sum += +item.totalQuantity;
                  return sum;
                }, 0);
              })
              .reduce((sum: any, val: any) => sum + val, 0) || 0,
        },
        status: m.status,
        childrens: {},
        children: m.childOrders.map((c: any, index: number) => {
          return {
            name: c.orderGFId,
            value: {
              totalQuantity: +c.totalQuantity,
              orderAmount: +c.orderAmount,
              compareQuantity: c?.compareQuantity,
              comparePrice: c?.comparePrice,
              delivery:
                c?.deliveryOrders?.reduce((sum: number, item: any) => {
                  sum += +item.totalQuantity;
                  return sum;
                }, 0) || 0,
            },
            status: c.status,
            site: {},
            children: c.deliveryOrders.map((d: any) => {
              return {
                name: d.code,
                status: d.status,
                value: {
                  totalQuantity: +d.totalQuantity,
                  orderAmount: +d.totalPay,
                },
                DO: {},
              };
            }),
          };
        }),
      };
    });

  const data = { name: compare.code, children: child, status: compare.status };

  const useCenteredTree = () => {
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const containerRef = useCallback((containerElem) => {
      if (containerElem !== null) {
        const { width, height } = containerElem.getBoundingClientRect();
        setTranslate({ x: width / 2, y: height / 2 });
      }
    }, []);
    return [translate, containerRef];
  };

  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 580, y: 260 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -290,
    y: -90,
  };

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }: {
    nodeDatum: any;
    toggleNode: any;
    foreignObjectProps: any;
  }) => (
    <foreignObject {...foreignObjectProps}>
      <div className="box">
        {StatusUIUtil.renderBgStatus(nodeDatum.status, "farm")}
        <div className="name">
          {(() => {
            if (nodeDatum?.site) {
              if (nodeDatum?.name === null) {
                return t("order:DISPATCH") + TEXT_HOLDER;
              }
              return t("order:DISPATCH") + " #" + nodeDatum?.name;
            } else if (nodeDatum?.DO) {
              return "DO #" + nodeDatum?.name;
            } else if (nodeDatum.childrens) {
              return t("order:PLACE_ORDER") + " #" + nodeDatum?.name;
            } else {
              return "#" + nodeDatum?.name;
            }
          })()}
        </div>
        {+nodeDatum.value?.orderAmount >= 0 && (
          <>
            <div
              className="item"
              style={{
                width:
                  nodeDatum.value?.childs >= 0
                    ? "56rem"
                    : nodeDatum.value?.delivery >= 0
                    ? "41rem"
                    : "35rem",
              }}>
              <Grid container>
                {nodeDatum.value?.childs >= 0 && (
                  <Grid item xs={2} display="flex" alignItems="flex-end" flexDirection="column">
                    <div className="label">{t("order:PLACE_ORDER")}</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {nodeDatum.value?.compareQuantity === "increase" ? (
                        <img src={Increase} alt="Increase" />
                      ) : nodeDatum.value?.compareQuantity === "decrease" ? (
                        <img src={Decrease} alt="Decrease" />
                      ) : (
                        <></>
                      )}
                      <div className="quan">{nodeDatum.value?.totalQuantity}</div>
                    </div>
                  </Grid>
                )}
                {nodeDatum.value?.delivery >= 0 && (
                  <Grid
                    item
                    xs={nodeDatum.value?.childs >= 0 ? 2 : 3}
                    display="flex"
                    alignItems="flex-end"
                    flexDirection="column">
                    <div className="label">{t("order:DISPATCH")}</div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {nodeDatum.value?.compareQuantity === "increase" ? (
                        <img src={Increase} alt="Increase" />
                      ) : nodeDatum.value?.compareQuantity === "decrease" ? (
                        <img src={Decrease} alt="Decrease" />
                      ) : (
                        <></>
                      )}
                      <div className="quan">
                        {+nodeDatum.value?.childs >= 0
                          ? nodeDatum.value?.childs
                          : nodeDatum.value?.totalQuantity}
                      </div>
                    </div>
                  </Grid>
                )}
                <Grid
                  item
                  xs={nodeDatum.value?.childs >= 0 ? 2 : 3}
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column">
                  <div className="label">Đã giao</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="quan">
                      {nodeDatum?.DO ? nodeDatum.value?.totalQuantity : nodeDatum.value?.delivery}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={nodeDatum.value?.delivery >= 0 ? 6 : 9}
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column">
                  <div className="label">{t("TOTAL_PRICE")}</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {nodeDatum.value?.comparePrice === "increase" ? (
                      <img src={Increase} alt="Increase" />
                    ) : nodeDatum.value?.comparePrice === "decrease" ? (
                      <img src={Decrease} alt="Decrease" />
                    ) : (
                      <></>
                    )}
                    <div className="price">
                      {checkPermissionViewPrice
                        ? currencyFormat(nodeDatum.value?.orderAmount)
                        : TEXT_ASTERISK}{" "}
                      đ
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            {nodeDatum.children?.length > 0 && (
              <button className="btn-icon" onClick={toggleNode}>
                {nodeDatum.__rd3t.collapsed ? "+" : <span>&#8722;</span>}
              </button>
            )}
          </>
        )}
      </div>
    </foreignObject>
  );

  return (
    <div className="do-ord-detail-farm">
      <SimpleModalHeader
        title={t("order:ORDER_DETAIL") + " #" + compare.code}
        onClose={closeForm}
      />
      <div ref={containerRef as any} style={{ width: "100%", height: "100vh" }}>
        <Tree
          data={data}
          translate={translate as any}
          nodeSize={nodeSize}
          orientation="vertical"
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
          pathFunc="step"
        />
      </div>
    </div>
  );
};

export default CompareOrderFarm;
