import {
    GFAutoComplete,
    GFButton,
    GFDatePicker,
    GFFormItem,
    GFTextField,
    GFTypography,
  } from "@Components/ui";
import {
  APP_ROUTES,
  CONTRACT_PARTY,
} from "@Constants";

import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, Dialog, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import _UniqBy from "lodash/uniqBy";
import _Filter from "lodash/filter";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import "./index.scss";
import { useSnackbar } from "notistack";
import { getErrorResponse, toClientDate } from "@Utils";
import { useHistory, useParams } from "react-router-dom";
import GFSelectVirtualized from "@Components/ui/GFSelectVirtualized";
import Loading from "@Components/Loading";
import {
  ContractPayload,
  contractSchema,
} from "src/schemas/contract-manage.schema";
import { CreateOrUpdateContractModel } from "@Models/contract.model";
import { ContractService } from "@Services/contract.service";
import {
  contractPartyOptions,
  contractTermOptions,
  contractTypeOptions,
} from "@Containers/ContractManage/contract.config";
import { ItemSection } from "@Components/Common";
import _ from "lodash";


type Props = {
  isUpdate: boolean;
};
  
const CreateOrUpdateContract: React.FC<Props>  = ({ isUpdate }) => {
  const contractId = useParams<{ contractId: string }>().contractId;
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isValid, isDirty, errors },
  } = useForm<ContractPayload>({
    resolver: zodResolver(contractSchema),
    mode: "onChange",
  });  

  console.log('isValid', isValid)
  console.log('isDirty', isDirty)
  console.log('errors', errors)

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("translation");
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingPage, setLoadingPage] = useState<boolean>(false)
  const [masterData, setMasterData] = useState<any>({})
  const [isDraft, setIsDraft] = useState<boolean>(false)
  const [selectParty, setSelectParty] = useState<string>(CONTRACT_PARTY.TWO_PARTY)
  
  

  useEffect( () => {
    const fetchData = async () => {
      try {
        const response = await ContractService.getMasterData();
        if (response){
          const legals = response.data.legals.map((item: any) => {
            return {
              value: item.id,
              label: `${item.code} - ${item.description}`
            }
          })

          const customers = response.data.customers.map((item: any) => {
            return {
              value: item.id,
              label: `${item.code} - ${item.fullName}`
            }
          })
          setMasterData({
            customersOptions: customers,
            legalsOptions: legals,
            customers: response.data.customers,
            legals: response.data.legals,
          })
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])

  useEffect(() => {
    if (contractId) {
      getContract(Number(contractId))
    }
  },[contractId])

  const getContract = async (contractId: number) => {
    try {
      setLoadingPage(true)
      const response = await ContractService.getById(contractId)
      if (response) {
        setValue('party', response.data.party)
        setValue('term', response.data.term)
        setValue('type', response.data.type)
        setValue('signingDate', response.data.signingDate)
        setValue('dateStart', response.data.dateStart)
        setValue('dateEnd', response.data.dateEnd)

        setValue('firstSellerId', response.data.firstSeller.id)
        setValue('tax', response.data?.firstSeller?.idTax ?? '')
        setValue('phoneNumber', response.data?.firstSeller?.phone ?? '')
        setValue('bankNum', response.data?.firstSeller?.bankNumber ?? '')
        setValue('bankName', response.data?.firstSeller?.bankName ?? '')
        setValue('legalRepresentative', response.data?.firstSeller?.legalRepresentative ?? '')
        setValue('position', response.data?.firstSeller?.jobTitle ?? '')
        setValue('address', response.data?.firstSeller?.address ?? '')

        setValue('secondSellerId', response.data.secondSeller?.id)
        setValue('tax2', response.data?.secondSeller?.idTax ?? '')
        setValue('phoneNumber2', response.data?.secondSeller?.phone ?? '')
        setValue('bankNum2', response.data?.secondSeller?.bankNumber ?? '')
        setValue('bankName2', response.data?.secondSeller?.bankName ?? '')
        setValue('legalRepresentative2', response.data?.secondSeller?.legalRepresentative ?? '')
        setValue('position2', response.data?.secondSeller?.jobTitle ?? '')
        setValue('address2', response.data?.secondSeller?.address ?? '')

        setValue('buyerId', response.data.buyer.id)
        setValue('tax3', _.get(response.data, 'buyer.lead.taxId') ?? '')
        setValue('phoneNumber3', _.get(response.data, 'buyer.lead.phoneNumber')?? '')
        setValue('idCard3', _.get(response.data, 'buyer.lead.phoneNumber')?? '')
        setValue('idCardDate3', _.get(response.data, 'buyer.lead.idIssueDate') ? toClientDate(_.get(response.data, 'buyer.lead.idIssueDate')) : '')
        setValue('bankNum3', _.get(response.data, 'buyer.lead.bankAccNum') ?? '')
        setValue('bankName3', _.get(response.data, 'buyer.lead.bankKey') ?? '')
        setValue('bankAccount3', _.get(response.data, 'buyer.lead.bankAccName') ?? '')
        setValue('position3', _.get(response.data, 'buyer.lead.position') ?? '')
        setValue('address3', _.get(response.data, 'buyer.lead.address') ?? '')
        
      }

      setLoadingPage(false)
    }
    catch (error) {
      console.error('Error fetching data:', error);
      setLoadingPage(false)
    }
  }

  const onSubmit = async (data: ContractPayload) => {
    await handleSave(data)
  };


  const handleSave = async (data: ContractPayload) => {
    try {
      setLoading(true)
      const payload: CreateOrUpdateContractModel = {
          dateStart: data.dateStart,
          dateEnd: data.dateEnd,
          term: data.term,
          type: data.type,
          party: data.party,
          firstSellerId: data.firstSellerId,
          secondSellerId: data.secondSellerId,
          buyerId: data.buyerId,
          signingDate: data.signingDate
      }
      
      if (isUpdate){
        await ContractService.update(Number(contractId), payload)
      }
      else {
        await ContractService.create(payload);
      }
      
      enqueueSnackbar(t("actionCompletedSuccessfully"), {
        variant: "success",
      });
      setLoading(false)
      history.push(APP_ROUTES.CONTRACT_MANAGE)
    } catch (error) {
      setLoading(false)
      enqueueSnackbar(
        `${t("actionUnsuccessful")}: ${getErrorResponse(error)}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleClose = () => {
    history.push(APP_ROUTES.CONTRACT_MANAGE);
  };

  const handleFirstSeller = async (value: number | null) => {
    const firstSeller = masterData.legals?.find((p: any) => p.id === value);
    setValue('tax', firstSeller?.idTax ?? '')
    setValue('phoneNumber', firstSeller?.phone ?? '')
    setValue('bankNum', firstSeller?.bankNumber ?? '')
    setValue('bankName', firstSeller?.bankName ?? '')
    setValue('legalRepresentative', firstSeller?.legalRepresentative ?? '')
    setValue('position', firstSeller?.jobTitle ?? '')
    setValue('address', firstSeller?.address ?? '')
  };
    
  const handleSecondSeller = async (value: number | null) => {
    const secondSeller = masterData.legals?.find((p: any) => p.id === value);
    setValue('tax2', secondSeller?.idTax ?? '')
    setValue('phoneNumber2', secondSeller?.phone ?? '')
    setValue('bankNum2', secondSeller?.bankNumber ?? '')
    setValue('bankName2', secondSeller?.bankName ?? '')
    setValue('legalRepresentative2', secondSeller?.legalRepresentative ?? '')
    setValue('position2', secondSeller?.jobTitle ?? '')
    setValue('address2', secondSeller?.address ?? '')
  };
  
  const handleChangeBuyer = async (value: number | null) => {
    const buyer = masterData.customers?.find((p: any) => p.id === value);
    setValue('tax3', _.get(buyer, 'lead.taxId') ?? '')
    setValue('phoneNumber3', _.get(buyer, 'lead.phoneNumber')?? '')
    setValue('idCard3', _.get(buyer, 'lead.phoneNumber')?? '')
    setValue('idCardDate3', _.get(buyer, 'lead.idIssueDate') ? toClientDate(_.get(buyer, 'lead.idIssueDate')) : '')
    setValue('bankNum3', _.get(buyer, 'lead.bankAccNum') ?? '')
    setValue('bankName3', _.get(buyer, 'lead.bankKey') ?? '')
    setValue('bankAccount3', _.get(buyer, 'lead.bankAccName') ?? '')
    setValue('position3', _.get(buyer, 'lead.position') ?? '')
    setValue('address3', _.get(buyer, 'lead.address') ?? '')
  };
    
  const handleChangeParty = (value: any) => {
      setSelectParty(value ?? CONTRACT_PARTY.TWO_PARTY)
      handleSecondSeller(null)
      setValue('secondSellerId', null)
  }

  return (
    <Dialog fullScreen open={true}>
      <div className="contract-dialog-content">
        <SimpleModalHeader
          title={
            isUpdate ? t("contract.updateContract") : t("contract.createContract")
          }
          onClose={handleClose}
        />
        <div className="contract-dialog-content__body">
          <form id="create-or-update-contract" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ItemSection className="contract-dialog-content__body__general">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <GFFormItem control={control} name="type">
                        <GFAutoComplete
                          translation
                          label={t("contract.type")}
                          options={contractTypeOptions}
                          required
                          placeholder={`${t("inputPlaceholder")} ${t("contract.type")}`}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="term">
                        <GFAutoComplete
                          translation
                          label={t("contract.term")}
                          options={contractTermOptions}
                          required
                          placeholder={`${t("inputPlaceholder")} ${t("contract.term")}`}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="party">
                        <GFAutoComplete
                          label={t("contract.party")}
                          translation
                          options={contractPartyOptions}
                          required
                          placeholder={`${t("inputPlaceholder")} ${t("contract.party")}`}
                          onChange={handleChangeParty}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="signingDate">
                        <GFDatePicker required label={t("contract.signingDate")} />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="dateStart">
                        <GFDatePicker required label={t("contract.dateStart")} />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="dateEnd">
                        <GFDatePicker label={t("contract.dateEnd")} />
                      </GFFormItem>
                    </Grid>
                  </Grid>
                </ItemSection>
              </Grid>

              <Grid item xs={12}>
                <GFTypography
                  color={"var(--primary-gray-3)"}
                  bgcolor={"var(--primary-gray)"}
                  padding={'10px 25px'}
                  fontWeight={600}
                  variant="subtitle2"
                >
                  {selectParty === CONTRACT_PARTY.TWO_PARTY
                    ? t("contract.seller")
                    : t("contract.seller1")}
                </GFTypography>
                <ItemSection>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <GFFormItem control={control} name="firstSellerId">
                        <GFSelectVirtualized
                          options={masterData.legalsOptions}
                          label={t("contract.firstSellerId")}
                          required
                          onChange={handleFirstSeller}
                          placeholder={`${t("inputPlaceholder")} ${t("contract.firstSellerId")}`}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="tax">
                        <GFTextField
                          fullWidth
                          label={t("contract.tax")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="phoneNumber">
                        <GFTextField
                          fullWidth
                          label={t("contract.phoneNumber")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="bankNum">
                        <GFTextField
                          fullWidth
                          label={t("contract.bankNum")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="bankName">
                        <GFTextField
                          fullWidth
                          label={t("contract.bankName")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="legalRepresentative">
                        <GFTextField
                          fullWidth
                          label={t("contract.legalRepresentative")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="position">
                        <GFTextField
                          fullWidth
                          label={t("contract.position")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="address">
                        <GFTextField
                          fullWidth
                          label={t("contract.address")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>
                  </Grid>
                </ItemSection>
                {selectParty === CONTRACT_PARTY.THREE_PARTY && (
                  <>
                    <GFTypography
                      color={"var(--primary-gray-3)"}
                      bgcolor={"var(--primary-gray)"}
                      padding={'10px 25px'}
                      fontWeight={600}
                      variant="subtitle2"
                    >
                      {t("contract.seller2")}
                    </GFTypography>
                    <ItemSection>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <GFFormItem control={control} name="secondSellerId">
                            <GFSelectVirtualized
                              options={masterData.legalsOptions}
                              label={t("contract.secondSellerId")}
                              required
                              onChange={handleSecondSeller}
                              placeholder={`${t("inputPlaceholder")} ${t("contract.secondSellerId")}`}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="tax2">
                            <GFTextField
                              fullWidth
                              label={t("contract.tax")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="phoneNumber2">
                            <GFTextField
                              fullWidth
                              label={t("contract.phoneNumber")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="bankNum2">
                            <GFTextField
                              fullWidth
                              label={t("contract.bankNum")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="bankName2">
                            <GFTextField
                              fullWidth
                              label={t("contract.bankName")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="legalRepresentative2">
                            <GFTextField
                              fullWidth
                              label={t("contract.legalRepresentative")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="position2">
                            <GFTextField
                              fullWidth
                              label={t("contract.position")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>

                        <Grid item xs={6}>
                          <GFFormItem control={control} name="address2">
                            <GFTextField
                              fullWidth
                              label={t("contract.address")}
                              disabled={true}
                            />
                          </GFFormItem>
                        </Grid>
                      </Grid>
                    </ItemSection>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <GFTypography
                  color={"var(--primary-gray-3)"}
                  bgcolor={"var(--primary-gray)"}
                  padding={'10px 25px'}
                  fontWeight={600}
                  variant="subtitle2"
                >
                  {t("contract.buyer")}
                </GFTypography>
                <ItemSection>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <GFFormItem control={control} name="buyerId">
                        <GFSelectVirtualized
                          options={masterData.customersOptions}
                          label={t("contract.buyerId")}
                          required
                          onChange={handleChangeBuyer}
                          placeholder={`${t("inputPlaceholder")} ${t("contract.buyerId")}`}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="tax3">
                        <GFTextField
                          fullWidth
                          label={t("contract.tax")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="idCard3">
                        <GFTextField
                          fullWidth
                          label={t("contract.idCard3")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="idCardDate3">
                        <GFTextField
                          fullWidth
                          label={t("contract.idCardDate3")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="phoneNumber3">
                        <GFTextField
                          fullWidth
                          label={t("contract.phoneNumber")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="bankNum3">
                        <GFTextField
                          fullWidth
                          label={t("contract.bankNum")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="bankName3">
                        <GFTextField
                          fullWidth
                          label={t("contract.bankName3")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="bankAccount3">
                        <GFTextField
                          fullWidth
                          label={t("contract.bankAccount3")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="position3">
                        <GFTextField
                          fullWidth
                          label={t("contract.position")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>

                    <Grid item xs={6}>
                      <GFFormItem control={control} name="address3">
                        <GFTextField
                          fullWidth
                          label={t("contract.address")}
                          disabled={true}
                        />
                      </GFFormItem>
                    </Grid>
                  </Grid>
                </ItemSection>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className="dialog-content__footer">
          <GFButton onClick={handleClose} variant="outlined">
            {t("CANCEL")}
          </GFButton>
          <GFButton
            variant="contained"
            type="submit"
            form="create-or-update-contract"
            onClick={() => setIsDraft(false)}
            disabled={(!isValid && !isUpdate) || !isDirty || loading}
          >
            {loading && <CircularProgress size={18} />}
            {t("CONFIRM")}
          </GFButton>
        </div>
      </div>
      {loadingPage && <Loading />}
    </Dialog>
  );
};

export default CreateOrUpdateContract;
  