/* eslint-disable react-hooks/exhaustive-deps */
import { getMessaging } from "@firebase/messaging/sw";
import {
  CheckRounded as CheckRoundedIcon,
  DoneAll as DoneAllIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Badge, Divider, Grid, InputAdornment, Popover, Tab, Tabs, TextField } from "@mui/material";
//import firebase, { message } from "./firebase";
import { initializeApp } from "firebase/app";
import { getToken, onMessage } from "firebase/messaging";
import moment from "moment";
//import { useSnackbar } from "notistack";
import Avt from "@Assets/images/noti.png";
import Notification from "@Components/Notification";
import { APP_ROUTES, BASE_LINK_IMAGE } from "@Constants";
import { Action, FetchAction, NotificationModel } from "@Models";
import { PaginateFetchReducer } from "@Models/shared";
import { AuthService, NotificationService } from "@Services";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
// import { APP_ROUTES } from "@Constants";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { NotificationIcon } from "../Icons/NotificationIcon";
import "./index.scss";

function reducer(state: PaginateFetchReducer<NotificationModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      const data: any = state.data;
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: {
          ...data,
          ...action.payload.data,
          notification: data.notification.concat(action.payload.data.notification),
        },
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload, loading: false };

    default:
      return { ...state };
  }
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIERBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const menuNotiTab = [
  {
    label: "Công nợ-Duyệt nợ",
    value: 0,
    valueLabel: "debt",
  },
  {
    label: "Đơn hàng",
    value: 1,
    valueLabel: "order",
  },
  {
    label: "Xét nghiệm",
    value: 2,
    valueLabel: "lab",
  },
  {
    label: "Phản hồi",
    value: 3,
    valueLabel: "feedback",
  },
  {
    label: "Tin tức",
    value: 4,
    valueLabel: "news",
  },
  {
    label: "Khác",
    value: 5,
    valueLabel: "notify",
  },
];

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const PushNotification: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  //const { enqueueSnackbar } = useSnackbar();
  // const [metaNotification, setMetaNotification] = useState<any>();
  const mounted = useRef(false);
  const [notiExport, setNotiExport] = useState<{ file: string; title: string }>({
    file: "",
    title: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const [notiId, setNotiId] = useState<number>(0);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [letterSuccess, setLetterSuccess] = useState<{ file: string | null; data: any }>({
    file: null,
    data: null,
  });

  /* Firebase web 8 */

  // useEffect(() => {
  //   const messaging = firebase.messaging();
  //   // getToken(messaging).then((currentToken) => {
  //   //   if (currentToken) {
  //   //     setTokenFound(true);
  //   //     // Send the token to your server and update the UI if necessary
  //   //     // ...
  //   //     console.log('currentToken', currentToken)
  //   //   } else {
  //   //     // Show permission request UI
  //   //     console.log('No registration token available. Request permission to generate one.');
  //   //     // ...
  //   //   }
  //   // }).catch((err) => {
  //   //   console.log('An error occurred while retrieving token. ', err);
  //   //   // ...
  //   // });
  //   Promise.resolve(message.getToken()).then(function (firebaseToken) {
  //     localStorage.setItem("firebaseToken", firebaseToken);
  //   });
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       //push client id to server
  //       // return PushNotification.addDeviceToken(token, getAccessToken())
  //     })
  //     .then((response) => {
  //       // console.log(response)
  //     })
  //     .catch(function (err) {
  //       console.log("Unable to get permission to notify.", err);
  //     });
  // }, []);

  /* Firebase web 9 */

  const messaging = getMessaging(app);

  useEffect(() => {
    async function fetchData() {
      //registration
      await navigator.serviceWorker.ready;
    }
    fetchData();
  }, []);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // ...
    });
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(true);
          // Send the token to your server and update the UI if necessary
          localStorage.setItem("firebaseToken", currentToken);
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        // enqueueSnackbar("Vui lòng bật thông báo!", {
        //   variant: "error",
        //   preventDuplicate: true,
        // });

        console.log("An error occurred while retrieving token. ", err);
      });
  }, [messaging]);

  const firebaseToken = localStorage.getItem("firebaseToken");

  const saveToken = async () => {
    await AuthService.saveTokenDevice({
      type_device: "browser",
      token: firebaseToken,
      meta_data: "",
    });
  };

  useEffect(() => {
    !isTokenFound &&
      setTimeout(() => {
        if (firebaseToken) saveToken();
      }, 200);
  }, [messaging]);

  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [searchString, setSearchString] = useState<string>("");
  const onSearch = _.debounce((value) => {
    setSearchString(value);
  }, 800);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await NotificationService.getNotificationByCustomer(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  useEffect(() => {
    fetchData(
      {
        page: 1,
        limit: 10,
        // seenFlg: list,
        type: menuNotiTab?.find((f) => f.value === value)?.valueLabel,
        strSearch: searchString,
      },
      true
    );
  }, [value, searchString]);

  useEffect(() => {
    notiId && markAsRead();
  }, [notiId]);

  const markAsRead = useCallback(async () => {
    try {
      await NotificationService.UpdateNotificationById(notiId);
    } catch (error) {
      console.log(error);
    }
  }, [notiId]);

  const markAllAsRead = async () => {
    try {
      await NotificationService.UpdateAllNotificationById();
    } catch (error) {
      console.log(error);
    }
  };

  const history = useHistory();

  // useEffect(() => {
  //   metaNotification &&
  //     history.push(
  //       APP_ROUTES.ORDER_DETAIL.replace(":orderId", metaNotification.orderGroupId.toString())
  //     );
  // }, [metaNotification]);

  return (
    <div className="badge-noti">
      <div
        className="push-noti-icon"
        onClick={(e: any) => {
          handleClick(e);
        }}>
        <Badge
          badgeContent={state.data.totalUnRead > 99 ? `99+` : state.data.totalUnRead}
          overlap="circular">
          <NotificationIcon color="#ffffff" size={[24, 24]} viewBox={[24, 24]} />
        </Badge>
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <div className="notification-list">
          <div className="header">
            <div className="header-title">
              <div className="total">{t("NOTIFICATION")}</div>
              {state.data.totalUnRead > 0 && (
                <div
                  className="mark-as-read"
                  onClick={() => {
                    markAllAsRead();
                    setTimeout(() => {
                      window.location.reload();
                    }, 200);
                  }}>
                  <DoneAllIcon style={{ fontSize: "2rem", marginRight: 10 }} />
                  {t("MARK_AS_SEEN")}
                </div>
              )}
            </div>
            {/* <div className="subtab">
              <Tabs
                className="tab"
                value={list}
                onChange={(e: any) => {
                  handleChangeTab(list);
                }}>
                <Tab
                  label={`Chưa xem(${state.data.totalUnRead})`}
                  value={false}
                  style={{ fontFamily: "SVN-Gotham" }}
                />
                <Tab
                  label={`Đã xem(${state.data.totalRead})`}
                  value={true}
                  style={{ fontFamily: "SVN-Gotham" }}
                />
              </Tabs>
            </div> */}
            <div className="noti-tab-list">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0A6836",
                  },
                }}>
                {menuNotiTab?.map((tab) => {
                  return (
                    <Tab
                      className="noti-tab-list__item"
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {tab.label}
                          {value === tab.value && state.data.totalUnRead > 0 && (
                            <div className="noti-tab-list__badge">{state.data.totalUnRead}</div>
                          )}
                        </div>
                      }
                      value={tab.value}
                      style={{
                        fontFamily: "SVN-Gotham",
                        color: value === tab.value ? "#0A6836" : "#5A5959",
                        fontWeight: value === tab.value ? 700 : 450,
                      }}
                    />
                  );
                })}
              </Tabs>
            </div>
            <div style={{ background: "#f9f9f9", padding: "12px 16px" }}>
              <TextField
                placeholder={t("ENTER_SEARCH")}
                variant="outlined"
                className="search-style"
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" className="icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
          </div>
          {!!state.data?.notification?.length && (
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              initialLoad={false}
              hasMore={
                state.data.notification?.length < state.totalRecords &&
                state.page < state.totalPages
              }
              loadMore={() =>
                fetchData({
                  limit: 10,
                  page: state.page + 1,
                  type: menuNotiTab?.find((f) => f.value === value)?.valueLabel,
                  strSearch: searchString,
                })
              }
              style={{ padding: "0px 16px" }}>
              {state.data?.notification
                // ?.filter((s: any) => s.seenFlg === list)
                ?.map((t: NotificationModel) => {
                  return (
                    <div
                      className="item"
                      key={t.id}
                      onClick={() => {
                        !t.seenFlg && setNotiId(t.id);
                        // setMetaNotification(t.meta);
                        //chờ BE thêm source
                        t.meta?.orderGroupId &&
                          // history.push(
                          //   APP_ROUTES.ORDER_DETAIL_FEED.replace(
                          //     ":orderId",
                          //     t.meta?.orderGroupId.toString()
                          //   )
                          // );
                          history.push(`/order-${t?.source}/${t.meta?.orderGroupId.toString()}`);
                        t.meta?.fileName &&
                          setNotiExport({ file: t.meta?.fileName, title: t.title });
                        t.meta?.result &&
                          setLetterSuccess({ file: t.meta?.errorFile, data: t.meta?.result });
                        if (t.type === "DEBT") {
                          history.push(APP_ROUTES.DEBT_REPORT + "-feed");
                          handleClose();
                        }
                        if (t.type === "NEWS") {
                          history.push({
                            pathname: APP_ROUTES.NEWS_DETAIL.replace(
                              ":newsId",
                              t.meta?.newsId?.toString()
                            ),
                          });
                          handleClose();
                        }
                      }}>
                      <div className="image">
                        {/* <Badge
                          color="error"
                          variant="dot"
                          invisible={t.seenFlg}
                          style={{ zIndex: 0 }}> */}
                        <img src={Avt} alt="customer" />
                        {/* </Badge> */}
                        <div className="notice">
                          <div className="title">{t.title}</div>
                          <div className="content">{t.body}</div>
                          <div className="createAt">{moment(t.createdAt).format("DD/MM/YYYY")}</div>
                        </div>
                      </div>
                      <Divider style={{ marginTop: "2.4rem" }} />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
        </div>
      </Popover>

      <Notification
        visible={notiExport.file !== ""}
        title={t("DATA_EXPORT_SUCCESSFUL")}
        subTitle={
          <span>
            {t("DATA_SUCCESSFULLY_EXPORTED_BY_NAME", {
              NAME:
                notiExport.title !== "" &&
                notiExport.title.slice(15, notiExport.title.length - 1).replace(
                  notiExport.title.slice(15, notiExport.title.length - 1).split(" ")[1][0],
                  notiExport.title
                    .slice(15, notiExport.title.length - 1)
                    .split(" ")[1][0]
                    .toUpperCase()
                ),
            })}
            <br />
            {t("CLICK_DOWNLOAD_FILE")}
          </span>
        }
        icon={
          <div className={`notification-icon-box green`}>
            <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "4rem",
              }}
              onClick={() => {
                setNotiExport({ file: "", title: "" });
              }}>
              {t("CLOSE")}
            </button>
            <a
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                borderRadius: "4rem",
              }}
              rel="noreferrer"
              href={`${BASE_LINK_IMAGE + notiExport.file}`}
              onClick={() => {
                setTimeout(() => {
                  setNotiExport({ file: "", title: "" });
                }, 3000);
              }}>
              {t("DOWNLOAD")}
            </a>
          </>
        }
        onClose={() => {
          setNotiExport({ file: "", title: "" });
        }}
      />

      <Notification
        visible={letterSuccess.data !== null}
        title={
          letterSuccess?.data &&
          (() => {
            if (
              letterSuccess?.data?.error > 0 &&
              letterSuccess?.data?.create <= 0 &&
              letterSuccess?.data?.update <= 0
            ) {
              return "Nhập thư CN/CK thất bại";
            } else if (
              letterSuccess?.data?.error > 0 &&
              (letterSuccess?.data?.create > 0 || letterSuccess?.data?.update > 0)
            ) {
              return "Nhập 1 phần thư CN/CK";
            } else {
              return "Nhập thư CN/CK thành công";
            }
          })()
        }
        subTitle={
          letterSuccess?.data && (
            <div className="letter-file-success">
              <div>
                Thư CN/CK đã nhập thất bại. Kết quả như sau:
                <br />
                <br />
              </div>
              <Grid container style={{ maxWidth: "43.5rem", color: "#000000" }}>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  className="col">
                  <div>Thêm thành công</div>
                  <div>Lỗi</div>
                  <div>{t("UPDATE_SUCCESSFUL")}</div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  className="col">
                  <div>{letterSuccess?.data?.create} thư</div>
                  <div>{letterSuccess?.data?.error} thư</div>
                  <div>{letterSuccess?.data?.update} thư</div>
                </Grid>
              </Grid>
            </div>
          )
        }
        icon={
          letterSuccess?.data &&
          (() => {
            if (
              letterSuccess?.data?.error > 0 &&
              letterSuccess?.data?.create <= 0 &&
              letterSuccess?.data?.update <= 0
            ) {
              return (
                <div className={"notification-icon-box"} style={{ background: "#B8292F" }}>
                  <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                </div>
              );
            } else if (
              letterSuccess?.data?.error > 0 &&
              (letterSuccess?.data?.create > 0 || letterSuccess?.data?.update > 0)
            ) {
              return (
                <div className={"notification-icon-box"} style={{ background: "#FFBC1F" }}>
                  <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                </div>
              );
            } else {
              return (
                <div className={"notification-icon-box"} style={{ background: "#0A6836" }}>
                  <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                </div>
              );
            }
          })()
        }
        actions={
          letterSuccess?.data && (
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                  borderRadius: "3rem",
                }}
                onClick={() => {
                  setLetterSuccess({ file: null, data: null });
                }}>
                {t("CLOSE")}
              </button>
              {letterSuccess?.file !== null && (
                <a
                  className="btn-confirm-style"
                  style={{
                    width: "20.6rem",
                    marginRight: "2rem",
                    borderRadius: "3rem",
                  }}
                  rel="noreferrer"
                  href={`${BASE_LINK_IMAGE + letterSuccess?.file}`}
                  onClick={() => {
                    setTimeout(() => {
                      setLetterSuccess({ file: null, data: null });
                    }, 3000);
                  }}>
                  Tải file lỗi
                </a>
              )}
            </>
          )
        }
        onClose={() => {
          setLetterSuccess({ file: null, data: null });
        }}
      />
    </div>
  );
};

export default PushNotification;
