import EmptyImg from "@Assets/images/empty.png";
import EmptyList from "@Components/EmptyList";
import Loading from "@Components/Loading";
import GFDataTableFilter from "@Components/ui/GFDataTable/GFDataTableFilter";
import GFDataTableHeader from "@Components/ui/GFDataTable/GFDataTableHeader";
import GFDataTableRow, { GFCheckboxProps } from "@Components/ui/GFDataTable/GFDataTableRow";
import GFDataTableSearchSection, {
  GFDataTableSearchSectionProps,
} from "@Components/ui/GFDataTable/GFDataTableSearchSection";
import GFGrabbingScroll from "@Components/ui/GFGrabbingScroll";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isNil } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { GFDataTableAction, GFDataTableFilterType, SelectOption } from "src/types";
import "./style.scss";

const StyledWrapper = styled(Box)({
  marginTop: "8rem",
  "& .loading-comp": {
    marginTop: "0 !important",
  },
});

export type GFDataTableColumn<T = any> = {
  title?: string;
  dataIndex?: keyof T;
  key?: string;
  width?: number;
  autoWidth?: boolean;
  color?: "primary" | "secondary" | "error" | "warning" | "info" | "success" | "default";
  align?: "left" | "center" | "right";
  filterOption?: SelectOption[];
  showTooltip?: boolean;
  onFilter?: (dataIndex: string, filterValue: any) => void;
  render?: (
    cellData: any,
    record: any,
    rowIndex: number,
    onChange?: (record: any, dataIndex: string, newValue: any) => void
  ) => React.ReactNode | null;
};

export type GFDataTableExpandable = {
  expandedRowRender: (record: any) => React.ReactNode;
  rowExpandable: (record: any) => boolean;
};

type GFDataTableProps = Omit<GFDataTableSearchSectionProps, 'showSearch'> & {
  columns: GFDataTableColumn[];
  dataSource: any[];
  pageStart?: number;
  hasMore?: boolean;
  useWindow?: boolean;
  height?: string;
  expandable?: GFDataTableExpandable;
  filters?: GFDataTableFilterType[];
  activeFilter?: string | number;
  showFilter?: boolean;
  showSearchSection?: boolean;
  showActions?: GFDataTableAction;
  showPdfButton?: boolean;
  loading?: boolean;
  rowPrefix?: string;
  showEmpty?: boolean;
  cellClick?: string[];
  showCheckboxConfigs?: GFCheckboxProps;
  bellowFilterContent?: React.ReactNode;
  searchPlaceholder?: string;
  className?: string;
  btnExportProps?: any;
  bottomContent?: (record: any, rowIndex: number) => React.ReactNode;
  onFilter?: (filterValue: string | number) => void;
  onLoadMore?: () => void;
  onRowClick?: (code: string | number | null, record: any) => void;
  onChangeData?: (record: any, dataIndex: string, newValue: any) => void;
};

function GFDataTable({
  columns,
  dataSource,
  pageStart = 0,
  hasMore = false,
  useWindow = false,
  height = "500px",
  expandable,
  filters = [],
  activeFilter,
  searchValue,
  dateRangeValue,
  showDateRange = true,
  showExcelButton = true,
  showPdfButton = false,
  showFilter = true,
  showSearchSection = true,
  showCreateButton: showAddButton = false,
  showActions,
  loading = false,
  showEmpty = true,
  showCheckboxConfigs,
  rowPrefix = "",
  bellowFilterContent,
  searchPlaceholder,
  className="",
  cellClick = [],
  btnExportProps,
  bottomContent,
  onRowClick,
  onCreate,
  onSearch,
  onDateRangeChange,
  onExportExcel,
  onExportPdf,
  onFilter,
  onLoadMore = () => {},
  onChangeData,
}: GFDataTableProps) {
  const { t } = useTranslation("translation");

  const handleDataChange = (record: any, dataIndex: string, newValue: any) => {
    if (onChangeData) {
      onChangeData(record, dataIndex, newValue);
    }
  };

  const handleLoadMore = () => {
    if (hasMore && onLoadMore) {
      onLoadMore();
    }
  };

  const hiddenScrollBottom = dataSource?.length <= 0 || loading;

  return (
    <div className={className}>
      {showSearchSection && (
        <GFDataTableSearchSection
          dateRangeValue={dateRangeValue}
          searchValue={searchValue}
          onDateRangeChange={onDateRangeChange}
          onExportExcel={onExportExcel}
          onExportPdf={onExportPdf}
          onSearch={onSearch}
          showDateRange={showDateRange}
          showExcelButton={showExcelButton}
          showPdfButton={showPdfButton}
          showSearch={showSearchSection}
          showCreateButton={showAddButton}
          onCreate={onCreate}
          searchPlaceholder={searchPlaceholder}
          btnExportProps={btnExportProps}
        />
      )}
      {showFilter && filters?.length > 0 && (
        <GFDataTableFilter filters={filters} activeFilter={activeFilter} onFilter={onFilter} />
      )}
      {bellowFilterContent && bellowFilterContent}
      <GFGrabbingScroll
        className="gf-datatable"
        style={{ "--height": height } as any}
        hiddenScrollBottom={hiddenScrollBottom}>
        <InfiniteScroll
          pageStart={pageStart}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          useWindow={useWindow}
          className="gf-datatable__body">
          <GFDataTableHeader
            columns={columns}
            expandable={expandable}
            isShowActions={!isNil(showActions)}
            showCheckbox={!isNil(showCheckboxConfigs)}
          />

          {loading ? (
            <StyledWrapper>
              <Loading />
            </StyledWrapper>
          ) : dataSource?.length > 0 ? (
            dataSource.map((record, index) => (
              <GFDataTableRow
                key={record.id}
                record={record}
                columns={columns}
                rowIndex={index}
                expandable={expandable}
                showActions={showActions}
                rowPrefix={rowPrefix}
                cellClick={cellClick}
                onChange={handleDataChange}
                onRowClick={onRowClick}
                bottomContent={bottomContent}
                showCheckboxConfigs={showCheckboxConfigs}
              />
            ))
          ) : showEmpty ? (
            <StyledWrapper>
              <EmptyList img={EmptyImg} title={t("EMPTY_LIST")} />
            </StyledWrapper>
          ) : null}
        </InfiniteScroll>
      </GFGrabbingScroll>
    </div>
  );
}

export default GFDataTable;
