import Excel from "@Assets/images/icons/excel.png";
import Pdf from "@Assets/images/icons/ic_pdf.png";
import RangePicker from "@Components/RangePicker";
import { GFButton } from "@Components/ui/GFButton";
import GFSearchInput from "@Components/ui/GFSearchInput";
import useDateRange from "@Hooks/useDateRange";
import { Add } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StyledGFDataTableSearchSection = styled("div")({
  marginBottom: "3.2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& .gf-search-input": {
    width: "68rem",
  },
  "& .date-range": {
    flexShrink: 0,
    height: "4.8rem",

    "& .DateInput": {
      height: "4.8rem !important",
      "& .DateInput_input": {
        height: "4.8rem !important",
      },
    },
  },
});

const FlexWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "2rem",
});

export type GFDataTableSearchSectionProps = {
  searchValue?: string;
  dateRangeValue?: { startTime: moment.Moment; endTime: moment.Moment };
  showExcelButton?: boolean;
  showPdfButton?: boolean;
  showDateRange?: boolean;
  showSearch?: boolean;
  showCreateButton?: boolean;
  searchPlaceholder?: string;
  btnExportProps?: any;
  onSearch?: (value: string) => void;
  onDateRangeChange?: (startTime: moment.Moment, endTime: moment.Moment) => void;
  onCreate?: () => void;
  onExportExcel?: () => void;
  onExportPdf?: () => void;
};

export default function GFDataTableSearchSection({
  searchValue = "",
  dateRangeValue,
  showDateRange = true,
  showExcelButton = true,
  showPdfButton = false,
  showSearch = true,
  showCreateButton = true,
  searchPlaceholder = "SEARCH_PLACEHOLDER",
  btnExportProps = {},
  onSearch,
  onDateRangeChange,
  onExportExcel,
  onExportPdf,
  onCreate,
}: GFDataTableSearchSectionProps) {
  const [range, setRange] = useDateRange();
  const { t } = useTranslation("translation");

  if (
    dateRangeValue &&
    (dateRangeValue.startTime !== range.startTime || dateRangeValue.endTime !== range.endTime)
  ) {
    setRange(dateRangeValue.startTime, dateRangeValue.endTime);
  }

  const handleChangeDate = (val: any[]) => {
    if (val && val.length) {
      const newDate = {
        startTime: moment(val[0]).set({ hours: 0, minutes: 0, seconds: 0 }),
        endTime: moment(val[1]).set({ hours: 23, minutes: 59, seconds: 59 }),
      };

      setRange(newDate.startTime, newDate.endTime);
      if (onDateRangeChange) {
        onDateRangeChange(newDate.startTime, newDate.endTime);
      }
    }
  };

  const btnExportPropsDefault = {
    variant: "text",
  };

  return (
    <StyledGFDataTableSearchSection className="gf-datatable-search-section">
      <FlexWrapper>
        {showSearch && (
          <GFSearchInput
            placeholder={t(searchPlaceholder)}
            value={searchValue}
            onSearch={onSearch}
          />
        )}
        {showDateRange && (
          <RangePicker
            startDate={range.startTime}
            endDate={range.endTime}
            onChangeDate={handleChangeDate}
          />
        )}
      </FlexWrapper>
      <FlexWrapper>
        {showExcelButton && (
          <GFButton
            {...btnExportPropsDefault}
            {...btnExportProps}
            onClick={onExportExcel}
            className="gf-datatable-search-section__export">
            <img src={Excel} alt="icon-excel" />
            {t("EXPORT_DATA")}
          </GFButton>
        )}

        {showCreateButton && (
          <GFButton
            variant="contained"
            onClick={onCreate}
            className="gf-datatable-search-section__add">
            <Add />
            {t("CREATE")}
          </GFButton>
        )}

        {showPdfButton && (
          <GFButton
            {...btnExportPropsDefault}
            {...btnExportProps}
            onClick={onExportPdf}
            className="gf-datatable-search-section__export">
            <img src={Pdf} alt="icon-pdf" />
            {t("EXPORT_PDF")}
          </GFButton>
        )}
      </FlexWrapper>
    </StyledGFDataTableSearchSection>
  );
}
