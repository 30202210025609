import Notification from "@Components/Notification";
import { APP_ROUTES, REGEX } from "@Constants";
import { AuthService, CustomerService } from "@Services";
import { FormUtil } from "@Utils";
import {
  CheckRounded as CheckRoundedIcon,
  ClearRounded as ClearRoundedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useTranslation } from "react-i18next";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation(["order"]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [checkCurrentPw, setCheckCurrentPw] = useState<boolean>(false);
  const [showPw, setShowPw] = useState<boolean>(false);
  const [showNewPw, setShowNewPw] = useState<boolean>(false);
  const [showConfirmPw, setShowConfirmPw] = useState<boolean>(false);
  const [noti, setNoti] = useState<boolean>(false);

  const submitForm = React.useCallback(async (values) => {
    localStorage.removeItem("admin-access-token");
    try {
      await CustomerService.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            localStorage.setItem("requestToken", res.data.destroyToken);
            localStorage.setItem("admin-access-token", res.data.token);
            setNoti(true);
          }
        })

        .catch((err: AxiosError) => {
          if (err.response?.status === 409) {
            setCheckPassword(true);
          } else if (err.response?.status === 400) {
            setCheckCurrentPw(true);
          }
        });
    } catch (error: any) {
      const errors: Record<string, unknown> = {};
      if (error.message) errors.oldPassword = "Mật khẩu cũ không chính xác";
      return errors;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Logout when recover or change password
  const destroyToken = async () => {
    await AuthService.destroyToken({
      requestToken: localStorage.getItem("requestToken"),
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          enqueueSnackbar("Thành công", {
            variant: "success",
          });
          window.location.reload();
        }
      })
      .catch(() => {
        enqueueSnackbar("Thất bại", {
          variant: "error",
        });
      });
  };
  return (
    <div className="cus-reset">
      <div className="right">
        <div className="title">Đổi mật khẩu</div>
        <div className="des">
          Vui lòng nhập những thông tin dưới đây để có thể thực hiện đổi mật khẩu
        </div>
        <Form
          validate={(values) => {
            const errors: Record<string, unknown> = {};
            if (values.confirmPassword !== values.newPassword) {
              errors.confirmPassword = "Mật khẩu không trùng khớp";
            } else if (values.newPassword?.length <= 5) {
              errors.newPassword = "Mật khẩu phải nhiều hơn 5 ký tự";
            } else if (values.oldPassword?.length <= 5) {
              errors.oldPassword = "Mật khẩu cũ phải nhiều hơn 5 ký tự";
            } else if (checkPassword === true) {
              errors.newPassword = "Mật khẩu không trùng mật khẩu cũ";
            } else if (checkCurrentPw === true) {
              errors.oldPassword = "Mật khẩu cũ không chính xác";
            }
            return errors;
          }}
          onSubmit={async (values) => submitForm(values)}>
          {({ handleSubmit }) => {
            return (
              <form
                onSubmit={handleSubmit}
                id="reset-pw"
                style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div className="form-item">
                  <div className="form-label">Mật khẩu cũ</div>
                  <Field
                    name="oldPassword"
                    validate={FormUtil.Rule.required("Bạn chưa nhập mật khẩu cũ")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Mật khẩu cũ"}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showPw ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPw((show) => !show)}>
                                  {showPw ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            input.onChange(e.target.value);
                            setCheckCurrentPw(false);
                          }}
                          helperText={meta.touched ? meta.error || meta.submitError : ""}
                          error={(meta.error || meta.submitError) && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">Mật khẩu mới</div>
                  <Field
                    name="newPassword"
                    validate={FormUtil.Rule.required("Bạn chưa nhập mật khẩu mới")}>
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Mật khẩu mới"}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showNewPw ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowNewPw((show) => !show)}>
                                  {showNewPw ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            input.onChange(e.target.value);
                            setValue(e.target.value);
                            setCheckPassword(false);
                          }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="form-item">
                  <div className="form-label">Nhập lại mật khẩu mới</div>
                  <Field name="confirmPassword">
                    {({ input, meta, ...rest }) => {
                      return (
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Nhập lại mật khẩu"}
                          className={"form-text-field"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showConfirmPw ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowConfirmPw((show) => !show)}>
                                  {showConfirmPw ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </div>
                <div className="cus-reset-validate">
                  <div className="validate-title">Yêu cầu mật khẩu có :</div>
                  <div className="validate-content">
                    {value !== "" ? (
                      <div className="item">
                        {REGEX.CHARACTER_LENGTH.test(value) === true ? (
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        ) : (
                          <div className="ic-red">
                            <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        )}
                        <div>&ensp;Tối thiểu 8 ký tự</div>
                      </div>
                    ) : (
                      <div className="item-inactive">
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Tối thiểu 8 ký tự</div>
                      </div>
                    )}

                    {value !== "" ? (
                      <div className="item">
                        {REGEX.UPPERCASE.test(value) === true ? (
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        ) : (
                          <div className="ic-red">
                            <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        )}
                        <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                      </div>
                    ) : (
                      <div className="item-inactive">
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                      </div>
                    )}

                    {value !== "" ? (
                      <div className="item">
                        {REGEX.DIGITS.test(value) === true ? (
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        ) : (
                          <div className="ic-red">
                            <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        )}
                        <div>&ensp;Ít nhất 1 chữ số</div>
                      </div>
                    ) : (
                      <div className="item-inactive">
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 chữ số</div>
                      </div>
                    )}

                    {value !== "" ? (
                      <div className="item">
                        {REGEX.SPECIAL_CHARACTER.test(value) === true ? (
                          <div className="ic-green">
                            <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        ) : (
                          <div className="ic-red">
                            <ClearRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                          </div>
                        )}
                        <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                      </div>
                    ) : (
                      <div className="item-inactive">
                        <div className="ic-green">
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                      </div>
                    )}
                  </div>
                </div>
                <button className="btn-confirm-style" type="submit">
                  Đặt lại mật khẩu
                </button>
              </form>
            );
          }}
        </Form>
      </div>

      <Notification
        visible={noti}
        icon={
          <div className={`notification-icon-box ${noti ? "green" : "red"}`}>
            {noti ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        title={noti ? "Đổi mật khẩu thành công!" : "Đổi mật khẩu thất bại!"}
        subTitle={
          noti ? (
            <span>
              <br />
              Mật khẩu mới cập nhật thành công
              <br />
              Quý khách có muốn đăng xuất khỏi các thiết bị khác
            </span>
          ) : (
            <span>Vui lòng đặt lại mật khẩu</span>
          )
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "12.6rem",
                marginRight: "2rem",
                borderRadius: "4rem",
              }}
              onClick={() => {
                setNoti(false);
              }}>
              {t("NO")}
            </button>
            <button
              className="btn-confirm-style"
              style={{
                borderRadius: "4rem",
              }}
              onClick={() => {
                noti ? destroyToken() : setNoti(false);
              }}>
              {noti ? " Đồng ý" : "Thử lại"}
            </button>
          </>
        }
        onClose={() => history.push(APP_ROUTES.SIGNIN)}
      />
    </div>
  );
};

export default ChangePassword;
