import { APP_ROUTES } from "@Constants";
import { useAppDispatch } from "@Core/store/hook";
import { loadProfile } from "@Core/store/profile";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const exceptedRoutes = [
  APP_ROUTES.SIGNIN,
  APP_ROUTES.FORGETPASSWORD,
  APP_ROUTES.RESETPASSWORD,
  APP_ROUTES.VERIFY_OTP,
  APP_ROUTES.IS_BLOCKED,
];

const AuthChecker = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    const pathname = location?.pathname;

    if (!pathname || exceptedRoutes.some((s) => pathname.includes(s))) {
      return;
    }

    dispatch(loadProfile());
  }, [dispatch, location]);

  return null;
};

export default AuthChecker;
