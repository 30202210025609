import { APPROVE_BUSINESS_TYPE } from "@Constants";
import { z } from "zod";

export const approvalStatusSchema = z.object({
  code: z
    .string({
      message: "Nhập mã trạng thái",
    })
    .regex(/^[a-zA-Z0-9]*$/, {
      message: "Mã trạng thái không được chứa ký tự đặc biệt",
    })
    .min(3, {
      message: "Mã trạng thái phải có ít nhất 3 ký tự",
    }),
  statusName: z
    .string({
      message: "Nhập tên trạng thái",
    })
    .min(1, {
      message: "Tên trạng thái không được để trống",
    }),
  businessType: z.nativeEnum(APPROVE_BUSINESS_TYPE, {
    message: "Loại nghiệp vụ không hợp lệ",
  }),
  isActive: z.boolean({
    message: "Chọn trạng thái hoạt động",
  }),
  description: z.string().optional(),
});

export type ApprovalStatusPayload = z.infer<typeof approvalStatusSchema>;
