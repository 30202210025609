import { z } from "zod";

export const warehouseTransferSchema = z.object({
  sourceBuId: z.number({
    message: "Vui lòng chọn BU chuyển đi",
  }),
  sourceEmployeeId: z.number({
    message: "Vui lòng chọn Vùng chuyển đi",
  }),
  sourceItemAllocationId: z.number({
    message: "Vui lòng chọn nhóm sản phẩm chuyển đi",
  }),
  targetBuId: z.number({
    message: "Vui lòng chọn BU chuyển đến",
  }),
  targetEmployeeId: z.number({
    message: "Vui lòng chọn Vùng chuyển đến",
  }),
  targetItemAllocationId: z.number({
    message: "Vui lòng chọn Nhóm sản phẩm chuyển đến",
  }),
  transferDate: z.date({
    message: "Vui lòng chọn ngày chuyển đi",
  }),
  receivedDate: z.date({
    message: "Vui lòng chọn ngày chuyển đến",
  }),
  transferNum: z.string({
    message: "Vùi lòng nhập số chuyển",
  }).transform((val) => Number(val)),
  note: z.string().max(500, 'Ghi chú tối đa 500 kí tự').optional(),
}).superRefine((data, ctx) => {
  if (data.receivedDate < data.transferDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Ngày chuyển đến không thể trước ngày chuyển đi",
      path: ["receivedDate"],
    });
  }

  if (data.transferNum <= 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Số chuyển phải lớn hơn 0",
      path: ["transferNum"],
    });
  }
})

export type WarehouseTransferForm = z.infer<typeof warehouseTransferSchema>;
