import { PaginationQueryModel } from "@Models";
import { OrderService } from "@Services";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { CampCoordinationPayload } from "src/dtos/camp.dto";
import { SelectOption } from "src/types";

type SiteQueryModel = PaginationQueryModel & {
  searchValue?: string;
  source?: string;
  customerId?: number;
  itemId?: number;
}

export const useAllSiteByCustomer = (query: SiteQueryModel) => {
  const { data, isLoading } = useQuery({
    queryKey: ["customer-sites", query],
    queryFn: () => {

      if (!query.customerId) {
        return
      }

      return OrderService.getAllSiteFarm(query)
    },
  })

  const sites: SelectOption[] = _.get(data, "data.data", []).map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    }
  });
  return {
    sites
  }
};


export const useUpdateOrderCoordination = () => {
  return useMutation({
    mutationKey: ["update-order-coordination"],
    mutationFn: (data: CampCoordinationPayload) => {
      return OrderService.updateOrderFarm(data as any);
    },
  })
}