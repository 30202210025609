
import { GFCheckbox, GFCustomerAvatar, GFDataTable, GFLayoutWrapper, GFModal, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import { ApproveOrRejectModel, CreateCustomerShippingAddressModel, CustomerManageGridModel, CustomerRequestModel, ICustomer, MasterData, ShippingAddressDetailState } from "@Models/customer-manage.model";
import { CustomerManageService } from "@Services/customer-manage.service";
import { getErrorResponse, toClientDate } from "@Utils";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalState, ModalType, GFDataTableFilterType } from "src/types";
// import CreateOrUpdateCustomerManage from "./CreateOrUpdateCustomer";
import { useHistory } from "react-router-dom";
import { useCustomerManageInfinite } from "src/app/queries/customer-manage.query";
import GFFloatingButton from "@Components/ui/GFFloatingButton";

const columnSettings = (t: any): GFDataTableColumn<CustomerManageGridModel>[] => [
  {
    title: "Số hợp đồng",
    dataIndex: "code",
    autoWidth: true,
  },
  {
    title: "Khách hàng",
    dataIndex: "customer",
    width: 300,
    autoWidth: true,
    render: (cellData) => {
      return <GFCustomerAvatar fullName={cellData.name} code={cellData.code} />
    },
  },
  {
    title: "Bên bán",
    dataIndex: "createdAt",
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{toClientDate(cellData)}</GFTypography>;
    },
  },
  {
    title: "Nhóm sản phẩm",
    dataIndex: "phoneNumber",
    autoWidth: true,
  },
  {
    title: "Loại hợp đồng",
    dataIndex: "shippingAddress",
    autoWidth: true,
    width: 250
  },
  {
    title: "Ngày hiệu lực",
    dataIndex: "createdAt",
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{toClientDate(cellData)}</GFTypography>;
    },
  },
  {
    title: "Số ngày trễ",
    dataIndex: "createdAt",
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{toClientDate(cellData)}</GFTypography>;
    },
  },
  {
    title: "Tệp đính kèm",
    dataIndex: 'itemGroupType',
    autoWidth: true,
    render: (cellData) => {
      return <GFTypography fontWeight={600}>{cellData}</GFTypography>;
    },
  },
];


const ContractAddendum = () => {

  const [searchValue, setSearchValue] = useState<string>("")
  
//   const {
//     data,
//     hasNextPage,
//     isLoading,
//     isFetching,
//     fetchNextPage,
//     total,
//     refetch,
//     countFilter
//    } = useCustomerManageInfinite({
//     limit: 10,
//     strSearch: searchValue,
//     status: 'ALL'
//   })

  const onSearch = (value: string) => {
    setSearchValue(value)
  }

  const { t } = useTranslation("translation");
  const { enqueueSnackbar } = useSnackbar();

  const [modal, setModal] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });

  const [modalShippingAddress, setModalShippingAddress] = useState<ModalState>({
    open: false,
    selectedId: null,
    modalType: ModalType.Create,
    loading: false,
  });


  const history = useHistory();
  const columns = useMemo(() => columnSettings(t), [t]);
  const data: any = []
  
  return (
    <GFLayoutWrapper>
     

     

      {/* {isOpenDeleteModal && (
        <GFModal
          title={t("confirmInformationTitle")}
          open={isOpenDeleteModal}
          onClose={handleCloseModal}
          onConfirm={handleDeleteCustomerManage}
          loading={modal.loading}>
          <GFTypography>{t("customerManage.deleteCustomerManageConfirmation")}</GFTypography>
        </GFModal>
      )} */}

      <GFDataTable
        columns={columns}
        dataSource={data}
        // hasMore={hasNextPage}
        // onLoadMore={fetchNextPage}
        showDateRange={false}
        showExcelButton={false}
        showCreateButton={false}
        // onCreate={handleOpenCreateModal}
        // activeFilter={activeFilter}
        // filters={filters}
        // onFilter={handleFilter}
        showSearchSection={true}
        // onRowClick={handleRowClick}
        cellClick={["customer"]}
        // showActions={{
        //   showActionDeleteButton(record) {
        //     return canAcctionStatuses.includes(record.status);
        //   },
        //   showActionEditButton(record) {
        //     return canAcctionStatuses.includes(record.status);
        //   },
        //   onActionEdit(id, record) {
        //     handleOpenUpdateModal(id, record);
        //   },
        //   onActionDelete(id, record) {
        //     handleOpenDeleteModal(id);
        //   },
        // }}
        searchValue={searchValue}
        searchPlaceholder={t('customerManage.searchPlaceholder')}
        onSearch={onSearch}
        // loading={isLoading}
        height="calc(100vh - 300px)"
      />
      {/* <GFFloatingButton visible={false} menuItems={menuItems} /> */}
    </GFLayoutWrapper>
  );
}

export default ContractAddendum