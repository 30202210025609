import { useMemo } from "react";

import { GFDataTable, GFTypography } from "@Components/ui";
import { GFDataTableColumn } from "@Components/ui/GFDataTable";
import useGetMe from "@Hooks/useGetMe";
import { useTableFilter } from "@Hooks/useTableFilter";
import { formatCurrency } from "@Utils";
import { useListPriceShipping } from "src/app/queries/product.query";
import { GFDataTableFilterType, PigTypeCode } from "src/types";
import "./index.scss";
import moment from "moment";

const columns: GFDataTableColumn[] = [
  {
    title: "Mã",
    dataIndex: "item.code",
    color: "primary",
  },
  {
    title: "Tên chi phí",
    dataIndex: "item.name",
    autoWidth: true,
    width: 300,
  },
  {
    title: "Loại heo",
    dataIndex: "materialGroup1",
  },
  {
    title: "Quảng đường",
    align: "right",
    dataIndex: "materialGroup2",
  },
  {
    title: "Loại xe",
    align: "right",
    dataIndex: "materialGroup3",
  },
  {
    title: "Đơn giá",
    dataIndex: "value",
    align: "right",
    render: (cellData) => <GFTypography>{formatCurrency(cellData)}</GFTypography>,
  },
  {
    title: "Đơn vị tính",
    dataIndex: "per",
    align: "right",
    render: (cellData, record) => (
      <GFTypography>
        {record.per}
        <GFTypography
          variant="body2"
          type="primary"
          fontWeight={600}
          sx={{ minWidth: 32, display: "inline-block", textAlign: "right" }}>
          /{record.baseUoM}
        </GFTypography>
      </GFTypography>
    ),
  },
];

export default function ListPriceShipping() {
  const { getPigTypeIdByCode } = useGetMe();

  const filters: GFDataTableFilterType[] = useMemo(
    () => [
      {
        label: "Tất cả",
        value: 0,
      },
      {
        label: "Heo giống",
        value: getPigTypeIdByCode(PigTypeCode.Breeding) as number,
      },
      {
        label: "Heo thịt",
        value: getPigTypeIdByCode(PigTypeCode.Fork) as number,
      },
    ],
    [getPigTypeIdByCode]
  );

  const { tableFilter, onChangeDateRange, onChangeFilter, onSearch, onSet } = useTableFilter({
    initialValues: {
      limit: 10,
      isInternal: false,
      filter: 0,
      from: moment().subtract(30, "days").toISOString(),
      to: moment().toISOString(),
    },
  });

  const { data, isFetching, isLoading, hasNextPage, fetchNextPage } = useListPriceShipping({
    limit: 10,
    isInternal: tableFilter.isInternal!,
    searchValue: tableFilter.searchValue,
    from: tableFilter.from,
    to: tableFilter.to,
    itemGroupTypeId: tableFilter.filter as number,
  });

  const handleChangeType = (isInternal: boolean) => {
    if (isFetching || isLoading) return;

    onSet({ ...tableFilter, isInternal });
  };

  return (
    <div className="u-layout header-only">
      <div className="u-sidebar">
        <div
          className={`u-sidebar-item ${!tableFilter.isInternal ? "active" : ""}`}
          onClick={() => handleChangeType(false)}>
          <p>Phí vận chuyển/cách ly bên ngoài</p>
        </div>
        <div
          className={`u-sidebar-item ${tableFilter.isInternal ? "active" : ""}`}
          onClick={() => handleChangeType(true)}>
          <p>Phí vận chuyển/cách ly bên trong</p>
        </div>
      </div>
      <div className="u-content-wrapper">
        <div className="u-content shadow price-shipping">
          <GFDataTable
            columns={columns}
            loading={isFetching || isLoading}
            dataSource={data}
            height="calc(100vh - 250px)"
            hasMore={hasNextPage}
            onLoadMore={fetchNextPage}
            showExcelButton={false}
            onDateRangeChange={onChangeDateRange}
            dateRangeValue={{
              endTime: moment(tableFilter.to),
              startTime: moment(tableFilter.from),
            }}
            onSearch={onSearch}
            btnExportProps={{
              variant: "outlined",
            }}
          />
        </div>
      </div>
    </div>
  );
}
