import GFIconButton from "@Components/ui/GFIconButton";
import GFTooltip from "@Components/ui/GFTooltip";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";

const FloatingButtonStyled = styled(Box)({
  position: "fixed",
  bottom: "2rem",
  right: "2rem",
  zIndex: 1000,
});

const FloatingMenuStyled = styled(Box)({
  position: "absolute",
  right: "58px",
  top: "0",
  transition: "all 0.3s",
  display: "flex",
  flexDirection: "row-reverse",
  columnGap: "1rem",
  alignItems: "center",
  overflow: "hidden",
});

type MenuItem = {
  title?: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

const menu = [
  {
    title: "Add",
    icon: <Add />,
    onClick: () => console.log("Add"),
  },
  {
    title: "Edit",
    icon: <Edit />,
    onClick: () => console.log("Edit"),
  },
  {
    title: "Delete",
    icon: <Delete />,
    onClick: () => console.log("Delete"),
  },
];

type FloatingButtonProps = {
  menuItems: MenuItem[];
  visible: boolean
};

export default function GFFloatingButton({visible, menuItems = menu }: FloatingButtonProps) {
  const [open, setOpen] = useState(visible);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const openMenuWidth = 48 * menuItems.length + 10 * (menuItems.length - 1);

  return (
    <FloatingButtonStyled>
      <GFIconButton onClick={handleToggle} variant={open ? "secondary" : "contained"} size="large">
        <Add
          sx={{
            transform: `rotate(${open ? -45 : 0}deg)`,
            transition: "all 0.2s",
          }}
        />
      </GFIconButton>
      <FloatingMenuStyled
        sx={{
          width: open ? `${openMenuWidth}px` : 0,
        }}>
        {menuItems.map((item, index) => (
          <GFTooltip title={item.title}>
            <GFIconButton onClick={item.onClick} variant="contained" size="large">
              {item.icon}
            </GFIconButton>
          </GFTooltip>
        ))}
      </FloatingMenuStyled>
    </FloatingButtonStyled>
  );
}
