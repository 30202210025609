import ImgEmpty from "@Assets/images/img_empty.png";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import DefaultSwitch from "@Components/Switch/DefaultSwitch";
import { APP_ROUTES, BASE_LINK_IMAGE, TEXT_HOLDER } from "@Constants";
import { useAppDispatch } from "@Core/store/hook";
import { loadProfile } from "@Core/store/profile";
import {
  DivisionModal,
  NewsDetailOutputModel,
  NewsInputModel,
  SalesOrgModal,
  SourceType,
} from "@Models";
import { NewService, ProductService, SalesOrgService } from "@Services";
import { FormDataUtil } from "@Utils";
import { Circle as CircleIcon, PriorityHigh as PriorityHighIcon } from "@mui/icons-material";
import { Dialog, Grid, InputAdornment, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./NewsDetail.scss";
import NewsPreview from "./NewsPreview";

const NewsDetail: React.FC = () => {
  const { t } = useTranslation(["translation", "order", "customer"]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation<any>();
  const mounted = useRef(false);
  const { newsId }: { newsId: string } = useParams();
  const [dataDetailNews, setDataDetailNews] = useState<NewsDetailOutputModel | null>(null);
  const [closeForm, setCloseForm] = useState<boolean>(true);
  const [dataSalesOrg, setDataSalesOrg] = useState<SalesOrgModal[]>([]);
  const [dataDivision, setDataDivision] = useState<DivisionModal[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [previewNews, setPreviewNews] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const dispatchProfile = useAppDispatch();

  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getNewsDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!dataDetailNews) {
      const data = dataDetailNews.sources.join(",");
      onFetchApiSaleOrg(data);
      onFetchApiDivision(data);
      if (dataDetailNews.isActive === false) {
        setIsActive(false);
      } else {
        setIsActive(true);
      }
    }
    // eslint-disable-next-line
  }, [dataDetailNews]);

  const onFetchApiSaleOrg: (data: string) => Promise<void> = async (data: string) => {
    try {
      const response = await SalesOrgService.getAllSalesOrg({ sources: data });
      if (mounted.current) {
        setDataSalesOrg(() => response.data.data);
      }
    } catch (error) {}
  };

  const onFetchApiDivision: (data: string) => Promise<void> = async (data: string) => {
    try {
      const response = await ProductService.getDivisionBySource({ getAll: true, sources: data });
      if (mounted.current) {
        setDataDivision(() => response.data.data);
      }
    } catch (error) {}
  };

  const getNewsDetail: () => Promise<void> = async () => {
    setLoading(true);
    try {
      const response = await NewService.getNewsById(newsId);
      if (mounted.current) {
        setDataDetailNews(() => response.data.data);
        setLoading(false);
      }
    } catch (err: any) {
      console.log(err);
      if (err.message.includes("404")) history.push(APP_ROUTES.NOT_FOUND);
      setLoading(false);
    }
  };

  const onSubmit = async (isDraft: boolean, isActive: boolean) => {
    setLoading(true);
    const data: NewsInputModel = {
      divisionIds: !!dataDetailNews ? dataDetailNews?.divisionIds : [],
      salesOrgIds: !!dataDetailNews ? dataDetailNews?.salesOrgIds : [],
      sources: !!dataDetailNews ? dataDetailNews?.sources : [],
      actors: !!dataDetailNews ? dataDetailNews?.actors : [],
      title: !!dataDetailNews ? dataDetailNews?.title : "",
      body: !!dataDetailNews ? dataDetailNews?.body : "",
      isDraft: isDraft,
      isActive: isActive,
      postedDate: !!dataDetailNews
        ? moment(dataDetailNews?.postedDate).toISOString()
        : moment(new Date()).toISOString(),
      oldImage: !!dataDetailNews ? dataDetailNews?.image : null,
      url: !!dataDetailNews ? dataDetailNews?.url : null,
    };

    const formData = FormDataUtil.convertJsonToFormData(data);

    NewService.updateNews(newsId, formData)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          enqueueSnackbar(t("UPDATE_NEWS_SUCCESSFUL"), {
            variant: "success",
          });
          getNewsDetail();
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("UPDATE_NEWS_FAILED"), {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const nameSalesOrg = !!dataSalesOrg.length
    ? dataSalesOrg.filter((i) => dataDetailNews?.salesOrgIds.includes(i.id))
    : [];

  const nameDivision = !!dataDivision.length
    ? dataDivision.filter((i) => dataDetailNews?.divisionIds.includes(i.id))
    : [];

  return (
    <>
      <Dialog fullScreen open={closeForm}>
        {loading ? (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        ) : (
          <>
            <div className="news-detail">
              <SimpleModalHeader
                title={t("DETAIL")}
                onClose={() => {
                  setCloseForm(false);
                  history.push({
                    pathname: APP_ROUTES.NEWS,
                    state: {
                      startTime: location.state?.startTime,
                      endTime: location.state?.endTime,
                      selectTab: location.state?.selectTab,
                    },
                  });
                }}
              />
              <div className="content">
                <Grid container>
                  <Grid item xs={8} className="content-info">
                    <div className="body">
                      <div className="body-header">
                        <div className="title">{t("INFO")}</div>
                        {!dataDetailNews?.isDraft && (
                          <div>
                            <DefaultSwitch
                              checked={isActive}
                              onChange={() => {
                                setIsActive(!isActive);
                                setNotiStatus({
                                  visible: true,
                                  status: isActive === false ? true : false,
                                });
                              }}
                            />
                            {isActive === false ? (
                              <span style={{ color: "#838283" }}>{t("HIDE")}</span>
                            ) : (
                              <span style={{ color: "#41AD49" }}>{t("SHOW")}</span>
                            )}
                          </div>
                        )}
                      </div>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <div className="label">{t("ACCESS_ARRAY")}</div>
                          <span className="value">
                            {dataDetailNews?.sources.length ===
                            Object.keys(SourceType).filter(
                              (i) => SourceType[i as keyof typeof SourceType] !== SourceType.CCU
                            ).length
                              ? t("ALL")
                              : dataDetailNews?.sources?.map((i) => t(i.toUpperCase())).join(", ")}
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="label">{t("customer:INDUSTRY")}</div>
                          <span className="value">
                            {!!nameDivision.length
                              ? nameDivision.map((i) => i.description).join(", ")
                              : TEXT_HOLDER}
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="label">{t("order:BUSINESS_UNIT")}</div>
                          <span className="value">
                            {!!nameSalesOrg.length
                              ? nameSalesOrg.map((i) => i.description).join(", ")
                              : TEXT_HOLDER}
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="label">{t("order:OBJECTIVE")}</div>
                          <span className="value">
                            {dataDetailNews?.actors?.map((i) => t(i.toUpperCase())).join(", ")}
                          </span>
                        </Grid>

                        <Grid item xs={4}>
                          <div className="label">{t("STATUS")}</div>
                          {dataDetailNews?.isDraft ? (
                            <span style={{ color: "#9B9A9B" }}>
                              <CircleIcon />
                              &ensp; {t("DRAFTS")}
                            </span>
                          ) : dataDetailNews?.isActive ? (
                            <span style={{ color: "#0A6836" }}>
                              <CircleIcon />
                              &ensp; {t("PUBLISHED")}
                            </span>
                          ) : (
                            <span style={{ color: "#B8292F" }}>
                              <CircleIcon />
                              &ensp;{t("HIDDEN")}
                            </span>
                          )}
                        </Grid>

                        <Grid item xs={4}></Grid>

                        <Grid item xs={12}>
                          <div className="label">{t("TITLE")}</div>
                          <span className="value">{dataDetailNews?.title || TEXT_HOLDER}</span>
                        </Grid>

                        <Grid item xs={12}>
                          <div className="label">{t("CONTENT")}</div>
                          <div className="value">{dataDetailNews?.body || TEXT_HOLDER}</div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="label">{t("Link bài viêt")}</div>
                          <div
                            className="value"
                            style={{
                              color: "#0A6836",
                              textDecoration: "underline",
                            }}>
                            <a
                              href={dataDetailNews?.url || TEXT_HOLDER}
                              target="_blank"
                              rel="noopener noreferrer">
                              {dataDetailNews?.url || TEXT_HOLDER}
                            </a>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="label">{t("IMAGE")}</div>
                          <div className="image">
                            {dataDetailNews?.image ? (
                              <img src={BASE_LINK_IMAGE + dataDetailNews?.image} alt="news" />
                            ) : (
                              <img src={ImgEmpty} alt="news" />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="news-detail-footer">
              <div className="content">
                <div className="content-left">
                  <Select
                    className="select_option btn--shiny"
                    renderValue={() => (
                      <span style={{ color: "#0a6836", WebkitTextFillColor: "#0a6836" }}>
                        {t("order:OPTIONS")}
                      </span>
                    )}
                    displayEmpty
                    startAdornment={<InputAdornment position="start"></InputAdornment>}
                    inputProps={{ readOnly: false }}
                    MenuProps={{
                      className: "select-menu-list",
                      anchorOrigin: {
                        vertical: 60,
                        horizontal: "right",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}>
                    {(() => {
                      return (
                        <>
                          <MenuItem
                            onClick={() => {
                              !!dataDetailNews &&
                                history.push(
                                  APP_ROUTES.NEWS_UPDATE.replace(
                                    ":newsId",
                                    dataDetailNews?.id.toString()
                                  )
                                );
                            }}>
                            {t("EDIT")}
                          </MenuItem>
                          <MenuItem onClick={() => setPreviewNews(true)}>{t("PREVIEW")}</MenuItem>
                        </>
                      );
                    })()}
                  </Select>
                </div>
                <div className="content-right">
                  <div
                    onClick={() => {
                      if (!loading) {
                        setCloseForm(false);
                        history.push({
                          pathname: APP_ROUTES.NEWS,
                          state: {
                            startTime: location.state?.startTime,
                            endTime: location.state?.endTime,
                            isDraft: location.state?.isDraft,
                            isActive: location.state?.isActive,
                          },
                        });
                      }
                    }}
                    className="btn-cancel-style btn__text-cancel"
                    style={{ marginLeft: "4rem" }}>
                    {t("CLOSE")}
                  </div>

                  {dataDetailNews?.isDraft && (
                    <button
                      onClick={() => {
                        !loading && onSubmit(false, true);
                      }}
                      type="submit"
                      disabled={loading}
                      className="btn-confirm-style"
                      style={{ marginLeft: "4rem" }}>
                      {t("PUBLISH")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Dialog>

      <Dialog fullScreen open={previewNews}>
        <NewsPreview closeForm={() => setPreviewNews(false)} data={dataDetailNews} />
      </Dialog>

      {notiStatus && (
        <Notification
          visible={notiStatus.visible}
          title={notiStatus.status ? t("SHOW_NEWS") : t("HIDDEN_NEWS")}
          subTitle={
            notiStatus.status ? (
              <span dangerouslySetInnerHTML={{ __html: t("CONFIRM_SHOW_NEWS") }} />
            ) : (
              <span dangerouslySetInnerHTML={{ __html: t("CONFIRM_HIDDEN_NEWS") }} />
            )
          }
          icon={
            <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
              <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  notiStatus.status
                    ? setNotiStatus({ visible: false, status: true })
                    : setNotiStatus({ visible: false, status: false });
                  notiStatus.status ? setIsActive(false) : setIsActive(true);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  if (notiStatus.status) {
                    setNotiStatus({ visible: false, status: true });
                    onSubmit(false, true);
                  } else {
                    setNotiStatus({ visible: false, status: false });
                    onSubmit(false, false);
                  }
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            notiStatus.status
              ? setNotiStatus({ visible: false, status: true })
              : setNotiStatus({ visible: false, status: false });
            notiStatus.status ? setIsActive(false) : setIsActive(true);
          }}
        />
      )}
    </>
  );
};

export default NewsDetail;
