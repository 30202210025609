import AuthChecker from "@Components/Common/AuthChecker";
import DownApp from "@Components/DownApp";
import Loading from "@Components/Loading";
import { APP_ROUTES, BASE_ROUTE } from "@Constants";
import Layout from "@Core/Layout";
import { appRoutes } from "@Core/Layout/routes";
import PrivateRoute from "@Core/auth/PrivateRoute";
import { useBreakpoints } from "@Hooks/useBreakpoints";
import React from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

const ForgetPassword = React.lazy(() => import("@Containers/ForgetPassword"));
const ResetPassword = React.lazy(() => import("@Containers/ForgetPassword/ResetPassword"));
const SignInForm = React.lazy(() => import("@Containers/SignInForm"));
const BlockAccount = React.lazy(() => import("@Containers/SignInForm/BlockAccount/BlockAccount"));
const OTPInput = React.lazy(() => import("@Containers/SignInForm/OTPInput/OTPInput"));
const NotFound = React.lazy(() => import("@Components/404"));
const RedirectPage = React.lazy(() => import("@Components/Redirect"));

//develop-merge

function App() {
  const breakpoints = useBreakpoints();
  return (
    <React.Suspense fallback={<Loading style={{ margin: "2rem 0", height: "80vh" }} />}>
      <div className="App">
        {breakpoints.md && (
          <Router basename={BASE_ROUTE}>
            <AuthChecker />
            <Switch>
              <Route exact path={APP_ROUTES.REDIRECT} component={RedirectPage} />
              <Route exact path={APP_ROUTES.VERIFY_OTP} component={OTPInput} />
              <Route exact path={APP_ROUTES.SIGNIN} component={SignInForm} />
              <Route exact path={APP_ROUTES.FORGETPASSWORD} component={ForgetPassword} />
              <Route exact path={APP_ROUTES.RESETPASSWORD} component={ResetPassword} />
              <Route exact path={APP_ROUTES.IS_BLOCKED} component={BlockAccount} />
              <PrivateRoute>
                <Layout>
                  <Route
                    exact
                    path={APP_ROUTES.ORDER_FEED}
                    render={() => <Redirect to={APP_ROUTES.ORDER_FEED} />}
                  />
                  <Switch>
                    {appRoutes.map((route, index) => {
                      return <Route key={index} {...route} exact />;
                    })}
                    <Route component={NotFound} />
                  </Switch>
                </Layout>
              </PrivateRoute>
            </Switch>
          </Router>
        )}
        {breakpoints.xs && !!breakpoints.md !== true ? <DownApp /> : ""}
      </div>
    </React.Suspense>
  );
}

export default App;
