import { REQUEST_URL } from "@Constants";
import { CreateOrUpdateContractModel, GetContractQuery } from "@Models/contract.model";
import { RequestUtil } from "@Utils";

export const ContractService = {
  getAll: (query: GetContractQuery) => {
    return RequestUtil.get({ url: REQUEST_URL.CONTRACT_MANAGE, query: query });
  },

  getById: (id: number) => {
    return RequestUtil.get({ url: `${REQUEST_URL.CONTRACT_MANAGE}/${id}` });
  },

  create: (data: CreateOrUpdateContractModel) => {
    return RequestUtil.post({ url: REQUEST_URL.CONTRACT_MANAGE, data: data });
  },

  update: (id: number, data: CreateOrUpdateContractModel) => {
    return RequestUtil.put({ url: `${REQUEST_URL.CONTRACT_MANAGE}/${id}`, data: data });
  },

  delete: (id: number) => {
    return RequestUtil.delete({ url: `${REQUEST_URL.CONTRACT_MANAGE}/${id}` });
  },

  getMasterData: () => {
    return RequestUtil.get({ url: `${REQUEST_URL.CONTRACT_MANAGE}/master-data` });
  }

};
