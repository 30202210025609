import { GFLabel, GFMenuItem, GFTextField } from "@Components/ui";
import { Autocomplete, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isFunction, uniqueId } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type SelectOption = {
  label: string;
  value: string | number;
};

type GFAutoCompleteProps = {
  label?: string;
  options?: SelectOption[];
  value?: string | number | null;
  error?: any;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  translation?: boolean;
  size?: "small" | "medium";
  onChange?: (value: string | number | null) => void;
};

const StyledAutocomplete = styled(Autocomplete)(({ size }) => ({
  "& .MuiAutocomplete-inputRoot": {
    "& .MuiAutocomplete-endAdornment button": {
      backgroundColor: "transparent !important",
    },
  },
}));

export default function GFAutoComplete({
  value = null,
  options = [],
  label,
  error,
  required,
  translation = false,
  placeholder,
  onChange,
  size = "medium",
  ...props
}: GFAutoCompleteProps) {
  const { t } = useTranslation("translation");

  const handleChange = (event: React.SyntheticEvent, newData: SelectOption | null) => {
    if (isFunction(onChange)) {
      onChange(newData?.value ?? null);
    }
  };

  const tOptions = useMemo(() => {
    return options.map((option) => ({
      label: translation ? t(option.label) : option.label,
      value: option.value,
    }));
  }, [options, t, translation]);

  return (
    <FormControl fullWidth error>
      {label && <GFLabel required={required}>{label}</GFLabel>}
      <StyledAutocomplete
        {...props}
        key={uniqueId()}
        options={tOptions}
        onChange={handleChange as any}
        noOptionsText={t("NO_OPTION")}
        value={tOptions.find((x) => x.value === value)}
        renderInput={(params) => (
          <GFTextField {...params} error={error} size={size} placeholder={placeholder}/>
        )}
        renderOption={(props, option: any) => (
          <GFMenuItem {...props} key={option.value}>
            {option.label}
          </GFMenuItem>
        )}
      />
    </FormControl>
  );
}
