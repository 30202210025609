import {
  GFFormItem,
  GFModal,
  GFTextField,
} from "@Components/ui";
import { CUSTOMER_MANAGE_REQUEST_TYPE } from "@Constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { Grid } from "@mui/material";
import { isFunction } from "lodash";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SapCodePayload, sapCodeSchema } from "src/schemas/sapcode.schema";
import { ModalDefaultProps } from "src/types";

type Props = ModalDefaultProps & {
    requestType: string,
    onConfirmSAPCode: (sapCode: string) => void
};

const defaultValues: SapCodePayload = {
    sapCode: "",
  };

export default function SAPCodeConfirmModal({
  open,
  onClose,
  onConfirm,
  onConfirmSAPCode,
  requestType
}: Props) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isDirty },
  } = useForm<SapCodePayload>({
    resolver: zodResolver(sapCodeSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { t } = useTranslation("translation");
  const onSubmit = (data: any) => {
    if (isFunction(onConfirmSAPCode)) {
      onConfirmSAPCode(data.sapCode);
    }
  };

  const label = requestType === CUSTOMER_MANAGE_REQUEST_TYPE.CREATE_ADDRESS ? t("customerManage.sapCode_ShippingAddress") : t("customerManage.sapCode_Customer")

  return (
    <GFModal
      title={t("customerManage.rejectReason")}
      open={open}
      onClose={onClose}
      width="50rem"
      onConfirm={handleSubmit(onSubmit)}
      showHeaderTitle={false}
      showIconClose={false}
      disabledConfirm={!isValid || !isDirty}
      >
      <form id="reject-reason" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GFFormItem control={control} name="sapCode">
            <GFTextField
                fullWidth
                label={label}
                required
                placeholder={`${t("inputPlaceholder")} ${label}`}
              />
            </GFFormItem>
          </Grid>
        </Grid>
      </form>
    </GFModal>
  );
}
