/* eslint-disable array-callback-return */
import Avatar from "@Assets/images/avt.png";
import { Actors, SourceType, TEXT_HOLDER } from "@Constants";
import { SalesOrgModal } from "@Models";
import { NotificationService, SalesOrgService } from "@Services";
import { FormUIUtil, FormUtil } from "@Utils";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import "./index.scss";
import { useTranslation } from "react-i18next";

const SendNotification: React.FC = () => {
  const { t } = useTranslation(["translation", "order"]);
  const { enqueueSnackbar } = useSnackbar();
  const [source, setSource] = useState<string>("");
  const [checkedActors, setCheckedActors] = useState<any>([]);
  const [salesOrgData, setSalesOrgData] = useState<SalesOrgModal[]>([]);
  const [checkedSalesOrg, setCheckedSalesOrg] = useState<any>([]);
  const [searchSalesOrg, setSearchSalesOrg] = useState<string>("");
  const [accountList, setAccountList] = useState<any>();
  const [accountListData, setAccountListData] = useState<any>([]);
  const [searchAccount, setSearchAccount] = useState<string>("");
  const [checkedAccount, setCheckAccount] = useState<any>([]);
  const [checkedAccountToSend, setCheckAccountToSend] = useState<any>([]);
  const [list, setList] = useState<number>(0);
  const [blur, setBlur] = useState<boolean>(false);
  const [submitErrorAccount, setSubmitErrorAccount] = useState<boolean>(false);
  const [submitErrorActors, setSubmitErrorActors] = useState<boolean>(false);
  const [submitErrorSource, setSubmitErrorSource] = useState<boolean>(false);

  const submitRef = useRef<any | null>(null);
  const submitForm = async (values: any) => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    //TODO - bo accountId khi da check tat ca
    // for (const element of dataAccountToSend) {
    //   if (checkedActors[list] === "roles") {
    //     dataAccount
    //       .map((d: any) => {
    //         if (accountList?.[element].some((acc: any) => acc?.id === d)) return d;
    //       })
    //       .filter(Boolean);
    //   } else {
    //     dataAccount
    //       .map((d: any) => {
    //         if (accountList?.[element].some((acc: any) => acc?.account?.id === d)) return d;
    //       })
    //       .filter(Boolean);
    //   }
    // }

    try {
      const res = await NotificationService.SendNotification({
        accountIds: Array.from(new Set(dataAccount)),
        actors: dataActors.join(","),
        title: values.title,
        body: values.body,
        getAll: dataAccountToSend.length > 0 ? dataAccountToSend : [],
        salesOrgIds: dataSalesOrg.join(","),
        sources: source === "all" ? "feed,farm" : source,
      });

      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar("Gửi thông báo thành công", {
          variant: "success",
          preventDuplicate: true,
        });
        setTimeout(() => window.location.reload(), 200);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeTab = (_: any, newList: number) => {
    setList(newList);
  };

  const onSearchAccount = _.debounce((value) => {
    setSearchAccount(value);
  }, 500);

  //get sales org
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await SalesOrgService.getAllSalesOrg({
          sources: source === "all" ? "feed,farm" : source,
        });
        if (res.status === 200 || res.status === 204) {
          setSalesOrgData(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    source !== "" && fetchData();
  }, [source]);

  //get users
  useEffect(() => {
    const fetchDataAccount = async () => {
      try {
        const res = await NotificationService.NotificationGetUsers({
          sources: source === "all" ? "feed,farm" : source,
          salesOrgIds: checkedActors.length > 0 ? new Array(checkedSalesOrg) : undefined,
          actors: checkedActors.length > 0 ? new Array(checkedActors) : undefined,
          getAll: true,
          searchString: searchAccount,
        });
        if (res.status === 200 || res.status === 204) {
          setAccountList(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkedActors.length !== 0 && fetchDataAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, searchAccount, checkedActors, checkedSalesOrg]);

  const dataSalesOrg = [...(checkedSalesOrg || [])];
  const dataActors = [...(checkedActors || [])];
  const dataAccount = [...(checkedAccount || [])];
  const dataAccountToSend = [...(checkedAccountToSend || [])];

  const checkedQuantityAccount = accountListData?.reduce((quan: number, cur: any) => {
    if (checkedActors[list] === "roles" || checkedActors[list] === "admin") {
      if (dataAccount.includes(cur.id)) {
        quan++;
      }
    } else {
      if (dataAccount.includes(cur?.account?.id)) {
        quan++;
      }
    }

    return quan;
  }, 0);

  const act = Actors.filter((act) => checkedActors.some((s: any) => s === act.value));

  //get defautl db for accountList
  const data = accountList && accountList?.[act[0]?.value];
  useEffect(() => {
    blur === true && setSearchAccount("");
  }, [blur]);

  useEffect(() => {
    setAccountListData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedActors, accountList]);

  return (
    <div className="send-noti">
      <div className="title">Gửi thông báo</div>
      <Form onSubmit={async (values) => submitForm(values)}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="noti-form">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {FormUIUtil.renderFormItem(
                    "Mảng",
                    <FormControl>
                      <Select
                        value={source}
                        defaultValue={source}
                        onChange={(e) => {
                          setSource(e.target.value as string);
                        }}
                        fullWidth
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        variant="outlined"
                        renderValue={
                          source !== ""
                            ? undefined
                            : () => <span className="form__placeholder">Chọn mảng</span>
                        }
                        error={submitErrorSource}
                        onBlur={() => setSubmitErrorSource(!Boolean(source))}
                        displayEmpty
                        className="form-select-field"
                        MenuProps={{
                          className: "select-menu-list",
                        }}>
                        {SourceType.map((type, index: number) => {
                          return (
                            <MenuItem key={index} value={type.value}>
                              {type.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{submitErrorSource && "Bạn chưa chọn mảng"}</FormHelperText>
                    </FormControl>
                  )}
                  {FormUIUtil.renderFormItem(
                    "Đối tượng",
                    <FormControl>
                      <Select
                        multiple
                        value={checkedActors}
                        defaultValue={checkedActors}
                        fullWidth
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        renderValue={(selected) => {
                          const actors = Actors
                            // eslint-disable-next-line array-callback-return
                            .map((act) => {
                              if (selected.some((s: any) => s === act.value)) return act.label;
                            })
                            .filter(Boolean);

                          return checkedActors.length === 0 ? (
                            <span className="form__placeholder">Chọn đối tượng</span>
                          ) : checkedActors.length === Actors.length ? (
                            t("ALL")
                          ) : (
                            actors.join(" + ")
                          );
                        }}
                        error={submitErrorActors}
                        onBlur={() => setSubmitErrorActors(checkedActors.length > 0 ? false : true)}
                        variant="outlined"
                        className="form-select-field"
                        displayEmpty
                        MenuProps={{
                          className: "select-menu-list",
                        }}>
                        <ListSubheader style={{ width: "100%", padding: 0 }}>
                          <div className="select-all">
                            <Checkbox
                              checked={checkedActors.length === Actors.length}
                              color="success"
                              onChange={(_, value) => {
                                if (value === true) {
                                  setCheckedActors(Actors?.map((a) => a.value) || []);
                                } else {
                                  setCheckedActors([]);
                                }
                              }}
                              style={{ transform: "scale(1.5)" }}
                            />
                            <div className="text-all">{t("ALL")}</div>
                          </div>
                        </ListSubheader>
                        {Actors.map((val, index: number) => (
                          <MenuItem key={index} value={val.label} className="checked-item">
                            <ListItemText primary={val.label} />
                            <Checkbox
                              checked={checkedActors.some((some: any) => some === val.value)}
                              onChange={(_, value) => {
                                if (value === true) {
                                  dataActors.push(val.value);
                                  setCheckedActors(dataActors);
                                } else {
                                  setCheckedActors(
                                    dataActors.filter((value) => {
                                      return value !== val.value;
                                    })
                                  );
                                }
                              }}
                              color="success"
                              style={{ transform: "scale(1.5)" }}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {submitErrorActors && "Bạn chưa chọn đối tượng"}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {FormUIUtil.renderFormItem(
                    t("BUSINESS_ORGANIZATION"),
                    <FormControl>
                      <Select
                        value={checkedSalesOrg}
                        defaultValue={checkedSalesOrg}
                        multiple
                        fullWidth
                        onClose={() => setSearchSalesOrg("")}
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        variant="outlined"
                        renderValue={(selected) => {
                          const salesOrg = salesOrgData
                            // eslint-disable-next-line array-callback-return
                            ?.map((so) => {
                              if (selected.some((s: any) => s === so.id)) return so.description;
                            })
                            .filter(Boolean);

                          return checkedSalesOrg?.length === 0 ? (
                            <span className="form__placeholder">Chọn tổ chức kinh doanh</span>
                          ) : checkedSalesOrg?.length === salesOrgData?.length ? (
                            t("ALL")
                          ) : (
                            salesOrg?.join(" + ")
                          );
                        }}
                        displayEmpty
                        className="form-select-field"
                        MenuProps={{
                          className: "select-menu-list",
                          autoFocus: false,
                          style: { maxHeight: "40rem" },
                        }}>
                        <ListSubheader style={{ width: "100%", padding: 0 }}>
                          <TextField
                            size="small"
                            placeholder="Tìm kiếm..."
                            autoFocus
                            variant="outlined"
                            className={"search-border-style"}
                            inputProps={{ className: "input" }}
                            fullWidth
                            color="success"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon style={{ width: "3rem", height: "3rem" }} />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchSalesOrg(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                          <div className="select-all">
                            <Checkbox
                              checked={
                                checkedSalesOrg.length ===
                                salesOrgData.filter((s) =>
                                  s.description.toLowerCase().includes(searchSalesOrg.toLowerCase())
                                ).length
                              }
                              color="success"
                              onChange={(_, value) => {
                                if (value === true) {
                                  setCheckedSalesOrg(
                                    salesOrgData
                                      .filter((s) =>
                                        s.description
                                          .toLowerCase()
                                          .includes(searchSalesOrg.toLowerCase())
                                      )
                                      ?.map((a) => a.id) || []
                                  );
                                } else {
                                  setCheckedSalesOrg([]);
                                }
                              }}
                              style={{ transform: "scale(1.5)" }}
                            />
                            <div className="text-all">{t("ALL")}</div>
                          </div>
                        </ListSubheader>
                        {salesOrgData
                          .filter((s) =>
                            s.description.toLowerCase().includes(searchSalesOrg.toLowerCase())
                          )
                          .map((type, index: number) => {
                            return (
                              <MenuItem key={index} value={type.description}>
                                <ListItemText primary={type.description || TEXT_HOLDER} />
                                <Checkbox
                                  checked={dataSalesOrg.some((some) => some === type.id)}
                                  onChange={(e, value) => {
                                    if (value === true) {
                                      dataSalesOrg.push(type.id);
                                      setCheckedSalesOrg(dataSalesOrg);
                                    } else {
                                      setCheckedSalesOrg(
                                        dataSalesOrg.filter((id) => {
                                          return id !== type.id;
                                        })
                                      );
                                    }
                                  }}
                                  color="success"
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                  {FormUIUtil.renderFormItem(
                    "Phạm vi nhận",
                    <FormControl>
                      <Select
                        value={checkedAccount}
                        defaultValue={checkedAccount}
                        multiple
                        onClose={() => setBlur(true)}
                        onSelect={() => setBlur(false)}
                        fullWidth
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        variant="outlined"
                        renderValue={(selected) => {
                          const account = accountList?.[checkedActors[list]]
                            // eslint-disable-next-line
                            ?.map((acc: any) => {
                              if (
                                checkedActors[list] === "roles" ||
                                checkedActors[list] === "admin"
                              ) {
                                if (selected.some((s: any) => s === acc?.id))
                                  return (
                                    acc?.fullName ||
                                    acc?.nickname ||
                                    acc?.role?.position ||
                                    TEXT_HOLDER
                                  );
                              } else {
                                if (selected.some((s: any) => s === acc?.account?.id))
                                  return (
                                    acc?.fullName ||
                                    acc?.nickname ||
                                    acc?.role?.position ||
                                    TEXT_HOLDER
                                  );
                              }
                            })
                            .filter(Boolean);

                          return checkedAccount?.length === 0 ? (
                            <span className="form__placeholder">Chọn phạm vi nhận</span>
                          ) : checkedQuantityAccount === accountListData?.length ? (
                            t("ALL")
                          ) : (
                            account?.join(" + ")
                          );
                        }}
                        displayEmpty
                        className="form-select-field"
                        onBlur={(e) => {
                          setSubmitErrorAccount(dataAccount.length > 0 ? false : true);
                        }}
                        error={submitErrorAccount}
                        MenuProps={{
                          className: "select-menu-list",
                          autoFocus: false,
                          style: { maxHeight: "40rem" },
                        }}>
                        <ListSubheader style={{ width: "100%", padding: 0 }}>
                          <TextField
                            size="small"
                            placeholder="Tìm kiếm..."
                            autoFocus
                            variant="outlined"
                            className={"search-border-style"}
                            inputProps={{ className: "input" }}
                            fullWidth
                            color="success"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <SearchIcon style={{ width: "3rem", height: "3rem" }} />
                                </InputAdornment>
                              ),
                            }}
                            // onClick={() => setBlur(false)}
                            onChange={(e) => onSearchAccount(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                          <div className="list-tab">
                            <Tabs className="tab" value={list} onChange={handleChangeTab}>
                              {act.map((act, index: number) => {
                                return (
                                  <Tab
                                    key={index}
                                    style={{ fontFamily: "SVN-Gotham" }}
                                    label={act.label + `(${accountList?.[act.value]?.length})`}
                                    value={index}
                                    onClick={() => setAccountListData(accountList[act.value])}
                                  />
                                );
                              })}
                            </Tabs>
                          </div>
                          <div className="select-all">
                            <Checkbox
                              color="success"
                              checked={
                                accountListData?.length > 0
                                  ? checkedQuantityAccount === accountListData?.length
                                  : false
                              }
                              onChange={(_, value) => {
                                if (value) {
                                  dataAccountToSend.push(checkedActors[list]);
                                  if (
                                    checkedActors[list] === "roles" ||
                                    checkedActors[list] === "admin"
                                  ) {
                                    dataAccount.push(
                                      ...(accountListData.map((a: any) => a.id) || [])
                                    );
                                  } else {
                                    dataAccount.push(
                                      ...(accountListData.map((a: any) => a?.account?.id) || [])
                                    );
                                  }
                                  setCheckAccount(dataAccount);
                                  setCheckAccountToSend(dataAccountToSend);
                                  // setCheckAccount(accountListData.map((a: any) => a.id) || []);
                                } else {
                                  // setCheckAccount([]);
                                  if (
                                    checkedActors[list] === "roles" ||
                                    checkedActors[list] === "admin"
                                  ) {
                                    setCheckAccount(
                                      dataAccount.filter((id) => {
                                        return (
                                          id !== accountListData.find((a: any) => a.id === id)?.id
                                        );
                                      })
                                    );
                                  } else {
                                    setCheckAccount(
                                      dataAccount.filter((id) => {
                                        return (
                                          id !==
                                          accountListData.find((a: any) => a?.account?.id === id)
                                            ?.account?.id
                                        );
                                      })
                                    );
                                  }
                                }
                                setCheckAccountToSend(
                                  dataAccountToSend.filter(
                                    (actors) =>
                                      checkedQuantityAccount !== accountList?.[actors]?.length
                                  )
                                );
                              }}
                              style={{ transform: "scale(1.5)" }}
                            />
                            <div className="text-all">{t("ALL")}</div>
                          </div>
                        </ListSubheader>
                        {accountListData?.map((type: any, index: number) => {
                          return (
                            <MenuItem key={index} value={type.code}>
                              <ListItem component="div">
                                <img
                                  src={Avatar}
                                  alt="#"
                                  style={{
                                    width: "4.8rem",
                                    height: "4.8rem",
                                    borderRadius: "50%",
                                    marginRight: "1rem",
                                  }}
                                />
                                <ListItemText
                                  primary={
                                    <div className="list-tab__name">
                                      {type?.fullName ||
                                        type?.nickname ||
                                        type?.role?.position ||
                                        TEXT_HOLDER}{" "}
                                      - {type.code}
                                    </div>
                                  }
                                  secondary={type?.phoneNumber || TEXT_HOLDER}
                                />
                              </ListItem>
                              <Checkbox
                                checked={
                                  checkedActors[list] === "roles" || checkedActors[list] === "admin"
                                    ? checkedAccount.some((some: any) => some === type.id)
                                    : checkedAccount.some((some: any) => some === type?.account?.id)
                                }
                                onChange={(_, value) => {
                                  if (value === true) {
                                    if (
                                      checkedActors[list] === "roles" ||
                                      checkedActors[list] === "admin"
                                    ) {
                                      dataAccount.push(type.id);
                                    } else {
                                      dataAccount.push(type?.account?.id);
                                    }
                                    setCheckAccount(dataAccount);
                                  } else {
                                    if (
                                      checkedActors[list] === "roles" ||
                                      checkedActors[list] === "admin"
                                    ) {
                                      setCheckAccount(
                                        dataAccount.filter((id) => {
                                          return id !== type.id;
                                        })
                                      );
                                    } else {
                                      setCheckAccount(
                                        dataAccount.filter((id) => {
                                          return id !== type?.account?.id;
                                        })
                                      );
                                    }
                                  }
                                  setCheckAccountToSend(
                                    dataAccountToSend.filter(
                                      (actors) =>
                                        checkedQuantityAccount !== accountList?.[actors]?.length
                                    )
                                  );
                                }}
                                color="success"
                                style={{ transform: "scale(1.5)" }}
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {submitErrorAccount && "Bạn chưa chọn phạm vi nhận"}
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <div className="form-item">
                <div className="form-label">Tiêu đề thông báo</div>
                <Field
                  name="title"
                  validate={FormUtil.Rule.required("Bạn chưa nhập tiêu đề thông báo")}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        color="success"
                        variant="outlined"
                        placeholder={"Nhập tiêu đề thông báo..."}
                        className={"form-text-field"}
                        inputProps={{ className: "input" }}
                        helperText={meta.touched ? meta.error : ""}
                        error={meta.error && meta.touched}
                      />
                    );
                  }}
                </Field>
              </div>
              <div className="form-item">
                <div className="form-label">Nội dung thông báo</div>
                <Field
                  name="body"
                  validate={FormUtil.Rule.required("Bạn chưa nhập nội dung thông báo")}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        color="success"
                        multiline
                        fullWidth
                        rows={3}
                        variant="outlined"
                        placeholder={"Nhập nội dung thông báo..."}
                        className={"form-textarea-field"}
                        inputProps={{ className: "input" }}
                        helperText={meta.touched ? meta.error : ""}
                        error={meta.error && meta.touched}
                      />
                    );
                  }}
                </Field>
              </div>
              <button className="btn-confirm-style" type="submit" ref={submitRef}>
                Gửi thông báo
              </button>
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default SendNotification;
