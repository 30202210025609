import { GFProductSearchWrapper } from "@Components/ui/GFProductSearch/styles";
import {
  GFSearchWithInfiniteScrollLoading,
  GFSearchWithInfiniteScrollNoData,
} from "@Components/ui/GFSearchWithInfiniteScroll/styles";
import GFTextField from "@Components/ui/GFTextField";
import { Search } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import _, { isFunction, isNil } from "lodash";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

type GFProductSearchProps<T = any> = {
  data?: T[];
  loading?: boolean;
  fetchingNextPage?: boolean;
  hasMore?: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  selectedKey?: string;
  renderItem: (item: T) => React.ReactNode;
  onGetNextPage?: () => void;
  onClickItem?: (item: T) => void;
  onSearchChange?: (value: string) => void;
};

export default function GFProductSearch<T = any>({
  data = [],
  loading = false,
  fetchingNextPage = false,
  hasMore = false,
  searchPlaceholder = "Tìm kiếm",
  searchValue,
  selectedKey = "id",
  renderItem,
  onGetNextPage,
  onClickItem,
  onSearchChange,
}: GFProductSearchProps<T>) {
  const [search, setSearch] = useState("");

  const searchDebounced = useCallback(
    _.debounce((value: string) => {
      if (isFunction(onSearchChange)) {
        onSearchChange(value);
      }
    }, 500),
    [onSearchChange]
  );

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);

    searchDebounced.cancel();

    searchDebounced(e.target.value);
  };

  const handleGetNextPage = () => {
    if (loading || fetchingNextPage) return;

    if (isFunction(onGetNextPage)) {
      onGetNextPage();
    }
  };

  const handleItemClick = (item: T) => {
    if (isFunction(onClickItem)) {
      onClickItem(item);
    }
  };

  useEffect(() => {
    if (!isNil(searchValue) && searchValue !== search) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  return (
    <GFProductSearchWrapper>
      <Box sx={{
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        paddingInline: '48px'
      }}>
        <GFTextField
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search />,
          }}
          placeholder={searchPlaceholder}
          fullWidth
          sx={{
            width: '100%',
            background: '#ffff'
          }}
          className="gf-search-with-infinite-scroll__input"
        />
      </Box>

      <Box flex={1} overflow={"auto"} px={'24px'}>
        <InfiniteScroll
          loadMore={handleGetNextPage}
          hasMore={hasMore}
          loader={
            <GFSearchWithInfiniteScrollLoading key={0}>
              <CircularProgress size={18} />
            </GFSearchWithInfiniteScrollLoading>
          }
          useWindow={false}
          initialLoad={false}>
          {data.length > 0 ? (
            data.map((item, idx) => (
              <Box key={(item as any)?.id ?? idx} onClick={() => handleItemClick(item)}>
                {renderItem(item)}
              </Box>
            ))
          ) : loading ? (
            <GFSearchWithInfiniteScrollLoading key={0}>
              <CircularProgress size={18} />
            </GFSearchWithInfiniteScrollLoading>
          ) : (
            <GFSearchWithInfiniteScrollNoData fontWeight={600} color="var(--primary-green)">
              Không có dữ liệu
            </GFSearchWithInfiniteScrollNoData>
          )}
        </InfiniteScroll>
      </Box>
    </GFProductSearchWrapper>
  );
}
