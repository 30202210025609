import { z } from "zod";

export const rejectReasonSchema = z.object({
    reason: z.string({
        message: "Vui lòng nhập lý do từ chối.",
      })
      .min(1, {
        message: "Vui lòng nhập lý do từ chối."
      })
      .max(500, {
        message: "Lý do từ chối không được vượt quá 500 ký tự.",
      })
})

export type RejectReasonPayload = z.infer<typeof rejectReasonSchema>;